import React from 'react'
import { emailNew as Inbox } from 'assets/icons/groove/v2'
import { styles } from './styles'

const ListInspector = () => {
  return (
    <div
      css={styles.base}
      className="grui w-100 h-100 flex items-center justify-center"
    >
      <Inbox />
    </div>
  )
}

export default ListInspector
