import React from 'react'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'

import { styles } from './styles'

const AgentChip = ({ user = {}, type }) => {
  const { avatarUrl, fullName } = user

  return (
    <div css={[styles.avatarWrapper, styles.types[type]]}>
      <Avatar avatar={avatarUrl} userName={fullName} size="xl" />
      {(type === 'typingReply' || type === 'typingNote') && (
        <div css={styles.typingIndicator}>
          <span />
        </div>
      )}
    </div>
  )
}

export default AgentChip
