import { useCallback } from 'react'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function DealsIndexRow({ deal, doNavigatableSetState }) {
  const { id, properties } = deal || {}
  const {
    dealstage: { value: dealstage } = {},
    dealname: { value: dealname } = {},
  } =
    properties || {}
  const onClick = useCallback(() => doNavigatableSetState(id), [
    doNavigatableSetState,
    id,
  ])
  return (
    <NavigatableIntegrationWidgetCard.ListItem
      title={dealname}
      onClick={onClick}
      subTitle={`${dealstage}`}
    />
  )
}
