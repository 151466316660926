import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRechargeCharges } from 'ducks/integrations/recharge/hooks/charges'
import {
  selectOrdersIsLoading,
  selectOrdersForStatus,
} from 'ducks/integrations/recharge/selectors/orders'
import { Loader } from 'shared/ui'

import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import ListItem from './ListItem'
import OrderListItem from '../Orders/ListItem'

const List = ({ integrationId, subscriptionId }) => {
  const [showMore, setShowMore] = useState(false)
  const {
    charges: allCharges,
    isLoading: isChargesLoading,
    isError,
  } = useRechargeCharges(integrationId, subscriptionId)
  const isOrdersLoading = useSelector(state =>
    selectOrdersIsLoading(state, subscriptionId)
  )
  const orders = useSelector(state =>
    selectOrdersForStatus(state, { subscriptionId, status: 'queued' })
  )

  const isLoading = isChargesLoading || isOrdersLoading

  const charges = useMemo(
    () => {
      if (!allCharges) {
        return []
      }

      return allCharges.filter(charge =>
        ['queued', 'skipped'].includes(charge.status)
      )
    },
    [allCharges]
  )

  const chargesAndOrders = useMemo(
    () => {
      if (!charges && !orders) {
        return []
      }

      if (showMore) {
        return [...charges, ...orders]
      }

      return [...charges, ...orders].slice(0, 3)
    },
    [charges, orders, showMore]
  )

  const toggleShowMore = useCallback(
    event => {
      event.preventDefault()

      setShowMore(!showMore)
    },
    [showMore]
  )

  if (!charges || !orders) {
    return null
  }

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Upcoming orders" />

      {isLoading && <Loader className="grui my-7" />}

      {isError && (
        <div className="grui px-4 py-7">
          <EmptyMessage>There was an error fetching Recharge data</EmptyMessage>
        </div>
      )}

      {!isLoading &&
        !chargesAndOrders.length && (
          <div className="grui px-4 py-7">
            <EmptyMessage>No upcoming orders to show</EmptyMessage>
          </div>
        )}

      {!isLoading && (
        <NavigatableIntegrationWidgetCard.List hideMargin>
          {chargesAndOrders.map(
            chargeOrOrder =>
              chargeOrOrder.charge !== undefined ? (
                <OrderListItem
                  key={chargeOrOrder.id}
                  orderId={chargeOrOrder.id}
                  subscriptionId={subscriptionId}
                  date={chargeOrOrder.scheduled_at}
                />
              ) : (
                <ListItem
                  key={chargeOrOrder.id}
                  chargeId={chargeOrOrder.id}
                  subscriptionId={subscriptionId}
                />
              )
          )}
        </NavigatableIntegrationWidgetCard.List>
      )}

      {charges.length > 3 &&
        !showMore && (
          <a
            className="grui recharge-show-more"
            onClick={toggleShowMore}
            rel="noopener noreferrer"
          >
            + Show more orders
          </a>
        )}
    </>
  )
}

export default memo(List)
