import React from 'react'
import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import { Label } from 'shared/ui'

import Section from '../Section'
import Collection from './Collection'

export default class CollectionSection extends React.PureComponent {
  render() {
    const {
      doNavigatableSetState,
      label,
      objects,
      parentId,
      settings,
      specificFieldsPattern,
    } = this.props
    if (specificFieldsPattern) {
      const pattern = new RegExp(specificFieldsPattern)
      const keys = extractSpecificFields(settings, pattern)
      if (!keys) return null
      if (keys.length === 0) return null
    }

    return (
      <Section className="flatBottom">
        <Label size="small">{label}</Label>
        <Collection
          doNavigatableSetState={doNavigatableSetState}
          objects={objects}
          parentId={parentId}
          settings={settings}
          specificFieldsPattern={specificFieldsPattern}
        />
      </Section>
    )
  }
}
