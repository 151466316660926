import React, { Fragment } from 'react'
import Link from 'redux-first-router-link'
import { Form, Loader } from 'util/ui'
import { FORGOT_PASSWORD_PAGE } from 'constants/pages'

import ValidatedField from 'shared/components/ui/ValidatedField'
import { SubHeader } from 'shared/components/ui/Typography'
import FormError from 'shared/components/ui/Form/Error'
import { propFunc } from 'util/functions'

import styles from './styles.less'

class ForgotForm extends React.PureComponent {
  componentDidMount() {
    const { onLoad } = this.props
    onLoad()
  }

  pendingValidation = {}

  clearValidationTimeout(name) {
    if (this.pendingValidation[name]) {
      clearTimeout(this.pendingValidation[name])
    }
  }

  handleChange = (name, _, { value, checked }) => {
    const { onChange, onValidate } = this.props
    this.clearValidationTimeout(name)
    this.pendingValidation[name] = setTimeout(() => {
      onValidate(name, checked === undefined ? value : checked)
    }, 500)
    onChange(name, checked === undefined ? value : checked)
  }

  handleBlur = name => {
    const { onValidate, formFields } = this.props
    this.clearValidationTimeout(name)
    onValidate(name, formFields[name])
  }

  render() {
    const {
      formFields,
      formFieldsErrors,
      submitForm,
      canSave,
      saving,
      isLoading,
      changePasswordErrors,
      passwordTokenFound,
      isTwoFactorAuthRequired,
    } = this.props

    return (
      <Form size="large">
        {!isLoading &&
          passwordTokenFound && (
            <Fragment>
              <Form.Field>
                <ValidatedField
                  placeholder="Password"
                  type="password"
                  value={formFields.passwordInput}
                  errorMessage={formFieldsErrors.passwordInput}
                  onChange={propFunc(this.handleChange, 'passwordInput')}
                  onBlur={propFunc(this.handleBlur, 'passwordInput')}
                  autoComplete="password"
                  name="password"
                />
              </Form.Field>
              <Form.Field>
                <ValidatedField
                  placeholder="Password confirmation"
                  type="password"
                  value={formFields.passwordConfirmInput}
                  errorMessage={formFieldsErrors.passwordConfirmInput}
                  onChange={propFunc(this.handleChange, 'passwordConfirmInput')}
                  onBlur={propFunc(this.handleBlur, 'passwordConfirmInput')}
                  autoComplete="password"
                  name="confirm-password"
                />
              </Form.Field>
              {isTwoFactorAuthRequired && (
                <Form.Field>
                  <ValidatedField
                    placeholder="Two-factor authentication code"
                    type="text"
                    value={formFields.twoFactorAuthenticationInput}
                    errorMessage={formFieldsErrors.twoFactorAuthenticationInput}
                    onChange={propFunc(
                      this.handleChange,
                      'twoFactorAuthenticationInput'
                    )}
                    onBlur={propFunc(
                      this.handleBlur,
                      'twoFactorAuthenticationInput'
                    )}
                    name="2fa"
                  />
                </Form.Field>
              )}

              {changePasswordErrors.map(error => (
                <FormError key={error} error={error} />
              ))}

              <Form.Button
                primary
                size="big"
                fluid
                disabled={!canSave}
                onClick={submitForm}
              >
                {!saving ? 'Change password' : 'One moment...'}
              </Form.Button>
            </Fragment>
          )}
        {!isLoading &&
          !passwordTokenFound && (
            <Fragment>
              <Form.Field className={styles.center}>
                <SubHeader size="small" as="h2" color="berry">
                  Reset password token has expired
                </SubHeader>
              </Form.Field>
              <Form.Field className={styles.center}>
                <Link to={{ type: FORGOT_PASSWORD_PAGE }}>Back</Link>
              </Form.Field>
            </Fragment>
          )}
        {isLoading && <Loader active inline="centered" />}
      </Form>
    )
  }
}

export default ForgotForm
