import React from 'react'
import ActivationCard from './ActivationCard'
import { styles } from './styles'

const ActivationCardWithBackground = ({
  backgroundImg,
  className,
  children,
}) => {
  return (
    <div css={styles.container} className={className}>
      <img src={backgroundImg} alt="Empty content" aria-hidden="true" />
      <div css={styles.content}>{children}</div>
    </div>
  )
}

ActivationCardWithBackground.Card = ActivationCard

export default ActivationCardWithBackground
