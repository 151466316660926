import styled from '@emotion/styled'
import { css } from '@emotion/react'
import cn from 'classnames'
import { Tooltip, Flex } from 'shared/ui'

const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const RecipientLabel = styled(({ className, text, type = 'cc' }) => {
  return (
    <div id="fb-recipient" css={truncate} className={cn(className, type)}>
      {text}
    </div>
  )
})`
  color: ${props => props.theme.color.primary.brand};
`

const TruncatedTooltip = ({ tooltip, children }) => {
  return (
    <Tooltip css={truncate} tooltip={tooltip} position="top">
      {children}
    </Tooltip>
  )
}

const Recipient = ({ user }) => {
  if (user && user.name) {
    return (
      <TruncatedTooltip tooltip={user.name}>
        <RecipientLabel text={user.name} />
      </TruncatedTooltip>
    )
  }

  // If we dont know the user name, just default to n/a
  return <RecipientLabel text={'N/A'} />
}

const RecipientSummary = ({ className, label = 'To', to }) => {
  return (
    <Flex className={cn('grui overflow-hidden', className)}>
      <Flex className="grui overflow-hidden">
        <div className="label">{label}:</div>
        <div className="grui ml-3 overflow-hidden">
          <Recipient user={to} />
        </div>
      </Flex>
    </Flex>
  )
}

export default styled(RecipientSummary)`
  .label {
    color: ${props => props.theme.color.monochrome.superDark};
  }
`
