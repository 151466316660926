import React from 'react'
import { Form } from 'util/ui'
import ValidatedField from 'shared/components/ui/ValidatedField'
import FormError from 'shared/components/ui/Form/Error'

class OtpForm extends React.PureComponent {
  handleCodeChange = (a, { value }) => {
    this.props.onChange('code', value, undefined, { validate: true })
  }

  render() {
    const {
      formFields,
      formFieldsErrors,
      submitForm,
      canSave,
      saving,
      loginError,
    } = this.props
    return (
      <Form size="large" autoComplete="off">
        <Form.Field>
          <ValidatedField
            autoFocus
            errorMessage={formFieldsErrors.code}
            name="code"
            onChange={this.handleCodeChange}
            placeholder="6-digit code"
            type="text"
            value={formFields.code}
          />
        </Form.Field>
        {loginError && <FormError error={loginError} />}
        <Form.Button
          disabled={!canSave}
          fluid
          onClick={submitForm}
          primary
          size="big"
        >
          {!saving ? 'Submit' : 'One moment...'}
        </Form.Button>
      </Form>
    )
  }
}

export default OtpForm
