import GlobalContextProvider from './GlobalContextProvider'
import WithGlobalContext from './WithGlobalContext'

import { DROPDOWN, MODAL, MODAL_DROPDOWN } from './constants'

WithGlobalContext.DROPDOWN = DROPDOWN
WithGlobalContext.MODAL = MODAL
WithGlobalContext.MODAL_DROPDOWN = MODAL_DROPDOWN

WithGlobalContext.GlobalContextProvider = GlobalContextProvider

export default WithGlobalContext
