import React, { useCallback, useMemo } from 'react'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'

export default function LinkedJiraCloudIssuesRow({
  doNavigatableSetState,
  issue,
  issue: {
    loaded,
    errored,
    errors,
    fields: {
      project: { name: projectName } = {},
      status: { name: status } = {},
    } = {},
    title,
  } = {},
}) {
  const onClick = useCallback(
    () => {
      doNavigatableSetState(issue.id)
    },
    [doNavigatableSetState, issue]
  )

  const errorMessage = useMemo(
    () => {
      if (isNullOrUndefined(errors)) return ''
      if (!Array.isArray(errors)) return ''

      return errors.join(<br />)
    },
    [errors]
  )

  if (issue && errored) {
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        title={`⚠️ ${title}`}
        subTitleStatus="negative"
        subTitle="Error"
        subTitleStatusText={errorMessage}
      />
    )
  }

  if (issue && loaded)
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        onClick={onClick}
        subTitle={`${projectName} · ${status}`}
        title={title}
      />
    )
  return null
}
