import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { selectIsRefreshingSearches } from 'selectors/app'
import { selectCurrentMailboxHasTickets } from 'selectors/mailboxes/selectCurrentMailboxHasTickets'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'

import TitleBar from './TitleBar'
import FilterBar from './FilterBar'
import styles from './styles.less'

export default function ConversationListHeader(props) {
  const { TitleBarComponent = TitleBar, FilterBarComponent = FilterBar } = props
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const currentMailboxHasTickets = useSelector(selectCurrentMailboxHasTickets)
  const isRefreshingSearches = useSelector(selectIsRefreshingSearches)

  const showActionBar =
    currentMailboxHasTickets === true ||
    !currentMailboxId ||
    isRefreshingSearches

  return (
    <div
      className={cn(
        styles['conversation-list-header'],
        'conversation-list-header'
      )}
    >
      {showActionBar && (
        <>
          <TitleBarComponent />
          <FilterBarComponent />
        </>
      )}
    </div>
  )
}
