import React from 'react'
import Screen from 'components/Screen'
import styles from './styles.css'

const NotFound = () => (
  <Screen className="ErrorPage">
    <div className="ErrorPage_header">{'404'}</div>

    <div className="ErrorPage_subheader">
      {"Sorry! This page doesn't exist. "}
    </div>

    <div className={styles.cta}>
      <a className="ErrorPage_cta" href="/">
        {'Reload'}
      </a>
    </div>
  </Screen>
)

export default NotFound
