import { CSSTransition } from 'react-transition-group'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import AnimateAutoHeight from 'components/AnimateAutoHeight'
import SubscriptionDetail from 'components/integrations/RechargeWidgetCard/Subscriptions/Detail'
import OrderDetail from 'components/integrations/RechargeWidgetCard/Orders/Detail'
import UpcomingOrderDetail from 'components/integrations/RechargeWidgetCard/UpcomingOrders/Detail'

export default function RechargeDetail({
  navigatableState,
  integration,
  className,
}) {
  const { type } = navigatableState || {}

  return (
    <AnimateAutoHeight duration={300}>
      <NavigatableIntegrationWidgetCard.AnimatedLeftRight>
        <CSSTransition
          className="index"
          classNames="index"
          in={type === 'subscription'}
          timeout={300}
        >
          <SubscriptionDetail integration={integration} className={className} />
        </CSSTransition>
        <CSSTransition
          className="detail"
          classNames="detail"
          in={type === 'order'}
          timeout={300}
        >
          <OrderDetail integration={integration} className={className} />
        </CSSTransition>
        <CSSTransition
          className="detail"
          classNames="detail"
          in={type === 'charge'}
          timeout={300}
        >
          <UpcomingOrderDetail
            integration={integration}
            className={className}
          />
        </CSSTransition>
      </NavigatableIntegrationWidgetCard.AnimatedLeftRight>
    </AnimateAutoHeight>
  )
}
