import React, { useMemo } from 'react'
import { Image } from 'semantic-ui-react'

export default function ContactAvatar({
  children,
  className,
  fallback: Fallback = false,
  onClick,
  size = 64,
  src,
}) {
  const style = useMemo(() => ({ height: size, width: size }), [size])
  if (Fallback && !src) return <Fallback />
  return (
    <div className="ContactAvatar">
      <Image
        avatar
        className={className}
        onClick={onClick}
        src={src}
        style={style}
      />
      {children}
    </div>
  )
}
