import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const list = () => css`
  display: flex;
  justify-content: center;
  margin-left: ${rem(-20)};
  margin-right: ${rem(-20)};
  & > * {
    margin: 0 ${rem(55)};
    max-width: ${rem(280)};
  }
`

export const styles = {
  list,
}
