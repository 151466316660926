import { css } from '@emotion/react'
import Label from './Label'
import Row from './IndentedRow'
import RecipientsEditor from './RecipientsEditor'

const flexStyle = css`
  flex: 1 1 100%;
`

const BccRow = ({ className, isNote, ...rest }) => {
  const { ticketId } = rest
  return (
    <Row css={flexStyle} className={className}>
      <Label>Bcc:</Label>
      <RecipientsEditor
        dataTestId={'bcc-input'}
        key={`recipientseditor-bcc-${ticketId}`}
        className="grui ml-3"
        type="bcc"
        ticketId={ticketId}
        allowMultipleRecipients
        draftType={isNote ? 'note' : 'reply'}
        {...rest}
      />
    </Row>
  )
}

export default BccRow
