import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const base = theme => css`
  color: ${theme.color.monochrome.white};
  transition: 0.2s color;
  padding: ${rem(4)};
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }

  &.active,
  &:hover {
    svg {
      opacity: 1;
    }
  }
`

export const styles = {
  base,
}
