import React from 'react'
import { SearchBox, SUI, Divider } from 'shared/ui'
import ResizingScrollingList from './ResizingScrollingList'

const SearchableList = ({
  focusedIndex,
  onSearch,
  searchTerm,
  searchResults,
  ...rest
}) => {
  return (
    <div>
      <SearchBox
        className="grui ml-10 mt-4"
        onSearchChange={onSearch}
        placeholder="Search..."
        value={searchTerm}
      />
      <SUI>
        <Divider />
      </SUI>
      <ResizingScrollingList
        focusedIndex={focusedIndex}
        searchTerm={searchTerm}
        {...rest}
      />
    </div>
  )
}

export default SearchableList
