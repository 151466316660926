import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import ContactAvatar from 'components/crm/ContactAvatar'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { doMoveContactToCompany } from 'ducks/crm/companies/operations'

import MissingAvatar from '../MissingAvatar'

export default function ChangeCompanyListItem({
  contactId,
  companyId,
  domain,
  id,
  index,
  logoUrl,
  name,
  onClose,
}) {
  const dispatch = useDispatch()
  const handleClick = useCallback(
    () => {
      dispatch(doMoveContactToCompany(contactId, companyId, id))
      if (onClose) onClose()
    },
    [companyId, contactId, dispatch, id, onClose]
  )
  return (
    <li className="ChangeCompany-Company" onClick={handleClick}>
      {index === 0 && <ListenToKeyboard onEnter={handleClick} />}
      <ContactAvatar size={32} src={logoUrl} fallback={MissingAvatar} />
      <div className="ChangeCompany-Company-NameAndDomain">
        <h5>{name}</h5>
        <div>{domain}</div>
      </div>
    </li>
  )
}
