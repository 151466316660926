import { css } from '@emotion/react'

// NOTE (mbunsch) There is an issue in the product table that causes scroll bars to appear
// It is caused by the modals within rows - we have overflow-x set, but it also causes
// an overflow-y. So in this case, we need to disable overflows for the Table component.
// However, there is no way to set it via a prop, so we need this hack here.
const tableOverflowOverride = css`
  & > div > div {
    overflow: visible;
  }
`

const table = theme => css`
  /* Make numbers have the same size */
  font-variant-numeric: tabular-nums;

  tbody,
  thead {
    tr {
      & > *:first-of-type {
        padding-left: 21px !important;
      }
      & > .cell-price {
        text-align: right;
        padding-right: 16px;
      }
      & > *:last-of-type {
        padding-right: 24px;
      }
      & > .cell-total {
        text-align: right;
        span {
          justify-content: flex-end;
        }
      }
    }
  }

  tbody > tr {
    /* Remove the hover state from table */
    &:hover {
      background-color: transparent;
      &:nth-of-type(even) {
        background-color: ${theme.color.table.evenRowBg};
      }
      td:first-of-type {
        border-left-color: transparent;
      }
    }
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 0%;

      &:first-of-type {
        padding-right: 8px;
      }

      &.cell-image {
        padding-right: 0;
      }

      &.cell-product-name {
        max-width: 200px;
        width: auto;
      }

      &.cell-total,
      &.cell-price {
        width: auto;
        word-break: break-all;
        white-space: normal;
        min-width: 100px;
      }

      &.cell-price button {
        text-align: right;
      }
    }
  }
`

const priceCell = css`
  & > * {
    width: 100%;
    & > span {
      text-decoration: line-through;
    }
  }
`

const empty = css`
  tbody tr > td:last-of-type {
    text-align: left;
    height: 60px;
  }
`

const numberField = css`
  width: 99px;
  & > * {
    width: 100%;
  }
`

const controls = css`
  /* Overwrite rowControlsStyles */
  opacity: 1;
  visibility: visible;
  justify-content: flex-start;
  padding-right: 0;

  & > * {
    margin-left: 0 !important;
  }
`

export const styles = {
  table,
  tableOverflowOverride,
  empty,
  priceCell,
  numberField,
  controls,
}
