import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'

import { Tooltip } from 'shared/ui'
import { dumbLinkify } from 'util/strings'
import BodySearchMarker from '../BodySearchMarker'

class QuotedBody extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: !!props.collapsed,
      bodyNode: null,
    }
  }

  onBodyNode = node => {
    if (!node || !node.querySelectorAll) return
    this.setState({
      bodyNode: !node || !node.querySelectorAll ? null : node,
    })
  }

  toggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

  render() {
    const { collapsed } = this.state
    const { className, text } = this.props

    const collapsedBody = (
      <div
        className={cn(className, 'hidden-in-print')}
        onClick={this.toggleCollapsed}
      >
        <Tooltip
          bottom
          offsetY={-4}
          tooltip="Show trimmed content"
          className="Icon Icon-more-horizontal"
        />
      </div>
    )
    const regularBody = (
      <>
        <SanitizedHTML
          className="body"
          html={dumbLinkify(text, { showFullUrl: true })}
          onBodyNode={this.onBodyNode}
        />
        <BodySearchMarker node={this.state.bodyNode} />
      </>
    )
    return collapsed ? collapsedBody : regularBody
  }
}

export default styled(QuotedBody)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.monochrome.superLight};
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.primary.brand};
  }

  .Icon {
    font-size: 22px;
    margin-top: -4px;
    margin-bottom: -4px;
    padding-left: 1px;
    padding-right: 1px;
    color: ${props => props.theme.color.monochrome.mediumDark};
  }

  &:hover .Icon {
    color: ${props => props.theme.color.monochrome.white};
  }
`
