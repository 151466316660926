import { useCallback, memo } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'

import { styles } from '../styles'

const Tag = ({ tag, onClick, isLoading }) => {
  const handleClick = useCallback(
    () => {
      onClick(tag)
    },
    [tag, onClick]
  )

  return (
    <Tooltip
      title="Apply tag"
      position="top"
      strategy="fixed"
      disabled={isLoading}
    >
      <button
        className="grui py-3 px-5"
        css={[button.styles.preflight, styles.tag]}
        onClick={handleClick}
        disabled={isLoading}
      >
        <span>{tag.name}</span>
      </button>
    </Tooltip>
  )
}

export default memo(Tag)
