import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIsInChat } from 'selectors/location'
import { isMac } from 'util/browser'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { useAiDraftGenerationAction } from 'ducks/ai/hooks'
import HotkeysGroup from './HotkeysGroup'

const inboxActions = {
  r: 'Reply',
  f: 'Forward',
  n: 'Note',
  d: app.t('AI_draft'),
  [`${isMac() ? '⌘' : 'alt + '}/`]: `Insert ${app.t('canned_reply')}`,
  "'": 'Toggle right sidebar',
  ';': 'Expand all replies',
  ':': 'Collapsed all replies',
  [`${isMac() ? 'cmd' : 'ctrl'}+enter`]: 'Send',
}

const chatActions = {
  r: 'Reply',
  n: 'Note',
  "'": 'Toggle right sidebar',
  enter: 'Send',
}

export default function Conversation() {
  const { isAiDraftGenerationFeatureEnabled } = useAiDraftGenerationAction()
  const isInChat = useSelector(selectIsInChat)
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const actions = useMemo(
    () => {
      const baseActions = { ...(isInChat ? chatActions : inboxActions) }
      if (!isAdminOrOwnerOrAgent) {
        delete baseActions.r
        delete baseActions.f
        delete baseActions['⌘/']
        delete baseActions['alt + /']
      }
      if (!isAiDraftGenerationFeatureEnabled) {
        delete baseActions.d
      }

      return baseActions
    },
    [isInChat, isAdminOrOwnerOrAgent, isAiDraftGenerationFeatureEnabled]
  )
  return <HotkeysGroup title="Conversation" hotkeys={actions} />
}
