import React from 'react'

import themeVars from 'ui_theme/site/globals/site.variables'

import LinkNewTrelloCardModalView from './view'

export default class LinkNewTrelloCardModalForm extends React.PureComponent {
  componentWillUnmount = () => {
    clearTimeout(this.formResetTimeout)
  }

  doCloseModal = () => {
    this.formResetTimeout = setTimeout(
      this.props.resetForm,
      themeVars.defaultDuration // Allow sidebar close animation to complete
    )
    this.props.doCloseModal()
  }

  handleChange = (_, { name, value }) => {
    if (name === 'board') {
      this.props.doFetchTrelloBoardDetails(value)
    }
    return this.props.onChange(name, value, null, { validate: true })
  }

  handleDueDateChange = value => {
    return this.handleChange(null, { name: 'dueDate', value })
  }

  render() {
    return (
      <LinkNewTrelloCardModalView
        {...this.props}
        doCloseModal={this.doCloseModal}
        onChange={this.handleChange}
        onDueDateChange={this.handleDueDateChange}
      />
    )
  }
}
