import React from 'react'
import LoginLayout from 'components/LoginLayout'
import ForgotPasswordForm from './Form'

export default function ForgotPasswordPage() {
  return (
    <LoginLayout
      showLoginLink
      tagline="Enter the email address associated with your account, and we’ll email you a link to reset your password."
    >
      <ForgotPasswordForm />
    </LoginLayout>
  )
}
