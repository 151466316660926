import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_AGENT_ADD } from 'ducks/drawers/types'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { useSelector } from 'react-redux'

const NotFound = ({ onCloseAssignmentDropdown }) => {
  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_AGENT_ADD,
    lazyLoad: true,
  })

  const onOpen = useCallback(
    () => {
      if (onCloseAssignmentDropdown) onCloseAssignmentDropdown()

      openAddDrawer('new', { additionalProps: { title: 'Add Teammate' } })
    },
    [openAddDrawer, onCloseAssignmentDropdown]
  )

  const isAdminOrOwner = useSelector(selectCurrentUserIsAdminOrOwner)

  return (
    <div className="grui flex my-20 mx-10 items-center justify-center flex-col">
      <div css={text.styles.textBlack}>{`No ${app.t('agent')} found!`}</div>
      {isAdminOrOwner && (
        <div className="grui mt-4">
          <Button size="small" onClick={onOpen}>
            {`Add ${app.t('agent_with_article')}`}
          </Button>
        </div>
      )}
    </div>
  )
}

export default NotFound
