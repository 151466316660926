import React from 'react'
import { Label } from 'shared/ui'

export default function Header({ text = 'Tags' }) {
  return (
    <Label as="div" size="large" className="grui ml-12 mt-10">
      {text}
    </Label>
  )
}
