import ListenToKeyboard from 'components/ListenToKeyboard'
import { connect } from 'react-redux'
import { doNavigateToPinnedSearchForNumKey } from 'actions/app'
import { doOpenNewTicketPage } from 'actions/pages'
import { doShowHotkeysModal } from 'ducks/modals/hotkeys'
import { doAssignCurrentOrSelectedToCurrentUser } from 'actions/tickets'

const select = state => ({
  preventDefault: true,
  disableForInput: true,
})
const perform = {
  onShiftForwardSlash: doShowHotkeysModal,
  onShiftN: doOpenNewTicketPage,
  onG: doAssignCurrentOrSelectedToCurrentUser,
  onNum1: doNavigateToPinnedSearchForNumKey,
  onNum2: doNavigateToPinnedSearchForNumKey,
  onNum3: doNavigateToPinnedSearchForNumKey,
  onNum4: doNavigateToPinnedSearchForNumKey,
  onNum5: doNavigateToPinnedSearchForNumKey,
  onNum6: doNavigateToPinnedSearchForNumKey,
  onNum7: doNavigateToPinnedSearchForNumKey,
  onNum8: doNavigateToPinnedSearchForNumKey,
  onNum9: doNavigateToPinnedSearchForNumKey,
  onNum0: doNavigateToPinnedSearchForNumKey,
}

export default connect(select, perform)(ListenToKeyboard)
