import React from 'react'
import { css } from '@emotion/react'
import { BoxHeadline, Flex, Text } from 'shared/ui'
import { summarizeCount } from 'util/counts'

const flexStyle = css`
  flex: 1 0 10%;
`

function Name({ name }) {
  return (
    <Flex className="grui overflow-hidden">
      <BoxHeadline truncate>{name}</BoxHeadline>
    </Flex>
  )
}

// allow this since Name is a very simple component.

export default function LabelText({ name, count, showCount }) {
  if (!showCount) return <Name name={name} />

  return (
    <Flex className="grui overflow-hidden">
      <Name name={name} />
      <Flex css={flexStyle}>
        <Text size="small" className="grui ml-3">
          ({summarizeCount(count)})
        </Text>
      </Flex>
    </Flex>
  )
}
