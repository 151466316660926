import React, { useCallback, useEffect } from 'react'

import Sidebar from 'shared/components/ui/Sidebar'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Avatar, Button, Flex, Label, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Dropdown, Form, Input, TextArea } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import LabelOptionIcon from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/LabelOptionIcon'
import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import Login from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Login'
import Name from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Name'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'
import renderAssigneeLabel from './renderAssigneeLabel'
import renderLabelLabel from './renderLabelLabel'

// Quick performance hack, needs moving to css
const AVATAR_STYLE = {
  float: 'left',
  marginRight: themeVars.tiny,
}

export default function LinkNewGitHubIssueModal({
  areRepositoriesLoading,
  areRepositoryDetailsLoading,
  assignableUsers = [],
  doCreateAndLinkGitHubIssueToTicket,
  doFetchGitHubDetailsForRepository,
  isFormValid,
  formFields,
  formFields: {
    assignee = [],
    body = null,
    label = [],
    milestone = null,
    repository = null,
    repositoryOwner = null,
    title = null,
  },
  labels = [],
  milestones = [],
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
  resetFields,
  resetForm,
  repositories,
  repositoryOwners,
}) {
  const isLoading = areRepositoriesLoading || areRepositoryDetailsLoading
  const isOwnerChoosen = repositoryOwner && !areRepositoriesLoading
  const isRepositoryChoosen = repository && !areRepositoriesLoading

  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [resetForm, onClose]
  )
  const doSubmitResetAndClose = useCallback(
    () => {
      doCreateAndLinkGitHubIssueToTicket(formFields)
      doResetAndClose()
    },
    [doCreateAndLinkGitHubIssueToTicket, doResetAndClose, formFields]
  )
  const onChange = useCallback(
    (event, { name, value }) => {
      propOnChange(name, value, null, { validate: true })
    },
    [propOnChange]
  )
  useEffect(() => resetFields('repository'), [resetFields]) // If repoOwner changes reset repo field
  useEffect(
    () => {
      if (repository) doFetchGitHubDetailsForRepository(repository) // If repo changes fetch its details (assignable users, labels and milestones
      resetFields('label', 'milestone') // If repo changes reset label and milestone fields
    },
    [doFetchGitHubDetailsForRepository, resetFields, repository]
  )
  useEffect(
    // If assignee or assignableUsers changes remove any assignees aren't in assignableUsers
    () => {
      const validAssignees = assignee.filter(id =>
        assignableUsers.find(({ value }) => value === id)
      )
      if (
        !areRepositoryDetailsLoading &&
        repository &&
        validAssignees.length !== assignee.length
      ) {
        propOnChange('assignee', validAssignees)
      }
    },
    [
      areRepositoryDetailsLoading,
      assignee,
      assignableUsers,
      propOnChange,
      repository,
    ]
  )

  return (
    <React.Fragment>
      <Button basic onClick={onOpen} className="grui ml-4 mt-4">
        New
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="create"
            portal
            position="top"
            tooltip={
              !isFormValid && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!isFormValid}
              fluid
              key="link"
              onClick={doSubmitResetAndClose}
              primary
            >
              Create and link issue
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={doResetAndClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to New GitHub Issue"
        onClose={doResetAndClose}
        open={open}
        wide
      >
        <Sidebar.Content>
          <Form>
            {!areRepositoriesLoading && (
              <React.Fragment>
                <Flex>
                  <FormRow split>
                    <Label as="div">Repository Owner</Label>
                    <ValidatedField
                      fluid
                      name="repositoryOwner"
                      options={repositoryOwners}
                      onChange={onChange}
                      placeholder="- Please select -"
                      search
                      selection
                      selectOnNavigation={false}
                      validatedFieldComponent={Dropdown}
                      value={repositoryOwner}
                    />
                  </FormRow>

                  <FormRow split>
                    <Label as="div">Repository</Label>
                    <ValidatedField
                      disabled={!isOwnerChoosen}
                      fluid
                      name="repository"
                      options={repositories}
                      onChange={onChange}
                      placeholder="- Please select -"
                      search
                      selection
                      selectOnNavigation={false}
                      validatedFieldComponent={Dropdown}
                      value={repository}
                    />
                  </FormRow>
                </Flex>
                <FormRow>
                  <Label as="div">Title</Label>
                  <ValidatedField
                    fluid
                    name="title"
                    onChange={onChange}
                    validatedFieldComponent={Input}
                    value={title || ''}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Assignees <OptionalLabel>(optional)</OptionalLabel>
                  </Label>
                  <ValidatedField
                    disabled={
                      !isRepositoryChoosen || areRepositoryDetailsLoading
                    }
                    fluid
                    multiple
                    name="assignee"
                    options={assignableUsers.map(option => {
                      return {
                        ...option,
                        children: (
                          <React.Fragment>
                            <Avatar
                              url={option.avatarUrl}
                              size={24}
                              style={AVATAR_STYLE}
                            />
                            <Login>{option.login}</Login>{' '}
                            <Name>{option.name}</Name>
                          </React.Fragment>
                        ),
                      }
                    })}
                    onChange={onChange}
                    placeholder="- Please select -"
                    renderLabel={renderAssigneeLabel}
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={
                      isRepositoryChoosen &&
                      !areRepositoryDetailsLoading &&
                      assignee
                        ? assignee
                        : []
                    }
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Labels <OptionalLabel>(optional)</OptionalLabel>
                  </Label>
                  <ValidatedField
                    disabled={
                      !isRepositoryChoosen || areRepositoryDetailsLoading
                    }
                    fluid
                    multiple
                    name="label"
                    options={labels.map(option => {
                      return {
                        ...option,
                        icon: <LabelOptionIcon {...option} />,
                      }
                    })}
                    onChange={onChange}
                    placeholder="- Please select -"
                    renderLabel={renderLabelLabel}
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={label}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Milestone <OptionalLabel>(optional)</OptionalLabel>
                  </Label>
                  <ValidatedField
                    disabled={
                      !isRepositoryChoosen || areRepositoryDetailsLoading
                    }
                    fluid
                    name="milestone"
                    options={milestones}
                    onChange={onChange}
                    placeholder="- Please select -"
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={milestone}
                  />
                </FormRow>

                <FormRow>
                  <Label as="div">
                    Description <OptionalLabel>(optional)</OptionalLabel>
                  </Label>
                  <ValidatedField
                    name="body"
                    onChange={onChange}
                    validatedFieldComponent={TextArea}
                    value={body || ''}
                  />
                </FormRow>
              </React.Fragment>
            )}
          </Form>
        </Sidebar.Content>
        {isLoading && <Loader />}
      </Sidebar>
    </React.Fragment>
  )
}
