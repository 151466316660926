import { css } from '@emotion/react'
import { listGap } from '../List/styles'

const recipientSearch = css`
  flex-grow: 1;
  outline: 0;
  cursor: text;

  .row_content {
    display: flex;
    align-items: center;
  }

  .recipients {
    flex-grow: 1;
    flex-wrap: wrap;

    .sui.sui-transparent {
      flex-grow: 1;
      min-width: 0;
    }
  }

  &&& {
    .ui.dropdown > input:not(.search):first-of-type {
      /* override non 'search' SUI DDs that hide the input fields. Ideally we
     * should just move to standard SUI Search DDs. In the meantime, we need
     * this CSS override. */
      display: block !important;
    }
  }
`

const searchBox = css`
  background-color: inherit;
  border: 0;
  color: 'inherit';
  flex-grow: 1;
  font: inherit;
  margin: 0;
  min-width: 200px;
  width: 100%;
  outline: 0;
  padding: 0;

  &&& {
    margin: 0 -8px 0 0;
    padding: 0 8px;
    line-height: 26px;
  }

  ::placeholder {
    color: hsla(0, 0%, 75%, 1);
  }
`

const recipientDropdown = theme => css`
  &&& {
    align-items: center;
    color: ${theme.color.monochrome.black};
    cursor: pointer;
    /* flex/inline-flex will cut off items in InternalDesignSystemDropdown*/
    display: 'block';

    .dropdown {
      &.icon {
        /* SUI override for a bigger caret thats closer to icon */
        font-size: ${theme.fontSize.small} !important;
        margin-left: ${theme.spacing['4']} !important;
      }
    }

    [role='menuitem']:not(.highlighted):hover {
      background-color: transparent;
    }

    & > .menu.menu.menu {
      max-width: 350px;
      min-width: 0 !important;
    }
  }
`

const recipient = theme => css`
  .email {
    color: ${theme.color.monochrome.superDark};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.normal} !important; /* SUI override */
    margin-left: ${theme.spacing['3']};
  }
`

const truncated = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const recipientSearchContainer = css`
  width: 100%;
`

const copyButton = css`
  margin: -3px 0;
  path {
    fill: currentColor;
  }
`

const copyIcon = css`
  &&& {
    height: 17px;
    width: 17px;
  }
`

const cancelGap = css`
  &&& {
    margin-left: -${listGap}px;
  }
`

export const styles = {
  recipientSearch,
  searchBox,
  recipientDropdown,
  recipient,
  truncated,
  recipientSearchContainer,
  copyButton,
  copyIcon,
  cancelGap,
}
