import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_EMAIL_FORWARD } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import NoticeBar from 'components/NoticeBar'
import { selectMailboxByStates } from 'selectors/mailboxes/selectMailboxByStates'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { MIGRATING_STATE } from 'ducks/mailboxes/constants'

import styles from './styles.less'
import { styles as emotionStyles } from './styles'

export default function MailboxMigratingAlert({ isManager }) {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const migratingMailboxes = useSelector(state =>
    selectMailboxByStates(state, [MIGRATING_STATE])
  )
  const firstMailbox = migratingMailboxes[0] || {}
  const currentMailboxId = ticketListMailboxId || firstMailbox.id
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
    lazyLoad: true,
  })

  const onOpen = useCallback(
    () => {
      openAddDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', 'forwarding'),
        },
      })
    },
    [openAddDrawer, currentMailboxId, drawerId]
  )

  let callToActionContent = 'Ask your account owner or admin to migrate it.'
  if (isManager) {
    callToActionContent = (
      <>
        <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
          Migrate now
        </a>
        {' | '}
        <a
          href="https://help.groovehq.com/help/migrating-imap-and-microsoft-mailboxes-in-groove-to-use-email-forwarding"
          target="_blank"
          rel="noopener noreferrer"
          css={emotionStyles.learnMoreLink}
        >
          Learn more
        </a>
      </>
    )
  }

  return (
    <NoticeBar type="warning" css={emotionStyles.billingPlanLimitedAlert}>
      {`URGENT: Your email connection method needs to be changed by Friday, September 20th to continue receiving emails in Groove. `}
      {callToActionContent}
    </NoticeBar>
  )
}
