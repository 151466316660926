import React from 'react'
import cn from 'classnames'
import Modal from 'components/Modal'
import styles from './styles.css'

const TabbedModal = ({
  currentTab,
  selectTab,
  className,
  close,
  position,
  offset,
  children,
}) => {
  // filter out null, false, undefined
  const filteredChildren = children.filter ? children.filter(x => x) : children
  const tabs = React.Children.map(filteredChildren, (child, index) => ({
    index,
    name: child.props.name,
    content: child.props.children,
    activeByDefault: child.props.active,
    onSelect: child.props.onSelect,
  }))

  const isActive = tab => {
    const anyActiveByDefault = tabs.some(t => t.activeByDefault)

    return (
      tab.name === currentTab ||
      (!currentTab && tab.activeByDefault) ||
      (!currentTab && !anyActiveByDefault && tab.index === 0)
    )
  }

  const selectAndRunCallback = tab => {
    selectTab(tab.name)

    const callback = tab.onSelect
    if (typeof callback === 'function') callback(tab.name)
  }

  const createOnClick = tab => event => {
    event.preventDefault()
    event.stopPropagation()
    selectAndRunCallback(tab)
  }

  return (
    <Modal onBackgroundClick={close} position={position} offset={offset}>
      <div className={cn(className, styles.root)}>
        <div className={styles.tabs}>
          {tabs.map(tab => (
            <div
              key={`tab-${tab.name}`}
              className={cn(styles.tab, {
                [styles['tab-active']]: isActive(tab),
              })}
              onClick={createOnClick(tab)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <div className={styles.content}>
          {tabs.filter(isActive).map(tab => tab.content)}
        </div>
      </div>
    </Modal>
  )
}

export { default as Tab } from './Tab'
export { default as UserItem } from './UserItem'
export default TabbedModal
