import { connect } from 'react-redux'
import { doFetchTopLabels, doSearchLabels } from 'actions/labels'
import { selectIsTicketSearchSuggestions } from 'selectors/search_suggestions'
import {
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectCurrentCommittedTicketSearchQueryFinalPart,
} from 'selectors/search/base'
import {
  selectCurrentSubmittedTicketSearchFilterMatches,
  selectCurrentSubmittedTicketSearchQueryString,
} from 'selectors/search/searchFilters'
import SearchSuggestions from './view'

export default connect(
  state => ({
    finalPart: selectCurrentCommittedTicketSearchQueryFinalPart(state),
    isSuggestions: selectIsTicketSearchSuggestions(state),
    currentSubmittedTicketSearchFilterMatches: selectCurrentSubmittedTicketSearchFilterMatches(
      state
    ),
    currentSubmittedTicketSearchQueryString: selectCurrentSubmittedTicketSearchQueryString(
      state
    ),
    currentCommittedTicketSearchQueryCurrentPart: selectCurrentCommittedTicketSearchQueryCurrentPart(
      state
    ),
  }),
  { doFetchTopLabels, doSearchLabels }
)(SearchSuggestions)
