import React from 'react'
import { node, string } from 'prop-types'
import cn from 'classnames'

import { useVideoElement } from './hooks'

const VideoButton = ({ className, videoId, children, onClick }) => {
  useVideoElement(videoId)

  if (!videoId) return null

  return (
    <div
      className={cn(
        'wistia_embed',
        `wistia_async_${videoId}`,
        'popover=true',
        'popoverContent=link',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

VideoButton.propTypes = {
  className: string,
  videoId: string,
  children: node,
}

VideoButton.defaultProps = {
  className: undefined,
  videoId: undefined,
  children: undefined,
}

export default VideoButton
