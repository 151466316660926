import {
  doAuthenticateOnServer,
  doGoogleOauth,
  doOfficeOauth,
} from 'actions/auth'
import { MAIN_PAGE } from 'constants/pages'

export function doLogin(params) {
  return doAuthenticateOnServer(
    params.emailInput,
    params.passwordInput,
    params.subdomainInput
  )
}

export function doGoogleLogin() {
  return doGoogleOauth(MAIN_PAGE)
}

export function doOfficeLogin(type) {
  return doOfficeOauth(MAIN_PAGE, undefined, {
    service: type === 'outlook' ? 'Outlook' : undefined,
  })
}
