import cn from 'classnames'
import { css } from '@emotion/react'
import React from 'react'
import { useSelector } from 'react-redux'

import ContactAvatar from 'components/crm/ContactAvatar'
import { IconButton } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

import { selectAvatarUrl } from 'ducks/crm/contacts'
import ContactNameAndEmail from '../WidgetCards/cards/CustomFieldCategory/ContactHeaderFields/ContactNameAndEmail'
import useSelectCustomFieldValueForKeyAndType from '../WidgetCards/cards/CustomFieldCategory/useSelectCustomFieldValueForKeyAndType'
import ChatStatus from './ChatStatus'

export default React.memo(ContactDetailsColumnHeader)

const styles = () => theme => css`
  z-index: 1000;
  position: relative;
  padding-bottom: 25px;
  pointer-events: none;

  .shadow {
    pointer-events: auto;
    background: rgba(254, 254, 254, 0);
    display: flex;
    height: 53px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
    padding: 10px 32px 10px 22px;
    transition: background ${themeVars.defaultDuration},
      box-shadow ${themeVars.defaultDuration};
  }

  .ContactNameAndEmail {
    padding: 0 ${theme.spacing['3']};
    margin-top: -${theme.spacing['3']};
    margin-left: -16px;
    opacity: 0;
    transition: opacity ${themeVars.defaultDuration};
  }

  .sui & .avatar {
    border: 2px solid white;
    margin-top: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    transition: box-shadow ${themeVars.defaultDuration},
      height ${themeVars.defaultDuration}, width ${themeVars.defaultDuration},
      margin-top ${themeVars.defaultDuration};
    max-width: 54px;
  }
  .ContactAvatar {
    transition: transform ${themeVars.defaultDuration};
    position: relative;
  }

  &.isScrolled .shadow {
    background: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

    .ContactNameAndEmail {
      opacity: 1;
    }

    .avatar {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
    }
    .ContactAvatar {
      transform: scale(0.75) translate(-13px, -23px);
    }
  }

  .headerActions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
`

export const iconButtonStyle = () => theme => css`
  border: 1px solid ${theme.color.monochrome.medium};
  background: white;
  padding: 7px;
`

function ContactDetailsColumnHeader({ doToggleEditMode, isScrolled }) {
  const email = useSelectCustomFieldValueForKeyAndType(
    'contact_email',
    'CONTACT'
  )
  const avatarUrl = useSelector(state =>
    selectAvatarUrl(state, { categoryType: 'CONTACT' })
  )

  return (
    <div
      css={styles}
      className={cn(ContactDetailsColumnHeader, { isScrolled })}
    >
      <div className="shadow">
        <ContactAvatar email={email} size={54} src={avatarUrl}>
          <ChatStatus />
        </ContactAvatar>
        <ContactNameAndEmail />
        <div className="headerActions">
          <IconButton
            css={iconButtonStyle}
            className="editWidgetsButton"
            name="slidersVerticalAlt"
            onClick={doToggleEditMode}
            size="small"
            svg
          />
        </div>
      </div>
    </div>
  )
}
