import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_AGENT_ADD } from 'ducks/drawers/types'

const AddUserDrawerLink = ({ highlight, isSubMenuItem }) => {
  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_AGENT_ADD,
    lazyLoad: true,
  })

  const onOpen = useCallback(
    () => {
      openAddDrawer('new', {
        additionalProps: { title: `Add ${app.t('Agent')}` },
      })
    },
    [openAddDrawer]
  )

  return (
    <Menu.Item
      isSubMenuItem={isSubMenuItem}
      highlight={highlight}
      onClick={onOpen}
    >
      + Add {app.t('agents')}
    </Menu.Item>
  )
}

AddUserDrawerLink.propTypes = {
  highlight: PropTypes.bool,
}

AddUserDrawerLink.defaultProps = {
  highlight: false,
}

export default AddUserDrawerLink
