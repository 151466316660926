import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doOpen as openLightbox } from 'actions/lightbox'
import { selectAccountSubdomain } from 'selectors/app'
import { useAttachments } from 'ducks/integrations/attachments/hooks'
import { Loader } from 'shared/ui'
import { isImageMimeType } from 'util/mime'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import Attachment from './Attachment'
import { styles } from './styles'

const MAX_ATTACHMENTS = 4

export default function CurrentConversations({
  type,
  ticketId,
  allVisible,
  onToggleAllVisible,
  emptyText,
}) {
  const dispatch = useDispatch()
  const subdomain = useSelector(selectAccountSubdomain)
  const { attachments, isLoading } = useAttachments(ticketId, type)

  const attachmentsToShow = useMemo(
    () => {
      if (!attachments) {
        return []
      }

      if (allVisible) {
        return attachments
      }

      return attachments.slice(0, MAX_ATTACHMENTS)
    },
    [attachments, allVisible]
  )

  const imageAttachments = useMemo(
    () =>
      attachments.filter(attachment => isImageMimeType(attachment.fileType)),
    [attachments]
  )

  const onClick = useCallback(
    attachmentId => {
      const startingIndex = imageAttachments.findIndex(
        attachment => attachment.id === attachmentId
      )
      dispatch(openLightbox(imageAttachments, startingIndex))
    },
    [dispatch, imageAttachments]
  )

  if (isLoading) {
    return <Loader className="grui my-7" />
  }

  if (!attachments.length) {
    return (
      <div className="grui px-4 py-7">
        <EmptyMessage>{emptyText}</EmptyMessage>
      </div>
    )
  }

  return (
    <>
      <NavigatableIntegrationWidgetCard.List hideMargin>
        {attachmentsToShow.map(attachment => (
          <Attachment
            key={attachment.id}
            attachment={attachment}
            ticketId={ticketId}
            subdomain={subdomain}
            isImage={isImageMimeType(attachment.fileType)}
            onClick={onClick}
          />
        ))}
      </NavigatableIntegrationWidgetCard.List>

      {attachments.length > MAX_ATTACHMENTS &&
        !allVisible && (
          <a
            css={styles.showMore}
            className="grui"
            onClick={onToggleAllVisible}
            rel="noopener noreferrer"
          >
            + Show all attachments
          </a>
        )}
    </>
  )
}
