import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const HardDeleteButton = React.memo(({ deleteConversation }) => {
  return (
    <div className="hard-delete">
      <IconButton
        name="delete"
        className={cn('menu-button', 'text-button', 'danger')}
        labelClassName="label"
        onClick={deleteConversation}
        label="Delete forever"
      />
    </div>
  )
})

export default HardDeleteButton
