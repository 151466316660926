import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  doFetchJiraCloudLabels,
  selectCreateIssueTypeFieldsFieldMeta,
} from 'ducks/integrations/jiraCloud'
import {
  selectJiraAreLabelsLoading,
  selectJiraLabelsByQuery,
} from 'ducks/integrations/jiraShared'
import { LABELS } from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'

export default connect(
  (state, { formFields: { type: issueTypeId, project: projectId } }) => ({
    areLabelsLoading: selectJiraAreLabelsLoading(state, {
      source: 'jiraCloud',
    }),
    labels: selectJiraLabelsByQuery(state, { source: 'jiraCloud' }),
    fieldMeta: selectCreateIssueTypeFieldsFieldMeta(state, {
      projectId,
      issueTypeId,
      fieldKey: LABELS,
    }),
  }),
  { doFetchJiraCloudLabels }
)(LabelsFormField)

function search(results) {
  return results
}
function LabelsFormField({
  areLabelsLoading,
  doFetchJiraCloudLabels: fetchLabels,
  formFields: { label = [], type },
  isProjectChoosen,
  labels,
  onChange: propOnChange,
  siteId,
  resetFields,
  fieldMeta,
  projectId,
}) {
  const [labelSearch, setLabelSearch] = useState('')
  const onSearchChange = useCallback(
    (_, { searchQuery }) => {
      return setLabelSearch(searchQuery)
    },
    [setLabelSearch]
  )
  useEffect(
    () => {
      if (siteId) fetchLabels(siteId, labelSearch)
    },
    [fetchLabels, siteId, labelSearch]
  )

  useEffect(() => resetFields('label'), [type, siteId, projectId, resetFields])

  const onChange = useCallback(
    (...args) => {
      const { searchQuery } = args[1]
      propOnChange(...args)
      setLabelSearch(searchQuery || '')
    },
    [propOnChange, setLabelSearch]
  )

  // this field is not supported on JIRA for the project + issue type combo
  if (!fieldMeta) return null

  return (
    <FormRow>
      <Label as="div">
        Labels&nbsp;
        {!fieldMeta.required && <OptionalLabel>(optional)</OptionalLabel>}
      </Label>
      <ValidatedField
        additionLabel=""
        allowAdditions
        disabled={!isProjectChoosen}
        fluid
        loading={areLabelsLoading}
        multiple
        noResultsMessage={areLabelsLoading ? 'Loading...' : 'No results found.'}
        name="label"
        options={[...(labels[labelSearch] || []), ...label].map(l => ({
          text: l,
          value: l,
        }))}
        onChange={onChange}
        onSearchChange={onSearchChange}
        placeholder={areLabelsLoading ? 'Loading...' : '- Please select -'}
        search={search}
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={label}
      />
    </FormRow>
  )
}
