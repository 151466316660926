import React, { useCallback, useEffect } from 'react'

import Sidebar from 'shared/components/ui/Sidebar'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Button, Label, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Dropdown } from 'util/ui'

import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

export default function LinkExistingGitHubIssueModal({
  issues,
  doLinkGitHubIssueToTicket,
  doFetchGitHubIssuesForRepository,
  formFields: { issue = null, repositoryOwner = null, repository = null } = {},
  areRepositoriesLoading,
  areRepositoryIssuesLoading,
  repositories,
  repositoryOwners,
  resetForm,
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
}) {
  const isLoading = areRepositoriesLoading
  const isRepositoryOwnerChoosen = repositoryOwner && !areRepositoriesLoading
  const isRepositoryChoosen = repository

  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [resetForm, onClose]
  )

  const onChange = useCallback(
    (event, { name, value }) => {
      propOnChange(name, value)
    },
    [propOnChange]
  )
  useEffect(
    () => {
      if (repository) doFetchGitHubIssuesForRepository(repository)
    },
    [repository, doFetchGitHubIssuesForRepository]
  )

  return (
    <React.Fragment>
      <Button basic className="grui mt-4" onClick={onOpen}>
        Existing
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="link"
            portal
            position="top"
            tooltip={
              !issue && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!issue}
              fluid
              onClick={useCallback(
                () => {
                  doLinkGitHubIssueToTicket(issue)
                  doResetAndClose()
                },
                [doLinkGitHubIssueToTicket, doResetAndClose, issue]
              )}
              primary
            >
              Link card
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={doResetAndClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to Existing GitHub Issue"
        onClose={doResetAndClose}
        open={open}
      >
        <ModalContent>
          {!isLoading && (
            <React.Fragment>
              <FormRow>
                <Label as="div">Repository Owner</Label>
                <ValidatedField
                  fluid
                  name="repositoryOwner"
                  options={repositoryOwners}
                  onChange={onChange}
                  placeholder="- Please select -"
                  search
                  selection
                  selectOnNavigation={false}
                  validatedFieldComponent={Dropdown}
                  value={repositoryOwner}
                />
              </FormRow>
              <FormRow>
                <Label as="div">Repository</Label>
                <ValidatedField
                  disabled={!isRepositoryOwnerChoosen}
                  fluid
                  name="repository"
                  options={repositories}
                  onChange={onChange}
                  placeholder="- Please select -"
                  search
                  selection
                  selectOnNavigation={false}
                  validatedFieldComponent={Dropdown}
                  value={repository}
                />
              </FormRow>
              <FormRow>
                <Label as="div">Issue</Label>
                <ValidatedField
                  disabled={!isRepositoryChoosen || areRepositoryIssuesLoading}
                  fluid
                  name="issue"
                  options={issues}
                  onChange={onChange}
                  placeholder="- Please select -"
                  search
                  selection
                  selectOnNavigation={false}
                  validatedFieldComponent={Dropdown}
                  value={issue}
                />
              </FormRow>
            </React.Fragment>
          )}
          {(isLoading || areRepositoryIssuesLoading) && <Loader />}
        </ModalContent>
      </Sidebar>
    </React.Fragment>
  )
}
