import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { emptyArr, last } from 'util/arrays'

import SubsequentActions from './SubsequentActions'
import Rating from './Rating'
import { getAuthor } from './util'

const SubsequentActionsList = ({
  message,
  currentUserId,
  integrationSettings,
  maxCollapsibleActionsCount,
}) => {
  const messageChangeId = message.action.change.id
  const readReceipt = message.readReceipt
  const subsequentActions = message.subsequentActions || emptyArr
  let subsequentActionsCount = subsequentActions.length
  if (readReceipt) {
    subsequentActionsCount += 1
  }

  const showToggleCollapseActions =
    subsequentActionsCount > maxCollapsibleActionsCount

  const [isCollapsed, setIsCollapsed] = useState(showToggleCollapseActions)

  const onToggleClick = useCallback(
    () => {
      setIsCollapsed(!isCollapsed)
    },
    [isCollapsed]
  )

  const getSubsequentActions = useMemo(
    () => {
      if (!isCollapsed || !subsequentActions || !subsequentActions.length) {
        return subsequentActions
      }

      return [last(subsequentActions)]
    },
    [isCollapsed, subsequentActions]
  )

  return (
    <div className="subsequent-list">
      <Rating messageChangeId={messageChangeId} />
      {showToggleCollapseActions && (
        <div
          className="action subsequent toggle-expand-actions"
          onClick={onToggleClick}
        >
          <div className="contents">
            <div className="header">
              <div className="author">
                <div className="byline">
                  {isCollapsed ? 'Show' : 'Hide'} All Actions ({subsequentActionsCount -
                    1})
                </div>
              </div>
              <div className="right-side">
                <div className="datetime" />
              </div>
            </div>
          </div>
        </div>
      )}
      {readReceipt &&
        (!isCollapsed && (
          <div className="action subsequent">
            <div className="contents">
              <div className="header">
                <div className="author">
                  <div className="byline">
                    <span className="action-verb">Seen</span> by{' '}
                    {getAuthor(readReceipt, currentUserId)}
                  </div>
                </div>

                <div className="right-side">
                  <div className="datetime">{readReceipt.datetime}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <SubsequentActions
        actions={getSubsequentActions}
        currentUserId={currentUserId}
        integrationSettings={integrationSettings}
      />
    </div>
  )
}

SubsequentActionsList.propTypes = {
  maxCollapsibleActionsCount: PropTypes.number,
}

SubsequentActionsList.defaultProps = {
  maxCollapsibleActionsCount: 2,
}

export default SubsequentActionsList
