import { useMemo } from 'react'

import LinkedResourceActions from '../../LinkedResourceActions'

export default function ContactActions({ url }) {
  const actions = useMemo(
    () => [
      {
        blankTarget: true,
        text: ['View on Salesforce'],
        url,
      },
    ],
    [url]
  )
  return <LinkedResourceActions actions={actions} />
}
