import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useCharges } from 'ducks/integrations/stripe/hooks/charges'
import { isEmpty } from 'util/arrays'
import { Loader } from 'shared/ui'

import doFetchCharges from 'ducks/integrations/stripe/operations/doFetchCharges'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import ListItem from './ListItem'

export default function List({ integrationId, customerId }) {
  const dispatch = useDispatch()
  const { charges, isLoading, isFetchingMore, hasMore } = useCharges(
    integrationId,
    customerId
  )

  const onShowMoreClick = useCallback(
    () => {
      dispatch(
        doFetchCharges({
          integrationId,
          customerId,
          lastItemId: charges[charges.length - 1].id,
        })
      )
    },
    [dispatch, charges, integrationId, customerId]
  )

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Payments" />
      <NavigatableIntegrationWidgetCard.List hideMargin>
        {isLoading && <Loader className="grui mb-5" />}

        {isEmpty(charges) &&
          !isLoading && (
            <EmptyMessage className="grui p-7">
              No charges data to show here
            </EmptyMessage>
          )}
        {!isEmpty(charges) &&
          charges.map(charge => {
            return (
              <ListItem
                key={charge.id}
                charge={charge}
                customerId={customerId}
              />
            )
          })}

        {hasMore && (
          <NavigatableIntegrationWidgetCard.ShowMore
            text="Show more payments"
            loadingText="Fetching..."
            isLoading={isFetchingMore}
            onClick={onShowMoreClick}
          />
        )}
      </NavigatableIntegrationWidgetCard.List>
    </>
  )
}
