import { connect } from 'react-redux'
import { doShowHotkeysModal } from 'ducks/modals/hotkeys'
import { selectBillingProvider } from 'ducks/billing/selectors/selectBillingProvider'
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserSignupOlderThan,
} from 'ducks/currentUser/selectors/base'
import { selectIsInInbox } from 'selectors/location'
import { selectMenuNoticeCounts, selectMenuAlerts } from 'selectors/menus'

import { selectFlag } from 'ducks/flags/selectors'
import { doSetFlag } from 'ducks/flags/operations'
import { HAS_SEEN_INSTALL_APP_MENU } from 'ducks/flags/flagNames'
import { selectShowMobileAppsMenu } from 'ducks/currentUser/selectors/preferences/selectShowMobileAppsMenu'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import {
  FEATURE_INBOX_MAX_LIVE_CHAT_CHANNELS,
  FEATURE_INBOX_MAX_SOCIAL_CHAT_CHANNELS,
} from 'ducks/billing/featureTypes'
import { selectUsageForFeature } from 'ducks/billing/selectors/usage/selectUsageForFeature'

import View from './view'

const select = state => ({
  isInInbox: selectIsInInbox(state),
  noticeCounts: selectMenuNoticeCounts(state),
  alerts: selectMenuAlerts(state),
  currentUserIsAdminOrOwner: selectCurrentUserIsAdminOrOwner(state),
  isLiveChatEnabled:
    selectUsageForFeature(state, FEATURE_INBOX_MAX_LIVE_CHAT_CHANNELS) > 0,
  isSocialChatEnabled:
    selectUsageForFeature(state, FEATURE_INBOX_MAX_SOCIAL_CHAT_CHANNELS) > 0,
  billingProvider: selectBillingProvider(state),
  hasSeenInstallAppMenu: selectFlag(state)(HAS_SEEN_INSTALL_APP_MENU),
  // Whether the user has been a Groove customer for > than 2 weeks
  isCurrentUserSignupOlderThan2Weeks: selectCurrentUserSignupOlderThan(
    state,
    2,
    'weeks'
  ),
  showMobileAppsMenu: selectShowMobileAppsMenu(state),
  showUpgrade: selectIsFreemium(state),
})

const perform = dispatch => ({
  showHotkeysModal: () => dispatch(doShowHotkeysModal()),
  setSeenInstallAppMenu: () => dispatch(doSetFlag(HAS_SEEN_INSTALL_APP_MENU)),
})

export default connect(select, perform)(View)
