import { connect } from 'react-redux'

import logo from 'assets/integrations/salesforce/icon-24.png'

import withCardDataLoading from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/withCardDataLoading'
import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'
import doFetchMatchingObjects from 'ducks/integrations/salesforce/operations/doFetchMatchingObjects'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'
import { selectObjectStateByEmail } from 'ducks/integrations/salesforce/selectors'
import { selectIntegration } from 'selectors/userIntegrations'
import { emptyObj } from 'util/objects'

import View from './view'

const select = state => {
  const email = selectCurrentContactOrCustomerEmail(state)
  const objectState = selectObjectStateByEmail(state, email)
  return {
    credentials: selectIntegrationCredentialsForProvider(state, {
      provider: 'salesforce',
    }),
    canReload: objectState === 'failure',
    data: emptyObj,
    image: logo,
    title: 'Salesforce',
    openStateKey: 'salesforce',
    isLoading: objectState === 'fetching',
    isFailed: objectState === 'failure',
    isMissing: objectState === 'missing',
    selectedIntegration: selectIntegration(state),
  }
}

const perform = dispatch => ({
  load: email => {
    return dispatch(doFetchMatchingObjects(email))
  },
})

export default connect(select, perform)(
  withCardDataLoading(View, {
    skipSelectPhase: true,
    cardClassName: 'card-content',
    useInAppCardView: false,
  })
)
