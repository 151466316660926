import React from 'react'
import SubscriptionDetail from 'components/integrations/StripeWidgetCard/Subscriptions/Detail'
import ChargeDetail from 'components/integrations/StripeWidgetCard/Charges/Detail'

export default function StripeDetail({
  className,
  navigatableState,
  integration,
}) {
  const { type } = navigatableState || {}

  if (type === 'subscription') {
    return (
      <SubscriptionDetail integration={integration} className={className} />
    )
  }

  if (type === 'charge') {
    return <ChargeDetail integration={integration} className={className} />
  }

  return null
}
