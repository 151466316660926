import { useSelector } from 'react-redux'
import { selectCustomFieldValueForCustomFieldKeyAndCategoryType } from 'ducks/crm/contacts'

export default function useSelectCustomFieldValueForKeyAndType(
  key,
  type,
  subKey = 'content'
) {
  const customFieldValue = useSelector(state =>
    selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
      customFieldKey: key,
      categoryType: type,
    })
  )
  if (Array.isArray(customFieldValue?.value)) {
    return customFieldValue.value.map(value => {
      if (subKey) {
        return value && value[subKey]
      }
      return value
    })
  } else if (subKey) {
    return customFieldValue?.value && customFieldValue.value[subKey]
  }
  return customFieldValue?.value
}
