import React from 'react'
import styled from '@emotion/styled'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { Button, BoxHeadline } from 'shared/ui'
import { propFunc } from 'util/functions'

const StyledContainer = styled('div')`
  flex-grow: 1;
`
const SearchingEmptyState = ({ onCreateNewUser, className, css }) => {
  return (
    <StyledContainer className={className} css={css}>
      <ListenToKeyboard
        stopPropagation
        preventDefault
        onEnter={propFunc(onCreateNewUser)}
      />
      <BoxHeadline>No user found</BoxHeadline>

      <Button
        primary
        className="grui mt-4"
        onClick={propFunc(onCreateNewUser)}
        size="small"
      >
        Create a new user
      </Button>
    </StyledContainer>
  )
}

export default SearchingEmptyState
