import { useCallback } from 'react'
import { generateAutoLoginUrl } from 'util/auth'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import ChevronRight from '@groovehq/internal-design-system/lib/assets/icons/ChevronRight'
import { Form } from 'util/ui'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from './styles'

const LoginMultipleAccountsButton = ({
  subdomain,
  company,
  token,
  selectedSubdomain,
  setSelectedSubdomain,
}) => {
  const handleOnClick = useCallback(
    () => {
      setSelectedSubdomain(subdomain)
    },
    [setSelectedSubdomain, subdomain]
  )
  return (
    <Form.Field key={subdomain}>
      <Button
        type="tertiary"
        disabled={!!selectedSubdomain}
        as="a"
        href={generateAutoLoginUrl(subdomain, token)}
        onClick={handleOnClick}
        css={styles.button}
      >
        <span css={styles.text}>{company || subdomain}</span>

        {selectedSubdomain === subdomain ? (
          <Spinner css={styles.buttonIcon} />
        ) : (
          <ChevronRight css={styles.buttonIcon} />
        )}
      </Button>
    </Form.Field>
  )
}

export default LoginMultipleAccountsButton
