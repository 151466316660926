import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import cn from 'classnames'

import { selectIsEditorPinned } from 'ducks/editor/selectors'

import ScrollerContext from 'components/Scroller/Context'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import editor from 'shared/editor/utils'

import SizerContext from './Context'

const detectorStyles = css`
  user-select: none;
  pointer-events: none;
  visibility: hidden;
  height: 0px;
  width: 100%;
`

const Sizer = React.forwardRef(({ children, className, footerRef }, ref) => {
  const { getScrollerAPI } = useContext(ScrollerContext)
  const cursorQueueIdRef = useRef(null)
  const iObserverRef = useRef(null)
  const innerRef = useRef(null)
  const topDetectorRef = useRef(null)
  const bottomDetectorRef = useRef(null)
  const isEditorPinned = useSelector(selectIsEditorPinned)
  const isNewTicket = useSelector(selectIsComposingNewTicket)

  const rootMargin = isNewTicket ? 20 : -8
  const bottomDetectorOffset = rootMargin - 1

  useImperativeHandle(ref, () => innerRef.current)

  const handleIntersection = useCallback(
    entry => {
      const topDetector = topDetectorRef.current
      const bottomDetector = bottomDetectorRef.current
      const inner = innerRef.current

      if (!topDetector || !bottomDetector || !inner) return

      switch (entry.target) {
        case topDetector:
          inner.classList.toggle('floatingHeader', !entry.isIntersecting)
          break
        case bottomDetector:
          inner.classList.toggle('floatingFooter', !entry.isIntersecting)
          break
        default:
          throw new Error("Unhandled entry in Sizer's IntersectionObserver")
      }
    },
    [topDetectorRef, bottomDetectorRef, innerRef]
  )

  useEffect(
    () => {
      const container = getScrollerAPI()?.getElement()
      let iObserver = iObserverRef.current
      if (container && !iObserver && window.IntersectionObserver) {
        iObserver = new window.IntersectionObserver(
          entries => {
            entries.forEach(handleIntersection)
          },
          {
            root: container,
            threshold: [0, 1],
            rootMargin: `${rootMargin}px`,
          }
        )
        iObserverRef.current = iObserver
      }

      return () => {
        if (iObserver) {
          iObserver.disconnect()
        }
      }
    },
    [iObserverRef, getScrollerAPI, handleIntersection, rootMargin]
  )

  useEffect(
    () => {
      const iObserver = iObserverRef.current
      if (!iObserver) return
      const topDetector = topDetectorRef.current
      const bottomDetector = bottomDetectorRef.current
      if (topDetector) iObserver.observe(topDetector)
      if (bottomDetector) iObserver.observe(bottomDetector)
    },
    [iObserverRef, topDetectorRef, bottomDetectorRef]
  )

  const scrollIntoView = useCallback(
    (onlyIfNeeded = false) => {
      if (isEditorPinned) return
      const scrollerAPI = getScrollerAPI()
      const bottomDetector = bottomDetectorRef.current
      if (scrollerAPI && bottomDetector) {
        const { containerHeight, scrollTop } = scrollerAPI.getScrollDimensions()
        if (onlyIfNeeded) {
          const inner = innerRef.current
          if (inner) {
            const center = inner.offsetTop + inner.offsetHeight / 2
            if (center < scrollTop + containerHeight) {
              return
            }
          }
        }
        const y =
          bottomDetector.offsetTop -
          containerHeight +
          Math.abs(bottomDetectorOffset)
        scrollerAPI.scrollToY(y)
      }
    },
    [
      getScrollerAPI,
      bottomDetectorRef,
      innerRef,
      isEditorPinned,
      bottomDetectorOffset,
    ]
  )

  const handleEditorInit = useCallback(
    () => {
      // HACK (jscheel): We have to defer to let the render loop run first
      setTimeout(() => {
        scrollIntoView()
      }, 1)
    },
    [scrollIntoView]
  )

  const handleEditorChange = useCallback(
    () => {
      // NOTE (jscheel): This ensures that the cursor is fully in view when a
      // a user types or clicks. It tries to grab the cursor's rect and the
      // footer's rect, and adjusts the scroll position if there is an overlap.
      cursorQueueIdRef.current = window.requestAnimationFrame(() => {
        const activeEditor = editor.getEditor(editor.COMPOSER_ID)
        const scrollerAPI = getScrollerAPI()
        if (!scrollerAPI) return
        const container = scrollerAPI.getElement()
        if (!activeEditor || !container) return
        const range = activeEditor.selection?.getRng()
        const rects = range.getClientRects()
        if (!rects) return
        let selectionRect = rects[rects.length - 1]

        // HACK (jscheel): Get selection's closest ancestor if we can't calculate
        // the bounding rect from the current selection.
        if (
          (!selectionRect ||
            (selectionRect.width === 0 && selectionRect.height === 0)) &&
          // Fix for https://app.bugsnag.com/groove/frontend/errors/6441d1397fc7f80008e630a9?filters[event.since]=7d
          activeEditor.selection?.getRng()?.commonAncestorContainer
            ?.getBoundingClientRect
        ) {
          selectionRect = activeEditor.selection
            ?.getRng()
            ?.commonAncestorContainer?.getBoundingClientRect()
        }

        const actionsRect = footerRef.current?.getBoundingClientRect()
        if (!selectionRect || !actionsRect) return
        const overlap = selectionRect.bottom - actionsRect.top
        if (overlap > 0) {
          const newY = container.scrollTop + Math.round(overlap) + 10
          scrollerAPI.scrollToY(newY)
        }
      })
    },
    [cursorQueueIdRef, footerRef, getScrollerAPI]
  )

  useEffect(
    () => {
      const activeEditor = editor.getEditor()
      if (activeEditor) {
        activeEditor.on('init', handleEditorInit)
        activeEditor.on('focus', handleEditorChange)
        activeEditor.on('change', handleEditorChange)
        activeEditor.on('keyup', handleEditorChange)
        activeEditor.once('SetContent', handleEditorChange)
      }

      return () => {
        if (activeEditor) {
          activeEditor.on('init', handleEditorInit)
          activeEditor.on('off', handleEditorChange)
          activeEditor.off('change', handleEditorChange)
          activeEditor.off('keyup', handleEditorChange)
          activeEditor.off('SetContent', handleEditorChange)
        }
      }
    },
    [handleEditorChange, handleEditorInit]
  )

  useEffect(
    () => {
      const cursorQueueId = cursorQueueIdRef.current
      return () => {
        if (cursorQueueId) window.cancelAnimationFrame(cursorQueueId)
      }
    },
    [cursorQueueIdRef]
  )

  const context = useMemo(
    () => {
      return {
        scrollIntoView,
      }
    },
    [scrollIntoView]
  )

  return (
    <SizerContext.Provider value={context}>
      <div ref={innerRef} className={cn('inner', className)}>
        <div ref={topDetectorRef} css={detectorStyles} />
        {children}
        <div
          ref={bottomDetectorRef}
          css={[
            detectorStyles,
            css`
              margin-top: -${bottomDetectorOffset}px;
            `,
          ]}
        />
      </div>
    </SizerContext.Provider>
  )
})

export default Sizer
