import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Search from '@groovehq/internal-design-system/lib/components/Search/Search'
import { debounce } from 'util/functions'
import {
  doSearchProducts,
  selectProductsForQuery,
  selectCurrencyCode,
} from 'ducks/integrations/shopify'
import { selectProductsIsLoading } from 'ducks/integrations/shopify/selectors'

import { styles } from './styles'
import ProductSearchMenu from './Menu'

const ProductsSearch = React.memo(
  ({ className, onAddItem, orderItems, integration }) => {
    const dispatch = useDispatch()
    const [query, setQuery] = useState('')
    const [productQuery, setProductQuery] = useState('')

    const isSearching = useSelector(state =>
      selectProductsIsLoading(state, integration.id)
    )

    const searchProducts = useCallback(
      async productTitle => {
        await dispatch(doSearchProducts(integration.id, productTitle))
        setProductQuery(productTitle)
      },
      [integration.id, dispatch]
    )

    const delayedSearchProducts = useMemo(
      () => {
        return debounce(searchProducts, 300)
      },
      [searchProducts]
    )

    const handleChange = useCallback(
      evt => {
        const val = evt.target.value
        setQuery(val)
      },
      [setQuery]
    )
    const products = useSelector(state =>
      selectProductsForQuery(state, integration.id, { query: productQuery })
    )
    const currencyCode = useSelector(state =>
      selectCurrencyCode(state, integration.id)
    )

    const results = useMemo(
      () => {
        const variants = []
        products.forEach(product => {
          product.variants.forEach(variant =>
            variants.push({ variant, product })
          )
        })
        return variants
      },
      [products]
    )

    const handleAddItem = variantId => {
      const result = results.find(({ variant }) => variant.id === variantId)
      if (result) onAddItem(result.variant)
    }

    // fetch a default list of products on mount
    useEffect(() => {
      searchProducts('')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(
      () => {
        // this will result in a double trigger of searchProducts, but inside of the function
        // we check if the data is already loaded and skip execution if it is, so we dont need to
        // add any special checks here
        delayedSearchProducts(query)
      },
      [query, delayedSearchProducts]
    )

    return (
      <div className={className} css={styles.base}>
        <Dropdown
          overlay={
            <ProductSearchMenu
              results={results}
              orderItems={orderItems}
              currencyCode={currencyCode}
            />
          }
          onSelect={handleAddItem}
          emptyHint="No products found"
        >
          <Search
            onChange={handleChange}
            css={styles.search}
            placeholder="Search..."
            value={query}
            loading={isSearching}
          />
        </Dropdown>
      </div>
    )
  }
)

export default ProductsSearch
