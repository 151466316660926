/* eslint-disable no-underscore-dangle */
import { $applyNodeReplacement, TextNode } from 'lexical'
import { isDateSearchFilter } from 'util/search'
import getSearchQueryPartAtPosition from 'util/search/getSearchQueryPartAtPosition'

export class FilterNode extends TextNode {
  static getType() {
    return 'filter'
  }

  static clone(node) {
    return new FilterNode(node.__text, node.__valueId, node.__key)
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(text, valueId, key) {
    super(text, key)
    this.__valueId = valueId
  }

  createDOM(config) {
    const element = super.createDOM(config)
    element.classList.add(config.theme.filter)
    return element
  }

  getValueId() {
    return this.__valueId
  }

  // filterName: from:
  getFilterName() {
    return `${this.__text.split(':')[0]}:`
  }

  getFilterValue() {
    const { value } = getSearchQueryPartAtPosition(this.__text, 0)
    return value
  }

  static importJSON(serializedNode) {
    const node = $createFilterNode(serializedNode.text, serializedNode.valueId)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      valueId: this.getValueId(),
      type: 'filter',
    }
  }

  canInsertTextBefore() {
    return true
  }

  isTextEntity() {
    return true
  }
}

export function $createFilterNode(text = '', valueId) {
  // Optional valueId: for storing the id of the value of the filter

  // If the filter ends with a colon, we need to add quotes to the end of it
  let textWithQuotes = text
  if (/:$/.test(text)) {
    textWithQuotes = `${text}""`
  }
  const filterNode = new FilterNode(textWithQuotes, valueId)
  filterNode.select(textWithQuotes.length - 1, textWithQuotes.length - 1)
  return $applyNodeReplacement(filterNode)
}

export function $isFilterNode(node) {
  return node instanceof FilterNode
}

export function $isFromFilter(node) {
  if (!node) return false
  return $isFilterNode(node) && node.__text.startsWith('from:')
}

export function $isDateFilter(node) {
  if (!node || !$isFilterNode(node)) return false
  return isDateSearchFilter(node.__text)
}

export function $isDatesRangeFilter(node) {
  if (!$isDateFilter(node)) return false
  return node.__text.startsWith('between:')
}

export function $isMailboxFilter(node) {
  if (!node) return false
  return $isFilterNode(node) && node.__text.startsWith('inbox:')
}
