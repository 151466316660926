import React, { useCallback } from 'react'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function LinkedTrelloCardsRow({
  doNavigatableSetState,
  card,
  card: {
    loaded,
    board: { name: boardName } = {},
    list: { name: listName } = {},
    title,
  } = {},
}) {
  const onClick = useCallback(
    () => {
      doNavigatableSetState(card.id)
    },
    [doNavigatableSetState, card]
  )
  if (card && loaded)
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        onClick={onClick}
        title={title}
        subTitle={`${listName}${
          listName && boardName ? ' · ' : ''
        }${boardName}`}
      />
    )
  return null
}
