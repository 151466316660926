import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useController } from 'react-hook-form'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { sync as SyncIcon } from 'assets/icons/groove/v2'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import DateTimeInput from 'shared/components/ui/DateTimeInput'
import { SUI } from 'shared/ui'
import { doFetchPaymentTermsTemplates } from 'ducks/integrations/shopify/operations'
import {
  selectPaymentTermTemplatesIsLoading,
  selectPaymentTermTemplatesIsLoaded,
  selectPaymentTermTemplatesHasError,
  selectPaymentTermTemplatesForIntegrationId,
} from 'ducks/integrations/shopify/selectors'
import {
  PAYMENT_TERMS_TYPES,
  PAYMENT_TERMS_TYPES_DATE_PICKER_LABEL,
} from 'ducks/integrations/shopify/constants'
import {
  FORM_KEY_IS_PAYMENT_DUE_LATER,
  FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID,
  FORM_KEY_PAYMENT_SCHEDULES_DUE_AT,
  FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT,
  FORM_KEY_PAYMENT_TERMS_TYPE,
} from 'components/integrations/ShopifyV2WidgetCard/CreateOrderDrawer/util'
import { add, strftime } from 'util/date'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'

const PaymentTerm = ({
  disabled,
  watch,
  register,
  control,
  onChange,
  integrationId,
  customerId,
}) => {
  const { isCalculating, integration, isSending } = useShopifyDraft({
    integrationId,
    customerId,
  })
  const dispatch = useDispatch()

  const isLoading = useSelector(state =>
    selectPaymentTermTemplatesIsLoading(state, integration?.id)
  )
  const isLoaded = useSelector(state =>
    selectPaymentTermTemplatesIsLoaded(state, integration?.id)
  )
  const hasError = useSelector(state =>
    selectPaymentTermTemplatesHasError(state, integration?.id)
  )
  const templates = useSelector(state =>
    selectPaymentTermTemplatesForIntegrationId(state, integration?.id)
  )

  const fetchTemplates = useCallback(
    () => {
      if (!integration?.id) return

      dispatch(doFetchPaymentTermsTemplates(integration.id))
    },
    [dispatch, integration?.id]
  )

  useEffect(
    () => {
      if (isLoading || isLoaded || hasError) return

      fetchTemplates()
    },
    [dispatch, isLoaded, isLoading, hasError, fetchTemplates]
  )

  const {
    field: {
      onChange: onSelectedPaymentTermTemplateIdChange,
      onBlur: onSelectedPaymentTermTemplateIdBlur,
      value: selectedPaymentTermTemplateId,
    },
  } = useController({
    name: FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID,
    control,
  })

  const {
    field: { onChange: onSelectedPaymentTermsType },
  } = useController({
    name: FORM_KEY_PAYMENT_TERMS_TYPE,
    control,
  })

  const {
    field: {
      onChange: onSelectedPaymentsScheduleDueAtChange,
      value: selectedPaymentsScheduleDueAt,
    },
  } = useController({
    name: FORM_KEY_PAYMENT_SCHEDULES_DUE_AT,
    control,
  })

  const {
    field: {
      onChange: onSelectedPaymentsScheduleIssuedAtChange,
      value: selectedPaymentsScheduleIssuedAt,
    },
  } = useController({
    name: FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT,
    control,
  })

  const selectedPaymentTermTemplate = useMemo(
    () => {
      if (!selectedPaymentTermTemplateId) return null

      return templates.find(t => t.id === selectedPaymentTermTemplateId)
    },
    [templates, selectedPaymentTermTemplateId]
  )

  const handleDateChange = useCallback(
    (date, termType) => {
      const paymentTermsType =
        termType || selectedPaymentTermTemplate?.paymentTermsType

      if (!paymentTermsType) return

      // NOTE: on shopify, they always use the current time, regardless of date selected...
      // weird but we keep it that way as well

      // https://shopify.dev/api/admin-graphql/2022-01/mutations/draftOrderUpdate#field-draftorderinput-paymentterms
      if (paymentTermsType === PAYMENT_TERMS_TYPES.FIXED) {
        onSelectedPaymentsScheduleIssuedAtChange(null)
        onSelectedPaymentsScheduleDueAtChange(date)
      } else if (paymentTermsType === PAYMENT_TERMS_TYPES.NET) {
        onSelectedPaymentsScheduleIssuedAtChange(date)
        onSelectedPaymentsScheduleDueAtChange(null)
      } else {
        onSelectedPaymentsScheduleIssuedAtChange(null)
        onSelectedPaymentsScheduleDueAtChange(null)
      }

      onChange()
    },
    [
      selectedPaymentTermTemplate?.paymentTermsType,
      onSelectedPaymentsScheduleIssuedAtChange,
      onSelectedPaymentsScheduleDueAtChange,
      onChange,
    ]
  )

  const handleSelectedPaymentTermTemplateChange = useCallback(
    (_templateId, selectedTemplate) => {
      const { id, paymentTermsType } = selectedTemplate

      onSelectedPaymentTermTemplateIdChange(id)

      onSelectedPaymentTermsType(paymentTermsType)

      // reset date
      handleDateChange(new Date(), paymentTermsType)
    },
    [
      onSelectedPaymentTermTemplateIdChange,
      handleDateChange,
      onSelectedPaymentTermsType,
    ]
  )

  const paymentTermsMenu = useMemo(
    () => {
      return (
        <Dropdown.Menu>
          {templates.map(template => {
            const { id, description } = template
            return (
              <Dropdown.Menu.Item key={id} itemKey={id} item={template}>
                {description}
              </Dropdown.Menu.Item>
            )
          })}
        </Dropdown.Menu>
      )
    },
    [templates]
  )

  const dateTimeInputSelectedValue = useMemo(
    () => {
      switch (selectedPaymentTermTemplate?.paymentTermsType) {
        case PAYMENT_TERMS_TYPES.FIXED:
          return selectedPaymentsScheduleDueAt
        case PAYMENT_TERMS_TYPES.NET:
          return selectedPaymentsScheduleIssuedAt
        default:
          return null
      }
    },
    [
      selectedPaymentsScheduleDueAt,
      selectedPaymentsScheduleIssuedAt,
      selectedPaymentTermTemplate?.paymentTermsType,
    ]
  )

  const showDatePicker = [
    PAYMENT_TERMS_TYPES.FIXED,
    PAYMENT_TERMS_TYPES.NET,
  ].includes(selectedPaymentTermTemplate?.paymentTermsType)

  const selectedPaymentTermTemplateNotice = useMemo(
    () => {
      if (
        !selectedPaymentTermTemplate ||
        (showDatePicker && !dateTimeInputSelectedValue)
      ) {
        return null
      }

      const { paymentTermsType, name, dueInDays } = selectedPaymentTermTemplate

      let dueDate = dateTimeInputSelectedValue

      if (dueInDays) {
        dueDate = add(dateTimeInputSelectedValue, dueInDays, 'day')
      }

      const dueDateHuman = strftime('%B %d, %Y', dueDate)

      let dueNotice

      switch (paymentTermsType) {
        case PAYMENT_TERMS_TYPES.RECEIPT:
          dueNotice = 'Payment due when invoice is sent.'
          break
        case PAYMENT_TERMS_TYPES.NET:
          dueNotice = `Payment due on ${dueDateHuman} (${name}).`
          break
        case PAYMENT_TERMS_TYPES.FIXED:
          dueNotice = `Payment due on ${dueDateHuman}.`
          break
        default:
          dueNotice = null
          break
      }

      if (!dueNotice) return null

      return (
        <>
          <span css={text.styles.fontMedium}>{dueNotice}</span>&nbsp;
          <span>You’ll be able to collect payment from the order page.</span>
        </>
      )
    },
    [selectedPaymentTermTemplate, dateTimeInputSelectedValue, showDatePicker]
  )

  const onTogglePaymentDueLater = useCallback(
    e => {
      if (e?.currentTarget?.checked === false) {
        // clear payment terms and schedules
        onSelectedPaymentTermsType(null)
        onSelectedPaymentTermTemplateIdChange(null)
        onSelectedPaymentsScheduleIssuedAtChange(null)
        onSelectedPaymentsScheduleDueAtChange(null)
      } else if (templates?.length) {
        // select the first term template
        const template = templates[0]
        handleSelectedPaymentTermTemplateChange(template.id, template)
      }

      onChange()
    },
    [
      onSelectedPaymentTermTemplateIdChange,
      onSelectedPaymentsScheduleIssuedAtChange,
      onSelectedPaymentsScheduleDueAtChange,
      onSelectedPaymentTermsType,
      onChange,
      templates,
      handleSelectedPaymentTermTemplateChange,
    ]
  )

  const isPaymentDueLater = watch(FORM_KEY_IS_PAYMENT_DUE_LATER)

  return (
    <div className="grui mt-10">
      <div>
        <Checkbox
          id={FORM_KEY_IS_PAYMENT_DUE_LATER}
          {...register(FORM_KEY_IS_PAYMENT_DUE_LATER, {
            onChange: onTogglePaymentDueLater,
          })}
          disabled={disabled || isCalculating || isSending}
        >
          Payment due later
        </Checkbox>
      </div>
      {isPaymentDueLater && (
        <>
          <div className="grui flex mt-10">
            <div>
              <div css={fieldStyles.labelBox}>Payment terms</div>
              <Dropdown
                overlay={paymentTermsMenu}
                disabled={disabled || isLoading || !isLoaded}
                onSelect={handleSelectedPaymentTermTemplateChange}
                selectedKey={selectedPaymentTermTemplate?.id}
              >
                <Dropdown.Button onBlur={onSelectedPaymentTermTemplateIdBlur}>
                  <>
                    {isLoading && (
                      <i>
                        loading<AnimatedEllipsis />
                      </i>
                    )}
                    {isLoaded &&
                      (selectedPaymentTermTemplate?.description ||
                        'Please select...')}
                    {hasError && (
                      <>
                        ⚠️&nbsp;<i>Error loading terms</i>
                      </>
                    )}
                  </>
                </Dropdown.Button>
              </Dropdown>
            </div>
            {hasError && (
              <Tooltip title="An error occurred fetching payment terms from your store. Please click to reload">
                <Button
                  className="grui self-end ml-4"
                  size="small"
                  type="icon"
                  onClick={fetchTemplates}
                >
                  <SyncIcon />
                </Button>
              </Tooltip>
            )}
            {showDatePicker && (
              <SUI className="grui ml-10">
                <div css={fieldStyles.labelBox}>
                  {
                    PAYMENT_TERMS_TYPES_DATE_PICKER_LABEL[
                      selectedPaymentTermTemplate?.paymentTermsType
                    ]
                  }
                </div>
                <DateTimeInput
                  onChange={handleDateChange}
                  justDate
                  disabled={disabled}
                  value={dateTimeInputSelectedValue}
                />
              </SUI>
            )}
          </div>
          <div className="grui mt-10">{selectedPaymentTermTemplateNotice}</div>
        </>
      )}
    </div>
  )
}

export default PaymentTerm
