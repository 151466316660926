import { useMemo, useCallback } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

const UnsavedChangesDrawer = ({ onClose, onExit, open = false, onDiscard }) => {
  const handleDiscard = useCallback(
    () => {
      onDiscard()
      onExit()
    },
    [onDiscard, onExit]
  )

  const footer = useMemo(
    () => {
      return (
        <ModalBtns
          warningBtnText="Discard order"
          warningBtnHtmlType="submit"
          onClickWarningBtn={handleDiscard}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={onClose}
        />
      )
    },
    [onClose, handleDiscard]
  )

  return (
    <Drawer title="Cancel order" footer={footer} onClose={onExit} open={open}>
      <div className="grui pt-10">
        Closing the “Create order” drawer will discard your draft order. Are you
        sure you want to continue?
      </div>
    </Drawer>
  )
}

export default UnsavedChangesDrawer
