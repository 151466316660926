import { css } from '@emotion/react'

export const editorStyle = theme => css`
  &&& {
    font-family: ${theme.font.primary};
    font-size: 14px;
    color: ${theme.color.monochrome.black};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.4285em;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    padding: 0;
    overflow: visible;
    // https://www.bram.us/2021/07/23/prevent-unwanted-layout-shifts-caused-by-scrollbars-with-the-scrollbar-gutter-css-property/
    // For systems use classic scrollbars: Prevent unneeded layout changes as the content growing or shrinking causes the scrollbar to appear/disappear,
    // a space is reserved for it.
    scrollbar-gutter: stable;
    min-height: 60px;
  }

  & > .mce-content-body {
    min-height: 60px;
  }

  .mce-content-body {
    outline: 0;
    width: 100%;
    .mce-resizehandle {
      z-index: auto;
    }
  }

  .mce-btn button {
    padding: 4px 4px 1px;
  }

  a {
    color: ${theme.color.primary.brand};
    text-decoration: none;
  }

  span.mention {
    font-weight: bold;
    border: 1px solid #ccc;
    background-color: #eee;
    padding: 2px;
    padding-top: 0px;
    border-radius: 5px;
  }

  p {
    margin-top: 0;
    line-height: 17px;
    margin-bottom: 17px;
  }
`
