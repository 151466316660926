import React, { useMemo } from 'react'

import { getDisplayName } from 'util/hoc'

import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'

export default function withSidebarModalPositioning(WrappedComponent) {
  const WithSidebarModalPositioning = props => {
    const { bottom, left, right, top, ...rest } = props
    const offset = useMemo(() => ({ bottom, left, right, top }), [
      bottom,
      left,
      right,
      top,
    ])

    return <WrappedComponent offset={offset} {...rest} />
  }

  WithSidebarModalPositioning.displayName = `WithSidebarModalPositioning(${getDisplayName(
    WrappedComponent
  )})`

  return withMenuPositioning(WithSidebarModalPositioning, {
    defaultOffset: [0, 0],
    direction: 'left',
    includeHeight: false,
    keepOnScreen: 16,
  })
}
