import copyToClipboard from 'copy-to-clipboard'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { doShowSnackbar } from 'actions/snackbar'
import { Icon } from 'util/ui'

export default function ContactDetailsColumnCopyButton({ message, text }) {
  const dispatch = useDispatch()
  const onClick = useCallback(
    event => {
      event.stopPropagation()
      if (copyToClipboard(text)) {
        return dispatch(doShowSnackbar(message))
      }
      return dispatch(doShowSnackbar('Could not copy a link to the message.'))
    },
    [dispatch, text, message]
  )
  return (
    <div className="action" onClick={onClick}>
      <Icon name="copy" size="small" />
    </div>
  )
}
