import { css } from '@emotion/react'
import { Loader } from 'shared/ui'

const loaderStyle = css`
  margin-top: 10px;
`

export default function ContactDetailsColumnLoader() {
  return <Loader css={loaderStyle} />
}
