import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const labels = css`
  overflow: hidden;
  white-space: nowrap;
`

const labelsHidden = css`
  opacity: 0;
  pointer-events: none;
`

export const Container = styled('div')`
  background-color: ${props => props.color};
  border-radius: 4px;
  line-height: 1.73;
  font-size: 11px;
  padding: 1px 4px 1px 4px;
  color: ${props => props.theme.color.monochrome.white};
  margin-right: 8px;
  margin-top: 8px;
  background-color: ${props => props.color};

  @media print {
    color: ${props => props.theme.color.monochrome.black} !important;
    background-color: white !important;
    border: 1px solid currentColor;

    & * {
      color: inherit !important;
      background-color: inherit !important;
    }
  }
`

const tagWithLightColor = theme => css`
  border: 1px solid rgba(${theme.color.monochrome.black_rgba}, 0.1);
  color: ${theme.color.monochrome.black};

  svg {
    color: inherit;
  }
`

const closeIcon = theme => css`
  color: ${theme.color.monochrome.white};
  margin-left: 2px;
  opacity: 0.3;
  transition: opacity 0.2s ease-out;
  width: ${rem(16)};
  height: ${rem(16)};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media print {
    display: none;
  }
`

export const styles = {
  labels,
  labelsHidden,
  closeIcon,
  tagWithLightColor,
}
