import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { doFetchJiraServerProject } from 'ducks/integrations/jiraServer'
import {
  selectJiraAreProjectsLoading,
  selectJiraProjectsAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Avatar, Label } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

export default connect(
  state => ({
    areProjectsLoading: selectJiraAreProjectsLoading(state, {
      source: 'jiraServer',
    }),
    projects: selectJiraProjectsAsOptions(state, { source: 'jiraServer' }),
  }),
  { doFetchJiraServerProject }
)(ProjectFormField)

function ProjectFormField({
  areProjectsLoading,
  doFetchJiraServerProject: fetchJiraServerProject,
  onChange,
  formFields: { project },
  projects = [],
}) {
  useEffect(
    () => {
      fetchJiraServerProject(project)
    },
    [fetchJiraServerProject, project]
  )
  return (
    <FormRow split>
      <Label as="div">Project</Label>
      <ValidatedField
        disabled={areProjectsLoading}
        fluid
        name="project"
        options={projects.map(option => {
          return {
            ...option,
            children: (
              <React.Fragment>
                <Avatar
                  url={option.avatarUrl}
                  size={24}
                  style={{
                    float: 'left',
                    marginRight: themeVars.tiny,
                  }}
                />
                {option.text}
              </React.Fragment>
            ),
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={project}
      />
    </FormRow>
  )
}
