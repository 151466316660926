export const NEWEST = 'newest'
export const OLDEST = 'oldest'
export const NEWEST_BY_COLLABORATOR = 'newestByCollaborator'
export const OLDEST_BY_COLLABORATOR = 'oldestByCollaborator'
export const LONGEST_UNANSWERED = 'longestUnanswered'
export const NEWEST_BY_DELETED = 'newestByDeleted'
export const NEWEST_BY_CLOSED = 'newestByClosed'
export const NEWEST_BY_SPAM = 'newestBySpam'

export const DEFAULT_SORT_ORDER = NEWEST
export const DEFAULT_SORT_ORDER_BY_COLLABORATOR = NEWEST_BY_COLLABORATOR
