import React, { useEffect, useRef, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import emailParser from 'email-addresses'
import { ENTER, UP, DOWN, ESCAPE, BACKSPACE, COMMA, TAB } from 'constants/keys'
import { emptyArr, uniqByProp } from 'util/arrays'
import { stopEvent } from 'util/keys'
import { emptyFunc } from 'util/functions'
import { formatEmailAddressesStringForEmailParser } from 'ducks/drafts2/util'

import Dropdown from './Dropdown'
import SearchBox from './SearchBox'

import { FocusContext } from '../../Expanded/FocusContext'

const KEYS_IGNORED_ON_UP = [UP, DOWN, ESCAPE, COMMA, TAB]
const dropdownClassName = 'recipient-search-dropdown'

// Kevin R
// Documenting the expected behaviors here. Maybe some day we'll add automated tests :p

// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is false, and the user hits ENTER, add the loaded recipient, stop editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is false, and the user hits ENTER, add created recipient, stop editing, show name field if necessary
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is false, and the user hits TAB, add the loaded recipient, stop editing, show name field if necessary, move to next field
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is false, and the user hits TAB, add created recipient, stop editing, show name field if necessary, move to next field
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is false, and the user hits COMMA, then do nothign
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is false, and the user hits COMMA, then do nothing
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is false, and the user clicks outside the input, add the loaded recipient, stop editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is false, and the user clicks outside the input, add created recipient, stop editing

// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is false, and the user hits ENTER, add the selected recipient from the dropdown, stop editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is false, and the user hits ENTER, do nothing
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is false, and the user hits TAB, add the selected recipient from the dropdown, stop editing, move to next field
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is false, and the user hits TAB, continue editing
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is false, and the user hits COMMA, do nothing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is false, and the user hits COMMA, do nothing
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is false, and the user clicks outside the input, stop editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is false, and the user clicks outside the input, stop editing

// When the user has entered nothing and allowMultipleRecipients is false, and the user hits ENTER, do nothing
// When the user has entered nothing and allowMultipleRecipients is false, and the user hits TAB, stop editing move to next field
// When the user has entered nothing and allowMultipleRecipients is false, and the user hits COMMA, do nothing
// When the user has entered nothing and allowMultipleRecipients is false, and the user clicks outside the input, stop editing

// When the user has pasted a value and allowMultipleRecipients is false, and there is more than one email address, use the first email address

// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is true, and the user hits ENTER, add the loaded recipient and contrinue editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is true, and the user hits ENTER, add created recipient, contrinue editing
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is true, and the user hits TAB, add the loaded recipient and contrinue editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is true, and the user hits TAB, add created recipient, contrinue editing
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is true, and the user hits COMMA, add the loaded recipient and contrinue editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is true, and the user hits COMMA, add created recipient, contrinue editing
// When the user has entered a valid email address, and the user exists, and allowMultipleRecipients is true, and the user clicks outside the input, add the loaded recipient and stop editing
// When the user has entered a valid email address, and the user does not exists, and allowMultipleRecipients is true, and the user clicks outside the input, add created recipient, stop editing

// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is true, and the user hits ENTER, add the selected recipient from the dropdown, contrinue editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is true, and the user hits ENTER, do nothing
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is true, and the user hits TAB, add the selected recipient from the dropdown, contrinue editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is true, and the user hits TAB, move to next field
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is true, and the user hits COMMA, add the selected recipient from the dropdown, contrinue editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is true, and the user hits COMMA, do nothing
// When the user has entered a non-email string, and the user exists, and allowMultipleRecipients is true, and the user clicks outside the input, contrinue editing
// When the user has entered a non-email string, and the user does not exists, and allowMultipleRecipients is true, and the user clicks outside the input, contrinue editing

// When the user has entered nothing and allowMultipleRecipients is true, and the user hits ENTER, do nothing
// When the user has entered nothing and allowMultipleRecipients is true, and the user hits TAB, stop editing move to next field
// When the user has entered nothing and allowMultipleRecipients is true, and the user hits COMMA, do nothing
// When the user has entered nothing and allowMultipleRecipients is true, and the user clicks outside the input, stop editing

// When the user has pasted a value and allowMultipleRecipients is true, and there is more than one email address, add all the email addresses

const InputWithDropdown = React.forwardRef((props, searchBoxRef) => {
  const {
    allowCreateNewContactByName,
    onAdd,
    onBulkAdd,
    onRemove,
    onBlur,
    onFocus,
    recipients,
    saveOnTab,
    type,
    noAutoFocus,
    defaultValue,
    filtered,
    onEscape,
    allowMultipleRecipients,
    className,
    editing,
    isSearching,
    onChange,
    searchRecipients,
    upward,
    dataTestId,
  } = props

  const activeRecipientId = useRef(null)

  const { getFocusedRow } = useContext(FocusContext)
  const focusedRow = getFocusedRow()

  useEffect(
    () => {
      if (
        focusedRow === type &&
        searchBoxRef.current &&
        !noAutoFocus &&
        searchBoxRef.current.value === '' &&
        editing
      ) {
        searchBoxRef.current.focus()
      }
    },
    [noAutoFocus, searchBoxRef, editing, type, focusedRow]
  )

  const clearInput = useCallback(
    ({ noRefocus } = {}) => {
      if (searchBoxRef.current) {
        // eslint-disable-next-line no-param-reassign
        searchBoxRef.current.value = ''
        if (!noRefocus) searchBoxRef.current.focus()
      }
    },
    [searchBoxRef]
  )

  const parseAndSave = useCallback(
    (
      value,
      {
        noRefocus,
        recipientId,
        useRecipientWhenValueMatch = false,
        useRecipientByEmail = false,
      } = {}
    ) => {
      const recipientById = filtered.find(rep => rep.id === recipientId)

      const parsed = emailParser.parseAddressList({
        input: value,
        rejectTLD: true,
      })
      const recipientByEmail =
        parsed &&
        filtered.find(
          rep => rep.email?.toLowerCase() === parsed[0].address.toLowerCase()
        )

      const valueEmailMatchesRecipient =
        recipientById &&
        recipientById.email?.toLowerCase().startsWith(value?.toLowerCase())

      if (
        recipientById &&
        (useRecipientWhenValueMatch === false ||
          valueEmailMatchesRecipient ||
          // This handles a situation where the person has just typed a name and then hits TAB/ENTER/COMMA. In this
          // case we want to use the recipient by id as the assumption is that the person has selected someone
          // from the recommendations in the dropdown
          !parsed)
      ) {
        onAdd(recipientById)
        clearInput({ noRefocus })
        return true
      } else if (useRecipientByEmail && recipientByEmail) {
        onAdd(filtered[0])
        clearInput({ noRefocus })
      } else if (parsed) {
        const emails = parsed.map(email => {
          return {
            email: email.address,
            name: email.name,
          }
        })
        onBulkAdd(emails)
        clearInput({ noRefocus })
        return true
      } else if (value && allowCreateNewContactByName) {
        if (value.split(',').length === 1) {
          onAdd({ name: value })
          clearInput({ noRefocus })
          return true
        }
      }
      return false
      // Note clearInput shouldnt be at the end of this method to support situations
      // where no match is found at all and the enter basically does nothing and allows
      // the user to refine there search
    },
    [clearInput, onAdd, onBulkAdd, allowCreateNewContactByName, filtered]
  )

  const keyUpHandler = useCallback(
    e => {
      if (KEYS_IGNORED_ON_UP.indexOf(e.keyCode) > -1) return
      if (e.keyCode === ENTER) {
        stopEvent(e)
        const value = (searchBoxRef.current.value || '').trim()
        if (value) {
          parseAndSave(value, {
            useRecipientWhenValueMatch: true,
            recipientId: activeRecipientId.current,
          })
        }
        return
      }
      let searchType = type
      if (searchType === 'bcc') searchType = 'cc'
      searchRecipients(e.target.value, searchType)
    },
    [type, searchRecipients, parseAndSave, searchBoxRef]
  )

  const removeRecipient = useCallback(
    () => {
      if (!recipients || !recipients.length || !onRemove) return true
      if (recipients.length > 0 && searchBoxRef.current.value === '') {
        return onRemove(recipients[recipients.length - 1], recipients)
      }
      return true
    },
    [recipients, onRemove, searchBoxRef]
  )

  const keyDownHandler = useCallback(
    e => {
      const value = (searchBoxRef.current.value || '').trim()
      if (e.keyCode === ESCAPE) {
        stopEvent(e)
        clearInput()
        if (onEscape) {
          onEscape()
        }

        return false
      }
      if (allowMultipleRecipients && e.keyCode === BACKSPACE) {
        removeRecipient()
      }
      if (e.keyCode === COMMA) {
        if (e.shiftKey) return true
        stopEvent(e)
        if (!allowMultipleRecipients) return false
        parseAndSave(value, {
          noRefocus: true,
          useRecipientWhenValueMatch: true,
          recipientId: activeRecipientId.current,
        })
        return false
      }
      if (e.keyCode === TAB && saveOnTab) {
        const parseSuccess = parseAndSave(value, {
          recipientId: activeRecipientId.current,
          useRecipientWhenValueMatch: true,
        })
        if (parseSuccess) {
          if (allowMultipleRecipients || type === 'to') {
            stopEvent(e)
          }
        } else if (!allowMultipleRecipients && value) {
          stopEvent(e)
        }
        return false
      }
      return true
    },
    [
      allowMultipleRecipients,
      onEscape,
      saveOnTab,
      clearInput,
      parseAndSave,
      removeRecipient,
      searchBoxRef,
      type,
    ]
  )

  const pasteHandler = useCallback(
    e => {
      const contents = (e.clipboardData || window.clipboardData).getData('text')
      const parsed = emailParser.parseAddressList({
        input: formatEmailAddressesStringForEmailParser(contents),
        rejectTLD: true,
      })

      if (parsed) {
        const emails = parsed.map(email => ({
          email: email.address,
          name: email.name,
        }))
        onBulkAdd(uniqByProp(emails, 'email'))
        stopEvent(e)
      }
    },
    [onBulkAdd]
  )

  const handleSearchBoxBlur = useCallback(
    e => {
      if (e.relatedTarget?.closest(`.${dropdownClassName}`)) return false
      if (e.target.type === 'search' && e.target.value !== '') {
        parseAndSave(e.target.value, {
          noRefocus: true,
          useRecipientByEmail: true,
        })
      }
      if (onBlur) return onBlur(e)
      return false
    },
    [onBlur, parseAndSave]
  )

  const handleSearchBoxFocus = useCallback(
    e => {
      if (onFocus) onFocus(e)
    },
    [onFocus]
  )

  const handleRecipientClick = useCallback(
    (recipientId, evt = undefined) => {
      if (evt) stopEvent(evt)
      parseAndSave(searchBoxRef.current.value, {
        noRefocus: !allowMultipleRecipients,
        useRecipientWhenValueMatch: evt.type === 'keydown',
        recipientId,
      })
    },
    [allowMultipleRecipients, parseAndSave, searchBoxRef]
  )

  const handleOnActivate = useCallback(recipientId => {
    activeRecipientId.current = recipientId
  }, [])

  return (
    <Dropdown
      disabled={!editing}
      isSearching={isSearching}
      onClick={handleRecipientClick}
      onBlur={handleSearchBoxBlur}
      onActivate={handleOnActivate}
      recipients={filtered}
      input={searchBoxRef.current}
      trigger={
        <SearchBox
          data-test-id={dataTestId}
          className={className}
          defaultValue={defaultValue}
          ref={searchBoxRef}
          multiple={allowMultipleRecipients}
          onChange={onChange}
          onKeyDown={keyDownHandler}
          onKeyUp={keyUpHandler}
          onPaste={pasteHandler}
          onFocus={handleSearchBoxFocus}
          onBlur={handleSearchBoxBlur}
          placeholder="Search..."
          tabIndex="0"
        />
      }
      upward={upward}
      className={dropdownClassName}
    />
  )
})

InputWithDropdown.propTypes = {
  allowCreateNewContactByName: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  recipients: PropTypes.array.isRequired,
  saveOnTab: PropTypes.bool,
  type: PropTypes.oneOf(['to', 'cc', 'bcc']),
  noAutoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filtered: PropTypes.array,
  allowMultipleRecipients: PropTypes.bool,
  className: PropTypes.string,
  editing: PropTypes.bool,
  isSearching: PropTypes.bool,
  onChange: PropTypes.func,
  searchRecipients: PropTypes.func,
  upward: PropTypes.bool,
}

InputWithDropdown.defaultProps = {
  allowCreateNewContactByName: false,
  onAdd: emptyFunc,
  onBlur: emptyFunc,
  onFocus: emptyFunc,
  onRemove: emptyFunc,
  saveOnTab: true,
  type: undefined,
  upward: false,
  searchRecipients: emptyFunc,
  onChange: emptyFunc,
  isSearching: false,
  editing: false,
  className: '',
  allowMultipleRecipients: false,
  filtered: emptyArr,
  noAutoFocus: false,
  defaultValue: '',
}

export default InputWithDropdown
