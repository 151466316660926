import React from 'react'
import { useSelector } from 'react-redux'

import CollapsableContent from 'components/CollapsableContent'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

import {
  selectGitHubHiddenFields,
  selectGitHubNodesById,
  selectGitHubVisibleFields,
} from 'ducks/integrations/github'

import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import LinkedGitHubIssueFields from './LinkedGitHubIssueFields'
import { useGitHubIssueActions } from './hooks'

export default function LinkedGitHubIssue({
  className,
  doNavigatableClearState,
  navigatableState: issueId,
}) {
  const issue = useSelector(selectGitHubNodesById)[issueId]
  const hiddenFields = useSelector(selectGitHubHiddenFields)
  const visibleFields = useSelector(selectGitHubVisibleFields)
  const actions = useGitHubIssueActions(issue, doNavigatableClearState)
  const { id, title } = issue || {}

  if (!issue) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      onBack={doNavigatableClearState}
      title={title}
    >
      {visibleFields &&
        visibleFields.length > 0 && (
          <LinkedGitHubIssueFields fields={visibleFields} issue={issue} />
        )}
      <LinkedResourceActions id={id} actions={actions} />

      {hiddenFields &&
        hiddenFields.length > 0 && (
          <CollapsableContent
            animateHeight={false}
            hideTrigger="– Hide additional fields"
            showTrigger="+ Show additional fields"
          >
            <LinkedGitHubIssueFields fields={hiddenFields} issue={issue} />
          </CollapsableContent>
        )}
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
