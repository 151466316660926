import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Link from 'redux-first-router-link'

import { Button, Label } from 'shared/ui'
import { sortByName } from 'util/arrays'
import HiddenWidget from './HiddenWidget'
import { selectContactDetailsColumnWidgetCardsByKey } from './WidgetCards/selectors'

export default function EditModeControlWindow({
  doExitEditMode,
  hiddenCards,
  doShowItem,
}) {
  const cards = useSelector(selectContactDetailsColumnWidgetCardsByKey)

  const sortedHiddenCards = useMemo(
    () => {
      const hiddenCardsWithName = hiddenCards.reduce((accumulator, item) => {
        const card = cards[item.key]
        if (card) {
          accumulator.push({
            ...item,
            name: card.name,
            type: card.type,
          })
        }
        return accumulator
      }, [])
      return sortByName(hiddenCardsWithName)
    },
    [hiddenCards, cards]
  )

  return (
    <div className="EditModeControlWindow">
      <Label className="EditModeControlWindow-title" as="div" size="large">
        Cards
      </Label>

      <div className="EditModeControlWindow-widgets">
        {hiddenCards.length === 0 && <p>All widget cards are shown.</p>}
        {sortedHiddenCards.map(({ key, name, type }) => {
          return (
            <HiddenWidget doShowItem={doShowItem} key={key} uiKey={key}>
              {name || type}
            </HiddenWidget>
          )
        })}
      </div>
      <div className="EditModeControlWindow-more">
        <Button as="div" onClick={doExitEditMode} primary size="medium">
          Done
        </Button>
        <Button
          as={Link}
          basic
          onClick={doExitEditMode}
          primary
          size="medium"
          to="/settings/integrations"
        >
          Install more integrations
        </Button>
      </div>
    </div>
  )
}
