import React from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import SmileyIcon from '@groovehq/internal-design-system/lib/assets/icons/Smiley'

import EmojiDropdown from './Dropdown'

export default function EmojiPicker({ className, onSelectEmoji, direction }) {
  return (
    <EmojiDropdown
      className={className}
      direction={direction || 'left'}
      key="emoji-picker"
      onSelect={onSelectEmoji}
      trigger={
        <Tooltip title="Add an emoji" position="top">
          <Button type="icon" size="small">
            <SmileyIcon />
          </Button>
        </Tooltip>
      }
      upward
    />
  )
}
