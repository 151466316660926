import { useCallback } from 'react'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function CollectionRow({
  doNavigatableSetState,
  object,
  parentId,
}) {
  const onClick = useCallback(
    () => doNavigatableSetState(`${parentId}:${object.type}:${object.id}`),
    [doNavigatableSetState, parentId, object.type, object.id]
  )
  return (
    <NavigatableIntegrationWidgetCard.ListItem
      onClick={onClick}
      subTitle={
        object.properties?.StageName?.value || object.properties?.Status?.value
      }
      title={object.label}
    />
  )
}
