import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { nonEmbeddedAttachments } from 'util/changesets'
import Attachments from 'components/Attachments'
import { capture, record } from 'ducks/tracking/actions'
import { isCustomer } from 'ducks/customers/reducers'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import { reverseHashInt } from 'util/scatterSwap'
import { buildId } from 'util/globalId'
import Header from './Header'
import Body from './Body'
import TrackingDetails from './TrackingDetails'
import Addresses from './Addresses'
import { getAvatarComponent } from './util'

const ExpandedMessageContent = ({
  message,
  expandChangeset,
  currentUserId,
  isFirstMessage,
  showMeta,
  mailboxIndicatorNeeded,
  mailboxName,
  mailboxColor,
  onOpenImage,
  onCopyLinkClick,
  onPrintClick,
  isAgent,
}) => {
  const [isAddressSectionExpanded, setIsAddressSectionExpanded] = useState(
    false
  )

  const { openDrawer: openUpdateDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLIES_UPDATE
  )

  const onAiOptionSelected = useCallback(
    item => {
      if (item.id === 'instantReply') {
        const messageId = reverseHashInt(message?.action?.change?.id)
        const messageGid = buildId('Message', messageId)
        capture('ai instant_reply selected', {
          message_id: messageGid,
        })
        record()
        openUpdateDrawer('newAi', {
          additionalProps: {
            aiProps: {
              messageBody: message.body,
              messageSubject: message.title,
            },
          },
        })
      }
    },
    [openUpdateDrawer, message]
  )

  const toggleExpandAddressSection = useCallback(
    e => {
      if (e && e.stopPropagation) {
        // prevent expand/collapse of changeset
        e.stopPropagation()
      }
      // can only expand once. until actual changeset is expanded/collapsed/re-rendered
      if (!isAddressSectionExpanded) {
        setIsAddressSectionExpanded(true)
      }
    },
    [isAddressSectionExpanded]
  )

  const action = message.action
  const hasAttachments = message.hasAttachments
  const isFromMerge = message.isFromMerge
  const isForward = message.isForward
  const readReceipt = message.readReceipt

  const change = action.change
  const isNote = message.isNote
  const isBodyEmpty = change.isBodyEmpty
  const filtered = nonEmbeddedAttachments(change)
  const isIncomingMessage = isCustomer(action.actor)

  return (
    <React.Fragment>
      <div className="top-side" onClick={expandChangeset}>
        {getAvatarComponent({ action })}
        <div className="header-container">
          <Header
            action={action}
            currentUserId={currentUserId}
            isNote={isNote}
            isFirstMessage={isFirstMessage}
            isForward={isForward}
            hasAttachments={hasAttachments}
            isFromMerge={isFromMerge}
            hasRawEmail={message.hasRawEmail}
            onCopyLinkClick={onCopyLinkClick}
            onPrintClick={onPrintClick}
            readReceipt={readReceipt}
            onAiOptionSelected={onAiOptionSelected}
            showAIInstantReplyButton={isAgent}
            showMeta={showMeta}
          />
          {!isNote &&
            mailboxIndicatorNeeded && (
              <div className="mailbox-indicator">
                <div
                  className="dot"
                  style={{ backgroundColor: mailboxColor }}
                />
                <div className="name">{mailboxName}</div>
              </div>
            )}
          <div
            className={cn('address-section', {
              expanded: isAddressSectionExpanded,
            })}
            onClick={toggleExpandAddressSection}
          >
            <Addresses
              change={change}
              isNote={isNote}
              mailboxIndicatorNeeded={mailboxIndicatorNeeded}
              expanded={isAddressSectionExpanded}
              isIncomingMessage={isIncomingMessage}
              isForward={isForward}
            />
          </div>
        </div>
      </div>
      <div className="middle-side">
        <Body
          change={change}
          isForward={isForward}
          isFirstMessage={isFirstMessage}
          onOpenImage={onOpenImage}
        />
      </div>
      <div className="bottom-side">
        <Attachments
          attachments={isBodyEmpty ? change.attachments : filtered}
        />
        {isFirstMessage && <TrackingDetails />}
      </div>
    </React.Fragment>
  )
}

export default ExpandedMessageContent
