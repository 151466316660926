import React from 'react'

import Form from './LabelForm'

const CreateOrUpdateForm = ({ initialValues, isNew, ...rest }) => {
  if (isNew) {
    return (
      <Form
        initialValues={initialValues}
        headerText="Create a tag"
        saveButtonText="Create a tag"
        {...rest}
      />
    )
  }
  return (
    <Form
      initialValues={initialValues}
      headerText="Edit tag"
      subText={`This tag has ${
        initialValues.labelingsCount
      } conversations associated with it.`}
      saveButtonText="Save changes"
      {...rest}
    />
  )
}

export default CreateOrUpdateForm
