import { doFetchTrelloBoards } from 'ducks/integrations/trello'
import { Flex, Label } from 'shared/ui'
import { useActionEffect } from 'util/hooks'

import LinkExistingTrelloCardModal from './LinkExistingTrelloCardModal'
import LinkNewTrelloCardModal from './LinkNewTrelloCardModal'

export default function LinkTrelloCard() {
  useActionEffect(doFetchTrelloBoards, [])
  return (
    <div className="LinkTrelloCard">
      <Label size="small">Link Conversation To Issue</Label>
      <Flex>
        <LinkExistingTrelloCardModal />
        <LinkNewTrelloCardModal />
      </Flex>
    </div>
  )
}
