export const DEFAULT_BREAKPOINTS = [615, 668, 888]

export const NEW_SIDEBAR_SIZES = {
  maxSize: 400,
  minSize: 280,
  size: 320,
  resize: 'dynamic',
}
export const DEFAULT_WIDTHS = {
  '(max-width: 1365px)': [
    { minSize: 350, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
  '(min-width: 1366px) and (max-width: 1919px)': [
    { minSize: 500, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
  '(min-width: 1920px)': [
    { minSize: 900, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
}
export const STORAGE_KEY = '2-columns-widths-v2'
