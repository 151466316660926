const FIELD_MAP = {
  assignee: 'issueAssigneeChanged',
  Component: 'issueComponentsChanged',
  duedate: 'issueDueDateChanged',
  issuetype: 'issueTypeChanged',
  labels: 'issueLabelsChanged',
  priority: 'issuePriorityChanged',
  project: 'issueProjectChanged',
  status: 'issueStatusChanged',
  summary: 'issueSummaryChanged',
}

export default function shouldShowIssueAction(
  {
    change: {
      action: {
        changelog: { items: [{ field, toString }] = [{}] } = {},
        issue_event_type_name: eventTypeName,
        webhookEvent,
      },
    },
  },
  settings
) {
  if (!settings) return false
  if (webhookEvent === 'comment_created')
    return settings['showAction.issueCommentAdded']
  if (webhookEvent === 'jira:issue_deleted')
    return settings['showAction.issueDeleted']
  if (webhookEvent === 'jira:issue_updated') {
    if (eventTypeName === 'issue_commented') return true
    if (field === 'resolution') {
      return toString
        ? settings['showAction.issueResolved']
        : settings['showAction.issueUnresolved']
    }
    return settings[`showAction.${FIELD_MAP[field]}`]
  }
  return false
}
