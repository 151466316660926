import { connect } from 'react-redux'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'

const MailboxLabel = connect((initialState, initialProps) => state => {
  const mailbox = selectMailbox(state, initialProps.mailboxId)
  return {
    label: mailbox ? mailbox.name : null,
  }
})(({ label }) => <React.Fragment>{label}</React.Fragment>)

export default MailboxLabel
