import React from 'react'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'

const FulfillmentRow = ({ fulfillment: { trackingInfo } }) => {
  if (!trackingInfo) return null

  return (
    <>
      {trackingInfo.map(({ company, url, number }) => (
        <div key={`${number}-${company}`} className="groupedValue">
          <div className="value singleline">
            {company},{' '}
            <a href={url} target="_blank" rel="noopener noreferrer">
              {number}
              <ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          </div>
        </div>
      ))}
    </>
  )
}

export default FulfillmentRow
