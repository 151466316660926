import React from 'react'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import styled from '@emotion/styled'

const stopPropagation = ev => ev.stopPropagation()

const CloseIcon = ({ className, to }) => {
  let target = to
  let onClick = stopPropagation
  if (typeof to === 'function') {
    onClick = to
    target = null
  }
  return (
    <Link
      className={cn(className, 'Icon Icon-close')}
      to={target}
      onClick={onClick}
      tabIndex="-1"
      redirect // i.e. behave like a modal: back button shouldn't take us back here
    />
  )
}

export default styled(CloseIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSize.x2large};
  color: ${props =>
    props.theme.color.monochrome.mediumDark} !important; /* or sui.a borks it */
  background-color: ${props => props.theme.color.monochrome.white};
  border-radius: ${props => props.theme.spacing['3']};
  cursor: pointer;
  z-index: 1;

  &:hover {
    transition: color 0.2s ease-in-out;
  }
`
