import { connect } from 'react-redux'

import {
  selectIsAddingNote,
  selectIsReplyingOrForwarding,
  selectIsForwarding,
} from 'selectors/page'

import {
  selectIsTwitterTicket,
  selectIsFacebookTicket,
} from 'selectors/tickets/type'

import { doChangeEditorFocus } from 'ducks/editor'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'

import Footer from './view'

const perform = dispatch => {
  return {
    onEditorFormFocus: () => dispatch(doChangeEditorFocus(true)),
    onEditorFormBlur: () => dispatch(doChangeEditorFocus(false)),
  }
}

const select = state => ({
  isReplyFormVisible: selectIsReplyingOrForwarding(state),
  isAddingNote: selectIsAddingNote(state),
  isForwarding: selectIsForwarding(state),
  isTwitter: selectIsTwitterTicket(state),
  isFacebook: selectIsFacebookTicket(state),
  is3ColumnView: selectPrefersClassicView(state),
})

export default connect(select, perform)(Footer)
