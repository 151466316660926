import React from 'react'
import { getByline } from './util'

export default class SubsequentActions extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { actions } = this.props

    const ids = actions.map(a => a.id)
    const nextActions = nextProps.actions
    const should =
      ids.length !== nextActions.length ||
      nextActions.some((a, idx) => ids[idx] !== a.id)

    return should
  }

  render() {
    const { currentUserId, actions, integrationSettings } = this.props
    const children = []
    actions.forEach(action => {
      const datetime = action.datetime
      const byLine = getByline({ action, currentUserId, integrationSettings })
      if (byLine) {
        children.push(
          <div key={action.id} className="action subsequent">
            <div className="contents">
              <div className="header">
                <div className="author">{byLine}</div>

                <div className="right-side">
                  <div className="datetime">{datetime}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    })

    return <React.Fragment>{children}</React.Fragment>
  }
}
