import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { TextArea } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

export default function DescriptionFormField({
  formFields: { description },
  onChange,
}) {
  return (
    <FormRow>
      <Label as="div">Description</Label>
      <ValidatedField
        name="description"
        onChange={onChange}
        validatedFieldComponent={TextArea}
        value={description || ''}
      />
    </FormRow>
  )
}
