import React from 'react'
import styled from '@emotion/styled'
import { SUI, Dropdown } from 'shared/ui'
import { propFunc } from 'util/functions'

export const option = (doChangeMailbox, ticketIds) => mailbox => {
  return {
    text: mailbox.label, // for searchInMenu
    description: `<${mailbox.email}>`, // for searchInMenu
    key: mailbox.id,
    onClick: propFunc(doChangeMailbox, ticketIds, mailbox.id),
    value: mailbox.id,
  }
}

class BulkChangeInboxDropdown extends React.PureComponent {
  state = { isOpen: false }
  onOpen = () => {
    this.setState({ isOpen: true })
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const {
      className,
      doChangeMailbox,
      mailboxes,
      conversationIds,
      trigger,
      ...rest
    } = this.props
    const { isOpen } = this.state
    if (!isOpen) return <span onClick={this.onOpen}>{trigger}</span>

    return (
      <SUI>
        <Dropdown
          open
          className={className}
          header={`Change ${app.t('Mailbox')}`}
          icon={null}
          noResultsText={`No ${app.t('mailboxes')} found`}
          options={mailboxes.map(option(doChangeMailbox, conversationIds))}
          placeholder="Filter by name, email..."
          searchInMenu // CAVEAT - if you pass this, options need text[/description] (not just content)
          selectOnBlur={false}
          trigger={trigger}
          onClose={this.onClose}
          {...rest}
        />
      </SUI>
    )
  }
}

export default styled(BulkChangeInboxDropdown)`
  > .menu {
    max-width: 320px;
  }
`
