/* eslint-disable jsx-a11y/label-has-for */

import React, { PureComponent } from 'react'
import { css } from '@emotion/react'
import cn from 'classnames'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { propFunc } from 'util/functions'
import { styles as checkboxStyles } from 'components/ConversationList/styles'
import {
  snooze as SnoozeIcon,
  edit as EditIcon,
  file as FileIcon,
  arrowLeft as ArrowLeft,
} from 'assets/icons/groove/v2'
import { LONGEST_UNANSWERED } from 'constants/defaults'
import TextSearchMarker from 'components/TextSearchMarker'
import LabelBox from './LabelBox'
import ExpiryLabel from './ExpiryLabel'

const avatarComponent = agent => {
  if (!agent) return null
  const avatarUrl = agent.avatarUrl
  const actorInitials = agent.initials
  const styles = { backgroundImage: `url(${avatarUrl})` }
  return (
    <div className="avatar" style={styles}>
      {!avatarUrl && actorInitials}
    </div>
  )
}

const metaContent = ({
  hasCollision,
  collidingAgent,
  collisionType,
  collisionOperation,
  assignmentLabel,
  shouldShowLabels,
  expiryMins,
  isExpirable,
  labelsJSON,
  labelIds,
  isUnread,
  active,
}) => {
  if (hasCollision) {
    return (
      <div className={cn('collision', collisionType)}>
        {avatarComponent(collidingAgent)}
        <div className="chip">
          <span className="name">
            {collidingAgent && collidingAgent.shortName}
          </span>{' '}
          {collisionOperation}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="assignment">{assignmentLabel || 'Unassigned'}</div>
      {shouldShowLabels && (
        <LabelBox
          isUnread={isUnread}
          labelsJSON={labelsJSON}
          labelIds={labelIds}
        />
      )}
      {isExpirable && (
        <div className="with-expiry-label">
          <ExpiryLabel remainingMins={expiryMins} isActive={active} />
        </div>
      )}
    </>
  )
}

const snippetContent = ({
  snippetAuthor,
  snippetText,
  snippetType,
  hasDraft,
  isSnoozed,
  snoozedUntilLabel,
  unansweredSinceLabel,
  unansweredSinceDurationLevel,
  currentSortOrder,
}) => {
  const isUnansweredSort = LONGEST_UNANSWERED === currentSortOrder
  const showAuthor = !hasDraft && snippetAuthor
  const showUnansweredSinceLabel = !!unansweredSinceLabel && isUnansweredSort

  if (isSnoozed) {
    return (
      <div className="snippet-content">
        {showUnansweredSinceLabel && (
          <span
            className={cn('unanswered-since', unansweredSinceDurationLevel)}
          >
            {unansweredSinceLabel}
          </span>
        )}
        <span className="snoozeduntil">
          <SnoozeIcon className="icon snooze" />
          {snoozedUntilLabel}
        </span>
      </div>
    )
  }
  return (
    <div className="snippet-content">
      <div className={cn('snippet', snippetType)}>
        {showUnansweredSinceLabel && (
          <span
            className={cn('unanswered-since', unansweredSinceDurationLevel)}
          >
            {unansweredSinceLabel}
          </span>
        )}
        {showAuthor && (
          <span className="author">
            {snippetType &&
              snippetType === 'internal' && <FileIcon className="icon note" />}
            <span>{snippetAuthor}</span>
            {snippetType === 'internal' ? (
              ': '
            ) : (
              <ArrowLeft className="arrow" />
            )}
          </span>
        )}
        {hasDraft && (
          <span className="author">
            <EditIcon className="icon draft" />Draft:{' '}
          </span>
        )}
        <span className={cn('snippetText', { empty: !snippetText })}>
          {snippetText ? (
            <TextSearchMarker text={snippetText} />
          ) : (
            'There is no content in this message'
          )}
        </span>
      </div>
    </div>
  )
}

const mailboxIndicator = mailboxColor => css`
  background-color: ${mailboxColor};
`

class InnerListItem extends PureComponent {
  onClick = e => {
    const { conversationId, navigateToConversation, draftType } = this.props
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    e.preventDefault()
    e.stopPropagation()
    navigateToConversation(conversationId, draftType)
    return false
  }

  onCheckboxClick = e => {
    e.stopPropagation()
    const { conversationId, toggleConversationSelection } = this.props
    toggleConversationSelection(conversationId)
  }

  onCloseOrOpenClick = e => {
    const {
      conversationId,
      isCloseable,
      openConversation,
      closeConversation,
    } = this.props
    e.stopPropagation()
    e.preventDefault()
    // eslint-disable-next-line no-unused-expressions
    isCloseable
      ? closeConversation(conversationId)
      : openConversation(conversationId)
  }

  onUnreadIndicatorClick = e => {
    const { conversationId, isUnread, markAsRead, markAsUnread } = this.props
    e.stopPropagation()
    e.preventDefault()
    // eslint-disable-next-line no-unused-expressions
    isUnread ? markAsRead(conversationId) : markAsUnread(conversationId)
  }

  handleUnsooze = () => {
    const { conversationId, unsoozeConversation } = this.props
    unsoozeConversation(conversationId)
  }

  render() {
    if (!this.props.present) return null
    const {
      assignmentLabel,
      customerLabelFull,
      messageCount,
      subject,
      subjectEmptyPlaceholderText,
      snippetText,
      snippetAuthor,
      snippetType,
      isCloseable,
      timestamp,
      shouldShowLabels,
      expiryMins,
      isExpirable,
      hasCollision,
      collidingAgent,
      collisionType,
      collisionOperation,
      hasAttachments,
      isStarred,
      mailboxColor,
      hasDraft,
      labelsJSON,
      labelIds,
      selected,
      isSnoozed,
      snoozedUntilLabel,
      isUnread,
      canBulkSelect = true,
      linkUrl,
      active,
      conversationId,
      unansweredSinceLabel,
      unansweredSinceDurationLevel,
      currentSortOrder,
      canPerformQuickActions,
    } = this.props
    return (
      <>
        <div
          className={cn('right-side', {
            'can-bulk-select': !!canBulkSelect,
            'can-perform-quickactions': !!canPerformQuickActions,
          })}
        >
          <div className="quick-actions">
            {canPerformQuickActions &&
              isSnoozed && (
                <span
                  role="button"
                  onClick={this.handleUnsooze}
                  className="grui mx-5"
                >
                  Unsnooze
                </span>
              )}
            {canPerformQuickActions && (
              <span
                role="button"
                onClick={this.onCloseOrOpenClick}
                className="grui mr-7"
              >
                {isCloseable ? 'Close' : 'Open'}
              </span>
            )}
            {canBulkSelect && (
              <Checkbox
                id={`conversation-${conversationId}`}
                onChange={this.onCheckboxClick}
                className="dataTestConversationListItem_bulkIndicator"
                css={[
                  checkboxStyles.checkbox,
                  isUnread && checkboxStyles.checkboxInUnreadTicket,
                  active && checkboxStyles.checkboxInActiveTicket,
                ]}
                checked={selected}
                withRadius
              />
            )}
          </div>
          <div className="datetime">{timestamp}</div>
        </div>
        {/* don't copy div.right-side (or any element with an event) into anchor tag below
            Because, inner element's click event will also trigger <a> clicked event causing page to navigate
            https://bugzilla.mozilla.org/show_bug.cgi?id=62151 */}
        <a href={linkUrl} onClick={this.onClick}>
          {mailboxColor && (
            <div className="mailbox-indicator">
              <div
                className="inner"
                css={propFunc(mailboxIndicator, mailboxColor)}
              />
            </div>
          )}
          {canPerformQuickActions && (
            <div
              className="unread-indicator"
              onClick={this.onUnreadIndicatorClick}
            />
          )}
          <div className="header">
            <div className="customer">{customerLabelFull}</div>
            {hasAttachments && <span className="Icon Icon-attachment" />}
            &nbsp;
            {isStarred && <span className="Icon Icon-star" />}
            <div className="icons" />
          </div>
          <div className="message">
            <div className="contents">
              <div className={cn('subject', { empty: !subject })}>
                {subject ? (
                  <TextSearchMarker text={subject} />
                ) : (
                  subjectEmptyPlaceholderText
                )}
              </div>
              {snippetContent({
                snippetAuthor,
                snippetText,
                snippetType,
                hasDraft,
                isSnoozed,
                snoozedUntilLabel,
                unansweredSinceLabel,
                unansweredSinceDurationLevel,
                currentSortOrder,
              })}
            </div>
            {messageCount > 0 && (
              <div className="count">
                {messageCount > 99 ? '99+' : messageCount}
              </div>
            )}
          </div>
          <div className="meta">
            <div className="meta-content">
              {metaContent({
                hasCollision,
                collidingAgent,
                collisionType,
                collisionOperation,
                assignmentLabel,
                shouldShowLabels,
                labelsJSON,
                labelIds,
                isUnread,
                expiryMins,
                isExpirable,
                active,
              })}
            </div>
          </div>
        </a>
      </>
    )
  }
}

export default InnerListItem
