import React from 'react'
import LogConversationPage from 'components/App/DesktopView/Pages/LogConversationPage'
import NewConversationPage from 'components/App/DesktopView/Pages/NewConversationPage'
import TicketLayout from 'components/App/DesktopView/Layout/Ticket'
import BulkDetailPane from 'components/App/DesktopView/Bulk/DetailPane'
import ListInspector from '../../ListInspector'

const Switcher = ({
  areTicketsSelected,
  isBulkSelectionMode,
  isClassicView,
  componentName,
  ...rest
}) => {
  if (componentName === 'NewConversationPage')
    return <NewConversationPage {...rest} />

  if (componentName === 'LogConversationPage')
    return <LogConversationPage {...rest} />

  if (isBulkSelectionMode && !isClassicView) {
    if (!areTicketsSelected) return <ListInspector />

    return <BulkDetailPane />
  }

  return <TicketLayout {...rest} />
}

export default Switcher
