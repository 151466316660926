import { css } from '@emotion/react'

const base = css`
  & > * {
    max-width: auto !important;
  }
  & > .reason {
    max-width: 25rem;
  }
`

export const styles = { base }
