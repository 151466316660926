import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'

import { Label } from 'shared/ui'

const Tab = styled(Label)`
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${props =>
      props.theme.color.monochrome
        .black} !important; /* because Typography does it */
  }

  &.active {
    color: ${props =>
      props.theme.color.primary
        .brand} !important; /* because Typography does it */
    cursor: default;
  }
`

const Header = ({
  className,
  onGroupTabClick,
  groupActive = false,
  selectedGroupName = null,
  onCurrentTabClick = null,
}) => {
  return (
    <div className={className}>
      <Tab
        className={cn({ active: !groupActive })}
        onClick={onCurrentTabClick}
        size="large"
      >
        {selectedGroupName || app.t('Agents')}
      </Tab>
      <Tab
        className={cn({ active: groupActive })}
        onClick={onGroupTabClick}
        size="large"
      >
        {app.t('Teams')}
      </Tab>
    </div>
  )
}

export default styled(Header)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding-bottom: 8px;
  padding: 16px 24px 0px;

  ${Tab} + ${Tab} {
    margin-left: 16px;
  }
`
