import React, { Fragment } from 'react'

import { SUI } from 'shared/ui'
import withListController from 'components/App/DesktopView/shared/withListController'
import SearchBar from './SearchBar'
import LabelList from './LabelList'
import styles from './styles.css'

const SearchableList = props => {
  const {
    focusedIndex,
    onSearch,
    searchTerm,
    doBeginManageLabels,
    mode,
    isAdmin,
  } = props
  return (
    <Fragment>
      <SearchBar
        onSearch={onSearch}
        searchTerm={searchTerm}
        doBeginManageLabels={doBeginManageLabels}
        isAdmin={isAdmin}
      />
      <SUI className={styles.suiList}>
        {(mode === 'bulk' || Boolean(searchTerm)) && (
          <LabelList
            focusedIndex={focusedIndex}
            searchTerm={searchTerm}
            {...props}
          />
        )}
      </SUI>
    </Fragment>
  )
}

const SearchableListWithListController = withListController(SearchableList)

export default SearchableListWithListController
