import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import ListenToKeyboard from 'components/ListenToKeyboard'
import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import withOpenState from 'shared/ui/withOpenState'
import withReduxOpenTracking from './withReduxOpenTracking'
import Menu from './Menu'

const Dropdown = ({
  bottom,
  left,
  hotKey, // e.g. 'onT'
  onOpen,
  open,
  right,
  top,
  trigger,
  ...rest
}) => {
  const dynamicProps = hotKey ? { [hotKey]: onOpen } : {}

  return (
    <div onClick={!open ? onOpen : undefined}>
      {!open &&
        hotKey && (
          <ListenToKeyboard
            disableForInput
            preventDefault
            key="keyboard-listener"
            {...dynamicProps}
          />
        )}
      {trigger}
      <Menu open={open} {...rest} offset={{ left, right, top, bottom }} />
    </div>
  )
}

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  hotKey: PropTypes.string,
}

Dropdown.defaultProps = {
  hotKey: null,
}

export default compose(
  withReduxOpenTracking,
  withOpenState,
  withMenuPositioning
)(Dropdown)
