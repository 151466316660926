import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import DatePicker from 'shared/components/ui/DatePicker'
import { DATE_QUERY_COMMAND } from './PlainTextEditor/DatePlugin'
import { styles } from './styles'

const DatePickerPane = ({ value, setValue, isDatesRangeFilter }) => {
  const [editor] = useLexicalComposerContext()
  const [month, setMonth] = useState(
    () => value.date || value.startDate || new Date()
  )
  const disabledDays = useMemo(() => {
    return { after: new Date() }
  }, [])

  const handleConfirm = useCallback(
    () => {
      editor.dispatchCommand(DATE_QUERY_COMMAND, { value })
    },
    [editor, value]
  )

  const handleCancel = useCallback(
    () => {
      editor.dispatchCommand(DATE_QUERY_COMMAND)
    },
    [editor]
  )

  const handleDateChange = useCallback(
    (dateValue, modifiers) => {
      if (modifiers?.disabled) return
      const newValue = {
        ...value,
      }
      if (isDatesRangeFilter) {
        delete newValue.date
        if (newValue.startDate && newValue.endDate) {
          newValue.startDate = dateValue
          delete newValue.endDate
        } else if (newValue.startDate && dateValue < newValue.startDate) {
          newValue.endDate = newValue.startDate
          newValue.startDate = dateValue
        } else if (newValue.startDate) {
          newValue.endDate = dateValue
        } else {
          newValue.startDate = dateValue
        }
      } else {
        newValue.date = dateValue
        delete newValue.endDate
        delete newValue.startDate
      }
      setValue(newValue)
    },
    [setValue, value, isDatesRangeFilter]
  )

  const pendingOrEndDate = useMemo(
    () => {
      const date = value.endDate
      if (date && date instanceof Date && date > new Date()) {
        return new Date()
      }

      return date
    },
    [value.endDate]
  )

  useEffect(
    () => {
      setMonth(value.date || value.startDate || new Date())
    },
    [value.date, value.startDate]
  )

  return (
    <>
      <div className="grui overflow-x-auto">
        <DatePicker
          displayNumberOfMonths={2}
          onDayClick={handleDateChange}
          selectedDate={value.date}
          css={styles.datePicker}
          disabledDays={disabledDays}
          initialMonth={month}
          toMonth={disabledDays.after}
          month={month}
          onMonthChange={setMonth}
          selectedRangeEnd={pendingOrEndDate}
          selectedRangeStart={value.startDate}
        />
      </div>
      <div className="grui py-8 px-10">
        <Button size="small" type="info" onClick={handleConfirm}>
          Confirm
        </Button>
        <Button
          size="small"
          type="tertiary"
          className="grui ml-8"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </>
  )
}

export default DatePickerPane
