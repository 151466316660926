import { css, keyframes } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const DROPDOWN_WIDTH = 400

const shake = keyframes`
  0%{
    transform: rotate(20deg);
    opacity: 0.6;
  }
  0.2%, 0.6%, 1%, 1.4%, 1.6% {
    transform: rotate(0);
    opacity: 1;
  }
  /* We only rotate once in three min */
  0.4%, 0.8%, 1.2%, 1.6%, 1.8%, 100% {
    transform: rotate(20deg);
    opacity: 0.6;
  }
`

const back = keyframes`
  0%{
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0);
  }
`

const menu = theme => css`
  background-color: ${theme.color.monochrome.white};
  box-shadow: 0 8px 16px 0 rgba(${theme.color.monochrome.black_rgba}, 0.1);
  border-radius: ${rem(8)};
  max-width: ${rem(DROPDOWN_WIDTH)};
  width: ${rem(DROPDOWN_WIDTH)};
`

const header = theme => css`
  border-bottom: 1px solid ${theme.color.monochrome.medium};
  position: sticky;
  top: 0;
  background-color: ${theme.color.monochrome.white};
  border-top-right-radius: ${rem(8)};
  border-top-left-radius: ${rem(8)};
`

const list = theme => css`
  border-bottom-right-radius: ${rem(8)};
  border-bottom-left-radius: ${rem(8)};
  max-height: ${rem(510)};
  padding-bottom: ${rem(12)};

  .active button {
    background-color: rgba(${theme.color.monochrome.mediumDark_rgba}, 0.05);
  }
`

const heading = theme => css`
  display: flex;
  align-items: center;
  color: ${theme.color.info['900']};
  font-weight: ${theme.fontWeight.medium};
`

const btnSetting = css`
  svg {
    width: ${rem(14)};
    height: ${rem(14)};
  }

  &:hover {
    opacity: 1;
  }
`

const btnSeenAll = theme => css`
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.color.brand['900']};
  width: auto;
  height: ${rem(32)};
  font-weight: ${theme.fontWeight.medium};
`

const tabs = theme => css`
  padding-bottom: ${rem(6)};

  .tabs-ink-bar {
    height: ${rem(2)};
    background-color: ${theme.color.brand['900']};
  }

  button {
    font-size: ${theme.fontSize.small};
    padding-left: ${rem(6)};
    padding-right: ${rem(6)};
    margin-right: ${rem(6)};
    margin-left: ${rem(6)};
  }

  .active {
    color: ${theme.color.brand['900']};
  }
`

const notification = theme => css`
  background-color: ${theme.color.monochrome.white};
  border-bottom: 1px solid ${theme.color.monochrome.medium};
  display: flex;
  width: 100%;
  padding: ${rem(12)} ${rem(8)} ${rem(15)};
  text-align: left;
  border-left: ${rem(4)} solid transparent;
`

const isUnseen = theme => css`
  background-color: ${theme.color.monochrome.superLight};
  border-left-color: ${theme.color.brand['300']};
`

const content = theme => css`
  min-width: 0;
  flex-grow: 1;

  .mention {
    font-weight: ${theme.fontWeight.medium};
  }

  .truncate {
    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  strong {
    font-weight: ${theme.fontWeight.fontSemibold};
  }
`

const timePassed = css`
  white-space: nowrap;
`

const notificationIcon = theme => css`
  width: ${rem(32)};
  height: ${rem(32)};
  border-radius: 50%;
  background-color: ${theme.color.info['100']};
  overflow: hidden;

  svg {
    color: ${theme.color.monochrome.white};
    min-width: ${rem(40)};
    height: ${rem(40)};
  }
`

const endIndicator = theme => css`
  width: ${rem(9)};
  height: ${rem(9)};
  border-radius: 50%;
  margin: ${rem(16)} auto 0;
  background-color: ${theme.color.monochrome.medium};
`

const note = theme => css`
  background-color: ${theme.color.warningAlt['100']};
`

const emptyNotification = css`
  padding: ${rem(12)} ${rem(24)} ${rem(32)};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(352)};
`

const emptyCategoryNotification = css`
  ${emptyNotification};
  max-width: ${rem(DROPDOWN_WIDTH)};
  width: ${rem(DROPDOWN_WIDTH)};
`

const bellRing = theme => css`
  /* https://9elements.github.io/fancy-border-radius/#42.43.41.50--400.400 */
  border-radius: 43% 57% 50% 50% / 42% 41% 59% 58%;
  background-color: ${theme.color.info['50']};
  width: ${rem(54)};
  height: ${rem(54)};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    transform: rotate(20deg);
  }
`

const notificationBtn = theme => css`
  color: ${theme.color.monochrome.white};
  position: relative;

  svg {
    width: ${rem(18)};
    height: ${rem(20)};
    opacity: 0.6;
    transition: transform 1s, opacity 0.1s;
    transform: rotate(20);
  }

  &:hover {
    svg {
      opacity: 1 !important;
    }
  }
`

const resetBack = css`
  svg {
    animation: ${back} ease-in-out;
    animation-duration: 0.3s;
  }
`

const size = 14
const hasUnseenNotifications = theme => css`
  position: absolute;
  top: -${rem(size / 3)};
  right: 100%;
  transform: translateX(${rem(18)});
  min-width: ${rem(size)};
  height: ${rem(size)};
  line-height: 1;
  border-radius: ${rem(size / 2)};
  background-color: ${theme.color.primary.negativeAlt};
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.semibold};
  padding: 1px 4px 0;
  z-index: 2;

  & + svg {
    animation: ${shake} infinite ease-in-out;
    animation-duration: 180s;
  }
`

export const styles = {
  menu,
  header,
  list,
  heading,
  btnSetting,
  btnSeenAll,
  tabs,
  notification,
  isUnseen,
  content,
  timePassed,
  notificationIcon,
  endIndicator,
  note,
  emptyNotification,
  emptyCategoryNotification,
  bellRing,
  notificationBtn,
  resetBack,
  hasUnseenNotifications,
}
