import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const StarButton = React.memo(({ isStarred, onStarIconClick }) => {
  const tooltip = isStarred ? 'Unstar [+]' : 'Star [+]'
  return (
    <div className="star">
      <IconButton
        tooltip={tooltip}
        name={isStarred ? 'starSelected' : 'star'}
        onClick={onStarIconClick}
        size="medium"
        active={isStarred}
        className={cn('menu-button', { starred: isStarred })}
        svg
      />
    </div>
  )
})

export default StarButton
