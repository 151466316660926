import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Tooltip } from 'shared/ui'
import { themeValByPropKey } from '@groovehq/internal-design-system/lib/util/theme'

const ActionButton = styled(({ className, icon, onClick, tooltip }) => {
  return (
    <Tooltip className={className} tooltip={tooltip} position={'bottom'}>
      <span className={`Icon Icon-${icon}`} onClick={onClick} />
    </Tooltip>
  )
})`
  cursor: pointer;
  font-size: 19px;
  color: ${props => props.theme.color.monochrome.mediumDark};

  &:hover {
    color: ${themeValByPropKey('hoverColor')} !important;
  }
`

ActionButton.propTypes = {
  hoverColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

ActionButton.defaultProps = {
  color: 'blue',
}

export default ActionButton
