import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { useAdminAccess } from 'subapps/settings/components/drawers/NoAccess/hooks'
import {
  selectOnboardingWorkflowCompletedAndSkippedList,
  selectFeatureBasedOnboardingWorkflowData,
} from 'subapps/onboarding/selectors'
import { doSetFlag } from 'ducks/flags/operations'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import ProgressIndicator from './ProgressIndicator'
import WorkflowMenu from './WorkflowMenu'
import Activation from './Activation'
import MailboxActivationFooter from './MailboxActivationFooter'
import { styles } from './styles'
import MailboxStep from './MailboxStep'

const OnboardingWorkflow = ({ onExit, open, onClose }) => {
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState()
  const onboardingWorkflowCompletedAndSkippedList = useSelector(
    selectOnboardingWorkflowCompletedAndSkippedList
  )
  const onboardingWorkflowData = useSelector(
    selectFeatureBasedOnboardingWorkflowData
  )

  useAdminAccess(onClose)
  const steps = useMemo(
    () => {
      return Object.keys(onboardingWorkflowData).reduce(
        (accumulator, currentStepKey) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[currentStepKey] = {
            ...onboardingWorkflowData[currentStepKey],
            // Can't set footer with Footer in data file,
            // because we use the data in selector file and will cause the circular dependency issue
            footer:
              currentStepKey === 'mailbox'
                ? MailboxActivationFooter
                : undefined,
            completed: onboardingWorkflowCompletedAndSkippedList.includes(
              currentStepKey
            ),
          }
          return accumulator
        },
        {}
      )
    },
    [onboardingWorkflowCompletedAndSkippedList, onboardingWorkflowData]
  )

  const uncompletedStepKeys = useMemo(
    () => {
      const uncompleted = Object.keys(steps).filter(
        key => !steps[key].completed
      )
      return uncompleted
    },
    [steps]
  )

  const handleSkip = useCallback(
    key => {
      const { flagName } = onboardingWorkflowData[key]
      if (flagName) {
        dispatch(doSetFlag(flagName))
      }
      if (uncompletedStepKeys.length === 1) {
        onExit()
      } else {
        setCurrentStep(
          uncompletedStepKeys.find(
            (k, index) =>
              index > uncompletedStepKeys.indexOf(key) && !steps[k].completed
          )
        )
      }
    },
    [steps, uncompletedStepKeys, onExit, dispatch, onboardingWorkflowData]
  )

  useEffect(
    () => {
      // Preload the images
      Object.keys(onboardingWorkflowData).forEach(key => {
        const image = document.createElement('img')
        image.src = onboardingWorkflowData[key].img
      })
    },
    [onboardingWorkflowData]
  )

  useEffect(
    () => {
      if (!currentStep || !uncompletedStepKeys.includes(currentStep)) {
        setCurrentStep(
          uncompletedStepKeys[0] || Object.keys(onboardingWorkflowData)[0]
        )
      }
    },
    [currentStep, uncompletedStepKeys, onboardingWorkflowData]
  )

  useEffect(
    () => {
      dispatch(doTryFetchAccountUsageOnboardingForOnboarding())
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (Object.keys(steps).length > 0 && uncompletedStepKeys.length === 0) {
        onExit()
      }
    },
    [steps, uncompletedStepKeys, onExit]
  )

  const {
    name,
    title,
    subtitle,
    img,
    imgWidth,
    imgHeight,
    note,
    btnLabel,
    drawerType,
    to,
    skippable = true,
    completed,
    footer,
  } =
    steps[currentStep] || {}

  return (
    <Modal
      open={open}
      portal="#overlays"
      onClose={onExit}
      css={styles.modalPanel}
      panelCenter
      backdropStyles={styles.backdrop}
    >
      {!onboardingWorkflowCompletedAndSkippedList.includes('mailbox') ? (
        <MailboxStep {...steps.mailbox} />
      ) : (
        <>
          <WorkflowMenu
            currentStep={currentStep}
            onStepChange={setCurrentStep}
            steps={steps}
          />
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              key={currentStep}
              className="grui w-100"
            >
              {currentStep && (
                <Activation
                  name={name}
                  title={title}
                  subtitle={subtitle}
                  img={img}
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                  note={note}
                  btnLabel={btnLabel}
                  drawerType={drawerType}
                  to={to}
                  type={currentStep}
                  footer={footer}
                  skippable={skippable && !completed}
                  onSkip={handleSkip}
                  completed={completed}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </>
      )}
    </Modal>
  )
}

OnboardingWorkflow.ProgressIndicator = ProgressIndicator

export default OnboardingWorkflow
