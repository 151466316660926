import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const logo = theme => css`
  padding: ${theme.spacing[6]};
`

const phone = css`
  font-size: ${rem(21)};
  line-height: 1;
`

const circle = css`
  position: absolute;
  right: ${rem(6)};
  top: ${rem(6)};
  z-index: 1;
`

const navItem = theme => css`
  &&&.active {
    a,
    button {
      color: ${theme.color.monochrome.dark};
    }
  }
`

const navItemWithBigIcon = css`
  &&& svg {
    width: ${rem(32)};
    height: ${rem(32)};
  }
`

export const styles = {
  logo,
  phone,
  circle,
  navItem,
  navItemWithBigIcon,
}
