import React, { useContext } from 'react'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import {
  text,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { timeAgoAbbr } from 'util/date'
import { notify as Notify } from 'assets/icons/groove/v2'
import { useNotificationEvent } from 'ducks/notifications/v2/hooks'
import { isEventAuthorAutomation } from 'ducks/notifications/v2/utils'
import { styles } from './styles'
import NotificationSubject from './NotificationSubject'
import { NotificationRoomContext } from './contexts'

const Notification = ({ eventId }) => {
  const { room } = useContext(NotificationRoomContext)
  const { event, isRead } = useNotificationEvent(eventId, room)

  const { title, subtitle, author, meta } = event.getContent()

  const { name: authorName, avatar_url: authorAvatarUrl } = author || {}
  const { is_note: isNote = false } = meta || {}

  // deduce the actor type by the globalId type
  const isAutomatedAuthor = isEventAuthorAutomation(event)

  const humanTime = timeAgoAbbr(new Date(event.getTs()))
  const timeAgo = humanTime ? `${humanTime} ago` : ''
  const time = new Date(event.getTs()).toLocaleString()

  return (
    <button
      css={[
        button.styles.preflight,
        styles.notification,
        !isRead && styles.isUnseen,
      ]}
    >
      {!isAutomatedAuthor && (
        <Avatar
          avatar={authorAvatarUrl}
          userName={authorName}
          size="xl"
          className="grui -mt-1 flex-shrink-0"
        />
      )}
      {isAutomatedAuthor && (
        <div
          className="grui flex-center flex-shrink-0"
          css={styles.notificationIcon}
        >
          <Notify />
        </div>
      )}
      <div css={styles.content} className="grui mx-5">
        <NotificationSubject event={event} />
        <div css={[text.styles.text2Xs, text.styles.textSuperDark]}>
          {title}
        </div>
        <div
          className="grui truncate"
          css={[
            text.styles.text2Xs,
            text.styles.textSuperDark,
            isNote && styles.note,
          ]}
        >
          {subtitle}
        </div>
      </div>
      <div
        css={[styles.timePassed, text.styles.text3Xs, text.styles.textDark]}
        title={time}
      >
        {timeAgo}
      </div>
    </button>
  )
}

export default React.memo(Notification)
