import React, { useCallback } from 'react'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_CREATE_ORDER } from 'ducks/drawers/types'
import { Button } from 'util/ui'

const DuplicateOrderButton = ({ integration, orderId, customerId }) => {
  const { openDrawer } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_CREATE_ORDER,
  })

  const onOpen = useCallback(
    () => {
      openDrawer(customerId, {
        additionalProps: {
          drawerIntegrationId: integration.id,
          orderIdToDuplicate: orderId,
        },
      })
    },
    [openDrawer, integration, customerId, orderId]
  )

  return (
    <Button basic onClick={onOpen}>
      Duplicate
    </Button>
  )
}

export default DuplicateOrderButton
