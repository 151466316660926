import { css } from '@emotion/react'

const backdrop = theme => css`
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.5);
`

const progressIndicator = theme => css`
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.monochrome.white};
  line-height: ${theme.lineHeight.leadingTight};
  background-color: ${theme.color.info['900']};
  border-radius: ${theme.spacing[4]};

  &.disabled {
    cursor: default;
  }
`

const progressHeading = theme => css`
  font-size: ${theme.fontSize.small};

  &.incomplete {
    color: ${theme.color.primary.warningText};
  }
`

const star = theme => css`
  line-height: 1;
  font-size: ${theme.fontSize.base};
`

const modalPanel = css`
  padding: 0;
  min-height: 520px;
  width: 1120px;
  max-width: 90%;

  @media screen and (min-height: 700px) {
    min-height: 640px;
  }

  @media screen and (max-height: 660px) {
    align-self: start;
  }

  &&& {
    flex-direction: row;
    align-items: stretch;
  }
`

const menu = theme => css`
  width: 320px;
  color: ${theme.color.monochrome.white};
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  font-size: ${theme.fontSize.large};
  z-index: 0;

  background-image: radial-gradient(
    circle at 50% 0,
    #493593,
    #533baa 63%,
    #6136ff 97%
  );

  h2 {
    font-size: 27px;
    color: inherit;
  }
`

const contactBtn = theme => css`
  border-radius: 9999px;
  font-size: ${theme.fontSize.base};
  color: ${theme.color.monochrome.black};
  border: none;
  &&&:hover {
    background-color: ${theme.color.monochrome.light};
  }
`

const actionBtn = theme => css`
  border-radius: 9999px;
  font-size: ${theme.fontSize.x2large};

  & + button {
    font-size: ${theme.fontSize.large};
  }
`

const step = theme => css`
  color: inherit;
  font-weight: ${theme.fontWeight.medium};

  .step-icon {
    width: 18px;
    height: 18px;
  }

  .arrow-icon {
    width: 20px;
    height: 20px;
    path {
      fill: currentColor;
    }
  }

  .check-icon {
    border-radius: 50%;
    background: ${theme.color.warningAlt['200']} center / 50% auto
      url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E")
      no-repeat;
  }

  .circle-icon {
    &::after {
      opacity: 0.5;
      border-radius: 50%;
      border: 1.5px solid currentColor;
      content: '';
      display: inline-block;
      width: ${theme.spacing[7]};
      height: ${theme.spacing[7]};
      transform: opacity 0.15s;
    }
  }

  &:hover {
    .circle-icon::after {
      opacity: 1;
    }
  }
`

const activationContainer = theme => css`
  h2 + * {
    max-width: 618px;
    font-size: ${theme.fontSize.x5large};
  }
  &.teammates {
    h2 + * {
      max-width: 400px;
      font-size: ${theme.fontSize.x2large};
    }

    .onboarding-activation-img-wrapper {
      margin-bottom: ${theme.spacing[3]};
    }
  }

  &.canned_reply {
    h2 + * {
      max-width: 720px;
    }
    .onboarding-activation-img-wrapper {
      max-width: 243px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        margin-bottom: ${theme.spacing[19]};
      }
    }
  }

  &.integration {
    h2 + * {
      max-width: 720px;
    }
    .onboarding-activation-img-wrapper {
      max-width: 330px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        max-width: 477px;
        margin-bottom: ${theme.spacing[14]};
      }
    }
  }

  &.kb {
    .onboarding-activation-img-wrapper {
      max-width: 310px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        margin-bottom: ${theme.spacing[15]};
        max-width: 477px;
      }
    }
  }

  &.rule {
    .onboarding-activation-img-wrapper {
      max-width: 248px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        margin-bottom: ${theme.spacing[15]};
        margin-top: ${theme.spacing[5]};
      }
    }
  }

  &.widget {
    .onboarding-activation-img-wrapper {
      max-width: 330px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        max-width: 515px;
        margin-bottom: ${theme.spacing[10]};
      }
    }
  }

  &.mailbox {
    .onboarding-activation-img-wrapper {
      max-width: 258px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        margin-bottom: ${theme.spacing[20]};
      }
    }

    button {
      margin-left: ${theme.spacing[4]};
      margin-right: ${theme.spacing[4]};
    }
  }

  &.payment_method {
    h2 + * {
      max-width: 660px;
    }
    .onboarding-activation-img-wrapper {
      max-width: 300px;
      margin-bottom: ${theme.spacing[4]};

      @media screen and (min-height: 700px) {
        max-width: 303px;
        margin-bottom: ${theme.spacing[19]};
      }
    }
  }
`

const imgWrapper = css`
  max-width: 380px;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

const inkBar = theme => css`
  height: 15px;
  width: 3px;
  background-color: ${theme.color.primary.warningAlt};
  position: absolute;
  left: -${theme.spacing[5]};
  transition: top 0.3s;
`

const blur = css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  max-width: 180px;

  &.bottom-blur {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    max-width: 191px;
  }
`

const pieContainer = css`
  width: 500px;
`

export const styles = {
  backdrop,
  progressIndicator,
  progressHeading,
  star,
  modalPanel,
  menu,
  contactBtn,
  actionBtn,
  step,
  activationContainer,
  imgWrapper,
  inkBar,
  blur,
  pieContainer,
}
