import LinkedTrelloCards from './LinkedTrelloCards'
import LinkTrelloCard from './LinkTrelloCard'

export default function TrelloIndex({ className, doNavigatableSetState }) {
  return (
    <div className={className}>
      <LinkedTrelloCards doNavigatableSetState={doNavigatableSetState} />
      <LinkTrelloCard />
    </div>
  )
}
