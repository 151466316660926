import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useCustomer } from 'ducks/integrations/recharge/hooks/customers'
import { selectSubscriptionForId } from 'ducks/integrations/recharge/selectors'
import {
  formatDateForCurrentYear,
  formatAmountWithCurrency,
  getRechargeMerchantUrl,
} from 'ducks/integrations/recharge/utils'
import { KeyValue } from 'components/KeyValues'
import { capitalize } from 'util/strings'
import { MISSING_VALUE } from 'ducks/integrations/recharge/contants'
import { capture } from 'ducks/tracking/actions'

import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import UpcomingOrders from 'components/integrations/RechargeWidgetCard/UpcomingOrders/List'
import Orders from 'components/integrations/RechargeWidgetCard/Orders/List'
import Footer from 'components/integrations/RechargeWidgetCard/Subscriptions/Footer'

export default function Detail({ integration, className }) {
  const { navigatableState, navigatableClearState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const { subscriptionId, customerId } = navigatableState || {}
  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )
  const customer = useCustomer(integration.id, customerId)

  useEffect(
    () => {
      return () => {
        // get back to index page while reloading widget
        navigatableClearState()
      }
    },
    [customer, navigatableClearState]
  )

  const createdAt = useMemo(
    () =>
      formatDateForCurrentYear(
        subscription?.created_at,
        '%d %b @ %l:%M%p',
        '%d %b %Y @ %l:%M%p'
      ),
    [subscription]
  )

  const cancelledAt = useMemo(
    () =>
      formatDateForCurrentYear(
        subscription?.cancelled_at,
        '%d %b @ %l:%M%p',
        '%d %b %Y @ %l:%M%p'
      ),
    [subscription]
  )

  const price = useMemo(
    () =>
      formatAmountWithCurrency(
        subscription?.price,
        subscription?.presentment_currency
      ),
    [subscription]
  )

  const statusClassName = useMemo(
    () => {
      if (!subscription) {
        return null
      }

      switch (subscription.status) {
        case 'active':
          return 'recharge-status-positive'
        case 'expired':
          return 'recharge-status-warning'
        case 'cancelled':
          return 'recharge-status-negative'
        default:
          return ''
      }
    },
    [subscription]
  )

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Recharge Subscription Link Clicked')
    return true
  }, [])

  const subscriptionUrl = getRechargeMerchantUrl(
    integration.fullStoreDomain,
    `merchant/subscriptions/${subscription?.id}/details`
  )

  if (!subscription) {
    return null
  }

  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      unifiedTitleIconButton
      onBack={navigatableClearState}
      title="back"
    >
      <div className="grui p-7">
        <KeyValue
          title="Subscription ID"
          value={
            <a
              href={subscriptionUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onViewExternalLinkClicked}
            >
              {subscription.id}
              <ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          }
        />
        <KeyValue title="Product title" value={subscription.product_title} />
        <KeyValue
          title="Variant"
          value={subscription.variant_title}
          missingValue={MISSING_VALUE}
        />
        <KeyValue title="Price" value={price} />
        <KeyValue title="Quantity" value={subscription.quantity} />
        <KeyValue
          title="Order interval frequency"
          value={subscription.order_interval_frequency}
        />
        <KeyValue title="Order unit" value={subscription.order_interval_unit} />
        <KeyValue
          title="Status"
          value={
            <span className={statusClassName}>
              {capitalize(subscription.status)}
            </span>
          }
        />
        <KeyValue title="Created at" value={createdAt} />

        {subscription.cancelled_at && (
          <>
            <KeyValue title="Cancelled at" value={cancelledAt} />
            <KeyValue
              title="Cancelled reason"
              value={subscription.cancellation_reason}
              singleline={false}
            />
          </>
        )}
      </div>

      <UpcomingOrders
        integrationId={integration.id}
        subscriptionId={subscription.id}
      />
      <Orders integrationId={integration.id} subscriptionId={subscription.id} />
      <Footer integration={integration} subscription={subscription} />
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
