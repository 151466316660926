import React from 'react'
import Link from 'redux-first-router-link'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { LOGIN_PAGE } from 'constants/pages'
import { Form, Icon } from 'util/ui'
import ValidatedField from 'shared/components/ui/ValidatedField'
import FormError from 'shared/components/ui/Form/Error'
import FormSuccess from 'shared/components/ui/Form/Success'
import { propFunc } from 'util/functions'

import styles from './styles.less'
import { styles as formStyles } from './styles'

const toLogin = { type: LOGIN_PAGE }

class ForgotForm extends React.PureComponent {
  pendingValidation = {}

  clearValidationTimeout(name) {
    if (this.pendingValidation[name]) {
      clearTimeout(this.pendingValidation[name])
    }
  }

  handleChange = (name, _, { value, checked }) => {
    const { onChange, onValidate } = this.props
    this.clearValidationTimeout(name)
    this.pendingValidation[name] = setTimeout(() => {
      onValidate(name, checked === undefined ? value : checked)
    }, 500)
    onChange(name, checked === undefined ? value : checked)
  }

  handleBlur = name => {
    const { onValidate, formFields } = this.props
    this.clearValidationTimeout(name)
    onValidate(name, formFields[name])
  }

  render() {
    const {
      formFields,
      formFieldsErrors,
      submitForm,
      canSave,
      saving,
      isSent,
      showSubdomain = false,
      forgotError,
      submitText,
      previousPage,
    } = this.props

    return (
      <Form size="large">
        {showSubdomain && (
          <Form.Field>
            <ValidatedField
              placeholder="Subdomain"
              type="text"
              value={formFields.subdomainInput}
              errorMessage={formFieldsErrors.subdomainInput}
              onChange={propFunc(this.handleChange, 'subdomainInput')}
              onBlur={propFunc(this.handleBlur, 'subdomainInput')}
              autoComplete="subdomain"
              name="subdomain"
            />
          </Form.Field>
        )}
        <Form.Field>
          <ValidatedField
            placeholder="Email Address"
            type="email"
            value={formFields.emailInput}
            errorMessage={formFieldsErrors.emailInput}
            onChange={propFunc(this.handleChange, 'emailInput')}
            onBlur={propFunc(this.handleBlur, 'emailInput')}
            autoComplete="email"
            name="email"
          />
        </Form.Field>
        {forgotError && <FormError error={forgotError} />}
        {isSent && <FormSuccess message="Link sent successfully" />}
        <Form.Group widths="equal">
          <Form.Field className={styles.backLink}>
            <Link to={previousPage?.type ? previousPage : toLogin}>
              <Icon name="chevron left" />
              Back to Login
            </Link>
          </Form.Field>
          <Button
            type="info"
            css={formStyles.btn}
            onClick={submitForm}
            disabled={!canSave}
            htmlType="submit"
          >
            {!saving ? submitText : 'One moment...'}
          </Button>
        </Form.Group>
      </Form>
    )
  }
}

export default ForgotForm
