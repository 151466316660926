import React, { useCallback } from 'react'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_REFUND_ORDER } from 'ducks/drawers/types'
import { Button } from 'util/ui'
import { buildDrawerQueryParam } from 'ducks/drawers/util'

const RefundOrderButton = ({ orderId, integration }) => {
  const { drawerId, openDrawer } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_REFUND_ORDER,
  })

  const onOpen = useCallback(
    () => {
      openDrawer(orderId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerIntegrationId',
            integration.id
          ),
        },
      })
    },
    [openDrawer, orderId, integration, drawerId]
  )

  return (
    <Button basic onClick={onOpen}>
      Refund
    </Button>
  )
}

export default RefundOrderButton
