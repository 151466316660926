import React, { useCallback } from 'react'
import Link from 'redux-first-router-link'
import { useSelector } from 'react-redux'
import GlobalNav from '@groovehq/internal-design-system/lib/components/GlobalNav/GlobalNav'
import { normalizeCount } from 'util/favicon'
import { selectPreferredInboxTheme } from 'ducks/currentUser/selectors/preferences/selectPreferredInboxTheme'

import useFeatureDot from 'shared/ui/useFeatureDot'
import { styles } from './styles'

export default function GlobalNavItem({
  icon,
  isActive,
  onClick,
  to,
  title,
  noticeCount = 0,
  featureDotFlag,
  alert,
  className,
}) {
  const preferredInboxTheme = useSelector(selectPreferredInboxTheme)
  // NOTE (jscheel): Because we roll out new subapps slowly, we use the
  // limitedScope version of useFeatureDot. Therefore we must set the agent flag
  // for the appropriate agents in the rollout.
  const { dotTargetRef, dotMarkAsSeen, Dot } = useFeatureDot(
    featureDotFlag,
    true,
    {
      placement: 'top-end',
    }
  )
  const handleClick = useCallback(
    e => {
      dotMarkAsSeen()
      if (onClick) onClick(e)
    },
    [onClick, dotMarkAsSeen]
  )

  const navItem = (
    <GlobalNav.Item
      to={to}
      data-test-id={title}
      isActive={isActive}
      onClick={handleClick}
      icon={icon}
      alert={alert}
      title={title}
      noticeCount={normalizeCount(noticeCount)}
      iconRef={featureDotFlag ? dotTargetRef : undefined}
      css={[
        preferredInboxTheme === 'light' && styles.navItem,
        styles.navItemWithBigIcon,
      ]}
      className={className}
    >
      {featureDotFlag && <Dot />}
    </GlobalNav.Item>
  )

  if (!to) return navItem

  return <Link to={to}>{navItem}</Link>
}
