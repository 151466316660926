import { memo, useCallback } from 'react'
import { isOwnReaction, getUserList } from 'ducks/comments/util'

import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import { styles } from './styles'

const Reaction = ({
  allReactions,
  reaction,
  currentUserId,
  onReactionClick,
}) => {
  const onClick = useCallback(
    () => {
      onReactionClick(reaction)
    },
    [onReactionClick, reaction]
  )

  return (
    <Tooltip
      title={getUserList(allReactions, currentUserId)}
      position="top"
      strategy="fixed"
    >
      <span
        css={[
          styles.reaction,
          isOwnReaction(allReactions, currentUserId) && styles.ownReaction,
        ]}
        onClick={onClick}
      >
        {reaction} {allReactions.length}
      </span>
    </Tooltip>
  )
}

export default memo(Reaction)
