import { connect } from 'react-redux'
import {
  selectSidebarCardOpenState,
  doChangeOpenState,
} from 'ducks/inAppCards/openState'

import View from './view'

const selectFactory = (initialState, initialOwnProps) => {
  const { openStateKey } = initialOwnProps

  return state => ({
    isCardOpen: selectSidebarCardOpenState(state, openStateKey),
  })
}

const performFactory = (initialState, initialOwnProps) => {
  const { openStateKey } = initialOwnProps

  return dispatch => ({
    doChangeOpenState: isOpen => {
      if (!openStateKey) return false
      return dispatch(doChangeOpenState(openStateKey, isOpen))
    },
  })
}

export default connect(selectFactory, performFactory)(View)
