import { Label } from 'shared/ui'
import { Input } from 'util/ui'
import { useAutofocus } from 'util/hooks'

import CustomFieldEditForm from '../../../CustomFieldKeyValue/CustomFieldEditForm'
import CompanyHeaderFieldsDomains from './CompanyHeaderFieldsDomains'
import CompanyLogoEditField from './CompanyLogoEditField'

export default function CompanyForm({
  domains,
  name,
  onCancel,
  onSave,
  doRecalcMenuPosition,
  variant,
}) {
  const autofocusRef = useAutofocus()
  return (
    <CustomFieldEditForm deletable={false} onCancel={onCancel} onSave={onSave}>
      {variant !== 'create' && (
        <>
          <Label as="div">Logo</Label>
          <CompanyLogoEditField contact={false} />
        </>
      )}
      <Label as="div">Company name</Label>
      <Input
        defaultValue={name}
        fluid
        name={`company_name.content`}
        ref={autofocusRef}
      />
      {variant !== 'create' && (
        <CompanyHeaderFieldsDomains
          domains={domains}
          doRecalcMenuPosition={doRecalcMenuPosition}
        />
      )}
    </CustomFieldEditForm>
  )
}
