import React from 'react'
import cn from 'classnames'

import Modal from 'components/Modal'
import { Flex, SUI } from 'shared/ui'

import EditPane from './EditPane'
import styles from './styles.css'

// We wrap the create and edit states inside the same modal to simplify the
// transition effect between them.
const EditModal = ({
  bottom = 20,
  labelToEdit,
  offset,
  onClose,
  open,
  ...rest
}) => {
  if (!open) return null
  const heightReduction = offset.top + bottom
  return (
    <Modal
      styleContainer={{
        maxHeight: `calc(100% - ${heightReduction}px)`,
        overflow: 'visible' /* hack for color picker */,
        display: 'flex',
      }}
      offset={offset}
      onBackgroundClick={onClose}
    >
      <SUI>
        <Flex className={cn('grui flex-col', styles.tags)}>
          <EditPane
            top={offset.top}
            bottom={offset.bottom}
            onClose={onClose}
            {...rest}
          />
        </Flex>
      </SUI>
    </Modal>
  )
}

// Hackery so that we can fetch Top Labels on mount.

class MountedModal extends React.Component {
  componentDidMount() {
    const { onModalOpen } = this.props
    if (onModalOpen) onModalOpen()
  }

  onClose = () => {
    const { onClose } = this.props
    if (this.ignoreNextClose === true) {
      this.ignoreNextClose = false
      return
    }
    onClose()
  }

  runAndClose = fn => (...args) => {
    fn(...args)
    this.props.onClose()
  }

  closeAndRun = fn => (...args) => {
    this.props.onClose()
    return fn(...args)
  }

  toggleLabel = (ticketId, label) => {
    const { mode, toggleLabel, onClose } = this.props
    const shouldClose = label.id > 0 || mode === 'single'
    if (!shouldClose) {
      this.ignoreNextClose = true
    }
    return toggleLabel(ticketId, label, mode).then(() => {
      if (shouldClose) {
        return onClose()
      }
      return null
    })
  }

  render() {
    const { onApply, doBeginManageLabels } = this.props
    return (
      <EditModal
        {...this.props} // calls the onClose callback // wrap the actions that should close the modal menu inside a fn that // We no longer manage the menu open/close state inside redux, so we
        doBeginManageLabels={this.closeAndRun(doBeginManageLabels)}
        toggleLabel={this.toggleLabel}
        onApply={this.runAndClose(onApply)}
        onCancel={this.onClose}
        onClose={this.onClose}
      />
    )
  }
}

export default MountedModal
