import React, {
  useMemo,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import NoteIcon from '@groovehq/internal-design-system/lib/assets/icons/Note'
import CopyText from '@groovehq/internal-design-system/lib/components/CopyText/CopyText'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import CopyIcon from 'assets/icons/groove/v2/copy.svg'
import { KeyValue, CollapsibleKeyValue } from 'components/KeyValues'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import {
  FULFILLMENT_DISPLAY_STATUS_TEXT,
  ORDER_DISPLAY_FINANCIAL_STATUS_TEXT,
  MISSING_VALUE,
} from 'ducks/integrations/shopify/constants'
import { strftime } from 'util/date'
import { shopifyMoneyString } from 'util/currency'
import doFetchOrderStatusPageUrl from 'ducks/integrations/shopify/operations/doFetchOrderStatusPageUrl'
import { selectOrderForId } from 'ducks/integrations/shopify/selectors'
import {
  shopifyGidToId,
  shopifyStoreAdminUrl,
} from 'ducks/integrations/shopify/utils'
import { capture } from 'ducks/tracking/actions'
import CancelOrderButton from './CancelOrderButton'
import RefundOrderButton from './RefundOrderButton'
import DuplicateOrderButton from './DuplicateOrderButton'
import ConditionalRow from './ConditionalRow'
import FulfillmentRow from './FulfillmentRow'
import OrderLineItem from './OrderLineItem'

export default function ShopifyDetail() {
  const dispatch = useDispatch()
  const [statusPageUrl, setStatusPageUrl] = useState(null)
  const { navigatableState, navigatableClearState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )
  const { orderId = null, integration = {} } = navigatableState || {}

  const orderShortId = orderId && shopifyGidToId(orderId)
  // we pass the order id and then get it from the state - this allows the id to be stored
  // in the url and survices a refresh
  const order = useSelector(state => selectOrderForId(state, orderId))

  useEffect(
    () => {
      if (!integration?.id || !order?.id) return

      // this is all just temp until Shopify releases Order.statusPageUrl.
      // More info https://trello.com/c/HJDgZlSA
      dispatch(doFetchOrderStatusPageUrl(integration.id, order.id)).then(
        response => {
          setStatusPageUrl(response?.order?.order_status_url)
        }
      )
    },
    [dispatch, integration?.id, order?.id]
  )

  useEffect(
    () => {
      return () => {
        // reset state on unmount, card breaks when refresh button clicked in detail view
        navigatableClearState()
      }
    },
    [integration?.id, navigatableClearState]
  )

  const lineItems = useMemo(
    () => {
      return order ? order.lineItems?.edges.map(edge => edge.node) : []
    },
    [order]
  )

  const cancelledAtString = useMemo(
    () => {
      if (order && order.isCancelled) {
        return strftime('%d %b %Y @ %l:%M%p', order.cancelledAt)
      }
      return null
    },
    [order]
  )

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Shopify Order Link Clicked')
    return true
  }, [])

  if (!navigatableState) return null
  if (!order) return null

  const {
    name,
    createdAt,
    requiresShipping,
    fulfillments,
    displayFinancialStatus,
    displayFulfillmentStatus,
    totalShippingPriceSet,
    currentSubtotalPriceSet,
    currentTotalTaxSet,
    currentTotalPriceSet,
    isCancelled,
    currentCartDiscountAmountSet,
    refundable,
    isCancellable,
    billingAddressMatchesShippingAddress,
    billingAddress,
    shippingAddress,
    netPaymentSet,
    refunds,
    totalReceivedSet,
    tags,
  } = order

  const hasBillingAddress =
    billingAddress && !!billingAddress?.formatted?.length
  const hasShippingAddress =
    shippingAddress && !!shippingAddress?.formatted?.length
  const hasFulfillmentLineItems = !!fulfillments?.length
  const hasRefunds = !!refunds?.length

  const orderUrl = `${shopifyStoreAdminUrl(
    integration.storeDomain
  )}/orders/${orderShortId}`

  return (
    <NavigatableIntegrationWidgetCard.Detail
      unifiedTitleIconButton
      onBack={navigatableClearState}
      title="back"
    >
      <div className="grui p-7 shopifyV2-order-data">
        <KeyValue
          title="Order"
          value={
            <>
              <a
                href={orderUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onViewExternalLinkClicked}
              >
                {name}
                <ExternalLink className="shopifyV2-externalLink-icon" />
              </a>
              {statusPageUrl && (
                <>
                  &nbsp;&nbsp;·&nbsp;&nbsp;
                  <CopyText
                    text={statusPageUrl}
                    duration={1000}
                    copiedHint="Copied!"
                  >
                    <span
                      css={text.styles.textPrimary}
                      className="grui cursor-pointer"
                    >
                      Status page&nbsp;<CopyIcon className="shopifyV2-copyStatusPageUrlIcon" />
                    </span>
                  </CopyText>
                </>
              )}
            </>
          }
        />
        <KeyValue
          title="Order date"
          value={strftime('%d %b %Y @ %l:%M%p', createdAt)}
        />
        <KeyValue
          title="Payment status"
          value={ORDER_DISPLAY_FINANCIAL_STATUS_TEXT[displayFinancialStatus]}
        />
        <KeyValue
          title="Fulfillment status"
          value={FULFILLMENT_DISPLAY_STATUS_TEXT[displayFulfillmentStatus]}
        />
        {isCancelled && (
          <KeyValue title="Cancelled" value={cancelledAtString} />
        )}
        <CollapsibleKeyValue
          title="Tags"
          items={tags}
          missingValue={MISSING_VALUE}
          delimiter=", "
        />
        {(!requiresShipping ||
          (requiresShipping && hasFulfillmentLineItems)) && (
          <KeyValue
            title="Shipping"
            missingValue={!requiresShipping && 'No shipping required'}
            grouped={requiresShipping && hasFulfillmentLineItems}
          >
            {requiresShipping &&
              hasFulfillmentLineItems && (
                <>
                  {fulfillments.map(fulfillment => (
                    <FulfillmentRow
                      fulfillment={fulfillment}
                      key={fulfillment.id}
                    />
                  ))}
                </>
              )}
          </KeyValue>
        )}
        {requiresShipping && (
          <>
            {hasShippingAddress && (
              <CollapsibleKeyValue
                title="Shipping address"
                items={shippingAddress.formatted}
              />
            )}
            {hasBillingAddress && (
              <CollapsibleKeyValue
                title="Billing address"
                missingValue={
                  billingAddressMatchesShippingAddress &&
                  'Same as shipping address'
                }
                items={
                  !billingAddressMatchesShippingAddress &&
                  billingAddress.formatted
                }
              />
            )}
          </>
        )}
      </div>
      <div className="shopifyV2-header-label">Order details</div>
      <div className="grui p-7">
        <table className="shopifyV2-order-table">
          {lineItems.map(lineItem => (
            <OrderLineItem
              lineItem={lineItem}
              integration={integration}
              key={lineItem.id}
            />
          ))}
          <tbody className="shopifyV2-order-table-sub">
            <ConditionalRow
              text="Discount"
              obj={currentCartDiscountAmountSet}
              negative
            />
            <tr>
              <td>
                <KeyValue title="Subtotal" missingValue={null} />
              </td>
              <td className="grui text-right shopifyV2-currency">
                {shopifyMoneyString(currentSubtotalPriceSet.shopMoney)}
              </td>
            </tr>
            {requiresShipping && (
              <ConditionalRow text="Shipping" obj={totalShippingPriceSet} />
            )}
            <ConditionalRow text="Tax" obj={currentTotalTaxSet} />
          </tbody>
          <tfoot>
            <tr className="section-total">
              <td>
                <KeyValue title="Total" missingValue={null} />
              </td>
              <td className="grui text-right shopifyV2-currency">
                {shopifyMoneyString(currentTotalPriceSet.shopMoney)}
              </td>
            </tr>
            <ConditionalRow
              className="section-start shopifyV2-order-table-sub"
              text="Paid by customer"
              obj={totalReceivedSet}
            />
            {hasRefunds &&
              refunds.map((refund, index) => (
                <tr
                  key={refund.id}
                  className={cn('shopifyV2-order-table-sub', {
                    'section-start': index === 0,
                  })}
                >
                  <td>
                    <KeyValue
                      title={index === 0 && 'Refunded'}
                      missingValue={null}
                    />
                  </td>
                  <td className="grui text-right shopifyV2-currency">
                    <Tooltip
                      title={
                        refund.note ? `Reason: “${refund.note}“` : 'Reason: —'
                      }
                    >
                      <div>
                        <NoteIcon className="shopifyV2-icon" />&nbsp;
                        {shopifyMoneyString({
                          currencyCode:
                            refund.totalRefundedSet.shopMoney.currencyCode,
                          amount: -refund.totalRefundedSet.shopMoney.amount,
                        })}
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            {hasRefunds && (
              <tr className="section-total">
                <td>
                  <KeyValue title="Net payment" missingValue={null} />
                </td>
                <td className="grui text-right shopifyV2-currency">
                  {shopifyMoneyString(netPaymentSet.shopMoney)}
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>
      <div className="shopifyV2-footer">
        {refundable && (
          <RefundOrderButton orderId={orderId} integration={integration} />
        )}
        <DuplicateOrderButton
          orderId={orderId}
          customerId={order.customer.id}
          integration={integration}
        />
        {isCancellable && (
          <CancelOrderButton integrationId={integration.id} orderId={orderId} />
        )}
      </div>
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
