import { css } from '@emotion/react'

const billingPlanLimitedAlert = theme => css`
  min-height: ${theme.spacing[16]};
  background: ${theme.color.primary.negativeAlt};
  button {
    font-weight: ${theme.fontWeight.semibold};
  }
`

const billingPlanLimitedOverdueAlert = css`
  background: #d60000;
`

const learnMoreLink = theme => css`
  &&& {
    color: ${theme.color.primary.info};
    text-decoration: underline;
  }
`

export const styles = {
  billingPlanLimitedAlert,
  billingPlanLimitedOverdueAlert,
  learnMoreLink,
}
