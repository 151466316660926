import moment from 'moment'
import Link from 'redux-first-router-link'

import KeyValues, { KeyValue } from 'components/KeyValues'

import Icon from './Icon'

function formatDate(date) {
  if (!date) return ''
  const asDate = new Date(date)
  const displayYear = asDate.getFullYear() !== new Date().getFullYear()
  return moment(date).format(`D MMM${displayYear ? " 'YY" : ''}, H:mmA`)
}

function getFieldProps(
  field,
  {
    fields: {
      assignee,
      created,
      components,
      duedate,
      labels,
      priority: { iconUrl: priorityIconUrl, name: priority } = {},
      project: {
        avatarUrls: { '48x48': projectAvatarUrl } = {},
        key: projectKey,
        name: project,
      } = {},
      resolution,
      status: { name: status } = {},
      issuetype: { iconUrl: typeIconUrl, name: type } = {},
      updated,
    } = {},
    site: { url: site } = {},
  }
) {
  const {
    avatarUrls: { '48x48': assigneeAvatarUrl } = {},
    accountId: assigneeId,
    displayName: assigneeDisplayName,
    emailAddress: assigneeEmail,
    key: assigneeKey,
  } =
    assignee || {}
  const { name: resolutionName } = resolution || {}
  switch (field) {
    case 'showSidebarField.type':
      return {
        singleline: true,
        title: 'Type',
        value: (
          <Link
            target="_blank"
            to={`${site}/issues/?jql=issuetype%20%3D%20%22${type}%22`}
          >
            <Icon src={typeIconUrl} />
            {type}
          </Link>
        ),
      }
    case 'showSidebarField.status':
      return {
        singleline: true,
        title: 'State',
        value: (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={`${site}/issues/?jql=status%20%3D%20%22${status}%22`}
          >
            {status}
          </Link>
        ),
      }
    case 'showSidebarField.resolution':
      return {
        title: 'Resolution',
        value: resolutionName,
      }
    case 'showSidebarField.dueDate':
      return {
        title: 'Due',
        value: formatDate(duedate),
      }
    case 'showSidebarField.created':
      return {
        title: 'Created',
        value: formatDate(created, true),
      }
    case 'showSidebarField.updated':
      return {
        title: 'Last updated',
        value: formatDate(updated, true),
      }
    case 'showSidebarField.assignee':
      if (assigneeId) {
        return {
          title: 'Assignee',
          value: (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={`${site}/jira/people/${assigneeId}`}
            >
              <Icon src={assigneeAvatarUrl} />
              {assigneeKey
                ? `@${assigneeKey}`
                : assigneeDisplayName || assigneeEmail || assigneeId}
            </Link>
          ),
        }
      }
      return null
    case 'showSidebarField.labels':
      if (labels && labels.length > 0) {
        return {
          title: 'Labels',
          value: [
            ...labels.map((label, index) => {
              return [
                <Link
                  key={label}
                  rel="noopener noreferrer"
                  target="_blank"
                  to={`${site}/issues/?jql=labels%20%3D%20%22${label}%22`}
                >
                  {label}
                </Link>,
                index + 1 !== labels.length && ', ',
              ]
            }),
          ],
        }
      }
      return null
    case 'showSidebarField.project':
      return {
        singleline: true,
        title: 'Project',
        value: (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={`${site}/browse/${projectKey}`}
          >
            <Icon src={projectAvatarUrl} />
            {project}
          </Link>
        ),
      }
    case 'showSidebarField.components':
      if (components && components.length > 0) {
        return {
          singleline: true,
          title: 'Components',
          value: [
            ...components.map(({ name: componentName }, index) => {
              return [
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  to={`${site}/issues/?jql=component%20%3D%20%22${componentName}%22`}
                >
                  {componentName}
                </Link>,
                index + 1 !== components.length && ', ',
              ]
            }),
          ],
        }
      }
      return null
    case 'showSidebarField.priority':
      if (priority) {
        return {
          singleline: true,
          title: 'Priority',
          value: (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={`${site}/issues/?jql=priority%20%3D%20%22${priority}%22`}
            >
              <Icon src={priorityIconUrl} />
              {priority}
            </Link>
          ),
        }
      }
      return null
    default:
      return null
  }
}

export default function LinkedJiraServerIssueFields({ fields, issue }) {
  return (
    <KeyValues>
      {fields.map(field => {
        const props = getFieldProps(field, issue)
        if (!props) return null
        return <KeyValue key={field} {...props} />
      })}
    </KeyValues>
  )
}
