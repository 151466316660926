import React from 'react'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { Icon } from 'util/ui'
import { propFunc } from 'util/functions'
import { SearchBox, Button } from 'shared/ui'
import styles from './styles.less'

export default function SearchBar({
  onSearch,
  searchTerm,
  doBeginManageLabels,
  isAdmin,
}) {
  return (
    <SpacedItems.Container
      className={styles.searchbar}
      direction="horizontal"
      gap="mini"
    >
      <SpacedItems.Item grow={1}>
        <SearchBox
          onSearchChange={onSearch}
          placeholder="Add a tag"
          value={searchTerm}
          styleVersion="v2"
        />
      </SpacedItems.Item>
      {isAdmin && (
        <SpacedItems.Item>
          <Button
            basic
            icon
            onClick={propFunc(doBeginManageLabels, '')}
            className={styles.button}
          >
            <Icon name="cog" />
          </Button>
        </SpacedItems.Item>
      )}
    </SpacedItems.Container>
  )
}
