import React from 'react'

import logo from 'assets/integrations/shopify.png'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import ShopifyDetail from './ShopifyDetail'
import ShopifyIndex from './ShopifyIndex'

import { styles } from './styles'

export default function ShopifyView({
  id,
  doHideCard,
  data,
  isShopifyLoading,
  name,
  onReload,
  integration,
  showCard,
  contactId,
}) {
  if (!showCard) return null

  return (
    <div css={styles.base}>
      <NavigatableIntegrationWidgetCard
        data={data}
        detailComponent={ShopifyDetail}
        doHideCard={doHideCard}
        image={logo}
        indexComponent={ShopifyIndex}
        isLoading={isShopifyLoading}
        title={name || 'Shopify'}
        persistenceKey={`shopify-${id}`}
        refreshable
        onRefresh={onReload}
        integration={integration}
        hidePadding
        key={contactId}
      />
    </div>
  )
}
