import React from 'react'
import Modal from 'shared/components/ui/Modal'
import config from 'config'
import { Loader } from 'util/ui'
import LegacyLink from './link'

const LegacyLinkWrapper = ({
  children,
  modalMessage,
  skipModal,
  isAccountExpired,
  ...rest
}) => {
  if (isAccountExpired) {
    return (
      <a className={rest.className} href="/settings/billing">
        {children}
      </a>
    )
  }
  if (!rest.subdomain || !rest.token) {
    const url = `${config.appUrl}/users/sign_in`
    return (
      <a className={rest.className} href={url} target={rest.target}>
        {children}
      </a>
    )
  }

  const link = <LegacyLink {...rest}>{children}</LegacyLink>
  if (skipModal) return link

  return (
    <Modal
      trigger={link}
      header={
        modalMessage || <Loader size={'large'} active inline="centered" />
      }
      confirmButton={false}
      cancelButton={false}
      closeIcon={false}
      wide
    />
  )
}

export default LegacyLinkWrapper
