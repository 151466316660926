import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  doCreateAndLinkJiraServerIssueToTicket,
  doFetchJiraServerProjects,
} from 'ducks/integrations/jiraServer'
import { selectJiraAreProjectsLoading } from 'ducks/integrations/jiraShared'

import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'
import { required } from 'util/validation'

import LinkNewJiraServerIssueModal from './view'

function mapStateToProps(state, { formFields: { project: projectId } }) {
  return {
    areProjectsLoading: selectJiraAreProjectsLoading(state, {
      source: 'jiraServer',
    }),
    projectId,
  }
}
const mapDispatchToProps = {
  doFetchJiraServerProjects,
  doCreateAndLinkJiraServerIssueToTicket,
}

export default compose(
  withOpenState,
  withProps({
    initialValues: { summary: '', project: null },
    validators: {
      description: required,
      project: required,
      summary: required,
      type: required,
    },
  }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkNewJiraServerIssueModal)
