import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const container = theme => css`
  display: flex;
  align-items: baseline;

  position: relative;
  color: ${theme.color.primary.brand};
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    display: block;
    height: 1px;
    background-color: currentColor;
  }
`

const input = theme => css`
  font-family: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  border: 0;
  outline: 0;
  width: 100%;
  resize: none;
  font-size: ${theme.spacing['12']};
  font-weight: ${theme.fontWeight.semibold};
  line-height: 1.333;
  font-size: ${rem(21)};
`

export const styles = {
  container,
  input,
}
