import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSubscriptionForId } from 'ducks/integrations/recharge/selectors'
import { formatDateForCurrentYear } from 'ducks/integrations/recharge/utils'
import { capture } from 'ducks/tracking/actions'
import { capitalize } from 'util/strings'

import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function ListItem({ subscriptionId, customerId }) {
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )

  const createdAt = useMemo(
    () =>
      formatDateForCurrentYear(subscription.created_at, '%d %b', '%d %b %Y'),
    [subscription]
  )

  const status = useMemo(
    () => {
      switch (subscription.status) {
        case 'active':
          return 'success'
        case 'expired':
          return 'warning'
        case 'cancelled':
          return 'negative'
        default:
          return ''
      }
    },
    [subscription.status]
  )

  const onClick = useCallback(
    () => {
      navigatableSetState({
        subscriptionId: subscription.id,
        customerId: subscription.customer_id,
        type: 'subscription',
      })
      capture('Recharge Subscription Viewed')
    },
    [navigatableSetState, subscription]
  )

  return (
    <NavigatableIntegrationWidgetCard.ListItem
      title={subscription.id}
      onClick={onClick}
      subTitle={createdAt}
      subTitleStatus={status}
      subTitleStatusText={capitalize(subscription.status)}
    />
  )
}
