import React from 'react'
import { KeyValue } from 'components/KeyValues'
import { shopifyMoneyString } from 'util/currency'

const ConditionalRow = ({ className, obj, text, negative = false }) => {
  const { shopMoney } = obj
  if (!shopMoney) return null
  if (!shopMoney.amount) return null
  if (parseFloat(shopMoney.amount) === 0) return null

  const { amount } = shopMoney

  return (
    <tr className={className}>
      <th scope="row">
        <KeyValue title={text} missingValue={null} />
      </th>
      <td className="shopifyV2-currency grui text-right">
        {shopifyMoneyString({
          ...shopMoney,
          amount: negative ? Math.abs(amount) * -1 : amount,
        })}
      </td>
    </tr>
  )
}

export default ConditionalRow
