import { useCallback, useMemo } from 'react'

import {
  doSetStateForGitHubIssue,
  doUnlinkGitHubIssue,
} from 'ducks/integrations/github'
import { useActions } from 'util/hooks'

export function useGitHubIssueActions(issue, doCloseIssueView) {
  const { id, errored, state, title, url } = issue || {}
  const isOpen = state === 'OPEN'
  const toggledState = isOpen ? 'CLOSED' : 'OPEN'
  const [onSetState, onUnlink] = useActions([
    doSetStateForGitHubIssue,
    doUnlinkGitHubIssue,
  ])
  const onToggleState = useCallback(() => onSetState(id, toggledState), [
    onSetState,
    id,
    toggledState,
  ])
  return useMemo(
    () => {
      if (!id) return []
      return [
        {
          action: () => {
            onUnlink(id, title, url)
            doCloseIssueView()
          },
          text: 'Unlink',
        },
        !errored && {
          action: onToggleState,
          text: isOpen ? 'Close' : 'Open',
        },
        {
          blankTarget: true,
          text: ['Open on GitHub'],
          url,
        },
      ]
    },
    [doCloseIssueView, errored, id, isOpen, onUnlink, onToggleState, title, url]
  )
}
