import { connect } from 'react-redux'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import { isBoostrappedSelector } from 'selectors/app/base'
import {
  selectCurrentTicketSearchQueryString,
  selectHasCurrentTicketSearchErrored,
  selectIsCurrentTicketSearchQueryValid,
} from 'selectors/search'
import { selectIsUpdateAvailable } from 'selectors/app'
import {
  selectIsTicketSelectionMode,
  selectTicketSelectionCount,
} from 'selectors/ticket_list'
import { selectMergingIsMerging } from 'ducks/merging/selectors/base'

import View from './view'

const select = state => {
  return {
    ticketId: selectCurrentTicketId(state),
    prefersClassicView: selectPrefersClassicView(state),
    isNewEmail: selectIsCreatingNewEmail(state),
    isNewConversation: selectIsLoggingNewConversation(state),
    isBootstrapped: isBoostrappedSelector(state),
    areTicketsSelected: selectTicketSelectionCount(state) > 0,
    hasSearchErrored: selectHasCurrentTicketSearchErrored(state),
    isBulkSelectionMode: selectIsTicketSelectionMode(state),
    isQueryStringValid: selectIsCurrentTicketSearchQueryValid(state),
    isUpdateAvailable: selectIsUpdateAvailable(state),
    isMerging: selectMergingIsMerging(state),
    queryString: selectCurrentTicketSearchQueryString(state),
  }
}

export default connect(select)(View)
