import React from 'react'

import Scroller from 'components/Scroller'

import { withResizeChange } from 'util/hoc'
import { getLength, sortByName } from 'util/arrays'
import { propFunc } from 'util/functions'
import { mod } from 'util/math'
import { ENTER, UP, DOWN, ESCAPE, TAB } from 'constants/keys'

import { SearchBox, SUI, Divider, TextLoader } from 'shared/ui'
import Header from './Header'
import GroupItem from './GroupItem'

import styles from './styles.css'

const ANY_GROUP = null

class SelectingTeamState extends React.Component {
  state = {
    activeIndex: 0,
    activeHoverIndex: null,
    searchTerm: '',
  }

  onKeyPressEscape = e => {
    this.props.close(e)
  }

  onKeyPressDown = () => {
    const { filteredGroups } = this.props
    const { activeIndex } = this.state
    const nextIndex = mod(activeIndex + 1, filteredGroups.length + 1)
    this.setState({ activeIndex: nextIndex })
  }

  onKeyPressUp = () => {
    const { filteredGroups } = this.props
    const { activeIndex } = this.state
    const nextIndex = mod(activeIndex - 1, filteredGroups.length + 1)
    this.setState({ activeIndex: nextIndex })
  }

  onTabOrEnter = () => {
    const { filteredGroups, drillDownOnTeam } = this.props
    const { activeIndex } = this.state
    if (activeIndex === (filteredGroups ? filteredGroups.length : 0)) {
      drillDownOnTeam(ANY_GROUP)
    } else {
      const group = filteredGroups[activeIndex]
      if (group) drillDownOnTeam(group.id)
    }
    this.cancel()
  }

  onKeyPressTab = this.onTabOrEnter
  onKeyPressEnter = this.onTabOrEnter

  cancel = () => {
    const { onCurrentTabClick, onFilterGroup } = this.props
    onCurrentTabClick()
    onFilterGroup('')
  }

  keyDownHandler = e => {
    if (e.keyCode === ESCAPE && this.onKeyPressEscape) this.onKeyPressEscape(e)
    if (e.keyCode === ENTER && this.onKeyPressEnter) this.onKeyPressEnter(e)
    if (e.keyCode === UP && this.onKeyPressUp) this.onKeyPressUp(e)
    if (e.keyCode === DOWN && this.onKeyPressDown) this.onKeyPressDown(e)
    if (e.keyCode === TAB && this.onKeyPressTab) this.onKeyPressTab(e)
  }

  handleSelect = groupId => {
    this.props.onGroupSelect(groupId)
    this.cancel()
  }

  get agentsInCurrentGroupWithoutYou() {
    const { filteredAgents: agentsInCurrentGroup, currentAgent } = this.props
    return agentsInCurrentGroup.filter(u => u.id !== currentAgent.id)
  }

  render() {
    const {
      ticket,
      allAgents,
      onCurrentTabClick,
      filteredGroups,
      onFilterGroup,
      selectedGroup,
      isLoading,
      windowHeight,
    } = this.props

    const { activeIndex } = this.state
    const assignedGroupId = ticket && ticket.assigned_group_id
    const sortedFilteredGroups = sortByName(filteredGroups)

    const headerHeight = 120
    const top = 8
    const bottom = 20
    const maxItemsHeight = 338
    const maxHeight = Math.min(
      windowHeight - headerHeight - top - bottom,
      maxItemsHeight
    )

    return (
      <div className={styles.assignmentModal}>
        <Header
          selectedGroupName={selectedGroup && selectedGroup.name}
          onCurrentTabClick={onCurrentTabClick}
          groupActive
        />

        <SearchBox
          className="grui ml-12 mt-10"
          onKeyDown={this.keyDownHandler}
          onKeyUp={this.keyUpHandler}
          onSearchChange={onFilterGroup}
          placeholder="Search..."
        />
        <SUI>
          <Divider />
        </SUI>

        {isLoading && <TextLoader />}
        {!isLoading && (
          <Scroller style={{ maxHeight }} className={styles.scroller}>
            {sortedFilteredGroups.map((group, index) => (
              <GroupItem
                agentCount={getLength(group.agents)}
                isActive={activeIndex === index}
                isCurrentChosen={
                  assignedGroupId && group.id === assignedGroupId
                }
                groupId={group.id}
                key={group.id}
                name={group.name}
                onClick={propFunc(this.handleSelect, group.id)}
              />
            ))}
            <GroupItem
              agentCount={getLength(allAgents)}
              allAgents={this.agentsInCurrentGroupWithoutYou}
              isActive={
                activeIndex ===
                (sortedFilteredGroups ? sortedFilteredGroups.length : 0)
              }
              isCurrentChosen={!assignedGroupId}
              isAnyTeam
              name={`Any ${app.t('Team')}`}
              onClick={propFunc(this.handleSelect, ANY_GROUP)}
            />
          </Scroller>
        )}
      </div>
    )
  }
}

const SelectingTeamStateWithResizingChange = withResizeChange(
  ({ height, ...rest }) => {
    return <SelectingTeamState {...rest} windowHeight={height} />
  }
)

export default SelectingTeamStateWithResizingChange
