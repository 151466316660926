import React from 'react'
import { Label } from 'shared/ui'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { styles } from './styles'

export default function HotkeysGroup({ title, hotkeys }) {
  return (
    <div css={styles.hotkeysGroupContainer}>
      <Label as="div" css={styles.title}>
        {title}
      </Label>

      <SpacedItems.Container direction="vertical" gap="mini">
        {Object.entries(hotkeys).map(([key, desctiption]) => (
          <SpacedItems.Item key={key} className="grui flex">
            <div css={styles.key}>{key}</div>
            <div>{desctiption}</div>
          </SpacedItems.Item>
        ))}
      </SpacedItems.Container>
    </div>
  )
}
