import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Mark from 'mark.js'
import { selectCurrentSubmittedTicketSearchQueryObject } from 'selectors/search/searchFilters'

// This component is used to highlight the search keywords in the ticket body
const BodySearchMarker = ({ node }) => {
  const { keywords = [] } = useSelector(
    selectCurrentSubmittedTicketSearchQueryObject
  )

  useEffect(
    () => {
      if (!node || !keywords.length) return
      const markInstance = new Mark(node)
      markInstance.unmark({
        done: () => {
          markInstance.mark(keywords, {
            accuracy: 'exactly',
          })
        },
      })
    },
    [node, keywords]
  )

  return null
}

export default BodySearchMarker
