import cn from 'classnames'
import styles from './styles.css'

export const UserItem = ({
  leftSide,
  rightSide,
  title,
  titleClass,
  hint,
  hintClass,
  className,
  onClick,
}) => (
  <div className={cn(className, styles.userItem)} onClick={onClick}>
    {leftSide}
    <div className={styles.userItem_center}>
      <div>
        <div className={cn(styles.userItem_title, titleClass)}>{title}</div>
        {hint !== title && (
          <div className={cn(styles.userItem_hint, hintClass)}>{hint}</div>
        )}
      </div>
    </div>
    {rightSide}
  </div>
)

export default UserItem
