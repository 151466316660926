/* eslint-disable react/no-multi-comp */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import { selectContactDealsForEmail } from 'ducks/integrations/hubspot/selectors'

import Properties from './Properties'

export default function HubSpotDetail({
  email,
  doNavigatableClearState,
  ownersById,
  navigatableState: dealId,
  settings,
}) {
  const specificFields = extractSpecificFields(
    settings,
    /showSidebarField.Deal\./
  )
  const dealsObject = useSelector(state => {
    return selectContactDealsForEmail(state, email)
  })
  const deal = useMemo(
    () =>
      dealsObject.deals &&
      dealsObject.deals.find(({ id }) => {
        return id === dealId
      }),
    [dealsObject, dealId]
  )
  if (!deal) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      onBack={doNavigatableClearState}
      title="HubSpot Detail"
    >
      <Properties
        properties={deal.properties}
        specificFields={specificFields}
        ownersById={ownersById}
      />
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
