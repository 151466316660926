import { useSelector } from 'react-redux'
import { selectIsInChat } from 'selectors/location'

import withSidebarModalPositioning from '../../../../withSidebarModalPositioning'
import CopyButton from '../../../CustomFieldKeyValue/CopyButton'
import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'
import ContactHeaderFieldsEditButton from './ContactHeaderFieldsEditButton'
import ContactNameAndEmail from './ContactNameAndEmail'
import ChangeContactButton from './ChangeContactButton'

export default withSidebarModalPositioning(ContactHeaderFieldsWithMenuPosition)

function ContactHeaderFieldsWithMenuPosition({
  doRecalc,
  doSetMenu,
  email,
  firstName,
  lastName,
  menuRef,
  offset,
  openMenu,
  subjectId,
  onCancel,
}) {
  const isChat = useSelector(selectIsInChat)
  const avatarUrl = useSelectCustomFieldValueForKeyAndType(
    'contact_avatar',
    'CONTACT',
    'url'
  )
  return (
    <>
      <ContactNameAndEmail />
      {email && (
        <CopyButton
          text={email}
          message="The contact's email was copied to your clipboard."
        />
      )}
      <ContactHeaderFieldsEditButton
        avatarUrl={avatarUrl}
        doRecalcMenuPosition={doRecalc}
        doSetMenu={doSetMenu}
        firstName={firstName}
        lastName={lastName}
        menuRef={menuRef}
        openMenu={openMenu}
        offset={offset}
        subjectId={subjectId}
        onCancel={onCancel}
      />
      {!isChat && (
        <ChangeContactButton
          doSetMenu={doSetMenu}
          menuRef={menuRef}
          offset={offset}
          openMenu={openMenu}
          subjectId={subjectId}
        />
      )}
    </>
  )
}
