import React from 'react'
import cn from 'classnames'
import AssignmentButtonView from 'components/ConversationHeader/Buttons/AssignmentButton'
import { IconButton } from 'shared/ui'

const AssignmentButton = React.memo(
  ({
    assignmentOpen,
    isAssigned,
    assignedGroupId,
    assignmentAgentName,
    onAssignmentIconClick,
    onAssignmentDropdownClose,
    AssignmentDropdown = AssignmentButtonView,
    isExpired = false,
  }) => {
    let tooltip = isAssigned
      ? `Reassign (${assignmentAgentName}) [a]`
      : 'Assign [a]'

    if (isExpired) {
      tooltip = "Can't Reassign an expired conversation"
    }

    return (
      <div className="assignment">
        {!assignmentOpen && (
          <IconButton
            name="chatBubbleUser"
            onClick={onAssignmentIconClick}
            className={cn('menu-button', {
              assigned: isAssigned,
            })}
            disabled={isExpired}
            size="medium"
            label={
              isAssigned && assignmentAgentName
                ? assignmentAgentName
                : undefined
            }
            tooltip={tooltip}
            svg
          />
        )}
        {assignmentOpen && (
          <AssignmentDropdown
            direction="right"
            drilledDownGroupId={assignedGroupId}
            key="assignment"
            trigger={
              <IconButton
                name="chatBubbleUser"
                size="medium"
                className={cn('menu-button', { assigned: isAssigned })}
                label={
                  isAssigned && assignmentAgentName
                    ? assignmentAgentName
                    : undefined
                }
                svg
              />
            }
            open
            onClose={onAssignmentDropdownClose}
          />
        )}
      </div>
    )
  }
)

export default AssignmentButton
