import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import CollapsableCard from 'components/CollapsableCard'
import { selectCurrentContactId } from 'ducks/crm/contacts'
import { selectCurrentTicketMailbox } from 'selectors/tickets/mailbox'
import { useCustomProfile } from 'ducks/integrations/customProfile'
import { IconButton } from 'shared/ui'
import {
  selectAccountPrefersUnsafeCustomProfile,
  selectAccountPreferenceCustomProfileAppInstalled,
} from 'selectors/app'

import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'

import Content from './Content'

export default function CustomProfile({ doHideCard, width }) {
  const contactId = useSelector(selectCurrentContactId)
  const mailbox = useSelector(selectCurrentTicketMailbox)
  const mailboxId = mailbox?.gid
  const { isLoading, isFailed, doLoad, content } = useCustomProfile(
    contactId,
    mailboxId
  )
  const isSafe = !useSelector(selectAccountPrefersUnsafeCustomProfile)
  const showCustomProfile = useSelector(
    selectAccountPreferenceCustomProfileAppInstalled
  )
  const onRefresh = useCallback(
    event => {
      event.stopPropagation()
      doLoad({ hard: true })
    },
    [doLoad]
  )

  if (!showCustomProfile) return null
  return (
    <CollapsableCard
      persistenceKey={'customWidget.custom_profile'}
      title={
        <SortableVisibleControlsWithTitle
          onHide={doHideCard}
          title="Custom profile"
        >
          <IconButton onClick={onRefresh} name="redo" size="tiny" svg />
        </SortableVisibleControlsWithTitle>
      }
    >
      <Content
        isFailed={isFailed}
        isLoading={isLoading}
        isSafe={isSafe}
        onRetry={doLoad}
        text={content}
        width={width}
      />
    </CollapsableCard>
  )
}
