import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsEditingTicketTitle } from 'selectors/app'
import EditableSubjectEditingState from './EditingState'
import EditableSubjectNormalState from './NormalState'

const EditableSubject = ({ ticketId }) => {
  const isEditingSubject = useSelector(selectIsEditingTicketTitle)
  if (isEditingSubject)
    return <EditableSubjectEditingState ticketId={ticketId} />

  return <EditableSubjectNormalState ticketId={ticketId} />
}

export default EditableSubject
