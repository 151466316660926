import React, { useMemo } from 'react'
import { CHANNEL_EMAIL_OPTIONS } from 'subapps/settings/components/drawers/Channels/Channels.data'
import ChannelOption from './ChannelOption'
import { styles } from './styles'

const Footer = ({ className, onClickCreateBtn }) => {
  const channelList = useMemo(() => {
    return Object.keys(CHANNEL_EMAIL_OPTIONS).map(provider => {
      const channel = {
        provider,
        ...CHANNEL_EMAIL_OPTIONS[provider],
      }
      return channel
    })
  }, [])

  return (
    <div css={styles.footer} className={className}>
      {channelList.map(channel => (
        <ChannelOption
          onClickCreateBtn={onClickCreateBtn}
          key={channel.provider || channel.type}
          title={channel.title}
          icon={channel.icon}
          type={channel.type}
          provider={channel.provider}
        />
      ))}
    </div>
  )
}

export default Footer
