import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
  selectJiraAreTypesLoading,
  selectJiraTypesAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionImageIcon from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionImageIcon'

export default connect((state, { projectId, siteId }) => {
  return {
    areTypesLoading: selectJiraAreTypesLoading(state, { source: 'jiraCloud' }),
    types: selectJiraTypesAsOptions(state, {
      projectId,
      siteId,
      source: 'jiraCloud',
    }),
  }
})(TypesFormField)

function TypesFormField({
  areTypesLoading,
  isProjectChoosen,
  formFields: { type },
  onChange,
  resetFields,
  siteId,
  types,
}) {
  useEffect(
    () => {
      resetFields('types')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteId]
  )
  return (
    <FormRow split>
      <Label as="div">Type</Label>
      <ValidatedField
        disabled={!isProjectChoosen || areTypesLoading}
        fluid
        loading={areTypesLoading}
        name="type"
        options={
          types &&
          types
            .map(option => {
              return {
                ...option,
                icon: (
                  <OptionImageIcon alt={option.text} src={option.iconUrl} />
                ),
              }
            })
            .filter(option => option.text !== 'Epic')
        }
        onChange={onChange}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={type}
      />
    </FormRow>
  )
}
