import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
  doFetchJiraCloudAssignableUsers,
  selectCreateIssueTypeFieldsFieldMeta,
} from 'ducks/integrations/jiraCloud'

import {
  selectJiraAreAssignableUsersLoading,
  selectJiraAssignableUsersAsOptions,
} from 'ducks/integrations/jiraShared'
import { ASSIGNEE } from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import Login from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Login'
import Name from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Name'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'
import JiraCloudOptionAvatar from '../../../../JiraCloudOptionAvatar'
import renderAssigneeLabel from './renderAssigneeLabel'

export default connect(
  (state, { formFields: { type: issueTypeId, project: projectId } }) => ({
    areAssignableUsersLoading: selectJiraAreAssignableUsersLoading(state, {
      source: 'jiraCloud',
    }),
    assignableUsers: selectJiraAssignableUsersAsOptions(state, {
      source: 'jiraCloud',
    }),
    fieldMeta: selectCreateIssueTypeFieldsFieldMeta(state, {
      projectId,
      issueTypeId,
      fieldKey: ASSIGNEE,
    }),
  }),
  { doFetchJiraCloudAssignableUsers }
)(AssigneeFormField)

function AssigneeFormField({
  assignableUsers = [],
  areAssignableUsersLoading,
  doFetchJiraCloudAssignableUsers: fetchAssignableUsers,
  formFields: { assignee, type },
  isProjectChoosen,
  onChange,
  projectId,
  resetFields,
  siteId,
  fieldMeta,
}) {
  useEffect(
    () => {
      if (siteId && projectId) {
        fetchAssignableUsers(siteId, projectId)
      }
    },
    [fetchAssignableUsers, projectId, resetFields, siteId]
  )
  useEffect(
    () => {
      resetFields('assignee')
    },
    [type, siteId, projectId, resetFields]
  )

  // this field is not supported on JIRA for the project + issue type combo
  if (!fieldMeta) return null

  return (
    <FormRow>
      <Label as="div">
        Assignees&nbsp;
        {!fieldMeta.required && <OptionalLabel>(optional)</OptionalLabel>}
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || areAssignableUsersLoading}
        fluid
        loading={areAssignableUsersLoading}
        name="assignee"
        options={assignableUsers.map(option => {
          return {
            ...option,
            children: (
              <React.Fragment>
                <JiraCloudOptionAvatar url={option.avatarUrl} />
                <Login>{option.text}</Login> <Name>{option.value}</Name>
              </React.Fragment>
            ),
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        renderLabel={renderAssigneeLabel}
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={isProjectChoosen && assignee ? assignee : null}
      />
    </FormRow>
  )
}
