import React from 'react'

import LoginLayout from 'components/LoginLayout'
import ForgotSubdomainForm from './Form'

export default function ForgotSubdomainPage() {
  return (
    <LoginLayout
      showLoginLink
      tagline="Forgot your account name? No problem. Just enter your email address below and we’ll send you a link to access your account."
    >
      <ForgotSubdomainForm />
    </LoginLayout>
  )
}
