import React from 'react'
import { connect } from 'react-redux'

import {
  selectJiraAreProjectsLoading,
  selectJiraProjectsAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import Avatar from './Avatar'

export default connect(state => ({
  areProjectsLoading: selectJiraAreProjectsLoading(state, {
    source: 'jiraCloud',
  }),
  projects: selectJiraProjectsAsOptions(state, { source: 'jiraCloud' }),
}))(ProjectFormField)

function ProjectFormField({
  areProjectsLoading,
  onChange,
  formFields: { project },
  projects = [],
}) {
  return (
    <FormRow split>
      <Label as="div">Project</Label>
      <ValidatedField
        disabled={areProjectsLoading}
        fluid
        name="project"
        options={projects.map(option => {
          return {
            ...option,
            children: (
              <React.Fragment>
                <Avatar url={option.avatarUrl} size={24} />
                {option.text}
              </React.Fragment>
            ),
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={project}
      />
    </FormRow>
  )
}
