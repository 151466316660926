import React from 'react'

import { shouldStartExpanded } from './util'
import QuotedBody from './QuotedBody'
import TextBody from './TextBody'

const Body = ({ change, isForward, isFirstMessage, onOpenImage }) => {
  const isBodyEmpty = change.isBodyEmpty

  return (
    <React.Fragment>
      {isBodyEmpty && (
        <div className="empty-body">No content in this message</div>
      )}
      {!isBodyEmpty &&
        change.parsedBody.map((part, index) => {
          if (part.type === 'quoted') {
            const startExpanded = shouldStartExpanded(
              isForward,
              isFirstMessage,
              change.parsedBody,
              index
            )

            return (
              <QuotedBody
                // eslint-disable-next-line react/no-array-index-key
                key={`quoted-${index}`}
                text={part.text}
                collapsed={!startExpanded}
              />
            )
          }
          return (
            <TextBody
              // eslint-disable-next-line react/no-array-index-key
              key={`text-${index}`}
              text={part.text}
              onOpenImage={onOpenImage}
            />
          )
        })}
    </React.Fragment>
  )
}

export default Body
