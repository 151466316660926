import React, { useCallback } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import KeyboardNavigator from 'components/KeyboardNavigator'
import RecipientItem from './RecipientItem'

const DropdownMenuItem = ({ recipient, onSelect, onActivate }) => {
  const handleActivate = useCallback(
    e => {
      onActivate(recipient.id, e)
    },
    [onActivate, recipient]
  )

  return (
    <Dropdown.Menu.Item
      itemKey={recipient.id}
      item={recipient}
      as={KeyboardNavigator.Item}
      activeClassName="highlighted"
      activeClassNameOverridden
      // Click handler
      onSelect={onSelect}
      onActivate={handleActivate}
    >
      <RecipientItem recipient={recipient} />
    </Dropdown.Menu.Item>
  )
}

export default DropdownMenuItem
