import { useSelector } from 'react-redux'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import { selectRelatedObjectsByObjectId } from 'ducks/integrations/salesforce/selectors'

import Properties from './Properties'

const TYPE_PLURAL = {
  Task: 'Tasks',
  Opportunity: 'Opportunities',
}
export default function SalesforceDetail({
  doNavigatableClearState,
  navigatableState,
  settings,
}) {
  const relatedById = useSelector(selectRelatedObjectsByObjectId)
  if (!navigatableState) return null
  const [parentId, objectType, objectId] = navigatableState.split(':')
  const parent = relatedById[parentId]
  if (!parent) return null
  const objects = parent.byType[TYPE_PLURAL[objectType]]
  if (!objects) return null
  const object = objects.find(({ id }) => id === objectId)
  if (!object) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      onBack={doNavigatableClearState}
      title={object.label}
    >
      <Properties
        properties={object.properties}
        settings={settings}
        specificFieldsPattern={`showSidebarField.${objectType}\\.`}
      />
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
