import React from 'react'
import cn from 'classnames'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { IconButton } from 'shared/ui'
import styles from './styles.less'

function RightSidebarToggler({
  doToggle,
  isClassicView,
  isCollapsed,
  isInChat,
}) {
  return (
    <React.Fragment>
      <ListenToKeyboard onQuote={doToggle} preventDefault disableForInput />
      <IconButton
        name="showHide"
        svg
        onClick={doToggle}
        className={cn(styles.toggleExpandCollapseButton, {
          [styles['toggleExpandCollapseButton--classicView']]:
            isClassicView && !isInChat,
          [styles['toggleExpandCollapseButton--collapsed']]: isCollapsed,
          [styles['toggleExpandCollapseButton--classicView--withTopNav']]:
            isClassicView && !isInChat,
        })}
        size="small"
        iconSize={14}
      />
    </React.Fragment>
  )
}

export default RightSidebarToggler
