import { useCallback, useEffect, useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { capture } from 'ducks/tracking/actions'
import { isEmpty } from 'util/strings'
import { doUpdatePreferences } from 'ducks/currentUser/operations'
import { styles as textStyles } from '@groovehq/internal-design-system/lib/styles/elements/Text/text.styles'
import moment from 'moment'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import CloseIcon from '@groovehq/internal-design-system/lib/assets/icons/Close'

import NpsSurveyButton from './NpsSurveyButton'
import { styles } from './styles'

const SURVEY_SHOWN = 'survey shown'
const SURVEY_SENT = 'survey sent'
const SURVEY_DISMISSED = 'survey dismissed'

const NEXT_NPS_IN_DAYS = 90

const LOW_SCORES = [1, 2, 3, 4]
const NEUTRAL_SCORES = [5, 6, 7]

const NpsForm = ({ survey, setSurvey, isShownCaptured }) => {
  const dispatch = useDispatch()

  const [score, setScore] = useState(null)
  const [comment, setComment] = useState('')

  const captureEvent = useCallback(
    eventName => {
      const params = {}

      if (!survey) return

      params.$survey_id = survey.id
      params.$survey_name = survey.name

      if (eventName === SURVEY_SENT) {
        params.$survey_response = score
        params.$set = {
          nps_global_score: score,
          nps_global_recorded_at: moment().unix(),
        }

        if (!isEmpty(comment)) {
          params.survey_comment = comment
          params.$set.nps_global_comment = comment
        }
      }

      capture(eventName, params)
    },
    [comment, score, survey]
  )

  const closeSurvey = useCallback(
    () => {
      dispatch(
        doUpdatePreferences(
          {
            nps_next_timestamp: moment()
              .add(NEXT_NPS_IN_DAYS, 'days')
              .unix(),
          },
          { moduleOptions: { toasts: { enabled: false } } }
        )
      )

      setSurvey(null)
    },
    [dispatch, setSurvey]
  )

  const onSubmit = useCallback(
    () => {
      captureEvent(SURVEY_SENT)
      closeSurvey()
    },
    [captureEvent, closeSurvey]
  )

  const onDismiss = useCallback(
    () => {
      captureEvent(SURVEY_DISMISSED)
      closeSurvey()
    },
    [captureEvent, closeSurvey]
  )

  const onCommentChange = useCallback(
    event => {
      setComment(event.target.value)
    },
    [setComment]
  )

  useEffect(
    () => {
      if (survey && !isShownCaptured.current) {
        captureEvent(SURVEY_SHOWN)
        isShownCaptured.current = true // eslint-disable-line no-param-reassign
      }
    },
    [survey, captureEvent, isShownCaptured]
  )

  return (
    <div className="grui w-100 p-8 absolute text-center" css={styles.wrapper}>
      <Button
        type="icon"
        size="small"
        css={styles.closeButton}
        onClick={score === null ? onDismiss : onSubmit}
      >
        <CloseIcon />
      </Button>

      <h3
        className="grui m-0 mb-8"
        css={[textStyles.textSm, textStyles.fontMedium]}
      >
        How likely are you to recommend Groove to a friend or colleague?
      </h3>

      <div className="grui flex items-center justify-center">
        <span className="grui mr-8">Not likely</span>

        <NpsSurveyButton value="1" selectedScore={score} onSelect={setScore} />
        <NpsSurveyButton
          value="2"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="3"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="4"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="5"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="6"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="7"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="8"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="9"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />
        <NpsSurveyButton
          value="10"
          selectedScore={score}
          onSelect={setScore}
          className="grui ml-4"
        />

        <span className="grui ml-8">Very likely</span>
      </div>

      {score !== null && (
        <>
          {LOW_SCORES.includes(parseInt(score, 10)) && (
            <p
              className="grui flex mt-8 mb-0 ml-auto mr-auto text-left"
              css={[textStyles.textSm, styles.textWrapper]}
            >
              {`We hate to see that low score but it's our mission to create the
              best experience possible for you. Please add your honest
              feedback below. The more honest the better!`}
            </p>
          )}
          {NEUTRAL_SCORES.includes(parseInt(score, 10)) && (
            <p
              className="grui flex mt-8 mb-0 ml-auto mr-auto text-left"
              css={[textStyles.textSm, styles.textWrapper]}
            >
              {`Please tell us how we can make Groove 10x better for you.
              The more honest the better!`}
            </p>
          )}

          <Textarea
            value={comment}
            className="grui mt-8"
            css={styles.textarea}
            name=""
            autoSize={{ minRows: 6 }}
            placeholder={`Tell us a bit more why you chose ${score} as your score?`}
            onChange={onCommentChange}
          />

          <Button className="grui mt-8" size="medium" onClick={onSubmit}>
            Submit
          </Button>
        </>
      )}
    </div>
  )
}

export default memo(NpsForm)
