import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import logo from 'assets/integrations/hubspot/icon-24.png'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'
import {
  selectIntegrationSettingsForId,
  selectCanDisplayForCurrentConversation,
} from 'ducks/integrations/selectors'
import doFetchContact from 'ducks/integrations/hubspot/operations/doFetchContact'
import {
  selectOwnersById,
  selectContactByEmail,
} from 'ducks/integrations/hubspot/selectors'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import HubSpotDetail from './HubSpotDetail'
import HubSpotIndex from './HubSpotIndex'

export default function HubSpotWidgetCard({ id, doHideCard }) {
  const dispatch = useDispatch()
  const email = useSelector(selectCurrentContactOrCustomerEmail)
  const contactObject = useSelector(state => selectContactByEmail(state, email))

  useEffect(
    () => {
      if (email) dispatch(doFetchContact(email))
    },
    [dispatch, email]
  )

  const settings = useSelector(state => {
    return selectIntegrationSettingsForId(state, id)
  })

  const ownersById = useSelector(selectOwnersById)

  const canShow = useSelector(state =>
    selectCanDisplayForCurrentConversation(state, id)
  )

  if (!canShow) return null

  return (
    <NavigatableIntegrationWidgetCard
      id={id}
      contactObject={contactObject}
      detailComponent={HubSpotDetail}
      doHideCard={doHideCard}
      email={email}
      image={logo}
      indexComponent={HubSpotIndex}
      ownersById={ownersById}
      persistenceKey={`hubspot`}
      settings={settings}
      title="HubSpot"
    />
  )
}
