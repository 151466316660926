import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'

import { avatarURL, initials as getInitials } from 'util/actors'

import styles from './styles.css'

const Avatar = ({
  url,
  initials,
  className,
  size = 35,
  fontSize,
  children,
  style,
  width,
  height,
  borderRadius,
  ...rest
}) => {
  let finalSize = size
  let finalFontSize = fontSize
  if (size !== undefined && size !== null && !(width && height)) {
    const [, sizeNumber, sizeUnit = 'px'] = size
      .toString()
      // eslint-disable-next-line no-useless-escape
      .match(/([0-9\.]+)([a-z]+)?$/i)
    finalSize = sizeNumber + sizeUnit
    if (!fontSize && sizeUnit.toLowerCase() === 'px') {
      finalFontSize = sizeNumber
      if (parseInt(sizeNumber, 10) < 35) {
        finalFontSize = Math.floor(sizeNumber / 2)
      }
    }
  }
  const finalWidth = width ? `${width}px` : finalSize
  const finalHeight = height ? `${height}px` : finalSize
  const finalBorderRadius = borderRadius ? `${borderRadius}px` : '50%'

  return (
    <div
      className={cn(styles.avatar, className, {
        [styles['avatar-withImage']]: !!url,
      })}
      style={{
        width: finalWidth,
        height: finalHeight,
        ...(url && {
          backgroundImage: `url(${CSS.escape(url)})`,
        }),
        // Gracefully handle 2 initials on smaller sizes.
        fontSize: finalFontSize,
        lineHeight: finalSize,
        borderRadius: finalBorderRadius,
        ...style,
      }}
      {...rest}
    >
      {initials}
      {children}
    </div>
  )
}

export const AvatarForActor = ({ missingUrl, actor, user, ...rest }) => {
  if (user) {
    console.warn('`user` is deprecated. Use `actor` instead') // eslint-disable-line
  }

  return (
    <Avatar
      url={avatarURL(actor || user) || missingUrl}
      initials={getInitials(actor || user)}
      {...rest}
    />
  )
}

export default styled(AvatarForActor)``
