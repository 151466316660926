import React from 'react'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'

const NoCustomer = React.memo(() => {
  return (
    <div className="grui p-7">
      <EmptyMessage>There is no customer information</EmptyMessage>
    </div>
  )
})

export default NoCustomer
