import { connect } from 'react-redux'
import { redirect } from 'redux-first-router'
import {
  selectDraftIdByTicketId,
  makeDraftPropOrDefaultSelector,
} from 'ducks/drafts2/selectors'
import {
  doOpenReplyDirectPage,
  doOpenTicketPage,
  doOpenForwardPageExpanded,
} from 'actions/pages'
import { selectIsForwarding } from 'selectors/page'
import SwitcherButton from './view'

const select = (state, props) => {
  const { ticketId } = props
  const draftId = selectDraftIdByTicketId(state, ticketId, 'reply')

  const selectDraftPropByName = makeDraftPropOrDefaultSelector(
    state,
    draftId,
    ticketId
  )

  const isForwardingFromDraft = selectDraftPropByName('isForwarding')
  const isForwardingFromQuery = selectIsForwarding(state)

  return {
    replyType: selectDraftPropByName('replyType') || 'reply-all',
    isForwarding: isForwardingFromDraft || isForwardingFromQuery,
  }
}

const performFactory = (initialState, initialOwnProps) => {
  const { ticketId } = initialOwnProps

  return dispatch => ({
    doOpenReplyDirectPage: expand =>
      dispatch(
        redirect(doOpenReplyDirectPage(ticketId, { expand, show: ['subject'] }))
      ),
    doOpenTicketPage: expand =>
      dispatch(
        redirect(doOpenTicketPage(ticketId, { expand, show: ['subject'] }))
      ),
    doOpenForwardPageExpanded: () =>
      dispatch(
        redirect(doOpenForwardPageExpanded(ticketId, { show: ['subject'] }))
      ),
  })
}

export default connect(select, performFactory)(SwitcherButton)
