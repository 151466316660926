import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'util/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

const Title = styled('div')`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const SwitcherIcon = styled(Icon)`
  flex-grow: 0;
`

const TitleBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => props.theme.spacing['10']};
  height: 40px;
  border-bottom: solid 1px ${props => props.theme.color.monochrome.medium};
  cursor: pointer;
`

const Content = styled('div')`
  padding: ${props => props.theme.spacing['10']};
`
const Container = styled('div')`
  background-color: white;
  border-radius: ${themeVars.defaultBorderRadius};
  margin-bottom: ${props => props.theme.spacing['5']};
  width: 100%;
  border: solid 1px ${props => props.theme.color.monochrome.medium};
`

export default class Card extends React.Component {
  state = {
    open: false,
  }

  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { title, children } = this.props
    return (
      <Container>
        <TitleBar onClick={this.handleClick}>
          <Title>{title}</Title>
          <SwitcherIcon
            name={`chevron ${this.state.open ? 'down' : 'right'}`}
            size="small"
          />
        </TitleBar>
        {children && this.state.open && <Content>{children}</Content>}
      </Container>
    )
  }
}
