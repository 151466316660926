import React, { createContext, useCallback, useState, useMemo } from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import { CSSTransition } from 'react-transition-group'

import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import AnimateAutoHeight from 'components/AnimateAutoHeight'
import CollapsableCard from 'components/CollapsableCard'
import SortableVisibleControlsWithTitle from 'components/crm/ContactDetailsColumn/SortableVisibleControlsWithTitle'
import { Label, Loader } from 'shared/ui'

import AnimatedLeftRight from './AnimatedLeftRight'
import Detail from './Detail'
import List from './List'
import ListItem from './ListItem'
import HeaderLabel from './HeaderLabel'
import Footer from './Footer'
import ShowMore from './ShowMore'

const ANIMATION_DURATION = 300

const styledComponent = styled(NavigatableIntegrationWidgetCard)`
  .NavigatableIntegrationWidgetCard-Detail-icon {
    font-size: ${props => props.theme.spacing['6']};
    color: ${props => props.theme.color.monochrome.mediumDark};
    cursor: pointer;
    margin-right: ${props => props.theme.spacing['5']};
    line-height: ${props => props.theme.spacing['7']};
  }

  a .NavigatableIntegrationWidgetCard-Detail-icon {
    color: inherit;
  }

  .NavigatableIntegrationWidgetCard-Detail-title {
    font-size: ${props => props.theme.spacing['7']};
    font-weight: ${props => props.theme.fontWeight.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .NavigatableIntegrationWidgetCard-Detail-titleIconButton {
    background-color: ${props => props.theme.color.monochrome.superLight};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: rgb(79, 93, 106);
    padding: ${props =>
      `${props.theme.spacing['3']} ${props.theme.spacing['5']} ${
        props.theme.spacing['3']
      } ${props.theme.spacing['3']} `};
    border-radius: ${props => props.theme.spacing['3']};
    border: 1px solid ${props => props.theme.color.monochrome.medium};
    display: inline-block;
    font-family: ${props => props.theme.font.primary} !important;

    &:hover {
      border-color: ${props => props.theme.color.monochrome.mediumDark};
    }
  }

  .NavigatableIntegrationWidgetCard-List {
    margin: ${props => props.theme.spacing['5']} -${props =>
  props.theme.spacing['7']} ${props => props.theme.spacing['7']};
    ${Loader} {
      margin-top: ${props => props.theme.spacing['5']};
    }

    &.hideMargin {
      margin: 0;
    }
  }

  .NavigatableIntegrationWidgetCard-ListItem {
    padding: 6px ${props => props.theme.spacing['7']} 9px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: #e3f6fe;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
    }
  }

  .NavigatableIntegrationWidgetCard-ListItem-iconColumn {
    flex-grow: 0;
    flex-shrink: 0;

    .icon {
      font-size: ${props => props.theme.spacing['6']};
      color: ${props => props.theme.color.monochrome.mediumDark};
    }
  }
  .NavigatableIntegrationWidgetCard-ListItem-titleAndSubTitleColumn {
    flex-grow: 1;
    min-width: 0;

    .NavigatableIntegrationWidgetCard-ListItem-title,
    .NavigatableIntegrationWidgetCard-ListItem-subTitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.2em;
    }

    .NavigatableIntegrationWidgetCard-ListItem-title {
      color: ${props => props.theme.color.primary.brand};
      font-size: ${props => props.theme.spacing.base};
      line-height: ${props => props.theme.spacing['11']};
    }

    .NavigatableIntegrationWidgetCard-ListItem-subTitle {
      font-size: ${props => props.theme.fontSize.xsmall};
      line-height: ${props => props.theme.spacing['10']};
    }

    .NavigatableIntegrationWidgetCard-ListItem-statusText {
      margin-left: 6px;
      padding-left: 6px;
      position: relative;
      font-size: ${props => props.theme.fontSize.xsmall};

      &:before {
        content: '';
        top: 7px;
        left: -1px;
        width: 2px;
        height: 2px;
        position: absolute;
        background-color: ${props => props.theme.color.monochrome.black};
      }

      &-positive {
        color: ${props => props.theme.color.positive['400']};
      }

      &-warning {
        color: ${props => props.theme.color.warning['500']};
      }

      &-negative {
        color: ${props => props.theme.color.negative['400']};
      }

      &-neutral {
        color: ${props => props.theme.color.monochrome.mediumDark};
      }
    }
  }

  .NavigatableIntegrationWidgetCard-HeaderLabel {
    color: ${props => props.theme.color.grey[500]};
    font-size: ${props => props.theme.fontSize.xsmall};
    font-weight: ${props => props.theme.fontWeight.medium};
    background: ${props => props.theme.color.monochrome.superLight};
    line-height: 24px;
    padding: 0 12px;
  }

  .NavigatableIntegrationWidgetCard-Footer {
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: ${props => props.theme.color.monochrome.light};
  }

  .NavigatableIntegrationWidgetCard-ShowMore {
    padding: 5px;
    padding-right: 10px;
    font-size: ${props => props.theme.fontSize.tiny};
    font-weight: ${props => props.theme.fontWeight.medium};
    text-align: right;
    min-height: 11px;
    color: ${props => props.theme.color.monochrome.mediumDark};

    & > span {
      color: ${props => props.theme.color.monochrome.mediumDark};
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.color.primary.brand};
      }
    }
  }

  ${Label} {
    display: block;
    margin-top: 16px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  ${Label} + ${EmptyMessage} {
    margin-top: 8px;
  }

  .ui.tabular.menu {
    max-width: none;
    width: auto !important;
    border-top: 1px solid ${props => props.theme.color.monochrome.medium};
    border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
    border-radius: 0;
    margin-bottom: 0;
    justify-content: center;
    .item, .item.active {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0 !important;
      border-top: 0;
      border-right: 1px solid transparent;
      border-bottom: 0;
      border-left: 1px solid ${props => props.theme.color.monochrome.medium};
      flex: 1;
      justify-content: center;
      &:first-of-type {
        border-left: 1px solid transparent;
      }
    }
    .item.active {
      font-weight: ${props => props.theme.fontWeight.medium};
    }
  }

`

export const NavigatableIntegrationWidgetCardContext = createContext()

export default styledComponent

styledComponent.Detail = Detail
styledComponent.List = List
styledComponent.ListItem = ListItem
styledComponent.AnimatedLeftRight = AnimatedLeftRight
styledComponent.HeaderLabel = HeaderLabel
styledComponent.Footer = Footer
styledComponent.ShowMore = ShowMore

function NavigatableIntegrationWidgetCard(props) {
  const {
    detailComponent: DetailComponent,
    doHideCard,
    className,
    image,
    indexComponent: IndexComponent,
    persistenceKey,
    title,
    unmountOnExit = true,
    refreshable = false,
    onRefresh,
    isLoading,
    hidePadding = false,
  } = props
  const [state, setState] = useState(null)
  const [deferedState, setDeferedState] = useState(null)
  const [isInTransition, doSetIsInTransition] = useState(false)
  const setStateAndDeferedState = useCallback(
    newState => {
      setState(newState)
      const timeout = setTimeout(
        () => setDeferedState(newState),
        ANIMATION_DURATION
      )
      return () => clearTimeout(timeout)
    },
    [setState, setDeferedState]
  )
  const setStateNow = useCallback(
    newState => {
      setState(newState)
      setDeferedState(newState)
    },
    [setState, setDeferedState]
  )
  const doNavigatableClearState = useCallback(
    () => setStateAndDeferedState(null),
    [setStateAndDeferedState]
  )
  const onTransitioning = useCallback(() => doSetIsInTransition(true), [
    doSetIsInTransition,
  ])
  const onTransitioned = useCallback(() => doSetIsInTransition(false), [
    doSetIsInTransition,
  ])

  const navigatableContext = useMemo(
    () => ({
      navigatableClearState: doNavigatableClearState,
      navigatableSetState: setStateNow,
      navigatableState: deferedState,
    }),
    [doNavigatableClearState, setStateNow, deferedState]
  )

  return (
    <CollapsableCard
      className={cn('NavigatableIntegrationWidgetCard', className)}
      hideOverflow={isInTransition}
      hidePadding={hidePadding}
      image={image}
      persistenceKey={persistenceKey}
      isLoading={isLoading}
      title={
        <SortableVisibleControlsWithTitle
          title={title}
          onHide={doHideCard}
          refreshable={refreshable}
          onRefresh={onRefresh}
        />
      }
    >
      <AnimateAutoHeight duration={ANIMATION_DURATION}>
        <AnimatedLeftRight duration={ANIMATION_DURATION}>
          <CSSTransition
            className="index"
            classNames="index"
            in={state === null}
            onEntering={onTransitioning}
            onEntered={onTransitioned}
            onExiting={onTransitioning}
            onExited={onTransitioned}
            timeout={ANIMATION_DURATION}
            unmountOnExit={unmountOnExit}
          >
            <div>
              <NavigatableIntegrationWidgetCardContext.Provider
                value={navigatableContext}
              >
                <IndexComponent
                  doNavigatableClearState={doNavigatableClearState}
                  doNavigatableSetState={setStateNow}
                  navigatableState={deferedState}
                  {...props}
                />
              </NavigatableIntegrationWidgetCardContext.Provider>
            </div>
          </CSSTransition>
          <CSSTransition
            className="detail"
            classNames="detail"
            in={state !== null}
            unmountOnExit={unmountOnExit}
            timeout={ANIMATION_DURATION}
          >
            <div>
              <NavigatableIntegrationWidgetCardContext.Provider
                value={navigatableContext}
              >
                <DetailComponent
                  doNavigatableClearState={doNavigatableClearState}
                  doNavigatableSetState={setStateNow}
                  navigatableState={deferedState}
                  {...props}
                />
              </NavigatableIntegrationWidgetCardContext.Provider>
            </div>
          </CSSTransition>
        </AnimatedLeftRight>
      </AnimateAutoHeight>
    </CollapsableCard>
  )
}
