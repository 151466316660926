import { useCallback } from 'react'
import cn from 'classnames'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import { isEmpty } from 'util/objects'
import { getDisplayLabel } from 'util/recipientLabel'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Close } from '@groovehq/internal-design-system/lib/assets/icons'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

export default function RemovableRecipient({
  className,
  recipients,
  recipient,
  onClick,
  syncing,
  children,
  interactionDisabled,
}) {
  const handleOnClick = useCallback(
    () => {
      if (interactionDisabled) return
      onClick(recipient)
    },
    [onClick, recipient, interactionDisabled]
  )

  const handlePreventParentFocus = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  if (!recipient || isEmpty(recipient)) return null
  return (
    <Tooltip
      title={recipient.email}
      position="top"
      strategy="fixed"
      maxWidth={500}
      disabled={interactionDisabled}
    >
      <div
        onClick={handlePreventParentFocus}
        onMouseDown={handlePreventParentFocus}
      >
        <div css={styles.recipient} className={cn('grui', className)}>
          {syncing ? (
            <Spinner size="small" className="grui mr-5" />
          ) : (
            <Avatar
              css={styles.recipientAvatar}
              avatar={recipient.avatar_url}
              userName={recipient.name}
              size="small"
              className="grui mr-5"
            />
          )}
          <div css={styles.truncated} className="grui mr-auto">
            {getDisplayLabel(recipient, recipients)}
          </div>
          <button
            css={[button.styles.preflight, styles.iconStyle]}
            className="grui flex items-center"
            onClick={handleOnClick}
          >
            <Close />
          </button>
        </div>
        {children}
      </div>
    </Tooltip>
  )
}
