import React from 'react'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import LabelOptionIcon from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/LabelOptionIcon'
import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'
import Sidebar from 'shared/components/ui/Sidebar'
import DateTimeInput from 'shared/components/ui/DateTimeInput'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Button, Flex, Label, Tooltip } from 'shared/ui'
import { Dropdown, Form, Input, TextArea } from 'util/ui'

import { TRELLO_COLORS } from './constants'
import renderLabelLabel from './renderLabelLabel'

export default function LinkNewTrelloCardModalView({
  boards,
  doCreateAndLinkTrelloCardToTicket,
  isBoardLoading,
  isFormValid,
  formFields: {
    description = null,
    dueDate = null,
    board = null,
    label = [],
    list = null,
    member = [],
    name = null,
  },
  labels,
  lists,
  members,
  onChange,
  onClose,
  onDueDateChange,
  onOpen,
  open,
}) {
  const isLoading = !boards || isBoardLoading
  const isBoardChoosen = board && !isBoardLoading
  return (
    <React.Fragment>
      <Button basic onClick={onOpen} className="grui ml-4 mt-4">
        New
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            portal
            position="top"
            tooltip={
              !isFormValid && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!isFormValid}
              fluid
              key="link"
              onClick={doCreateAndLinkTrelloCardToTicket}
              primary
            >
              Create and link card
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={onClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to New Trello Card"
        onClose={onClose}
        open={open}
        wide
      >
        <ModalContent>
          <Form>
            {boards && (
              <React.Fragment>
                <Flex>
                  <FormRow split>
                    <Label as="div">Board</Label>
                    <ValidatedField
                      fluid
                      name="board"
                      options={boards}
                      onChange={onChange}
                      placeholder="- Please select -"
                      search
                      selection
                      selectOnNavigation={false}
                      validatedFieldComponent={Dropdown}
                      value={board}
                    />
                  </FormRow>

                  <FormRow split>
                    <Label as="div">List</Label>
                    <ValidatedField
                      disabled={!isBoardChoosen}
                      fluid
                      name="list"
                      options={lists}
                      onChange={onChange}
                      placeholder="- Please select -"
                      search
                      selection
                      selectOnNavigation={false}
                      validatedFieldComponent={Dropdown}
                      value={list}
                    />
                  </FormRow>
                </Flex>
                <FormRow>
                  <Label as="div">Name</Label>
                  <ValidatedField
                    disabled={!isBoardChoosen}
                    fluid
                    name="name"
                    onChange={onChange}
                    validatedFieldComponent={Input}
                    value={name || ''}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Labels <OptionalLabel />
                  </Label>
                  <ValidatedField
                    disabled={!isBoardChoosen}
                    fluid
                    multiple
                    name="label"
                    options={
                      labels &&
                      labels.map(option => {
                        return {
                          ...option,
                          icon: (
                            <LabelOptionIcon
                              {...option}
                              color={TRELLO_COLORS[option.color]}
                            />
                          ),
                        }
                      })
                    }
                    onChange={onChange}
                    placeholder="- Please select -"
                    renderLabel={renderLabelLabel}
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={label}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Members <OptionalLabel />
                  </Label>
                  <ValidatedField
                    disabled={!isBoardChoosen}
                    fluid
                    multiple
                    name="member"
                    options={members}
                    onChange={onChange}
                    placeholder="- Please select -"
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={member}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Due Date <OptionalLabel />
                  </Label>
                  <ValidatedField
                    disabled={!isBoardChoosen}
                    fluid
                    name="dueDate"
                    onChange={onDueDateChange}
                    removable
                    validatedFieldComponent={DateTimeInput}
                    value={dueDate}
                  />
                </FormRow>
                <FormRow>
                  <Label as="div">
                    Description <OptionalLabel />
                  </Label>
                  <ValidatedField
                    disabled={!isBoardChoosen}
                    fluid
                    name="description"
                    onChange={onChange}
                    validatedFieldComponent={TextArea}
                    value={description || ''}
                  />
                </FormRow>
              </React.Fragment>
            )}
          </Form>
        </ModalContent>
        {isLoading && <Loader />}
      </Sidebar>
    </React.Fragment>
  )
}
