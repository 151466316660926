import { useEffect, useCallback } from 'react'

// Handle sidebar open/collapse and make sure it's not taking space when it's collapsed
// If it's taking space, dragging the recipient from the editor into the right edge of the screen will show the sidebar
const useRightSidebar = ({ isRightSidebarCollapsed, overlay, style = {} }) => {
  const [isSidebarOut, setIsSidebarOut] = React.useState(
    isRightSidebarCollapsed
  )

  const onHiddenTransitionEnd = useCallback(e => {
    if (e.propertyName === 'transform') {
      setIsSidebarOut(true)
    }
  }, [])

  useEffect(
    () => {
      if (!isRightSidebarCollapsed) {
        setIsSidebarOut(false)
      }
    },
    [isRightSidebarCollapsed]
  )

  return {
    onTransitionEnd: isRightSidebarCollapsed
      ? onHiddenTransitionEnd
      : undefined,
    finalStyle: {
      ...style,
      ...(isRightSidebarCollapsed && {
        marginLeft:
          -style.minWidth > style.width ? -style.minWidth : -style.width,
        transform: 'translateX(100%)',
      }),
      ...(overlay && {
        width: 320,
        minWidth: 320,
        flexGrow: 0,
        flexShrink: 0,
        marginLeft: -320,
        transform: !isRightSidebarCollapsed ? null : 'translateX(100%)',
        zIndex: 98,
      }),
      // make sure it's not taking space
      ...(isSidebarOut && {
        position: 'absolute',
        right: 0,
      }),
    },
  }
}

export default useRightSidebar
