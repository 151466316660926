import { createSelector } from 'reselect'

import {
  selectCurrentContactId,
  selectCurrentContactIsMissing,
  selectChangeContactIsLoading,
  selectCurrentContactHasSomeCustomFieldValues,
} from 'ducks/crm/contacts'
import { selectAllCustomFieldCategoriesIsLoading } from 'ducks/crm/customFieldCategories'
import { selectContactDetailsColumnPreferencesIsLoading } from 'ducks/agentPreferences/selectors'
import { sortByKey } from 'util/arrays'

import {
  selectContactDetailsColumnWidgetCardsByKey,
  selectDefaultContactDetailsColumnWidgetCardOrderAndVisibility,
} from './WidgetCards/selectors'

export const selectIsContactDetailsColumnLoading = createSelector(
  selectAllCustomFieldCategoriesIsLoading,
  selectCurrentContactId,
  selectContactDetailsColumnPreferencesIsLoading,
  selectCurrentContactIsMissing,
  selectCurrentContactHasSomeCustomFieldValues,
  selectChangeContactIsLoading,
  (
    categoriesIsLoading,
    contactId,
    preferencesIsLoading,
    isMissing,
    hasValues,
    changeContactLoading
  ) => {
    return (
      (!contactId && !isMissing) ||
      !hasValues ||
      categoriesIsLoading ||
      preferencesIsLoading ||
      changeContactLoading
    )
  }
)

export const selectContactDetailsColumnHiddenWidgetCards = createSelector(
  selectDefaultContactDetailsColumnWidgetCardOrderAndVisibility,
  selectContactDetailsColumnWidgetCardsByKey,
  (orderAndVisibility, widgetCards) => {
    const hiddenKeys = orderAndVisibility
      .map(({ key, visible }) => (visible === false ? key : null))
      .filter(x => x !== null)
    return sortByKey(hiddenKeys.map(key => widgetCards[key]), 'name')
  }
)
