import React, { useLayoutEffect, useState } from 'react'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useRefs } from '@groovehq/internal-design-system/lib/hooks'
import useOpenContact from 'util/hooks/useOpenContact'
import { propFunc } from 'util/functions'
import blurTop from 'assets/blur-top.png'
import blurBottom from 'assets/blur-bottom.png'
import Step from './Step'
import { styles } from './styles'

const addPadding = top => `${top + 11}px`

const WorkflowMenu = ({ currentStep, onStepChange, steps }) => {
  // Put all refs into a Map and provide getting ref function
  const [getTabRef] = useRefs()
  const activeTabRef = getTabRef(currentStep)
  const [inkBarStyle, setInkBarStyle] = useState({
    top: addPadding(0),
  })
  const openContact = useOpenContact()

  useLayoutEffect(
    () => {
      if (!activeTabRef.current) return
      const { offsetTop: top } = activeTabRef.current
      setInkBarStyle({
        top: addPadding(top),
      })
    },
    [currentStep, activeTabRef]
  )

  return (
    <div
      css={styles.menu}
      className="grui pb-12 pt-14 flex flex-col flex-shrink-0 relative"
    >
      <img src={blurTop} alt="blur top" css={styles.blur} aria-hidden />
      <img
        src={blurBottom}
        alt="blur bottom"
        className="bottom-blur"
        css={styles.blur}
        aria-hidden
      />
      <div className="grui pl-16 pr-13">
        <h2 className="grui" css={heading.styles.h2}>
          Lets get started!
        </h2>
        <div className="grui relative mt-10">
          <div>
            {Object.keys(steps).map(key => {
              const { name, completed } = steps[key]
              return (
                <Step
                  key={key}
                  completed={completed}
                  current={currentStep === key}
                  onClick={propFunc(onStepChange, key)}
                  ref={getTabRef(key)}
                >
                  {name}
                </Step>
              )
            })}
            {/* Prevent animation it on loading */}
            {activeTabRef.current && (
              <div css={styles.inkBar} style={inkBarStyle} />
            )}
          </div>
        </div>
      </div>
      <Button
        type="tertiary"
        onClick={openContact}
        css={styles.contactBtn}
        className="grui px-12 py-6 mx-5 mt-auto self-center"
      >
        Need a hand getting setup?
      </Button>
    </div>
  )
}

export default WorkflowMenu
