import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { convertHexToRGBA } from 'util/colors'

const html = theme => css`
  ul {
    margin-top: ${rem(6)};
    padding-left: ${rem(13)};
    padding-right: ${rem(8)};
    padding-inline-start: ${rem(13)} !important;

    li {
      line-height: ${theme.lineHeight.leadingXSnug};
      margin-bottom: ${rem(8)};

      &::marker {
        color: ${convertHexToRGBA(theme.color.info['900'], 0.1)};
      }
    }
  }
`

const text = theme => css`
  position: relative;
  overflow: hidden;
  height: ${rem(130)};

  &:before {
    content: '';
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(
      180deg,
      transparent 22.12%,
      ${theme.color.monochrome.white} 93.25%
    );
  }
`

const showMore = theme => css`
  color: ${theme.color.monochrome.black};
  border-color: currentColor;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.leadingSnug};
  border-radius: 9999px;

  &:hover {
    color: ${theme.color.primary.info};
    border-color: currentColor;
  }
`

const summaryHeading = css`
  height: ${rem(24)};
  display: flex;
  align-items: center;
`

const loadingSummary = theme => css`
  &&& li {
    background-color: ${theme.color.monochrome.white};
    border: 1px solid currentColor;
    line-height: ${theme.lineHeight.leadingNone};
  }

  [role='separator'] {
    background-color: currentColor;
    margin-top: ${rem(16)};
  }
`

export const styles = {
  html,
  text,
  showMore,
  summaryHeading,
  loadingSummary,
}
