import { motion } from 'framer-motion'
import { css } from '@emotion/react'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import ResetIcon from '@groovehq/internal-design-system/lib/assets/icons/Reset'

const variants = {
  stopped: {
    rotate: 360,
    transition: { ease: 'linear' },
  },
  refreshing: {
    rotate: [0, 360],
    transition: { duration: 1, repeat: Infinity, ease: 'linear' },
  },
}
const motionDiv = css`
  transform-origin: 10px 11px;
  margin-top: -1px;
`

const View = ({ doRefreshSearches, isRefreshingSearches, className }) => {
  return (
    <Button
      type="icon"
      size="small"
      onClick={doRefreshSearches}
      className={cn('controlIconButton', className)}
    >
      <motion.div
        initial="stopped"
        animate={isRefreshingSearches ? 'refreshing' : 'stopped'}
        variants={variants}
        css={motionDiv}
      >
        <ResetIcon />
      </motion.div>
    </Button>
  )
}

export default View
