import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const btn = theme => css`
  border-radius: ${rem(200)};
  font-weight: ${theme.fontWeight.normal};
  font-family: ${theme.font.secondary};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  flex-shrink: 0;
  margin-right: ${rem(8)};
`

export const styles = {
  btn,
}
