import React, { useMemo, useCallback, useState } from 'react'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import NumberField from '@groovehq/internal-design-system/lib/components/NumberField/NumberField'
import Product from '@groovehq/internal-design-system/lib/components/Shopify/Product/Product'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'

import { moneyString } from 'util/currency'
import PriceDiscount from '../PriceDiscount'
import { styles } from './styles'
import { getDiscountedPrice, isNotEmpty, getItemTotal } from '../order.util'

const { RowControls } = Table

const createProductImage = ({ value }) => (
  <Product.Image image={value?.url} alt={value?.altText} />
)

const createProductCell = ({ value, row: { original } }) => {
  const { name, productURL, sku } = value
  const { custom, requiresShipping } = original

  return (
    <Product name={name} productURl={productURL}>
      {custom ? (
        <span>
          {`${requiresShipping ? 'Requires ' : 'Does not require '}shipping`}
        </span>
      ) : (
        <span css={text.styles.uppercase}>SKU: {sku}</span>
      )}
    </Product>
  )
}

const createPriceCell = ({ value, updateData, row: { index, original } }) => {
  const { price, currency } = value
  const { quantity, appliedDiscount } = original
  const discountedPrice = getDiscountedPrice(price, appliedDiscount)
  const finalPrice = isNotEmpty(discountedPrice) ? discountedPrice : price

  // Update discounted price and item total accordingly
  const handleDiscountChange = newAppliedDiscount => {
    const total = getItemTotal(price, quantity, newAppliedDiscount)
    updateData(index, 'bulk', {
      appliedDiscount: newAppliedDiscount,
      total,
    })
  }

  return (
    <div css={styles.priceCell}>
      <div>
        {isNotEmpty(discountedPrice) && (
          <>
            <span css={[text.styles.textMediumDark, mainStyles.moneyString]}>
              {moneyString({ currencyCode: currency, amount: price })}
            </span>
            <br />
          </>
        )}
        <PriceDiscount
          currency={currency}
          discount={appliedDiscount}
          onDiscountChange={handleDiscountChange}
          priceCents={price}
        >
          <button css={button.styles.link} type="button">
            <span css={mainStyles.moneyString}>
              {moneyString({ currencyCode: currency, amount: finalPrice })}
            </span>
          </button>
        </PriceDiscount>
      </div>
    </div>
  )
}

const QuantityCell = ({
  value,
  updateData,
  onEdit,
  row: { index, original },
  disabled,
}) => {
  const [feedback, setFeedback] = useState({})
  // Handle change quantity and change item total accordingly
  const handleChange = ({ target }) => {
    const quantity = target.value
    const total = getItemTotal({
      index,
      priceColumn: original.price,
      quantity: target.value,
    })

    updateData(index, 'bulk', {
      quantity,
      total,
    })
    onEdit(
      Object.assign(target, {
        name: original.id,
      })
    )
  }

  const handleBlurNumber = useCallback(
    () => {
      feedback.help = ''
      setFeedback(feedback)
    },
    [feedback]
  )

  return (
    <NumberField
      css={styles.numberField}
      value={value}
      onChange={handleChange}
      min={1}
      onBlur={handleBlurNumber}
      required
      disabled={disabled}
      {...feedback}
    />
  )
}

const createTotalCell = ({ row: { original } }) => {
  const { quantity, appliedDiscount } = original
  const { price } = original.price
  const discountedPrice = getDiscountedPrice(price, appliedDiscount)
  const originalPrice = isNotEmpty(discountedPrice) ? discountedPrice : price
  const finalPrice = discountedPrice || originalPrice
  const amount = finalPrice * quantity

  return (
    <span css={mainStyles.moneyString}>
      {moneyString({ currencyCode: original.price.currency, amount })}
    </span>
  )
}

const useColumns = () => {
  const columns = useMemo(() => {
    const createRowControls = ({ value, deleteData }) => (
      <RowControls
        value={value}
        deleteData={deleteData}
        css={styles.controls}
      />
    )

    return [
      {
        Header: '',
        accessor: 'id',
        Cell: createRowControls,
        disableSortBy: true,
        className: 'cell-id',
        headerClassName: 'cell-id',
      },
      {
        Header: 'Product',
        accessor: 'image',
        Cell: createProductImage,
        disableSortBy: true,
        className: 'cell-image',
        headerClassName: 'cell-image',
      },
      {
        accessor: 'product',
        Cell: createProductCell,
        disableSortBy: true,
        className: 'cell-product-name',
        headerClassName: 'cell-product-name',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: createPriceCell,
        disableSortBy: true,
        className: 'cell-price',
        headerClassName: 'cell-price',
      },
      {
        Header: '',
        id: 'multiply',
        // eslint-disable-next-line react/no-multi-comp
        Cell() {
          return <span className="grui ml-5 mr-5">×</span>
        },
        disableSortBy: true,
        className: 'cell-multiply',
        headerClassName: 'cell-multiply',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: QuantityCell,
        disableSortBy: true,
        className: 'cell-quantity',
        headerClassName: 'cell-quantity',
      },
      {
        Header: 'Total',
        Cell: createTotalCell,
        accessor: 'total',
        disableSortBy: true,
        className: 'cell-total',
        headerClassName: 'cell-total',
      },
    ]
  }, [])

  return columns
}

export const emptyDataColumns = [
  {
    Header: 'Product',
    accessor: 'product',
    width: '70%',
    disableSortBy: true,
    className: 'cell-product-name',
    headerClassName: 'cell-product-name',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    disableSortBy: true,
    className: 'cell-quantity',
    headerClassName: 'cell-quantity',
  },
  {
    Header: 'Total',
    accessor: 'total',
    disableSortBy: true,
    width: '25%',
    className: 'cell-total',
    headerClassName: 'cell-total',
  },
]

export default useColumns
