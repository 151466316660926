import React from 'react'
import cn from 'classnames'
import { styles } from './styles'

const ItemWithIcon = ({ icon, children, className }) => {
  if (!icon) return children
  return (
    <div className={cn('grui flex items-center w-100', className)}>
      <span css={styles.menuItemIcon} className="grui mr-5 item-icon">
        {icon}
      </span>
      {children}
    </div>
  )
}

export default ItemWithIcon
