import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const restoreLabel = (isSpam, isDeleted) => {
  if (isSpam) {
    return 'Mark as not spam & reopen'
  }

  if (isDeleted) {
    return 'Remove from trash & reopen'
  }

  return null
}

const RestoreButton = React.memo(
  ({ restoreFromSpam, restoreFromDeleted, isDeleted = true, isSpam }) => {
    return (
      <div className="restore">
        <IconButton
          name="delete"
          className={cn('menu-button', 'text-button')}
          labelClassName="label"
          onClick={isSpam ? restoreFromSpam : restoreFromDeleted}
          label={restoreLabel(isSpam, isDeleted)}
        />
      </div>
    )
  }
)

export default RestoreButton
