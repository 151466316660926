import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import cn from 'classnames'
import { SelectableItem } from 'shared/ui'
import { hasLowContrast } from 'util/colors'
import LabelText from './LabelText'
import LabelCheckbox from './LabelCheckbox'

const ListItem = ({
  className,
  isChecked,
  isFocused,
  label: { id, color, name, labelingsCount: count },
  onCheckboxClick = null /* When checkbox input changed */,
  onClick = null /* when row/label or checkbox input clicked */,
  showCount,
}) => {
  const theme = useTheme()
  const isTagColorSmilarToCheck = hasLowContrast(
    theme.color.monochrome.white,
    color
  )
  return (
    <SelectableItem
      className={cn('grui py-4 pl-12 pr-10', className)}
      content={
        <LabelCheckbox
          // checked is only working for the first time render
          // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#checked
          // need to use key to remount the input to make isChecked work
          checked={isChecked}
          key={!isChecked}
          id={id}
          label={<LabelText name={name} count={count} showCount={showCount} />}
          inputColor={isTagColorSmilarToCheck ? undefined : color}
          name={name}
          onCheckboxClick={onCheckboxClick}
        />
      }
      onContainerClick={onClick}
      selected={isFocused}
    />
  )
}

export default styled(ListItem)`
  display: block;
`
