import React from 'react'
import cn from 'classnames'
import { css } from '@emotion/react'

import { IconButton } from 'shared/ui'

export default class PinIcon extends React.Component {
  state = { hovered: false }

  onHover = () => {
    this.setState({ hovered: true })
  }

  onBlur = () => {
    this.setState({ hovered: false })
  }

  pinStyle = theme => css`
    &&:hover svg path,
    &&.pinned svg path {
      fill: ${theme.color.primary.warning} !important;
    }
  `

  render() {
    const { disabled = false, isPinned, onPinClick } = this.props

    return (
      <IconButton
        name="pin"
        size="small"
        tooltip={isPinned ? 'Unpin search' : 'Pin search'}
        iconSize="20"
        onClick={!disabled ? onPinClick : null}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onBlur}
        css={this.pinStyle}
        className={cn({ pinned: isPinned })}
        svg
      />
    )
  }
}
