import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const checkbox = css`
  // Offset the radius for the checkbox
  margin: ${rem(-10)};
`

const checkboxInUnreadTicket = theme => css`
  label {
    .checkbox-radius::before {
      background-color: ${theme.color.monochrome.white};
    }
  }
`

const checkboxInActiveTicket = theme => css`
  label {
    .checkbox-radius::before {
      background-color: ${theme.color.monochrome.medium};
    }
  }
`

export const styles = {
  checkbox,
  checkboxInUnreadTicket,
  checkboxInActiveTicket,
}
