import { css } from '@emotion/react'

const placeholder = theme => css`
  width: 27px;
  height: 24px;
  position: relative;
  background-color: ${theme.color.monochrome.medium};
  border-top-left-radius: 1px;

  &:before {
    content: '';
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: ${theme.color.monochrome.white};
    border-bottom-left-radius: 2px;
  }

  &:after {
    content: '';
    top: 3px;
    right: 3px;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 6px solid ${theme.color.monochrome.medium};
    border-right: 6px solid transparent;
  }
`
const extension = theme => css`
  margin-top: 2px;
  padding: 1px;
  font-size: 6px;
  font-weight: ${theme.fontWeight.medium};
  text-align: center;
  text-transform: uppercase;
  color: ${theme.color.monochrome.white};
  background-color: ${theme.color.monochrome.mediumDark};
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
`

const image = css`
  width: 27px;
  height: 35px;
  object-fit: cover;
  border-radius: 2px;
  cursor: pointer;
`

const imageWrapper = css`
  cursor: pointer;
`

const pdf = theme => css`
  background-color: ${theme.color.negative['600']};
`

const doc = theme => css`
  background-color: ${theme.color.info['700']};
`

const xls = theme => css`
  background-color: ${theme.color.positive['900']};
`

export const styles = {
  placeholder,
  extension,
  image,
  imageWrapper,
}

export const extentionStyles = {
  pdf,
  doc,
  xls,
}
