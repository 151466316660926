import styled from '@emotion/styled'
import { css } from '@emotion/react'
import themeVars from 'ui_theme/site/globals/site.variables'

const listText = css`
  line-height: 1.42;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const listItem = css`
  margin-bottom: 4px;
  :first-child {
    margin-bottom: 4px;
  }
`

const addressStyle = () => theme => css`
  padding-left: ${theme.spacing['10']};
  margin-bottom: 0px;
`

const Container = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing['5']};
  :last-child {
    margin-bottom: 0px;
  }
`

const ItemKey = styled('span')`
  margin-right: 4px;
`
const ItemValue = styled('span')`
  color: ${props => props.color && `${themeVars[props.color]}`};
`

const Address = ({
  addressFirst,
  addressSecond,
  province,
  city,
  zip,
  country,
}) => {
  return (
    <div css={[listItem, addressStyle]}>
      <div css={listText}>{addressFirst || ''}</div>
      {addressSecond && <div css={listText}>{addressSecond || ''}</div>}
      <div css={listText}>{`${province || ''}, ${city || ''}, ${zip ||
        ''}`}</div>
      <div css={listText}>{country || ''}</div>
    </div>
  )
}

const Item = ({ label, text, labelColor, textColor, children }) => {
  return (
    <div css={[listText, listItem]}>
      <ItemKey color={labelColor}>{`${label}:`}</ItemKey>
      <ItemValue color={textColor}>{text || children}</ItemValue>
    </div>
  )
}

export default {
  Container,
  Item,
  Address,
}
