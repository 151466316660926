import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  doBasicSearchContacts,
  selectContactsForQuery,
  selectIsLoadingForQuery,
  selectIsErroredForQuery,
} from 'ducks/crm/contacts'
import { doCreateAndChangeContactForConversation } from 'ducks/crm/contacts/operations/updating/doCreateAndChangeContactForConversation'
import { doChangeContactForConversation } from 'ducks/crm/contacts/operations/updating/doChangeContactForConversation'
import { selectCurrentTicketGqlId } from 'selectors/tickets'
import { emptyArr } from 'util/arrays'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { guessNameFromEmail, isValidEmail } from 'util/strings'

const DEFAULT_INITIAL_VALUES = { name: '', email: '' }
export function useChangeOrCreateContact({ contactId, onClose }) {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()
  const conversationId = useSelector(selectCurrentTicketGqlId)
  const conversationNumber = useSelector(selectCurrentTicketId)
  const contactsRaw =
    useSelector(state =>
      selectContactsForQuery(state, { query: searchTerm })
    ) || emptyArr

  const contacts = useMemo(
    () => {
      return contactsRaw.filter(({ id }) => {
        return id !== contactId
      })
    },
    [contactsRaw, contactId]
  )
  const isSearchingLoading = useSelector(state =>
    selectIsLoadingForQuery(state, { query: searchTerm })
  )
  const isSearchingError = useSelector(state =>
    selectIsErroredForQuery(state, { query: searchTerm })
  )
  const handleClose = useCallback(
    () => {
      if (onClose) onClose()
      setSearchTerm('')
    },
    [onClose, setSearchTerm]
  )
  return {
    isSearchingLoading,
    isSearchingError,
    contacts,
    onCreate: useCallback(
      ({ name, email }) => {
        dispatch(
          doCreateAndChangeContactForConversation(
            contactId,
            conversationId,
            conversationNumber,
            name,
            email
          )
        )
        handleClose()
        return true
      },
      [dispatch, conversationId, conversationNumber, handleClose, contactId]
    ),
    onClose: handleClose,
    onSelect: useCallback(
      ({ id }) => {
        dispatch(
          doChangeContactForConversation(conversationId, conversationNumber, id)
        )
        handleClose()
      },
      [conversationId, conversationNumber, handleClose, dispatch]
    ),
    onSearch: useCallback(
      query => {
        setSearchTerm(query)
        dispatch(doBasicSearchContacts(query))
      },
      [setSearchTerm, dispatch]
    ),
    searchTerm,
    initialValues: useMemo(
      () => {
        if (isValidEmail(searchTerm)) {
          return {
            ...DEFAULT_INITIAL_VALUES,
            email: searchTerm,
            name: guessNameFromEmail(searchTerm),
          }
        }
        return { ...DEFAULT_INITIAL_VALUES, name: searchTerm }
      },
      [searchTerm]
    ),
  }
}
