import { useSelector } from 'react-redux'
import { selectCustomFieldValuesContentForCustomFieldKeysAndCategoryType } from 'ducks/crm/contacts'
import { getCustomFieldKeys } from 'ducks/integrations/recharge/utils'

import CustomerDetails from './CustomerDetails'

export default function RechargeIndex(props) {
  const { integration } = props
  const categoryType = 'CONTACT'
  const keyPrefix = `recharge_${integration.storeDomain}`
  const fieldKeys = getCustomFieldKeys(keyPrefix)

  const fields = useSelector(state =>
    selectCustomFieldValuesContentForCustomFieldKeysAndCategoryType(
      state,
      { categoryType },
      fieldKeys
    )
  )

  return <CustomerDetails fields={fields} integrationId={integration.id} />
}
