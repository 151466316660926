import { css } from '@emotion/react'

import ContactAvatar from 'components/crm/ContactAvatar'

import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'
import withSidebarModalPositioning from '../../../../withSidebarModalPositioning'
import CopyButton from '../../../CustomFieldKeyValue/CopyButton'
import ChangeCompanyButton from './ChangeCompanyButton'
import CompanyHeaderFieldsEditButton from './CompanyHeaderFieldsEditButton'
import { useChangeCompanyMenus } from './hooks'
import MissingAvatar from './MissingAvatar'

export default withSidebarModalPositioning(CompanyHeaderFieldsWithMenuPosition)

const missingCompanyClass = css`
  cursor: pointer;
  opacity: 0.5;
`
function CompanyHeaderFieldsWithMenuPosition({
  domains,
  doRecalc,
  doSetMenu,
  menuRef,
  name,
  offset,
  openMenu,
  subjectId,
}) {
  const domain = domains && domains[0]
  const avatarUrl = useSelectCustomFieldValueForKeyAndType(
    'company_logo',
    'COMPANY',
    'url'
  )
  const avatarDataUrl = useSelectCustomFieldValueForKeyAndType(
    'company_logo',
    'COMPANY',
    'dataUrl'
  )
  const altAvatarUrl = useSelectCustomFieldValueForKeyAndType(
    'contact_avatar_url',
    'CONTACT'
  )
  const changeCompanyMenus = useChangeCompanyMenus()
  return (
    <>
      <ContactAvatar
        size={32}
        src={avatarDataUrl || avatarUrl || altAvatarUrl}
        fallback={MissingAvatar}
      />
      <div className="nameAndDomain">
        {subjectId ? (
          <>
            <div className="name">{name || domain || <em>Unknown</em>}</div>
            {name && domain && <div className="domain">{domain}</div>}
          </>
        ) : (
          <div
            css={missingCompanyClass}
            onClick={changeCompanyMenus.onOpenChange}
          >
            <div className="name">{<em>No company</em>}</div>
            {
              <div className="domain">
                <em>Add</em>
              </div>
            }
          </div>
        )}
      </div>

      {subjectId && (
        <>
          <CopyButton
            text={domain}
            message="The company's domain was copied to your clipboard."
          />
          <CompanyHeaderFieldsEditButton
            doRecalcMenuPosition={doRecalc}
            doSetMenu={doSetMenu}
            domains={domains}
            menuRef={menuRef}
            name={name}
            openMenu={openMenu}
            offset={offset}
            subjectId={subjectId}
          />
        </>
      )}
      <ChangeCompanyButton
        changeCompanyMenus={changeCompanyMenus}
        doRecalcMenuPosition={doRecalc}
        menuRef={menuRef}
        offset={offset}
        subjectId={subjectId}
      />
    </>
  )
}
