import React, { useCallback, useContext } from 'react'
import {
  useStatus,
  useStatusText,
} from 'ducks/integrations/stripe/hooks/charges'
import { getFormattedPrice } from 'util/numbers'

import moment from 'moment'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function ListItem({
  charge,
  charge: { id, amount, created, currency },
  customerId,
}) {
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const status = useStatus(charge)
  const statusText = useStatusText(charge)

  const onClick = useCallback(
    () => {
      navigatableSetState({ id, type: 'charge', customerId })
    },
    [navigatableSetState, id, customerId]
  )

  if (charge)
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        onClick={onClick}
        title={getFormattedPrice(amount, currency)}
        subTitle={`${moment.unix(created).format('Do MMM YYYY')}`}
        subTitleStatus={status}
        subTitleStatusText={statusText}
      />
    )
  return null
}
