import React from 'react'
import withOpenState from 'shared/ui/withOpenState'
import { Loader, Button, Label } from 'shared/ui'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { KeyValue } from 'components/KeyValues'
import Section from '../Section'
import Contact from './Contact'
import CreateContactModal from './Modals/CreateContact'
import ContextDropdown from './ContextDropdown'

class Contacts extends React.PureComponent {
  state = {
    contextId: null,
  }

  handleChange = (_, chosen) => {
    this.setState({
      contextId: chosen.value,
    })
  }

  render() {
    const {
      className,
      contacts,
      doNavigatableSetState,
      settings,
      credentials,
    } = this.props
    const { contextId } = this.state
    let contactContextId = contextId
    let contactObject
    if (contextId) {
      contactObject = contacts.find(contact => contact.id === contextId)
    } else {
      const leads = contacts.filter(object => object.type === 'Lead')
      const actualContacts = contacts.filter(
        object => object.type === 'Contact'
      )
      contactObject = leads[0] || actualContacts[0]
      contactContextId = contactObject && contactObject.id
    }

    return (
      <div className={className}>
        <Section>
          <Label size="small">Results</Label>
          <ContextDropdown
            objects={contacts}
            handleChange={this.handleChange}
            contextId={contactContextId}
          />
        </Section>
        {contactObject && (
          <>
            <Contact
              key={contactObject.id}
              contactObject={contactObject}
              doNavigatableSetState={doNavigatableSetState}
              settings={settings}
              credentials={credentials}
            />
          </>
        )}
      </div>
    )
  }
}

class Card extends React.PureComponent {
  render() {
    const {
      className,
      doNavigatableSetState,
      object,
      isMissing,
      isLoading,
      settings,
      credentials,
      open,
      onOpen,
      onClose,
    } = this.props

    if (isLoading) {
      return <Loader />
    }
    if (!object) return null
    if (object && object.count > 1) {
      return (
        <Contacts
          className={className}
          contacts={object.objects}
          credentials={credentials}
          doNavigatableSetState={doNavigatableSetState}
          key={object.id}
          settings={settings}
        />
      )
    }
    if (isMissing) {
      return (
        <Section>
          {open && <CreateContactModal open={open} onClose={onClose} />}
          <p>No contact or lead found.</p>
          <Button basic primary onClick={onOpen}>
            + Create lead
          </Button>
        </Section>
      )
    }
    const errors = object.error
    if (isDefined(errors)) {
      if (Array.isArray(errors) && errors.length) {
        return (
          <>
            <div>⚠️Salesforce returned error:</div>
            {errors.map(e => (
              <>
                <KeyValue key={e.code} title="Error Code" value={e.code} />
                <KeyValue
                  key={e.message}
                  title="Error Message"
                  singleline={false}
                  value={e.message}
                />
              </>
            ))}
          </>
        )
      }

      let message = errors.message
      if (errors.code === 500) {
        message = 'Server error'
      }
      return <React.Fragment>{message}</React.Fragment>
    }
    const onlyObject = object.objects && object.objects[0]
    return (
      <>
        {open && (
          <CreateContactModal
            open={open}
            onClose={this.closeCreateContactModal}
          />
        )}
        {onlyObject && (
          <div className={className}>
            <Contact
              key={onlyObject.id}
              contactObject={onlyObject}
              doNavigatableSetState={doNavigatableSetState}
              settings={settings}
              credentials={credentials}
            />
          </div>
        )}
      </>
    )
  }
}

export default withOpenState(Card)
