import styled from '@emotion/styled'

import { Icon } from 'util/ui'

const CarouselControl = styled(({ className, direction, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <Icon name={`chevron ${direction}`} />
    </div>
  )
})`
  cursor: pointer;
`
CarouselControl.displayName = 'CarouselControl'
export default CarouselControl
