import { memo, useMemo } from 'react'

import { eye as Eye, eyeSlash as EyeSlash } from 'assets/icons/groove/v2'
import { KeyValue } from 'components/KeyValues'

import CustomFieldKeyValue, { DragHandle } from '../../CustomFieldKeyValue'
import CustomFieldKeyValuesValue from '../../CustomFieldKeyValue/CustomFieldKeyValuesValue'

export default memo(ArrayCustomFields)

const MAX_VALUES = 5

const emptyValue = { content: '' }
const emptyValueArray = [emptyValue]
function ArrayCustomFields({
  fieldHidden,
  icon,
  individualTitle,
  isInEditMode,
  fieldKey: key,
  onEye,
  title,
  type,
  subjectId,
  values,
}) {
  const valuesFiltered = useMemo(
    () => values && values.filter(({ content } = {}) => !!content),
    [values]
  )
  const valuesWithEmpty = useMemo(
    () => [...(valuesFiltered || []), emptyValue],
    [valuesFiltered]
  )
  if (!valuesFiltered || valuesFiltered.length === 0) {
    return (
      <CustomFieldKeyValue
        icon={icon}
        index={0}
        isArray
        isInEditMode={isInEditMode}
        fieldKey={key}
        onEye={onEye}
        title={individualTitle}
        type={type}
        value={emptyValue}
        values={emptyValueArray}
        subjectId={subjectId}
      />
    )
  }
  const EyeIcon = fieldHidden ? Eye : EyeSlash
  return (
    <KeyValue
      actions={
        isInEditMode && (
          <>
            <DragHandle />
            {onEye && (
              <div className="action editAction" onClick={onEye}>
                <EyeIcon />
              </div>
            )}
          </>
        )
      }
      grouped
      icon={icon}
      showActions
      title={title}
    >
      {valuesFiltered.map((value, index) => {
        return (
          <CustomFieldKeyValuesValue
            fieldKey={key}
            key={key}
            value={value}
            icon={icon}
            index={index}
            isArray
            isInEditMode={isInEditMode}
            subjectId={subjectId}
            title={individualTitle}
            type={type}
            values={values}
          />
        )
      })}
      {valuesFiltered.length < MAX_VALUES && (
        <CustomFieldKeyValuesValue
          fieldKey={key}
          icon={icon}
          index={values.length}
          isArray
          isInEditMode={isInEditMode}
          subjectId={subjectId}
          title={individualTitle}
          type={type}
          value={emptyValue}
          values={valuesWithEmpty}
        />
      )}
    </KeyValue>
  )
}
