import React from 'react'
import styled from '@emotion/styled'

import { Dropdown } from 'util/ui'

class DropdownItem extends React.PureComponent {
  onClick = e => {
    const { onClick, value } = this.props
    onClick(e, { value })
  }

  render() {
    const { text } = this.props
    return <Dropdown.Item onClick={this.onClick}>{text}</Dropdown.Item>
  }
}

class ContextDropdown extends React.PureComponent {
  render() {
    const { contextId, handleChange, objects } = this.props
    const options = objects.map(object => {
      return {
        type: object.type,
        key: object.id,
        text: object.properties.Name.value,
        value: object.id,
      }
    })
    let label
    if (contextId) {
      const found = options.find(option => option.key === contextId)
      label = found && found.text
    }

    const contacts = options.filter(object => object.type === 'Contact')
    const leads = options.filter(object => object.type === 'Lead')
    return (
      <Dropdown
        name="contextId"
        placeholder="Select a Contact"
        onChange={handleChange}
        className="selection"
        fluid
        floating
        text={label}
        value={label}
      >
        <Dropdown.Menu>
          {leads.length > 0 && (
            <React.Fragment>
              <Dropdown.Header
                content={
                  <span>
                    Leads <span className="count">{leads.length}</span>
                  </span>
                }
              />
              {leads.map(option => {
                return (
                  <DropdownItem
                    key={option.key}
                    type={option.type}
                    value={option.value}
                    text={option.text}
                    onClick={handleChange}
                  />
                )
              })}
            </React.Fragment>
          )}
          {contacts.length > 0 && (
            <React.Fragment>
              <Dropdown.Header
                content={
                  <span>
                    Contacts <span className="count">{contacts.length}</span>
                  </span>
                }
              />
              {contacts.map(option => {
                return (
                  <DropdownItem
                    key={option.key}
                    type={option.type}
                    value={option.value}
                    text={option.text}
                    onClick={handleChange}
                  />
                )
              })}
            </React.Fragment>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export const StyledContextDropdown = styled('div')`
  margin-top: ${props => props.theme.spacing['7']};

  & .menu .header {
    border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
  }
  & .text {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    max-width: 100%;
  }
  .sui & .ui.dropdown .menu > .item {
    border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
    padding-left: 24px !important;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .sui & .ui.dropdown .menu > .item(:last-child) {
    border: 0;
  }
  & .header {
    background-color: #f6f7fa;
    padding: 12px 16px !important;
    margin: 0 !important;
  }
  & .header .count {
    font-weight: 400;
  }
`

export default class WrappedContextDropdown extends React.PureComponent {
  render() {
    const { objects, handleChange, contextId } = this.props
    return (
      <StyledContextDropdown>
        <ContextDropdown
          objects={objects}
          handleChange={handleChange}
          contextId={contextId}
        />
      </StyledContextDropdown>
    )
  }
}
