import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'

export default styled(StatusText)`
  &.status {
    &-positive {
      color: ${props => props.theme.color.primary.positive};
    }

    &-warning {
      color: ${props => props.theme.color.primary.warning};
    }

    &-negative {
      color: ${props => props.theme.color.primary.negative};
    }

    &-neutral {
      color: ${props => props.theme.color.monochrome.mediumDark};
    }
  }
`

function StatusText({ text, status, className }) {
  const statusClassName = useMemo(
    () => {
      switch (status) {
        case 'success':
          return 'status-positive'
        case 'warning':
          return 'status-warning'
        case 'negative':
          return 'status-negative'
        case 'neutral':
          return 'status-neutral'
        default:
          return ''
      }
    },
    [status]
  )

  return <div className={cn(className, statusClassName)}>{text}</div>
}
