/* eslint-disable react/display-name */
import { css } from '@emotion/react'
import React from 'react'
import cn from 'classnames'

import PanelGroup from 'components/PaneGroup'
import RightSidebar from 'components/App/DesktopView/Layout/RightSidebar'
import WrappedTicketList from 'subapps/ticketing/components/WrappedTicketList'
import MainCell from 'components/App/DesktopView/Layout/MainCell'
import withPanelWidthManager from 'components/App/DesktopView/Layout/withPanelWidthManager'

import RightSidebarToggler from '../../RightSidebar/Toggler'
import useRightSidebar from '../../RightSidebar/useRightSidebar'
import styles from '../styles.css'

import OverlayBackground from './OverlayBackground'
import { DEFAULT_WIDTHS, DEFAULT_BREAKPOINTS, STORAGE_KEY } from './defaults'

class ModernView extends React.PureComponent {
  // Length has to match panes count
  componentWrappers = [
    React.forwardRef((props, ref) => {
      return (
        <div
          {...props}
          ref={ref}
          className={cn(props.className, 'hidden-in-print')}
        />
      )
    }),
    null,
    React.forwardRef((props, ref) => {
      const { isRightSidebarCollapsed, overlay } = this.props
      const { onTransitionEnd, finalStyle } = useRightSidebar({
        isRightSidebarCollapsed,
        overlay,
        style: props.style,
      })

      return (
        <div
          {...props}
          ref={ref}
          onTransitionEnd={onTransitionEnd}
          style={finalStyle}
          className={cn(
            props.className,
            styles.rightSidebarWrapper,
            'hidden-in-print'
          )}
        />
      )
    }),
  ]

  render() {
    const {
      areTicketsSelected,
      doToggleRightSidebar,
      hasSearchErrored,
      isBulkSelectionMode,
      isMerging,
      isNewConversation,
      isNewEmail,
      isQueryStringValid,
      isRightSidebarCollapsed,
      isUpdateAvailable,
      overlay,
      queryObject,
      queryString,
      queryStringIsComplete,
      ticketId,
      panelWidths = [],
      panelUpdateCallback,
      ...rest
    } = this.props

    const showRightSideBar = !isBulkSelectionMode && ticketId
    const isRightSidebarHiddenOrCollapsed =
      isRightSidebarCollapsed || !showRightSideBar
    const collapsedPanels = isRightSidebarHiddenOrCollapsed
      ? (panelWidths.length - 1).toString()
      : ''
    return (
      <React.Fragment>
        {showRightSideBar && <RightSidebarToggler />}
        <PanelGroup
          css={css`
            @media print {
              min-width: 0 !important;
            }
          `}
          spacing={0}
          panelWidths={panelWidths}
          collapsedPanels={collapsedPanels}
          onUpdate={panelUpdateCallback}
          ComponentWrappers={this.componentWrappers}
          breakpoints={DEFAULT_BREAKPOINTS}
          className="four-column-view"
        >
          <WrappedTicketList
            hasSearchErrored={hasSearchErrored}
            isQueryStringValid={isQueryStringValid}
            queryObject={queryObject}
            queryString={queryString}
          />
          <MainCell
            areTicketsSelected={areTicketsSelected}
            isBulkSelectionMode={isBulkSelectionMode}
            isMerging={isMerging}
            isNewConversation={isNewConversation}
            isNewEmail={isNewEmail}
            isUpdateAvailable={isUpdateAvailable}
            ticketId={ticketId}
            {...rest}
          />
          {showRightSideBar && (
            <RightSidebar
              panelWidth={(panelWidths[2] || {}).size}
              overlay={overlay}
            />
          )}
        </PanelGroup>
        <OverlayBackground
          onClick={doToggleRightSidebar}
          visible={overlay && !isRightSidebarCollapsed}
        />
      </React.Fragment>
    )
  }
}

export default withPanelWidthManager(DEFAULT_WIDTHS, STORAGE_KEY)(ModernView)
