import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.monochrome.white};
  padding: ${rem(118)} ${rem(20)} ${rem(60)};
`

const innerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const footer = css`
  display: flex;
  margin: ${rem(8)} ${rem(-8)};
  justify-content: center;
  flex-wrap: wrap;
`

const btn = theme => css`
  border-radius: ${rem(24)};
  font-family: ${theme.font.gtWalsheimPro};
  margin: ${rem(8)};
  min-width: ${rem(250)};

  svg {
    width: ${rem(32)};
    height: ${rem(32)};
    vertical-align: middle;
  }

  & > * {
    display: flex;
    width: 100%;
    align-items: center;
    &::after {
      content: '';
      display: block;
    }
  }

  & > *::after,
  .channel-icon-wrapper {
    flex-shrink: 0;
    min-width: ${rem(32)};
  }
`

const oAuthButtton = theme => css`
  svg {
    border-radius: 50%;
    background-color: ${theme.color.monochrome.white};
  }
`

const emailBtn = theme => css`
  font-weight: ${theme.fontWeight.normal};
  color: ${theme.color.info[900]};
`

const content = theme => css`
  max-width: ${rem(530)};
  h2 {
    font-size: ${theme.fontSize.x7large};
    margin-left: ${rem(30)};
    margin-right: ${rem(30)};
  }
  & > p {
    margin-bottom: ${rem(16)};
  }
`

const imgWrapper = theme => css`
  display: none;
  margin-left: ${rem(50)};
  width: ${rem(537)};
  img {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.computerBreakpoint}) {
    display: block;
  }
`

const demoLink = theme => css`
  all: unset;
  cursor: pointer;
  color: ${theme.color.primary.info};
`

export const styles = {
  container,
  innerContainer,
  footer,
  btn,
  emailBtn,
  oAuthButtton,
  content,
  imgWrapper,
  demoLink,
}
