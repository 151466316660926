import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import {
  constructSearchQueryObject,
  constructSearchQueryString,
} from 'util/search'
import {
  selectCurrentTicketSearchQueryString,
  selectSearchesRequestState,
  selectTicketSearchTotalCountsByQueryId,
} from 'selectors/search'
import { selectCurrentPayload, selectQueryParams } from 'selectors/location'
import { selectAgentHasMailboxAccess } from 'selectors/mailboxes/selectAgentHasMailboxAccess'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import {
  selectShowContinueForwardingSetupBar,
  selectShowContinueForwardingSetupPane,
} from 'selectors/layout'
import SearchModifier from 'components/SearchModifier/Desktop/Modal'
import { selectIsCurrentDemoMailbox } from 'selectors/mailboxes/selectIsCurrentDemoMailbox'
import FilterBadges from 'components/ConversationList/Header/Search/FilterBadges'
import { isEmpty } from 'util/objects'
import LayoutSwitcher from './LayoutSwitcher'
import EmptyFolder from './EmptyFolder'
import NoMailboxAccess from './NoMailboxAccess'
import styles from './styles.less'

const Inbox = () => {
  // always true since we build it ourselves.
  const queryStringIsComplete = true
  const isNewEmail = useSelector(selectIsCreatingNewEmail)
  const isNewConversation = useSelector(selectIsLoggingNewConversation)
  const isNewEmailOrNewConversation = !!isNewConversation || !!isNewEmail
  const hasMailboxAccess = useSelector(selectAgentHasMailboxAccess)
  const currentPayload = useSelector(selectCurrentPayload)
  const currentQuery = useSelector(selectQueryParams)
  const ticketQueryString = useSelector(selectCurrentTicketSearchQueryString)
  const queryString =
    isEmpty(currentPayload) && isEmpty(currentQuery)
      ? ticketQueryString // when url has no payload and query
      : currentPayload?.term // term from the url
  const { mailbox, folder } = useMemo(
    () => constructSearchQueryObject(queryString),
    [queryString]
  )
  const folderId = folder?.[0]
  const queryStringOnlyWithInboxAndFolder = useMemo(
    () => {
      if (folder?.length) {
        // Remove draft_type query from the query string, so we can get the correct count
        return constructSearchQueryString({
          folder,
          mailbox,
        })
      }
      return ''
    },
    [mailbox, folder]
  )
  const showForwardingSetupBar = useSelector(
    selectShowContinueForwardingSetupBar
  )
  const showForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )
  const { loaded: searchesLoaded, error: searchesError } = useSelector(
    selectSearchesRequestState
  )

  const isDemo = useSelector(selectIsCurrentDemoMailbox)

  const ticketCountsInFolder = useSelector(
    selectTicketSearchTotalCountsByQueryId
  )[queryStringOnlyWithInboxAndFolder]

  const folderHasNoTicket =
    searchesLoaded && !searchesError && ticketCountsInFolder === 0

  const shouldShowEmptyFolder =
    folderHasNoTicket &&
    !isNewEmailOrNewConversation &&
    !showForwardingSetupBar &&
    !showForwardingSetupPane &&
    !isDemo

  if (!hasMailboxAccess) return <NoMailboxAccess />

  if (shouldShowEmptyFolder) {
    return <EmptyFolder folderId={folderId} />
  }

  return (
    <div className={cn('TicketingApp', styles.content)}>
      <SearchModifier />
      <FilterBadges />
      <LayoutSwitcher queryStringIsComplete={queryStringIsComplete} />
    </div>
  )
}

export default React.memo(Inbox)
