import { connect } from 'react-redux'

import { selectAgents } from 'selectors/agents/base'
import {
  selectSelectedGroup,
  selectSelectedGroupAgentsCount,
  selectSelectedAgent,
  selectIsFilteringPossibleAssignees,
  selectFilteredAgentsInSelectedGroup,
  selectFilteredAgentsInAllGroups,
  selectFilteredGroups,
  selectFilteredGroupsFromAgentFilter,
  selectSuggestedAgents,
} from 'selectors/assignments'
import { selectIsInChat } from 'selectors/location'

import Menu from 'components/App/DesktopView/shared/AssignmentDropdown'

const select = state => ({
  allAgents: selectAgents(state),
  filteredAgents: selectFilteredAgentsInSelectedGroup(state),
  filteredAgentsAllGroups: selectFilteredAgentsInAllGroups(state),
  filteredGroups: selectFilteredGroups(state),
  filteredGroupsFromAgentFilter: selectFilteredGroupsFromAgentFilter(state),
  isFilteringPossibleAssignees: selectIsFilteringPossibleAssignees(state),
  selectedAgent: selectSelectedAgent(state),
  selectedGroup: selectSelectedGroup(state),
  selectedGroupAgentsCount: selectSelectedGroupAgentsCount(state),
  suggestedAgents: selectSuggestedAgents(state),
  canShowPresence: selectIsInChat(state),
})

export default connect(select)(Menu)
