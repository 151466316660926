import styled from '@emotion/styled'

import View from './view'

const Styled = styled(View)`
  &&& {
    border-radius: 4px;
    height: 40px;
    position: relative;
    width: 40px;

    .icon {
      height: 40px;
      width: 40px;
      display: block;
      border-radius: 4px;
    }

    .popover {
      position: absolute;
      top: 0;
      z-index: 3;
    }

    .cover {
      bottom: 0px;
      left: 0px;
      position: fixed;
      right: 0px;
      top: 0px;
    }

    .sketch-picker {
      padding-bottom: 50px !important; /* hack to allow buttons to fit at bottom */
    }
  }
`

export default Styled
