import React from 'react'
import { useSelector } from 'react-redux'

import CollapsableContent from 'components/CollapsableContent'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import {
  selectJiraCloudHiddenFields,
  selectJiraCloudIssuesById,
  selectCurrentTicketJiraCloudIssuesById,
  selectJiraCloudVisibleFields,
} from 'ducks/integrations/jiraCloud'
import LinkedJiraCloudIssueFields from './LinkedJiraCloudIssueFields'
import { useJiraCloudIssueActions } from './hooks'

export default function LinkedJiraCloudIssue({
  className,
  doNavigatableClearState,
  navigatableState: issueId,
}) {
  const unlinkedIssue = useSelector(selectJiraCloudIssuesById)[issueId]
  const issue =
    useSelector(selectCurrentTicketJiraCloudIssuesById)[issueId] ||
    unlinkedIssue
  const hiddenFields = useSelector(selectJiraCloudHiddenFields)
  const visibleFields = useSelector(selectJiraCloudVisibleFields)
  const actions = useJiraCloudIssueActions(issue, doNavigatableClearState)
  const { id, fields: { summary } = {}, key, title } = issue || {}
  const summaryWithKey = `[${key}] ${summary}`

  if (!issue) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      onBack={doNavigatableClearState}
      title={key ? summaryWithKey : title}
    >
      {visibleFields &&
        visibleFields.length > 0 && (
          <LinkedJiraCloudIssueFields fields={visibleFields} issue={issue} />
        )}
      <LinkedResourceActions id={id} actions={actions} />

      {hiddenFields &&
        hiddenFields.length > 0 && (
          <CollapsableContent
            animateHeight={false}
            hideTrigger="– Hide additional fields"
            showTrigger="+ Show additional fields"
          >
            <LinkedJiraCloudIssueFields fields={hiddenFields} issue={issue} />
          </CollapsableContent>
        )}
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
