import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'

const CarouselItem = styled('div')`
  height: 100%;
  width: 100%;
  position: absolute;
  left: -100%;
  transition: left ease-out ${themeVars.defaultDuration};
  background: white;
  visibility: hidden;

  &.current {
    left: 0;
    visibility: visible;
    z-index: 2;
  }

  &.next {
    left: 100%;
  }

  &.previousCurrent {
    visibility: visible;
  }
`
CarouselItem.displayName = 'CarouselItem'
export default CarouselItem
