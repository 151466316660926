import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectChargeForId } from 'ducks/integrations/recharge/selectors'
import { formatDateForCurrentYear } from 'ducks/integrations/recharge/utils'
import { capture } from 'ducks/tracking/actions'
import { capitalize } from 'util/strings'

import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function ListItem({ chargeId, subscriptionId }) {
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const charge = useSelector(state =>
    selectChargeForId(state, { subscriptionId, chargeId })
  )

  const createdAt = useMemo(
    () => formatDateForCurrentYear(charge.scheduled_at, '%d %b', '%d %b %Y'),
    [charge]
  )

  const status = useMemo(
    () => {
      switch (charge.status) {
        case 'queued':
          return 'neutral'
        default:
          return ''
      }
    },
    [charge]
  )

  const statusText = useMemo(
    () => {
      switch (charge.status) {
        case 'queued':
          return 'Draft'
        default:
          return capitalize(charge.status)
      }
    },
    [charge]
  )

  const onClick = useCallback(
    () => {
      navigatableSetState({
        orderId: charge.id,
        customerId: charge.customer.id,
        subscriptionId,
        type: 'charge',
      })
      capture('Recharge Order Viewed')
    },
    [navigatableSetState, charge, subscriptionId]
  )

  return (
    <NavigatableIntegrationWidgetCard.ListItem
      title={charge.id}
      onClick={onClick}
      subTitle={createdAt}
      subTitleStatus={status}
      subTitleStatusText={statusText}
    />
  )
}
