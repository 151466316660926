import { contrast } from 'util/colors'

export default function renderLabelLabel({ color, text }) {
  return {
    style: {
      color: `${contrast(color)}`,
      background: `#${color}`,
    },
    content: text,
  }
}
