import { PureComponent } from 'react'
import metrics from 'util/metrics'

export default class ListLoaded extends PureComponent {
  componentDidMount() {
    const { queryString, totalCount, onListLoaded } = this.props
    metrics.stopTimer(`search-load-${queryString}`, { totalCount })
    setTimeout(onListLoaded, 500)
  }

  render() {
    return null
  }
}
