import { connect } from 'react-redux'

import { doAssignTicketToGroupAndAgent } from 'actions/tickets'

import { selectGroupsById } from 'selectors/app'
import { selectAgents, selectAgentsById } from 'selectors/agents/base'
import {
  selectFilteredAgentsInAllGroups,
  selectFilteredAgentsInSelectedGroup,
  selectFilteredGroups,
  selectFilteredGroupsFromAgentFilter,
  selectIsFilteringPossibleAssignees,
  selectSelectedAgent,
  selectSelectedGroup,
  selectSelectedGroupAgentsCount,
  selectSuggestedAgents,
  selectTicketAssigning,
} from 'selectors/assignments'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectIsInChat } from 'selectors/location'

import Menu from '../'

const select = state => ({
  agentsById: selectAgentsById(state),
  allAgents: selectAgents(state),
  filteredAgents: selectFilteredAgentsInSelectedGroup(state),
  filteredAgentsAllGroups: selectFilteredAgentsInAllGroups(state),
  filteredGroups: selectFilteredGroups(state),
  filteredGroupsFromAgentFilter: selectFilteredGroupsFromAgentFilter(state),
  groupsById: selectGroupsById(state),
  isFilteringPossibleAssignees: selectIsFilteringPossibleAssignees(state),
  isOpen: selectTicketAssigning(state),
  selectedAgent: selectSelectedAgent(state),
  selectedGroup: selectSelectedGroup(state),
  selectedGroupAgentsCount: selectSelectedGroupAgentsCount(state),
  suggestedAgents: selectSuggestedAgents(state),
  conversationIds: selectCurrentTicketId(state),
  canShowPresence: selectIsInChat(state),
})

const perform = {
  onGroupSelect: doAssignTicketToGroupAndAgent,
  onAgentSelect: doAssignTicketToGroupAndAgent,
}

export default connect(select, perform)(Menu)
