import React from 'react'
import { bool, func, string } from 'prop-types'
import Close from '@groovehq/internal-design-system/lib/assets/icons/Close'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'
import { useVideoElement } from './hooks'

const Video = ({ onCloseVideo, className, closeable, videoId }) => {
  useVideoElement(videoId)

  if (!videoId) return null

  return (
    <div css={styles.videoWrapper} className={className}>
      <div
        className={`wistia_embed wistia_async_${videoId} popover=true popoverContent=thumbnail popoverOverlayOpacity=0.75`}
        css={styles.video}
      >
        &nbsp;
      </div>
      {closeable && (
        <button
          css={[button.styles.preflight, styles.close]}
          className="grui"
          onClick={onCloseVideo}
        >
          <Close />
        </button>
      )}
    </div>
  )
}

Video.propTypes = {
  onCloseVideo: func,
  className: string,
  closeable: bool,
  videoId: string,
}

Video.defaultProps = {
  onCloseVideo: () => {},
  className: undefined,
  closeable: false,
  videoId: undefined,
}

export default Video
