import { connect } from 'react-redux'
import { selectAgent } from 'selectors/agents/base'

const AgentLabel = connect((initialState, initialProps) => state => {
  const agent = selectAgent(state, initialProps.agentId)
  return {
    agentLabel: agent.label || null,
  }
})(({ agentLabel }) => <React.Fragment>{agentLabel}</React.Fragment>)

export default AgentLabel
