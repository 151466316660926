import styled from '@emotion/styled'

import CarouselControl from './CarouselControl'

const CarouselControls = styled(({ className, goToPrevious, goToNext }) => {
  return (
    <div className={className}>
      <CarouselControl direction="left" onClick={goToPrevious} />
      <CarouselControl direction="right" onClick={goToNext} />
    </div>
  )
})`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacing['5']};
  width: 100%;
  .sui & > div > i.icon {
    margin-right: ${props => props.theme.spacing['3']};
  }
  .sui & > div:last-child > i.icon {
    margin-right: 0;
  }
`

CarouselControls.displayName = 'CarouselControls'
export default CarouselControls
