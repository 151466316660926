/* eslint-disable import/first */
/* eslint-disable react/jsx-filename-extension */

// NOTE (jscheel): Do **not** put anything above these polyfills.
require('./polyfills')

import 'core-js/stable'
import 'regenerator-runtime/runtime'
// END NOTE

import preApp from 'preApp'
import { hydrateApp } from 'app'

hydrateApp(preApp)

import config from 'config'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Bugsnag from '@bugsnag/js'

import { setupServiceWorker } from 'util/serviceworker'
import { bootstrap as bootstrapBugsnag } from 'util/bugsnag'

import App from 'components/App'
import ListenToKeyboard from 'components/ListenToKeyboard'

bootstrapBugsnag(config.env)

import { runTriggers } from './triggers'

momentDurationFormatSetup(moment)

require('./util/removeChildIssueProtection')
require('./util/cache_log').start()
require('./util/cache').watchForUpdate()

// fastclick to handle tap events on mobile out of the box
require('fastclick').attach(document.body)

require('./util/scrollbars').detectScrollbarType()

const container = document.getElementById('app')

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const render = MainComponent => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={app.store}>
        <ListenToKeyboard.GlobalContextProvider>
          <MainComponent />
        </ListenToKeyboard.GlobalContextProvider>
      </Provider>
    </ErrorBoundary>,
    container
  )
}

render(App)

app.store.subscribe(runTriggers)
runTriggers()
if (!config.disableSW) {
  setupServiceWorker()
}

if (!config.isTest) {
  const SEARCH_REFRESH_INTERVAL = 20 * 60000 // 15 minutes
  if (!localStorage.disableSearchRefresh) {
    setInterval(app.doRefreshCounts, SEARCH_REFRESH_INTERVAL)
  } else {
    //  eslint-disable-next-line no-console
    console.debug('search refresh disabled')
  }
}
