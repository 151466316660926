import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { doFetchJiraServerAssignableUsers } from 'ducks/integrations/jiraServer'
import {
  selectJiraAreAssignableUsersLoading,
  selectJiraAssignableUsersAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Avatar, Label } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import Login from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Login'
import Name from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Name'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'
import renderAssigneeLabel from './renderAssigneeLabel'

export default connect(
  state => ({
    areAssignableUsersLoading: selectJiraAreAssignableUsersLoading(state, {
      source: 'jiraServer',
    }),
    assignableUsers: selectJiraAssignableUsersAsOptions(state, {
      source: 'jiraServer',
    }),
  }),
  { doFetchJiraServerAssignableUsers }
)(AssigneeFormField)

function AssigneeFormField({
  assignableUsers = [],
  areAssignableUsersLoading,
  doFetchJiraServerAssignableUsers: fetchAssignableUsers,
  formFields: { assignee },
  isProjectChoosen,
  onChange,
  projectId,
  resetFields,
}) {
  useEffect(
    () => {
      if (projectId) {
        fetchAssignableUsers(projectId)
      }
    },
    [fetchAssignableUsers, projectId]
  )
  useEffect(
    // If assignee or assignableUsers changes remove the assignee if it isn't in assignableUsers
    () => {
      if (
        !areAssignableUsersLoading &&
        assignee &&
        !assignableUsers.find(({ value }) => value === assignee)
      ) {
        resetFields('assignee')
      }
    },
    [areAssignableUsersLoading, assignee, assignableUsers, resetFields]
  )
  return (
    <FormRow>
      <Label as="div">
        Assignees <OptionalLabel>(optional)</OptionalLabel>
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || areAssignableUsersLoading}
        fluid
        loading={areAssignableUsersLoading}
        name="assignee"
        options={assignableUsers.map(option => {
          return {
            ...option,
            children: (
              <React.Fragment>
                <Avatar
                  url={option.avatarUrl}
                  size={24}
                  style={{
                    float: 'left',
                    marginRight: themeVars.tiny,
                  }}
                />
                <Login>{option.text}</Login> <Name>{option.value}</Name>
              </React.Fragment>
            ),
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        renderLabel={renderAssigneeLabel}
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={isProjectChoosen && assignee ? assignee : null}
      />
    </FormRow>
  )
}
