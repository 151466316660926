import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import colorfulTop from 'assets/colorful-top.png'

const hasBg = theme => css`
  background: url(${colorfulTop}) top center / 100% 158px no-repeat
    ${theme.color.monochrome.white};
  background-origin: border-box;
`

const aiContainer = theme => css`
  font-family: ${theme.font.quaternary};
  font-size: ${theme.fontSize.large};
  border: 1px solid rgba(${theme.color.monochrome.black_rgba}, 0.1);
  padding: ${rem(16)};
  background-origin: border-box;
  color: ${theme.color.monochrome.black};
  box-shadow: 0 1px 3px 0 rgba(${theme.color.monochrome.black_rgba}, 0.15);
  background-color: ${theme.color.monochrome.white};
  border-radius: ${rem(6)};
`

const error = theme => css`
  border-color: ${theme.color.negative['500']};
  font-size: ${theme.fontSize.x2large};

  svg {
    color: ${theme.color.negative['500']};
  }

  .errorMessage {
    max-width: ${rem(360)};
  }
`

const header = theme => css`
  align-items: center;
  font-weight: ${theme.fontWeight.bold};
  margin: ${rem(-16)};
  margin-bottom: 0;
  padding: ${rem(16)};
  border-bottom: 1px solid rgba(${theme.color.monochrome.black_rgba}, 0.1);
  border-radius: ${rem(5)} ${rem(5)} 0 0;
`

const body = css`
  padding-top: ${rem(16)};
  min-height: 230px;
`

const footer = css``

const tertiaryBtn = theme => css`
  padding-left: ${rem(30)};
  padding-right: ${rem(30)};
  color: ${theme.color.info['900']};
  border-color: currentColor;
  margin-right: ${rem(16)};
  font-family: ${theme.font.tertiary};

  &:hover {
    color: ${theme.color.primary.info};
    border-color: currentColor;
  }
`

const btnLink = theme => css`
  color: ${theme.color.info['900']};
  font-family: ${theme.font.tertiary};

  &:hover {
    color: ${theme.color.primary.info};
  }
`

const tertiarySm = theme => css`
  padding: ${rem(1)} ${rem(8)} 0;
  color: rgba(${theme.color.monochrome.black_rgba}, 0.6);
  border-color: currentColor;
  font-size: ${theme.fontSize.small};
  font-family: ${theme.font.quaternary};
  font-weight: ${theme.fontWeight.medium};
  text-transform: uppercase;
  height: auto;
  margin-right: 0;

  &:hover {
    color: ${theme.color.monochrome.black};
  }
`

const loadingCopy = theme => css`
  font-size: ${theme.fontSize.x2large};
  color: ${theme.color.primary.info};
  font-weight: ${theme.fontWeight.medium};
  margin: ${rem(8)} 0 ${rem(16)};
`

const hiddenComposer = css`
  // Can't use display: none, because we need to insert content to the current editor
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  height: 0;
`

const docItem = theme => css`
  color: rgba(${theme.color.monochrome.black_rgba}, 0.7);
  svg {
    color: #a4a4a4;
  }

  a,
  button {
    &:hover {
      text-decoration: underline;
      color: ${theme.color.monochrome.black};
    }
  }
`

const learnLink = theme => css`
  color: ${theme.color.primary.info};

  &:hover {
    text-decoration: underline;
  }
`

const divider = theme => css`
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.1);
  width: auto;
`

const aiRatingButtons = css`
  display: flex;
  z-index: 0;

  button {
    svg {
      height: ${rem(16)};
      width: ${rem(16)};
    }
    &:last-of-type svg {
      transform: rotate(180deg);
    }
  }
`

const aiRatingDescription = theme => css`
  color: ${theme.color.monochrome.dark};
`

export const styles = {
  aiContainer,
  error,
  header,
  body,
  footer,
  tertiaryBtn,
  btnLink,
  hasBg,
  tertiarySm,
  loadingCopy,
  hiddenComposer,
  docItem,
  learnLink,
  divider,
  aiRatingButtons,
  aiRatingDescription,
}
