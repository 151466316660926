import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { doCreateCompany, doMoveContactToCompany } from 'ducks/crm/companies'

import { selectCurrentContactId } from 'ducks/crm/contacts'

import CompanyForm from '../CompanyForm'

const styles = {
  padding: '16px',
}
const defaultDomains = ['']
export default function CreateCompany({
  companyId: oldCompanyId,
  doRecalcMenuPosition,
  onClose,
}) {
  const dispatch = useDispatch()
  const contactId = useSelector(selectCurrentContactId)
  const handleSave = useCallback(
    async fields => {
      const newCompanyId = await dispatch(doCreateCompany(fields))
      dispatch(doMoveContactToCompany(contactId, oldCompanyId, newCompanyId))
    },
    [contactId, dispatch, oldCompanyId]
  )
  return (
    <div css={styles}>
      <CompanyForm
        doRecalcMenuPosition={doRecalcMenuPosition}
        domains={defaultDomains}
        name=""
        onCancel={onClose}
        onSave={handleSave}
        variant="create"
      />
    </div>
  )
}
