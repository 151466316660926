import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import cn from 'classnames'
import {
  text,
  divider,
} from '@groovehq/internal-design-system/lib/styles/elements'

import Scroller from 'components/Scroller'
import { SelectableItem, Flex, Avatar, Text } from 'shared/ui'
import withListController from 'components/App/DesktopView/shared/withListController'

import { propFunc } from 'util/functions'
import { withResizeChange } from 'util/hoc'

const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const scrollerStyle = () => theme => css`
  background-color: ${theme.color.monochrome.white};
  max-height: 246px;
  min-width: 255px;
  max-width: 400px;
  border-radius: 6px;
`

const Container = styled(Flex)`
  & > * {
    :not(:last-child) {
      margin-right: ${props => props.theme.spacing['5']};
    }
  }
  align-items: center;
  ${truncate};
`

const AgentAvatar = styled(Avatar)`
  margin-right: ${props => props.theme.spacing['7']};
  border-radius: 50%;
`

const ListItem = ({
  className,
  isFocused,
  item,
  saveRef,
  onClick = null,
  onMouseMoveCapture,
  /* when row/label or checkbox input clicked */
  showArrow,
}) => {
  return (
    <SelectableItem
      className={cn(className, isFocused && 'active')}
      content={
        <Container>
          <AgentAvatar size={28} actor={item} />
          <span className="grui truncate" css={text.styles.fontMedium}>
            {`@${item.username}`}
          </span>
          <Text size="small" truncate>
            {item.name}
          </Text>
        </Container>
      }
      onContainerClick={onClick}
      onMouseMoveCapture={onMouseMoveCapture}
      selected={isFocused}
      ref={saveRef}
      showArrow={showArrow}
    />
  )
}

const SearchingListItem = styled(ListItem)`
  padding: 8px;
  padding-left: 18px;
  display: block;

  // Prevent showing the selected styles on hover
  // so only the focused item will show selected
  &:hover:not(.active) {
    background-color: unset;
  }

  [role='separator'] {
    pointer-events: none;
  }
`

const UserList = ({
  items = [],
  suggestedAgentCount,
  onSelect,
  onItemMouseFocus,
  focusedIndex = 0,
  saveItemRef,
  showArrow = true,
}) => {
  return (
    <div>
      {items.map((item, index) => {
        return (
          <Fragment key={item.id}>
            {suggestedAgentCount > 0 &&
              index === suggestedAgentCount && (
                <div
                  role="separator"
                  css={divider.styles.primary}
                  className="grui my-3"
                  tabIndex={-1}
                />
              )}
            <SearchingListItem
              isFocused={index === focusedIndex}
              key={item.id}
              item={item}
              onClick={propFunc(onSelect, null, item)}
              // Enable focusing an item by mouse
              // we don't want to trigger a mouse event if the pop up is showing up at the first time
              // and the mouse is just around the item
              // so we can't use mouseover and mouseenter
              onMouseMoveCapture={propFunc(onItemMouseFocus, index)}
              // eslint-disable-next-line react/jsx-no-bind
              saveRef={node => saveItemRef(index, node)}
              showArrow={showArrow}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

class ScrollingList extends React.Component {
  componentWillUnmount() {
    this.itemRefs = null
  }

  saveItemRef = (idx, node) => {
    if (this.itemRefs && !this.itemRefs[idx]) {
      this.itemRefs[idx] = node
    }
  }

  itemRefs = []

  render() {
    const { focusedIndex, onItemMouseFocus } = this.props

    return (
      <Scroller
        css={scrollerStyle}
        className="grui box-shadow-md py-3"
        focusedElement={this.itemRefs[focusedIndex]}
      >
        <UserList
          {...this.props}
          saveItemRef={this.saveItemRef}
          onItemMouseFocus={onItemMouseFocus}
        />
      </Scroller>
    )
  }
}

const ScrollingListWithResizingChange = withListController(
  withResizeChange(ScrollingList)
)

export default ScrollingListWithResizingChange
