import ActionSubject from './ActionSubject'
import ActionText from './ActionText'
import ToText from './ToText'

export default function IntegrationActionContentJiraCloud({
  action,
  action: {
    change: {
      action: {
        comment: { author: { displayName: commentDisplayName } = {} } = {},
        user: { displayName } = {},
      },
    },
  },
}) {
  return (
    <React.Fragment>
      {commentDisplayName || displayName} <ActionText action={action} />
      <ActionSubject action={action} />
      <ToText action={action} />
    </React.Fragment>
  )
}
