// Props Proxy HOC that initializes redux state so that selectIsLabelingModalOpen
// any other selector logic that relies on know if the tagging modal is
// open.
//
// Context: historically we had all our modal state in redux, so the
// labeling app+selectors was built accordingly. However with the more recent
// Dropdown/Trigger refactors, we now track the open state primarily with the
// `withOpenState` HOC i.e inside React component state. This refactor is a
// general improvement, however we dont need to rewrite all our selectors to
// support this new style - we can use both.
//
import React from 'react'
import { connect } from 'react-redux'
import { doShowTagsAssignModal, doHideTagsAssignModal } from 'actions/modals'
import { getDisplayName } from 'util/hoc'

export default function withReduxOpenTracking(WrappedDropdown) {
  class WithReduxOpenTracking extends React.Component {
    handleOpen = () => {
      const { onOpen, trackOpen } = this.props
      if (onOpen) onOpen()
      trackOpen()
    }

    handleClose = () => {
      const { onClose, trackClose } = this.props
      if (onClose) onClose()
      trackClose()
    }

    render() {
      const { trackClose, trackOpen, ...rest } = this.props

      return (
        <WrappedDropdown
          {...rest}
          // order is important
          onOpen={this.handleOpen}
          onClose={this.handleClose}
        />
      )
    }
  }

  WithReduxOpenTracking.displayName = `WithReduxOpenTracking(${getDisplayName(
    WrappedDropdown
  )})`

  const perform = {
    trackClose: doHideTagsAssignModal,
    trackOpen: doShowTagsAssignModal,
  }

  return connect(null, perform)(WithReduxOpenTracking)
}
