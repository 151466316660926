import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

/*
 * Styles are used by other pages in settings too
 */
export const pageSpacing = rem(40)

const base = theme => css`
  .shopifyV2-header-label {
    color: ${theme.color.grey[500]};
    font-size: ${theme.fontSize.xsmall};
    font-weight: ${theme.fontWeight.medium};
    background: ${theme.color.monochrome.superLight};
    line-height: 24px;
    padding: 0 12px;
  }

  .shopifyV2-order-status {
    font-size: 11px;
  }

  .shopifyV2-order-cancelled {
    text-decoration: line-through;
  }

  .shopifyV2-order-status-PAID {
    color: ${theme.color.primary.positive};
  }

  .shopifyV2-order-status-PARTIALLY_PAID {
    color: ${theme.color.positive['300']};
  }

  .shopifyV2-order-status-PENDING {
    color: ${theme.color.primary.warningText};
  }

  .shopifyV2-order-status-PARTIALLY_REFUNDED {
    color: ${theme.color.primary.negativeAlt};
  }

  .shopifyV2-order-status-REFUNDED {
    color: ${theme.color.primary.negative};
  }

  .shopifyV2-events {
    max-height: 416px;
    overflow-y: auto;
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
  }

  .shopifyV2-events-dayGroup {
    margin-top: 16px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .shopifyV2-events-dayLabel {
    color: ${theme.color.grey[500]};
    font-size: ${theme.fontSize.xsmall};
    font-weight: ${theme.fontWeight.medium};
    display: flex;
    align-items: center;
    margin-left: 13px;
    margin-bottom: -2px;
    &:before {
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 9px;
      margin-right: 8px;
      border: 1px solid ${theme.color.monochrome.light};
    }
  }

  .shopifyV2-events-event {
    position: relative;
    background: ${theme.color.monochrome.superLight};
    border: 1px solid ${theme.color.monochrome.light};
    border-radius: 6px;
    color: ${theme.color.monochrome.superDark};
    display: flex;
    padding: 6px 10px;
    margin-top: 8px;
    line-height: 1.5;
    align-items: baseline;
    position: relative;

    &:after {
      content: ' ';
      clear: both;
    }

    .shopifyV2-events-event-message {
      min-width: 0;
      flex-grow: 1;
    }

    .shopifyV2-events-event-time {
      margin-left: 8px;
      text-align: right;
      color: ${theme.color.monochrome.mediumDark};
      white-space: nowrap;
      font-size: ${theme.fontSize.xsmall};
      float: right;
    }

    .shopifyV2-events-event-author {
      font-weight: ${theme.fontWeight.medium};
      margin-bottom: 0.35rem;
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 8px;
      top: -9px;
      left: 16px;
      border-left: 1px solid ${theme.color.monochrome.light};
    }
  }

  .shopifyV2-events-show-more {
    text-align: right;
    button {
      font-size: ${theme.fontSize.tiny};
      font-weight: ${theme.fontWeight.medium};
      color: ${theme.color.monochrome.mediumDark};
    }
  }

  .shopifyV2-events-more,
  .shopifyV2-events-show-more {
    padding-top: 8px;
    margin-bottom: 40px;
  }

  .shopifyV2-events-comment {
    background-color: ${theme.color.warning['50']};
    border: 1px solid ${theme.color.warning['50']};

    .shopifyV2-events-event-time {
      float: unset;
      position: absolute;
      right: 6px;
    }
  }

  .ui.tabular + .ui.tab.segment {
    border: none;
    border-radius: 0;
  }

  .shopifyV2-lineItem {
    display: flex;
    &:last-of-type {
      margin-left: auto;
    }
  }

  .shopifyV2-footer {
    background: ${theme.color.monochrome.light};
    padding: 8px 12px;
    display: flex;
    align-items: center;

    a {
      font-size: ${theme.fontSize.xsmall};
      font-weight: ${theme.fontWeight.medium};
      text-decoration: underline;
      .shopifyV2-externalLink-icon {
        font-size: ${theme.fontSize.small};
      }
    }
  }

  .shopifyV2-order-data {
    margin-bottom: -4px;
  }

  .shopifyV2-order-table {
    width: 100%;
    border-collapse: collapse;
    color: rgb(79, 93, 106);

    .shopifyV2-order-table-lineItem {
      tr:first-of-type th,
      tr:first-of-type td {
        padding-top: 8px;
      }
      &:first-of-type tr:first-of-type th,
      &:first-of-type tr:first-of-type td {
        padding-top: 0;
      }
      tr:last-of-type {
        border-bottom: 1px solid ${theme.color.monochrome.medium};
        th,
        td {
          padding-bottom: 8px;
        }
      }
    }

    tbody.shopifyV2-order-table-sub {
      tr:first-of-type {
        td,
        th {
          padding-top: 5px;
        }
      }
    }

    .shopifyV2-order-table-sub,
    .shopifyV2-order-table-sub .KeyValue {
      color: rgba(79, 93, 106, 0.7);
    }

    .shopifyV2-order-table-sub .KeyValue .key {
      font-weight: normal;
    }

    tfoot {
      tr:first-of-type {
        th,
        td {
          padding-top: 2px;
        }
      }
    }

    th {
      font-weight: normal;
      text-align: left;
    }

    .KeyValue {
      padding-top: 0;
      padding-bottom: 0;
    }

    .shopifyV2-order-table-itemText {
      font-size: ${theme.fontSize.xsmall};
    }

    tr {
      &.section-total {
        font-weight: ${theme.fontWeight.medium};
      }
      &.section-start {
        border-top: 1px solid ${theme.color.monochrome.medium};
      }
    }
  }

  .shopifyV2-currency {
    font-variant-numeric: tabular-nums;
  }

  .NavigatableIntegrationWidgetCard-Detail-title {
    padding: ${theme.spacing[7]};
    padding-bottom: 0;
    margin-bottom: -7px;
    .NavigatableIntegrationWidgetCard-Detail-icon.chevron {
      margin-left: -2px;
    }
  }

  .shopifyV2-externalLink-icon {
    margin-bottom: -2px;
    margin-left: 0.25rem;
  }

  .shopifyV2-icon {
    font-size: ${theme.fontSize.x2large};
    vertical-align: middle;
  }

  .shopifyV2-textClip {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .shopifyV2-copyStatusPageUrlIcon {
    vertical-align: sub;
    path {
      fill: ${theme.color.primary.brand};
    }
  }
`

const moneyString = css`
  font-variant-numeric: tabular-nums;
`

const lineThrough = css`
  text-decoration: line-through;
`

export const styles = {
  base,
  moneyString,
  lineThrough,
}
