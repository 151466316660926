import React from 'react'
import { isEmpty } from 'util/arrays'
import { Button, Dropdown } from 'util/ui'
import { propFunc } from 'util/functions'
import { Label, Loader } from 'shared/ui'
import IntegrationError from 'shared/ui/IntegrationError'
import EmptyMessage from './EmptyMessage'
import Help from './Help'
import SubscriptionList from './SubscriptionList'

export default function EmailMarketingView({
  contactId,
  type,
  subscriptions,
  availableLists,
  isFailed,
  isLoading,
  load,
  onUnsubscribe,
  onSubscribe,
}) {
  if (isLoading) {
    return <Loader />
  }
  if (isFailed) {
    return (
      <>
        <IntegrationError onRetry={load} />
      </>
    )
  }
  if (!subscriptions && !availableLists) return null
  return (
    <>
      {subscriptions && (
        <>
          <Label size="small">Subscriptions</Label>
          {isEmpty(subscriptions) && (
            <EmptyMessage>No subscriptions here!</EmptyMessage>
          )}
          {subscriptions && (
            <SubscriptionList
              contactId={contactId}
              subscriptions={subscriptions}
              onUnsubscribe={onUnsubscribe}
            />
          )}
        </>
      )}
      {!isEmpty(availableLists) && (
        <>
          <Dropdown
            trigger={<Button primary>Add to list</Button>}
            icon={null}
            selectOnNavigation={false}
            selectOnBlur={false}
            options={availableLists.map(list => ({
              value: list.id,
              key: list.id,
              text: list.name,
              onClick: propFunc(onSubscribe, contactId, list),
            }))}
          />
        </>
      )}
      {type === 'MAILCHIMP' && <Help />}
    </>
  )
}
