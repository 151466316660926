/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState, useMemo } from 'react'
import { string, oneOfType, node, bool, func } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'

const SetTax = ({ children, onHasTaxesChange, hasTaxes, disabled }) => {
  const [visible, setVisible] = useState(false)
  const [chargeTax, setChargeTax] = useState(hasTaxes)

  const handleVisibleChange = useCallback(
    isVisible => {
      setVisible(isVisible)
      // Set the prop data, if close the menu without saving
      setChargeTax(hasTaxes)
    },
    [hasTaxes]
  )

  const handleChange = useCallback(e => {
    setChargeTax(e.target.checked)
  }, [])

  const handleCancel = useCallback(
    () => {
      handleVisibleChange(false)
    },
    [handleVisibleChange]
  )

  const handleSave = useCallback(
    () => {
      setVisible(false)
      onHasTaxesChange(chargeTax)
    },
    [chargeTax, onHasTaxesChange]
  )

  const footer = (
    <>
      <Button type="tertiary" size="small" onClick={handleCancel}>
        Cancel
      </Button>
      <Button size="small" onClick={handleSave}>
        Apply
      </Button>
    </>
  )

  const overlay = useMemo(
    () => {
      return (
        <div className="grui px-7 py-6 text-left">
          <p
            css={[paragraph.styles.preflight, text.styles.textMediumDark]}
            className="grui mb-5"
          >
            Taxes are automatically calculated
          </p>
          <Checkbox
            id="chargeTaxes"
            checked={chargeTax}
            onChange={handleChange}
          >
            Charge taxes
          </Checkbox>
        </div>
      )
    },
    [chargeTax, handleChange]
  )

  return (
    <Dropdown
      overlay={overlay}
      footer={footer}
      footerWithBtns
      hasMinWidth
      autoHeight
      visible={visible}
      onVisibleChange={handleVisibleChange}
      disabled={disabled}
    >
      {children}
    </Dropdown>
  )
}

SetTax.propTypes = {
  children: oneOfType([string, node]),
  onHasTaxesChange: func,
  hasTaxes: bool,
}

SetTax.defaultProps = {
  children: undefined,
  onHasTaxesChange() {},
  hasTaxes: true,
}

export default SetTax
