import { css } from '@emotion/react'

const messageCard = css`
  a {
    text-decoration: none;
  }
  .header {
    font-weight: 600;
  }
`

export const styles = {
  messageCard,
}
