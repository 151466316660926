import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'redux-first-router-link'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { warningTriangle as WarningTriangle } from 'assets/icons/groove/v2'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import {
  TO_SETTINGS_BILLING_PAGE,
  TO_SETTINGS_BILLING_DRAFT_CREDITS_PAGE,
} from 'ducks/billing/constants'
import { useAiDraftGenerationAction } from 'ducks/ai/hooks'
import { ADMIN_EMAIL_NOTIFICATION_TYPES } from 'ducks/ai/types'
import { doAgentSendAdminEmailNotification } from 'ducks/agents/operations'
import { styles } from './styles'

const AIDraftLockout = ({ onExit, open }) => {
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(false)
  const {
    wallet: { canUse },
    isAiDraftGenerationFeatureEnabled,
    isPaidAccount,
    canUseFeatureByFeatureLimit,
  } = useAiDraftGenerationAction()
  const isCurrentUserManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const handleRequestUpgrade = useCallback(
    async () => {
      setDisabled(true)
      const response = await dispatch(
        doAgentSendAdminEmailNotification(
          ADMIN_EMAIL_NOTIFICATION_TYPES.aiDraft
        )
      )
      setDisabled(false)
      if (!response.error) {
        onExit()
      }
    },
    [dispatch, onExit]
  )

  const buttonName = useMemo(
    () => {
      if (!isCurrentUserManager) {
        return `Request access to more ${app.t('AI_Draft')} credits`
      }
      if (canUseFeatureByFeatureLimit && isPaidAccount) {
        return `Manage credits`
      }
      return `View plans + upgrade`
    },
    [isCurrentUserManager, canUseFeatureByFeatureLimit, isPaidAccount]
  )

  const to = useMemo(
    () => {
      if (!isCurrentUserManager) {
        return undefined
      }
      if (canUseFeatureByFeatureLimit && isPaidAccount) {
        return TO_SETTINGS_BILLING_DRAFT_CREDITS_PAGE
      }
      return TO_SETTINGS_BILLING_PAGE
    },
    [isCurrentUserManager, canUseFeatureByFeatureLimit, isPaidAccount]
  )

  useEffect(
    () => {
      if (!isAiDraftGenerationFeatureEnabled || canUse) {
        onExit()
      }
    },
    [onExit, canUse, isAiDraftGenerationFeatureEnabled]
  )

  return (
    <Modal
      portal="#drawer-root"
      panelCenter
      open={open}
      css={styles.modalPanel}
      onClose={onExit}
      backdropStyles={styles.backdrop}
      className="grui text-center"
    >
      <WarningTriangle width="64" height="64" />
      <Modal.Title css={styles.title} className="grui mt-10">
        {canUseFeatureByFeatureLimit
          ? `${app.t('AI_Draft')} credit limit reached`
          : `Free ${app.t('AI_Draft')} credit limit reached`}
      </Modal.Title>
      <Modal.Description css={styles.description}>
        {isCurrentUserManager
          ? `Uh oh! You've hit the cap for your ${app.t(
              'AI_draft'
            )} generations. To continue using ${app.t(
              'AI_drafts'
            )}, you’ll need to add more credits or upgrade your plan.`
          : `Uh oh! You've hit the cap for your ${app.t(
              'AI_draft'
            )} generations. To continue using ${app.t(
              'AI_drafts'
            )}, an admin of your account will need to add more credits or upgrade your plan.`}
      </Modal.Description>
      <Modal.Button
        onClick={isCurrentUserManager ? undefined : handleRequestUpgrade}
        className="grui mt-10 px-18"
        size="big"
        css={text.styles.fontSemibold}
        disabled={disabled}
        as={isCurrentUserManager ? Link : undefined}
        to={to}
      >
        {buttonName}
      </Modal.Button>
    </Modal>
  )
}

export default AIDraftLockout
