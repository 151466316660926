import { useSelector } from 'react-redux'
import { selectReactionsForCommentId } from 'selectors/currentChangesets'
import { nonEmbeddedAttachments } from 'util/changesets'
import Attachments from 'components/Attachments'
import ReactionList from 'components/ReactionList'

import Header from './Header'
import Body from './Body'
import TrackingDetails from './TrackingDetails'

import { getAvatarComponent } from './util'

const ExpandedNoteContent = ({
  message,
  expandChangeset,
  currentUserId,
  isFirstMessage,
  showMeta,
  onOpenImage,
  onCopyLinkClick,
  onPrintClick,
  onDeleteClick,
  onEditClick,
  onReactionClick,
}) => {
  const action = message.action
  const hasAttachments = message.hasAttachments
  const isFromMerge = message.isFromMerge
  const isForward = message.isForward
  const readReceipt = message.readReceipt

  const change = action.change
  const isNote = message.isNote
  const isBodyEmpty = change.isBodyEmpty
  const filtered = nonEmbeddedAttachments(change)

  const reactions = useSelector(state =>
    selectReactionsForCommentId(state, action.change.id)
  )

  return (
    <React.Fragment>
      <div className="header-body-container">
        {getAvatarComponent({ action })}
        <div className="note-header-body">
          <div onClick={expandChangeset}>
            <Header
              action={action}
              currentUserId={currentUserId}
              isNote={isNote}
              isFirstMessage={isFirstMessage}
              isForward={isForward}
              hasAttachments={hasAttachments}
              isFromMerge={isFromMerge}
              hasRawEmail={message.hasRawEmail}
              onCopyLinkClick={onCopyLinkClick}
              onPrintClick={onPrintClick}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              onReactionClick={onReactionClick}
              readReceipt={readReceipt}
              showMeta={showMeta}
            />
          </div>
          <div>
            <Body
              change={change}
              isForward={isForward}
              isFirstMessage={isFirstMessage}
              onOpenImage={onOpenImage}
            />
          </div>
        </div>
      </div>
      <div className="bottom-side">
        <Attachments
          attachments={isBodyEmpty ? change.attachments : filtered}
        />
        {isFirstMessage && <TrackingDetails />}
        <ReactionList
          reactions={reactions}
          currentUserId={currentUserId}
          onClick={onReactionClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ExpandedNoteContent
