import React from 'react'
import cn from 'classnames'
import { Tooltip } from 'shared/ui'
import styles from './styles.css'

export default function Badge(props) {
  const { children, className, tooltipPosition, tooltipColor, ...rest } = props
  return (
    <Tooltip
      {...rest}
      className={cn(styles.badge, className)}
      position={tooltipPosition}
      color={tooltipColor}
    >
      {children}
    </Tooltip>
  )
}
