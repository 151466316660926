import React from 'react'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { Modal, Grid } from 'util/ui'

import ManagePane from './Manage'
import FormPane from './Form'
import DeletePane from './Delete'

const ManageModal = ({
  isEditing,
  isCreating,
  isDeleting,
  isOpen,
  onClose,
  onCreate,
  onUpdate,
}) => {
  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnRootNodeClick
      dimmer="inverted"
      onClose={onClose}
      open={isOpen}
      size="fullscreen"
      mountNode={app.suiModalRoot}
    >
      <Modal.Content>
        <Grid container centered columns={1}>
          <Grid.Column computer={6} largeScreen={5} widescreen={5}>
            <KeyboardNavigator.Focus />
            {!isEditing && !isCreating && !isDeleting && <ManagePane />}
            {isEditing && <FormPane onSave={onUpdate} />}
            {isCreating && <FormPane onSave={onCreate} isNew />}
            {isDeleting && <DeletePane />}
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default ManageModal
