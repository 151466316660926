import { connect } from 'react-redux'
import { required, minLength, match, join } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'
import {
  selectChangePasswordRequire2fa,
  selectChangePasswordErrors,
  selectIsValidResetPasswordToken,
  selectIsLoadingChangePassword,
} from 'selectors/account'
import {
  doChangePasswordWithResetToken,
  doLoadResetPasswordRequest,
} from './actions'

import ChangePasswordFormView from './view'

const initialValues = {
  passwordInput: '',
  passwordConfirmInput: '',
  twoFactorAuthenticationInput: '',
}

const validators = {
  passwordInput: join([required, minLength(6)]),
  passwordConfirmInput: join([required, match('passwordInput')]),
}

const select = state => ({
  initialValues,
  validators,
  isLoading: selectIsLoadingChangePassword(state),
  passwordTokenFound: selectIsValidResetPasswordToken(state),
  changePasswordErrors: selectChangePasswordErrors(state),
  isTwoFactorAuthRequired: selectChangePasswordRequire2fa(state),
})

const perform = {
  onSave: doChangePasswordWithResetToken,
  onLoad: doLoadResetPasswordRequest,
}

export default connect(select, perform)(withFormFields(ChangePasswordFormView))
