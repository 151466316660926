import React from 'react'
import NoteEditor from 'components/App/DesktopView/shared/Composer/Note/Editor'
import { memoize } from 'util/memoization'

const config = memoize(uploadAttachmentsFromEditorPaste => {
  return {
    uploadAttachmentsFromEditorPaste,
  }
})

export default class EditorView extends React.PureComponent {
  onChange = text => {
    const { content, onChange } = this.props
    // We will handle content unchanged situation in the debouncedHandleDraftChange action
    // to make sure the content (draft body state) is updated
    onChange(text, content)
  }

  editorKey = () => {
    const { ticketId } = this.props
    return `reply-editor-ticket-${ticketId}`
  }

  // expansion controls whether the editor should update if we expand
  // the content key.
  // If we expand, it should not update.
  // If we shrink, we should update.
  editorKeyExpansion = () => {
    const { draftId } = this.props
    return draftId || null
  }

  render() {
    const {
      content,
      onKeyDown,
      onMention,
      onFocus,
      onBlur,
      onInit,
      uploadAttachmentsFromEditorPaste,
      focusWhenInitialized,
      placeholder,
    } = this.props

    return (
      <NoteEditor
        content={content}
        contentKey={this.editorKey()}
        contentKeyExpansion={this.editorKeyExpansion()}
        className="editor"
        onChange={this.onChange}
        onKeyDown={onKeyDown}
        onMention={onMention}
        onFocus={onFocus}
        onBlur={onBlur}
        onInit={onInit}
        config={config(uploadAttachmentsFromEditorPaste)}
        focusWhenInitialized={focusWhenInitialized}
        placeholder={placeholder}
      />
    )
  }
}
