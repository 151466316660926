import React from 'react'
import { getParents } from 'util/dom'

export default class Printable extends React.Component {
  componentDidMount() {
    this.markPrintable()
    this.setupMutationObserver()
  }

  setupMutationObserver() {
    if (!this.node) return
    const observer = new MutationObserver(this.markPrintable)
    observer.observe(this.node, { childList: true, subtree: true })
  }

  saveRef = node => {
    this.node = node
    if (this.props.passRef) this.props.passRef(node)
  }

  markPrintable = () => {
    if (!this.node) return

    const parents = getParents(this.node)
    const subelements = Array.from(this.node.querySelectorAll('*'))
    this.nodes = parents.concat(subelements)

    this.nodes.forEach(element => element.classList.add('printable'))
  }

  render() {
    const { passRef, ...rest } = this.props

    return <div {...rest} ref={this.saveRef} />
  }
}
