import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectOrderForId } from 'ducks/integrations/recharge/selectors'
import { formatDateForCurrentYear } from 'ducks/integrations/recharge/utils'
import { capture } from 'ducks/tracking/actions'
import { capitalize } from 'util/strings'

import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function ListItem({ orderId, subscriptionId, date }) {
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const order = useSelector(state =>
    selectOrderForId(state, { subscriptionId, orderId })
  )

  const createdAt = useMemo(
    () => formatDateForCurrentYear(date, '%d %b', '%d %b %Y'),
    [date]
  )

  const status = useMemo(
    () => {
      switch (order.status) {
        case 'success':
          return 'success'
        case 'error':
          return 'warning'
        case 'cancelled':
          return 'negative'
        case 'queued':
          return 'neutral'
        default:
          return ''
      }
    },
    [order.status]
  )

  const onClick = useCallback(
    () => {
      navigatableSetState({
        orderId: order.id,
        customerId: order.customer.id,
        subscriptionId,
        type: 'order',
      })
      capture('Recharge Order Viewed')
    },
    [navigatableSetState, order, subscriptionId]
  )

  return (
    <NavigatableIntegrationWidgetCard.ListItem
      title={order.id}
      onClick={onClick}
      subTitle={createdAt}
      subTitleStatus={status}
      subTitleStatusText={capitalize(order.status)}
    />
  )
}
