const env = process.env.APP_ENV || process.env.NODE_ENV || 'development'

let configFile

const kubesHosts = {}

const isAlpha = !!self.location.host.match(/(alpha\.groovehq\.com)|(\.local$)/)

let shouldUseKubesEndpoints = false
if (typeof self !== 'undefined') {
  if (kubesHosts[self.location.host]) {
    shouldUseKubesEndpoints = true
  } else if (self.USE_NEW_ENDPOINTS === true) {
    shouldUseKubesEndpoints = true
  } else if (isAlpha) {
    shouldUseKubesEndpoints = true
  }
}

// a40da0c introduced the LStorage dependency.
// However this code is also executed inside a ServiceWorker, which has no
// access to LS.
try {
  configFile = localStorage.getItem('groove-env')
} catch (e) {} // eslint-disable-line

if (!configFile || configFile === 'null') {
  if (shouldUseKubesEndpoints) {
    configFile = 'kubes'
  } else {
    configFile = env.match(/(alpha|beta|metal)/) ? 'production' : env
  }
}

const config = require(`./config/${configFile}`) // eslint-disable-line import/no-dynamic-require

config.env = env

config.isDevelopment = config.env === 'development'
config.isTest = config.env === 'test'
config.isStaging = config.env === 'staging'
config.isProduction = config.env === 'production'

config.isAlpha = isAlpha

config.isDocker = !!self.location.host.match(/(\.groovehq\.docker)/)

export default config
