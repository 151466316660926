import { useMemo } from 'react'

import { doUnlinkJiraCloudIssue } from 'ducks/integrations/jiraCloud'
import { useAction } from 'util/hooks'

export function useJiraCloudIssueActions(issue, doCloseIssueView) {
  const { externalId, url } = issue || {}
  const onUnlink = useAction(doUnlinkJiraCloudIssue)
  return useMemo(
    () => {
      if (!externalId) return []
      return [
        {
          action: () => {
            onUnlink(externalId)
            doCloseIssueView()
          },
          text: 'Unlink',
        },
        {
          blankTarget: true,
          text: ['Open on Jira'],
          url,
        },
      ]
    },
    [doCloseIssueView, externalId, onUnlink, url]
  )
}
