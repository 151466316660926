import { connect } from 'react-redux'
import {
  selectIsAddingNote,
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
  selectIsViewingTicket,
} from 'selectors/page'
import { doDropFiles } from 'actions/droppable_files'
import withDropzoneHoc from './view'

const select = state => {
  return {
    isOnNewForm:
      selectIsCreatingNewEmail(state) || selectIsLoggingNewConversation(state),
    isCreatingPrivateNote:
      selectIsAddingNote(state) || selectIsLoggingNewConversation(state),
    isOnTicketOrDraftPage: selectIsViewingTicket(state),
  }
}

const perform = {
  onDropFiles: doDropFiles,
}

export default function withDropzone(WrappedComponent, options) {
  return connect(select, perform)(withDropzoneHoc(WrappedComponent, options))
}
