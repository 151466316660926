import React from 'react'
import cn from 'classnames'
import storage from 'util/storage'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import FormatIcon from '@groovehq/internal-design-system/lib/assets/icons/Format'
import TrashIcon from '@groovehq/internal-design-system/lib/assets/icons/Trash'

// global
import { Flex } from 'shared/ui'
import { propFunc, runOnNextTick } from 'util/functions'
import { isCmdEnter } from 'util/keys'
import { isMac } from 'util/browser'
import { DIVIDE, FORWARD_SLASH, NUM7 } from 'constants/keys'
import { getEditor, COMPOSER_ID } from 'shared/editor/utils'
import { updateToWithRecipientName } from 'ducks/drafts2/util'

// re-used
import NavBar from 'components/App/DesktopView/shared/Composer/Email/Header/NavBar'
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import ReplyStateIndicator from 'components/ReplyStateIndicator'
import Attachments from 'components/Attachments'
import ExpandedHeader from 'components/App/DesktopView/shared/Composer/Header/Expanded'
import EditorDropzoneIndicator from 'components/App/DesktopView/shared/Composer/shared/EditorDropzoneIndicator'
import withDropzone from 'components/Dropzone'
import SearchKb from 'subapps/ticketing/components/TicketSearchKb'
import AssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge'

// styles
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'

// shared
import SendButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/SendButton'
import AttachmentButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AttachmentButton'
import { handleDrop } from 'components/App/DesktopView/CommunicationForms/shared/dragAndDrop'
import AgentCollision from 'components/App/DesktopView/Layout/TicketInspector/AgentCollision'
import AssignmentDropdown from 'components/App/DesktopView/CommunicationForms/Reply/shared/AssignmentDropdown'
import AttachmentUpload from 'components/AttachmentUpload'
import AiButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AiButton'

// local
import FormSwitcherButton from './FormSwitcherButton'
import Editor from './EditorView'
import Sizer from '../../shared/Sizer'
import NoteReplySwitcher from '../shared/NoteReplySwitcher'
import PinButton from '../shared/PinButton'

const FlexWithDropzone = withDropzone(Flex, {
  indicatorComponent: EditorDropzoneIndicator,
  className: cn(styles.EditorDropzone, 'Dropzone'),
})

const FooterWithDropzone = withDropzone('div', {
  skipIndicator: true,
})

class ReplyEmailForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      formattingVisible: !!storage.get('showFormattingToolbar'),
      kbDropdownVisible: false,
    }
    this.footerRef = React.createRef()
    this.aiOverlayTopRef = React.createRef()
    this.aiOverlayWidthRef = React.createRef()
    this.aiOverlayBottomRef = React.createRef()
  }

  componentDidMount() {
    const { isForwardingFromDraft, isForwardingFromQuery, to } = this.props
    this.ensureDefaults()
    if (
      (isForwardingFromDraft === undefined && isForwardingFromQuery) ||
      (isForwardingFromDraft && !to?.length)
    ) {
      this.onForwardClick()
    }
  }

  // We need to do this, because we cannot hide the editor until changesets are fully loaded, so it is possible to start a draft _without_ defaults. Which is why we need to detect the moment the defaults are available and use ensureDefaults to safely set them.
  componentDidUpdate(prevProps) {
    const { isForwardingFromQuery } = this.props
    this.ensureDefaults()
    if (!prevProps.isForwardingFromQuery && isForwardingFromQuery) {
      this.fixForwardingIfRequired()
    }
  }

  onEditorContentChange = (text, oldBody) => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props
    if (!draftId && !text) return

    debouncedHandleDraftChange(ticketId, 'body', text, {
      draftId,
      oldBody,
    })
    this.ensureDefaults()
  }

  onAgentChange = (_, groupId, agentId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeId', agentId, {
      draftId,
    })
  }

  onGroupChange = (_, groupId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeGroupId', groupId, {
      draftId,
    })
  }

  onSubjectChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    debouncedHandleDraftChange(ticketId, 'title', value, {
      draftId,
    })
  }

  onNameChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props
    const { to } = this.props

    debouncedHandleDraftChange(
      ticketId,
      'to',
      updateToWithRecipientName(to, value),
      {
        draftId,
      }
    )
  }

  onMailboxClick = mailboxId => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'mailboxId', mailboxId, {
      draftId,
    })
  }

  onSendAndOpen = () => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: 'opened', isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndClose = () => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: 'closed', isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndSnooze = snoozeUntil => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { snoozeUntil, isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onDeleteClick = () => {
    const { deleteDraft, ticketId, draftId } = this.props
    deleteDraft(ticketId, draftId)
  }

  onAttachmentClick = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  onReplyAllClick = () => {
    const {
      switchToReplyAll,
      ticketId,
      draftId,
      isForwardingFromQuery,
      openTicketPageWithReplying,
    } = this.props
    switchToReplyAll(ticketId, draftId)
    if (isForwardingFromQuery) {
      // Navigate to the ticket page, so can remove the forward slug
      openTicketPageWithReplying(ticketId)
    }
  }

  onDirectReplyClick = () => {
    const {
      switchToDirectReply,
      ticketId,
      draftId,
      isForwardingFromQuery,
      openTicketPageWithReplying,
    } = this.props
    switchToDirectReply(ticketId, draftId)
    if (isForwardingFromQuery) {
      openTicketPageWithReplying(ticketId)
    }
  }

  onForwardClick = () => {
    const { draftId, ticketId, switchToForward } = this.props
    switchToForward(ticketId, draftId)
  }

  onUploadComplete = () => {
    const { ticketId, draftId, handleUploadComplete } = this.props
    handleUploadComplete(ticketId, draftId)
  }

  getPlaceholderText() {
    const shortcut = isMac() ? '⌘/' : 'alt + /'
    return `Use ${shortcut} for ${app.t('canned_replies')}`
  }

  ensureDefaults = () => {
    const {
      defaultsAvailable,
      draftId,
      defaultsSet,
      ensureDefaults,
      forwarding,
    } = this.props
    if (draftId && !defaultsSet && defaultsAvailable) {
      runOnNextTick(() => ensureDefaults(draftId, forwarding))
    }
  }

  // this is a way of detecting that we have a conflict between the query string
  // and the draft state. We have an option to drive the forwarding state from the url
  // but in order to be consistent, we need to "copy" that state from the url into the
  fixForwardingIfRequired = () => {
    const { isForwardingFromDraft, isForwardingFromQuery } = this.props
    if (!isForwardingFromDraft && isForwardingFromQuery) {
      this.onForwardClick()
    }
  }

  afterAttachmentDelete = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  toggleFormatting = () => {
    const { formattingVisible } = this.state
    this.setState({
      formattingVisible: !formattingVisible,
    })
    storage.set('showFormattingToolbar', !formattingVisible)
  }

  handleDropOnEditor = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      handleDrop(accepted, updatedDraftId, uploadFiles)
    }, 100)
  }

  handleDropOnFooter = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      handleDrop(accepted, updatedDraftId, uploadFiles)
    }, 100)
  }

  handleKbDropdownVisible = visible =>
    this.setState({ kbDropdownVisible: visible })

  uploadAttachmentsFromEditorPaste = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()

    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      uploadFiles(accepted, false, updatedDraftId)
    }, 100)
  }

  startTyping = () => {
    const { ticketId, realtimeAgentStartTicketTypingNotification } = this.props
    realtimeAgentStartTicketTypingNotification(ticketId)
  }

  handleOnKeyDownEvent = e => {
    if ([FORWARD_SLASH, NUM7, DIVIDE].includes(e.keyCode)) {
      const isForMac = isMac() && e.metaKey
      const isForOthers = !isMac() && (e.altKey || (e.altKey && e.shiftKey))

      if (!isForMac && !isForOthers) {
        return true
      }

      const tinyEditor = getEditor(COMPOSER_ID)
      const cursorBookmark = tinyEditor.selection.getBookmark()

      this.props.openCannedReplyDropdown('forward-slash', cursorBookmark)

      return true
    }

    if (isCmdEnter(e)) {
      const { isSendable, prefersOpen } = this.props

      e.preventDefault()
      e.stopPropagation()
      if (isSendable) {
        if (prefersOpen) {
          return this.onSendAndOpen()
        }
        return this.onSendAndClose()
      }
      return true
    }
    this.startTyping()
    return true
  }

  render() {
    const { formattingVisible, kbDropdownVisible } = this.state
    const {
      ticketId,
      draftId,
      mailboxId,
      subject,
      to,
      cc,
      bcc,
      isSendable,
      notSendableReason,
      forwarding,
      isOpen,
      onEditorFormFocus,
      onEditorFormBlur,
      onEditorInit,
      showKbSearch,
      assignmentLabel,
      assigneeGroupId,
      prefersOpen,
      attachments,
      isNewTicket,
      prefersAiEnabled,
      className,
    } = this.props

    let actionLabel = forwarding ? 'Forward' : 'Reply'
    if (isNewTicket) {
      actionLabel = 'Send'
    }

    return (
      <div
        id="composer"
        className={cn(styles.form, className)}
        data-test-id="reply-editor-email"
      >
        <Sizer
          className={cn({ forwarding, 'new-email': isNewTicket })}
          footerRef={this.footerRef}
        >
          <div
            className={cn(
              'form-header-wrapper',
              kbDropdownVisible && 'kbDropdownVisible'
            )}
          >
            <AnimatePresence initial={false}>
              {showKbSearch && (
                <motion.div
                  layout
                  initial={{ height: 0, overflow: 'hidden' }}
                  animate={{
                    height: 'auto',
                    transitionEnd: { overflow: 'visible' },
                  }}
                  exit={{ height: 0, overflow: 'hidden' }}
                  transition={{ ease: 'easeInOut', duration: 0.2 }}
                >
                  <SearchKb
                    className="grui mb-5 search-kb"
                    onFocus={propFunc(this.handleKbDropdownVisible, true)}
                    onBlur={propFunc(this.handleKbDropdownVisible, false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <div className="form-header" ref={this.aiOverlayTopRef}>
              <div className="envelope-section sui sui-transparent">
                <div className="sui-inner sui-inner-transparent">
                  <ExpandedHeader
                    className="expanded-header"
                    recipientsTo={to}
                    recipientsCc={cc}
                    recipientsBcc={bcc}
                    name={to?.[0]?.name}
                    subject={subject}
                    onClick={this.onClick}
                    onSubjectChange={this.onSubjectChange}
                    onNameChange={this.onNameChange}
                    onMailboxClick={this.onMailboxClick}
                    mailboxId={mailboxId}
                    ticketId={ticketId}
                    recipientsOpenUpward
                    forwarding={forwarding}
                  >
                    {!isNewTicket && (
                      <FormSwitcherButton
                        key={ticketId}
                        ticketId={ticketId}
                        onReplyAllClick={this.onReplyAllClick}
                        onDirectReplyClick={this.onDirectReplyClick}
                        onForwardClick={this.onForwardClick}
                      />
                    )}
                  </ExpandedHeader>
                </div>
              </div>
              <NavBar
                replying
                ticketId={ticketId}
                forwarding={forwarding}
                className="extras-section"
              />
              {!isNewTicket && <PinButton />}
            </div>
          </div>
          <div className="form-editor-wrapper" ref={this.aiOverlayWidthRef}>
            <FlexWithDropzone
              className={cn('editorAndAttachments grui flex-col flex-2-1-auto')}
              onDropCustom={this.handleDropOnEditor}
            >
              <Editor
                id="email-editor"
                className="toolbar-bottom"
                ticketId={ticketId}
                draftId={draftId}
                onChange={this.onEditorContentChange}
                onKeyDown={this.handleOnKeyDownEvent}
                onFocus={onEditorFormFocus}
                onBlur={onEditorFormBlur}
                onInit={onEditorInit}
                uploadAttachmentsFromEditorPaste={
                  this.uploadAttachmentsFromEditorPaste
                }
                focusWhenInitialized={
                  isNewTicket ? !!to?.length : !forwarding || !!to?.length
                }
                placeholder={this.getPlaceholderText()}
              />

              <div className="attachments">
                <Attachments
                  forDraft
                  draftId={draftId}
                  ticketId={ticketId}
                  afterDelete={this.afterAttachmentDelete}
                  footerRef={this.footerRef}
                />
              </div>
            </FlexWithDropzone>
          </div>
          <div className="form-footer-wrapper" ref={this.footerRef}>
            <FooterWithDropzone
              className="form-footer"
              onDropCustom={this.handleDropOnFooter}
            >
              <div
                className={cn({
                  'formatting-visible': formattingVisible,
                })}
                ref={this.aiOverlayBottomRef}
              >
                <div id="email-editor-toolbar" />
              </div>
              <div className="form-footer-content">
                <div
                  className="sendButtonContainer"
                  data-test-id="send-reply-button"
                >
                  <SendButton
                    actionLabel={actionLabel}
                    disabled={!isSendable}
                    isOpen={isOpen}
                    ticketId={ticketId}
                    draftId={draftId}
                    onSendOpenClick={this.onSendAndOpen}
                    onSendClosedClick={this.onSendAndClose}
                    onSnoozeOptionClick={this.onSendAndSnooze}
                    prefersOpen={prefersOpen}
                  />
                  {!isNewTicket && (
                    <ReplyStateIndicator
                      className={'collisionIndicator'}
                      hideDraftIndicator
                      conversationId={ticketId}
                    />
                  )}
                </div>
                {prefersAiEnabled && <AiButton />}
                <div className="grui flex flex-grow">
                  <div className="left-side">
                    {!isSendable &&
                      notSendableReason && (
                        <span className="not-sendable-reason large">
                          {notSendableReason}
                        </span>
                      )}

                    <div className="actions">
                      <Tooltip title="Toggle formatting" position="top">
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.toggleFormatting}
                        >
                          <FormatIcon />
                        </Button>
                      </Tooltip>
                      <div className="attachments">
                        <AttachmentButton
                          triggerId="attachmentUploadTrigger"
                          attachments={attachments}
                          onClick={this.onAttachmentClick}
                        >
                          <AttachmentUpload
                            hideIcon
                            triggerId="attachmentUploadTrigger"
                            draftId={draftId}
                            onUploadComplete={this.onUploadComplete}
                          />
                        </AttachmentButton>
                      </div>
                      <EmojiPicker />
                      {!isNewTicket && (
                        <NoteReplySwitcher ticketId={ticketId} />
                      )}
                      <AssignmentDropdown
                        direction="left"
                        drilledDownGroupId={assigneeGroupId}
                        key="assignment"
                        onGroupSelect={this.onGroupChange}
                        onAgentSelect={this.onAgentChange}
                        trigger={
                          <div className="left-divider">
                            <AssignmentBadge
                              assignmentLabel={assignmentLabel}
                              size="small"
                              iconSize={24}
                              tooltipPosition="top"
                            />
                          </div>
                        }
                        upward
                      />
                    </div>
                  </div>
                  <div className="right-side">
                    {!isNewTicket && <AgentCollision />}
                    <div className="actions">
                      <Tooltip
                        title="Delete reply"
                        position="top"
                        strategy="fixed"
                      >
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.onDeleteClick}
                          className="deleteButton"
                          data-test-id="DraftDeleteButton"
                        >
                          <TrashIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </FooterWithDropzone>
          </div>
          {!isSendable &&
            notSendableReason && (
              <span className="not-sendable-reason small">
                {notSendableReason}
              </span>
            )}
        </Sizer>
      </div>
    )
  }
}

export default ReplyEmailForm
