import withOpenState from 'shared/ui/withOpenState'

import { Button } from 'shared/ui'

import CreateContactModal from './Modals/CreateContact'

export default withOpenState(CreateHubSpotContactButton)

function CreateHubSpotContactButton({ onClose, onOpen, open }) {
  return (
    <>
      {open && <CreateContactModal open={open} onClose={onClose} />}
      <Button basic onClick={onOpen} ml={2} mt={2}>
        + Create contact
      </Button>
    </>
  )
}
