import React from 'react'
import { pathToAction } from 'redux-first-router'
import cn from 'classnames'
import { runOnNextTick } from 'util/functions'
import KeyboardAndScrollerContext from '../KeyboardAndScrollerContext'

function isAction(to) {
  return typeof to === 'object' && !Array.isArray(to)
}

export default class KeyboardNavigatorItem extends React.Component {
  static contextType = KeyboardAndScrollerContext

  constructor(props, context) {
    super(props)
    if (context.keyboardNavigator) context.keyboardNavigator.registerItem(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
  }

  componentDidMount() {
    if (this.props.active) this.activate()
  }

  componentWillReceiveProps(props) {
    if (props.active && !this.props.active) {
      this.activate()
    }
  }

  componentWillUnmount() {
    if (this.context.keyboardNavigator)
      this.context.keyboardNavigator.unregisterItem(this)
  }

  getKeyboardNavigatorProp = () => {
    if (!this.context || !this.context.keyboardNavigator) return undefined
    const {
      registeredItems,
      registerItem,
      unregisterItem,
      activeIndex,
      lastIndex,
      totalSize,
      ...rest
    } = this.context.keyboardNavigator
    const itemIndex = this.getItemIndex()
    return {
      ...rest,
      lastIndex,
      totalSize,
      activeIndex,
      itemIndex,
      isActive: activeIndex === itemIndex,
    }
  }

  getItemIndex = () => {
    const { registeredItems } = this.context.keyboardNavigator
    return registeredItems.indexOf(this)
  }

  pleaseUpdate = () => {
    this.setState({})
  }

  handleActivate = event => {
    if (!event) return
    if (this.context && this.context.getScrollerAPI && this.element) {
      // eslint-disable-next-line no-unused-expressions
      this.element?.scrollIntoViewIfNeeded()
    }
    if (this.props.onActivate) this.props.onActivate(event, this.element)
  }

  handleMouseMove = event => {
    const { keyboardNavigator } = this.context
    const { componentProps, onMouseMove } = this.props
    if (keyboardNavigator.activateOnHover && !keyboardNavigator.isActive) {
      this.activate(event, true) // Forced as mouse events don't care if enabled or not
    }
    const handleMouseMove =
      (componentProps && componentProps.onMouseMove) || onMouseMove
    return handleMouseMove && handleMouseMove(event)
  }

  takeRef = node => {
    this.element = node
    const { componentProps, ref, forwardedRef } = this.props
    const refFunction =
      (componentProps && componentProps.ref) || ref || forwardedRef
    if (typeof refFunction === 'function') return refFunction(node)
    if (refFunction?.current) {
      refFunction.current = node
      return undefined
    }
    return null
  }

  handleSelect = (index, event) => {
    const { doNavigateToAction, onSelect, routesMap, to } = this.props
    if (onSelect) onSelect(index, event)
    if (to) doNavigateToAction(isAction(to) ? to : pathToAction(to, routesMap))
  }

  handleClick = event => {
    const { componentProps, disableClickToSelect, onClick } = this.props
    const handleMouseClick =
      (componentProps && componentProps.onClick) || onClick
    if (handleMouseClick) handleMouseClick(event)
    if (!disableClickToSelect) this.handleSelect(this.getItemIndex(), event)
  }

  activate = (event, force = false) => {
    // Need to defer so that item has been mounted in DOM and index is
    // correctly determined from that order. Also need to remove this event
    // from the pool so its properties are preserved when deferred function
    // runs (https://reactjs.org/docs/events.html#event-pooling)
    if (event) event.persist()

    const { keyboardNavigator } = this.context
    runOnNextTick(() => {
      if (keyboardNavigator) {
        keyboardNavigator.setActiveItem(this.getItemIndex(), event, force)
      }
    })
  }

  render() {
    const {
      activeClassName,
      activeClassNameOverridden,
      component,
      componentProps,
      children,
      className,
      activateOnHover,
      enabled,
      onActivate,
      to,
      disableClickToSelect,
      ...componentCompatileProps
    } = this.props
    const {
      active,
      doNavigateToAction,
      onSelect,
      routesMap,
      forwardedRef,
      ...domCompatibleProps
    } = componentCompatileProps
    const keyboardNavigator = this.getKeyboardNavigatorProp()
    if (!keyboardNavigator) return children

    const { isActive } = keyboardNavigator
    return (
      <div
        {...domCompatibleProps}
        className={cn(
          className,
          {
            active: isActive && !activeClassNameOverridden,
          },
          isActive ? activeClassName : undefined
        )}
        onClick={this.handleClick}
        onMouseMove={this.handleMouseMove}
        ref={this.takeRef}
      >
        {typeof children === 'function' ? children({ isActive }) : children}
      </div>
    )
  }
}
