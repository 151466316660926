import { useMemo } from 'react'
import { Icon } from 'util/ui'

import cn from 'classnames'

export default function NavigatableIntegrationListItem({
  onClick,
  title,
  subTitle,
  subTitleStatus,
  subTitleStatusText,
}) {
  const statusClassName = useMemo(
    () => {
      const className = 'NavigatableIntegrationWidgetCard-ListItem-statusText'
      switch (subTitleStatus) {
        case 'success':
          return `${className}-positive`
        case 'warning':
          return `${className}-warning`
        case 'negative':
          return `${className}-negative`
        case 'neutral':
          return `${className}-neutral`
        default:
          return className
      }
    },
    [subTitleStatus]
  )

  return (
    <div
      className="NavigatableIntegrationWidgetCard-ListItem"
      onClick={onClick}
    >
      <div className="NavigatableIntegrationWidgetCard-ListItem-titleAndSubTitleColumn">
        <div className="NavigatableIntegrationWidgetCard-ListItem-title">
          {title}
        </div>
        <div className="NavigatableIntegrationWidgetCard-ListItem-subTitle">
          {subTitle}

          {subTitleStatusText && (
            <span
              className={cn(
                'NavigatableIntegrationWidgetCard-ListItem-statusText',
                statusClassName
              )}
              title={subTitleStatusText}
            >
              {subTitleStatusText}
            </span>
          )}
        </div>
      </div>
      {onClick && (
        <div className="NavigatableIntegrationWidgetCard-ListItem-iconColumn">
          <Icon name="chevron right" />
        </div>
      )}
    </div>
  )
}
