export default function ActionText({
  action: {
    change: {
      action: {
        changelog: { items: [{ field, fromString, toString }] = [{}] } = {},
        issue_event_type_name: eventTypeName,
        webhookEvent,
      },
    },
  },
}) {
  switch (webhookEvent) {
    case 'comment_created':
      return 'added a comment to '
    case 'jira:issue_deleted':
      return 'deleted '
    case 'jira:issue_updated':
      if (eventTypeName === 'issue_commented') return 'added a comment to '
      switch (field) {
        case 'assignee':
          return toString ? 'assigned ' : 'unassigned '
        case 'Component':
          if (toString) {
            return `added the component "${toString}" to `
          }
          return `removed the component "${fromString}" from `
        case 'duedate':
          return 'changed the due date of '
        case 'issuetype':
          return 'changed the issue type of '
        case 'Key':
          return 'changed the key of '
        case 'labels':
          return 'changed the labels of '
        case 'priority':
          return 'changed the priority of '
        case 'project':
          return 'changed the project of '
        case 'resolution':
          return toString === null
            ? 'unresolved '
            : 'changed the resolution of '
        case 'status':
          return 'changed the status of '
        case 'summary':
          return 'changed the summary of '
        default:
          return null
      }

    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
