import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import NoticeBar from 'components/NoticeBar'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectFuturePhase } from 'ducks/billing/selectors/selectFuturePhase'
import { selectFuturePricingIds } from 'ducks/billing/selectors/selectFuturePricingIds'
import { selectFutureBillingCycle } from 'ducks/billing/selectors/selectFutureBillingCycle'
import { selectFuturePhaseNoticeBarFlag } from 'ducks/billing/selectors/selectFuturePhaseNoticeBarFlag'
import { selectIsBillingPage } from 'subapps/settings/selectors'
import { diff } from 'util/date'
import { PLAN_DOWNGRADABLE_DIFF } from 'constants/billing'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { styles } from './styles'

const BillingPlanLimited = () => {
  const { startDate } = useSelector(selectFuturePhase)
  const flagName = useSelector(selectFuturePhaseNoticeBarFlag)
  const isBillingPage = useSelector(selectIsBillingPage)
  const overdue =
    diff('milliseconds', new Date(), startDate * 1000) < PLAN_DOWNGRADABLE_DIFF

  const {
    drawerId: billingDowngradeConfirmationDrawerId,
    openDrawer: openBillingDowngradeConfirmation,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
  })

  const futurePricingIds = useSelector(selectFuturePricingIds)
  const futureBillingCycle = useSelector(selectFutureBillingCycle)
  const forceShow = isBillingPage || overdue

  const handleClick = useCallback(
    () => {
      openBillingDowngradeConfirmation(futurePricingIds.join('|'), {
        query: {
          ...buildDrawerQueryParam(
            billingDowngradeConfirmationDrawerId,
            'drawerBillingCycle',
            futureBillingCycle
          ),
        },
      })
    },
    [
      openBillingDowngradeConfirmation,
      billingDowngradeConfirmationDrawerId,
      futureBillingCycle,
      futurePricingIds,
    ]
  )

  return (
    <NoticeBar
      forceShow={forceShow}
      dismissible={!forceShow}
      flagName={overdue ? undefined : flagName}
      autoFlagSeen={false}
      css={[
        styles.billingPlanLimitedAlert,
        overdue && styles.billingPlanLimitedOverdueAlert,
      ]}
      className="grui py-5"
    >
      {overdue
        ? `Your account's feature usage exceeds your new selected plan, this will
      lead to an automatic upgrade if not adjusted.`
        : `Your account is currently using features in excess of its scheduled downgrade.`}
      <button
        className="grui mx-5"
        css={button.styles.secondaryLink}
        onClick={handleClick}
      >
        Learn more
      </button>
    </NoticeBar>
  )
}

export default BillingPlanLimited
