import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const menuStyle = () => theme => css`
  min-width: ${rem(232)};
  color: ${theme.color.monochrome.black};
  border-radius: ${rem(8)};
  font-size: ${theme.fontSize.large};

  [role='menuitem'] {
    .item-icon {
      background-color: ${theme.color.monochrome.superLight};
    }
    &[tabindex='0']:hover,
    &.highlighted {
      background-color: ${theme.color.monochrome.superLight} !important;
      .item-icon {
        background-color: ${theme.color.monochrome.white};
      }
    }
  }
`

const dot = css`
  z-index: 1;
  top: ${rem(-2)};
  right: ${rem(-2)};
`

const badge = theme => css`
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
  line-height: 1;
  height: ${rem(19)};
  min-width: ${rem(19)};
  border-radius: ${rem(10)};
  background-color: ${theme.color.primary.info};
  color: ${theme.color.monochrome.white};
  padding: ${rem(3)} ${rem(7)} ${rem(4)};
`

export const styles = {
  menuStyle,
  dot,
  badge,
}
