import Link from 'redux-first-router-link'

import getIssueUrl from './getIssueUrl'

export default function ActionSubject({
  action: {
    change: {
      action: {
        changelog: { items: [{ field, fromString, toString }] = [{}] } = {},
        issue: { fields: { summary } = {}, key, self } = {},
        webhookEvent,
      },
    },
  },
}) {
  switch (webhookEvent) {
    case 'comment_created':
    case 'jira:issue_deleted':
    case 'jira:issue_updated':
      if (field === 'assignee') {
        return toString || fromString
      }
      return (
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={getIssueUrl(key, self)}
        >
          [{key}] {field === 'summary' ? fromString : summary}
        </Link>
      )

    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
