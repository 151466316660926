import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import { text as textStyle } from '@groovehq/internal-design-system/lib/styles/elements'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import ScrollerContext from 'components/Scroller/Context'
import { selectDraftReplyTextStreamingResult } from 'ducks/ai/selectors/streamingResults'

const SPINNER_MESSAGES = [
  {
    message: 'Analyzing converastion…',
    duration: [1000, 1500],
  },
  {
    message: 'Searching for relevant sources…',
    duration: [1500, 2500],
  },
  {
    message: 'Preparing draft…',
    duration: [1000, 2000],
  },
]

const LoadingState = () => {
  const [idx, setIdx] = useState(0)

  useEffect(
    () => {
      if (
        !SPINNER_MESSAGES[idx] ||
        idx === SPINNER_MESSAGES.length - 1 ||
        !SPINNER_MESSAGES[idx].duration
      ) {
        return () => {}
      }
      const [min, max] = SPINNER_MESSAGES[idx].duration
      const duration = Math.floor(Math.random() * (max - min + 1) + min)
      const timeout = setTimeout(() => {
        setIdx(idx + 1)
      }, duration)

      return () => {
        clearTimeout(timeout)
      }
    },
    [idx, setIdx]
  )

  return (
    <>
      <div className="not-printable grui flex flex-col items-center justify-center mt-5 mb-10">
        <Spinner size="medium" className="grui mb-10" />
        <div css={textStyle.styles.fontMedium}>
          {SPINNER_MESSAGES[idx].message}
        </div>
      </div>
      <div>
        <div className="grui overflow-x-hidden" style={{ width: '92%' }}>
          <div className="placeholder-content" />
        </div>
        <div className="grui overflow-x-hidden" style={{ width: '98%' }}>
          <div className="placeholder-content" />
        </div>
        <div className="grui overflow-x-hidden" style={{ width: '96%' }}>
          <div className="placeholder-content" />
        </div>
        <div className="grui overflow-x-hidden" style={{ width: '95%' }}>
          <div className="placeholder-content" />
        </div>
      </div>
    </>
  )
}

const StreamingResponse = () => {
  const content = useSelector(selectDraftReplyTextStreamingResult)
  const { getScrollerAPI } = useContext(ScrollerContext)

  useEffect(
    () => {
      const api = getScrollerAPI()
      if (api) {
        api.scrollToBottom()
      }
    },
    [getScrollerAPI, content]
  )

  return (
    <div>
      <SanitizedHTML className="body" html={content} />
      {!content && <LoadingState />}
    </div>
  )
}

export default StreamingResponse
