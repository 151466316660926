import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'
import { snoozeLabel } from 'util/snooze'

import SnoozeDropdown from 'components/App/DesktopView/shared/Snooze'

const SnoozeButton = React.memo(
  ({
    snoozeOpen,
    isSnoozed,
    onSnoozeIconClick,
    onSnoozeDropdownClose,
    onSnoozeOptionClick,
    snoozedUntil,
    isExpired = false,
    expiresAt = null,
  }) => {
    return (
      <div className="snooze">
        {isSnoozed && (
          <IconButton
            name="snooze"
            svg
            size="medium"
            className={cn('menu-button', {
              snoozed: isSnoozed,
            })}
            onClick={onSnoozeIconClick}
            disabled={isExpired}
            tooltip={
              !isExpired
                ? `Snooze (${snoozeLabel(snoozedUntil)}) [s]`
                : "Can't Unsnooze an expired conversation"
            }
          />
        )}

        {!isSnoozed && (
          <SnoozeDropdown
            size="medium"
            icon={null}
            iconButtonClassName="menu-button"
            onOptionClick={onSnoozeOptionClick}
            open={snoozeOpen}
            onClose={onSnoozeDropdownClose}
            tooltip={
              !isExpired
                ? 'Snooze [s]'
                : "Can't Unsnooze an expired conversation"
            }
            disabled={isExpired}
            expiresAt={expiresAt}
          />
        )}
      </div>
    )
  }
)

export default SnoozeButton
