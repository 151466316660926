import config from 'config'
import { load } from 'recaptcha-v3'
import { LOGIN_PAGE } from 'constants/pages'
import { selectResetPasswordToken } from 'selectors/account'
import { doLoadResetPasswordRequest as doLoadResetPasswordRequestAction } from 'actions/account'
import { doChangePasswordWithResetToken as doChangePasswordWithResetTokenAction } from 'ducks/agents/operations'

function getReCaptchaToken() {
  return load(config.google_recaptcha_site_key).then(recaptcha =>
    recaptcha.execute('passwordreset')
  )
}

export function doChangePasswordWithResetToken(params) {
  return async (dispatch, getState) => {
    const state = getState()
    const resetToken = selectResetPasswordToken(state)
    const recaptchaToken = await getReCaptchaToken()

    const response = await dispatch(
      doChangePasswordWithResetTokenAction({
        recaptchaToken,
        token: resetToken,
        password: params.passwordInput,
        passwordConfirm: params.passwordConfirmInput,
        authCode: params.twoFactorAuthenticationInput,
      })
    )

    const { errors } = response
    if (!errors.length) {
      dispatch({ type: LOGIN_PAGE })
    }

    return response
  }
}

export function doLoadResetPasswordRequest() {
  return (dispatch, getState) => {
    const state = getState()
    const resetToken = selectResetPasswordToken(state)
    return doLoadResetPasswordRequestAction(resetToken)(dispatch, getState)
  }
}
