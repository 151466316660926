export const INTEGRATION_METHOD_CREATE_NEW = 'CREATE_NEW'
export const INTEGRATION_METHOD_ADD_TO_EXISITING = 'ADD_TO_EXISITING'

export const INTEGRATION_FORM_INITIAL_LOADING =
  'INTEGRATION_FORM_INITIAL_LOADING'
export const INTEGRATION_FORM_INITIAL_READY = 'INTEGRATION_FORM_INITIAL_READY'

export const INTEGRATION_FORM_NEXT_STEP_DISABLED =
  'INTEGRATION_FORM_NEXT_STEP_DISABLED'
export const INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD =
  'INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD'
export const INTEGRATION_FORM_NEXT_STEP_LOADING =
  'INTEGRATION_FORM_NEXT_STEP_LOADING'
export const INTEGRATION_FORM_NEXT_STEP_READY =
  'INTEGRATION_FORM_NEXT_STEP_READY'
