import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { strftime } from 'util/date'

const TimelineEventMessage = React.memo(({ timelineEvent }) => {
  const { message, createdAt } = timelineEvent
  const time = useMemo(
    () => {
      return strftime('%H:%M %p', createdAt)
    },
    [createdAt]
  )

  const handleOnBodyNode = useCallback(
    node => {
      if (node) {
        const timeElement = window.document.createElement('SPAN')
        timeElement.className = 'shopifyV2-events-event-time'
        timeElement.innerHTML = time
        node.prepend(timeElement)
      }
    },
    [time]
  )

  return (
    <div className={cn('shopifyV2-events-event')}>
      <div className="shopifyV2-events-event-message">
        <SanitizedHTML
          html={message}
          className="shopifyV2-textClip"
          onBodyNode={handleOnBodyNode}
        />
      </div>
    </div>
  )
})

export default TimelineEventMessage
