import React, { useCallback, useContext } from 'react'
import { ORDER_DISPLAY_FINANCIAL_STATUS_TEXT } from 'ducks/integrations/shopify/constants'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import { timeInHuman } from 'util/date'
import { useShopifyOrder } from 'ducks/integrations/shopify/hooks'
import { capture } from 'ducks/tracking/actions'

const Order = React.memo(({ integrationId, orderId }) => {
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const { order, integration } = useShopifyOrder({
    integrationId,
    orderId,
  })
  const onClick = useCallback(
    () => {
      capture('Shopify Order Viewed')
      navigatableSetState({ orderId: order.id, integration })
    },
    [navigatableSetState, order, integration]
  )

  return (
    <NavigatableIntegrationWidgetCard.ListItem
      onClick={onClick}
      subTitle={
        <div className="shopifyV2-order-status">
          {timeInHuman(order.createdAt)} ·{' '}
          <span
            className={`shopifyV2-order-status-${order.displayFinancialStatus}`}
          >
            {ORDER_DISPLAY_FINANCIAL_STATUS_TEXT[order.displayFinancialStatus]}
          </span>
        </div>
      }
      title={
        order.isCancelled ? (
          <span className="shopifyV2-order-cancelled">{order.name}</span>
        ) : (
          order.name
        )
      }
    />
  )
})

export default Order
