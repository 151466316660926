import React from 'react'
import cn from 'classnames'
import { Flex } from 'shared/ui'
import ActionButton from './ActionButton'

const Actions = ({ className, onEdit, onDelete, ...rest }) => {
  return (
    <Flex className={cn('actions grui flex-1', className)} {...rest}>
      <ActionButton
        hoverColor="color.primary.brand"
        icon="mode-edit"
        onClick={onEdit}
        tooltip="Edit"
      />
      <ActionButton
        hoverColor="color.primary.negative"
        icon="delete"
        onClick={onDelete}
        className="grui pl-4"
        tooltip="Delete"
      />
    </Flex>
  )
}

export default Actions
