import { connect } from 'react-redux'

import { doCancelCreateLabel } from 'actions/labels'

import { selectInitialLabelProps } from 'selectors/modals_base'

import CreateLabel from './view'

const select = state => {
  return {
    initialValues: selectInitialLabelProps(state),
  }
}

const perform = {
  onCancel: doCancelCreateLabel,
}

export default connect(select, perform)(CreateLabel)
