import React from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import { Tooltip } from 'shared/ui'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { detect } from 'util/browser'

import styles from './styles.css'

const availableIcons = [
  'chrome',
  'edge',
  'firefox',
  'internet-explorer',
  'opera',
  'safari',
]

const iconName = (browser, platform) => {
  const {
    browser: { name: browserName },
  } = detect(`${browser} (${platform})`)
  return availableIcons.includes(browserName) ? browserName : 'unknown'
}

const TrackingDetails = ({ browser, platform, pageUrl, type }) => {
  if (type !== 'Widget') return null
  if (!pageUrl) return null
  let tooltip = null

  if (platform || browser) {
    tooltip = `${platform || ''} ${browser || ''}`
  }

  const icon = (
    <div
      className={cn(
        `Icon Icon-browser-${iconName(browser, platform)}`,
        styles.browserIcon
      )}
    />
  )
  return (
    <div className={styles.TrackingDetails}>
      {tooltip && (
        <Tooltip tooltip={tooltip} className={styles.tooltip} position="right">
          {icon}
        </Tooltip>
      )}
      {!tooltip && <React.Fragment>{icon}&nbsp;</React.Fragment>}
      <div className={styles.label}>
        Sent from your widget
        {pageUrl && (
          <React.Fragment>
            &nbsp;at&nbsp;
            <a
              className={styles.pageTitle}
              rel="noopener noreferrer"
              href={pageUrl}
              target="_blank"
            >
              {pageUrl}
            </a>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const select = state => {
  const ticket = selectCurrentTicket(state)

  const { browser, platform, pageUrl, type } = ticket

  return {
    type,
    browser,
    platform,
    pageUrl,
  }
}

export default connect(select)(TrackingDetails)
