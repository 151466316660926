import { connect } from 'react-redux'

import {
  doSearchLabels,
  doStartEditLabel,
  doStartDeleteLabel,
} from 'actions/labels'

import { selectIsFetchingLabelSearchResults } from 'selectors/app'
import {
  selectIsSearchingLabels,
  selectLabelSearchTerm,
  selectLabelsForManaging,
} from 'selectors/labelings'

import ManageLabels from './view'

const select = state => {
  return {
    isFetchingSearchResults: selectIsFetchingLabelSearchResults(state),
    isSearching: selectIsSearchingLabels(state),
    labels: selectLabelsForManaging(state),
    searchTerm: selectLabelSearchTerm(state),
  }
}

const perform = {
  onEdit: doStartEditLabel,
  onDelete: doStartDeleteLabel,
  onAdd: doStartEditLabel,
  onSearch: doSearchLabels,
}

export default connect(select, perform)(ManageLabels)
