import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const card = theme => css`
  background-color: ${theme.color.monochrome.white};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: ${rem(377)};
  box-shadow: 0 ${rem(4)} ${rem(15)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.15);
  border-radius: ${rem(12)};
  padding: ${rem(40)} ${rem(24)} ${rem(28)};
  text-align: center;

  & > img {
    max-width: 100%;
    display: block;
  }
`

const icon = theme => css`
  background-color: ${theme.color.monochrome.white};
  border-radius: 50%;
  width: ${rem(76)};
  height: ${rem(76)};
  box-shadow: 0 ${rem(2)} ${rem(4)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.15);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
  color: ${theme.color.info['900']};
  padding: ${rem(4)};

  &::before {
    content: '';
    display: block;
    background-color: ${theme.color.info['100']};
    position: absolute;
    top: ${rem(4)};
    bottom: ${rem(4)};
    left: ${rem(4)};
    right: ${rem(4)};
    border-radius: 50%;
  }

  svg {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }
`

const button = theme => css`
  font-family: ${theme.font.secondary};
  font-weight: ${theme.fontWeight.medium};
  min-width: ${rem(282)};
`

export const styles = {
  card,
  icon,
  button,
}
