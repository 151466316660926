import cn from 'classnames'
import { Icon, Popup } from 'util/ui'

import styles from './styles.less'

export default function Info({ children, className, corner, description }) {
  return (
    <Popup
      content={children || description}
      horizontalOffset={corner ? 10 : 8}
      inverted
      on="hover"
      position={corner ? 'top right' : 'top center'}
      trigger={
        <Icon
          className={cn(styles.InfoIcon, className, {
            [styles.corner]: corner,
          })}
          name="question"
          circular
          size="small"
        />
      }
      verticalOffset={corner ? -2 : -4}
    />
  )
}
