export const MESSAGE = 'Message'
export const SNOOZE_STATE = 'Snooze::State'
export const UNSNOOZE_STATE = 'Unsnooze::State'
export const STATE = 'State'
export const STAR = 'Star'
export const AGENT = 'Agent'
export const GROUP = 'Group'
export const AGENT_AND_GROUP = 'AgentAndGroup'
export const MAILBOX = 'Mailbox'
export const TITLE = 'Title'
export const DELETED = 'Deleted'
export const INTEGRATED_EXTERNAL = 'IntegrationExternal'
export const SHOPIFY_EXTERNAL_CHANGE = 'Shopify::ExternalChange'
export const END_USER = 'Enduser'
export const RATING = 'Rating'
export const FOLLOW = 'Follow'
export const LABEL = 'Label'
export const TICKET_CUSTOMER_ACTION_OPEN = 'Ticket::CustomerActionOpen'
export const TICKET_MERGER = 'Ticket::Merger'
export const TICKET_INTEGRATION = 'Ticket::Integration'
export const COLLAPSED_CHANGESET = 'CollapsedChangeset'
export const SEPARATOR = 'Separator'
export const COMMENT_DELETION = 'Comment::Deletion'
export const COMMENT_EDIT = 'Comment::Edit'
export const COMMENT_REACTION = 'Comment::Reaction'
