import styled from '@emotion/styled'
import { propFunc } from 'util/functions'

const Action = ({ name, onClick, ...rest }) => {
  return (
    <div
      id={name}
      className="action"
      onClick={propFunc(onClick, name)}
      {...rest}
    >
      {name}
    </div>
  )
}

export default styled(Action)`
  color: ${props => props.theme.color.monochrome.mediumDark};
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`
