import { useMemo } from 'react'

import ArrayOfStringsWithPrimary from 'shared/components/ui/Form/ArrayOfStringsWithPrimary'
import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'

const MAX_EMAIL_ADDRESSES = 6

export default function ContactHeaderFieldsEmails({ doRecalcMenuPosition }) {
  const email = useSelectCustomFieldValueForKeyAndType(
    'contact_email',
    'CONTACT'
  )
  const emails =
    useSelectCustomFieldValueForKeyAndType(
      'contact_secondary_emails',
      'CONTACT'
    ) || []
  const records = useMemo(() => [email, ...emails], [emails, email])
  return (
    <ArrayOfStringsWithPrimary
      maxRecords={MAX_EMAIL_ADDRESSES}
      noun={'email'}
      onCountChange={doRecalcMenuPosition}
      primaryName="contact_email"
      records={records}
      secondaryName="contact_secondary_emails"
      title="Emails"
    />
  )
}
