import { useCallback, useEffect, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doToggleLabel } from 'actions/labelings/shared/index'
import { doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { capture } from 'ducks/tracking/actions'
import { selectLabelsWithAppliedUpdates } from 'selectors/labelings'
import { selectLabelsByIds } from 'selectors/labels'
import { convertGidToRaw } from 'util/scatterSwap'
import { buildId } from 'util/globalId'

import Tag from './Tag'
import { styles as baseStyles } from '../styles'
import { styles } from './styles'

const tagsForLoadingState = [
  // Random text for showing different length of tags
  'billing',
  'sales',
  'upgrade',
  'premium',
  'whitelabel',
  'enterprise',
].map((value, index) => ({
  id: index + 1,
  name: value,
}))

const SuggestedTags = ({
  ticketId,
  suggestion,
  isLoading,
  isLoadingTagNames,
  isLoadedTagNames,
}) => {
  const dispatch = useDispatch()
  const ticketGid = buildId('Conversation', ticketId)
  const ticketLabels = useSelector(selectLabelsWithAppliedUpdates)
  const canShowLoadingState = isLoading || isLoadingTagNames

  useEffect(
    () => {
      if (
        !isLoading &&
        !isLoadingTagNames &&
        !isLoadedTagNames &&
        suggestion?.value?.length
      ) {
        dispatch(doFetchTagsV2ByIdsOrNames(suggestion.value))
      }
    },
    [dispatch, suggestion, isLoadingTagNames, isLoadedTagNames, isLoading]
  )

  const labelsFromStore = useSelector(state =>
    selectLabelsByIds(
      state,
      suggestion?.value?.map(tagGid => convertGidToRaw(tagGid).toString())
    )
  )

  const onClick = useCallback(
    tag => {
      capture('ai tag selected', {
        ticket_id: ticketGid,
      })
      dispatch(doToggleLabel(ticketId, { id: tag.id, name: tag.name }))
    },
    [dispatch, ticketId, ticketGid]
  )

  const tags = useMemo(
    () => {
      if (canShowLoadingState) {
        return tagsForLoadingState
      }

      if (!labelsFromStore) {
        return []
      }

      return labelsFromStore.filter(
        tag => !ticketLabels.some(ticketLabel => ticketLabel.id === tag.id)
      )
    },
    [labelsFromStore, ticketLabels, canShowLoadingState]
  )

  if (!suggestion?.id && !isLoading) {
    return null
  }

  return (
    <div
      className="grui flex flex-col mt-10"
      css={canShowLoadingState && baseStyles.loadingState}
    >
      <span css={baseStyles.actionTitle}>Suggested Tags</span>

      {tags.length ? (
        <div className="grui flex flex-row mt-10" css={styles.tagsList}>
          {tags.map(tag => (
            <Tag
              key={tag.id}
              tag={tag}
              onClick={onClick}
              isLoading={isLoading}
            />
          ))}
        </div>
      ) : (
        <span className="grui mt-10" css={styles.emptyText}>
          No tags
        </span>
      )}
    </div>
  )
}

export default memo(SuggestedTags)
