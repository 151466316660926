import React from 'react'
import cn from 'classnames'
import PinIcon from 'components/PinIcon'
import { Flex } from 'shared/ui'

import SortMenu from './SortMenu'
import styles from './styles.less'

const Narrow = ({
  canSeePinnedSearches,
  isSearchPinnable,
  isSelectionMode,
  onPinUnpinClick,
  pinnedSearch,
  SortMenuComponent = SortMenu,
}) => {
  return (
    <div
      className={cn(
        styles['conversation-list-header-wide'],
        'conversation-list-header-title-bar'
      )}
    >
      <div className={styles.top}>
        <div className={styles.topCenter}>
          <SortMenuComponent />
        </div>
        <Flex className={styles.topRight}>
          {canSeePinnedSearches &&
            isSearchPinnable && (
              <PinIcon
                size="small"
                enabled={!isSelectionMode}
                isPinned={!!pinnedSearch}
                onPinClick={onPinUnpinClick}
              />
            )}
        </Flex>
      </div>
    </div>
  )
}

export default Narrow
