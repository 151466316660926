import React, { useCallback } from 'react'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_CANCEL_ORDER } from 'ducks/drawers/types'
import { Button } from 'util/ui'
import { buildDrawerQueryParam } from 'ducks/drawers/util'

const CancelOrderButton = ({ integrationId, orderId, disabled }) => {
  const { openDrawer, drawerId } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_CANCEL_ORDER,
  })

  const onOpen = useCallback(
    () => {
      openDrawer(orderId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerIntegrationId',
            integrationId
          ),
        },
      })
    },
    [openDrawer, integrationId, orderId, drawerId]
  )

  return (
    <Button basic negative onClick={onOpen} disabled={disabled}>
      Cancel
    </Button>
  )
}

export default CancelOrderButton
