import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  ALL_REPOSITORIES,
  doFetchGitHubIssuesForRepository,
  doLinkGitHubIssueToTicket,
  selectGitHubIsLoading,
  selectGitHubRepositoryIssuesAsOptionsForRespository,
  selectGitHubRepositoriesAsOptionsForOwner,
  selectGitHubRepositoryOwnersAsOptions,
  selectGitHubAreRepositoryIssuesLoadingForRespository,
} from 'ducks/integrations/github'

import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'

import LinkExistingGitHubIssueModal from './view'

function mapStateToProps(state, props) {
  const {
    formFields: { repository, repositoryOwner },
  } = props
  return {
    areRepositoriesLoading: selectGitHubIsLoading(state, {
      loadingId: ALL_REPOSITORIES,
    }),
    areRepositoryIssuesLoading:
      repository &&
      selectGitHubAreRepositoryIssuesLoadingForRespository(state, {
        repositoryId: repository,
      }),
    issues:
      repository &&
      selectGitHubRepositoryIssuesAsOptionsForRespository(state, {
        repositoryId: repository,
      }),
    repositoryOwners: selectGitHubRepositoryOwnersAsOptions(state),
    repositories:
      repositoryOwner &&
      selectGitHubRepositoriesAsOptionsForOwner(state, {
        ownerId: repositoryOwner,
      }),
  }
}

const mapDispatchToProps = {
  doFetchGitHubIssuesForRepository,
  doLinkGitHubIssueToTicket,
}

export default compose(
  withOpenState,
  withProps({ initialValues: { repositoryOwner: null } }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkExistingGitHubIssueModal)
