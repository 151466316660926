import cn from 'classnames'
import { css } from '@emotion/react'
import { useSelector } from 'react-redux'

import {
  selectIsMxClientLoading,
  selectCurrentContactMatrixUserPresence,
} from 'ducks/chat/selectors/presence'
import { doGetUserPresence } from 'ducks/chat/actions/presence'
import { useActionEffect } from 'util/hooks'

const styles = () => theme => css`
  color: ${theme.color.monochrome.black};
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  position: absolute;
  top: 50px;
  left: 35px;

  &:before {
    content: '';
    display: inline-block;
    border-radius: 100%;
    background-color: #93a3af;
    height: 14px;
    width: 14px;
    margin: 0 10px 0 6px;
    border: 2px solid ${theme.color.monochrome.white};
  }
  &.offline:before {
    background-color: ${theme.color.primary.negative};
  }
  &.online:before {
    background-color: ${theme.color.primary.positive};
  }
  &.away:before {
    background-color: ${theme.color.primary.warning};
  }
`

export default function ChatStatus({ className }) {
  const isLoading = useSelector(selectIsMxClientLoading)
  const status = useSelector(selectCurrentContactMatrixUserPresence)
  useActionEffect(doGetUserPresence)
  if (isLoading || status === 'unknown') return null
  return <div css={styles} className={cn(status, className)} />
}
