import cn from 'classnames'
import React from 'react'
import styled from '@emotion/styled'
import { debounce } from 'util/functions'
import SearchComposer from './PlainTextEditor/Composer'
import styles from './styles.css'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearchBoxFocused: false,
      shouldShowDatePicker: false,
    }
  }

  componentDidMount() {
    const { doFetchTopLabels, topLabels } = this.props
    if (!topLabels) doFetchTopLabels(null)
  }

  updateSearchBoxFocusedState = isSearchBoxFocused => {
    this.setState({ isSearchBoxFocused })
  }

  doFetchLabels = currentPart => {
    if (!currentPart) return
    const { doSearchLabels, labelsByTerm } = this.props
    const { operator, value } = currentPart
    if (
      (operator === 'tag' || operator === 'keywords') &&
      !labelsByTerm[value]
    ) {
      doSearchLabels(value, { storeTerm: false })
    }
  }

  doFetchRecipients = currentPart => {
    const { doSearchRecipients, customersByEmail } = this.props
    if (!currentPart) return
    if (!customersByEmail) return
    const { operator, value } = currentPart
    if (!value) return
    if (
      (operator === 'from' || operator === 'keywords') &&
      !customersByEmail[value]
    ) {
      doSearchRecipients(value)
    }
  }

  debouncedFetchRecipients = debounce(this.doFetchRecipients, 500)

  // We don't need to handle click, left/right key and select events for the search box separately
  // they are all handled by the onChange event of the PlainTextEditor
  handleInputChange = (value, currentPart) => {
    const trimedValue = value?.trim()
    this.props.doUpdateTicketSearchQuery(trimedValue, {
      commit: true,
      submit: false,
      currentPart,
    })
    this.debouncedFetchRecipients(currentPart)
    this.doFetchLabels(currentPart)
  }

  toggleDatePicker = visible => {
    this.setState({ shouldShowDatePicker: visible })
  }

  handleSearchBoxFocused = () => {
    this.updateSearchBoxFocusedState(true)
    this.props.doToggleListSearchBoxFocused(true)
  }

  handleSearchBoxBlur = () => {
    this.toggleDatePicker(false)
    this.updateSearchBoxFocusedState(false)
    this.props.doToggleListSearchBoxFocused(false)
  }

  handleSubmit = (query, { shouldIncludePreviousSubmittedFilters } = {}) => {
    const {
      doSubmitTicketsSearch,
      currentSubmittedTicketSearchFilterMatches,
    } = this.props
    this.handleSearchBoxBlur()
    const newQuery = !shouldIncludePreviousSubmittedFilters
      ? query
      : [...currentSubmittedTicketSearchFilterMatches, query]
          .filter(Boolean)
          .join(' ')

    return doSubmitTicketsSearch(newQuery, {
      shouldIncludeSearchMailboxes: shouldIncludePreviousSubmittedFilters,
    })
  }

  isNoSuggestions = () => {
    const {
      suggestions,
      previousLabelSuggestionSize: prevLabelSize,
    } = this.props
    const allSuggestionsCount = Object.values(this.props.suggestions).reduce(
      (output, value) => {
        return output + value.length
      },
      0
    )
    return (
      allSuggestionsCount === 0 ||
      (prevLabelSize === 0 &&
        suggestions.label &&
        suggestions.label[0] &&
        suggestions.label[0].isLoading &&
        allSuggestionsCount === 1)
    )
  }

  // We want to stop the curtain click event
  // when someone clicks around the search input
  // so that we don't close search because of an accidental
  // click
  stopCurtainClick = ev => {
    ev.stopPropagation()
  }

  render() {
    const {
      className,
      committedSearchQuery,
      previousLabelSuggestionSize: prevLabelSize,
    } = this.props
    const { isSearchBoxFocused, shouldShowDatePicker } = this.state

    return (
      <div className={className}>
        <div className={styles.searchAndSuggestionsBox}>
          <div
            onMouseDown={this.stopCurtainClick}
            className={cn(styles.aboveCurtain, 'search-above-curtain')}
          >
            <SearchComposer
              onChange={this.handleInputChange}
              onToggleDatePicker={this.toggleDatePicker}
              isSearchBoxFocused={isSearchBoxFocused}
              committedSearchQuery={committedSearchQuery}
              prevLabelSize={prevLabelSize}
              onSearchBoxFocus={this.handleSearchBoxFocused}
              onSearchBoxBlur={this.handleSearchBoxBlur}
              onSubmit={this.handleSubmit}
              shouldShowDatePicker={shouldShowDatePicker}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default styled(Search)`
  position: relative;
`
