import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'

import { propFunc } from 'util/functions'
import { Flex, Button, Header, SubHeader } from 'shared/ui'
import SearchableList from './SearchableList'

const ManagePane = ({
  className,
  isOpen,
  labels,
  onCancel,
  onClose,
  onAdd,
  ...rest
}) => {
  return (
    <Flex className={cn('grui flex-col', className)}>
      <Header as="h1">Manage tags</Header>
      <SubHeader as="h3" size="small">
        Quickly &amp; easily manage tags on your account
      </SubHeader>
      <div className="grui mt-10">
        <SearchableList
          items={labels}
          labels={labels}
          searchPlaceholderText="Search..."
          onAdd={onAdd}
          {...rest}
        />
        <div className={'manage-actions'}>
          <Button primary onClick={propFunc(onAdd, { name: '' })} key="add">
            Create a new tag
          </Button>
        </div>
      </div>
    </Flex>
  )
}

const Styled = styled(ManagePane)`
  .manage-actions {
    padding: 24px 16px 0px;
    border-top: 1px solid var(--color-monochrome-medium);
  }
`

export default Styled
