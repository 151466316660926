import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const wrapper = theme => css`
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: ${theme.color.monochrome.white};
  box-shadow: 0 -10px 10px rgba(${theme.color.monochrome.black_rgba}, 0.1);
`

const textWrapper = css`
  max-width: ${rem(600)};
`

const textarea = css`
  width: 100%;
  max-width: ${rem(600)};
  margin: 0 auto;
`

const closeButton = css`
  top: ${rem(12)};
  right: ${rem(12)};
  position: absolute;
`

export const styles = {
  wrapper,
  textWrapper,
  textarea,
  closeButton,
}
