import { text } from '@groovehq/internal-design-system/lib/styles/elements'

const Warning = () => {
  return (
    <div
      css={[
        text.styles.text2Xs,
        text.styles.textMediumDark,
        text.styles.italic,
      ]}
      className="grui mt-8"
    >
      {`${app.t(
        'AI_draft'
      )} responses may contain false information. Always review the content before sending a reply.`}
    </div>
  )
}

export default Warning
