import cn from 'classnames'
import React, { Fragment } from 'react'
import { SVGIcon, Icon } from 'shared/ui'
import styles from './styles.less'

export default function TicketState({
  className,
  isSnoozed,
  isSpam,
  isDeleted,
  theme,
}) {
  const {
    color: {
      primary: { warning, negative },
    },
  } = theme
  return (
    <span className={cn(styles.ticketState, className)}>
      {isSnoozed && (
        <Fragment>
          <SVGIcon color={warning} name="snooze" size={19} />Snoozed
        </Fragment>
      )}
      {isSpam && (
        <Fragment>
          <Icon color={negative} name="error-outline" size={19} />Spam
        </Fragment>
      )}
      {isDeleted && (
        <Fragment>
          <Icon color={negative} name="delete" size={19} />Trash
        </Fragment>
      )}
    </span>
  )
}
