import React, { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import { shopifyMoneyString } from 'util/currency'
import {
  FORM_KEY_REFUND_AMOUNT,
  FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT,
} from './constants'

const Footer = ({
  control,
  isValid,
  sending,
  onClose,
  isLoading,
  isCalculating,
  orderCurrencyCode,
}) => {
  const userRefundAmount = useWatch({
    control,
    name: FORM_KEY_REFUND_AMOUNT,
  })

  const suggestedRefund = useWatch({
    control,
    name: FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT,
  })

  const { suggestedTransactions } = suggestedRefund || {}
  const suggestedRefundAmount =
    suggestedTransactions?.[0]?.amountSet?.shopMoney?.amount || 0

  const isRefundAmountDifferentToSuggested =
    parseFloat(userRefundAmount) !== parseFloat(suggestedRefundAmount)

  const saveBtnText = useMemo(
    () => {
      if (sending) return 'Refunding...'
      if (isCalculating) return 'Calculating refund...'
      return `Refund ${shopifyMoneyString({
        currencyCode:
          suggestedTransactions?.[0]?.amountSet?.shopMoney?.currencyCode ||
          orderCurrencyCode,
        amount: userRefundAmount,
      })}`
    },
    [
      isCalculating,
      suggestedTransactions,
      sending,
      orderCurrencyCode,
      userRefundAmount,
    ]
  )

  return (
    <div className="grui text-center">
      {isRefundAmountDifferentToSuggested && (
        <MessageCard type="warning" className="grui my-10 mx-12 inline-flex">
          The amount you’re refunding is different from the suggested refund
          total.
        </MessageCard>
      )}
      <ModalBtns
        saveBtnText={saveBtnText}
        tertiaryBtnText="Cancel"
        onClickTertiaryBtn={onClose}
        saveBtnDisabled={isCalculating || sending || isLoading || !isValid}
        saveBtnHtmlType="submit"
      />
    </div>
  )
}

export default Footer
