import { css } from '@emotion/react'

const arrow = theme => css`
  width: ${theme.spacing[11]};
  height: ${theme.spacing[11]};
  transform: translateY(1px);
  margin-left: 1px;
  transition: transform 0.2s;
`

const openedArrow = css`
  transform: translateY(1px) rotate(90deg);
`
export const styles = {
  arrow,
  openedArrow,
}
