import { connect } from 'react-redux'
import { selectTicketSearchTotalCountsByQueryId } from 'selectors/search'
import SearchRawCountView from './view'

const selectFactory = (_initialState, initialOwnProps) => {
  const { queryId } = initialOwnProps

  return (state, { count: inputCount }) => {
    if (inputCount !== undefined) {
      return {
        count: inputCount,
      }
    }

    const ticketCounts = selectTicketSearchTotalCountsByQueryId(state)
    const count = ticketCounts[queryId]

    return {
      count,
    }
  }
}

export default connect(selectFactory, null)(SearchRawCountView)
