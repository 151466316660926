import React, { useCallback } from 'react'
import cn from 'classnames'
import { emptyArr } from 'util/arrays'

import RemovableRecipient from './RemovableRecipient'
import Droppable from '../../Expanded/Droppable'
import Draggable from '../../Expanded/Draggable'
import { styles } from './styles'

const List = ({
  className,
  recipients = emptyArr,
  onRemove,
  syncing,
  children,
  type,
  allowMultipleRecipients,
  focus,
}) => {
  const handleOnRemove = useCallback(
    recipient => {
      focus()
      onRemove(recipient)
    },
    [onRemove, focus]
  )

  return (
    <div
      className={cn(
        'grui flex flex-auto recipient-list-container ml-4',
        className
      )}
      css={styles.list}
    >
      {recipients.map(recipient => {
        return (
          <Droppable
            recipient={recipient}
            type={type}
            key={recipient.id || recipient.email}
            disabled={!allowMultipleRecipients && recipients.length === 1}
          >
            <Draggable recipient={recipient} type={type}>
              <RemovableRecipient
                className="recipient"
                recipient={recipient}
                recipients={recipients}
                onClick={handleOnRemove}
                syncing={syncing}
                type={type}
              />
            </Draggable>
          </Droppable>
        )
      })}
      {children}
    </div>
  )
}

export default List
