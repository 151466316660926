import React from 'react'
import { useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectReportingPermitted } from 'ducks/currentUser/selectors/selectReportingPermitted'
import {
  SETTINGS_BILLING_PAGE,
  SETTINGS_INTEGRATIONS_PAGE,
} from 'subapps/settings/types'
import { LANDING_PAGE as KB_LANDING_PAGE } from 'subapps/kb/pages'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsAdminOrOwnerOrAgent,
} from 'ducks/currentUser/selectors'
import { doOpenMainPage } from 'actions/pages'
import { REPORTS_MAIN_PAGE } from 'ducks/reports/pages'
import { selectCurrentPage } from 'selectors/location'
import { selectFeatureUsage } from 'ducks/billing/selectors/features'
import { FEATURE_KB_MAX_KBS } from 'ducks/billing/featureTypes'

import Item from './Item'
import { styles } from './styles'

const toMainPage = doOpenMainPage()
const toReportsPage = { type: REPORTS_MAIN_PAGE }
const toKbLandingPage = { type: KB_LANDING_PAGE }
const toIntegrationsPage = { type: SETTINGS_INTEGRATIONS_PAGE }
export const toBillingPage = { type: SETTINGS_BILLING_PAGE }

const Nav = ({ className, active }) => {
  const showReporting = useSelector(selectReportingPermitted)
  const showIntegrations = useSelector(selectCurrentUserIsAdminOrOwnerOrAgent)
  const isFreemium = useSelector(selectIsFreemium)
  const currentUserIsAdminOrOwner = useSelector(selectCurrentUserIsAdminOrOwner)
  const showUpgrade = isFreemium && currentUserIsAdminOrOwner
  const currentPage = useSelector(selectCurrentPage)
  const { isFeatureEnabled: showKbSection } = useSelector(state =>
    selectFeatureUsage(state, FEATURE_KB_MAX_KBS)
  )

  return (
    <nav className={className}>
      <div
        css={[text.styles.fontMedium, styles.list]}
        className="grui flex items-center"
      >
        <Item
          to={toMainPage}
          css={styles.item}
          isActive={active === 'Conversations'}
        >
          Conversations
        </Item>
        {showReporting && (
          <Item
            to={toReportsPage}
            css={styles.item}
            isActive={active === 'Reporting'}
            dataTestId="Reporting"
          >
            Reports
          </Item>
        )}
        {showKbSection && (
          <Item
            to={toKbLandingPage}
            css={styles.item}
            isActive={active === 'Knowledge Base'}
          >
            Knowledge Base
          </Item>
        )}
        {showIntegrations && (
          <Item
            to={toIntegrationsPage}
            css={styles.item}
            isActive={currentPage === SETTINGS_INTEGRATIONS_PAGE}
          >
            Apps
          </Item>
        )}

        {showUpgrade && (
          <Item css={styles.upgrade} to={toBillingPage}>
            Upgrade your free plan
          </Item>
        )}
      </div>
    </nav>
  )
}

export default Nav
