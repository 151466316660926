import React from 'react'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

import CollectionRow from './CollectionRow'

export default function Collection({
  doNavigatableSetState,
  objects,
  parentId,
}) {
  return (
    <NavigatableIntegrationWidgetCard.List>
      {objects.map(object => {
        return (
          <CollectionRow
            doNavigatableSetState={doNavigatableSetState}
            key={`object-${object.id}`}
            object={object}
            parentId={parentId}
          />
        )
      })}
    </NavigatableIntegrationWidgetCard.List>
  )
}
