import Link from 'redux-first-router-link'

export default function ActionText({
  action: {
    change: {
      action: {
        action,
        assignee: { html_url: assingneeHtmlUrl, login: assigneeLogin } = {},
        changes: { title: oldTitle } = {},
        comment: { html_url: commentHtmlUrl } = {},
        event,
        label: { url: labelUrl, name: labelName } = {},
        milestone: { html_url: milestoneHtmlUrl, title: milestoneTitle } = {},
        sender: { login: senderLogin } = {},
      },
    },
  },
}) {
  if (event === 'issue_comment' && action === 'created') {
    return [
      'added ',
      <Link rel="noopener noreferrer" target="_blank" to={commentHtmlUrl}>
        a comment
      </Link>,
      ' to ',
    ]
  }
  switch (action) {
    case 'assigned':
      if (assigneeLogin === senderLogin) return 'assigned themselves to '
      return [
        'assigned ',
        <Link rel="noopener noreferrer" target="_blank" to={assingneeHtmlUrl}>
          {assigneeLogin === senderLogin ? 'themselves' : `@${assigneeLogin}`}
        </Link>,
        ' to ',
      ]
    case 'closed':
      return 'closed '
    case 'deleted':
      return 'deleted '
    case 'demilestoned':
      return [
        'removed the milestone ',
        <Link rel="noopener noreferrer" target="_blank" to={milestoneHtmlUrl}>
          {milestoneTitle}
        </Link>,
        ' from ',
      ]
    case 'edited':
      if (oldTitle) return 'changed the title of '
      return 'changed the body of '
    case 'labeled':
      return [
        'added the label ',
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={labelUrl.replace(
            'https://api.github.com/repos',
            'https://github.com'
          )}
        >
          {labelName}
        </Link>,
        ' to ',
      ]

    case 'locked':
      return 'locked '
    case 'milestoned':
      return [
        'added the milestone ',
        <Link rel="noopener noreferrer" target="_blank" to={milestoneHtmlUrl}>
          {milestoneTitle}
        </Link>,
        ' to ',
      ]
    case 'pinned':
      return 'pinned '
    case 'reopened':
      return 're-opened '
    case 'transferred':
      return 'transferred '
    case 'unassigned':
      if (assigneeLogin === senderLogin) return 'unassigned themselves from '
      return [
        'unassigned ',
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={assingneeHtmlUrl}
        >{`@${assigneeLogin}`}</Link>,
        ' from ',
      ]
    case 'unlabeled':
      return [
        'removed the label ',
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={labelUrl.replace(
            'https://api.github.com/repos',
            'https://github.com'
          )}
        >
          {labelName}
        </Link>,
        ' from ',
      ]
    case 'unlocked':
      return 'unlocked '
    case 'unpinned':
      return 'unpinned '
    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
