import cn from 'classnames'
import React from 'react'
import { string, bool, number } from 'prop-types'
import { motion } from 'framer-motion'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

// Default prop for delay isn't working for motion, so need to set the default value here
const Title = ({
  children,
  className,
  animated,
  delay = 0.875,
  iconHidden,
}) => {
  const base = (
    <h4
      css={[heading.styles.h4, styles.title]}
      className={cn('grui text-center px-15 pb-10', className)}
    >
      <span className="grui relative">
        {!iconHidden && <span css={styles.titleIcon}>✨&thinsp;&thinsp;</span>}
        {children}
      </span>
    </h4>
  )

  if (animated) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay }}
      >
        {base}
      </motion.div>
    )
  }
  return base
}

Title.propTypes = {
  className: string,
  animated: bool,
  delay: number,
}

Title.defaultProps = {
  className: undefined,
  animated: false,
  /**
   * How many seconds to delay the fade in animation
   */
  delay: 0.875,
}

export default Title
