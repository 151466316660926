import { connect } from 'react-redux'

import { selectAdminAgents } from 'selectors/agents/base'
import NoMailboxAccess from './view'

const select = state => ({
  admins: selectAdminAgents(state),
})

export default connect(select)(NoMailboxAccess)
