import { LINK, URL } from 'ducks/crm/customFields'

import CopyButton from './CopyButton'

const COPYABLE_FIELD_KEYS = [
  'contact_email',
  'contact_secondary_emails',
  'contact_phone_number',
  'contact_facebook',
  'contact_twitter',
  'contact_linkedin',
  'company_domain',
  'company_facebook',
  'company_twitter',
  'company_linkedin',
  'company_phone_number',
  'company_secondary_domains',
]
const COPYABLE_FIELD_TYPES = [LINK, URL]

export default function CustomFieldCopyButton({ fieldKey, type, value }) {
  const isCopyable =
    (COPYABLE_FIELD_KEYS.includes(fieldKey) ||
      COPYABLE_FIELD_TYPES.includes(type)) &&
    value
  if (!isCopyable) return null
  const text = getTextFromValue(fieldKey, type, value || {})
  return (
    <CopyButton
      message="The field value was copied to your clipboard."
      text={text}
    />
  )
}

function getTextFromValue(key, type, { content, link, raw }) {
  if (['contact_linkedin', 'company_linkedin'].includes(key)) {
    return `https://www.linkedin.com/in/${content}/`
  }
  if (['contact_twitter', 'company_twitter'].includes(key)) {
    return `https://www.twitter.com/${content}/`
  }
  if (['contact_facebook', 'company_facebook'].includes(key)) {
    return `https://www.facebook.com/${content}`
  }
  return link || raw || content
}
