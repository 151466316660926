import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import {
  doFetchGitHubIssues,
  selectCurrentTicketLinkedIssues,
  selectCurrentTicketLinkedIssueIdsOrUrls,
} from 'ducks/integrations/github'
import { Label, Loader } from 'shared/ui'
import { any } from 'util/arrays'

import LinkedGitHubIssuesRow from './LinkedGitHubIssuesRow'

export default function LinkedGitHubIssues({ doNavigatableSetState }) {
  const dispatch = useDispatch()
  const issues = useSelector(selectCurrentTicketLinkedIssues)
  const issueIds = useSelector(selectCurrentTicketLinkedIssueIdsOrUrls)
  const issueIdSerialized = JSON.stringify(issueIds)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(doFetchGitHubIssues(issueIds)), [
    dispatch,
    issueIdSerialized,
  ])

  if (!issues || issues.length === 0) return null
  const isLoaded = any(({ loaded }) => loaded, issues)
  return (
    <>
      <Label size="small">Linked Issues ({issues.length})</Label>
      <NavigatableIntegrationWidgetCard.List>
        {!isLoaded && <Loader />}
        {isLoaded &&
          issues.reverse().map(issue => {
            return (
              issue && (
                <LinkedGitHubIssuesRow
                  doNavigatableSetState={doNavigatableSetState}
                  issue={issue}
                  key={issue.id}
                />
              )
            )
          })}
      </NavigatableIntegrationWidgetCard.List>
    </>
  )
}
