import React from 'react'

// ScrollerAPI definition
// refresh: () => {},
// getScrollDimensions: () => {},
// scrollElementIntoView: () => {},
// scrollElementIntoViewIfNeeded: () => {},
// scrollToX: () => {},
// scrollToY: () => {},
// scrollToBottom: () => {},
// getElement: () => {},

const defaultContext = {
  getScrollerAPI: () => null,
}

export default React.createContext(defaultContext)
