import { connect } from 'react-redux'
import { selectLocationState } from 'redux-first-router'
import { doUpdateTitle } from 'actions/tickets/fields'
import { selectIsEditingTicketTitle, selectIsPrintModeOn } from 'selectors/app'
import {
  selectLastMessageId,
  selectMessageCount,
  selectCurrentTicketChangesets,
  selectCurrentTicketMessages,
} from 'selectors/currentChangesets'
import { doFetchChangesetTicketActions } from 'actions/tickets/doFetchChangesetTicketActions'
import { doOpen as onOpenImage } from 'actions/lightbox'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'

import Changesets from './view'

const select = state => ({
  currentUserId: selectCurrentUser(state).id,
  isEditingTicketTitle: selectIsEditingTicketTitle(state),
  lastMessageId: selectLastMessageId(state),
  messageCount: selectMessageCount(state),
  changesets: selectCurrentTicketChangesets(state),
  messages: selectCurrentTicketMessages(state),
  isPrintModeOn: selectIsPrintModeOn(state),
  is3ColumnView: selectPrefersClassicView(state),
  location: selectLocationState(state),
})

const perform = {
  doUpdateTitle,
  doFetchChangesetTicketActions,
  onOpenImage,
}

export default connect(select, perform)(Changesets)
