import { chooseIndefiniteArticle } from 'util/strings'
import { shopifyChangeText } from '../util/shopifyChangeText'

const getLinkText = provider => {
  if (provider === 'github') return 'GitHub issue'
  return 'Unknown integration'
}

const IntegrationAction = action => {
  const { change: { provider, removed, title, url } = {} } = action

  if (provider === 'hubspot') {
    return (
      <React.Fragment>
        <span className="action-verb">created</span> {title} as a{' '}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          contact
        </a>
      </React.Fragment>
    )
  }

  if (provider === 'salesforce') {
    return (
      <React.Fragment>
        <span className="action-verb">created</span> {title} as a{' '}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          lead
        </a>
      </React.Fragment>
    )
  }

  if (['github', 'jira_cloud', 'jira_server', 'trello'].includes(provider)) {
    return (
      <React.Fragment>
        {removed ? (
          <span>
            <span className="action-verb">unlinked</span> this conversation from{' '}
          </span>
        ) : (
          <span>
            <span className="action-verb">linked</span> this conversation to{' '}
          </span>
        )}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {title}
        </a>
      </React.Fragment>
    )
  }

  if (provider === 'recharge') {
    const [eventId, resource] = title.split('-')
    return (
      <React.Fragment>
        {resource === 'subscription' && (
          <span>
            <span className="action-verb">
              {removed ? 'cancelled' : 'reactivated'}
            </span>{' '}
            the subscription{' '}
          </span>
        )}
        {resource === 'order' && (
          <span>
            <span className="action-verb">skipped the next order</span> for the
            subscription{' '}
          </span>
        )}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          #{eventId}
        </a>
      </React.Fragment>
    )
  }

  if (provider === 'shopify_v2' && !!action?.change?.change_type) {
    // just for chat. ticket actions are rendered through:
    // components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/util
    const { change: { change_type: changeType } = {} } = action
    return (
      <span className="action-verb">
        {shopifyChangeText(changeType.toLowerCase())}&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {title}
        </a>
      </span>
    )
  }

  const linkText = getLinkText(provider)
  const direction = removed ? (
    <span>
      <span className="action-verb">removed</span> this from
    </span>
  ) : (
    <span>
      <span className="action-verb">added</span> this to
    </span>
  )
  const base = `${direction} ${chooseIndefiniteArticle(linkText)} `

  return (
    <React.Fragment>
      {base}
      <a target="_blank" rel="noopener noreferrer" href={url}>
        {linkText}
      </a>
    </React.Fragment>
  )
}

export default IntegrationAction
