import { LOGOUT_PAGE } from 'constants/pages'
import { to2faPage } from 'subapps/settings/actions/redirect'
import {
  SETTINGS_BILLING_PAGE,
  SETTINGS_REDIRECT_INTEGRATION_PAGE,
} from 'subapps/settings/types'

export const ALLOWED_EXPIRED_BILLING = [
  SETTINGS_REDIRECT_INTEGRATION_PAGE,
  LOGOUT_PAGE,
  SETTINGS_BILLING_PAGE,
  { ...to2faPage },
]

export const PLAN_DOWNGRADABLE_DIFF = 72 * 60 * 60 * 1000 // 72 hours in milliseconds
