import React from 'react'
import Scroller from 'components/Scroller'
import { withResizeChange } from 'util/hoc'
import List from './List'

const ScrollingList = props => {
  return (
    <Scroller style={{ maxHeight: '400px' }}>
      <List {...props} />
    </Scroller>
  )
}

export default withResizeChange(ScrollingList)
