import { useSelector } from 'react-redux'

import {
  DURATION,
  NUMBER,
  SOURCE_AND_MEDIUM,
  URL,
} from 'ducks/crm/customFields'

import { selectAreCustomFieldsLoading } from '../../selectors'
import CustomFieldNestedSet from '../../CustomFieldNestedSet'

const FIELD_PROPS = {
  current: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: URL,
    icon: 'page',
    nestedKey: 'lastPageView.url',
    title: 'Current page',
  },

  previous: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: URL,
    icon: 'page',
    nestedKey: 'penultimatePageView.url',
    title: 'Previous page',
  },

  entrance: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: URL,
    icon: 'page',
    nestedKey: 'firstPageView.url',
    title: 'Entrance page',
  },

  source: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: SOURCE_AND_MEDIUM,
    nestedKey: 'firstPageView.referrer',
    title: 'Source/Medium',
  },

  pages_viewed: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: NUMBER,
    icon: 'copy',
    nestedKey: 'totalPageViews',
    title: 'Pages viewed',
  },

  session_duration: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: DURATION,
    nestedKey: 'totalTimeOnPage',
    title: 'Session duration',
  },
}

export default function ChatInsights({ doHideCard }) {
  const isLoading = useSelector(selectAreCustomFieldsLoading)

  return (
    <CustomFieldNestedSet
      collection="current_session"
      doHideCard={doHideCard}
      isLoading={isLoading}
      fields={FIELD_PROPS}
      title="Chat Insights"
    />
  )
}
