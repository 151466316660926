import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import Options from 'components/SearchModifier/Options'
import { Flex } from 'shared/ui'
import styles from './styles.less'

const FilterBar = ({ className, shouldShowOptions, isWide }) => {
  return (
    <Flex
      className={cn(
        'px-12',
        styles['filter-bar'],
        isWide && styles['filter-bar-wide'],
        className
      )}
    >
      <div className="filters">{shouldShowOptions && <Options />}</div>
    </Flex>
  )
}

FilterBar.propTypes = {
  shouldShowOptions: PropTypes.bool.isRequired,
  isWide: PropTypes.bool,
}

FilterBar.defaultProps = {
  shouldShowOptions: false,
  isWide: false,
}

FilterBar.displayName = 'FilterBar'

export default FilterBar
