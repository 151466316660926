import React from 'react'
import NoticeBar from 'components/NoticeBar'

const TwoFactorAuthDisabled = () => {
  return (
    <NoticeBar key="2fa-disabled-notice">
      {`An account administrator has required that all ${app.t(
        'agents'
      )} enable Two-Factor Authentication.`}
    </NoticeBar>
  )
}

export default TwoFactorAuthDisabled
