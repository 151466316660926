import { memo } from 'react'
import cn from 'classnames'

import SentimentIcon from '@groovehq/internal-design-system/lib/assets/icons/Sentiment'
import { styles } from './styles'

const sentiments = ['negative', 'neutral', 'positive']

const Sentiment = ({ suggestion, isLoading, className }) => {
  if (!suggestion?.id && !isLoading) {
    return null
  }

  const sentimentType = sentiments[+suggestion?.value + 1]

  return (
    <div
      className={cn('grui text-center px-12', className)}
      css={isLoading ? [styles.loadingSentiment] : styles[sentimentType]}
    >
      {!isLoading && (
        <>
          <SentimentIcon
            sentiment={+suggestion.value}
            active
            className="grui mr-5 align-middle"
            width="16"
            height="16"
          />
          <span className="grui align-middle">
            {sentimentType.charAt(0).toUpperCase() + sentimentType.slice(1)}{' '}
            sentiment
          </span>
        </>
      )}
    </div>
  )
}

export default memo(Sentiment)
