import React, { useCallback } from 'react'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function LinkedGitHubIssuesRow({
  doNavigatableSetState,
  issue,
  issue: {
    loaded,
    repository: { nameWithOwner: repoName } = {},
    state,
    title,
  } = {},
}) {
  const onClick = useCallback(
    () => {
      doNavigatableSetState(issue.id)
    },
    [doNavigatableSetState, issue]
  )
  if (issue && loaded)
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        onClick={onClick}
        subTitle={`${repoName} · ${state === 'OPEN' ? 'Open' : 'Closed'}`}
        title={title}
      />
    )
  return null
}
