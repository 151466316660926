import { connect } from 'react-redux'
import { emptyObj } from 'util/objects'
import { selectRatingsByMessageId } from 'selectors/ratings'

import RatingsView from './view'

const select = (_, initialProps) => state => {
  const { grade, feedback, actor: { labelFull } = {} } =
    selectRatingsByMessageId(state)[initialProps.messageChangeId] || emptyObj

  return {
    grade,
    feedback,
    actorLabel: labelFull,
  }
}

export default connect(select)(RatingsView)
