import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const btnLogin = theme => css`
  border-radius: ${rem(200)};
  width: 100%;
  font-weight: ${theme.fontWeight.semibold};
`

const title = theme => css`
  font-size: ${theme.fontSize.x6large};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.lineHeight.leadingTight};
  color: ${theme.color.info[900]};
`

const textDividerWrapper = css`
  && > * {
    margin: 0;
  }
`

const line = theme => css`
  border-top: 1px solid rgba(${theme.color.info.black_rgba}, 0.1);
`

const googleLoginBtn = theme => css`
  & svg + span {
    min-width: ${rem(161)};
    text-align: start;

    @media only screen and (max-width: ${theme.breakpoints.tabletBreakpoint}) {
      min-width: ${rem(141)};
    }
  }
`

export const styles = {
  btnLogin,
  title,
  textDividerWrapper,
  line,
  googleLoginBtn,
}
