import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'redux-first-router-link'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { warning as Warning } from 'assets/icons/groove/v2'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import {
  TO_SETTINGS_BILLING_DRAFT_CREDITS_PAGE,
  TO_SETTINGS_BILLING_PAGE,
} from 'ducks/billing/constants'
import { pluralize } from 'util/strings'
import { useAiDraftGenerationAction } from 'ducks/ai/hooks'
import { ADMIN_EMAIL_NOTIFICATION_TYPES } from 'ducks/ai/types'
import { doAgentSendAdminEmailNotification } from 'ducks/agents/operations'
import { styles } from './styles'

const UsageCounter = () => {
  const isCurrentUserManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const {
    wallet: { canUse, balance, id: walletId },
    isPaidAccount,
    canUseFeatureByFeatureLimit,
  } = useAiDraftGenerationAction()

  const dispatch = useDispatch()

  const limitReached = !canUse
  const freeHint = canUseFeatureByFeatureLimit
    ? app.t('AI_draft')
    : `free ${app.t('AI_draft')}`

  const ActionElement = isCurrentUserManager ? Link : 'button'

  const [disabled, setDisabled] = useState(false)
  const handleRequestUpgrade = useCallback(
    async () => {
      setDisabled(true)
      await dispatch(
        doAgentSendAdminEmailNotification(
          ADMIN_EMAIL_NOTIFICATION_TYPES.aiDraft
        )
      )
      setDisabled(false)
    },
    [dispatch]
  )

  const to = useMemo(
    () => {
      if (!isCurrentUserManager) {
        return undefined
      }
      if (canUseFeatureByFeatureLimit && isPaidAccount) {
        return TO_SETTINGS_BILLING_DRAFT_CREDITS_PAGE
      }
      return TO_SETTINGS_BILLING_PAGE
    },
    [isCurrentUserManager, canUseFeatureByFeatureLimit, isPaidAccount]
  )

  const buttonName = useMemo(
    () => {
      if (!isCurrentUserManager) {
        return `Request access to more ${app.t('AI_draft')} credits`
      }
      if (canUseFeatureByFeatureLimit && isPaidAccount) {
        return `Manage credits`
      }
      return `Upgrade plan`
    },
    [isCurrentUserManager, canUseFeatureByFeatureLimit, isPaidAccount]
  )

  if (!walletId || balance > 5) {
    return null
  }

  return (
    <div
      css={[
        styles.usageCounter,
        text.styles.fontMedium,
        limitReached && styles.limitReached,
      ]}
      className="grui p-5 m-5 flex-center"
    >
      {limitReached ? (
        <Warning css={styles.negativeIcon} />
      ) : (
        <Warning css={styles.warningIcon} />
      )}
      <span className="grui ml-5">
        {limitReached
          ? `${app.t('AI_drafts')} limit reached`
          : `${balance} ${freeHint} ${pluralize(balance, 'credit')} left`}
      </span>
      <ActionElement
        className="grui mx-5"
        css={button.styles.link}
        onClick={isCurrentUserManager ? undefined : handleRequestUpgrade}
        disabled={disabled}
        to={to}
      >
        {buttonName}
      </ActionElement>
    </div>
  )
}

export default UsageCounter
