import { connect } from 'react-redux'
import { doSelectAllTickets } from 'actions/app'
import {
  doDeleteSelectedTickets,
  doMarkSelectedTicketsAsSpam,
  doMarkSelectedTicketsAsUnread,
  doUnmarkBulkSelectionMode,
  doChangeSelectedTicketsMailbox,
} from 'actions/tickets'
import { selectShowExperimentalLabelUi } from 'selectors/app/base'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { selectCurrentTicketSearchResultTicketIds } from 'selectors/search'
import { getLength } from 'util/arrays'
import DetailPane from './view'

const select = state => ({
  shownTicketCount: getLength(selectCurrentTicketSearchResultTicketIds(state)),
  ticketIds: selectSelectedTicketIds(state),
  isExperimentalLabelUiEnabled: selectShowExperimentalLabelUi(state),
})

const perform = {
  onCancel: doUnmarkBulkSelectionMode,
  onDeleteClick: doDeleteSelectedTickets,
  onMarkAsSpamClick: doMarkSelectedTicketsAsSpam,
  onMarkAsUnreadClick: doMarkSelectedTicketsAsUnread,
  onSelectAllShown: doSelectAllTickets,
  onChangeMailboxClick: doChangeSelectedTicketsMailbox,
}

export default connect(select, perform)(DetailPane)
