/* eslint-disable jsx-a11y/label-has-for */
import { css } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { propFunc } from 'util/functions'
import {
  Button,
  ValidatedInput,
  Flex,
  Divider,
  Label,
  Icon,
  IntegrationError,
  Loader,
} from 'shared/ui'
import { Form } from 'util/ui'
import { join, email, required } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'

const StyledContainer = styled('div')`
  background-color: white;
  padding-top: ${props => props.theme.spacing['10']};
  display: flex;
  flex-direction: column;
`

const StyledError = styled(IntegrationError)`
  margin: ${props => props.theme.spacing['12']} 0;
`

class CreatingNewState extends React.Component {
  handleChange = (name, validate, e) => {
    const { onChange } = this.props
    onChange(name, e.target.value, validate)
  }

  makeValidatedFormField = ({ as, name, label, placeholder, validate }) => {
    const { formFields, formFieldsErrors } = this.props
    return (
      <Form.Field
        css={css`
          padding: 0 24px;
          margin-bottom: 16px !important;
        `}
      >
        <label>{label}</label>
        <ValidatedInput
          as={as}
          size="small"
          placeholder={placeholder}
          defaultValue={formFields[name]}
          errorMessage={formFieldsErrors[name]}
          onChange={propFunc(this.handleChange, name, validate)}
        />
      </Form.Field>
    )
  }

  handleCancel = () => {
    const { resetForm, closeForm } = this.props
    resetForm()
    closeForm()
  }

  handleSubmit = () => {
    const { submitForm } = this.props
    submitForm()
  }

  handleEnter = e => {
    const key = e.charCode || e.keyCode
    if (key === 13) e.preventDefault()
  }

  takeSubmitRef = node => {
    this.submitRef = node
  }

  takeResetRef = node => {
    this.resetRef = node
  }

  render() {
    const {
      formFieldErrors,
      closeForm,
      saving,
      isCreatingError,
      openCreating,
    } = this.props
    return (
      <StyledContainer>
        <Flex className="grui items-center">
          <Icon
            name="arrow-back"
            onClick={propFunc(closeForm)}
            className="grui ml-12"
            size="medium"
          />
          <Label as="div" size="large" className="grui ml-4">
            Create user
          </Label>
        </Flex>

        {saving && <Loader className="grui my-20" />}

        {isCreatingError && <StyledError onRetry={openCreating} />}
        {!isCreatingError &&
          !saving && (
            <Form
              onKeyPress={this.handleEnter}
              css={css`
                margin-top: 24px;
              `}
            >
              {this.makeValidatedFormField({
                label: 'Full name',
                name: 'name',
                iconName: 'account-circle',
                placeholder: 'John Doe',
                validate: required,
              })}

              {this.makeValidatedFormField({
                label: 'Email address',
                name: 'email',
                placeholder: 'someone@example.com',
                validate: join([email, required]),
              })}

              <Divider className="grui mt-4" />
              <Flex className="grui p-10 justify-end">
                <Button
                  size="small"
                  className="grui ml-10"
                  primary
                  onClick={this.handleSubmit}
                  disabled={saving || formFieldErrors}
                  ref={this.takeSubmitRef}
                >
                  Create user
                </Button>
                <Button
                  size="small"
                  basic
                  onClick={this.handleCancel}
                  disabled={saving}
                  className="grui ml-10"
                  ref={this.takeResetRef}
                >
                  Cancel
                </Button>
              </Flex>
            </Form>
          )}
      </StyledContainer>
    )
  }
}

export default withFormFields(CreatingNewState)
