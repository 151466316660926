import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'

import { CannedReplyDropdown } from 'ducks/cannedReplies/components'
import ToggleKbSearch from 'ducks/ticketing/components/ToggleKbSearch'

import NavLink from './NavLink'

const NavBar = ({ className, commenting, replying, ticketId }) => {
  return (
    <div className={cn(className, 'grui flex', { commenting })}>
      {replying && (
        <CannedReplyDropdown
          ticketId={ticketId}
          isReply={replying}
          isNote={commenting}
          className="grui mr-11"
        />
      )}
      <ToggleKbSearch />
    </div>
  )
}

export default styled(NavBar)`
  min-height: 24px;
  &.commenting {
    background-color: ${props => props.theme.color.secondary.warning};
  }

  ${NavLink} + ${NavLink} {
    margin-left: 20px;
  }
`
