const AVAILABLE_TRANSLATIONS = {
  action_changed_description_of_card: 'descriptionChanged',
  action_renamed_card: 'titleChanged',
  action_archived_card: 'cardArchived',
  action_sent_card_to_board: 'cardUnarchived',
  action_move_card_from_board: 'boardChanged',
  action_move_card_from_list_to_list: 'listChanged',
  action_member_joined_card: 'membersChanged',
  action_member_left_card: 'membersChanged',
  action_added_member_to_card: 'membersChanged',
  action_removed_member_from_card: 'membersChanged',
  action_add_label_to_card: 'labelsChanged',
  action_remove_label_from_card: 'labelsChanged',
  action_added_a_due_date: 'dueDateChanged',
  action_changed_a_due_date: 'dueDateChanged',
  action_removed_a_due_date: 'dueDateChanged',
  action_comment_on_card: 'commentAdded',
  action_archived_list: 'listArchived',
  action_sent_list_to_board: 'listUnarchived',
  action_closed_board: 'boardArchived',
  action_reopened_board: 'boardUnarchived',
  action_delete_card: 'cardDeleted',
}

export default function shouldShowAction(
  { change: { action: { display: { translationKey } = {} } = {} } },
  settings
) {
  const setting = `showAction.${AVAILABLE_TRANSLATIONS[translationKey]}`
  return (
    Object.keys(AVAILABLE_TRANSLATIONS).includes(translationKey) &&
    settings &&
    settings[setting]
  )
}
