import { css } from '@emotion/react'

const rawCss = theme => `
  * {
    max-width: 100%;
  }

  && h1,
  && h2,
  && h3,
  && h4 {
    color: ${theme.color.monochrome.black};
  }

  && h1 {
    margin-top: 0;
    font-family: ${theme.font.secondary};
    font-size: 20px;
    line-height: 36px;
  }

  && h2,
  && h3,
  && h4,
  && h5,
  && h6 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: ${theme.font.primary};
  }

  && h2,
  && h3,
  && h4 {
    line-height: 16px;
  }

  && h2,
  && h3 {
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  && h4,
  && h5,
  && h6 {
    font-weight: ${theme.fontWeight.normal};
  }

  && h2 {
    font-size: 12px;
  }

  && h3 {
    font-size: 10px;
  }

  && h4 {
    font-size: 13px;
    line-height: 24px;
  }

  && h5,
  && h6 {
    font-size: 13px;
    line-height: 18px;
    color: ${theme.color.monochrome.dark};
  }

  && img {
    vertical-align: middle;
  }

  && p {
    margin-bottom: 4px;
  }

  && a + a:before {
    content: '|';
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
  }

  && ul,
  ol {
    padding-left: 0;
    list-style-position: inside;
  }

  && ul ul,
  && ul ol,
  && ol ul,
  && ol ol {
    padding-left: 8px;
  }

  && hr {
    margin: 24px 0;
    border: 0;
    border-top: 1px solid ${theme.color.monochrome.medium};
  }

  && th {
    font-size: 10px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
    color: ${theme.color.monochrome.black};
  }

  && th,
  && td {
    padding: 0 6px;
  }
  && th:first-of-type,
  && td:first-of-type {
    padding-left: 0;
  }
  && th:last-child,
  && td:last-child {
    padding-right: 0;
  }

  && label {
    display: block;
    margin-bottom: 8px;
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${theme.color.monochrome.black};
  }

  && textarea,
  && input[type='text'],
  && input[type='email'],
  && input[type='password'],
  && input[type='search'],
  && input[type='url'] {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0.84615385em 1.23076923em;
    font-family: ${theme.font.primary};
    font-size: 1em;
    line-height: 1.30769231em;
    background: ${theme.color.monochrome.white};
    border: 1px solid ${theme.color.monochrome.medium};
    border-radius: 4px;
    outline: 0;
    appearance: none;

    &:focus {
      color: ${theme.color.monochrome.black};
      border-color: ${theme.color.primary.brand};
    }
  }

  && button,
  && input[type='button'],
  && input[type='submit'],
  && input[type='reset'] {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0.41em 1em 0.59em;
    font-family: ${theme.font.primary};
    border: 0;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    appearance: none;
  }

  && button,
  && input[type='submit'] {
    color: ${theme.color.monochrome.white};
    background-color: ${theme.color.primary.brand};

    &:hover {
      background-color: ${theme.color.buttonPrimary.backgroundHover};
    }
  }

  && input[type='button'] {
    color: ${theme.color.primary.brand};
    background-color: ${theme.color.monochrome.white};
    box-shadow: inset 0 0 0 1px ${theme.color.primary.brand};
  }

  && input[type='reset'] {
    color: ${theme.color.monochrome.black};
    box-shadow: inset 0 0 0 1px ${theme.color.monochrome.medium};

    &:hover {
      box-shadow: inset 0 0 0 1px ${theme.color.monochrome.mediumDark};
    }
  }
`

export const rawCssIframe = theme => {
  // remove && used by emotion. As this will be displayed in iframe, it won't be compiled
  return rawCss(theme).replace(/&& /g, '')
}

export const containerStyles = () => theme => css(rawCss(theme))
