import React from 'react'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import NewLabel from '@groovehq/internal-design-system/lib/components/NewLabel/NewLabel'
import { styles } from './styles'

const Item = ({
  className,
  isActive,
  children,
  to,
  isNew,
  dataTestId,
  onClick,
}) => {
  return (
    <div
      css={styles.item}
      className={cn(className, {
        active: isActive,
      })}
    >
      <Link to={to} data-test-id={dataTestId} onClick={onClick}>
        {<span className="grui align-middle">{children}</span>}
        {isNew && (
          <NewLabel
            className="grui ml-5 mt-1 align-middle"
            isInfiniteAnimation
            size="small"
          />
        )}
      </Link>
    </div>
  )
}

export default Item
