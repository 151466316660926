import React, { Fragment, useRef } from 'react'

import { ENTER, TAB } from 'constants/keys'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'

import { styles } from './styles'
import AiOptions from './AiOptions'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }

const DropdownMenu = ({ onSelect, list }) => {
  const currentUser = useSelector(selectCurrentUser)

  const searchRef = useRef()
  const containerRef = useRef()
  const menuRef = useRef()

  if (!currentUser.id) return null

  return (
    <div css={styles.container} className="grui pb-5" ref={containerRef}>
      {list.map(
        section =>
          section && (
            <Fragment key={section.name}>
              <div css={styles.menuHeader}>{section.name}</div>
              <KeyboardNavigator
                keys={KEYBOARD_NAVIGATOR_KEYS}
                focusElement={searchRef.current}
                css={styles.wrapper}
                // We want to control it to allow more forgiving mouse leave
                activateOnHover={false}
              >
                <div className="grui" ref={menuRef}>
                  {section.items.map(item => (
                    <AiOptions key={item.id} item={item} onSelect={onSelect} />
                  ))}
                </div>
              </KeyboardNavigator>
            </Fragment>
          )
      )}
    </div>
  )
}

export default React.memo(DropdownMenu)
