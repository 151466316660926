import React from 'react'

import NoCustomer from './NoCustomer'
import DetailsContainer from './DetailsContainer'

const ShopifyIndex = React.memo(props => {
  const { customer } = props

  const { id: customerGid } = customer

  return !customerGid ? (
    <div>
      <NoCustomer />
    </div>
  ) : (
    <DetailsContainer {...props} customerGid={customerGid} />
  )
})

ShopifyIndex.displayName = 'ShopifyIndex'

export default ShopifyIndex
