import styled from '@emotion/styled'
import { Checkbox } from 'shared/ui'

export default styled(Checkbox)`
  &&& {
    label {
      width: 85%;
    }
  }
`
