import { css } from '@emotion/react'

const paymentIcon = theme => css`
  width: ${theme.spacing['12']};
  height: ${theme.spacing['10']};
`

export const styles = {
  paymentIcon,
}
