import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'

import { doStartEditing } from 'ducks/inAppCards/customer'
import { doToggleRightSidebar } from 'actions/app'
import { selectIsRightSidebarCollapsed } from 'selectors/app'
import { selectCurrentCustomerId } from 'selectors/tickets/customer/selectCurrentCustomerId'
import { propFunc } from 'util/functions'
import { selectIsContactDetailsColumnLoading } from 'components/crm/ContactDetailsColumn/selectors'
import styles from './styles.css'

const NoCustomerEmail = () => {
  const dispatch = useDispatch()
  const customerId = useSelector(selectCurrentCustomerId)
  const isLoading = useSelector(selectIsContactDetailsColumnLoading)
  const isRightSidebarCollapsed = useSelector(selectIsRightSidebarCollapsed)

  const handleClick = useCallback(
    () => {
      if (isRightSidebarCollapsed) {
        dispatch(doToggleRightSidebar())
        // Right side bar needs 300 to show
        // then the form can show in the correct position
        setTimeout(() => dispatch(doStartEditing()), 300)
      } else {
        dispatch(doStartEditing())
      }
    },
    [dispatch, isRightSidebarCollapsed]
  )

  return (
    <div className={styles.warning}>
      <button
        onClick={propFunc(handleClick, customerId)}
        css={button.styles.preflight}
        disabled={isLoading}
      >
        <b>Invalid customer email:</b>&nbsp;
        <span>to reply, please click here to update their email address</span>
      </button>
    </div>
  )
}

export default NoCustomerEmail
