import React from 'react'
import cn from 'classnames'

import ListenToKeyboard from 'components/ListenToKeyboard'

import { ENTER } from 'constants/keys'
import { isFunction, runOnNextTick } from 'util/functions'

import Item from './Item'
import KeyboardNavContext from './Context'
import { createFocusComponent } from './Focus'
import styles from './styles.css'

const registeredKeyboardNavigators = []
const IGNORE_EVENTS_FROM = ['INPUT', 'TEXTAREA']
const LISTEN_TO_KEYBOARD_ENTER_DEFAULT = [ENTER]

export default class KeyboardNavigator extends React.Component {
  static Item = Item
  static Focus = createFocusComponent(registeredKeyboardNavigators)
  static registeredKeyboardNavigators = registeredKeyboardNavigators

  constructor(props) {
    super(props)
    this.state = {}
    this.contextStore = {
      registeredItems: [],
      registerItem: this.registerItem.bind(this),
      unregisterItem: this.unregisterItem.bind(this),
      setActiveItem: this.setActiveItem,
      activateOnHover: props.activateOnHover !== false,
    }
    this.handleUp = this.handleUp.bind(this)
    this.handleDown = this.handleDown.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.getIsEnabled = this.getIsEnabled.bind(this)
  }

  componentWillMount() {
    this.mounted = true
    registeredKeyboardNavigators.unshift(this)
  }

  componentDidMount() {
    this.onNextTick(this.updateRegisteredItems)
  }

  componentWillReceiveProps(props) {
    const { activateOnHover = true } = props
    if (this.contextStore.activateOnHover !== activateOnHover) {
      this.contextStore.activateOnHover = activateOnHover
    }
    if (this.props.activeKey !== props.activeKey) {
      this.setActiveItem(null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.activeIndex !== this.state.activeIndex ||
      prevState.activeIndexEvent !== this.state.activeIndexEvent
    ) {
      this.onNextTick(this.updateRegisteredItems)
      const activeItem = this.getActiveItem()
      // Not sure why I need to defer this but can revert props to an earlier state
      // If these callbacks trigger a rerender. Suspect a bug in react-lite
      // need to try removing and testing on search suggestions when React
      // upgrade is complete.
      this.onNextTick(() => {
        if (activeItem) activeItem.handleActivate(this.state.activeIndexEvent)
        const { onChange } = this.props
        if (onChange)
          onChange(this.state.activeIndex, this.state.activeIndexEvent)
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    const index = registeredKeyboardNavigators.indexOf(this)
    if (index > -1) registeredKeyboardNavigators.splice(index, 1)
  }

  onNextTick = func =>
    runOnNextTick(() => {
      if (this.mounted !== false) {
        func()
      }
    })

  getDefaultActiveIndex() {
    const items = this.contextStore.registeredItems
    const index = items.findIndex(item => item.props.active)
    const firstItem = items[0]
    const defaultIndex =
      firstItem && firstItem.props.active !== false ? 0 : null // Check the first item isn't explicitly not active
    return index > -1 ? index : defaultIndex
  }

  getTotalSize() {
    return this.contextStore.registeredItems.length
  }

  getLastIndex() {
    return this.getTotalSize() - 1
  }

  getItemAtIndex(index) {
    return this.contextStore.registeredItems[index]
  }

  getActiveItem() {
    return this.contextStore.registeredItems[this.getActiveIndex()]
  }

  getActiveOnHandlelect() {
    const activeItem = this.getActiveItem()
    return (activeItem && activeItem.handleSelect) || null
  }

  setActiveItem = (index, event, force = false) => {
    if (!this.getIsEnabled(force)) return
    const { activeIndex } = this.state

    // Kevin R (2023-10-10)
    // Running set state causes the component to re-render. When you hover over
    // the items in a keyboard list we are getting tons of events which is causing
    // tons of re-renders. I dont really understand why we need to store the
    // activeIndexEvent, but for the recipient editor we dont need it so adding
    // this flag which can ignore duplicate events which significantly improves
    // performance
    const { supressDuplicateActivate = false } = this.props
    if (supressDuplicateActivate === false || activeIndex !== index) {
      this.setState({
        activeIndex: index,
        activeIndexEvent: event,
      })
    }
  }

  getActiveIndex() {
    const { activeIndex } = this.state
    if (activeIndex || activeIndex === 0) return activeIndex
    return this.getDefaultActiveIndex()
  }

  /**
   * Determine if the navigator is enabled
   *
   * Its `enabled` prop must not be false and (
   *   (It must be the last navigator mounted and no other navigators have focus) or
   *   (It must have focus)
   * )
   * All condition asside from the `enabled` prop can be overrided by passing
   * `true` as the first argument.
   */
  getIsEnabled(force = false) {
    const { enabled: propsEnabled = true } = this.props
    const focusedNavigator = registeredKeyboardNavigators.find(navigator => {
      const isFocusElementActive =
        navigator.props.focusElement === document.activeElement
      const isChildFocused =
        navigator.element && navigator.element.contains(document.activeElement)
      return isFocusElementActive || isChildFocused
    })
    const lastMountedEnabled = registeredKeyboardNavigators[0] === this
    const focusEnabled = focusedNavigator === this
    return (
      propsEnabled &&
      ((lastMountedEnabled && !focusedNavigator) || focusEnabled || force)
    )
  }

  registerItem = item => {
    const { activeIndex } = this.state
    this.contextStore.registeredItems.push(item)
    const itemIndex = this.contextStore.registeredItems.indexOf(item)
    // We queue the a single update so that multiple items can register in the
    // same tick and only trigger a single update.
    if (!this.queuedUpdate) {
      this.queuedUpdate = this.onNextTick(this.updateRegisteredItems)
    }
    // Wait for element to be in the DOM and then see if we need to increment
    // the active index based on new items preceeding it.
    this.onNextTick(() => {
      if (
        this.getActiveItem() &&
        item.element &&
        this.getActiveItem().element &&
        // eslint-disable-next-line no-bitwise
        item.element.compareDocumentPosition(this.getActiveItem().element) &
          Node.DOCUMENT_POSITION_FOLLOWING
      ) {
        this.setActiveItem(activeIndex + 1)
      }
    })
    return itemIndex
  }

  unregisterItem = item => {
    const itemIndex = this.contextStore.registeredItems.indexOf(item)
    if (itemIndex > -1) this.contextStore.registeredItems.splice(itemIndex, 1)
    const { activeIndex } = this.state
    if (itemIndex < activeIndex) this.setActiveItem(activeIndex - 1)
    if (!this.queuedUpdate) {
      this.queuedUpdate = this.onNextTick(() => {
        this.updateRegisteredItems()
      })
    }
  }

  handleUp = (event, force = false) => {
    const activeIndex = this.getActiveIndex()
    if (activeIndex === null) {
      this.setActiveItem(0, event)
    } else if (activeIndex >= 0) {
      const { loop = true } = this.props
      const isAtTop = activeIndex === 0
      const newIndexLooped = isAtTop ? this.getLastIndex() : activeIndex - 1
      const newIndexUnlooped = isAtTop ? activeIndex : activeIndex - 1
      const newIndex = loop ? newIndexLooped : newIndexUnlooped
      this.setActiveItem(newIndex, event, force)
    }
  }

  forceHandleUp = event => {
    this.handleUp(event, true)
  }

  handleDown = (event, force = false) => {
    const activeIndex = this.getActiveIndex()
    if (activeIndex === null) {
      this.setActiveItem(0, event)
    } else if (activeIndex <= this.getLastIndex()) {
      const { loop = true } = this.props
      const isAtBottom = activeIndex === this.getLastIndex()
      const newIndexLooped = isAtBottom ? 0 : activeIndex + 1
      const newIndexUnlooped = isAtBottom ? activeIndex : activeIndex + 1
      const newIndex = loop ? newIndexLooped : newIndexUnlooped
      this.setActiveItem(newIndex, event, force)
    }
  }

  forceHandleDown = event => {
    this.handleDown(event, true)
  }

  handleSelect(e) {
    e.preventDefault()
    const activeIndex = this.getActiveIndex()
    if (activeIndex >= 0) {
      const activeItem = this.getActiveItem()
      const itemHandleSelect = activeItem && activeItem.handleSelect
      if (itemHandleSelect) itemHandleSelect(activeIndex, e)
    }
    if (this.props.onSelect) this.props.onSelect(activeIndex, e)
    if (!this.props.preventClearActiveOnSelect) {
      this.setActiveItem(null, e)
    }
  }

  updateRegisteredItems = () => {
    Object.assign(this.contextStore, {
      registeredItems: this.contextStore.registeredItems.sort((a, b) => {
        if (a.element === b.element) return 0
        if (
          // eslint-disable-next-line no-bitwise
          a.element.compareDocumentPosition(b.element) &
          Node.DOCUMENT_POSITION_PRECEDING
        ) {
          // b comes before a
          return 1
        }
        return -1
      }),
      lastIndex: this.getLastIndex(),
      totalSize: this.getTotalSize(),
    })
    const activeIndex = this.getActiveIndex()
    if (isFunction(this.props.onActiveIndexChange)) {
      this.props.onActiveIndexChange(activeIndex)
    }
    this.contextStore.activeIndex = activeIndex // Needs to be done after the sort
    // Add it back to make hovering an KeyboardNavContext.Item work (force the item to rerender to show active state):
    // the contextStore won't get the this.state.activeIndex on the state change,
    // we only change the contextStore.activeIndex (see the above code) here after componentDidUpdate, so miss the chance to rerender the context's consumers
    this.contextStore.registeredItems.forEach(item => item.pleaseUpdate())

    this.queuedUpdate = false
  }

  keyEnabled = event => {
    const { target } = event
    if (!target) return true
    if (
      target === this.props.focusElement ||
      (this.element && this.element.contains(document.activeElement))
    )
      return true
    return (
      IGNORE_EVENTS_FROM.indexOf(target.nodeName) < 0 &&
      target.contentEditable !== 'true'
    )
  }

  takeRef = element => {
    this.element = element
  }

  render() {
    const {
      keys,
      onSelect,
      enabled,
      activateOnHover,
      className,
      focusElement,
      activeKey,
      supressDuplicateActivate,
      onActiveIndexChange,
      preventClearActiveOnSelect,
      ...rest
    } = this.props
    return (
      <KeyboardNavContext.Provider value={this.contextStore}>
        <div
          {...rest}
          className={cn(styles.KeyboardNavigator, className)}
          ref={this.takeRef}
        >
          <ListenToKeyboard
            onUp={this.handleUp}
            onDown={this.handleDown}
            enabled={this.keyEnabled}
            preventDefault
          />
          <ListenToKeyboard
            keys={(keys && keys.select) || LISTEN_TO_KEYBOARD_ENTER_DEFAULT}
            onKeyDown={this.handleSelect}
            enabled={this.keyEnabled}
          />
          {keys &&
            keys.forceUp && (
              <ListenToKeyboard
                keys={keys.forceUp}
                onKeyDown={this.forceHandleUp}
                enabled={this.keyEnabled}
              />
            )}
          {keys &&
            keys.forceDown && (
              <ListenToKeyboard
                keys={keys.forceDown}
                onKeyDown={this.forceHandleDown}
                enabled={this.keyEnabled}
              />
            )}
          {this.props.children}
        </div>
      </KeyboardNavContext.Provider>
    )
  }
}
