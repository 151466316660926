import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { useShopifyTimeline } from 'ducks/integrations/shopify/hooks'
import { capture } from 'ducks/tracking/actions'
import TimelineEventsForDay from './TimelineEventsForDay'
import ShowMoreEventsLink from './ShowMoreEventsLink'

const TimelineEvents = React.memo(({ customerId, integrationId }) => {
  const [showMoreEvents, setShowMoreEvents] = useState(false)

  const { timelineEventsByDay } = useShopifyTimeline({
    customerId,
    integrationId,
  })

  const onShowMoreEventsClick = useCallback(() => {
    setShowMoreEvents(true)
  }, [])

  useEffect(() => {
    capture('Shopify Timeline Viewed')
  }, [])

  if (!timelineEventsByDay) return <Fragment />

  const eventGroups = []
  timelineEventsByDay.forEach((events, day) => {
    eventGroups.push(
      <TimelineEventsForDay
        day={day}
        key={events[0]?.id}
        customerId={customerId}
        integrationId={integrationId}
      />
    )
  })

  return (
    <div className="grui p-7 shopifyV2-events">
      {eventGroups.slice(0, 4).map(eventGroup => eventGroup)}
      {eventGroups.length > 4 && (
        <>
          {!showMoreEvents && (
            <div className="shopifyV2-events-show-more">
              <button
                className="grui"
                css={button.styles.link}
                onClick={onShowMoreEventsClick}
              >
                + Show more events
              </button>
            </div>
          )}
          {showMoreEvents && (
            <>
              {eventGroups.slice(4).map(eventGroup => eventGroup)}
              <div className="shopifyV2-events-more">
                <ShowMoreEventsLink
                  integrationId={integrationId}
                  customerId={customerId}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
})

export default TimelineEvents
