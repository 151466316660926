import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { all } from 'util/arrays'

import CustomFieldEditForm from './CustomFieldEditForm'
import CustomFieldEditValue from './CustomFieldEditValue'
import WidgetCardModal from '../WidgetCardModal'

const cssClassName = css`
  overflow: visible;
`
export default function CustomFieldEditModal({
  className,
  fieldKey,
  icon: Icon,
  index,
  isArray,
  menuRef,
  offset,
  onCancel,
  onSave,
  options,
  title,
  type,
  value,
  values,
}) {
  const isEmpty = useMemo(
    () => {
      return !value || all(eachValue => !eachValue, Object.values(value))
    },
    [value]
  )
  return (
    <WidgetCardModal
      css={cssClassName}
      className={className}
      contentRef={menuRef}
      noCurtain={false}
      onBackgroundClick={onCancel}
      offset={offset}
    >
      <div className="title">
        {Icon && <Icon className="icon" />}
        <div className="titleValue">{title}</div>
      </div>
      <CustomFieldEditForm
        deletable={!isEmpty}
        index={index}
        isArray={isArray}
        onCancel={onCancel}
        onSave={onSave}
        values={values}
      >
        {isArray ? (
          values?.map((valuesValue, i) => {
            if (i === index) {
              return (
                <CustomFieldEditValue
                  fieldKey={`${fieldKey}[${i}]`}
                  key={fieldKey}
                  title={title}
                  type={type}
                  options={options}
                  value={valuesValue}
                />
              )
            }
            return (
              <>
                {Object.keys(valuesValue).map(hiddenKey => {
                  return (
                    <input
                      type="hidden"
                      key={hiddenKey}
                      name={`${fieldKey}[${i}].${hiddenKey}`}
                      value={valuesValue[hiddenKey]}
                    />
                  )
                })}
              </>
            )
          })
        ) : (
          <CustomFieldEditValue
            fieldKey={fieldKey}
            isArray={isArray}
            title={title}
            type={type}
            options={options}
            value={value}
            values={values}
          />
        )}
      </CustomFieldEditForm>
    </WidgetCardModal>
  )
}
