import { connect } from 'react-redux'
import { selectDuckPageComponent, selectDuckPageProps } from 'ducks/selectors'
import View from './view'

const select = state => {
  const {
    page: { componentName, props, subapp },
  } = state
  return {
    componentName,
    DucksPageComponent: selectDuckPageComponent(state),
    ducksPageProps: selectDuckPageProps(state),
    props,
    subapp,
  }
}

export default connect(select)(View)
