import React, { useState, useMemo, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Link from 'redux-first-router-link'
import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { MySettings } from '@groovehq/internal-design-system/lib/assets/icons'
import Tabs from '@groovehq/internal-design-system/lib/components/Tabs/Tabs'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { SETTINGS_NOTIFICATIONS_PAGE } from 'subapps/settings/types'
import Scroller from 'components/Scroller'
import { Loader } from 'shared/ui'
import { ENTER, TAB } from 'constants/keys'
import { propFunc } from 'util/functions'
import ListenToKeyboard from 'components/ListenToKeyboard'
import {
  NOTIFICATION_EVENT_TYPES,
  EVENT_TYPE_FOLLOWING,
  EVENT_TYPE_MENTION,
  EVENT_TYPE_RATING,
  EVENT_TYPE_REACTION,
} from 'ducks/notifications/v2/constants'
import { useNotifications } from 'ducks/notifications/v2/hooks'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { doOpenTicketPage } from 'actions/pages'
import { getEventRelatedConversationMeta } from 'ducks/notifications/v2/utils'
import Notification from './Notification'
import { styles } from './styles'
import { NotificationRoomContext } from './contexts'
import EmptyCategoryNotification from './EmptyCategoryNotification'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }

const tabsData = {
  all: {
    key: 'all',
    tabName: 'All',
    filters: NOTIFICATION_EVENT_TYPES,
  },
  mentions: {
    key: 'mentions',
    tabName: '@Mentions',
    filters: [EVENT_TYPE_MENTION],
  },
  following: {
    key: 'following',
    tabName: 'Following',
    filters: [EVENT_TYPE_FOLLOWING],
  },
  ratings: {
    key: 'ratings',
    tabName: 'Ratings',
    filters: [EVENT_TYPE_RATING],
  },
  reaction: {
    key: 'reaction',
    tabName: 'Reactions',
    filters: [EVENT_TYPE_REACTION],
  },
}

const Menu = ({ onClickNotification }) => {
  const dispatch = useDispatch()
  const { room } = useContext(NotificationRoomContext)
  const [activeTabKey, setActiveTabKey] = useState('all')

  const activeTab = useMemo(() => tabsData[activeTabKey], [activeTabKey])

  const {
    isReady,
    hasNotifications,
    eventIds,
    markAllEventsRead,
    markEventReadUpTo,
    isLoading,
    loadMore,
    hasMore,
    allUnreadCount,
    calculateUnreadCountForEventTypes,
  } = useNotifications(activeTab.filters)

  const showEmptyNotifications = isReady && !hasNotifications && !isLoading

  const tabs = useMemo(
    () => {
      return Object.values(tabsData).map(item => {
        let count = 0

        if (item.key === 'all') {
          count = allUnreadCount
        } else {
          count = calculateUnreadCountForEventTypes(item.filters)
        }

        const hasUnread = count > 0

        return {
          ...item,
          tabName: (
            <span className="grui relative">
              {item.tabName}{' '}
              {hasUnread && (
                <Dot
                  color="color.primary.negativeAlt"
                  size="small"
                  className="grui absolute"
                />
              )}
            </span>
          ),
        }
      })
    },
    [allUnreadCount, calculateUnreadCountForEventTypes]
  )

  const handleClickNotification = useCallback(
    eventId => {
      const event = room.findEventById(eventId)

      if (event) {
        markEventReadUpTo(event)
        // Handle navigation
        const { ticketId, changesetId } = getEventRelatedConversationMeta(event)

        if (isDefined(ticketId)) {
          dispatch(doOpenTicketPage(ticketId, { changesetId }))
        }
      }

      onClickNotification()
    },
    [dispatch, room, onClickNotification, markEventReadUpTo]
  )

  return (
    <ListenToKeyboard hijack={ListenToKeyboard.DROPDOWN}>
      <div>
        <KeyboardNavigator keys={KEYBOARD_NAVIGATOR_KEYS} activeKey={null}>
          <div css={styles.menu}>
            <div css={styles.header} className="grui pt-5 pl-10 pr-7">
              <div className="grui flex justify-between mb-4">
                <span css={[styles.heading, text.styles.textNormal]}>
                  Notifications{' '}
                  <Link
                    css={[
                      button.styles.preflight,
                      button.styles.link,
                      styles.btnSetting,
                    ]}
                    className="grui opacity-70 px-4 mt-1"
                    to={{ type: SETTINGS_NOTIFICATIONS_PAGE }}
                  >
                    <MySettings />
                  </Link>
                </span>
                <Button
                  type="icon"
                  css={[button.styles.link, styles.btnSeenAll]}
                  onClick={markAllEventsRead}
                  className="grui px-7"
                  disabled={allUnreadCount === 0}
                >
                  Mark all as seen
                </Button>
              </div>
              <Tabs tabs={tabs} onChange={setActiveTabKey} css={styles.tabs} />
            </div>
            <div>
              <>
                {!showEmptyNotifications && (
                  <Scroller
                    css={styles.list}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loadingMore={isLoading}
                    loader={<Loader className="grui mt-7" />}
                    endIndicator={<div css={styles.endIndicator} />}
                  >
                    {eventIds.map(eventId => (
                      <KeyboardNavigator.Item
                        key={eventId}
                        onSelect={propFunc(handleClickNotification, eventId)}
                      >
                        <Notification eventId={eventId} />
                      </KeyboardNavigator.Item>
                    ))}
                  </Scroller>
                )}
                {showEmptyNotifications && <EmptyCategoryNotification />}
              </>
            </div>
          </div>
        </KeyboardNavigator>
      </div>
    </ListenToKeyboard>
  )
}

export default Menu
