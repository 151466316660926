import { css } from '@emotion/react'

const summary = css`
  li:last-of-type {
    font-weight: 500;
  }
`

const isCalculating = css`
  opacity: 0.5;
`

const moneyString = css`
  font-variant-numeric: tabular-nums;
`

export const styles = { summary, isCalculating, moneyString }
