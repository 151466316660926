import { Label } from 'shared/components/ui/Typography'
import styled from '@emotion/styled'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { suggestionList } from 'util/search/constants'

const Hint = styled('span')`
  color: ${props => props.theme.color.monochrome.mediumDark};
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Suggestion = styled('div')`
  display: flex;
  margin-bottom: ${props => props.theme.spacing['3']};
  :last-child {
    margin-bottom: 0px;
  }
`

const Container = styled(SpacedItems.Container)`
  padding: ${({ theme: { spacing } }) =>
    `${spacing['10']} ${spacing['12']} ${spacing['0']} ${spacing['12']}`};
`

export default function InvalidSearchMessage({ className }) {
  return (
    <Container gap="tiny" direction="vertical" className={className}>
      <SpacedItems.Item>
        <Label size="large" color="berry">
          Invalid search
        </Label>
      </SpacedItems.Item>
      <SpacedItems.Item>
        {
          "You have used a search operator that doesn't exist. Try one of these instead..."
        }
      </SpacedItems.Item>
      <SpacedItems.Item>
        {suggestionList.map(({ suggestion, hint }) => (
          <Suggestion key={suggestion}>
            <span css={text.styles.fontMedium}>{suggestion}</span>
            <Hint>{hint}</Hint>
          </Suggestion>
        ))}
      </SpacedItems.Item>
    </Container>
  )
}
