import React, { useCallback } from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const PagingButtons = React.memo(
  ({
    openNextConversation,
    openPreviousConversation,
    totalConversations,
    currentConversationIndex,
  }) => {
    // Indexes are 0 based, but position displayed to customers starts from 1
    const currentConversationPosition = currentConversationIndex + 1
    const hasConversationCount = totalConversations > 0
    const isLast =
      currentConversationPosition === totalConversations && hasConversationCount
    const isFirst = currentConversationPosition === 1 && hasConversationCount

    const handlePreviousClick = useCallback(
      () => {
        if (!isFirst && openPreviousConversation) openPreviousConversation()
      },
      [openPreviousConversation, isFirst]
    )

    const handleNextClick = useCallback(
      () => {
        if (!isLast && openNextConversation) openNextConversation()
      },
      [openNextConversation, isLast]
    )

    return (
      <div className="paging">
        {hasConversationCount &&
          currentConversationPosition > 0 && (
            <div className="progress">
              {currentConversationPosition} of {totalConversations}
            </div>
          )}
        <div className="button previous">
          <IconButton
            name="angleLeftB"
            size="medium"
            className={cn('menu-button', { disabled: isFirst })}
            onClick={handlePreviousClick}
            tooltip="Previous [k]"
            svg
          />
        </div>
        <div className="button next">
          <IconButton
            name="angleRightB"
            size="medium"
            className={cn('menu-button', { disabled: isLast })}
            onClick={handleNextClick}
            tooltip="Next [j]"
            svg
          />
        </div>
      </div>
    )
  }
)

export default PagingButtons
