import { css } from '@emotion/react'

const ordersFooter = theme => css`
  padding: 5px;
  padding-right: 10px;
  font-size: ${theme.fontSize.tiny};
  font-weight: 500;
  text-align: right;
  min-height: 11px;
  color: ${theme.color.monochrome.mediumDark} !important;

  & > a {
    color: ${theme.color.monochrome.mediumDark} !important;
    cursor: pointer;

    &:hover {
      color: ${theme.color.primary.brand} !important;
    }
  }
`

export const styles = {
  ordersFooter,
}
