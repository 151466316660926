import { css } from '@emotion/react'

const truncateStyle = css`
  overflow: hidden;
  white-space: nowrap;
`

const subjectStyle = theme => css`
  color: ${theme.color.monochrome.superDark};
  font-family: ${theme.pageFont};
  font-weight: ${theme.fontWeight.medium};
`

const largerSubjectStyle = theme => css`
  font-size: ${theme.fontSize.x4large};

  @media print {
    overflow: visible;
    white-space: normal;
  }
`
const smallerSubjectStyle = theme => css`
  font-size: ${theme.fontSize.x2large};
`
const printableTicketNumberStyle = theme => css`
  font-size: ${theme.fontSize.x2large};
`

export const styles = {
  truncateStyle,
  subjectStyle,
  largerSubjectStyle,
  smallerSubjectStyle,
  printableTicketNumberStyle,
}
