import styled from '@emotion/styled'
import cn from 'classnames'
import { Button } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

import useFileInputBindings from './useFileInputBindings'

export default styled(AvatarEditField)`
  align-items: center;
  min-height: 80px;

  .DisplayedAvatar.ui.avatar.image {
    float: left;
    width: 80px;
    height: 80px;
    background: ${props => props.theme.color.monochrome.medium};
    /* border-radius: ${themeVars.defaultBorderRadius}; */

    &.contact {
      border-radius: 100%;
    }
  }
  &&& {
    .button {
      margin-top: 5px;
      margin-left: ${props => props.theme.spacing['5']};
    }
  }
`

function AvatarEditField({
  as,
  className,
  contact = true,
  fallback,
  Fallback,
  name,
  url: existingUrl,
  ...rest
}) {
  const {
    doRemove,
    doOpen,
    fileInputRef,
    openTriggerRef,
    onFileChange,
    url,
    remove,
  } = useFileInputBindings(existingUrl)
  const As = url && !as ? 'img' : as || 'div'
  return (
    <div className={cn(className, 'AvatarEditField')}>
      {url && (
        <As
          className={cn('DisplayedAvatar', { contact })}
          src={url}
          onClick={doOpen}
          {...rest}
        />
      )}
      {(!url || remove) && Fallback ? (
        <Fallback className={cn('DisplayedAvatar', { contact })} />
      ) : null}
      {(!url || remove) && fallback ? fallback : null}
      {
        <Button
          as="div"
          basic
          onClick={doOpen}
          innerRef={openTriggerRef}
          size="small"
        >
          Upload photo
        </Button>
      }
      {url && (
        <Button as="div" basic onClick={doRemove} size="small">
          Remove photo
        </Button>
      )}
      <input
        type="hidden"
        className="grui hidden"
        name={`${name}[remove]`}
        value={remove}
      />
      <input
        type="file"
        className="grui hidden"
        name={name}
        ref={fileInputRef}
        onChange={onFileChange}
      />
    </div>
  )
}
