import { css } from '@emotion/react'

import { Avatar } from 'shared/ui'

const siteAvatarClass = () => theme => css`
  float: left;
  margin-right: ${theme.spacing['6']};
`

export default function JiraCloudOptionAvatar(props) {
  return (
    <Avatar
      css={siteAvatarClass}
      className={props.className}
      size={props.size || 24}
      {...props}
    />
  )
}
