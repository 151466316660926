import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import CollapsableCard from 'components/CollapsableCard'
import ReorderableAndHideableComponents from 'components/ReorderableAndHideableComponents'
import { selectCurrentContactIdOrTemp } from 'ducks/crm/contacts'

import SortableVisibleControlsWithTitle from '../SortableVisibleControlsWithTitle'
import CustomFieldNested from './CustomFieldNested'
import { selectAreCustomFieldsLoading } from './selectors'

export default function CustomFieldNestedSet({
  children,
  collection,
  doHideCard,
  isLoadingProp,
  fields,
  title,
}) {
  const defaultOrder = useMemo(
    () => Object.keys(fields).map(key => ({ key, visible: true })),
    [fields]
  )
  const subjectId = useSelector(selectCurrentContactIdOrTemp)
  const isLoading = useSelector(selectAreCustomFieldsLoading)
  const itemProps = useCallback(
    key => {
      return { ...fields[key], subjectId }
    },
    [fields, subjectId]
  )
  if (!subjectId) return null
  return (
    <CollapsableCard
      isLoading={isLoading || isLoadingProp}
      persistenceKey={`customWidget.${collection}`}
      title={
        <SortableVisibleControlsWithTitle title={title} onHide={doHideCard} />
      }
    >
      {children}
      <ReorderableAndHideableComponents
        className="SortableCustomFieldsContainer"
        collection={collection}
        initialOrder={defaultOrder}
        itemComponent={CustomFieldNested}
        itemProps={itemProps}
        itemNoun="field"
        namespace="contact_details_column"
      />
    </CollapsableCard>
  )
}
