import React from 'react'
import { useSelector } from 'react-redux'
import { selectHumanizedSearchQueryArray } from 'selectors/search/searchFilters'

const SearchItem = ({ query }) => {
  const queryArray = useSelector(state =>
    selectHumanizedSearchQueryArray(state, query)
  )
  return (
    <span className="grui -ml-2 -mr-2">
      {queryArray.map(part => {
        return part.match(/:/) ? (
          <span className="grui search-filter mx-2" key={part}>
            {part}
          </span>
        ) : (
          <span className="grui mx-2" key={part}>
            {part}
          </span>
        )
      })}
    </span>
  )
}

export default SearchItem
