import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import config from 'config'
import Announcement, {
  animations,
} from '@groovehq/internal-design-system/lib/components/Announcement/Announcement'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from '@groovehq/internal-design-system/lib/components/Announcement/Announcement.styles'
import { HIDE_FEATURES_ANNOUNCEMENT } from 'ducks/flags/flagNames'
import { doSetFlag } from 'ducks/flags/operations'
import useFrillWidget from 'util/hooks/useFrillWidget'
import { styles as layoutStyles } from './styles'

const NewFeaturesAnnouncement = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(true)
  const { toggle: handleShowAnnoucements } = useFrillWidget(
    config.frillWidgets.announcements.key
  )

  const handleCloseCard = useCallback(
    () => {
      setShow(false)
      dispatch(doSetFlag(HIDE_FEATURES_ANNOUNCEMENT))
    },
    [dispatch]
  )

  const handleLearnMore = useCallback(
    () => {
      handleShowAnnoucements()
      handleCloseCard()
    },
    [handleShowAnnoucements, handleCloseCard]
  )

  const footer = useMemo(
    () => (
      <div className="grui pl-11 pb-12">
        <Button
          type="info"
          size="xBig"
          css={styles.btn}
          className="grui mr-7"
          onClick={handleLearnMore}
        >
          Learn more
        </Button>
        <Button
          type="tertiary"
          size="xBig"
          css={[styles.btn, styles.btnTertiary]}
          onClick={handleCloseCard}
        >
          Dismiss
        </Button>
      </div>
    ),
    [handleCloseCard, handleLearnMore]
  )

  return (
    <div css={layoutStyles.announcementContainer}>
      <Announcement.AnimatePresenceMotion
        open={show}
        initialAnimation={animations.initial}
        endAnimation={animations.end}
      >
        <Announcement
          onClose={handleCloseCard}
          tag="Feature Release"
          title={`New with Groove: 2FA, AI Instant replies, Templates for Rules, editing and deleting notes, advanced time-based rules - live in your ${app.t(
            'mailbox'
          )}!`}
          copy={`We’re excited to release a couple new features! Check the announcements section for more details 🎉`}
          footer={footer}
        />
      </Announcement.AnimatePresenceMotion>
    </div>
  )
}

export default NewFeaturesAnnouncement
