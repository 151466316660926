import { IconButton } from 'shared/ui'
import { css } from '@emotion/react'

const BackButtonStyle = css`
  padding: 7px;
`

export default function BackButton({ to }) {
  return (
    <div key="back-button" className="back">
      <IconButton
        name="arrowLeft"
        size="medium"
        svg
        to={to}
        tooltip="Back"
        css={BackButtonStyle}
      />
    </div>
  )
}
