import React from 'react'
import { useSelector } from 'react-redux'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import {
  selectShouldShowDealSection,
  selectContactDealsForEmail,
} from 'ducks/integrations/hubspot/selectors'
import { Label } from 'shared/ui'

import Section from '../Section'
import DealsIndexRow from './DealsIndexRow'

export default function DealsIndex({ id, doNavigatableSetState, email }) {
  const dealsObject = useSelector(state => {
    return selectContactDealsForEmail(state, email)
  })
  const showDeals = useSelector(state => selectShouldShowDealSection(state, id))
  if (!showDeals || !dealsObject || dealsObject.state !== 'fetched') return null
  if (dealsObject.state === 'fetched' && dealsObject.deals.length === 0) {
    return null
  }

  return (
    <Section className="DealsIndex">
      <Label size="small">Deals</Label>
      <NavigatableIntegrationWidgetCard.List>
        {dealsObject.deals.map(deal => (
          <DealsIndexRow
            deal={deal}
            doNavigatableSetState={doNavigatableSetState}
            key={deal.id}
          />
        ))}
      </NavigatableIntegrationWidgetCard.List>
    </Section>
  )
}
