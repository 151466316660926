import React from 'react'

const Header = () => (
  <div>
    <div className="Header">
      <div className="Header_title">Original Message</div>
    </div>
    <div className="Header Header-placeholder" />
  </div>
)

export default Header
