import { css } from '@emotion/react'
import { listGap } from '../RecipientsEditor/List/styles'

const moreOptions = css`
  @media only screen and (max-height: 600px) {
    overflow: auto;
  }
`

const indentedStyle = css`
  margin-left: 40px;
`

const gapStyle = css`
  gap: 8px 0;
`

const rowWrapper = css`
  display: flex;
  flex: 1 1 100%;
`

const indicator = theme => css`
  position: relative;
  height: 100%;
  transform: translateX(-${listGap / 2 + 1}px);

  &.indicator-no-gap {
    transform: translateX(2px);
  }

  &:after {
    position: absolute;
    display: 'block';
    content: '';
    width: 2px;
    height: 100%;
    background-color: ${theme.color.primary.brand};
  }
`

const droppable = css`
  height: 26px;
`

const draggable = css`
  cursor: grab;
`

export const styles = {
  indentedStyle,
  gapStyle,
  rowWrapper,
  moreOptions,
  indicator,
  droppable,
  draggable,
}
