import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const selectorButton = theme => css`
  border-radius: 2rem;
  font-weight: ${theme.fontSize.medium};
  font-size: ${theme.fontSize.large};
  height: ${rem(32)};
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  margin-right: ${theme.spacing['8']};
`

const selectorNoteButton = theme => css`
  box-shadow: inset 0 0 0 1px ${theme.color.buttonNoteOutlineFilledHover.border};
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  &:hover {
    box-shadow: inset 0 0 0 1px
      ${theme.color.buttonNoteOutlineFilledHover.borderHover};
  }
`

export const styles = {
  selectorButton,
  selectorNoteButton,
}
