import React, { useState } from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'

import { styles } from './styles'
import { styles as buttonWithMenuStyles } from '../Nav/ButtonWithMenu/styles'
import AccordionNav from './AccordionNav'

const menuOffset = [-12, 10]

const BurgerMenu = ({ className, active }) => {
  const [open, setOpen] = useState(false)

  return (
    <Dropdown
      overlay={<AccordionNav active={active} setOpen={setOpen} />}
      strategy="fixed"
      offset={menuOffset}
      visible={open}
      onVisibleChange={setOpen}
      className="grui p-0"
      css={buttonWithMenuStyles.dropdownContainer}
      autoHeight
    >
      <button
        type="button"
        aria-label="Toggle mobile menu"
        aria-expanded={open}
        css={[button.styles.preflight, styles.burgerMenu]}
        className={className}
      >
        <span css={styles.hamburgerBar} />
        <span css={styles.hamburgerBar} />
        <span css={styles.hamburgerBar} />
      </button>
    </Dropdown>
  )
}

export default BurgerMenu
