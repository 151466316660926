import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { convertHexToRGBA } from 'util/colors'

const tagsList = css`
  gap: ${rem(8)};
  flex-wrap: wrap;
`

const emptyText = theme => css`
  font-size: ${theme.fontSize.small};
  font-style: italic;
`

const tag = theme => css`
  background-color: ${theme.color.monochrome.white};
  border: 1px solid ${convertHexToRGBA(theme.color.info[900], 0.1)};
  border-radius: ${rem(4)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;

  &:hover {
    background-color: ${theme.color.monochrome.superLight};
  }

  &:disabled {
    pointer-events: none;
    border-color: inherit;

    span {
      visibility: hidden;
    }
  }
`

export const styles = {
  tagsList,
  emptyText,
  tag,
}
