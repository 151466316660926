import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const container = theme => css`
  display: flex;
  align-items: center;
  background-color: ${theme.color.info['50']};
  border-radius: ${rem(12)};
`

const heading = theme => css`
  color: ${theme.color.info['900']};
  line-height: inherit;
  margin-bottom: ${rem(2)};
  font-weight: ${theme.fontWeight.semibold};
`

const content = theme => css`
  color: ${theme.color.info['900']};
  font-size: ${theme.fontSize.large};
  margin: 0;
`

const btn = theme => css`
  flex-shrink: 0;
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.semibold};
`

export const styles = {
  container,
  heading,
  content,
  btn,
}
