import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const base = () => css`
  .menu-list {
    padding-left: ${rem(12)};
  }
`

const pinnedItem = theme => css`
  svg {
    color: ${theme.color.mainNav.pin};
    width: ${rem(12)};
    height: ${rem(12)};
  }
`

const leftNavFooter = theme => css`
  hr {
    border: 0;
    border-top: 1px solid ${theme.color.monochromeAlt.black};
    border-bottom: 1px solid ${theme.color.monochrome.dark};
    margin: 0;
  }

  .left-nav-footer-container {
    color: ${theme.color.mainNav.color};
    margin: 22px 14px 28px 14px;
    text-align: center;

    .title {
      margin-bottom: 8px;
      font-size: ${theme.fontSize.large};
      font-weight: ${theme.fontWeight.medium};
    }
  }
`
const composeButton = theme => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
  border-radius: 16px;
  color: ${theme.color.mainNav.composeButtonColor};
  background-color: ${theme.color.mainNav.composeButtonBackground};
  border: solid 1px ${theme.color.mainNav.composeButtonBorder};
  &:hover {
    color: ${theme.color.mainNav.composeButtonColorHover};
    background-color: ${theme.color.mainNav.composeButtonBackgroundHover};
    border: solid 1px ${theme.color.mainNav.composeButtonBorderHover};
  }
`

const navTitleText = () => css`
  padding-right: 6.438em;
`

const menu = () => css`
  &.with-footer .menu-list {
    padding-bottom: ${rem(270)};
  }
`

const fixOptionsPosition = css`
  > .grui.truncate {
    margin-left: 2px;
  }
  &:hover {
    [role='presentation'] {
      margin-left: auto;
    }
  }
`

const clickable = css`
  cursor: pointer;
`

export const styles = {
  base,
  pinnedItem,
  leftNavFooter,
  composeButton,
  navTitleText,
  menu,
  fixOptionsPosition,
  clickable,
}
