import { css } from '@emotion/react'

const base = theme => css`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.monochrome.white};
  position: relative;
`

export const styles = {
  base,
}
