import { useSelector } from 'react-redux'
import logo from 'assets/integrations/github/icon-24.png'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import LinkedGitHubIssuesIndex from './LinkedGitHubIssuesIndex'
import LinkedGitHubIssue from './LinkedGitHubIssue'

export default function GitHubWidgetCard({ doHideCard }) {
  const conversationId = useSelector(selectCurrentTicketId)
  if (!conversationId) return null
  return (
    <NavigatableIntegrationWidgetCard
      detailComponent={LinkedGitHubIssue}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={LinkedGitHubIssuesIndex}
      title="GitHub"
      persistenceKey="github"
    />
  )
}
