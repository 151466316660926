import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useCustomer } from 'ducks/integrations/stripe/hooks/customers'
import { useInvoice } from 'ducks/integrations/stripe/hooks/invoices'
import {
  useStatus,
  useBillingCycle,
} from 'ducks/integrations/stripe/hooks/subscriptions'
import { selectSubscriptionForId } from 'ducks/integrations/stripe/selectors/subscriptions'
import { capitalize, humanize } from 'util/strings'
import { getFormattedPrice } from 'util/numbers'

import KeyValues, { KeyValue } from 'components/KeyValues'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import { strftime } from 'util/date'

import cn from 'classnames'
import StatusText from 'components/integrations/StatusText'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'

export default function Detail({ integration, className }) {
  const { navigatableState, navigatableClearState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )
  const { id, customerId } = navigatableState || {}
  const customer = useCustomer(integration.id, customerId)

  const subscription = useSelector(state =>
    selectSubscriptionForId(state, {
      customerId: customer.id,
      subscriptionId: id,
    })
  )

  const upcomingInvoice = useInvoice(
    integration.id,
    customer.id,
    id,
    'upcoming'
  )

  const status = useStatus(subscription)
  const billingCycle = useBillingCycle(subscription)

  const discount = useMemo(
    () => {
      const coupon = subscription.discount?.coupon

      if (!coupon) {
        return null
      }

      const parsedAmountOff = parseInt(coupon.amount_off, 10)
      if (parsedAmountOff) {
        return getFormattedPrice(parsedAmountOff, coupon.currency || 'usd')
      }

      const parsedPercentOff = parseFloat(coupon.percent_off)
      if (parsedPercentOff) {
        return `${parsedPercentOff}%`
      }

      return null
    },
    [subscription]
  )

  const subscriptionUrl = `https://dashboard.stripe.com/subscriptions/${id}`

  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={cn(className, 'grui p-7')}
      onBack={navigatableClearState}
      title="back"
      unifiedTitleIconButton
    >
      <KeyValues>
        <KeyValue
          title="ID"
          value={
            <a target="_blank" rel="noopener noreferrer" href={subscriptionUrl}>
              {id}
              <ExternalLink />
            </a>
          }
        />
        <KeyValue
          title="Status"
          value={
            <StatusText
              text={capitalize(humanize(subscription.status))}
              status={status}
            />
          }
        />
        {subscription.created && (
          <KeyValue
            title="Started"
            value={strftime('%d %b %Y', new Date(subscription.created * 1000))}
          />
        )}
        <KeyValue title="Billing cycle" value={billingCycle} />
        {discount && <KeyValue title="Discount" value={discount} />}
        {subscription.quantity && (
          <KeyValue title="Number of plans" value={subscription.quantity} />
        )}
        {upcomingInvoice.subscription && (
          <KeyValue
            title="Next invoice amount"
            value={getFormattedPrice(
              upcomingInvoice.total,
              upcomingInvoice.currency
            )}
          />
        )}
        {subscription.current_period_end && (
          <KeyValue
            title="Next invoice date"
            value={strftime(
              '%d %b %Y',
              new Date(subscription.current_period_end * 1000)
            )}
          />
        )}
        <KeyValue
          title="Set to cancel"
          value={`${!!subscription.cancel_at_period_end}`}
        />
      </KeyValues>
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
