import React from 'react'
import { oneOf, string, bool, number } from 'prop-types'
import { motion } from 'framer-motion'
import Video from './Video'
import Title from './Title'

import { styles } from './styles'

const VideoContainer = ({ className, size, children, animated, delay }) => {
  const base = (
    <div css={styles.sizes[size]} className={className}>
      <div>{children}</div>
    </div>
  )

  if (animated) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay }}
      >
        {base}
      </motion.div>
    )
  }
  return base
}

VideoContainer.propTypes = {
  className: string,
  size: oneOf(['small', 'big']),
  animated: bool,
  delay: number,
}

VideoContainer.defaultProps = {
  className: undefined,
  size: 'small',
  animated: false,
  /**
   * How many seconds to delay the fade in animation
   */
  delay: 0.5,
}

VideoContainer.Title = Title
VideoContainer.Video = Video

export default VideoContainer
