import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIsInChat } from 'selectors/location'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import HotkeysGroup from './HotkeysGroup'

const inboxActions = {
  '?': 'View shortcut overview screen',
  'shift+n': 'Open new conversation',
}

const chatActions = {
  '?': 'View shortcut overview screen',
}

export default function Other() {
  const isInChat = useSelector(selectIsInChat)
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const actions = useMemo(
    () => {
      const baseActions = { ...(isInChat ? chatActions : inboxActions) }
      if (!isAdminOrOwnerOrAgent) {
        delete baseActions['shift+n']
      }
      return baseActions
    },
    [isInChat, isAdminOrOwnerOrAgent]
  )

  return <HotkeysGroup title="Other" hotkeys={actions} />
}
