import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdownContainer = theme => css`
  &&& {
    display: flex;
    flex-direction: column;
    color: ${theme.color.info['900']};
    font-size: ${theme.fontSize.large};
    overflow: unset;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: unset;
  }
`

const overlay = css`
  margin: 0 ${rem(-10)};
  & > * {
    margin: 0 ${rem(10)};
  }
`

const wandIcon = css`
  img {
    width: 24px;
    height: 19px;
  }
`

export const styles = {
  dropdownContainer,
  overlay,
  wandIcon,
}
