import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/react'

import ScrollerContext from 'components/Scroller/Context'

const curtainStyle = css`
  padding: 32px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: hsla(0, 0%, 80%, 1);
  font-size: 18px;
  font-weight: 500;
  pointer-events: none;
`

const contentsStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 65px;
  border: 4px dashed hsla(0, 0%, 94%, 1);
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

export default () => {
  const { getScrollerAPI } = useContext(ScrollerContext)
  const [dimensions, setDimensions] = useState(0)

  useEffect(
    () => {
      const scrollerAPI = getScrollerAPI()
      const container = scrollerAPI?.getElement()
      if (container) {
        // HACK (jscheel): In the sake of time, I'm not gonna pass refs around.
        // Yes, I know, I get it, but this is tightly coupled anyways.
        const header = container.querySelector('#composer .form-header')
        const footer = container.querySelector('#composer .form-footer')
        const headerRect = header.getBoundingClientRect()
        const footerRect = footer.getBoundingClientRect()
        const isFloatingFooter = !!container.querySelector(
          '#composer .floatingFooter'
        )
        setDimensions({
          top: headerRect.bottom,
          left: headerRect.left,
          width: headerRect.width,
          height: footerRect.top - headerRect.bottom - 8,
          paddingBottom: isFloatingFooter ? 32 : 0,
        })
      }
    },
    [getScrollerAPI, setDimensions]
  )

  return (
    <div css={[curtainStyle, dimensions]}>
      <div css={contentsStyle}>Drop here to attach</div>
    </div>
  )
}
