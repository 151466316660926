import { connect } from 'react-redux'
import { selectLocationState } from 'redux-first-router'
import { selectAgentHasMailboxAccess } from 'selectors/mailboxes/selectAgentHasMailboxAccess'
import View from './view'

const select = state => ({
  hasMailboxAccess: selectAgentHasMailboxAccess(state),
  routesMap: selectLocationState(state).routesMap,
})

export default connect(select)(View)
