import React from 'react'
import LoginLayout from 'components/LoginLayout'

import OtpForm from './Form'

export default function LoginPage() {
  return (
    <LoginLayout tagline="Your account is protected with two-factor authentication. Please enter the code from your authenticator app.">
      <OtpForm />
    </LoginLayout>
  )
}
