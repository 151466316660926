import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useCustomer } from 'ducks/integrations/stripe/hooks/customers'
import {
  useStatus,
  useStatusText,
} from 'ducks/integrations/stripe/hooks/charges'
import { selectChargeForId } from 'ducks/integrations/stripe/selectors/charges'

import KeyValues, { KeyValue } from 'components/KeyValues'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import { getFormattedPrice } from 'util/numbers'
import { strftime } from 'util/date'

import cn from 'classnames'
import StatusText from 'components/integrations/StatusText'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'

export default function Detail({ integration, className }) {
  const { navigatableState, navigatableClearState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const { id, customerId } = navigatableState || {}
  const customer = useCustomer(integration.id, customerId)

  const charge = useSelector(state =>
    selectChargeForId(state, { customerId: customer.id, chargeId: id })
  )

  const status = useStatus(charge)
  const statusText = useStatusText(charge)
  const paymentUrl = `https://dashboard.stripe.com/payments/${
    charge.payment_intent
  }`

  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={cn(className, 'grui p-7')}
      unifiedTitleIconButton
      onBack={navigatableClearState}
      title="back"
    >
      <KeyValues>
        <KeyValue
          title="ID"
          value={
            <a target="_blank" rel="noopener noreferrer" href={paymentUrl}>
              {charge.payment_intent}
              <ExternalLink />
            </a>
          }
        />
        <KeyValue
          title="Statement descriptor"
          value={charge.calculated_statement_descriptor}
        />
        {charge.balance_transaction ? (
          <>
            <KeyValue
              title="Amount"
              value={getFormattedPrice(
                charge.balance_transaction.amount,
                charge.currency
              )}
            />
            <KeyValue
              title="Fee"
              value={getFormattedPrice(
                charge.balance_transaction.fee,
                charge.currency
              )}
            />
            <KeyValue
              title="Net"
              value={getFormattedPrice(
                charge.balance_transaction.net,
                charge.currency
              )}
            />
          </>
        ) : (
          <KeyValue
            title="Amount"
            value={getFormattedPrice(charge.amount, charge.currency)}
          />
        )}
        <KeyValue
          title="Status"
          value={<StatusText text={statusText} status={status} />}
        />
        <KeyValue title="Description" value={charge.description} />
        <KeyValue
          title="Last update"
          value={strftime('%d %b %Y', new Date(charge.created * 1000))}
        />
      </KeyValues>
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
