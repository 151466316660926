// Switches between all panes that appear inside the main pane of the standard
// Layout E.g.
//
//   MainPage, TicketPage, SearchPage, NewConversationPage, LogConversationPage
//
import { connect } from 'react-redux'
import { selectIsCurrentTicketSearchQueryStringComplete } from 'selectors/search'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import View from './view'

const select = state => {
  const {
    page: { componentName, props },
  } = state

  return {
    ...props,
    componentName,
    queryStringIsComplete: selectIsCurrentTicketSearchQueryStringComplete(
      state
    ),
    mailboxId: selectCurrentMailboxId(state),
  }
}

export default connect(select)(View)
