import { useController } from 'react-hook-form'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'

import { styles } from './styles'

const { Button } = Dropdown

const options = [
  {
    id: 'CUSTOMER',
    value: 'CUSTOMER',
    name: 'Customer changed/canceled order',
  },
  {
    id: 'FRAUD',
    value: 'FRAUD',
    name: 'Fraudulent order',
  },
  {
    id: 'INVENTORY',
    value: 'INVENTORY',
    name: 'Items unavailable',
  },
  {
    id: 'DECLINED',
    value: 'DECLINED',
    name: 'Payment declined',
  },
  {
    id: 'STAFF',
    value: 'STAFF',
    name: 'Staff Error',
  },
  {
    id: 'OTHER',
    value: 'OTHER',
    name: 'Other',
  },
]

const CancelReasonDropdown = ({ control, name }) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: options && options[0].value,
  })
  const overlay = <DropdownMenu data={options} />
  const selected = options.find(item => item.value === value)
  return (
    <Dropdown
      overlay={overlay}
      onSelect={onChange}
      selectedKey={selected?.value}
    >
      <Button css={styles.dropdownBtn}>
        {selected ? selected.name : 'Please select cancellation reason'}
      </Button>
    </Dropdown>
  )
}

export default CancelReasonDropdown
