import React, { useMemo } from 'react'
import cn from 'classnames'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { strftime } from 'util/date'

const TimelineEventComment = React.memo(({ timelineEvent }) => {
  const {
    message,
    createdAt,
    attributeToApp,
    appTitle,
    __typename,
  } = timelineEvent
  const isComment = __typename === 'CommentEvent'
  const time = useMemo(
    () => {
      return strftime('%H:%M %p', createdAt)
    },
    [createdAt]
  )
  const author = attributeToApp ? appTitle : ''

  return (
    <div
      className={cn('shopifyV2-events-event', {
        'shopifyV2-events-comment': isComment,
      })}
    >
      <div className="shopifyV2-events-event-message">
        {isComment && (
          <div className="shopifyV2-events-event-author shopifyV2-textClip">
            {author}
          </div>
        )}
        <SanitizedHTML html={message} className="shopifyV2-textClip" />
      </div>
      <div className="shopifyV2-events-event-time">{time}</div>
    </div>
  )
})

export default TimelineEventComment
