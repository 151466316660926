import cn from 'classnames'
import React, { cloneElement, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import CarouselControls from './CarouselControls'
import CarouselItem from './CarouselItem'
import CarouselWindow from './CarouselWindow'
import CarouselWrapper from './CarouselWrapper'

Carousel.Item = CarouselItem

export default Carousel

Carousel.propTypes = {
  height: PropTypes.integer,
  width: PropTypes.integer,
}

Carousel.defaultProps = {
  height: 100,
  width: null,
}
function Carousel({ children, height, width }) {
  const [{ currentIndex, previousIndex }, setCurrentIndex] = useState({
    currentIndex: 0,
  })
  const numberOfItems = children.length
  const goToIndex = useCallback(
    index => {
      let newIndex = index
      if (index < 0) newIndex = children.length - 1
      if (index >= children.length) newIndex = 0
      return setCurrentIndex({
        currentIndex: newIndex,
        previousIndex: currentIndex,
      })
    },
    [currentIndex]
  )
  const goToPrevious = useCallback(
    () => goToIndex(currentIndex - 1, numberOfItems),
    [currentIndex]
  )
  const goToNext = useCallback(
    () => goToIndex(currentIndex + 1, numberOfItems),
    [currentIndex]
  )
  return (
    <CarouselWrapper width={width}>
      <CarouselWindow height={height} width={width}>
        {children.map((child, index) => {
          return cloneElement(child, {
            className: cn({
              current: currentIndex === index,
              next:
                currentIndex === index - 1 ||
                (index === 0 && currentIndex === children.length - 1),
              previous:
                currentIndex === index + 1 ||
                (currentIndex === 0 && index === children.length),
              previousCurrent: previousIndex === index,
            }),
          })
        })}
      </CarouselWindow>
      <CarouselControls goToNext={goToNext} goToPrevious={goToPrevious} />
    </CarouselWrapper>
  )
}
