import React, { useCallback } from 'react'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import { Button } from 'util/ui'
import { useShopifyOrders } from 'ducks/integrations/shopify/hooks'
import { shopifyGidToId } from 'ducks/integrations/shopify/utils'
import { capture } from 'ducks/tracking/actions'
import { styles } from './styles'
import Order from './Order'

const RecentOrders = React.memo(({ customerId, integrationId }) => {
  const {
    orders = [],
    externalLinkViewMoreOrders,
    externalLinkViewCustomer,
    fetchMoreOrders,
    isFetchingMoreOrders,
    isMoreOrdersFetched,
    openCreateOrderDrawer,
    hasOrders,
    hasMoreOrders,
  } = useShopifyOrders({
    integrationId,
    customerId,
  })

  const customerShortId = shopifyGidToId(customerId)

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Shopify Customer Link Clicked')
    return true
  }, [])

  return (
    <>
      <div className="shopifyV2-header-label">Recent orders</div>
      {!hasOrders && <div className="grui p-7">No orders found</div>}

      {hasOrders &&
        orders.map(order => (
          <Order
            orderId={order.id}
            key={order.id}
            integrationId={integrationId}
          />
        ))}

      {hasOrders &&
        isMoreOrdersFetched && (
          <div css={styles.ordersFooter}>
            <a
              href={`${externalLinkViewMoreOrders}?customer_id=${customerShortId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="grui ml-auto"
            >
              View orders in Shopify<ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          </div>
        )}

      {hasMoreOrders &&
        !isMoreOrdersFetched && (
          <div css={styles.ordersFooter}>
            {isFetchingMoreOrders && 'Fetching...'}
            {!isFetchingMoreOrders && (
              <a href="" onClick={fetchMoreOrders} rel="noopener noreferrer">
                + Show more orders
              </a>
            )}
          </div>
        )}

      <div className="shopifyV2-footer">
        <Button basic onClick={openCreateOrderDrawer}>
          Create order
        </Button>
        <a
          href={externalLinkViewCustomer}
          target="_blank"
          rel="noopener noreferrer"
          className="grui ml-auto"
          onClick={onViewExternalLinkClicked}
        >
          View in Shopify<ExternalLink className="shopifyV2-externalLink-icon" />
        </a>
      </div>
    </>
  )
})

RecentOrders.displayName = 'RecentOrders'

export default RecentOrders
