import editor from 'shared/editor/utils'
import { convertImageToBase64 } from 'util/browser'
import { EDITOR_MAX_INLINE_IMAGE_SIZE } from 'util/file'

export function handleDrop(accepted, draftId, uploadAction) {
  const files = accepted
  const nonImages = []
  const promises = []
  files.forEach(file => {
    const isImage = file.type.match('image/')
    const isWrongSize = isImage && file.size > EDITOR_MAX_INLINE_IMAGE_SIZE
    const rightTypeAndSize = isImage && !isWrongSize
    if (rightTypeAndSize) {
      promises.push(convertImageToBase64(file))
    } else {
      if (isImage && isWrongSize) {
        app.doShowSnackbar(
          'Added image as attachment because the file was too large'
        )
      }
      nonImages.push(file)
    }
  })
  if (promises.length > 0) {
    Promise.all(promises).then(values => {
      const tags = values.map(data => {
        let widthAttr = ''
        if (data.width > 400) widthAttr = 'width="400"'
        return `<img data-original-filename="${
          data.name
        }" class="grv-inline-image" src="${data.src}" ${widthAttr} />`
      })
      const html = tags.join('<br />')

      editor.insertContent(html)
    })
  }
  if (nonImages.length > 0) {
    uploadAction(accepted, false, draftId)
  }
}
