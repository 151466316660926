import React from 'react'
import { useSelector } from 'react-redux'

import {
  selectContactListsForEmail,
  selectShouldShowListSection,
} from 'ducks/integrations/hubspot/selectors'
import { Label } from 'shared/ui'

import Section from '../Section'

export default function Lists({ id, email }) {
  const listsObject = useSelector(state => {
    return selectContactListsForEmail(state, email)
  })
  const shouldShow = useSelector(state =>
    selectShouldShowListSection(state, id)
  )
  if (!shouldShow) return false
  if (!listsObject || listsObject.state !== 'fetched' || !listsObject.lists)
    return null
  const lists = listsObject.lists
  return (
    <Section>
      <Label size="small">List memberships</Label>
      <ul>
        {lists.map(({ id: listId, name }) => {
          return <li key={listId}>{name}</li>
        })}
      </ul>
    </Section>
  )
}
