import React from 'react'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import { styles } from './styles'
import useColumns, { emptyDataColumns } from './useColumns'

const ProductListTable = React.memo(
  ({
    orderItems,
    handleRemoveItem,
    handleUpdateItem,
    integrationId,
    customerId,
  }) => {
    const { isCalculating, isSending } = useShopifyDraft({
      integrationId,
      customerId,
    })
    const columns = useColumns()

    return (
      <div css={styles.tableOverflowOverride}>
        <Table
          columns={orderItems.length ? columns : emptyDataColumns}
          data={orderItems}
          loading={false}
          css={[styles.table, !orderItems.length && styles.empty]}
          deleteData={handleRemoveItem}
          updateData={handleUpdateItem}
          className="grui mt-5"
          disabled={isCalculating || isSending}
        />
      </div>
    )
  }
)

export default ProductListTable
