import React from 'react'
import cn from 'classnames'

import metrics from 'util/metrics'

import ReplyNoteEditFormn from 'components/App/DesktopView/CommunicationForms/Reply/NoteEdit'
import SubsequentActionsList from './SubsequentActionsList'
import ExpandedMessageContent from './ExpandedMessageContent'
import ExpandedNoteContent from './ExpandedNoteContent'

import { handlePrintClick } from './util'

class ExpandedMessageView extends React.Component {
  state = {
    isEdit: false,
    showMeta: true,
  }

  componentDidMount() {
    const { message } = this.props
    metrics.stopTimer(`changeset-load-${message.id}`)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { message } = this.props
    const { message: nextMessage } = nextProps
    const { isEdit } = this.state
    const { isEdit: nextIsEdit } = nextState
    const { showMeta } = this.state
    const { showMeta: nextShowMeta } = nextState

    const actionsWereCount = message.subsequentActions
      ? message.subsequentActions.length
      : 0
    const actionsAreCount = nextMessage.subsequentActions
      ? nextMessage.subsequentActions.length
      : 0

    const should =
      message.id !== nextMessage.id ||
      actionsAreCount !== actionsWereCount ||
      message.action.change.deletedAt !== nextMessage.action.change.deletedAt ||
      message.action.change.editedAt !== nextMessage.action.change.editedAt ||
      isEdit !== nextIsEdit ||
      showMeta !== nextShowMeta
    return should
  }

  onPrintClick = e => {
    e.stopPropagation()
    handlePrintClick(e)
  }

  onCopyLinkClick = e => {
    const { message, doShowSnackbar } = this.props
    const { origin, pathname } = window.location
    const messageURL = `${origin}${pathname}#changeset-${message.id}`
    if (!navigator.clipboard)
      return doShowSnackbar('Could not copy a link to the message.')
    navigator.clipboard
      .writeText(messageURL)
      .then(() =>
        doShowSnackbar('Link to the message was copied to your clipboard')
      )
    e.stopPropagation()
    return null
  }

  onDeleteClick = e => {
    const { ticketId, message, onDelete } = this.props

    e.stopPropagation()
    onDelete(ticketId, message.action.change.id)
  }

  onEditClick = e => {
    e.stopPropagation()
    this.setState({ isEdit: true })
  }

  onCancelEdit = () => {
    this.setState({ isEdit: false })
  }

  onMouseOver = () => {
    const { showMeta } = this.state

    if (!showMeta) {
      return
    }

    this.setState({ showMeta: false })
  }

  onReactionClick = reaction => {
    const { ticketId, message, onReaction } = this.props
    onReaction(ticketId, message.action.change.id, reaction)
  }

  expandChangeset = () => {
    const { message, expand } = this.props
    expand(message.id)
  }

  showActionHeader = () => {
    this.setState({ showMeta: false })
  }

  showMetaHeader = () => {
    this.setState({ showMeta: true })
  }

  render() {
    const {
      message,
      ticketId,
      currentUserId,
      onOpenImage,
      integrationSettings,
      isFirstMessage,
      mailboxIndicatorNeeded,
      mailboxName,
      mailboxColor,
    } = this.props

    const { isEdit, showMeta } = this.state

    const isOutsideCommunication = message.isOutsideCommunication
    const isNote = message.isNote
    const isAgent = message?.action?.actor?.type === 'Agent'

    if (isEdit) {
      return (
        <div
          id={`changeset-${message.id}`}
          className={cn('changeset expanded', {
            forward: isOutsideCommunication,
          })}
        >
          <ReplyNoteEditFormn
            ticketId={ticketId}
            message={message}
            onCancelEdit={this.onCancelEdit}
          />
          <SubsequentActionsList
            message={message}
            currentUserId={currentUserId}
            integrationSettings={integrationSettings}
          />
        </div>
      )
    }

    return (
      <div
        id={`changeset-${message.id}`}
        className={cn('changeset expanded', {
          forward: isOutsideCommunication,
        })}
      >
        <div className="action-container">
          <div
            className={cn('action', {
              message: !isNote,
              note: isNote,
              'fullstack-noteAction': isNote,
              forward: isOutsideCommunication,
            })}
            data-print-target="message-or-note"
            onMouseEnter={this.showActionHeader}
            onMouseLeave={this.showMetaHeader}
            onMouseOver={this.onMouseOver}
          >
            <div className="contents">
              {!isNote && (
                <ExpandedMessageContent
                  message={message}
                  expandChangeset={this.expandChangeset}
                  currentUserId={currentUserId}
                  isFirstMessage={isFirstMessage}
                  showMeta={showMeta}
                  mailboxIndicatorNeeded={mailboxIndicatorNeeded}
                  mailboxName={mailboxName}
                  mailboxColor={mailboxColor}
                  onOpenImage={onOpenImage}
                  onCopyLinkClick={this.onCopyLinkClick}
                  onPrintClick={this.onPrintClick}
                  isAgent={isAgent}
                />
              )}
              {isNote && (
                <ExpandedNoteContent
                  message={message}
                  expandChangeset={this.expandChangeset}
                  ticketId={ticketId}
                  currentUserId={currentUserId}
                  isFirstMessage={isFirstMessage}
                  showMeta={showMeta}
                  onOpenImage={onOpenImage}
                  onCopyLinkClick={this.onCopyLinkClick}
                  onPrintClick={this.onPrintClick}
                  onDeleteClick={this.onDeleteClick}
                  onEditClick={this.onEditClick}
                  onReactionClick={this.onReactionClick}
                />
              )}
            </div>
          </div>
        </div>
        <SubsequentActionsList
          message={message}
          currentUserId={currentUserId}
          integrationSettings={integrationSettings}
        />
      </div>
    )
  }
}

export default ExpandedMessageView
