import { memo, useCallback, useMemo, useState } from 'react'
import { useRechargeOrders } from 'ducks/integrations/recharge/hooks/orders'
import { Loader } from 'shared/ui'

import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import ListItem from './ListItem'

const List = ({ integrationId, subscriptionId }) => {
  const [showMore, setShowMore] = useState(false)
  const { orders: allOrders, isLoading, isError } = useRechargeOrders(
    integrationId,
    subscriptionId
  )

  const orders = useMemo(
    () => {
      if (!allOrders) {
        return []
      }

      return allOrders.filter(charge =>
        ['success', 'cancelled'].includes(charge.status)
      )
    },
    [allOrders]
  )

  const ordersToShow = useMemo(
    () => {
      if (!orders) {
        return []
      }

      if (showMore) {
        return orders
      }

      return orders.slice(0, 3)
    },
    [orders, showMore]
  )

  const toggleShowMore = useCallback(
    event => {
      event.preventDefault()

      setShowMore(!showMore)
    },
    [showMore]
  )

  if (!orders) {
    return null
  }

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Recent orders" />

      {isLoading && <Loader className="grui my-7" />}

      {isError && (
        <div className="grui px-4 py-7">
          <EmptyMessage>There was an error fetching Recharge data</EmptyMessage>
        </div>
      )}

      {!isLoading &&
        !ordersToShow.length && (
          <div className="grui px-4 py-7">
            <EmptyMessage>No recent orders to show</EmptyMessage>
          </div>
        )}

      <NavigatableIntegrationWidgetCard.List hideMargin>
        {ordersToShow.map(order => (
          <ListItem
            key={order.id}
            orderId={order.id}
            subscriptionId={subscriptionId}
            date={order.scheduled_at}
          />
        ))}
      </NavigatableIntegrationWidgetCard.List>

      {orders.length > 3 &&
        !showMore && (
          <a
            className="grui recharge-show-more"
            onClick={toggleShowMore}
            rel="noopener noreferrer"
          >
            + Show more orders
          </a>
        )}
    </>
  )
}

export default memo(List)
