import React from 'react'
import { bool, func, string } from 'prop-types'
import { useFormState } from 'react-hook-form'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { shopifyMoneyString, currencyCodeToSymbol } from 'util/currency'
import { styles } from './AdditinalInfo.styles'
import { FORM_KEY_NOTE, FORM_KEY_SHIPPING_AMOUNT } from '../../constants'

const AdditinalInfo = React.memo(
  ({
    control,
    register,
    className,
    orderCurrencyCode,
    shippingMaximumRefundableSet,
    isCalculating,
    canRefundShipping,
    calculateSuggestedRefund,
  }) => {
    const { errors } = useFormState({
      control,
      name: FORM_KEY_SHIPPING_AMOUNT,
    })

    const maxRefundableCurrencyCode =
      shippingMaximumRefundableSet?.shopMoney?.currencyCode || orderCurrencyCode
    const currencySymbol = currencyCodeToSymbol(maxRefundableCurrencyCode)

    const maxShippingString = shopifyMoneyString({
      currencyCode: maxRefundableCurrencyCode,
      amount: shippingMaximumRefundableSet?.shopMoney?.amount,
    })

    return (
      <div className={className} css={styles.base}>
        {canRefundShipping && (
          <div className="grui mb-11">
            <Field
              tagPosition="left"
              tagContent={currencySymbol}
              className="grui mb-3"
              disabled={isCalculating}
              label="Refund shipping"
              placeholder="0.00"
              {...register(FORM_KEY_SHIPPING_AMOUNT, {
                onChange: calculateSuggestedRefund,
                valueAsNumber: true,
              })}
              validateStatus={
                errors?.[FORM_KEY_SHIPPING_AMOUNT]?.message
                  ? 'error'
                  : undefined
              }
              help={errors?.[FORM_KEY_SHIPPING_AMOUNT]?.message}
            />
            <p css={[paragraph.styles.preflight, text.styles.textMediumDark]}>
              <span>
                <span css={mainStyles.moneyString}>{maxShippingString}</span>{' '}
                available for refund
              </span>
            </p>
          </div>
        )}
        <Field
          className="reason"
          label="Reason for refund"
          disabled={isCalculating}
          {...register(FORM_KEY_NOTE)}
        />
        <p
          css={[paragraph.styles.preflight, text.styles.textMediumDark]}
          className="grui mb-5"
        >
          Only you and other staff can see this reason.
        </p>
      </div>
    )
  }
)

AdditinalInfo.propTypes = {
  className: string,
  currencyCode: string,
  calculating: bool,
  onShippingChange: func,
}

AdditinalInfo.defaultProps = {
  className: undefined,
  currencyCode: '',
  calculating: false,
  onShippingChange() {},
}

export default AdditinalInfo
