import React from 'react'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  shopifyGidToId,
  shopifyStoreAdminUrl,
} from 'ducks/integrations/shopify/utils'
import { styles } from './styles'

function ReturnNotification({ integration, orderId }) {
  const { storeDomain } = integration
  const shopifyUrl = `${shopifyStoreAdminUrl(
    storeDomain
  )}/orders/${shopifyGidToId(orderId)}/return`

  return (
    <MessageCard
      type="info"
      closeable
      css={styles.messageCard}
      className="grui mb-12"
    >
      {/* Dynamic top callout title should be interpreted as markdown as received from shopify */}
      <div>
        <span className="header">Return items before issuing refunds</span>
      </div>
      <div>
        If you want to track shipping and automatically restock returned items,
        return items before issuing a refund. You can’t return an item if it’s
        already been refunded.
      </div>
      <div className="grui mt-10">
        <a
          href={shopifyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="grui inline-block mr-10"
          css={button.styles.secondary}
        >
          Return items in Shopify
        </a>
        <a
          href="https://help.shopify.com/en/manual/orders/refund-cancel-order"
          target="_blank"
          rel="noopener noreferrer"
          css={button.styles.link}
        >
          Learn more
        </a>
      </div>
    </MessageCard>
  )
}

export default React.memo(ReturnNotification)
