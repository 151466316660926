import React from 'react'
import Link from 'redux-first-router-link'
import NoticeBar from 'components/NoticeBar'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'

import styles from './styles.less'

const TrialMessage = ({ trialDaysLeft, isManager }) => {
  return (
    <NoticeBar
      key="app-notice-trialinfo"
      flagName="app-notice-trialinfo"
      into
      dismissible
      forceShow
    >
      {trialDaysLeft <= 1 && 'You only have less than a day left in your trial'}
      {trialDaysLeft > 1 &&
        trialDaysLeft < 2 &&
        'You only have 1 day left in your trial'}
      {trialDaysLeft >= 2 &&
        `You ${trialDaysLeft <= 5 ? 'only' : ''} have ${trialDaysLeft.toFixed(
          0
        )} days left in your trial`}
      {isManager ? (
        <Link
          className={styles.buttonLink}
          to={{ type: SETTINGS_BILLING_PAGE }}
        >
          Subscribe now
        </Link>
      ) : (
        '. Please contact your account owner or admin to upgrade.'
      )}
    </NoticeBar>
  )
}

export default TrialMessage
