import React from 'react'
import cn from 'classnames'
import { useTheme } from '@emotion/react'
import { Avatar } from 'shared/ui'
import styles from './styles.css'

const AvatarForAgentItem = ({
  className,
  isAnyone,
  agents,
  agent,
  presence,
}) => {
  const {
    color: { monochrome, primary },
  } = useTheme()

  if (!isAnyone) {
    return (
      <Avatar
        className={cn(styles.agent_avatar, className)}
        actor={agent}
        size={28}
        initialsColor={monochrome.white}
        initialsBackground={primary.brand}
        initialsFontSize={13}
        presence={presence}
      />
    )
  }
  const agentCount = agents ? agents.length : 0
  return (
    <div className={styles.anyone_avatar}>
      {agentCount > 99 ? '99+' : `${agentCount}`}
    </div>
  )
}

export default AvatarForAgentItem
