import React from 'react'
import { SUI } from 'shared/ui'
import FilterBar from 'subapps/ticketing/components/TicketList/Header/FilterBar'
import TitleBar from 'subapps/ticketing/components/TicketList/Header/TitleBar'

export default function ActionBar(props) {
  return (
    <SUI>
      <TitleBar isWide {...props} />
      <FilterBar isWide />
    </SUI>
  )
}
