import { connect } from 'react-redux'
import { selectDraftBodyByDraftId } from 'ducks/drafts2/selectors'

import View from './view'

const select = () => {
  return (state, props) => {
    const { draftId } = props
    const content = selectDraftBodyByDraftId(state, draftId)

    return {
      content,
    }
  }
}

export default connect(select)(View)
