import React from 'react'

const EndIndicator = React.memo(() => {
  return (
    <div className="end-indicator">
      <div className="dot" />
    </div>
  )
})

export default EndIndicator
