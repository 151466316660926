import React, { useCallback, useEffect } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Scroller from 'components/Scroller'
import DropdownMenuItem from './DropdownMenuItem'

const DropdownMenu = React.forwardRef(
  ({ onSelect, onActivate, recipients }, activatedRecipientIdRef) => {
    const handleActivate = useCallback(
      id => {
        const currentId = activatedRecipientIdRef.current
        // eslint-disable-next-line no-param-reassign
        activatedRecipientIdRef.current = id
        if (onActivate && currentId !== id) {
          onActivate(id)
        }
      },
      [activatedRecipientIdRef, onActivate]
    )

    useEffect(
      () => {
        handleActivate(recipients[0]?.id)
      },
      [recipients, handleActivate]
    )

    return (
      <Dropdown.Menu>
        <Scroller>
          {recipients.map(r => (
            <DropdownMenuItem
              key={r.id}
              recipient={r}
              // Click handler
              onSelect={onSelect}
              onActivate={handleActivate}
            />
          ))}
        </Scroller>
      </Dropdown.Menu>
    )
  }
)

export default DropdownMenu
