import React from 'react'
import { useSelector } from 'react-redux'

import CollapsableContent from 'components/CollapsableContent'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import {
  selectJiraServerHiddenFields,
  selectJiraServerIssuesById,
  selectCurrentTicketJiraServerIssuesById,
  selectJiraServerVisibleFields,
} from 'ducks/integrations/jiraServer'
import LinkedJiraServerIssueFields from './LinkedJiraServerIssueFields'
import { useJiraServerIssueActions } from './hooks'

export default function LinkedJiraServerIssue({
  className,
  doNavigatableClearState,
  navigatableState: issueId,
}) {
  const unlinkedIssue = useSelector(selectJiraServerIssuesById)[issueId]
  const issue =
    useSelector(selectCurrentTicketJiraServerIssuesById)[issueId] ||
    unlinkedIssue
  const hiddenFields = useSelector(selectJiraServerHiddenFields)
  const visibleFields = useSelector(selectJiraServerVisibleFields)
  const actions = useJiraServerIssueActions(issue, doNavigatableClearState)
  const { id, fields: { summary } = {}, key, title } = issue || {}
  const summaryWithKey = `[${key}] ${summary}`

  if (!issue) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      onBack={doNavigatableClearState}
      title={key ? summaryWithKey : title}
    >
      {visibleFields &&
        visibleFields.length > 0 && (
          <LinkedJiraServerIssueFields fields={visibleFields} issue={issue} />
        )}
      <LinkedResourceActions id={id} actions={actions} />

      {hiddenFields &&
        hiddenFields.length > 0 && (
          <CollapsableContent
            animateHeight={false}
            hideTrigger="– Hide additional fields"
            showTrigger="+ Show additional fields"
          >
            <LinkedJiraServerIssueFields fields={hiddenFields} issue={issue} />
          </CollapsableContent>
        )}
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
