import { connect } from 'react-redux'

import {
  doSetTicketAssignmentFilter,
  doSetTicketGroupAssignmentFilter,
  doDrillDownOnTeam,
} from 'actions/modals'

import { selectCurrentAgent, selectAgentCount } from 'selectors/agents/base'
import { selectIsAgentsBootstrapped } from 'selectors/agents/selectIsAgentsBootstrapped'
import { selectIsGroupsBootstrapped } from 'selectors/groups/selectIsGroupsBootstrapped'

import AssignmentModal from './view'

const select = state => ({
  isLoading: !(
    selectIsAgentsBootstrapped(state) && selectIsGroupsBootstrapped(state)
  ),
  currentAgent: selectCurrentAgent(state),
  totalAgentCount: selectAgentCount(state),
})

const perform = dispatch => ({
  drillDownOnTeam: groupId => dispatch(doDrillDownOnTeam(groupId)),
  onFilter: term => {
    dispatch(doSetTicketAssignmentFilter(term))
  },
  onFilterGroup: term => {
    dispatch(doSetTicketGroupAssignmentFilter(term))
  },
})

export default connect(select, perform)(AssignmentModal)
