import styled from '@emotion/styled'

import CollapsableCard from 'components/CollapsableCard'
import CollapsableContent from 'components/CollapsableContent'
import { useRecentConversations } from 'ducks/integrations/recentConversations'

import themeVars from 'ui_theme/site/globals/site.variables'

import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'
import RecentConversation from './RecentConversation'

export default styled(RecentConversations)`
  margin: -11px -${props => props.theme.spacing['7']} -13px;

  .RecentConversations-more {
    margin-top: -10px;
  }
  .ticket {
    display: block;
    padding: 7px 12px 5px;
    cursor: pointer;

    &.current {
      background: ${props => props.theme.color.monochrome.superLight};
      border-left: 2px solid #19b4ff;
      padding-left: 10px;
    }

    &:hover {
      background: ${props => props.theme.color.secondary.brand};
    }

    .assigneeAndUpdatedAt {
      color: ${props => props.theme.color.monochrome.mediumDark};
      font-size: ${themeVars.relative11px};
      .assignee {
        color: ${props => props.theme.color.monochrome.black};
      }
    }

    .titleAndState {
      line-height: 22px;
      display: flex;
      .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #129eff;
        flex-grow: 1;
      }
      .state {
        width: 16px;
        .unread {
          margin: 7px 0 0 4px;
          background: #00b7fc;
          height: 8px;
          width: 8px;
          border-radius: 8px;
        }

        .snoozed {
          height: 12px;
          margin-left: 2px;
          path {
            fill: #e95f3b;
          }
        }

        .closed {
          path {
            fill: #00ba9e;
          }
        }
      }
    }
  }

  ${CollapsableContent} {
    margin-top: ${props => props.theme.spacing['3']};
  }

  .emptyState {
    font-style: italic;
    color: var(--color-monochrome-mediumDark);
    padding: ${props => props.theme.spacing['7']};
  }

  .CollapsableContent-trigger {
    margin-right: ${props => props.theme.spacing['7']};
    margin-bottom: 11px;
  }

  .CollapsableContent-fields.rah-static--height-auto {
    margin-bottom: -11px;
  }
`

function RecentConversations({ className, doHideCard }) {
  const {
    isLoading,
    hasConversations,
    conversations,
  } = useRecentConversations()

  return (
    <CollapsableCard
      isLoading={isLoading}
      persistenceKey={'customWidget.recent_conversations'}
      title={
        <SortableVisibleControlsWithTitle
          title="Recent Conversations"
          onHide={doHideCard}
        />
      }
    >
      <div className={className}>
        {!hasConversations && (
          <p className="emptyState">
            No recent conversations from this contact.
          </p>
        )}
        {hasConversations && (
          <>
            {conversations
              .slice(0, 3)
              .map(conversation => (
                <RecentConversation
                  key={conversation.id}
                  conversation={conversation}
                />
              ))}
            {conversations.length > 3 && (
              <CollapsableContent
                hideTrigger="– Hide more conversations"
                showTrigger="+ Show more conversations"
              >
                <div className="RecentConversations-more">
                  {conversations
                    .slice(3)
                    .map(conversation => (
                      <RecentConversation
                        key={conversation.id}
                        conversation={conversation}
                      />
                    ))}
                </div>
              </CollapsableContent>
            )}
          </>
        )}
      </div>
    </CollapsableCard>
  )
}
