import React from 'react'
import cn from 'classnames'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { arrowRight as ArrowRight } from 'assets/icons/groove/v2'
import { styles } from './styles'

const Step = React.forwardRef(
  ({ children, completed, current, onClick }, ref) => {
    return (
      <button
        className="grui flex items-center w-100 py-5"
        css={[button.styles.preflight, styles.step]}
        onClick={onClick}
        ref={ref}
      >
        <span
          className={cn(
            'grui truncate pr-5 mr-auto',
            completed && 'opacity-80'
          )}
        >
          {children}
        </span>
        {current && <ArrowRight className="arrow-icon" />}
        {!current && (
          <span
            className={cn(
              'grui step-icon flex-shrink-0 flex items-center justify-center',
              completed ? 'check-icon' : 'circle-icon'
            )}
          />
        )}
      </button>
    )
  }
)

Step.displayName = 'Step'

export default Step
