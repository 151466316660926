import { connect } from 'react-redux'

import {
  doSearchLabels,
  doFetchTopLabelsForCurrentMailbox,
  doCancelCreateLabel,
  doCreateLabelAndAddAsSelected,
} from 'actions/labels'
import { doToggleLabel, doLabelCurrentTicket } from 'actions/labelings/shared'
import { doSelectLabel } from 'actions/labelings/desktop'

import {
  selectIsLoadingTopLabels,
  selectIsFetchingLabelSearchResults,
} from 'selectors/app'
import {
  selectIsSearchingLabels,
  selectLabelSearchTerm,
  selectLabelsChanged,
  selectLabelsForBulkEditing,
} from 'selectors/labelings'
import {
  selectIsCreatingALabel,
  selectInitialLabelProps,
} from 'selectors/modals_base'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectLabelingDropdownDisplayMoreLabels } from 'selectors/labels'

import LabelsModal from './view'

const select = state => {
  return {
    hasPendingEdits: selectLabelsChanged(state),
    isCreatingALabel: selectIsCreatingALabel(state),
    isFetchingSearchResults: selectIsFetchingLabelSearchResults(state),
    isLoadingTop: selectIsLoadingTopLabels(state),
    isSearching: selectIsSearchingLabels(state),
    labelToEdit: selectInitialLabelProps(state),
    labels: selectLabelsForBulkEditing(state),
    hasMoreLabels: selectLabelingDropdownDisplayMoreLabels(state),
    searchTerm: selectLabelSearchTerm(state),
    ticketId: selectCurrentTicketId(state),
  }
}

const perform = {
  doCancelCreateLabel,
  doToggleLabel,
  onApply: doLabelCurrentTicket,
  onCheckboxChanged: doSelectLabel,
  onModalOpen: doFetchTopLabelsForCurrentMailbox,
  onSearch: doSearchLabels,
  onSave: doCreateLabelAndAddAsSelected,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onApply: ownProps.onApply || dispatchProps.onApply,
  }
}

export default connect(select, perform, mergeProps)(LabelsModal)
