export const ASSIGNING = 'ASSIGNING'
export const BULK_LABELING = 'BULK_LABELING'
export const CANNED_REPLIES = 'CANNED_REPLIES'
export const CONFIRM_UNSNOOZE = 'CONFIRM_UNSNOOZE'
export const DELETING = 'DELETING'
export const DRAFT_ASSIGNING = 'DRAFT_ASSIGNING'
export const DROPDOWN = 'DROPDOWN'
export const LABELING_EDIT = 'LABELING_EDIT'
export const LABEL_FORM = 'LABEL_FORM'
export const LABEL_MANAGE = 'LABEL_MANAGE'
export const LIGHTBOX = 'LIGHTBOX'
export const MAILBOX_CHANGE = 'MAILBOX_CHANGE'
export const MENTION = 'MENTION'
export const MERGE = 'MERGE'
export const RECIPIENTS = 'RECIPIENTS'
export const SNOOZING = 'SNOOZING'
export const INTEGRATIONS = 'INTEGRATIONS'
export const TICKET_PREVIEW = 'TICKET_PREVIEW'
export const CHAT_AVAILABILITY = 'CHAT_AVAILABILITY'
export const CHAT_PRESENCE_OVERVIEW = 'CHAT_PRESENCE_OVERVIEW'
export const CHANGE_USER = 'CHANGE_USER'
