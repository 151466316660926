import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import selectAgentsForGroupId from 'selectors/agents/selectAgentsForGroupId'

import { BoxHeadline, Text } from 'shared/ui'

import styles from './styles.css'

const AGENTS_IN_GROUP_DISPLAY_LIMIT = 2

const groupAvatarLabel = ({ isAnyTeam, allAgents, agentCount }) => {
  if (isAnyTeam) {
    if (allAgents) {
      if (allAgents.length > 99) return '99+'
      else if (allAgents.length > 0) return `${allAgents.length}`
    }
    return '0'
  }
  if (agentCount > 99) return '99+'
  return `${agentCount || 0}`
}

const groupAgentsText = ({ isAnyTeam, allAgents, agents, agentCount }) => {
  const currentAgents = (isAnyTeam ? allAgents : agents) || []
  if (currentAgents) {
    const text = currentAgents
      .slice(0, AGENTS_IN_GROUP_DISPLAY_LIMIT)
      .map(agent => agent.username || agent.first_name)
      .join(', ')

    return agentCount > AGENTS_IN_GROUP_DISPLAY_LIMIT ? `${text}...` : text
  }
  return ''
}

const Label = ({ children, isCurrentChosen, className }) => {
  return (
    <BoxHeadline
      as="span"
      className={className}
      color={isCurrentChosen ? 'groovy' : undefined}
    >
      {children}
    </BoxHeadline>
  )
}

const GroupItem = ({
  agentCount,
  allAgents,
  isActive,
  isAnyTeam = false,
  isCurrentChosen,
  name,
  onClick,
  groupId,
}) => {
  const agents = useSelector(state =>
    selectAgentsForGroupId(state, groupId, AGENTS_IN_GROUP_DISPLAY_LIMIT)
  )

  return (
    <div
      className={cn(styles.itemOuter, {
        [styles.unassignedItemOuter]: isAnyTeam,
      })}
      onClick={onClick}
    >
      <div
        className={cn(styles.group, styles.item, {
          [styles.activeItem]: isActive,
          [styles.currentItem]: isCurrentChosen,
          [styles.unassignedItem]: isAnyTeam,
          currentChosen: isCurrentChosen,
        })}
      >
        {!isAnyTeam && (
          <div className={styles.group_avatar}>
            {groupAvatarLabel({ isAnyTeam, agentCount, allAgents })}
          </div>
        )}

        <div
          className={cn(styles.item_text, {
            [styles.item_text_anyone]: isAnyTeam,
          })}
        >
          <Label isCurrentChosen={isCurrentChosen}>{name}</Label>
          {!isAnyTeam && (
            <Text size="small" as="span" className="grui ml-4">
              {groupAgentsText({ isAnyTeam, allAgents, agents, agentCount })}
            </Text>
          )}
        </div>
        <span className={cn('Icon Icon-keyboard-return', styles.returnIcon)} />
      </div>
    </div>
  )
}

export default GroupItem
