import { useCallback, useMemo } from 'react'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION,
  DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION,
} from 'ducks/drawers/types'
import { Button } from 'util/ui'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function Footer({ integration, subscription }) {
  const { drawerId: cancelDrawerId, openDrawer: openCancelDrawer } = useDrawer({
    type: DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION,
  })
  const drawerResourceId = useMemo(
    () => `${subscription.customer_id}-${subscription.id}`,
    [subscription]
  )

  const {
    drawerId: reactivateDrawerId,
    openDrawer: openReactivateDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION,
  })

  const onCancelClick = useCallback(
    () => {
      openCancelDrawer(drawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            cancelDrawerId,
            'drawerIntegrationId',
            integration.id
          ),
        },
      })
    },
    [openCancelDrawer, cancelDrawerId, integration, drawerResourceId]
  )

  const onReactivateClick = useCallback(
    () => {
      openReactivateDrawer(drawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            reactivateDrawerId,
            'drawerIntegrationId',
            integration.id
          ),
        },
      })
    },
    [openReactivateDrawer, reactivateDrawerId, integration, drawerResourceId]
  )

  return (
    <NavigatableIntegrationWidgetCard.Footer>
      {subscription.status === 'active' && (
        <Button basic onClick={onCancelClick}>
          Cancel subscription
        </Button>
      )}

      {subscription.status === 'cancelled' && (
        <Button basic onClick={onReactivateClick}>
          Reactivate subscription
        </Button>
      )}
    </NavigatableIntegrationWidgetCard.Footer>
  )
}
