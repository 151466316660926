import { useCallback, useRef, useEffect, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { getLength } from 'util/arrays'
import { styles } from './styles'
import DropdownMenu from './DropdownMenu'

const internalDesignSystemDropdownOffset = [4, 5]

const RecipientDropdown = ({
  className,
  disabled,
  isSearching,
  onClick,
  onClose,
  recipients,
  trigger,
  input,
  onActivate,
}) => {
  const [visible, setVisible] = useState(false)
  // Active index from onSelect (KeyboardNavigator) always get the previous index when pressiong enter key
  // maybe it has to do with enter handler for SearchBox
  // Track the activated item by onActivate in DropdownMenu instead
  const activatedRecipientIdRef = useRef()
  const handleSelect = useCallback(
    (_index, evt) => {
      if (activatedRecipientIdRef.current) {
        onClick(activatedRecipientIdRef.current, evt)
      }
    },
    [onClick]
  )

  const handleVisibilityChange = useCallback(
    v => {
      setVisible(v)
      if (!v) {
        if (onClose) onClose()
      }
    },
    [onClose]
  )

  useEffect(
    () => {
      setVisible(!!(!disabled && (getLength(recipients) > 0 || isSearching)))
    },
    [disabled, recipients, isSearching]
  )

  return (
    <KeyboardNavigator
      focusElement={input}
      // Enter handler
      onSelect={handleSelect}
      className="grui h-auto"
      css={styles.recipientDropdown}
      supressDuplicateActivate
    >
      <Dropdown
        className={className}
        strategy="fixed"
        portal="#overlays"
        overlay={
          <DropdownMenu
            onSelect={handleSelect}
            onActivate={onActivate}
            ref={activatedRecipientIdRef}
            recipients={recipients}
          />
        }
        tabIndex="0"
        onVisibleChange={handleVisibilityChange}
        visible={visible}
        offset={internalDesignSystemDropdownOffset}
        // Make sure dropdown won't focus its children and blur the input in SearchBox
        isNavByArrowsDisabled
        menuItemIconHidden
      >
        <div>{trigger}</div>
      </Dropdown>
    </KeyboardNavigator>
  )
}

export default RecipientDropdown
