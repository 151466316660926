import React, { Fragment, useCallback, useState, useMemo } from 'react'
import ChevronRight from '@groovehq/internal-design-system/lib/assets/icons/ChevronRight'
import KeyValue from 'components/KeyValues/KeyValue'
import { styles } from './CollapsibleKeyValue.styles'

// nothing fancy or super extensible about this one, just takes a string/node array as items prop
// feel free to extend this if need be to accept other prop types for items
const CollapsibleKeyValue = ({
  title,
  missingValue,
  delimiter = ' ',
  items = [],
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onToggleExpand = useCallback(
    () => {
      setIsExpanded(v => !v)
    },
    [setIsExpanded]
  )

  const hasItems = !!items.length

  const value = useMemo(
    () => {
      if (isExpanded || !hasItems) return null

      return (items || []).filter(e => e).map((item, index) => (
        <Fragment key={item}>
          {item}
          {index < items.length - 1 && <>{delimiter}</>}
        </Fragment>
      ))
    },
    [hasItems, items, isExpanded, delimiter]
  )

  return (
    <KeyValue
      title={title}
      grouped={isExpanded}
      missingValue={!hasItems && missingValue}
      showActions
      actions={
        hasItems && (
          <ChevronRight
            css={[styles.arrow, isExpanded && styles.openedArrow]}
          />
        )
      }
      // using value prop so it does not push the chevron out of view
      // and also force the overflow ellipsis to show
      value={value}
      onClick={hasItems && onToggleExpand}
      onlyTopLineClickable
    >
      {isExpanded &&
        hasItems &&
        items.map(item => (
          <div key={item} className="groupedValue">
            <div className="value singleline">{item}</div>
          </div>
        ))}
    </KeyValue>
  )
}

export default CollapsibleKeyValue
