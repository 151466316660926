import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentContactOrCustomerEmail,
  selectCustomFieldValueContentForCustomFieldKeyAndCategoryType,
} from 'ducks/crm/contacts'
import { capture } from 'ducks/tracking/actions'
import { selectCustomerIsReloading } from 'ducks/integrations/recharge/selectors/customers'
import { selectIsCustomFieldsOrCategoriesLoading } from 'ducks/crm/customFields/selectors'

import doUpdateRechargeCustomFieldValues from 'ducks/integrations/recharge/operations/doUpdateRechargeCustomFieldValues'
import logo from 'assets/integrations/recharge.png'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

import RechargeIndex from './RechargeIndex'
import RechargeDetail from './RechargeDetail'

import { styles } from './styles'

export default function RechargeView({
  id,
  contactId,
  doHideCard,
  name,
  integration,
}) {
  const dispatch = useDispatch()
  const email = useSelector(selectCurrentContactOrCustomerEmail)
  const categoryType = 'CONTACT'
  const keyPrefix = `recharge_${integration.storeDomain}`

  const customerId = useSelector(state => {
    return selectCustomFieldValueContentForCustomFieldKeyAndCategoryType(
      state,
      {
        customFieldKey: `${keyPrefix}_id`,
        categoryType,
      }
    )
  })

  const isCustomFieldsOrCategoriesLoading = useSelector(state =>
    selectIsCustomFieldsOrCategoriesLoading(state)
  )

  const isCustomerReloading = useSelector(state =>
    selectCustomerIsReloading(state, integration.id, { customerId })
  )

  const onReload = useCallback(
    async event => {
      event.stopPropagation()
      await dispatch(
        doUpdateRechargeCustomFieldValues({
          integrationId: integration.id,
          contactId,
          customerId,
          email,
          keyPrefix,
        })
      )
      capture('Recharge Card Refreshed')
    },
    [dispatch, integration, contactId, customerId, email, keyPrefix]
  )

  return (
    <div css={styles.base}>
      <NavigatableIntegrationWidgetCard
        doHideCard={doHideCard}
        image={logo}
        indexComponent={RechargeIndex}
        detailComponent={RechargeDetail}
        isLoading={isCustomFieldsOrCategoriesLoading || isCustomerReloading}
        title={name || 'Recharge'}
        persistenceKey={`recharge-${id}`}
        refreshable
        onRefresh={onReload}
        integration={integration}
        hidePadding
      />
    </div>
  )
}
