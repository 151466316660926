import { useSelector } from 'react-redux'

import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import { selectIsCustomerLoadingForEmail } from 'ducks/integrations/hubspot/selectors'
import { Loader } from 'shared/ui'

import Company from './Company'
import Contact from './Contact'
import CreateHubSpotContactButton from './CreateHubSpotContactButton'
import ListsIndex from './ListsIndex'
import DealsIndex from './DealsIndex'

export default function HubSpotIndex({
  id,
  className,
  contactObject,
  email,
  doNavigatableSetState,
  ownersById,
  settings,
}) {
  const isLoading = useSelector(state => {
    return selectIsCustomerLoadingForEmail(state, { email })
  })
  if (
    contactObject
      ? contactObject.error && contactObject.error.code === 404
      : !isLoading
  ) {
    return (
      <>
        <EmptyMessage>No contact found.</EmptyMessage>
        <CreateHubSpotContactButton />
      </>
    )
  }
  return (
    <div className={className}>
      <Contact
        contactObject={contactObject}
        ownersById={ownersById}
        settings={settings}
      />
      <ListsIndex id={id} email={email} />
      <Company
        id={id}
        email={email}
        ownersById={ownersById}
        settings={settings}
      />
      <DealsIndex
        id={id}
        email={email}
        doNavigatableSetState={doNavigatableSetState}
        ownersById={ownersById}
        settings={settings}
      />
      {isLoading && <Loader />}
    </div>
  )
}
