import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'
import bg from 'assets/login-bg.png'

const loginPage = theme => css`
  font-family: ${theme.font.tertiary};
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .sui-inner {
    overflow: unset;
  }

  * {
    letter-spacing: -0.02em;
  }

  .ui.label {
    letter-spacing: normal;
  }

  & > * {
    flex: 1;
  }
`

const blogContainer = theme => css`
  background: #eef0ff url(${bg}) left center/cover no-repeat;
  position: sticky;
  top: 0;

  @media only screen and (max-width: ${theme.breakpoints.computerBreakpoint}) {
    &&& {
      display: none;
    }
  }
`

const blog = theme => css`
  color: rgba(${theme.color.info.black_rgba}, 0.6);
  font-size: ${theme.fontSize.x2large};
  line-height: ${theme.lineHeight.leadingSnug};
  max-width: ${rem(315)};
  width: 100%;
`

const label = theme => css`
  font-size: ${theme.fontSize.x4large};
  text-align: center;
`

const blogCard = theme => css`
  display: block;
  border-radius: ${rem(20)};
  margin: ${rem(48)} 0;
  background-color: ${theme.color.monochrome.white};
  overflow: hidden;
  box-shadow: 0px 149px 42px 0px rgba(${theme.color.info.black_rgba}, 0),
    0px 96px 38px 0px rgba(${theme.color.info.black_rgba}, 0.01),
    0px 54px 32px 0px rgba(${theme.color.info.black_rgba}, 0.03),
    0px 24px 24px 0px rgba(${theme.color.info.black_rgba}, 0.04),
    0px 6px 13px 0px rgba(${theme.color.info.black_rgba}, 0.05);

  &:hover {
    h4 {
      text-decoration: underline;
    }
  }
`

const blogCardHeading = theme => css`
  font-size: ${theme.fontSize.x4large};
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.info[900]};
  margin-top: 0;
  margin-bottom: ${rem(8)};
`

const copy = css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const btnBlog = theme => css`
  border-radius: ${rem(200)};
  color: rgba(${theme.color.info.black_rgba}, 0.6);
  border-color: currentColor;
  background-color: transparent;
  min-width: ${rem(170)};

  &:hover {
    background-color: transparent;
  }
`

export const styles = {
  loginPage,
  blogContainer,
  blog,
  label,
  blogCard,
  btnBlog,
  blogCardHeading,
  copy,
}
