import React from 'react'
import cn from 'classnames'
import { styles } from './TransactionRow.styles'

const TransactionRow = ({
  paymentIcon,
  formattedGateway,
  accountNumber,
  className,
}) => {
  const { altText, url } = paymentIcon || {}

  return (
    <div className={cn('grui flex items-center mt-2', className)}>
      {url && (
        <>
          <img alt={altText} src={url} css={styles.paymentIcon} /> &nbsp;
        </>
      )}
      <span>{formattedGateway}</span>
      {accountNumber && <span>&nbsp;({accountNumber})</span>}
    </div>
  )
}

export default TransactionRow
