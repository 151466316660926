import { isFeatureEnabled } from 'ducks/tracking/actions'

const FeatureGate = ({ flag, children }) => {
  if (!flag) throw new Error('FeatureGate: flag prop not provided')
  const isEnabled = isFeatureEnabled(flag)

  if (!isEnabled) return null

  return <>{children}</>
}

export default FeatureGate
