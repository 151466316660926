import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { SelectableItem, Text, BoxHeadline, Flex } from 'shared/ui'

const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Container = styled(Flex)`
  ${truncate};
`

const ListItem = ({
  className,
  isFocused,
  email,
  secondaryEmails = [],
  name,
  onClick = null /* when row/label or checkbox input clicked */,
}) => {
  return (
    <SelectableItem
      className={cn('grui py-4 pl-12 pr-10', className)}
      content={
        <Container className="grui flex-col">
          <BoxHeadline truncate>{name}</BoxHeadline>
          <Text size="small" truncate>
            {email}
          </Text>
          {secondaryEmails.map(secEmail => (
            <Text key={secEmail} size="small" truncate>
              {secEmail}
            </Text>
          ))}
        </Container>
      }
      onContainerClick={onClick}
      selected={isFocused}
    />
  )
}

export default styled(ListItem)`
  display: block;
`
