// allow simple wrappers
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import cn from 'classnames'
import { css } from '@emotion/react'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { ButtonWithDropdown } from 'shared/ui'
import DropdownSwitcher from './DropdownSwitcher'

// HACK (jscheel): Reskinning a sui component for now
const replyStyle = theme => css`
  background-color: ${theme.color.buttonInfo.background};
  border-color: ${theme.color.buttonInfo.border};
  color: ${theme.color.buttonInfo.text};

  &:hover {
    background-color: ${theme.color.buttonInfo.backgroundHover};
    border-color: ${theme.color.buttonInfo.borderHover};
    color: ${theme.color.buttonInfo.textHover};
  }

  &:active {
    background-color: ${theme.color.buttonInfo.backgroundPressed};
    border-color: ${theme.color.buttonInfo.borderPressed};
    color: ${theme.color.buttonInfo.textPressed};
  }

  &[disabled] {
    background-color: ${theme.color.buttonInfo.backgroundDisabled};
    border-color: ${theme.color.buttonInfo.borderDisabled};
    color: ${theme.color.buttonInfo.textDisabled};
  }
`

const noteStyle = theme => css`
  background-color: ${theme.color.buttonNote.background};
  border-color: ${theme.color.buttonNote.border};
  color: ${theme.color.buttonNote.text};

  &:hover {
    background-color: ${theme.color.buttonNote.backgroundHover};
    border-color: ${theme.color.buttonNote.borderHover};
    color: ${theme.color.buttonNote.textHover};
  }

  &:active {
    background-color: ${theme.color.buttonNote.backgroundPressed};
    border-color: ${theme.color.buttonNote.borderPressed};
    color: ${theme.color.buttonNote.textPressed};
  }

  &[disabled] {
    background-color: ${theme.color.buttonNote.backgroundDisabled};
    border-color: ${theme.color.buttonNote.borderDisabled};
    color: ${theme.color.buttonNote.textDisabled};
  }
`

const baseButtonStyle = theme => css`
  .sui .sui-inner {
    .ui.buttons {
      border-radius: 2rem;
      background: none !important;
      box-shadow: none;
      transition: box-shadow 0.2s ease-in-out;
      &:hover,
      &:active,
      &:disabled {
        background: none !important;
      }
      &:focus-within {
        box-shadow: 0 0 4px 0 ${theme.color.buttonInfo.backgroundHover}99;
      }
    }
    .ui.button {
      ${replyStyle(theme)};
      border-radius: 2rem 0 0 2rem !important;
      font-family: ${theme.font.primary};
      font-weight: ${theme.fontWeight.medium};
      height: 32px;
    }

    .ui.button.dropdown {
      ${replyStyle(theme)};
      border-radius: 0 2rem 2rem 0 !important;
      padding-left: 10px !important;
    }
    .ui.button .dropdown.icon {
      border-left: none !important;
    }

    /* HACK (jscheel): SUI is positioning our menu wrong, just forcing it for now since we will switch to internal-design-system eventually */
    .ui.buttons > .ui.dropdown:last-child .menu {
      left: 0 !important;
      right: auto !important;
    }

    .without-dropdown .ui.button {
      border-radius: 2rem !important;
    }
  }
`

const noteButtonStyle = theme => css`
  .sui .sui-inner {
    .ui.buttons:focus-within {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    }
    .ui.button {
      ${noteStyle(theme)};
      border-radius: 2rem 0 0 2rem !important;
    }
    .ui.button.dropdown {
      ${noteStyle(theme)};
      border-radius: 0 2rem 2rem 0 !important;
    }
  }
`

const ButtonDisableWrapper = ({ className, disabled, children, passRef }) => {
  return (
    <div className={cn(className, disabled ? 'disabled' : null)} ref={passRef}>
      {children}
    </div>
  )
}

const ButtonDisableWrapperStyled = styled(ButtonDisableWrapper)`
  transition: opacity 200ms ease-in-out;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

/**
 * Serg: The code is morally wrong and disgusting but doing it properly will
 * take time and we need to ship this now. If you still see this in 3 months
 * (i.e. after March 2019), feel free to curse and shame me.
 */
class Button extends React.PureComponent {
  state = { dropdownOpen: false }

  getButtonText() {
    const { actionLabel, draft, isOpen, isNote, prefersOpen } = this.props

    if (isNote && actionLabel) {
      return actionLabel
    }

    if (draft && draft.automaticActions) {
      const stateAction = draft.automaticActions.find(a => a.type === 'status')
      const snoozeAction = draft.automaticActions.find(
        a => a.type === 'snooze_until'
      )

      if (stateAction) {
        switch (stateAction.value) {
          case 'opened': {
            return `${actionLabel} & ${isOpen ? 'leave opened' : 'Open'}`
          }
          case 'closed': {
            return `${actionLabel} & ${isOpen ? 'leave closed' : 'Close'}`
          }
          case 'spam': {
            return `${actionLabel} & Spam`
          }
          default:
        }
      }

      if (snoozeAction) {
        return `${actionLabel} & Snooze`
      }
    }

    return `${actionLabel} & ${prefersOpen ? 'Open' : 'Close'}`
  }

  closeDropdown = () => this.setState({ dropdownOpen: false })

  openDropdown = () => {
    const { disabled } = this.props
    if (!disabled) this.setState({ dropdownOpen: true })
  }

  toggleDropdown = () =>
    this.state.dropdownOpen ? this.closeDropdown() : this.openDropdown()

  handleDropdownClick = e => {
    const clickedOption = e.target.closest('.item[role="option"]')
    const isSnoozeOption =
      clickedOption && /snooze/.test(clickedOption.textContent)

    if (clickedOption && isSnoozeOption) return false

    return this.toggleDropdown()
  }

  handleSnoozeOptionClick = async (snoozeUntil, evt) => {
    const {
      onSnoozeOptionClick,
      resetAutomaticActionStatus,
      draft,
    } = this.props

    if (onSnoozeOptionClick) {
      await resetAutomaticActionStatus(draft)
      await onSnoozeOptionClick(snoozeUntil, evt)
    }
    this.closeDropdown()
  }

  handleSendClosedClick = async () => {
    const { onSendClosedClick, resetAutomaticActionStatus, draft } = this.props

    await resetAutomaticActionStatus(draft)
    onSendClosedClick()
  }

  handleSendOpenClick = async () => {
    const { onSendOpenClick, resetAutomaticActionStatus, draft } = this.props

    await resetAutomaticActionStatus(draft)
    onSendOpenClick()
  }

  handleButtonClick = () => {
    const {
      disabled,
      onSendClosedClick,
      onSendOpenClick,
      onSendClick,
      prefersOpen,
      isNote,
    } = this.props

    if (disabled) return

    if (isNote && onSendClick) {
      onSendClick()
    } else if (prefersOpen) {
      onSendOpenClick()
    } else {
      onSendClosedClick()
    }

    this.closeDropdown()
  }

  containerRef = node => (this.container = node)

  findTrigger = () =>
    this.container && this.container.querySelector('.dropdown.button')

  render() {
    const {
      actionLabel,
      className,
      disabled,
      isOpen,
      isNote,
      prefersOpen,
      dropdownVisible = true,
    } = this.props
    const { dropdownOpen } = this.state

    return (
      <ButtonDisableWrapperStyled
        disabled={disabled}
        passRef={this.containerRef}
        css={[baseButtonStyle, isNote && noteButtonStyle]}
        className={className}
      >
        <ListenToKeyboard
          onEscape={this.closeDropdown}
          enabled={dropdownOpen}
        />
        <ButtonWithDropdown
          className={cn({ 'without-dropdown': !dropdownVisible })}
          // color={isNote ? 'golden' : themeVars.groovyColorName}
          dropdown={
            dropdownVisible && (
              <DropdownSwitcher
                actionLabel={actionLabel}
                isOpen={isOpen}
                onBlur={this.closeDropdown}
                onClick={this.handleDropdownClick}
                onSendClosedClick={this.handleSendClosedClick}
                onSendOpenClick={this.handleSendOpenClick}
                onSnoozeOptionClick={this.handleSnoozeOptionClick}
                open={dropdownOpen}
                openOnFocus={false}
                prefersOpen={prefersOpen}
                isNote={isNote}
              />
            )
          }
          onClick={this.handleButtonClick}
        >
          {this.getButtonText()}
        </ButtonWithDropdown>
      </ButtonDisableWrapperStyled>
    )
  }
}

Button.propTypes = {
  /* Reply dropdown action prefix e.g. "Reply" as in "Reply & blah" */
  actionLabel: PropTypes.string.isRequired,
  /* Whether the dropdown menu is disabled */
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onSendClick: PropTypes.func.isRequired,
  onSendOpenClick: PropTypes.func,
  onSendClosedClick: PropTypes.func,
  /* onClick handler for snooze menu options */
  onSnoozeOptionClick: PropTypes.func,
  prefersOpen: PropTypes.bool.isRequired,
}

Button.defaultProps = {
  disabled: false,
}

export default Button
