import { useMemo } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Product from '@groovehq/internal-design-system/lib/components/Shopify/Product/Product'
import DropdownMenuItem from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenuItem'
import { moneyString } from 'util/currency'
import { formatNumberWithSeparators } from 'util/numbers'

import { styles } from './styles'

const ProductItem = ({
  name,
  image,
  sku,
  stock,
  currency,
  price,
  orderItems,
  variantId,
}) => {
  const alreadyAdded = useMemo(() => orderItems.some(i => i.id === variantId), [
    orderItems,
    variantId,
  ])

  return (
    <DropdownMenuItem
      itemKey={variantId}
      disabled={alreadyAdded}
      css={styles.menuItem}
    >
      <Product.Image image={image} alt={name} />
      <Product name={name} css={styles.product}>
        {sku}
      </Product>
      <span css={[styles.stock, alreadyAdded && text.styles.italic]}>
        {alreadyAdded
          ? 'Item already added'
          : `${formatNumberWithSeparators(stock)} in stock`}
      </span>
      <span className="grui flex-1 text-right">
        {moneyString({ amount: price * 100, currencyCode: currency })}
      </span>
    </DropdownMenuItem>
  )
}

export default ProductItem
