// Dropdown Switcher that emulates an in-place 'sub-menu'
import React from 'react'
import { IconButton } from 'shared/ui'

const MoreButton = React.memo(
  ({ isChangeMenuOpen, isMoreOptionsOpen, openMoreDropdown, Dropdown }) => {
    if (!isMoreOptionsOpen && !isChangeMenuOpen) {
      return (
        <div className="more">
          <IconButton
            name="optionsHorizontal"
            size="medium"
            className="menu-button"
            onClick={openMoreDropdown}
            tooltip="More [.]"
            data-test-id="MoreMenuOpener"
            svg
          />
        </div>
      )
    }

    return (
      <div className="more" data-test-id="MoreMenu">
        <Dropdown
          trigger={
            <IconButton
              name="optionsHorizontal"
              size="medium"
              className="menu-button"
              svg
            />
          }
        />
      </div>
    )
  }
)

export default MoreButton
