import { connect } from 'react-redux'

import { doHandleUploadComplete, doUploadFiles } from 'actions/attachments'

import {
  selectDraftIdByTicketId,
  selectDraftPropByDraftId,
  makeDraftPropOrDefaultSelector,
  selectAttachmentsByDraftId,
} from 'ducks/drafts2/selectors'
import { doRealtimeAgentStartTicketTypingNotification } from 'actions/realtime'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
  doEnsureDefaults,
} from 'ducks/drafts2/operations'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { getAssignmentLabel } from 'components/App/DesktopView/CommunicationForms/shared/util'
import { emptyArr } from 'util/arrays'

import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import {
  selectShowMentionSelector,
  selectIsMentioningAgent,
  selectSelectedMentionSelectorAgentId,
  selectSuppressMentionSelector,
} from 'selectors/mentions'
import { doOpenTicketPage } from 'actions/pages'
import { doUpdateAgentMention } from 'actions/app'
import { doMentionAgent } from 'actions/tickets'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { selectPrefersAiEnabled } from 'selectors/app/selectAccountPreferences'

import View from './view'

const select = () => {
  return (state, props) => {
    const { ticketId } = props
    const ticket = selectRawTicket(state, ticketId)
    const draftId = selectDraftIdByTicketId(state, ticketId, 'note')

    const selectDraftPropByName = makeDraftPropOrDefaultSelector(
      state,
      draftId,
      ticketId
    )

    const assigneeId = selectDraftPropByName('assigneeId')
    const assigneeGroupId = selectDraftPropByName('assigneeGroupId')
    const serverVersion = selectDraftPropByName('serverVersion')
    const version = selectDraftPropByName('version')

    const showKbSearch = selectShowKbSearchOnInboxEditor(state)

    return {
      draftId,
      defaultsSet: selectDraftPropByDraftId(state, draftId, 'defaultsSet'),
      defaultsAvailable: !!ticket.draftDefaults,
      syncable: !!draftId,
      isSynced: serverVersion === version,
      assignmentLabel: getAssignmentLabel(state, assigneeId, assigneeGroupId),
      assigneeGroupId,
      mailboxId: selectDraftPropByName('mailboxId'),
      to: selectDraftPropByName('to') || emptyArr,
      subject: selectDraftPropByName('title') || ticket.title || '',
      phone: selectDraftPropByName('phone_number'),
      replyType: selectDraftPropByName('replyType') || 'reply-all',
      isSendable: selectDraftPropByName('isSendable'),
      notSendableReason: selectDraftPropByName('notSendableReason'),
      showKbSearch,
      attachments: selectAttachmentsByDraftId(state, draftId),
      showMentionSelector: selectShowMentionSelector(state),
      isMentioning: selectIsMentioningAgent(state),
      selectedMentionSelectorAgentId: selectSelectedMentionSelectorAgentId(
        state
      ),
      suppressMentionSelector: selectSuppressMentionSelector(state),
      isNewTicket: selectIsComposingNewTicket(state),
      prefersAiEnabled: selectPrefersAiEnabled(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId, oldBody } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        'note',
        draftId,
        ticketId,
        messageId,
        fields,
        oldBody
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'note', draftId, ticketId, messageId, fields)
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'note', draftId, ticketId, messageId, fields)
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
    uploadFiles: (...args) => {
      dispatch(doUploadFiles(...args))
    },
    realtimeAgentStartTicketTypingNotification: (...args) => {
      dispatch(doRealtimeAgentStartTicketTypingNotification(...args))
    },
    doUpdateAgentMention: (...args) => {
      dispatch(doUpdateAgentMention(...args))
    },
    ensureDefaults: (draftId, forwarding) => {
      dispatch(doEnsureDefaults(draftId, 'note', forwarding))
    },
    openTicketPageWithReplying: ticketId => {
      dispatch(doOpenTicketPage(ticketId, { replying: true }))
    },
    doMentionAgent: (...args) => {
      dispatch(doMentionAgent(...args))
    },
    handleUploadComplete: (...args) =>
      dispatch(doHandleUploadComplete(...args)),
  }
}

export default connect(select, perform)(View)
