import { connect } from 'react-redux'
import { selectGroup } from 'selectors/app'

const GroupLabel = connect((initialState, initialProps) => state => {
  const found = selectGroup(state, initialProps.groupId)
  return {
    label: found ? found.name || null : null,
  }
})(({ label }) => <React.Fragment>{label}</React.Fragment>)

export default GroupLabel
