import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tag from '@groovehq/internal-design-system/lib/components/Tag/Tag'
import { selectCurrentSubmittedTicketSearchFilterMatches } from 'selectors/search/searchFilters'
import { selectCurrentCommittedTicketSearchQueryString } from 'selectors/search'
import { doClearSearch, doSubmitTicketsSearch } from 'actions/search'
import { selectCustomersByEmail } from 'ducks/customers/selectors'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import { propFunc } from 'util/functions'
import { _processQueryStringPart } from 'util/search'
import { getMailboxNameOrEmail } from 'util/mailboxes'
import { styles } from '../styles'

const FilterBadges = () => {
  const dispatch = useDispatch()
  const currentCommittedTicketSearchQueryString = useSelector(
    selectCurrentCommittedTicketSearchQueryString
  )
  const matches = useSelector(selectCurrentSubmittedTicketSearchFilterMatches)
  const customersByEmail = useSelector(selectCustomersByEmail)
  const mailboxes = useSelector(selectKnownMailboxes)
  const mailboxsById = useSelector(selectMailboxesById)

  const handleRemoveFilter = useCallback(
    filter => {
      const remainingSubmittedFilters = matches.filter(item => item !== filter)
      dispatch(
        doSubmitTicketsSearch(
          [
            ...remainingSubmittedFilters,
            currentCommittedTicketSearchQueryString,
          ]
            .filter(Boolean)
            .join(' '),
          { shouldIncludeSearchMailboxes: false }
        )
      )
    },
    [dispatch, matches, currentCommittedTicketSearchQueryString]
  )

  const amendFilterBadge = useCallback(
    match => {
      const filter = _processQueryStringPart(match)
      const filterName = `${filter.key}:`
      const value = filter.values[0]
      if (filterName === 'from:') {
        // Amend from: filter to include customer name
        const customer = customersByEmail[value]
        if (!customer?.name) return match
        return [filter.key, `${customer.name} (${value})`].join(':')
      }
      if (filterName === 'inbox:') {
        // Amend inbox: filter to show mailbox name
        // if there're multiple mailboxes with the same name, show email as well
        const mailbox = mailboxsById[value]
        if (!mailbox) return match
        const mailboxName = getMailboxNameOrEmail(mailbox, mailboxes, {
          nameWithEmail: true,
        })
        return [filter.key, mailboxName].join(':')
      }
      return match
    },
    [customersByEmail, mailboxes, mailboxsById]
  )

  const handleClear = useCallback(
    e => {
      dispatch(doClearSearch(e, { shouldBlur: true }))
    },
    [dispatch]
  )

  if (!matches.length) return null

  return (
    <div
      className="grui p-5 pl-8 flex flex-wrap items-center"
      css={styles.filterBadges}
    >
      <Button size="small" type="secondary" onClick={handleClear}>
        Clear search filters
      </Button>
      {matches.map((match, index) => (
        <Tag
          closable
          css={styles.filterBadge}
          key={match}
          onClose={propFunc(handleRemoveFilter, match, index)}
        >
          {amendFilterBadge(match)}
        </Tag>
      ))}
    </div>
  )
}

export default FilterBadges
