import { css, keyframes } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const slideUp = keyframes`
  0% {
    transform: translate3d(0, 300px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

const positionBase = theme => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 ${theme.spacing['10']};
  z-index: 96;
  overflow: hidden;
`

const position = theme => css`
  padding: 0 ${theme.spacing['20']};
`

const positionMini = theme => css`
  padding: 0 ${theme.spacing['10']};
`

const containerBase = theme => css`
  animation: 0.4s ease-out ${slideUp};
  background-color: ${theme.color.info[50]};
  color: ${theme.color.info['900']};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.semibold};
  margin: 0 auto;

  h2 {
    font-family: ${theme.font.primary};
    font-weight: ${theme.fontWeight.bold};
  }

  button {
    font-size: ${theme.fontSize.large};
    font-weight: ${theme.fontWeight.semibold};
    span {
      margin-right: ${rem(8)};
      font-size: ${theme.fontSize.xlarge};
    }
  }
`

const container = theme => css`
  padding: ${rem(40)} ${rem(12)} ${rem(40)} ${rem(64)};
  border-radius: ${rem(24)} ${rem(24)} 0 0;
  max-width: 855px;

  @media (max-width: 1249px) {
    padding: ${rem(40)} ${rem(24)};
    max-width: none;
    align-content: center;
    img {
      display: none;
    }
  }

  h2 {
    font-size: 46px;
    margin: 0 0 ${theme.spacing['11']} 0;
  }

  p {
    margin: 0 0 ${theme.spacing['11']} 0;
  }

  button {
    max-width: ${rem(312)};
  }

  img {
    align-self: center;
    transform: translateY(8px);
    max-width: 100%;
    height: auto;
  }
`

const containerMini = theme => css`
  padding: ${rem(24)} ${rem(12)};
  border-radius: ${rem(6)};
  border: 10px transparent;
  max-width: 310px;
  font-weight: ${theme.fontWeight.normal};
  text-align: center;

  h2 {
    font-size: ${theme.fontSize.x5large};
    margin: 0 0 ${theme.spacing['9']} 0;
  }

  p {
    margin: 0 0 ${theme.spacing['11']} 0;
  }

  button {
    width: 95%;
  }

  img {
    display: none;
  }
`

const content = css`
  display: flex;
  flex-basis: 350px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 1249px) {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    text-align: center;
  }
`

const contentMini = css`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  text-align: center;
`

export const styles = {
  positionBase,
  position,
  positionMini,
  containerBase,
  container,
  containerMini,
  content,
  contentMini,
}
