import { css } from '@emotion/react'

const base = theme => css`
  background: ${theme.color.monochrome.superLight};
  color: ${theme.color.monochrome.mediumDark};

  svg {
    width: 100px;
    height: 100px;
  }
`

export const styles = {
  base,
}
