import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import TrashIcon from '@groovehq/internal-design-system/lib/assets/icons/Trash'

// global
import { Flex } from 'shared/ui'
import { runOnNextTick } from 'util/functions'
import { isCmdEnter } from 'util/keys'

// re-used
import NavBar from 'components/App/DesktopView/shared/Composer/Email/Header/NavBar'
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import AssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge'
import ReplyStateIndicator from 'components/ReplyStateIndicator'
import SearchKb from 'subapps/ticketing/components/TicketSearchKb'

// shared
import SendButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/SendButton'
import AssignmentDropdown from 'components/App/DesktopView/CommunicationForms/Reply/shared/AssignmentDropdown'

// styles
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'

// local
import Header from './Header'
import Editor from './EditorView'
import Sizer from '../../shared/Sizer'
import PinButton from '../shared/PinButton'

class ReplyFacebookForm extends React.PureComponent {
  static propTypes = {
    draftId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.footerRef = React.createRef()
  }

  state = {}

  componentDidMount() {}

  // We need to do this, because we cannot hide the editor until changesets are fully loaded, so it is possible to start a draft _without_ defaults. Which is why we need to detect the moment the defaults are available and use doEnsureDefaults to safely set them.
  componentDidUpdate() {
    const {
      defaultsAvailable,
      draftId,
      defaultsSet,
      doEnsureDefaults,
    } = this.props
    if (draftId && !defaultsSet && defaultsAvailable) {
      runOnNextTick(() => doEnsureDefaults(draftId))
    }
  }

  onEditorContentChange = text => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    if (!draftId && !text) return

    debouncedHandleDraftChange(ticketId, 'body', text, {
      draftId,
    })
  }

  onAgentChange = (_, groupId, agentId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeId', agentId, {
      draftId,
    })
  }

  onGroupChange = (_, groupId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeGroupId', groupId, {
      draftId,
    })
  }

  onSubjectChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    debouncedHandleDraftChange(ticketId, 'title', value, {
      draftId,
    })
  }

  onMailboxClick = mailboxId => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'mailboxId', mailboxId, {
      draftId,
    })
  }

  onSendAndOpen = () => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: 'opened', isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndClose = () => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: 'closed', isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndSnooze = snoozeUntil => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      forwarding,
    } = this.props

    handleDraftMultiChange(
      ticketId,
      { snoozeUntil, isForwarding: forwarding },
      { draftId }
    )
    sendDraft(draftId)
  }

  onDeleteClick = () => {
    const { deleteDraft, ticketId, draftId } = this.props
    deleteDraft(ticketId, draftId)
  }

  handleOnKeyDownEvent = e => {
    if (isCmdEnter(e)) {
      e.preventDefault()
      e.stopPropagation()
      if (this.props.prefersOpen) {
        return this.onSendAndOpen()
      }
      return this.onSendAndClose()
    }
    return true
  }

  render() {
    const {
      ticketId,
      draftId,
      assignmentLabel,
      assigneeGroupId,
      isSendable,
      forwarding,
      isOpen,
      facebookRecipient,
      showKbSearch,
      onEditorInit,
      prefersOpen,
    } = this.props
    return (
      <div
        id="composer"
        className={styles.form}
        data-test-id="reply-editor-facebook"
      >
        <Sizer
          className={forwarding ? 'forwarding' : ''}
          footerRef={this.footerRef}
        >
          <div className="form-header-wrapper">
            <AnimatePresence initial={false}>
              {showKbSearch && (
                <motion.div
                  layout
                  initial={{ height: 0, overflow: 'hidden' }}
                  animate={{
                    height: 'auto',
                    transitionEnd: { overflow: 'visible' },
                  }}
                  exit={{ height: 0, overflow: 'hidden' }}
                  transition={{ ease: 'easeInOut', duration: 0.2 }}
                >
                  <SearchKb className="grui mb-5 search-kb" />
                </motion.div>
              )}
            </AnimatePresence>
            <div className="form-header">
              <div className="envelope-section">
                <Header
                  className="facebook-recipients"
                  label={'Reply to'}
                  facebookRecipient={facebookRecipient}
                />
              </div>
              <NavBar
                replying
                ticketId={ticketId}
                onExpandClick={this.onExpandedHeaderOpen}
                onReplyClick={this.onReplyClick}
                onForwardClick={this.onForwardClick}
                hideForward
                className="extras-section"
              />
              <PinButton />
            </div>
          </div>
          <div className="form-editor-wrapper">
            <Flex
              className={cn('editorAndAttachments grui flex-col flex-2-1-auto')}
            >
              <Editor
                className="toolbar-bottom"
                ticketId={ticketId}
                draftId={draftId}
                onChange={this.onEditorContentChange}
                onKeyDown={this.handleOnKeyDownEvent}
                onInit={onEditorInit}
              />
            </Flex>
          </div>
          <div className="form-footer-wrapper" ref={this.footerRef}>
            <div className="form-footer">
              <div className="form-footer-content">
                <div className="grui flex flex-grow">
                  <div className="sendButtonContainer">
                    <SendButton
                      actionLabel="Reply"
                      disabled={!isSendable}
                      isOpen={isOpen}
                      ticketId={ticketId}
                      draftId={draftId}
                      onSendOpenClick={this.onSendAndOpen}
                      onSendClosedClick={this.onSendAndClose}
                      onSnoozeOptionClick={this.onSendAndSnooze}
                      prefersOpen={prefersOpen}
                    />
                    <ReplyStateIndicator
                      className={'collisionIndicator'}
                      hideDraftIndicator
                      conversationId={ticketId}
                    />
                  </div>
                  <div className="left-side">
                    <div className="actions">
                      <EmojiPicker />
                      <AssignmentDropdown
                        direction="left"
                        drilledDownGroupId={assigneeGroupId}
                        key="assignment"
                        onGroupSelect={this.onGroupChange}
                        onAgentSelect={this.onAgentChange}
                        trigger={
                          <div className="left-divider">
                            <AssignmentBadge
                              assignmentLabel={assignmentLabel}
                              size="small"
                              iconSize={24}
                              tooltipPosition="top"
                            />
                          </div>
                        }
                        upward
                      />
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="actions">
                      <Tooltip
                        title="Delete reply"
                        position="top"
                        strategy="fixed"
                      >
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.onDeleteClick}
                          className="deleteButton"
                          data-test-id="DraftDeleteButton"
                        >
                          <TrashIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sizer>
      </div>
    )
  }
}

export default ReplyFacebookForm
