import React from 'react'
import styled from '@emotion/styled'

import { pluralize } from 'util/strings'

const Header = ({ className, attachments }) => {
  return (
    <div className={className}>
      <div className="cutout">
        {attachments.length} {pluralize(attachments.length, 'Attachment')}
      </div>
    </div>
  )
}

export default styled(Header)`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.color.monochrome.medium};
  padding: 12px 0;

  .cutout {
    color: ${props => props.theme.color.monochrome.black};
    position: relative;
    display: inline-block;
    padding-right: ${props => props.theme.spacing['4']};
  }

  @media print {
    margin-bottom: ${props => props.theme.spacing['5']};
  }
`
