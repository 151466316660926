import React, { useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { ThemeProvider } from '@emotion/react'
import grooveTheme from '@groovehq/internal-design-system/lib/theme/groove'
import { GlobalStyles } from '@groovehq/internal-design-system/lib/styles/global'
import Title from 'components/App/Title'
import Tracking from 'components/App/Tracking'
import ImpersonationBar from 'components/App/ImpersonationBar'
import SnackbarSwitcher from 'components/App/shared/SnackbarSwitcher'
import Mobile from 'components/Mobile'
import NpsSurvey from 'components/NpsSurvey'
import { isMobile, isSafari10, isIE11, isSafari } from 'util/browser'
import { initFavico } from 'util/favicon'
import { useRenderDrawers } from 'ducks/drawers/hooks/useRenderDrawers'
import selectIsCurrentAppBootstrapped from 'selectors/app/selectIsCurrentAppBootstrapped'
import { selectPreferredInboxTheme } from 'ducks/currentUser/selectors/preferences/selectPreferredInboxTheme'
import { doRefreshIfNecessary } from 'ducks/tokens/actions'
import { useTrackingContext } from 'ducks/tracking/context'
import Page from './Page'

import './sui_base.less'
import './GrooveIcons.font'
import './global.less'

const showMobilePage = isMobile()

const App = () => {
  const isCurrentAppBootstrapped = useSelector(selectIsCurrentAppBootstrapped)
  const preferredInboxTheme = useSelector(selectPreferredInboxTheme)
  const dispatch = useDispatch()
  useTrackingContext()

  useEffect(
    () => {
      document.documentElement.classList.toggle('safari', isSafari())
      document.documentElement.classList.toggle('safari10', isSafari10())
      document.documentElement.classList.toggle('ie11', isIE11())
      initFavico()

      // Check to see if the access token needs to be refreshed every 5 minutes.
      setInterval(() => {
        // If the token expires within the next hour then refresh.
        const timeOffset = Math.floor(Date.now() / 1000) + 1 * 60 * 60
        dispatch(doRefreshIfNecessary(timeOffset))
      }, 1000 * 60 * 5)
    },
    [dispatch]
  )

  const { Drawer, drawerProps } = useRenderDrawers()

  if (showMobilePage) {
    return (
      <ThemeProvider theme={grooveTheme[preferredInboxTheme]}>
        <GlobalStyles />
        <Mobile />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={grooveTheme[preferredInboxTheme]}>
      <GlobalStyles />
      <div id="app-inner" className="fullHeight grui flex flex-col">
        <Title />
        <Tracking />
        <Page />
        <ImpersonationBar />
        <SnackbarSwitcher />
        <NpsSurvey />
      </div>

      {isCurrentAppBootstrapped && (
        <AnimatePresence exitBeforeEnter>
          <Drawer {...drawerProps} />
        </AnimatePresence>
      )}
    </ThemeProvider>
  )
}

export default memo(App)
