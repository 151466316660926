import React, { PureComponent, useMemo } from 'react'
import { css, useTheme } from '@emotion/react'
import cn from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'
import { Tooltip } from 'shared/ui'
import { debounce, propFunc } from 'util/functions'
import { pluralize } from 'util/strings'
import { hasLowContrast } from 'util/colors'

const labelWithLightColor = theme => css`
  &&&& {
    color: ${theme.color.monochrome.white};
  }
`

const labelWithDarkColor = theme => css`
  &&&& {
    color: ${theme.color.monochrome.black};
  }
`

const labelStyleBackgroundColor = color => css`
  &&&& {
    background-color: ${color};
  }
`

function Labels({ labelsJSON }) {
  const theme = useTheme()
  const labels = useMemo(
    () => {
      return JSON.parse(labelsJSON)
    },
    [labelsJSON]
  )

  return (
    <>
      {labels.map(({ name, color }) => {
        const isTagColorSimilarToBackground = hasLowContrast(
          theme.color.monochrome.white,
          color
        )
        return (
          <span
            key={name}
            className="label"
            css={[
              labelWithLightColor,
              propFunc(labelStyleBackgroundColor, color),
              isTagColorSimilarToBackground && labelWithDarkColor,
            ]}
          >
            {name}
          </span>
        )
      })}
    </>
  )
}

function TooltipContainer({ labelsJSON, isHorizontalOverflow, labelIds }) {
  const theme = useTheme()
  const labels = useMemo(
    () => {
      return JSON.parse(labelsJSON)
    },
    [labelsJSON]
  )

  return (
    <Tooltip
      className="tooltip"
      position="bottom"
      tooltip={labels.map(({ name, color }) => {
        const isTagColorSimilarToBackground = hasLowContrast(
          theme.color.monochrome.white,
          color
        )

        return (
          <span
            key={`${name}-tooltip`}
            className="label"
            css={[
              labelWithLightColor,
              propFunc(labelStyleBackgroundColor, color),
              isTagColorSimilarToBackground && labelWithDarkColor,
            ]}
          >
            {name}
            <br />
          </span>
        )
      })}
    >
      <span className="more">
        +{' '}
        {isHorizontalOverflow
          ? `${labelIds.length} ${pluralize(labelIds.length, 'Tag')}`
          : 'more'}
      </span>
    </Tooltip>
  )
}

export default class LabelBox extends PureComponent {
  constructor(props) {
    super(props)
    this.labelBox = React.createRef()
    this.labelContainer = React.createRef()
    this.resizeTimeout = null
  }

  state = { isVerticalOverflow: false, isHorizontalOverflow: false }

  componentDidMount() {
    this.handleResize()
    this.observer = new ResizeObserver(this.handleResize)
    const labelBox = this.labelBox && this.labelBox.current
    this.observer.observe(labelBox)
  }

  componentWillUnmount() {
    this.removeObserver()
  }

  handleResize = debounce(() => {
    if (this.isUnmounting) return
    const labelContainer = this.labelContainer && this.labelContainer.current
    if (!labelContainer) return
    // Kevin R (2021-11-09)
    // https://trello.com/c/MBOaLxar/1645-tags-a-flashing
    // We recieved a video from a customer where the tags kept flicering between the isHorizontalOverflow state
    // Currently the only way I've been able to reproduce the issue reliably is by setting scrollWidth to 0 when
    // isHorizontalOverflow is true. I suspect there might be some brower bug causing this, but noone I've asked
    // can reproduce it, so we'll just patch the issue theoretically
    const { offsetWidth, scrollWidth } = labelContainer

    // Reproduce the disco tag issue mentione above
    // if (this.state.isHorizontalOverflow) scrollWidth = 0

    if (scrollWidth === 0 || offsetWidth === 0) return
    const isVerticalOverflow = labelContainer.scrollHeight > 24
    // happens when the last visible tag is too wide to fit into conversation list pane
    const isHorizontalOverflow = scrollWidth > offsetWidth

    if (
      isVerticalOverflow !== this.state.isVerticalOverflow ||
      isHorizontalOverflow !== this.state.isHorizontalOverflow
    ) {
      this.setState({ isVerticalOverflow, isHorizontalOverflow })
    }
  }, 200)

  removeObserver() {
    this.isUnmounting = true
    this.observer.disconnect()
    this.observer = null
  }

  render() {
    const { labelsJSON, labelIds } = this.props
    const { isVerticalOverflow, isHorizontalOverflow } = this.state

    return (
      <div className="labelbox" ref={this.labelBox}>
        <div className="labels" ref={this.labelContainer}>
          <div className={cn('labels-inner', { hidden: isHorizontalOverflow })}>
            <Labels labelsJSON={labelsJSON} />
          </div>
        </div>
        {(isVerticalOverflow || isHorizontalOverflow) && (
          <TooltipContainer
            labelsJSON={labelsJSON}
            isHorizontalOverflow={isHorizontalOverflow}
            labelIds={labelIds}
          />
        )}
      </div>
    )
  }
}
