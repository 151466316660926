import React from 'react'
import Editor from './Editor'

export default class EditorView extends React.PureComponent {
  onChange = text => {
    const { content, onChange } = this.props
    if (text !== content) onChange(text)
  }

  editorKey = () => {
    const { ticketId } = this.props
    return `reply-editor-ticket-${ticketId}`
  }

  // expansion controls whether the editor should update if we expand
  // the content key.
  // If we expand, it should not update.
  // If we shrink, we should update.
  editorKeyExpansion = () => {
    const { draftId } = this.props
    return draftId || null
  }

  render() {
    const {
      content,
      onKeyDown,
      showCharCount,
      getCountPrefix,
      onInit,
    } = this.props

    return (
      <Editor
        showCharCount={showCharCount}
        content={content}
        contentKey={this.editorKey()}
        contentKeyExpansion={this.editorKeyExpansion()}
        className="editor"
        onChange={this.onChange}
        onKeyDown={onKeyDown}
        onInit={onInit}
        getCountPrefix={getCountPrefix}
      />
    )
  }
}
