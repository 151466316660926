import React, { useCallback } from 'react'
import { string } from 'prop-types'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import { useController } from 'react-hook-form'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'

import { styles } from './styles'

const AdditionalInfo = ({
  integrationId,
  customerId,
  className,
  control,
  updateDraft,
  disabled,
}) => {
  const { isCalculating, isSending } = useShopifyDraft({
    integrationId,
    customerId,
  })
  const {
    field: { onChange: onNotesChange, value: notesValue },
  } = useController({
    name: 'notes',
    control,
  })

  const onNotesBlur = useCallback(
    e => {
      onNotesChange(e)
      updateDraft({ note: e.target.value })
    },
    [onNotesChange, updateDraft]
  )

  return (
    <div className={className} css={styles.base}>
      <Textarea
        name="notes"
        onChange={onNotesChange}
        onBlur={onNotesBlur}
        value={!notesValue ? '' : notesValue}
        label="Notes"
        placeholder="Add a note…"
        disabled={disabled || isCalculating || isSending}
        autoSize={{
          maxRows: 6,
          minRows: 3,
        }}
      />
    </div>
  )
}

AdditionalInfo.propTypes = {
  className: string,
  integrationId: string.isRequired,
  customerId: string.isRequired,
}

AdditionalInfo.defaultProps = {
  className: undefined,
}

export default AdditionalInfo
