import { connect } from 'react-redux'
import { doToggleSelectedTicketStates } from 'actions/tickets'
import { selectCommonStateForSelected } from 'selectors/ticket_list'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { getLength } from 'util/arrays'

import BulkStateBadge from './view'

const select = state => ({
  commonStateForSelected: selectCommonStateForSelected(state),
  count: getLength(selectSelectedTicketIds(state)),
})

const perform = dispatch => ({
  onStateBadgeClick: commonState => () => {
    dispatch(doToggleSelectedTicketStates(commonState))
  },
})

export default connect(select, perform)(BulkStateBadge)
