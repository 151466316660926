/* Stateful component that switches from the Default reply options DD, to the
 * Snooze DD. They share the same trigger to appear seamless
 * */
import React from 'react'
import PropTypes from 'prop-types'

import { isEdge } from 'util/browser'

import SnoozeDropdown from 'components/App/DesktopView/shared/Snooze'
import ReplyDropdown from './ReplyDropdown'
import NoteDropdown from './CommentingDropdown'

class DropdownSwitcher extends React.Component {
  state = { snoozeMenuVisible: false }

  getTrigger() {
    if (!this._trigger) this._trigger = <div />
    return this._trigger
  }

  showSnoozeMenu = () => {
    this.setState({ snoozeMenuVisible: true })
  }

  closeSnoozeMenu = () => {
    this.setState({ snoozeMenuVisible: false })
  }

  handleOnSnoozeOptionClick = (snoozeUntil, evt) => {
    const { onSnoozeOptionClick } = this.props
    if (onSnoozeOptionClick) onSnoozeOptionClick(snoozeUntil, evt)
    this.closeSnoozeMenu()
  }

  render() {
    const {
      actionLabel,
      className,
      disabled,
      isOpen,
      onBlur,
      onClick,
      onFocus,
      onSendClosedClick,
      onSendOpenClick,
      open,
      openOnFocus,
      prefersOpen,
      isNote,
    } = this.props

    const commonProps = {
      button: true,
      className,
      disabled,
      floating: true,
      onBlur,
      onClick,
      onFocus,
      open,
      openOnFocus,
      trigger: this.getTrigger(), // https://github.com/Semantic-Org/Semantic-UI-React/issues/1717
      upward: true,
      direction: 'left',
    }
    const { snoozeMenuVisible } = this.state
    if (snoozeMenuVisible) {
      return (
        <SnoozeDropdown
          {...commonProps}
          open
          spoofDropdownButton
          iconTrigger={false}
          suiWrap={false}
          onOptionClick={this.handleOnSnoozeOptionClick}
          onClose={this.closeSnoozeMenu}
          snoozeButtonLabel={`${actionLabel} & snooze`}
          closeOnBlur={
            // For some reason, click event in Edge takes longer to bubble
            // than in other browsers, so with "close on blur" enabled
            // dropdown automatically closes as soon as it is opened by user.
            // To fix this, we are not closing this dropdown on blur in Edge,
            // which isn't ideal, but tolerable UX.
            // eslint-disable-next-line no-unneeded-ternary
            isEdge() ? false : true
          }
        />
      )
    }

    const Dropdown = isNote ? NoteDropdown : ReplyDropdown

    return (
      <Dropdown
        {...commonProps}
        actionLabel={actionLabel}
        isOpen={isOpen}
        onSendOpenClick={onSendOpenClick}
        onSendClosedClick={onSendClosedClick}
        onSendSnoozedClick={this.showSnoozeMenu}
        prefersOpen={prefersOpen}
      />
    )
  }
}

DropdownSwitcher.propTypes = {
  /* Reply dropdown action prefix e.g. "Reply" as in "Reply & blah" */
  actionLabel: PropTypes.string,
  /* Whether the dropdown menu is disabled */
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onSendOpenClick: PropTypes.func.isRequired,
  onSendClosedClick: PropTypes.func.isRequired,
  onSnoozeOptionClick: PropTypes.func.isRequired,
  prefersOpen: PropTypes.bool.isRequired,
}

DropdownSwitcher.defaultProps = {
  actionLabel: '',
  disabled: false,
}

export default DropdownSwitcher
