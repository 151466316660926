import React, { useCallback, useEffect } from 'react'

import Sidebar from 'shared/components/ui/Sidebar'
import { Button, Flex, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { all } from 'util/arrays'
import { Form } from 'util/ui'

import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import {
  AssigneeFormField,
  ComponentsFormField,
  DescriptionFormField,
  LabelsFormField,
  ParentIssueFormField,
  PrioritiesFormField,
  ProjectFormField,
  SummaryFormField,
  TypesFormField,
} from './fields'

export default function LinkNewJiraServerIssueModal({
  areProjectsLoading,
  areSitesLoading,
  doCreateAndLinkJiraServerIssueToTicket,
  doFetchJiraServerProjects,
  formFields,
  formFields: { description, project = null, summary, type },
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
  projectId,
  resetFields,
  resetForm,
  siteId,
}) {
  const isLoading = areProjectsLoading || areSitesLoading
  const isFormValid = all(x => x && x !== '', [
    description,
    project,
    summary,
    type,
  ])
  const isProjectChoosen = !!project
  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [resetForm, onClose]
  )
  const doSubmitResetAndClose = useCallback(
    () => {
      doCreateAndLinkJiraServerIssueToTicket(siteId, projectId, formFields)
      doResetAndClose()
    },
    [
      doCreateAndLinkJiraServerIssueToTicket,
      doResetAndClose,
      siteId,
      projectId,
      formFields,
    ]
  )
  const onChange = useCallback(
    (event, { name, value }) => {
      propOnChange(name, value, null, { validate: true })
    },
    [propOnChange]
  )
  useEffect(
    () => {
      doFetchJiraServerProjects()
    },
    [doFetchJiraServerProjects]
  )
  return (
    <React.Fragment>
      <Button basic onClick={onOpen} className="grui ml-4 mt-4">
        New
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="create"
            portal
            position="top"
            tooltip={
              !isFormValid && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!isFormValid || isLoading}
              fluid
              key="link"
              onClick={doSubmitResetAndClose}
              primary
            >
              Create and link issue
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={doResetAndClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to New Jira Server Issue"
        onClose={doResetAndClose}
        open={open}
        wide
      >
        <ModalContent>
          {!isLoading && (
            <Form>
              {!areProjectsLoading && (
                <React.Fragment>
                  <Flex>
                    <ProjectFormField
                      formFields={formFields}
                      onChange={onChange}
                    />
                    <TypesFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      projectId={projectId}
                      resetFields={resetFields}
                      siteId={siteId}
                    />
                  </Flex>
                  <ParentIssueFormField
                    formFields={formFields}
                    onChange={onChange}
                    projectId={projectId}
                    resetFields={resetFields}
                    siteId={siteId}
                    type={type}
                  />
                  <SummaryFormField
                    formFields={formFields}
                    onChange={onChange}
                  />

                  <DescriptionFormField
                    formFields={formFields}
                    onChange={onChange}
                  />
                  <AssigneeFormField
                    formFields={formFields}
                    isProjectChoosen={isProjectChoosen}
                    onChange={onChange}
                    projectId={projectId}
                    resetFields={resetFields}
                    siteId={siteId}
                  />
                  <LabelsFormField
                    formFields={formFields}
                    isProjectChoosen={isProjectChoosen}
                    onChange={onChange}
                    siteId={siteId}
                  />

                  <Flex>
                    <ComponentsFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      projectId={projectId}
                      resetFields={resetFields}
                      siteId={siteId}
                    />
                    <PrioritiesFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      resetFields={resetFields}
                      siteId={siteId}
                    />
                  </Flex>
                </React.Fragment>
              )}
            </Form>
          )}
        </ModalContent>
        {isLoading && <Loader />}
      </Sidebar>
    </React.Fragment>
  )
}
