import { SINGLE_LINE } from 'ducks/crm/customFields'

import CustomFieldNestedSet from '../../CustomFieldNestedSet'
import FamilyAndVersion from './FamilyAndVersion'

const FIELD_PROPS = {
  browser: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_user_agent',
    displayAs: FamilyAndVersion,
    icon: 'window',
    nestedKey: 'browser',
    title: 'Browser',
  },
  platform: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_user_agent',
    displayAs: FamilyAndVersion,
    icon: 'windows',
    nestedKey: 'platform',
    title: 'Platform',
  },
  device: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_user_agent',
    displayAs: SINGLE_LINE,
    icon: 'mobile',
    nestedKey: 'device.family',
    title: 'Device',
  },
  language: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_browser_language',
    displayAs: SINGLE_LINE,
    icon: 'language',
    title: 'Browser language',
  },
  timezone: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_browser_time_zone',
    displayAs: SINGLE_LINE,
    icon: 'clock',
    title: 'Browser timezone',
  },
}

export default function BrowserInfo({ doHideCard }) {
  return (
    <CustomFieldNestedSet
      collection="browser_info"
      doHideCard={doHideCard}
      fields={FIELD_PROPS}
      title="Browser info"
    />
  )
}
