import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'

import { formatTime } from 'util/date'

import { Label } from 'shared/components/ui/Typography'

const Container = styled(Label)`
  white-space: nowrap;
`

export default function Time({ date, className }) {
  return (
    <Container
      size="medium"
      color="ultraLight"
      className={cn('time', className)}
    >
      {formatTime(date)}
    </Container>
  )
}
