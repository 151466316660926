import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { doOpenNewTicketPage } from 'actions/pages'
import { doSetFlag } from 'ducks/flags/operations'
import { HIDE_NEW_COMPOSER_CALLOUT } from 'ducks/flags/flagNames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Link from 'redux-first-router-link'
import { Edit } from '@groovehq/internal-design-system/lib/assets/icons'
import { styles as menuStyles } from '@groovehq/internal-design-system/lib/components/Menu/Menu.styles'
import NewLabel from '@groovehq/internal-design-system/lib/components/NewLabel/NewLabel'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectHideShouldNewComposerCallout } from 'ducks/flags/selectors'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import RefreshButton from '../Sidebar/RefreshButton'
import { styles } from './styles'

export const HeaderWithComposeButton = () => {
  const dispatch = useDispatch()
  const canCompose = useSelector(selectCurrentUserIsAdminOrOwnerOrAgent)
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const openNewConversationLink = useMemo(() => doOpenNewTicketPage(), [])
  const shouldHideNewComposerCallout = useSelector(
    selectHideShouldNewComposerCallout
  )

  const setHideNewComposerCallout = useCallback(
    () => {
      dispatch(doSetFlag(HIDE_NEW_COMPOSER_CALLOUT))
    },
    [dispatch]
  )

  return (
    <div className="grui block pl-10 pb-5 pt-10">
      <div className="grui flex items-start">
        {canCompose && (
          <Button
            as={Link}
            type="tertiary"
            size="small"
            css={styles.composeButton}
            customIcon={<Edit />}
            to={openNewConversationLink}
            onClick={
              !shouldHideNewComposerCallout
                ? setHideNewComposerCallout
                : undefined
            }
          >
            Compose
          </Button>
        )}
        {!shouldHideNewComposerCallout && (
          <NewLabel className="grui -mt-3" size="small" />
        )}
      </div>
      <div css={menuStyles.heading} className="grui mt-7 pr-10 pl-5">
        <h4 css={heading.styles.h5}>Inbox</h4>
        {!is3ColumnView && (
          <div css={menuStyles.controls} className="menu-controls">
            <RefreshButton />
          </div>
        )}
      </div>
    </div>
  )
}
