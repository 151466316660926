import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import {
  doFetchJiraServerIssues,
  selectCurrentTicketLinkedIssues,
} from 'ducks/integrations/jiraServer'
import { selectJiraSites } from 'ducks/integrations/jiraShared'

import { Label, Loader } from 'shared/ui'
import { any } from 'util/arrays'

import LinkedJiraServerIssuesRow from './LinkedJiraServerIssuesRow'

export default function LinkedJiraServerIssues({ doNavigatableSetState }) {
  const dispatch = useDispatch()
  const issues = useSelector(selectCurrentTicketLinkedIssues)
  const issueIds = issues && issues.map(({ externalId }) => externalId)
  const issueIdSerialized = JSON.stringify(issueIds)
  const sites = useSelector(state =>
    selectJiraSites(state, { source: 'jiraServer' })
  )
  useEffect(
    () => {
      dispatch(doFetchJiraServerIssues(issueIds))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, issueIdSerialized, sites.map(({ id }) => id).join()]
  )
  if (!issues || issues.length === 0) return null
  const isLoaded = any(({ loaded }) => loaded, issues)
  return (
    <>
      <Label size="small">Linked Issues ({issues.length})</Label>
      <NavigatableIntegrationWidgetCard.List>
        {!isLoaded && <Loader />}
        {isLoaded &&
          issues.map(issue => {
            return (
              issue && (
                <LinkedJiraServerIssuesRow
                  doNavigatableSetState={doNavigatableSetState}
                  issue={issue}
                  key={issue.id}
                />
              )
            )
          })}
      </NavigatableIntegrationWidgetCard.List>
    </>
  )
}
