import { connect } from 'react-redux'
import {
  selectLastForgotMessage,
  selectLastForgotIsSent,
} from 'selectors/account'
import { email, required } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'
import { selectIsAccountSubdomain, selectSubdomain } from 'selectors/location'
import ForgotForm from '../ForgotForm'
import { doResetPassword } from './actions'

let initialValues = null
function getInitialValues(state) {
  if (!initialValues) {
    initialValues = {
      emailInput: '',
      subdomainInput: selectIsAccountSubdomain(state)
        ? selectSubdomain(state)
        : '',
    }
  }
  return initialValues
}

const validators = {
  emailInput: email,
  subdomainInput: required,
}

const select = state => ({
  initialValues: getInitialValues(state),
  validators,
  showSubdomain: !selectIsAccountSubdomain(state),
  forgotError: selectLastForgotMessage(state),
  isSent: selectLastForgotIsSent(state),
  submitText: 'Send reset link',
})

const perform = {
  onSave: doResetPassword,
}

export default connect(select, perform)(withFormFields(ForgotForm))
