import React from 'react'

export function createFocusComponent(register) {
  return class KeyboardNavigatorFocus extends React.Component {
    componentDidMount = () => {
      register.unshift(this)
    }

    componentWillUnmount = () => {
      const index = register.indexOf(this)
      if (index > -1) register.splice(index, 1)
    }

    render() {
      return null
    }
  }
}
