import styled from '@emotion/styled'

import Modal from 'components/Modal'
import themeVars from 'ui_theme/site/globals/site.variables'

export default styled(WidgetCardModal)`
  padding: ${({ padded = true }) => {
    if (padded === true) return themeVars.large
    if (padded) return padded
    return 0
  }};
  width: ${({ width = 300 }) => width}px;
  &.date {
    width: 290px;
  }
  .title {
    display: flex;
    color: rgb(79, 93, 106);
    font-size: ${props => props.theme.spacing.base};
    font-weight: ${themeVars.graphikWeightMedium};
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon {
      height: 22px;
      margin-right: 6px;
      .fill {
        fill: ${props => props.theme.color.monochrome.mediumDark};
      }
    }
  }

  .form {
    margin-top: ${props => props.theme.spacing['5']};

    .label {
      margin: ${props => props.theme.spacing['10']} 0
        ${props => props.theme.spacing['5']};

      &:first-of-type {
        margin-top: 0;
      }
    }

    .input {
      margin-bottom: ${props => props.theme.spacing['5']};

      &.last-child {
        margin-bottom: 0;
      }
    }

    .emailRow {
      display: flex;
      margin-bottom: ${props => props.theme.spacing['5']};

      .input {
        flex-grow: 1;
        margin-bottom: 0;
        margin-right: ${props => props.theme.spacing['5']};
      }

      .button {
        margin-right: 0;
        line-height: 30px;
      }
    }

    .tooltipTrigger {
      cursor: pointer;
      color: ${props => props.theme.color.primary.brand};
    }

    .link {
      color: ${props => props.theme.color.primary.brand};
      cursor: pointer;
      margin-top: ${props => props.theme.spacing['7']};
      margin-bottom: ${props => props.theme.spacing['10']};
    }
    .DatePicker {
      border: none;
    }
  }

  &&& {
    .buttons {
      display: flex;
      margin-top: ${props => props.theme.spacing['5']};
      .left {
        flex-grow: 1;
      }
      .right {
        flex-shrink: 0;
        display: flex;
        flex-direction: row-reverse;
        .button {
          margin-left: ${props => props.theme.spacing['5']};
        }
      }
    }
  }
`

function WidgetCardModal({ className, ...props }) {
  return <Modal contentClass={className} {...props} />
}
