import { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Form } from 'util/ui'
import { SVGIcon } from 'shared/ui'
import GA from 'util/googleAnalytics'

const baseStyle = () => theme => css`
  &&& {
    position: relative;
    & .ui.button {
      font-family: ${theme.font.primary} !important;
      font-size: ${theme.fontSize.x2large} !important;
      line-height: 1 !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;

      border-radius: 38px;
      font-weight: normal;
    }
  }
`

const basicStyle = () => theme => css`
  &&& {
    .ui.button {
      color: ${theme.color.monochrome.black} !important;
      background: ${theme.color.monochrome.white} !important;
      box-shadow: inset 0 0 0 1px ${theme.color.monochrome.medium} !important;
      &:hover {
        box-shadow: inset 0 0 0 1px ${theme.color.monochrome.black} !important;
      }
      &.disabled,
      &:disabled {
        box-shadow: inset 0 0 0 1px ${theme.color.monochrome.medium} !important;
      }
      span {
        color: ${theme.color.monochrome.black} !important;
      }
    }
  }
`

const darkStyle = () => theme => css`
  &&& {
    .ui.button {
      color: ${theme.color.monochrome.white} !important;
      background-color: ${theme.color.primary.info};
      &:hover {
        background-color: ${theme.color.buttonInfo.backgroundHover};
      }
      &.ui.basic.disabled,
      &.ui.basic:disabled {
        box-shadow: none !important;
      }
      span {
        color: ${theme.color.monochrome.white} !important;
      }
    }
  }
`

const iconStyle = css`
  position: absolute;
  left: 16px;
  top: 12px;
`

const darkIconStyle = () => theme => css`
  background: ${theme.color.monochrome.white};
  border: 4px solid ${theme.color.monochrome.white};
  border-radius: 32px;
  width: 32px;
  height: 32px;
  left: 12px;
  top: 8px;
`

const buttonSpanStyle = () => theme => css`
  &&& {
    color: ${theme.color.monochrome.dark} !important;
  }
`

const darkButtonSpanStyle = () => theme => css`
  &&& {
    color: ${theme.color.monochrome.white} !important;
  }
`

const OAuthButton = ({
  onClick,
  iconName,
  disabled = false,
  saving = false,
  text = '',
  savingText = '',
  tracking,
  dark,
  ...rest
}) => {
  const [clicked, setClicked] = useState(false)
  useEffect(
    () => {
      if (saving === false && clicked) {
        setClicked(false)
      }
    },
    [saving, clicked]
  )
  return (
    <Form.Button
      size="big"
      fluid
      disabled={disabled || saving}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => {
        setClicked(true)
        if (tracking) {
          GA.track(tracking.category, tracking.action, tracking.label)
        }
        onClick()
      }}
      {...rest}
      css={[baseStyle, dark ? darkStyle : basicStyle]}
    >
      <SVGIcon
        name={iconName}
        size={24}
        css={[iconStyle, dark && darkIconStyle]}
      />
      <span css={[dark ? darkButtonSpanStyle : buttonSpanStyle]}>
        {clicked && saving && savingText ? savingText : text}
      </span>
    </Form.Button>
  )
}

export default OAuthButton
