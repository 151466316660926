import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@groovehq/internal-design-system/lib/components/Switch/Switch'

import { selectIsAddingNote } from 'selectors/page'
import { doOpenNotePage, doOpenTicketPage } from 'actions/pages'
import { css } from '@emotion/react'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

const wrapperStyle = css`
  margin-left: 8px !important;
`

const switcherStyle = theme => css`
  margin-top: 0;
  height: 32px;
  line-height: 32px;

  label {
    height: 32px;
  }

  label .switch-children {
    font-weight: ${theme.fontWeight.medium};
    display: inline-block;
    transform: translateY(-1px);
    white-space: nowrap;
  }

  label .switch-btn {
    color: ${theme.color.buttonNote.background};
    &:hover {
      color: ${theme.color.buttonNote.hover};
    }
  }
  input:checked + label .switch-btn {
    color: ${theme.color.buttonInfo.background};
    &:hover {
      color: ${theme.color.buttonInfo.hover};
    }
  }
`

const NoteReplySwitcher = ({ ticketId }) => {
  const commenting = useSelector(selectIsAddingNote)
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const dispatch = useDispatch()

  const handleChange = useCallback(
    () => {
      if (commenting) {
        dispatch(doOpenTicketPage(ticketId, { replying: true }))
      } else {
        dispatch(doOpenNotePage(ticketId))
      }
    },
    [dispatch, commenting, ticketId]
  )

  if (!isAdminOrOwnerOrAgent) return null

  return (
    <div
      id="note-reply-switcher"
      data-target-type={commenting ? 'reply' : 'note'}
      className="left-divider"
      css={wrapperStyle}
      data-test-id="note-switch"
    >
      <Switch
        id="note-reply-switcher-internal"
        checked={commenting}
        onChange={handleChange}
        css={switcherStyle}
      >
        {commenting ? 'Reply' : 'Note'}
      </Switch>
    </div>
  )
}

export default NoteReplySwitcher
