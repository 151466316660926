import React from 'react'
import { useSelector } from 'react-redux'
import {
  divider,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Link from 'redux-first-router-link'
import { selectConversationSources } from 'ducks/ai/selectors/selectConversationSources'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import {
  bookOpen as Book,
  messagesBubble as MessagesBubble,
} from 'assets/icons/groove/v2'
import { pluralize } from 'util/strings'
import { styles } from './styles'
import Warning from './Warning'

const ResponseWithDocuments = ({ response, children }) => {
  const { answer } = response
  const { sources } = useSelector(selectConversationSources)
  const topFiveSources = sources?.slice(0, 5)
  const sourcesLength = topFiveSources?.length

  return (
    <>
      <SanitizedHTML className="body grui mb-6" html={answer} />
      {children}
      {!!sourcesLength && (
        <div css={text.styles.textXs}>
          <div
            css={[divider.styles.primary, styles.divider]}
            className="grui my-10 -ml-10 -mr-10"
            role="separator"
          />
          <div className="grui mb-3" css={text.styles.fontMedium}>
            {`${sourcesLength} Relevant ${pluralize(
              sourcesLength,
              'source'
            )} found:`}
          </div>
          {topFiveSources.map(source => (
            <div
              key={source.id}
              css={styles.docItem}
              className="grui mt-2 flex items-center"
            >
              {source.isArticle ? (
                <Book width="12" height="12" />
              ) : (
                <MessagesBubble width="12" height="12" />
              )}
              <Link to={source.to} target="_blank" className="grui ml-5">
                {source.title}
              </Link>
            </div>
          ))}
        </div>
      )}
      <Warning />
    </>
  )
}

export default ResponseWithDocuments
