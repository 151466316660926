import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import PinIcon from '@groovehq/internal-design-system/lib/assets/icons/Pin'

import { selectIsEditorPinned } from 'ducks/editor/selectors'
import { doUpdateEditorPinned } from 'ducks/editor/operations'
import { css } from '@emotion/react'

const baseStyle = theme => css`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: ${theme.color.monochrome.mediumDark};
`

const activeStyle = theme => css`
  color: ${theme.color.primary.brand};
  transform: rotate(-45deg);
`

const PinButton = () => {
  const dispatch = useDispatch()
  const pinned = useSelector(selectIsEditorPinned)

  const handleClick = useCallback(
    () => {
      dispatch(doUpdateEditorPinned(!pinned))
    },
    [dispatch, pinned]
  )

  return (
    <Tooltip
      title={pinned ? 'Unpin editor' : 'Pin editor'}
      position="top"
      strategy="fixed"
    >
      <Button
        type="icon"
        size="small"
        className="pinEditorButton"
        id="pin-editor-button"
        data-target-type={pinned ? 'inline' : 'pinned'}
        onClick={handleClick}
        tabIndex="-1"
      >
        <PinIcon css={[baseStyle, pinned && activeStyle]} />
      </Button>
    </Tooltip>
  )
}

export default PinButton
