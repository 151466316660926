import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import ProgressCircle from '@groovehq/internal-design-system/lib/components/ProgressCircle/ProgressCircle'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { Dropdown } from 'shared/ui'
import {
  ArrowDown,
  ArrowUpDown,
} from '@groovehq/internal-design-system/lib/assets/icons'
import NewLabel from '@groovehq/internal-design-system/lib/components/NewLabel/NewLabel'
import {
  HAS_SEEN_TICKETS_WAIT_LONGEST,
  HIDE_TICKETS_WAIT_LONGEST_CALLOUT,
} from 'ducks/flags/flagNames'
import { selectFlag } from 'ducks/flags/selectors'
import { doSetFlag } from 'ducks/flags/operations'
import { LONGEST_UNANSWERED } from 'constants/defaults'
import { selectFeatureCalloutExpired } from 'selectors/app/selectFeatureCalloutExpired'

import { styles } from './styles'

const newLabelTargets = [LONGEST_UNANSWERED, 'longestWaiting']

const SortMenu = ({ options, className }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [menuOptions, setMenuOptions] = useState([])
  const [hasWaitLongestOption, setHasWaitLongestOption] = useState(false)
  const hasSeenWaitLongest = useSelector(state =>
    selectFlag(state)(HAS_SEEN_TICKETS_WAIT_LONGEST)
  )
  const hideTicketsWaitLongestCallout = useSelector(state =>
    selectFlag(state)(HIDE_TICKETS_WAIT_LONGEST_CALLOUT)
  )
  const featureCalloutExpired = useSelector(state =>
    selectFeatureCalloutExpired(state, '2023-03-26T00:00:00')
  )

  const showProgressCircle =
    !featureCalloutExpired &&
    hasWaitLongestOption &&
    hasSeenWaitLongest === false

  const showTicketsWaitLongestNewLabel =
    !featureCalloutExpired && hideTicketsWaitLongestCallout === false

  useEffect(
    () => {
      if (options.length === 0 || !showTicketsWaitLongestNewLabel) {
        setMenuOptions(options)
        return
      }
      let longestOption = false
      const optionsWithNewLabel = options.map(option => {
        if (newLabelTargets.includes(option.value)) {
          longestOption = true
          return {
            ...option,
            text: (
              <>
                {option.text}
                {` `}
                <NewLabel
                  isInfiniteAnimation
                  size="small"
                  className="grui mx-5"
                />
              </>
            ),
          }
        }
        return option
      })
      setHasWaitLongestOption(longestOption)
      setMenuOptions(optionsWithNewLabel)
    },
    [options, showTicketsWaitLongestNewLabel]
  )

  useEffect(
    () => {
      if (showProgressCircle && isOpen) {
        dispatch(doSetFlag(HAS_SEEN_TICKETS_WAIT_LONGEST))
      }
    },
    [isOpen, dispatch, showProgressCircle]
  )

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSelect = useCallback(
    (_, option) => {
      if (
        showTicketsWaitLongestNewLabel &&
        newLabelTargets.includes(option.value)
      ) {
        dispatch(doSetFlag(HIDE_TICKETS_WAIT_LONGEST_CALLOUT))
      }
    },
    [dispatch, showTicketsWaitLongestNewLabel]
  )

  const sortOrderText = useMemo(
    () => {
      const selectedOptionText = (options || []).find(x => x.active)
        ?.selectedOptionText

      return (
        <Button
          type="icon"
          size="small"
          className="grui w-auto px-6"
          css={styles.sortOrderTrigger}
        >
          <ArrowUpDown className="grui arrow-up-down" />
          {selectedOptionText || 'Sort By'}
          <ArrowDown className="caret" />
        </Button>
      )
    },
    [options]
  )

  const menuOptionsWithoutInvalidDomProps = useMemo(
    () =>
      menuOptions.map(({ selectedOptionText, ...validProps }) => validProps),
    [menuOptions]
  )

  return (
    <div css={styles.sortMenuWrapper}>
      {showProgressCircle && <ProgressCircle css={styles.progressCircle} />}
      <Dropdown
        trigger={sortOrderText}
        css={styles.sortMenu}
        className={cn('grui', className)}
        direction="right"
        header="Sorting"
        onOpen={handleOpen}
        onClose={handleClose}
        icon={null}
        options={menuOptionsWithoutInvalidDomProps}
        onChange={handleSelect}
      />
    </div>
  )
}

export default SortMenu
