import ActionSubject from './ActionSubject'
import ActionText from './ActionText'
import ToText from './ToText'

export default function IntegrationActionContentGitHub({
  action,
  action: {
    change: {
      action: {
        sender: { login },
      },
    },
  },
}) {
  return (
    <React.Fragment>
      {login} <ActionText action={action} />
      <ActionSubject action={action} />
      <ToText action={action} />
    </React.Fragment>
  )
}
