import React from 'react'
import { css } from '@emotion/react'
import StateBadge from 'components/StateBadge'

const CloseableStyle = css`
  &&:not(.disabled).closeable {
    padding: 8px;
  }
`

const BulkStateBadge = ({
  commonStateForSelected,
  count,
  onStateBadgeClick,
  variant,
}) => {
  const commonState = commonStateForSelected || 'open'
  return (
    <StateBadge
      onClick={onStateBadgeClick(commonState, count)}
      state={commonState}
      label={commonState}
      variant={variant}
      size="medium"
      css={CloseableStyle}
    />
  )
}

export default BulkStateBadge
