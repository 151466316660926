import { useContext, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { KeyValue } from 'components/KeyValues'
import { selectChargeForId } from 'ducks/integrations/recharge/selectors'
import {
  formatDateForCurrentYear,
  getRechargeMerchantUrl,
} from 'ducks/integrations/recharge/utils'
import { capitalize } from 'util/strings'
import { capture } from 'ducks/tracking/actions'

import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'

import DetailsTable from '../Orders/DetailsTable'
import Footer from './Footer'

export default function Detail({ integration, className }) {
  const { navigatableState, navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const { orderId, customerId, subscriptionId } = navigatableState || {}
  const charge = useSelector(state =>
    selectChargeForId(state, { subscriptionId, chargeId: orderId })
  )

  const scheduledAt = useMemo(
    () =>
      formatDateForCurrentYear(
        charge?.scheduled_at,
        '%d %b @ %l:%M%p',
        '%d %b %Y @ %l:%M%p'
      ),
    [charge]
  )

  const onBack = useCallback(
    () =>
      navigatableSetState({
        customerId,
        subscriptionId,
        orderId,
        type: 'subscription',
      }),
    [navigatableSetState, subscriptionId, customerId, orderId]
  )

  const statusClassName = useMemo(
    () => {
      if (!charge) {
        return null
      }

      switch (charge.status) {
        case 'queued':
          return 'recharge-status-neutral'
        default:
          return ''
      }
    },
    [charge]
  )

  const status = useMemo(
    () => {
      if (!charge) {
        return null
      }

      switch (charge.status) {
        case 'queued':
          return 'Draft'
        default:
          return capitalize(charge.status)
      }
    },
    [charge]
  )

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Recharge Order Link Clicked')
    return true
  }, [])

  const chargeUrl = getRechargeMerchantUrl(
    integration.fullStoreDomain,
    `merchant/orders/charges/${charge?.id}`
  )

  if (!charge) {
    return null
  }

  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      unifiedTitleIconButton
      onBack={onBack}
      title="back"
    >
      <div className="grui p-7">
        <KeyValue
          title="Order ID"
          value={
            <a
              href={chargeUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onViewExternalLinkClicked}
            >
              {charge.id}
              <ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          }
        />
        <KeyValue
          title="Status"
          value={<span className={statusClassName}>{status}</span>}
        />
        <KeyValue title="Scheduled at" value={scheduledAt} />
      </div>

      <DetailsTable charge={charge} />
      <Footer
        integration={integration}
        charge={charge}
        customerId={customerId}
        subscriptionId={subscriptionId}
      />
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
