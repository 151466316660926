import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentContactId } from 'ducks/crm/contacts'
import { doRemoveContactsFromCompany } from 'ducks/crm/companies'

import { Icon, Menu } from 'util/ui'

import WidgetCardModal from '../../../../WidgetCardModal'
import ChangeCompany from './ChangeCompany'
import CreateCompany from './CreateCompany'

export default function ChangeCompanyButton({
  changeCompanyMenus: {
    onClose,
    onCreate,
    onOpenMore,
    onOpenChange,
    onOpenCreate,
    openMenu,
  } = {},
  doRecalcMenuPosition,
  menuRef,
  offset,
  subjectId,
}) {
  const dispatch = useDispatch()
  const contactId = useSelector(selectCurrentContactId)
  const onRemove = useCallback(
    () => {
      dispatch(doRemoveContactsFromCompany(subjectId, [contactId]))
      onClose()
    },
    [dispatch, subjectId, onClose, contactId]
  )
  return (
    <>
      <div className="action" ref={menuRef} onClick={onOpenMore}>
        <Icon name="ellipsis vertical" size="small" />
      </div>
      {openMenu === 'more' && (
        <WidgetCardModal
          padded="4px 12px"
          contentRef={menuRef}
          offset={offset}
          onBackgroundClick={onClose}
          width={250}
        >
          <Menu text vertical>
            <Menu.Item
              name={`${
                subjectId ? "Change contact's" : 'Add contact to'
              } company`}
              onClick={onOpenChange}
            />
            {subjectId && (
              <Menu.Item name={"Remove contact's company"} onClick={onRemove} />
            )}
          </Menu>
        </WidgetCardModal>
      )}
      {['change-company', 'create-company'].includes(openMenu) && (
        <WidgetCardModal
          contentRef={menuRef}
          noCurtain={false}
          offset={offset}
          onBackgroundClick={onClose}
          padded={false}
        >
          {openMenu === 'change-company' && (
            <ChangeCompany
              companyId={subjectId}
              onClose={onClose}
              onCreate={onOpenCreate}
            />
          )}
          {openMenu === 'create-company' && (
            <CreateCompany
              companyId={subjectId}
              doRecalcMenuPosition={doRecalcMenuPosition}
              onClose={onClose}
              onCreate={onCreate}
            />
          )}
        </WidgetCardModal>
      )}
    </>
  )
}
