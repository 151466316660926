import React from 'react'

import { throttle, runOnNextTick } from 'util/functions'
import storage from 'util/storage'

export default function(defaultWidths, storageKey) {
  return WrappedComponent => {
    return class PanelWidthManager extends React.PureComponent {
      mediaQueries = []

      state = {
        mediaQuery: Object.keys(defaultWidths)[0],
        widths: storage.get(storageKey) || defaultWidths,
      }

      constructor(props) {
        /* eslint-disable  react/no-direct-mutation-state */
        super(props)
        this.mediaQueries = Object.keys(defaultWidths).map(mediaQueryRule => ({
          rule: mediaQueryRule,
          list: window.matchMedia(mediaQueryRule),
        }))

        const matchedQuery = this.mediaQueries.find(mq => mq.list.matches)
        this.state.mediaQuery = (matchedQuery || this.mediaQueries[0]).rule

        this.mediaQueries.forEach(mediaQuery =>
          mediaQuery.list.addListener(this.onMediaQueryEvent)
        )
        /* eslint-enable  react/no-direct-mutation-state */
      }

      componentWillUnmount() {
        this.mediaQueries.forEach(mediaQuery =>
          mediaQuery.list.removeListener(this.onMediaQueryEvent)
        )
      }

      onMediaQueryEvent = e => {
        if (e.matches) {
          runOnNextTick(() => this.setState({ mediaQuery: e.media }))
        }
      }

      onUpdate = throttle(updatedWidths => {
        const { mediaQuery, widths } = this.state
        const newWidths = { ...widths, [mediaQuery]: updatedWidths }

        this.setState({ widths: newWidths })
        storage.set(storageKey, newWidths)

        const evt = new CustomEvent('panelGroupUpdated', { widths, newWidths })
        window.dispatchEvent(evt)
      }, 500)

      render() {
        const { mediaQuery, widths } = this.state
        const isUnder1200 = mediaQuery === '(max-width: 1199px)'
        return (
          <WrappedComponent
            {...this.props}
            mediaQuery={mediaQuery}
            overlay={isUnder1200}
            panelWidths={widths[mediaQuery]}
            panelUpdateCallback={this.onUpdate}
          />
        )
      }
    }
  }
}
