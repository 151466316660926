import moment from 'moment'
import Link from 'redux-first-router-link'

import KeyValues, { KeyValue } from 'components/KeyValues'

function formatDate(date) {
  if (!date) return ''
  const asDate = new Date(date)
  const displayYear = asDate.getFullYear() !== new Date().getFullYear()
  return moment(date).format(`D MMM${displayYear ? " 'YY" : ''}, H:mmA`)
}

const LOCK_TRANSLATIONS = {
  TOO_HEATED: 'Too heated',
  OFF_TOPIC: 'Off topic',
  RESOLVED: 'Resolved',
  SPAM: 'Spam',
}

function getFieldProps(
  field,
  {
    activeLockReason,
    assignees,
    createdAt,
    labels,
    locked,
    milestone,
    repository: { nameWithOwner: repoName, url: repoUrl } = {},
    state,
    updatedAt,
  }
) {
  const { title: milestoneTitle, url: milestoneUrl } = milestone || {}
  switch (field) {
    case 'showSidebarField.repository':
      return {
        singleline: true,
        title: 'Repository',
        value: (
          <Link target="_blank" to={repoUrl}>
            {repoName}
          </Link>
        ),
      }
    case 'showSidebarField.state':
      return {
        singleline: true,
        title: 'State',
        value: state === 'OPEN' ? 'Open' : 'Closed',
      }
    case 'showSidebarField.assignees':
      if (assignees && assignees.length > 0) {
        return {
          title: 'Assignees',
          value: assignees.map(({ login, url: assigneeUrl }, i) => [
            i === 0 ? null : ', ',
            <Link key={login} target="_blank" to={assigneeUrl}>
              {login}
            </Link>,
          ]),
        }
      }
      return null
    case 'showSidebarField.labels':
      if (labels && labels.length > 0) {
        return {
          title: 'Labels',
          value: [
            ...labels.map(({ name, url: labelUrl }, i) => [
              i === 0 ? null : ', ',
              <Link key={name} target="_blank" to={labelUrl}>
                {name}
              </Link>,
            ]),
          ],
        }
      }
      return null
    case 'showSidebarField.created':
      return {
        singleline: true,
        title: 'Created',
        value: formatDate(createdAt),
      }
    case 'showSidebarField.lastUpdated':
      return {
        singleline: true,
        title: 'Last updated',
        value: formatDate(updatedAt),
      }
    case 'showSidebarField.milestone':
      return (
        milestone && {
          singleline: true,
          title: 'Milestone',
          value: (
            <Link target="_blank" to={milestoneUrl}>
              {milestoneTitle}
            </Link>
          ),
        }
      )
    case 'showSidebarField.locked':
      return {
        singleline: true,
        title: 'Locked',
        value: locked ? LOCK_TRANSLATIONS[activeLockReason] : 'Unlocked',
      }
    default:
      return null
  }
}

export default function LinkedGitHubIssueFields({ fields, issue }) {
  return (
    <KeyValues>
      {fields.map(field => {
        const props = getFieldProps(field, issue)
        if (!props) return null
        return <KeyValue key={field} {...props} />
      })}
    </KeyValues>
  )
}
