import cn from 'classnames'
import { css, ClassNames, useTheme } from '@emotion/react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import CollapsableContent from 'components/CollapsableContent'

import ReorderableAndHideableComponentsActions from './Actions'
import EmptyMessage from './EmptyMessage'
import ReorderableAndHideableComponent from './ReorderableAndHideableComponent'
import { useReorderingAndVisibility } from './hooks'

const SortableContainer = sortableContainer('div')
const SortableReorderableAndHideableComponent = sortableElement(
  ReorderableAndHideableComponent
)
const helperClass = theme => css`
  z-index: 10000000;
  pointer-events: auto !important;
  background: white;
  padding: ${theme.spacing['3']} ${theme.spacing['5']};
  border-radius: ${theme.spacing['3']};
  border: 1px solid ${theme.color.monochrome.medium};
  box-sizing: content-box !important;
  margin: calc(-${theme.spacing['3']} - 1px) calc(-${theme.spacing['5']} - 1px);
  .action {
    box-sizing: border-box;
  }
  && .dragHandle {
    cursor: grabbing;
  }
`

export default function ReorderableAndHideableComponents({
  className,
  collection,
  itemComponent,
  hideable = true,
  isInEditModeClassName = 'isInEditMode',
  initialOrder,
  itemNoun = 'item',
  itemNounPlural = `${itemNoun}s`,
  itemProps,
  hideTrigger = `– Hide extra ${itemNounPlural}`,
  showTrigger = `+ Show all ${itemNounPlural}`,
  onToggle,
  emptyMessage = `No visible ${itemNounPlural}`,
  namespace = 'reorderable_and_hideable_components',
  reorderable = true,
  useDragHandle = true,
}) {
  const {
    doEnterEditMode,
    doExitEditMode,
    doHideItem,
    doReorderItems,
    doShowItem,
    isInEditMode,
    items,
  } = useReorderingAndVisibility(namespace, collection, initialOrder)
  const { visibleItems, hiddenItems } = items.reduce(
    (result, { index, key, visible }) => {
      const addTo =
        visible || hideable === false ? result.visibleItems : result.hiddenItems
      const Wrapper = reorderable
        ? SortableReorderableAndHideableComponent
        : ReorderableAndHideableComponent
      const computedProps =
        typeof itemProps === 'function'
          ? itemProps(key, index, visible)
          : itemProps
      addTo.push(
        <Wrapper
          collection={`${collection}:${visible ? 'visible' : 'hidden'}`}
          key={key}
          index={index}
          isInEditMode={isInEditMode}
          isVisible={visible}
          itemComponent={itemComponent}
          itemKey={key}
          itemProps={computedProps}
          onHideItem={hideable && doHideItem}
          onShowItem={hideable && doShowItem}
        />
      )
      return result
    },
    { visibleItems: [], hiddenItems: [] }
  )
  const areMoreItems = hiddenItems.length > 0
  const theme = useTheme()
  return (
    <ClassNames>
      {/* we cannot use emotion css prop here because helperClass needs to be
      sent to react-sortable-hoc as a string and default return of emotion
      object */}
      {({ css: cssObjToStr }) => (
        <div
          className={cn('ReorderableAndHideableComponents', className, {
            isInEditMode,
          })}
        >
          <SortableContainer
            helperClass={cn(
              cssObjToStr`${helperClass(theme)}`,
              'EditModeHelper',
              {
                [isInEditModeClassName]: isInEditMode,
              }
            )}
            onSortEnd={doReorderItems}
            useDragHandle={useDragHandle}
          >
            {visibleItems.length > 0 ? (
              visibleItems
            ) : (
              <EmptyMessage>{emptyMessage}</EmptyMessage>
            )}
          </SortableContainer>
          <ReorderableAndHideableComponentsActions
            isInEditMode={isInEditMode}
            onDone={doExitEditMode}
            onEdit={doEnterEditMode}
            noMoreFields={!areMoreItems}
          />
          {areMoreItems && (
            <CollapsableContent
              hideTrigger={!isInEditMode && hideTrigger}
              showTrigger={!isInEditMode && showTrigger}
              shown={isInEditMode === true ? true : null}
              onToggle={onToggle}
            >
              <SortableContainer
                helperClass={cn(
                  cssObjToStr`${helperClass(theme)}`,
                  'EditModeHelper',
                  {
                    [isInEditModeClassName]: isInEditMode,
                  }
                )}
                onSortEnd={doReorderItems}
                useDragHandle={useDragHandle}
              >
                {hiddenItems}
              </SortableContainer>
            </CollapsableContent>
          )}
        </div>
      )}
    </ClassNames>
  )
}
