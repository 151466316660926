import cn from 'classnames'
import styled from '@emotion/styled'
import Link from 'redux-first-router-link'

const Tabs = props => <div {...props} className="tabs" />

export default Tabs

Tabs.Header = styled('div')`
  display: flex;
  background: #ffffff;
  border-bottom: 1px solid #ccc;
`

Tabs.Link = styled(TabsLink)`
  display: block;
  padding: 16px 0;
  font-family: 'Graphik', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: ${props => props.theme.spacing['7']};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 2px solid ${props => props.theme.color.monochrome.white};
  cursor: pointer;
  margin-right: ${props => props.theme.spacing['11']};
  font-weight: 500;
  .sui & {
    color: ${props => props.theme.color.monochrome.dark};
    &:hover {
      color: ${props => props.theme.color.monochrome.black};
    }
  }

  &.active {
    border-bottom: 2px solid ${props => props.theme.color.primary.brand};
    color: ${props => props.theme.color.monochrome.black};
    font-weight: 500;
  }
`

Tabs.Actions = styled('div')`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

Tabs.Content = styled(TabsContent)`
  display: none;
  padding-top: ${props => props.theme.spacing['8']};

  &.description {
    padding-top: 0px;
  }

  &.active {
    display: block;
  }
`

function TabsContent(props) {
  const { active, className } = props
  return <div {...props} className={cn(className, { active })} />
}

function TabsLink(props) {
  const { active, className, ...rest } = props
  return <Link {...rest} className={cn('grui', className, { active })} />
}
