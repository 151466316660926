import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import withOpenState from 'shared/ui/withOpenState'
import Content from './Content'

const Dropdown = ({
  bottom,
  left,
  onOpen,
  open,
  right,
  top,
  trigger,
  ...rest
}) => {
  return (
    <div onClick={!open ? onOpen : undefined}>
      {trigger}
      <Content open={open} offset={{ left, right, top, bottom }} {...rest} />
    </div>
  )
}

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
}

export default compose(withOpenState, withMenuPositioning)(Dropdown)
