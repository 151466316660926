import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import {
  doFetchTrelloCards,
  selectCurrentTicketLinkedCards,
} from 'ducks/integrations/trello'
import { Label, Loader } from 'shared/ui'
import { any } from 'util/arrays'

import LinkedTrelloCardsRow from './LinkedTrelloCardsRow'

export default function LinkedTrelloCards({ doNavigatableSetState }) {
  const dispatch = useDispatch()
  const cards = useSelector(selectCurrentTicketLinkedCards)
  const cardIds = cards && cards.map(({ externalId }) => externalId)
  const cardIdSerialized = JSON.stringify(cardIds)
  useEffect(
    () => {
      if (cardIds) dispatch(doFetchTrelloCards(cardIds))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, cardIdSerialized]
  )
  if (!cards || cards.length === 0) return null
  const isLoaded = any(({ loaded }) => loaded, cards)
  return (
    <>
      <Label size="small">Linked Issues ({cards.length})</Label>
      <NavigatableIntegrationWidgetCard.List>
        {!isLoaded && <Loader />}
        {isLoaded &&
          cards.map(card => {
            return (
              card && (
                <LinkedTrelloCardsRow
                  doNavigatableSetState={doNavigatableSetState}
                  card={card}
                  key={card.id}
                />
              )
            )
          })}
      </NavigatableIntegrationWidgetCard.List>
    </>
  )
}
