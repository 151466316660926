import { useSelector } from 'react-redux'

import logo from 'assets/integrations/trello/icon-24.png'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import LinkedTrelloCardsIndex from './LinkedTrelloCardsIndex'
import LinkedTrelloCard from './LinkedTrelloCard'

export default function TrelloWidgetCard({ doHideCard }) {
  const conversationId = useSelector(selectCurrentTicketId)
  if (!conversationId) return null
  return (
    <NavigatableIntegrationWidgetCard
      detailComponent={LinkedTrelloCard}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={LinkedTrelloCardsIndex}
      title="Trello"
      persistenceKey="trello"
    />
  )
}
