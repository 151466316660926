import React from 'react'

import { Label } from 'shared/ui'

import Section from '../../Section'
import CollectionSection from '../CollectionSection'
import Properties from '../Properties'
import PropertySection from '../PropertySection'
import ContactActions from './Actions'

export default class Contact extends React.PureComponent {
  componentDidMount() {
    const { contactObject, related, doFetchRelatedObjects } = this.props
    if (!related) doFetchRelatedObjects(contactObject)
  }
  render() {
    const {
      contactObject,
      doNavigatableSetState,
      credentials,
      tasks,
      opportunities,
      account,
      settings,
    } = this.props

    if (!contactObject) return null
    if (!contactObject.properties) return null
    if (!credentials) return null
    const id = contactObject.id
    const url = [credentials.salesforceDomain, id].join('/')

    if (!contactObject || contactObject.state !== 'fetched') return null
    return (
      <>
        <Section>
          <Label size="small">{contactObject.type}</Label>
          <Properties
            properties={contactObject.properties}
            settings={settings}
            specificFieldsPattern="showSidebarField.Contact\."
          />
          <ContactActions url={url} />
        </Section>
        {account && (
          <PropertySection
            properties={account.properties}
            settings={settings}
            specificFieldsPattern="showSidebarField.Account\."
            label="Account"
          />
        )}
        {opportunities &&
          opportunities.length > 0 && (
            <CollectionSection
              doNavigatableSetState={doNavigatableSetState}
              objects={opportunities}
              parentId={id}
              settings={settings}
              specificFieldsPattern="showSidebarField.Opportunity\."
              label="Opportunities"
            />
          )}
        {tasks &&
          tasks.length > 0 && (
            <CollectionSection
              doNavigatableSetState={doNavigatableSetState}
              objects={tasks}
              parentId={id}
              settings={settings}
              specificFieldsPattern="showSidebarField.Task\."
              label="Tasks"
            />
          )}
      </>
    )
  }
}
