import { useController, useWatch } from 'react-hook-form'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { shopifyMoneyString } from 'util/currency'
import {
  FORM_KEY_TYPE,
  FORM_KEY_SUGGESTED_REFUND_TRANSACTIONS,
  FORM_KEY_MAXIMUM_REFUNDABLE_SET,
} from './constants'
import TransactionRow from './TransactionRow'

const RefundTypeSelection = ({ control }) => {
  const {
    field: { onChange, value },
  } = useController({
    name: FORM_KEY_TYPE,
    control,
  })

  const maximumRefundableSet = useWatch({
    control,
    name: FORM_KEY_MAXIMUM_REFUNDABLE_SET,
  })

  const suggestedRefundTransactions = useWatch({
    control,
    name: FORM_KEY_SUGGESTED_REFUND_TRANSACTIONS,
  })

  return (
    <>
      <div className="grui mb-4">
        <Radio
          id="refund-now"
          name={FORM_KEY_TYPE}
          value="refund-now"
          checked={value === 'refund-now'}
          onChange={onChange}
          onBlur={onChange}
        >
          Refund{' '}
          {!!maximumRefundableSet && shopifyMoneyString(maximumRefundableSet)}
        </Radio>
        {value === 'refund-now' &&
          !!suggestedRefundTransactions?.length &&
          suggestedRefundTransactions.map(transaction => {
            const {
              parentTransaction: { id, paymentIcon } = {},
              formattedGateway,
              accountNumber,
            } = transaction

            return (
              <TransactionRow
                key={id}
                paymentIcon={paymentIcon}
                formattedGateway={formattedGateway}
                accountNumber={accountNumber}
                className="grui pl-12"
              />
            )
          })}
      </div>
      <Radio
        id="refund-later"
        name={FORM_KEY_TYPE}
        value="refund-later"
        checked={value === 'refund-later'}
        onChange={onChange}
        onBlur={onChange}
      >
        Refund later
      </Radio>
      {value === 'refund-later' && (
        <div css={text.styles.textMediumDark} className="grui pl-12">
          Your customer won’t be refunded. Refund the amount owed at a later
          point.
        </div>
      )}
    </>
  )
}

export default RefundTypeSelection
