import React from 'react'
import { connect } from 'react-redux'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { selectCurrentTicketList } from 'selectors/app'
import { selectTicketSearchTotalCountsByQueryId } from 'selectors/search'
import SearchRawCount from 'components/App/DesktopView/Layout/SearchRawCount'
import { styles } from './styles'

const selectFactory = (_, initialOwnProps) => {
  const {
    labelId,
    agentId,
    folderId,
    buildMailboxQueryString,
  } = initialOwnProps
  const payload = {}
  if (labelId) {
    payload.is = 'open'
    payload.label = labelId
  }
  if (agentId) {
    payload.is = 'open'
    payload.assignee = agentId
  }
  if (folderId) payload.folder = folderId
  const queryId = buildMailboxQueryString && buildMailboxQueryString(payload)

  return state => {
    const currentTicketList = selectCurrentTicketList(state)
    const counts = selectTicketSearchTotalCountsByQueryId(state)
    const ticketsCount = counts ? counts[queryId] : null

    return {
      queryId,
      ticketsCount,
      active: currentTicketList && currentTicketList.id === queryId,
    }
  }
}

class ItemView extends React.PureComponent {
  handleClick = () => {
    const { doOpenSearchPage, doStartSearchTimer, queryId, name } = this.props
    let searchId = queryId
    if (name.match('Drafts')) {
      searchId = `draft_type:reply ${queryId}`
    }
    if (doStartSearchTimer) doStartSearchTimer(queryId)

    doOpenSearchPage(searchId)
  }

  render() {
    const {
      queryId,
      active,
      name,
      displayCountWhenInactive,
      hideIfZeroConversations,
      ticketsCount,
      isSubMenuItem,
      icon,
      moreOptions,
    } = this.props
    const shouldDisplayCount = active || (!active && displayCountWhenInactive)

    if (hideIfZeroConversations && ticketsCount <= 0) {
      return null
    }

    return (
      <Menu.Item
        css={[styles.fixOptionsPosition, styles.clickable]}
        isSubMenuItem={isSubMenuItem}
        onClick={this.handleClick}
        active={active}
        count={
          shouldDisplayCount && (
            <SearchRawCount count={ticketsCount} queryId={queryId} />
          )
        }
        icon={icon}
        moreOptions={moreOptions}
        title={name}
      >
        {name}
      </Menu.Item>
    )
  }
}

const Item = connect(selectFactory)(ItemView)

export default Item
