import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { CHANNEL_EMAIL_OPTIONS } from 'subapps/settings/components/drawers/Channels/Channels.data'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { styles } from './styles'

const ChannelOption = ({
  className,
  onClickCreateBtn,
  type: channelType,
  provider,
  icon,
  title,
  onSelect,
}) => {
  const isGmailChannel = channelType === 'google'
  const { openDrawer, drawerId } = useDrawer(
    CHANNEL_EMAIL_OPTIONS[provider].drawer
  )

  const handleSelectChannel = useCallback(
    () => {
      if (onClickCreateBtn) {
        onClickCreateBtn()
      }
      if (onSelect) {
        onSelect(channelType)
        return
      }
      openDrawer('new', {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', channelType),
          ...buildDrawerQueryParam(drawerId, 'drawerChannelProvider', provider),
        },
        additionalProps: {
          drawerSource: 'inbox',
        },
      })
    },
    [openDrawer, drawerId, onClickCreateBtn, channelType, provider, onSelect]
  )

  return (
    <Button
      css={[styles.btn, isGmailChannel ? styles.oAuthButtton : styles.emailBtn]}
      className={className}
      type={isGmailChannel ? 'info' : 'tertiary'}
      size="xBig"
      onClick={handleSelectChannel}
    >
      <span className="channel-icon-wrapper">{icon}</span>
      <span className="grui flex-grow">{title}</span>
    </Button>
  )
}

export default ChannelOption
