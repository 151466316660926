import React, { Fragment, useMemo } from 'react'
import { timeInHuman } from 'util/date'
import { useShopifyTimeline } from 'ducks/integrations/shopify/hooks'
import TimelineEvent from './TimelineEvent'

const TimelineEventsForDay = React.memo(
  ({ day, integrationId, customerId }) => {
    const { timelineEventsByDay } = useShopifyTimeline({
      customerId,
      integrationId,
    })

    const timelineEvents = useMemo(
      () => {
        return timelineEventsByDay.get(day)
      },
      [timelineEventsByDay, day]
    )

    const dateString = useMemo(
      () => {
        return timeInHuman(new Date(day), {
          showToday: true,
          hideTodayTime: true,
        })
      },
      [day]
    )

    if (!timelineEvents) return <Fragment />
    return (
      <div className="shopifyV2-events-dayGroup">
        <div className="shopifyV2-events-dayLabel">{dateString}</div>
        {timelineEvents.map(timelineEvent => (
          <TimelineEvent timelineEvent={timelineEvent} key={timelineEvent.id} />
        ))}
      </div>
    )
  }
)

export default TimelineEventsForDay
