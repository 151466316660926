import React from 'react'
import styled from '@emotion/styled'

import { Button, Header, SUI, SubHeader, withFormFields } from 'shared/ui'
import ValidatedField from 'shared/components/ui/ValidatedField'

import { Form } from 'util/ui'
import { minLength } from 'util/validation'

import ColorPicker from '../../shared/ColorPickerIcon'

const defaultColor = 'var(--color-primary-brand)'

const LabelForm = ({
  className,
  formFields,
  formFieldsErrors,
  submitForm,
  submitting,
  onCancel,
  onChange,
  headerText,
  saveButtonText,
  subText,
}) => {
  const color = formFields.color || defaultColor

  return (
    <div className={className}>
      <Header as="h1">{headerText}</Header>
      {subText && (
        <SubHeader as="h3" size="small">
          {subText}
        </SubHeader>
      )}
      <div className="inputs">
        <ColorPicker color={color} onSubmit={onChange} />
        <Form.Field>
          <ValidatedField
            defaultValue={formFields.name}
            errorMessage={formFieldsErrors.name || formFieldsErrors.error}
            autoFocus
            // eslint-disable-next-line react/jsx-no-bind
            onChange={e =>
              onChange('name', e.persist() || e.target.value, minLength(1))
            }
            placeholder={'Tag name...'}
          />
        </Form.Field>
      </div>
      <div className="separator" />
      <div className="buttons">
        <SUI>
          <Button
            disabled={!formFields.name || submitting}
            primary
            onClick={submitForm}
          >
            {submitting ? 'Saving...' : saveButtonText}
          </Button>
          <Button basic className="grui ml-10 cancel" onClick={onCancel}>
            Cancel
          </Button>
        </SUI>
      </div>
    </div>
  )
}

const Styled = styled(withFormFields(LabelForm))`
  &&& {
    .inputs {
      display: flex;
      padding: 24px 16px 16px 16px;
    }

    .separator {
      border-top: 1px solid var(--color-monochrome-medium);
      height: 1px;
    }

    .buttons {
      padding: 16px;
    }

    .field {
      padding-left: 8px;
      width: 100%;

      .ui.input {
        width: 100%;
      }
    }
  }
`

export default Styled
