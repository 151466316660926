import React from 'react'
import { css } from '@emotion/react'

import SnoozeDropdown from './DropdownSwitcher'

const SnoozeStyle = () => theme => css`
  padding: 12px;

  &&:hover svg path,
  &&.snoozed svg path {
    fill: ${theme.color.primary.negativeAlt} !important;
  }
`

export default function IconDropdown({ ticketId, iconSize = 14, ...rest }) {
  return (
    <SnoozeDropdown
      iconSize={iconSize}
      icon={null}
      iconButtonCss={SnoozeStyle}
      {...rest}
    />
  )
}
