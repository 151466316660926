import React, { useState, useMemo, useCallback } from 'react'
import { compose } from 'redux'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { mergeDateAndTime } from 'util/date'
import { Divider, Flex, SUI, Button } from 'shared/ui'
import DateTimeInputSinglePane from 'shared/components/ui/DateTimeInputSinglePane'
import Modal from 'components/Modal'
import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import { notImplemented } from 'util/functions'

const DividerNoMargin = styled(Divider)`
  &&& {
    margin: unset;
  }
`

const WarningMessage = styled('div')`
  max-width: 254px;
  padding: 0 16px 16px 16px;
`

const Link = styled('a')`
  cursor: pointer;
`

const snoozeDropdownOverflow = css`
  @media screen and (max-height: 710px) {
    overflow: auto;
    max-height: calc(100vh - 300px) !important;
  }
`

const CustomSnoozeDropdown = ({
  open,
  left,
  right,
  top,
  bottom,
  hidden,
  onClose,
  onSnooze,
  onCancel,
  snoozeButtonLabel,
  expiresAt,
  snoozeDropdownNeedOverflow,
}) => {
  const [canSnooze, setCanSnooze] = useState(false)
  const [snoozeUntil, setSnoozeUntil] = useState(null)

  const calcOffset = useMemo(() => ({ bottom, left, right, top }), [
    bottom,
    left,
    right,
    top,
  ])

  const styleContainerOpts = useMemo(
    () => ({
      visibility: hidden ? 'hidden' : 'visible',
      display: 'flex',
    }),
    [hidden]
  )

  const showExpireWarning = useMemo(
    () => {
      if (!expiresAt || !snoozeUntil) return false
      return snoozeUntil.getTime() > expiresAt
    },
    [expiresAt, snoozeUntil]
  )

  const onSnoozeClick = useCallback(
    () => {
      onSnooze(snoozeUntil)
      onClose()
    },
    [snoozeUntil, onSnooze, onClose]
  )

  const onChange = useCallback(
    ({ date, time }) => {
      const newSnoozeDate = mergeDateAndTime(date, time)

      if (expiresAt && newSnoozeDate.getTime() > expiresAt) {
        setCanSnooze(false)
      } else {
        setCanSnooze(
          !!newSnoozeDate && newSnoozeDate.getTime() > new Date().getTime()
        )
      }
      setSnoozeUntil(newSnoozeDate)
    },
    [expiresAt, setCanSnooze, setSnoozeUntil]
  )

  if (!open) return null

  return (
    <Modal
      onBackgroundClick={onClose}
      styleContainer={styleContainerOpts}
      offset={calcOffset}
    >
      <SUI css={snoozeDropdownNeedOverflow && snoozeDropdownOverflow}>
        <DateTimeInputSinglePane
          onChange={onChange}
          fromDate={new Date()}
          initialTime={'08:00'}
          // disabling because the snooze cron job only runs every 15 mins, enable after port to minute schedule
          canEditTimeInput={false}
          // if expiration date is set, show it 1 day before
          warningFromDate={expiresAt ? new Date(expiresAt) : null}
        />
        <DividerNoMargin />
        <Flex className="grui p-10 justify-start flex-row">
          <Flex>
            <Button
              primary
              onClick={onSnoozeClick}
              size="medium"
              disabled={!canSnooze}
            >
              {snoozeButtonLabel}
            </Button>
            <Button
              basic
              className="grui ml-4"
              onClick={onCancel}
              size="medium"
            >
              Cancel
            </Button>
          </Flex>
        </Flex>

        {showExpireWarning && (
          <WarningMessage>
            The Snooze reply time is later than the remaining reply time that is
            allowed by Messenger.{' '}
            <Link onClick={onSnoozeClick}>Snooze anyway</Link>?
          </WarningMessage>
        )}
      </SUI>
    </Modal>
  )
}

CustomSnoozeDropdown.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSnooze: PropTypes.func,
  onCancel: PropTypes.func,
  snoozeButtonLabel: PropTypes.string,
}

CustomSnoozeDropdown.defaultProps = {
  open: true,
  onClose: notImplemented('onClose'),
  onSnooze: notImplemented('onSnooze'),
  onCancel: notImplemented('onCancel'),
  snoozeButtonLabel: 'Snooze',
}

export default compose(withMenuPositioning)(CustomSnoozeDropdown)
