import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  doUpdateAgentPreference,
  selectAgentPreferences,
} from 'ducks/agentPreferences'
import { selectIsRightSidebarCollapsed } from 'selectors/app'
import { uniqBy } from 'util/arrays'

function getChangedItemPostionValue(items, oldIndex, newIndex) {
  const item = items.splice(oldIndex, 1)[0]
  items.splice(newIndex, 0, item)
  return items.map(({ key, visible }, index) => {
    return { index, key, visible }
  })
}

function doChangeItemPosition(
  currentItems,
  namespace,
  key,
  oldIndex,
  newIndex
) {
  return dispatch => {
    const items = [...currentItems]
    dispatch(
      doUpdateAgentPreference(namespace, key, {
        items: getChangedItemPostionValue(items, oldIndex, newIndex),
      })
    )
  }
}

function doUpdateItemVisibility(
  currentItems,
  preferenceNamespace,
  preferenceKey,
  itemKey,
  setVisibleTo
) {
  return dispatch => {
    const items = [...currentItems.filter(({ key }) => !key.match(/^[0-9]+$/))]
    const newValue = items.map(item => {
      return {
        ...item,
        visible: item.key === itemKey ? setVisibleTo : item.visible,
      }
    })

    const oldIndex = items.findIndex(({ key }) => key === itemKey)
    return dispatch(
      doUpdateAgentPreference(preferenceNamespace, preferenceKey, {
        items: setVisibleTo
          ? getChangedItemPostionValue(newValue, oldIndex, 0)
          : newValue,
      })
    )
  }
}

export function useReorderingAndVisibility(
  preferenceNamespace,
  preferenceKey,
  defaults,
  priorityDefaults
) {
  const dispatch = useDispatch()
  const delayedEditEditMode = useRef()
  const [isInEditMode, setIsInEditMode] = useState(false)
  const agentPrefs = useSelector(selectAgentPreferences)
  const agentPref = agentPrefs[`${preferenceNamespace}:${preferenceKey}`]
  const agentPrefKeys = agentPref?.value?.items.map(({ key }) => key)
  const currentItems = useMemo(
    () =>
      uniqBy(
        [
          ...(priorityDefaults?.filter(
            ({ key }) => !agentPrefKeys?.includes(key)
          ) || []),
          ...(agentPref ? agentPref.value.items : []),
          ...(defaults || []),
        ],
        ({ key }) => key
      ).map((item, index) => ({ ...item, index })),
    [agentPref, priorityDefaults, defaults, agentPrefKeys]
  )
  const isRightSidebarCollapsed = useSelector(selectIsRightSidebarCollapsed)
  const doEnterEditMode = useCallback(() => setIsInEditMode(true), [
    setIsInEditMode,
  ])
  const doExitEditMode = useCallback(() => setIsInEditMode(false), [
    setIsInEditMode,
  ])
  const doToggleEditMode = useCallback(() => setIsInEditMode(!isInEditMode), [
    setIsInEditMode,
    isInEditMode,
  ])
  const doExitEditModeDelayed = useCallback(
    () => {
      delayedEditEditMode.current = setTimeout(doExitEditMode, 200)
      return () => clearTimeout(delayedEditEditMode.current)
    },
    [doExitEditMode]
  )

  const doHideItem = useCallback(
    itemKey => {
      return dispatch(
        doUpdateItemVisibility(
          currentItems,
          preferenceNamespace,
          preferenceKey,
          itemKey,
          false
        )
      )
    },
    [currentItems, dispatch, preferenceNamespace, preferenceKey]
  )
  const doShowItem = useCallback(
    itemKey => {
      return dispatch(
        doUpdateItemVisibility(
          currentItems,
          preferenceNamespace,
          preferenceKey,
          itemKey,
          true
        )
      )
    },
    [currentItems, dispatch, preferenceNamespace, preferenceKey]
  )
  const doReorderItems = useCallback(
    ({ oldIndex, newIndex }) => {
      return dispatch(
        doChangeItemPosition(
          currentItems,
          preferenceNamespace,
          preferenceKey,
          oldIndex,
          newIndex
        )
      )
    },
    [currentItems, dispatch, preferenceNamespace, preferenceKey]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(doExitEditModeDelayed, [isRightSidebarCollapsed])
  return {
    hiddenItems: useMemo(() => currentItems.filter(({ visible }) => !visible), [
      currentItems,
    ]),
    items: currentItems,
    doEnterEditMode,
    doExitEditMode,
    doHideItem,
    doReorderItems,
    doShowItem,
    doToggleEditMode,
    isInEditMode,
  }
}
