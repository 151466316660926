import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { selectAppliedDiscount } from 'ducks/billing/selectors/selectAppliedDiscount'
import { useBilling } from 'ducks/billing/hooks'
import { doUpdateAccountPreference } from 'ducks/accountPreferences/operations'
import logo from 'assets/groove_logo_v2.svg'
import debug from 'util/debug'
import { propFunc } from 'util/functions'
import { selectShouldShowDiscountLockout } from 'ducks/billing/selectors/selectShouldShowDiscountLockout'
import { selectIsBillingSetupPaymentDrawerOpen } from 'ducks/billing/selectors/selectIsBillingSetupPaymentDrawerOpen'
import ClaimDiscount from './ClaimDiscount'
import SaveDiscount from './SaveDiscount'
import { styles } from './styles'

const DiscountLockout = ({ onExit, open }) => {
  const dispatch = useDispatch()
  const appliedDiscount = useSelector(selectAppliedDiscount)
  const shouldShowDiscountLockout = useSelector(selectShouldShowDiscountLockout)
  const isBillingSetupPaymentDrawerOpen = useSelector(
    selectIsBillingSetupPaymentDrawerOpen
  )
  const { isSaving, isUpdatingCard, reloadBilling } = useBilling()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSaveDiscount, setShowSaveDiscount] = useState(false)

  const handleRemoveDiscount = useCallback(
    async () => {
      setIsSubmitting(true)

      try {
        await dispatch(doUpdateAccountPreference('billing_coupon', ''))
        await reloadBilling()
      } catch (error) {
        debug(error)
      }

      setIsSubmitting(false)
      onExit()
    },
    [dispatch, reloadBilling, onExit]
  )

  useEffect(
    () => {
      if (!shouldShowDiscountLockout && !isBillingSetupPaymentDrawerOpen) {
        onExit()
      }
    },
    [shouldShowDiscountLockout, isBillingSetupPaymentDrawerOpen, onExit]
  )

  return (
    <Modal
      open={open}
      portal="#drawer-root"
      onClose={
        !showSaveDiscount ? propFunc(setShowSaveDiscount, true) : undefined
      }
      css={styles.modalPanel}
      panelCenter
      backdropStyles={styles.backdrop}
    >
      <div css={styles.header} className="grui w-100 p-11 pt-12">
        <div css={styles.logo}>
          <img src={logo} alt="GrooveHQ" className="grui mx-auto block" />
        </div>
      </div>
      {showSaveDiscount ? (
        <SaveDiscount
          coupon={appliedDiscount?.coupon}
          setShowSaveDiscount={setShowSaveDiscount}
          onRemoveDiscount={handleRemoveDiscount}
          isSaving={isSubmitting}
        />
      ) : (
        <ClaimDiscount
          coupon={appliedDiscount?.coupon}
          isSaving={isSaving || isUpdatingCard}
        />
      )}
    </Modal>
  )
}

export default DiscountLockout
