import React from 'react'
import cn from 'classnames'
import { css } from '@emotion/react'
import { IconButton } from 'shared/ui'

const CloseableStyle = css`
  &&:not(.closeable) svg {
    transform: scaleX(-1);
  }
`

const WithIconState = ({
  onClick,
  closeable,
  iconSize = 15,
  className,
  size = 'small',
}) => {
  return (
    <IconButton
      css={CloseableStyle}
      className={cn(className, { closeable })}
      name={closeable ? 'check' : 'redo'}
      svg
      // the close icon is 67% smaller than the open icon, not sure if this is the right approach
      iconSize={closeable ? iconSize * 1.67 : iconSize}
      onClick={onClick}
      size={size}
      tooltip={closeable ? 'Close [shift+c]' : 'Reopen [shift+o]'}
    />
  )
}

export default WithIconState
