import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const subtitle = theme => css`
  max-width: ${rem(640)};
  margin: ${rem(6)} auto 0;
  font-size: ${theme.fontSize.x5large};
  line-height: ${theme.lineHeight.leadingSnug};
`

const button = theme => css`
  min-height: ${rem(48)};
  padding: ${rem(11)} ${rem(30)};
  font-weight: ${theme.fontWeight.medium};
  border-radius: ${rem(28)};
`

const callToAction = theme => css`
  font-size: ${theme.fontSize.x3large};
`

const mailboxNameHighlight = theme => css`
  padding: 0 4px 4px;

  &::before {
    background-color: ${theme.color.secondary.info};
  }
`

const icon = theme => css`
  min-height: ${rem(80)};
  font-size: ${rem(80)};
  color: ${theme.color.primary.info};
  background-color: ${theme.color.secondary.info};
  border-radius: ${rem(80)};
  padding: ${theme.spacing['6']};
`

export const styles = {
  subtitle,
  button,
  callToAction,
  mailboxNameHighlight,
  icon,
}
