import { connect } from 'react-redux'
import { selectIsAccountSubdomain } from 'selectors/location'
import LoginAgentMissing from './view'

const select = state => {
  return {
    isAccountSubdomain: selectIsAccountSubdomain(state),
  }
}

export default connect(select)(LoginAgentMissing)
