import { connect } from 'react-redux'
import { fetchingStatusesSelector } from 'selectors/app'
import { selectCurrentRawEmail } from 'selectors/raw_emails'
import RawEmailPage from './view'

const select = state => {
  const email = selectCurrentRawEmail(state) || {}
  const statuses = fetchingStatusesSelector(state)
  return {
    headers: email.headers,
    plain: email.plain,
    html: email.html,
    retrieved: email.retrieved,
    fetching: statuses.fetchRawEmail,
  }
}

export default connect(select)(RawEmailPage)
