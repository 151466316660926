import styles from './styles.less'

export default function SidebarDotContent({ color }) {
  return !color ? null : (
    <span
      className={styles.SidebarItem_dot}
      style={{ backgroundColor: color }}
    />
  )
}
