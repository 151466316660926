// This is a 'special' label - and slightly different from our usual Label
// typography element.
import styled from '@emotion/styled'
import cn from 'classnames'

const Label = ({ className, children, ...props }) => {
  return (
    <div className={cn('label', className)} {...props}>
      {children}
    </div>
  )
}

export default styled(Label)`
  color: ${props => props.theme.color.monochrome.superDark};
  line-height: 26px;
  font-weight: 500;
`
