import React from 'react'
import { downcase } from 'util/strings'
import ListenToKeyboard from 'components/ListenToKeyboard'

export default class ClickOutside extends React.Component {
  static defaultProps = {
    onTrigger: undefined,
    detectEscKey: false,
    disabled: false,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true)
  }

  handleClick = e => {
    const { disabled, onTrigger } = this.props

    const isClickedInside = e && this.node && this.node.contains(e.target)
    const isLink = downcase(e && e.target && e.target.tagName) === 'a'

    if (!disabled && !isClickedInside && !isLink) {
      if (typeof onTrigger === 'function') onTrigger(e)
      e.stopImmediatePropagation()
      e.preventDefault()
    }

    this.nodeClick = false
  }

  handleEscape = e => {
    const { disabled, onTrigger } = this.props
    if (!disabled && typeof onTrigger === 'function') {
      onTrigger(e)
    }
  }

  takeRef = node => {
    this.node = node
  }

  render() {
    return (
      <div className={this.props.className} ref={this.takeRef}>
        <ListenToKeyboard onEscape={this.handleEscape} />
        {this.props.children}
      </div>
    )
  }
}
