import { connect } from 'react-redux'
import { doBulkChangeMailbox } from 'actions/tickets'
import { selectPossibleMailboxesForChange } from 'selectors/mailboxes'
import BulkChangeInboxDropdownView from './view'

const select = state => ({
  mailboxes: selectPossibleMailboxesForChange(state),
})

const perform = {
  doChangeMailbox: doBulkChangeMailbox,
}

export default connect(select, perform)(BulkChangeInboxDropdownView)
