import React from 'react'
import cn from 'classnames'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { propFunc } from 'util/functions'
import SearchItem from './SearchItem'
import oldStyles from './styles.css'
import { styles } from './styles'

const RecentSearches = ({ className, list = [], onSubmit }) => {
  return (
    <div className={className}>
      <div className={cn(oldStyles.SearchSuggestions_title, 'grui mb-4')}>
        Recent searches
      </div>
      <div className="grui mx-3" css={styles.innerList}>
        {list.map(query => (
          <KeyboardNavigator.Item
            key={query}
            css={styles.recentSearch}
            className="grui px-5 py-4 truncate"
            onSelect={propFunc(onSubmit, query, {
              shouldIncludePreviousSubmittedFilters: false,
            })}
            // Prevent setting default active
            active={false}
          >
            <SearchItem query={query} />
          </KeyboardNavigator.Item>
        ))}
      </div>
    </div>
  )
}

export default RecentSearches
