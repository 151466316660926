import React from 'react'
import Modal from 'components/Modal'
import ListenToKeyboard from 'components/ListenToKeyboard'
import styles from './styles.css'
import Options from '../../Options'

export default function SearchModifer({ isOpen, closeModal }) {
  if (!isOpen) return null
  return (
    <Modal contentClass={styles.content} onBackgroundClick={closeModal}>
      <ListenToKeyboard onEscape={closeModal} />
      <Options options={'state'} onSelect={closeModal} />
    </Modal>
  )
}
