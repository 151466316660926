import { css, useTheme } from '@emotion/react'
import EmptyMessage from 'components/EmptyMessage'
import SandboxedIframe from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/CustomProfile/SandboxedIframe'
import {
  containerStyles,
  rawCssIframe,
} from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/CustomProfile/view'
import { IntegrationError, Loader } from 'shared/ui'

import SanitizedHTML from 'shared/components/ui/SanitizedHTML'

const SANITIZE_CONFIG = { ADD_TAGS: ['iframe'] }

const loaderStyle = css`
  margin-top: 15px;
`

export default function CustomProfileContent({
  isFailed,
  isLoading,
  isSafe,
  onRetry,
  text,
}) {
  const theme = useTheme()

  if (isLoading) return <Loader css={loaderStyle} />
  if (isFailed) return <IntegrationError onRetry={onRetry} />
  if (typeof text !== 'string') {
    return <EmptyMessage>No custom profile found for this user.</EmptyMessage>
  }

  if (isSafe) {
    return (
      <SanitizedHTML
        css={containerStyles}
        html={text}
        config={SANITIZE_CONFIG}
      />
    )
  }
  return <SandboxedIframe html={text} rawCss={rawCssIframe(theme)} />
}
