import { memo, useMemo } from 'react'

import SmileyIcon from '@groovehq/internal-design-system/lib/assets/icons/Smiley'
import EmojiPickerButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/EmojiPickerButton'
import Reaction from './Reaction'

import { styles } from './styles'

const ReactionList = ({ reactions, currentUserId, onClick }) => {
  const filteredReactions = useMemo(
    () => {
      return Object.keys(reactions).reduce((result, reaction) => {
        const newResult = { ...result }

        if (reactions[reaction].length) {
          newResult[reaction] = reactions[reaction]
        }

        return newResult
      }, {})
    },
    [reactions]
  )

  const reactionsList = useMemo(() => Object.keys(filteredReactions), [
    filteredReactions,
  ])

  if (!reactionsList.length) {
    return null
  }

  return (
    <div css={styles.list}>
      {reactionsList.map(reaction => {
        return (
          <Reaction
            key={reaction}
            allReactions={filteredReactions[reaction]}
            reaction={reaction}
            currentUserId={currentUserId}
            onReactionClick={onClick}
          />
        )
      })}
      {reactionsList.length && (
        <EmojiPickerButton direction="right" onChoose={onClick}>
          <button className="grui" css={[styles.reaction, styles.button]}>
            <SmileyIcon />
          </button>
        </EmojiPickerButton>
      )}
    </div>
  )
}

export default memo(ReactionList)
