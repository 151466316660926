import React from 'react'
import styled from '@emotion/styled'

const ColorIcon = ({ className }) => <span className={className} />

const Styled = styled(ColorIcon)`
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background-color: ${props => props.color || '#58a2fb'};
  flex-grow: 0;
  flex-shrink: 0;
`

export default Styled
