import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectSubscriptionForId } from 'ducks/integrations/recharge/selectors'
import { DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER } from 'ducks/drawers/types'
import { Button } from 'util/ui'

import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function Footer({
  integration,
  charge,
  customerId,
  subscriptionId,
}) {
  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )

  const drawerResourceId = useMemo(
    () => `${subscription.customer_id}-${subscription.id}-${charge.id}`,
    [subscription, charge]
  )

  const {
    drawerId: skipNextOrderDrawerId,
    openDrawer: openSkipDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER,
  })

  const canShowSkipButton = useMemo(
    () => subscription.status === 'active' && charge.status === 'queued',
    [subscription, charge]
  )

  const onSkipClick = useCallback(
    () => {
      openSkipDrawer(drawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            skipNextOrderDrawerId,
            'drawerIntegrationId',
            integration.id
          ),
        },
      })
    },
    [openSkipDrawer, skipNextOrderDrawerId, integration, drawerResourceId]
  )

  if (!canShowSkipButton) {
    return null
  }

  return (
    <NavigatableIntegrationWidgetCard.Footer>
      <Tooltip
        disabled={!subscription.is_prepaid}
        position="top-end"
        title="Subscription is prepaid and can't be skipped."
      >
        <div>
          <Button
            basic
            disabled={subscription.is_prepaid}
            onClick={onSkipClick}
          >
            Skip next order
          </Button>
        </div>
      </Tooltip>
    </NavigatableIntegrationWidgetCard.Footer>
  )
}
