import { connect } from 'react-redux'

import { doToggleRightSidebar } from 'actions/app'
import { selectIsRightSidebarCollapsed } from 'selectors/app'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectIsInChat } from 'selectors/location'

import RightSidebarToggler from './view'

const select = state => {
  return {
    isClassicView: selectPrefersClassicView(state),
    isCollapsed: selectIsRightSidebarCollapsed(state),
    isInChat: selectIsInChat(state),
  }
}

const perform = {
  doToggle: doToggleRightSidebar,
}

export default connect(select, perform)(RightSidebarToggler)
