import { selectAgent } from 'selectors/agents/base'
import { selectGroup } from 'selectors/app'

export function getAssignmentLabel(state, assigneeId, assigneeGroupId) {
  const assignee = selectAgent(state, assigneeId)
  const group = selectGroup(state, assigneeGroupId)
  let assignmentLabel = assignee ? assignee.label : null
  if (group && group.name) {
    assignmentLabel = assignmentLabel
      ? `${group.name}: ${assignmentLabel}`
      : group.name
  }

  return assignmentLabel
}
