import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'
import santahat from 'assets/santahat.png'

const menuStyle = () => theme => css`
  min-width: ${rem(232)};
  color: ${theme.color.monochrome.black};
  border-radius: ${rem(8)};
  font-size: ${theme.fontSize.large};

  [role='menuitem'] {
    color: ${theme.color.monochrome.black};

    &[tabindex='0']:hover,
    &.highlighted {
      background-color: ${theme.color.info['50']} !important;
      .item-icon {
        background-color: ${theme.color.monochrome.white};
      }
    }
  }

  [role='separator'] ~ [role='menuitem'] {
    .item-icon {
      background-color: ${theme.color.monochrome.superLight};
    }
    &[tabindex='0']:hover,
    &.highlighted {
      background-color: ${theme.color.monochrome.superLight} !important;
      .item-icon {
        background-color: ${theme.color.monochrome.white};
      }
    }
  }
`

const avatarContainer = css`
  position: relative;
  cursor: pointer;
`

const menuItemIcon = theme => css`
  background-color: ${theme.color.info['50']};
  width: ${rem(32)};
  height: ${rem(32)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  color: ${theme.color.info['900']};

  svg {
    width: 100%;
    height: 100%;

    &.sm-icon {
      margin: ${rem(2)};
    }
  }
`

const highlightMenuItem = theme => css`
  .item-icon {
    background-color: ${theme.color.warningAlt['200']};
  }

  &&&[role='menuitem']:hover,
  &&&[role='menuitem'].highlighted {
    background-color: ${theme.color.secondary.warningAlt} !important;

    .item-icon {
      background-color: ${theme.color.warningAlt['200']};
    }
  }
`

const santa = css`
  overflow: visible;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 41px;
    left: 0px;
    top: -8px;
    background: url(${santahat}) top left no-repeat;
    background-size: contain;
  }
`

export const styles = {
  menuStyle,
  avatarContainer,
  menuItemIcon,
  highlightMenuItem,
  santa,
}
