import { useMemo } from 'react'

import { useSelector } from 'react-redux'

import { selectLastSeenAt } from 'ducks/crm/contacts'
import CustomFieldKeyValue from '../../CustomFieldKeyValue'

export default function DerivedLastSeenAt({
  customFieldKey,
  fieldHidden,
  isInEditMode,
  options,
  onEye,
  subjectId,
}) {
  const content = useSelector(selectLastSeenAt)
  const value = useMemo(() => ({ content }), [content])
  return (
    <CustomFieldKeyValue
      isEditable={false}
      fieldKey={customFieldKey}
      fieldHidden={fieldHidden}
      icon="clock"
      isInEditMode={isInEditMode}
      options={options}
      onEye={onEye}
      subjectId={subjectId}
      title="Last seen at"
      type={'SINGLE_LINE'}
      value={value}
    />
  )
}
