import styled from '@emotion/styled'
import { Label } from 'shared/components/ui/Typography'
import SpacedItems from 'shared/components/ui/SpacedItems'
import themeVars from 'ui_theme/site/globals/site.variables'

const Container = styled(SpacedItems.Container)`
  padding: ${props => props.theme.spacing['10']}
    ${props => props.theme.spacing['12']} 0px ${themeVars.big}};
`

export default function SearchErrorMessage() {
  return (
    <Container gap="tiny" direction="vertical">
      <SpacedItems.Item>
        <Label size="large" color="berry">
          Search failed
        </Label>
      </SpacedItems.Item>
      <SpacedItems.Item>
        <p>
          This issue has automatically been logged and sent to our engineering
          team.
        </p>
        <p>Please refresh your browser to try again.</p>
      </SpacedItems.Item>
    </Container>
  )
}
