import { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { selectCurrentCompanyId } from 'ducks/crm/companies'
import themeVars from 'ui_theme/site/globals/site.variables'
import { emptyArr } from 'util/arrays'

import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'
import CompanyHeaderFieldsWithMenuPositioning from './CompanyHeaderFieldsWithMenuPositioning'

export default styled(CompanyHeaderFields)`
  &&& {
    color: ${props => props.theme.color.monochrome.black};
    display: flex;
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    .avatar {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 4px;
      margin-right: ${props => props.theme.spacing['5']};
      border-radius: 2px;
    }

    .nameAndDomain {
      min-width: 0;
      flex-grow: 1;
    }

    .name,
    .email {
      font-size: 12px;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      font-size: 14px;
      font-weight: ${themeVars.graphikWeightMedium};
      line-height: 21px;
    }

    .action {
      cursor: pointer;
      border-radius: ${themeVars.defaultBorderRadius};
      padding: 4px 3px;
      width: 24px;
      height: 24px;
      text-align: center;
      opacity: 0;
      margin: -1px 0;
      flex-shrink: 0;
      flex-grow: 0;
      transition: opacity ${themeVars.defaultDuration};

      &:hover {
        background: ${props => props.theme.color.monochrome.light};
      }

      .icon {
        height: ${props => props.theme.spacing['10']};
        padding: 0;
        margin: 0;
        color: ${props => props.theme.color.monochrome.mediumDark};
        &.small.icon {
          font-size: 12px;
        }
      }
    }

    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
`

function CompanyHeaderFields({ className }) {
  const [openMenu, doSetMenu] = useState(null)
  const companyId = useSelector(selectCurrentCompanyId)
  const domain = useSelectCustomFieldValueForKeyAndType(
    'company_domain',
    'COMPANY'
  )
  const name = useSelectCustomFieldValueForKeyAndType('company_name', 'COMPANY')
  const secondaryDomains =
    useSelectCustomFieldValueForKeyAndType(
      'company_secondary_domains',
      'COMPANY'
    ) || emptyArr
  const domains = useMemo(() => [domain || '', ...secondaryDomains], [
    domain,
    secondaryDomains,
  ])

  return (
    <CompanyHeaderFieldsWithMenuPositioning
      className={className}
      domains={domains}
      name={name}
      doSetMenu={doSetMenu}
      openMenu={openMenu}
      subjectId={companyId}
    />
  )
}
