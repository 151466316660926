import { connect } from 'react-redux'

import {
  doCancelManageLabels,
  doCreateLabel,
  doUpdateLabel,
} from 'actions/labels'

import { selectIsManageLabelsModalOpen } from 'selectors/labelings'
import {
  selectIsCreatingALabel,
  selectIsEditingALabel,
  selectIsDeletingALabel,
} from 'selectors/modals'

import LabelsModal from './view'

const select = state => {
  return {
    isOpen: selectIsManageLabelsModalOpen(state),
    isCreating: selectIsCreatingALabel(state),
    isEditing: selectIsEditingALabel(state),
    isDeleting: selectIsDeletingALabel(state),
  }
}

const perform = {
  onCreate: doCreateLabel,
  onUpdate: doUpdateLabel,
  onClose: doCancelManageLabels,
}

export default connect(select, perform)(LabelsModal)
