import { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'

import { selectCurrentContactIdOrTemp } from 'ducks/crm/contacts'
import { doStopEditing, selectIsEditing } from 'ducks/inAppCards/customer'
import themeVars from 'ui_theme/site/globals/site.variables'

import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'
import ContactHeaderFieldsWithMenuPositioning from './ContactHeaderFieldsWithMenuPositioning'

export default styled(ContactHeaderFields)`
  &&& {
    color: ${props => props.theme.color.monochrome.black};
    display: flex;
    margin-bottom: 18px;
    min-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    .avatar {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 4px;
      margin-right: ${props => props.theme.spacing['5']};
    }

    .nameAndEmail {
      min-width: 0;
      flex-grow: 1;
    }

    .name,
    .email {
      font-size: 12px;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      font-size: 14px;
      font-weight: ${themeVars.graphikWeightMedium};
      line-height: 21px;
    }

    .action {
      cursor: pointer;
      border-radius: ${themeVars.defaultBorderRadius};
      padding: 4px 3px;
      width: 24px;
      height: 24px;
      text-align: center;
      opacity: 0;
      margin: -1px 0;
      flex-shrink: 0;
      flex-grow: 0;
      transition: opacity ${themeVars.defaultDuration};

      &:hover {
        background: ${props => props.theme.color.monochrome.light};
      }

      .icon {
        height: ${props => props.theme.spacing['10']};
        padding: 0;
        margin: 0;
        color: ${props => props.theme.color.monochrome.mediumDark};
        &.small.icon {
          font-size: 12px;
        }
      }
    }

    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
`

function ContactHeaderFields({ className }) {
  const dispatch = useDispatch()
  const [openMenu, doSetMenu] = useState(null)
  const subjectId = useSelector(selectCurrentContactIdOrTemp)
  const isEditingCustomer = useSelector(selectIsEditing)

  const firstName = useSelectCustomFieldValueForKeyAndType(
    'contact_first_name',
    'CONTACT'
  )
  const lastName = useSelectCustomFieldValueForKeyAndType(
    'contact_last_name',
    'CONTACT'
  )
  const email = useSelectCustomFieldValueForKeyAndType(
    'contact_email',
    'CONTACT'
  )

  useEffect(
    () => {
      if (isEditingCustomer) {
        doSetMenu('edit')
      }
    },
    [isEditingCustomer]
  )

  const handleCancel = useCallback(
    () => {
      if (isEditingCustomer) {
        dispatch(doStopEditing())
      }
    },
    [isEditingCustomer, dispatch]
  )

  if (!subjectId) return null
  return (
    <ContactHeaderFieldsWithMenuPositioning
      className={className}
      doSetMenu={doSetMenu}
      email={email}
      firstName={firstName}
      lastName={lastName}
      openMenu={openMenu}
      subjectId={subjectId}
      onCancel={handleCancel}
    />
  )
}
