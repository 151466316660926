import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const statusIndicatorBadge = theme => css`
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
  line-height: 1;
  border-radius: ${rem(10)};
  background-color: ${theme.color.primary.info};
  color: ${theme.color.monochrome.white};
  height: ${rem(8)};
  min-width: ${rem(8)};
  margin-left: auto;

  &.critical {
    background-color: ${theme.color.primary.negative};
  }
  &.major {
    background-color: ${theme.color.primary.negativeAlt};
  }
  &.minor {
    background-color: ${theme.color.primary.warning};
  }
  &.none {
    background-color: ${theme.color.primary.positive};
  }
`

export const styles = {
  statusIndicatorBadge,
}
