import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'shared/ui'
import { propFunc } from 'util/functions'

const Container = styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 16px;
  background-color: ${props => props.theme.color.monochrome.white};
`

const Header = styled('div')`
  align-items: center;
  color: hsla(0, 0%, 75%, 1);
  display: flex;
  font-size: 28px;
  padding: 17px 24px;
`

const LeftSide = styled('div')`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 8px;
  }
`

const leftSideStyle = () => theme => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
  text-transform: uppercase;
  color: ${theme.color.primary.brand};
`

const RightSide = styled('div')`
  ${LeftSide};
  ${props => (props.isRightGap ? 'margin-right: 48px' : '')};
  margin-left: auto;
  transition: margin-right 0.2s ease-in-out;
`

export default function HeaderEditingTitleState({
  onStopEditingTitle,
  isRightSidebarCollapsed,
  conversationId,
}) {
  return (
    <Container>
      <Header>
        <LeftSide css={leftSideStyle}>Edit subject</LeftSide>
        <RightSide isRightGap={isRightSidebarCollapsed}>
          <Icon
            name="close"
            size="medium"
            css={css`
              margin-right: -6px;
              margin-top: -2px;
              margin-bottom: -2px;
              padding: 4px;
              font-size: 20px;
              color: hsla(0, 0%, 80%, 1);
              cursor: pointer;
            `}
            onClick={propFunc(onStopEditingTitle, conversationId)}
          />
        </RightSide>
      </Header>
    </Container>
  )
}
