import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import doCreateContact from 'ducks/integrations/hubspot/operations/doCreateContact'
import withFormFields from 'shared/ui/Form/withFormFields'
import { required, join, email } from 'util/validation'
import { selectCurrentCustomer } from 'selectors/tickets/customer/selectCurrentCustomer'
import {
  selectCreateContactError,
  selectNewContactInitialValues,
} from 'ducks/integrations/hubspot/selectors'

import View from './view'

function mapStateToProps(state) {
  return {
    createContactError: selectCreateContactError(state),
    customer: selectCurrentCustomer(state),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    doCreateContact: () => {
      dispatch(doCreateContact(props.formFields))
        .then(() => {
          props.resetForm()
          props.onClose()
        })
        .catch(() => {})
    },
    onClose: () => {
      props.resetForm()
      props.onClose()
    },
  }
}

const Form = compose(
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(View)

const validators = {
  email: join([email, required]),
}

function select(state) {
  return {
    initialValues: selectNewContactInitialValues(state),
  }
}

class LinkForm extends React.PureComponent {
  render() {
    return <Form {...this.props} validators={validators} />
  }
}

const ConnectedLinkForm = connect(select)(LinkForm)

export default ConnectedLinkForm
