import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { styles } from './SendInvoice.styles'

const { Button } = Dropdown

function FromDropdown({ control, name, list }) {
  const {
    field: { onChange, value, onBlur, ref },
  } = useController({
    name,
    control,
  })

  const handleSelectFromEmail = useCallback(
    email => {
      onChange(email)
    },
    [onChange]
  )

  const convertedList = useMemo(
    () => {
      return list.map(val => ({ id: val, name: val, value: val }))
    },
    [list]
  )

  return (
    <Dropdown
      overlay={<DropdownMenu data={convertedList} />}
      onSelect={handleSelectFromEmail}
      selectedKey={value}
    >
      <Button css={styles.dropdownBtn} ref={ref} onBlur={onBlur}>
        {value || ''}
      </Button>
    </Dropdown>
  )
}

export default FromDropdown
