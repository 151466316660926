import React, { useCallback } from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { BoxHeadline, Text } from 'shared/ui'
import { selectPresenceByAgentId } from 'ducks/chat/selectors/presence'

import AvatarForAgentItem from './AvatarForAgentItem'
import styles from './styles.css'

const agentItemTextName = ({ isUnassigned, hint, agent, agents }) => {
  if (!isUnassigned) return hint || agent.name

  if (isUnassigned && agents) {
    if (agents.length > 2) {
      const text = agents
        .splice(0, 2)
        .map(a => a.username || a.first_name)
        .join(', ')
      return `${text}...`
    }

    return agents.map(a => a.username || a.first_name).join(', ')
  }
  return ''
}

const Label = styled(({ children, className }) => (
  <BoxHeadline className={className} as="span">
    {children}
  </BoxHeadline>
))`
  .currentChosen & {
    color: ${props => props.theme.color.primary.brand} !important;
  }
`

const AgentItem = ({
  agents,
  agent,
  agentId,
  className,
  isUnassigned = false,
  isActive,
  isCurrentChosen,
  onClick,
  hint = null,
  trackActiveElement = null,
  canShowPresence,
}) => {
  const onRef = useCallback(
    node => {
      if (isActive && trackActiveElement) trackActiveElement(node)
    },
    [isActive, trackActiveElement]
  )

  // no use fetching online status on non-chat screen. This can be removed when we want it to show for all
  const presence = useSelector(state =>
    selectPresenceByAgentId(state, canShowPresence ? agentId : null)
  )

  return (
    <div
      className={cn(className, styles.itemOuter, {
        [styles.unassignedItemOuter]: isUnassigned,
      })}
      onClick={onClick}
      ref={onRef}
    >
      <div
        className={cn(styles.agent, styles.item, {
          [styles.unassignedItem]: isUnassigned,
          [styles.activeItem]: isActive,
          currentChosen: isCurrentChosen,
        })}
      >
        {!isUnassigned && (
          <AvatarForAgentItem
            agents={agents}
            agent={agent}
            presence={presence}
          />
        )}
        <div
          className={cn(styles.item_text, {
            [styles.item_text_anyone]: isUnassigned,
          })}
        >
          <Label>{agent.label}</Label>
          {!isUnassigned && (
            <Text
              size="small"
              as="span"
              className="grui ml-4"
              css={styles.agentName}
            >
              {agentItemTextName({ isUnassigned, hint, agent, agents })}
            </Text>
          )}
        </div>
        <span className={cn('Icon Icon-keyboard-return', styles.returnIcon)} />
      </div>
    </div>
  )
}

export default AgentItem
