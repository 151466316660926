import React, { Fragment, PureComponent } from 'react'
import cn from 'classnames'

import Scroller from 'components/Scroller'
import ScrollerContext from 'components/Scroller/Context'
import DefaultListItem from 'components/ConversationList/ListItem'
import ListenToKeyboard from 'components/ListenToKeyboard'

import { Loader } from 'shared/ui'
import { debounce } from 'util/functions'

import { isSafari10 } from 'util/browser'

import DefaultEmptyState from './EmptyState'
import ListLoaded from './ListLoaded'
import EndIndicator from './EndIndicator'

import styles from './styles.less'

function getHeightStyle() {
  /**
   * Safari 10 doesn't calculate height of flex children correctly, so we need
   * to set height explicitly for children to use it as basis
   * https://bugs.webkit.org/show_bug.cgi?id=137730
   */
  if (!isSafari10()) return {}

  const header = document.querySelector('[class*="ConversationListHeader_"]')
  if (!header) return {}

  const headerHeight = header.getBoundingClientRect().height
  return { height: `calc(100% - ${headerHeight}px)` }
}

class ConversationList extends PureComponent {
  static contextType = ScrollerContext

  componentDidMount = () => {
    const { onComponentDidMount } = this.props
    if (onComponentDidMount) {
      onComponentDidMount(this)
    }
  }

  onClick = (id, event) => {
    const { isSelectionMode, toggleConversationSelection } = this.props
    if (isSelectionMode) {
      event.preventDefault()
      event.stopPropagation()
      toggleConversationSelection(id)
      return false
    }
    return true
  }

  onAltClick = id => {
    const {
      isSelectionMode,
      markBulkSelectionMode,
      toggleConversationSelection,
    } = this.props
    if (!isSelectionMode) {
      markBulkSelectionMode([id])
    } else {
      toggleConversationSelection(id)
    }
  }

  onShiftClick = id => {
    const {
      isSelectionMode,
      markBulkSelectionMode,
      onRangedSelection,
    } = this.props
    if (!isSelectionMode) markBulkSelectionMode()
    onRangedSelection(id)
  }

  onConversationKeyboardActivate = (id, e) => {
    const { isSelectionMode, openConversationPage } = this.props
    if (!isSelectionMode) openConversationPage(id)
    this.onSelect(id, e)
  }

  onReachTop = () => {
    const { onTopChanged } = this.props
    onTopChanged(true)
  }

  onLeaveTop = () => {
    const { onTopChanged } = this.props
    onTopChanged(false)
  }

  saveScrollerRef = scrollerComponent => {
    if (scrollerComponent) {
      this.scroller = scrollerComponent
    }
  }

  hasMoreItemsToLoad() {
    const {
      conversationIds,
      isLoadingMore,
      currentPage,
      totalPages,
    } = this.props

    const hasConversations = conversationIds.some(e => e)
    const hasMore = hasConversations && currentPage < totalPages

    return !!isLoadingMore || !!hasMore
  }

  moveUp = () => {
    const { currentConversationId, conversationIds } = this.props
    const index = conversationIds.indexOf(currentConversationId)
    const length = conversationIds.length
    let nextConversationId
    if (index === 0) {
      nextConversationId = conversationIds[length - 1]
    } else {
      nextConversationId = conversationIds[index - 1]
    }
    if (nextConversationId) this.navigateTo(nextConversationId)
  }

  moveDown = () => {
    const { currentConversationId, conversationIds } = this.props
    const index = conversationIds.indexOf(currentConversationId)
    const length = conversationIds.length
    let nextConversationId
    if (index === length - 1) {
      if (!this.hasMoreItemsToLoad()) {
        // last, jump to first
        // (only if all items have been loaded, not just the page)
        nextConversationId = conversationIds[0]
      }
    } else {
      nextConversationId = conversationIds[index + 1]
    }
    if (nextConversationId) this.navigateTo(nextConversationId)
  }

  navigateTo = debounce(nextConversationId => {
    const { navigateToConversation } = this.props
    navigateToConversation(nextConversationId)
  }, 50)

  render() {
    const {
      loadMore,
      hasLoaded,
      is3ColumnView,
      isLoadingMore,
      isSelectionMode,
      isSidebarOpen,
      isConversationsCurtainVisible,
      selectedConversationIds,
      conversationIds,
      onListLoaded,
      totalCount,
      conversationListQueryKey,
      ListItem = DefaultListItem,
      EmptyState = DefaultEmptyState,
      canLoadMore,
      currentSortOrder,
      footer,
    } = this.props

    const hasConversations = conversationIds.some(e => e)
    const triggerListLoaded =
      (hasConversations && !isLoadingMore) || isSidebarOpen
    const hasMore = hasConversations && canLoadMore

    return (
      <Fragment>
        <ListenToKeyboard
          onUp={this.moveUp}
          onDown={this.moveDown}
          onJ={this.moveUp}
          onK={this.moveDown}
          preventDefault
          disableForInput
        />
        <div className="ConversationList" style={getHeightStyle()}>
          {!hasLoaded && <Loader className={styles.loader} />}
          {!hasConversations && hasLoaded && <EmptyState />}
          {hasLoaded &&
            hasConversations && (
              <Scroller
                ref={this.saveScrollerRef}
                loadMore={loadMore}
                hasMore={hasMore}
                loadingMore={isLoadingMore}
                loader={<Loader className={styles.loader} />}
                footer={footer}
                endIndicator={
                  <EndIndicator key={'conversation-end-indicator'} />
                }
                className={cn(
                  'ConversationListItems',
                  'desktopNormalScrollbarDisableRightBorder',
                  {
                    'ConversationListItems--bulk-select-on': !!isSelectionMode,
                    [styles.isLoadingMore]: isLoadingMore,
                  }
                )}
              >
                {conversationIds
                  .filter(e => e)
                  .map(conversationId => (
                    <ListItem
                      key={conversationId}
                      conversationId={conversationId}
                      selected={
                        selectedConversationIds.indexOf(conversationId) > -1
                      }
                      is3ColumnView={is3ColumnView}
                      currentSortOrder={currentSortOrder}
                    />
                  ))}
              </Scroller>
            )}
          {triggerListLoaded && (
            <ListLoaded
              key={conversationListQueryKey}
              onListLoaded={onListLoaded}
              totalCount={totalCount}
              queryString={conversationListQueryKey}
            />
          )}
          {isConversationsCurtainVisible && (
            <div className="ConversationList_curtain" />
          )}
        </div>
      </Fragment>
    )
  }
}

export default ConversationList
