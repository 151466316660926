import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import RadioButtons from '@groovehq/internal-design-system/lib/components/RadioButtons/RadioButtons'
import appleQR from 'assets/apple_qr.png'
import androidQR from 'assets/android_qr.png'
import appleStore from 'assets/appstore.png'
import googleStore from 'assets/googleplay.png'
import { doSetFlag } from 'ducks/flags/operations'
import { HIDE_INSTALL_APP_MENU } from 'ducks/flags/flagNames'

import { styles } from './styles'

const apps = {
  ios: {
    qrAlt: 'IOS app qrcode',
    qrcodeImg: appleQR,
    storeAlt: 'Apple store',
    storeImg: appleStore,
    storeUrl: 'https://apps.apple.com/groove-help-desk/id1509476768',
  },
  android: {
    qrAlt: 'Android app qrcode',
    qrcodeImg: androidQR,
    storeAlt: 'Google play',
    storeImg: googleStore,
    storeUrl: 'https://play.google.com/store/apps/details?id=com.groovehq.app',
  },
}

const AppsMenu = ({ className, onVisibleChange, setOpen, showHideButton }) => {
  const [option, setOption] = useState('ios')
  const app = apps[option]
  const dispatch = useDispatch()

  const handleChange = useCallback(e => {
    setOption(e.target.value)
  }, [])

  const handleClose = useCallback(
    () => {
      if (onVisibleChange) {
        onVisibleChange(false)
      }
      if (setOpen) {
        setOpen(false)
      }
      dispatch(doSetFlag(HIDE_INSTALL_APP_MENU))
    },
    [onVisibleChange, setOpen, dispatch]
  )

  return (
    <div css={styles.container} className={className}>
      <div css={styles.heading}>Groove on the move 📱</div>
      <p>
        Install our mobile app to keep on top of your conversations while on the
        move.
      </p>
      <p css={styles.copy}>
        Scan the QR code with your phone’s camera below to get started!
      </p>

      <RadioButtons
        value={option}
        onChange={handleChange}
        name="app-type"
        css={styles.redioButtons}
      >
        <RadioButtons.Button value="ios">iOS</RadioButtons.Button>
        <RadioButtons.Button value="android">Android</RadioButtons.Button>
      </RadioButtons>
      <div css={styles.qr}>
        <img src={app.qrcodeImg} alt={app.qrAlt} width={183} height={183} />
      </div>
      <a
        css={styles.storeImg}
        href={app.storeUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={app.storeImg} alt={app.storeAlt} width={96} height={32} />
      </a>
      {showHideButton && (
        <button
          type="button"
          css={[button.styles.tertiaryLink, styles.buttonLink]}
          onClick={handleClose}
        >
          Don’t show me this again
        </button>
      )}
    </div>
  )
}

export default AppsMenu
