import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'shared/ui'
import { itemsDifferByKey } from 'util/arrays'

const buildOption = (key, text, onClick) => ({
  key,
  onClick,
  text,
  value: key,
})

// The first DD option will change, depending on the user preference.
// By default
//   - the Reply button defaults to Reply and Close
//     - the first DD option will be
//     -  if the ticket is open
//       - 'Reply and leave open'
//     - otherwise 'Reply and open'
//
// However if the user preference is set to Send as 'open'
//   - the Reply button defaults to Reply and Open
//     - the first DD option will be 'Reply and (leave) closed'
//
const firstOption = (isOpen, prefersOpen, onOpen, onClose) => {
  if (!prefersOpen) {
    // the default
    if (isOpen) return ['leave open', onOpen]
    return ['open', onOpen]
  }
  if (!isOpen) return ['leave closed', onClose]
  return ['close', onClose]
}

const buildFirstOption = (isOpen, prefersOpen, onOpen, onClose, action) => {
  const [label, handler] = firstOption(isOpen, prefersOpen, onOpen, onClose)
  return buildOption(label, `${action} & ${label}`, handler)
}

const buildOptions = (
  isOpen,
  prefersOpen,
  onOpen,
  onClose,
  onSnooze,
  action
) => {
  const openOrCloseOpt = buildFirstOption(
    isOpen,
    prefersOpen,
    onOpen,
    onClose,
    action
  )
  const snoozeOption = buildOption('snoozed', `${action} & snooze...`, onSnooze)
  return [openOrCloseOpt, snoozeOption]
}

class ReplyDropdown extends React.PureComponent {
  componentWillUpdate(nextProps) {
    this.recalculateOptions(nextProps)
  }

  getOptions() {
    return this._options || this.recalculateOptions(this.props)
  }

  // recalculateOptions ensures that the options array we pass
  // is only changed when required
  recalculateOptions(props) {
    const {
      actionLabel,
      onSendOpenClick,
      onSendClosedClick,
      onSendSnoozedClick,
      isOpen,
      prefersOpen,
    } = props

    const newOptions = buildOptions(
      isOpen,
      prefersOpen,
      onSendOpenClick,
      onSendClosedClick,
      onSendSnoozedClick,
      actionLabel
    )

    if (itemsDifferByKey(this._options, newOptions, 'text', 'onClick')) {
      this._options = newOptions
    }

    return this._options
  }

  render() {
    const {
      actionLabel,
      isOpen,
      onSendClosedClick,
      onSendOpenClick,
      onSendSnoozedClick,
      prefersOpen,
      ...rest
    } = this.props
    return (
      <Dropdown
        {...rest}
        header={`${actionLabel} options`}
        options={this.getOptions()}
      />
    )
  }
}

ReplyDropdown.propTypes = {
  /* Reply dropdown action prefix e.g. "Reply" as in "Reply & blah" */
  actionLabel: PropTypes.string.isRequired,
  /* Whether the dropdown menu is disabled */
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  /* onClick handlers for the button + each of the 3 DD menu options */
  onSendOpenClick: PropTypes.func.isRequired,
  onSendClosedClick: PropTypes.func.isRequired,
  onSendSnoozedClick: PropTypes.func.isRequired,
  prefersOpen: PropTypes.bool.isRequired,
}

ReplyDropdown.defaultProps = {
  disabled: false,
}

export default ReplyDropdown
