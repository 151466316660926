import React from 'react'

class AutogrowingTextarea extends React.Component {
  componentDidMount() {
    this.autoGrow(this.textarea)

    if (this.props.autoFocus) this.textarea.focus()
  }

  onInput() {
    this.autoGrow(this.textarea)
  }

  autoGrow = textarea => {
    textarea.style.height = '0.5em'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  render() {
    return (
      <textarea
        ref={node => (this.textarea = node)}
        {...this.props}
        onInput={e => {
          this.onInput(e)
          this.props.onInput && this.props.onInput(e)
        }}
      />
    )
  }
}

export default AutogrowingTextarea
