import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { doFetchJiraServerPriorities } from 'ducks/integrations/jiraServer'
import {
  selectJiraArePrioritiesLoading,
  selectJiraPrioritiesAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionImageIcon from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionImageIcon'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'

export default connect(
  state => ({
    arePrioritiesLoading: selectJiraArePrioritiesLoading(state, {
      source: 'jiraServer',
    }),
    priorities: selectJiraPrioritiesAsOptions(state, { source: 'jiraServer' }),
  }),
  {
    doFetchJiraServerPriorities,
  }
)(PrioritiesFormField)

function PrioritiesFormField({
  arePrioritiesLoading,
  doFetchJiraServerPriorities: fetchPriorities,
  isProjectChoosen,
  formFields: { priority },
  onChange,
  priorities,
}) {
  useEffect(() => fetchPriorities(), [fetchPriorities])
  return (
    <FormRow split>
      <Label as="div">
        Priority <OptionalLabel>(optional)</OptionalLabel>
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || arePrioritiesLoading}
        fluid
        loading={arePrioritiesLoading}
        name="priority"
        options={priorities.map(option => {
          return {
            ...option,
            icon: <OptionImageIcon alt={option.text} src={option.iconUrl} />,
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={priority}
      />
    </FormRow>
  )
}
