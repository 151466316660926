import { connect } from 'react-redux'

import {
  selectDraftIdByTicketId,
  makeDraftPropOrDefaultSelector,
} from 'ducks/drafts2/selectors'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
  doEnsureDefaults,
} from 'ducks/drafts2/operations'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { getAssignmentLabel } from 'components/App/DesktopView/CommunicationForms/shared/util'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import { selectAccountPrefersReplyOpen } from 'selectors/app/selectAccountPreferences'
import { emptyArr } from 'util/arrays'

import View from './view'

const select = () => {
  return (state, props) => {
    const { ticketId } = props
    const ticket = selectRawTicket(state, ticketId)
    const draftId = selectDraftIdByTicketId(state, ticketId, 'reply')

    const selectDraftPropByName = makeDraftPropOrDefaultSelector(
      state,
      draftId,
      ticketId
    )

    const assigneeId = selectDraftPropByName('assigneeId')
    const assigneeGroupId = selectDraftPropByName('assigneeGroupId')
    const facebookRecipient =
      selectDraftPropByName('facebookRecipient') ||
      selectDraftPropByName('to')?.[0] ||
      emptyArr

    const showKbSearch = selectShowKbSearchOnInboxEditor(state)

    return {
      draftId,
      defaultsSet: selectDraftPropByName('defaultsSet'),
      defaultsAvailable: !!ticket.draftDefaults,
      syncable: !!draftId,
      isOpen: ticket.isOpen,
      assignmentLabel: getAssignmentLabel(state, assigneeId, assigneeGroupId),
      assigneeGroupId,
      mailboxId: selectDraftPropByName('mailboxId'),
      subject: selectDraftPropByName('title') || '',
      isSendable: selectDraftPropByName('isSendable'),
      facebookRecipient,
      showKbSearch,
      prefersOpen: selectAccountPrefersReplyOpen(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        'reply',
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'reply', draftId, ticketId, messageId, fields)
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'reply', draftId, ticketId, messageId, fields)
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
    doEnsureDefaults: draftId => {
      dispatch(doEnsureDefaults(draftId, 'reply'))
    },
  }
}

export default connect(select, perform)(View)
