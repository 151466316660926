import ActionSubject from './ActionSubject'
import ActionText from './ActionText'
import ToText from './ToText'

export default function IntegrationActionContentTrello({
  action,
  action: {
    change: { action: { memberCreator: { username } = {} } = {} },
  },
}) {
  return (
    <React.Fragment>
      {username} <ActionText action={action} />
      <ActionSubject action={action} />
      <ToText action={action} />
    </React.Fragment>
  )
}
