import { getClosest, getParents } from 'util/dom'
import { snoozeLabel, SNOOZED_INDEFINITELY } from 'util/snooze'
import Link from 'redux-first-router-link'
import {
  MESSAGE,
  SNOOZE_STATE,
  UNSNOOZE_STATE,
  STATE,
  STAR,
  AGENT,
  GROUP,
  AGENT_AND_GROUP,
  MAILBOX,
  TICKET_MERGER,
  TITLE,
  DELETED,
  TICKET_INTEGRATION,
  INTEGRATED_EXTERNAL,
  END_USER,
  SHOPIFY_EXTERNAL_CHANGE,
  LABEL,
  COMMENT_DELETION,
} from 'constants/changesetActionChangeTypes'
import TagLabel from '../TagLabel'
import AgentLabel from '../AgentLabel'
import GroupLabel from '../GroupLabel'
import MailboxLabel from '../MailboxLabel'
import integrationAction from '../IntegrationAction'
import AgentAvatar from '../AgentAvatar'
import ProviderIcon from '../ProviderIcon'
import IntegrationExternalAction, {
  shouldShowIntegrationAction,
} from '../IntegrationExternalAction'
import RuleLabel from '../RuleLabel'
import { shopifyChangeText } from './shopifyChangeText'

export const handlePrintClick = (e, printableContainer) => {
  const startingNode = !e?.target
    ? printableContainer
    : getClosest(e.target, '[data-print-target="message-or-note"]')
  if (!startingNode) return
  const parents = getParents(startingNode)
  const allChildren = Array.from(startingNode.querySelectorAll('*'))
  const nodes = parents
    .concat(allChildren)
    .filter(
      node =>
        !node.classList.contains('hidden-in-print') &&
        !node.classList.contains('visible-only-in-print')
    )

  nodes.forEach(node => {
    let display
    if (typeof node.className === 'string' && node.className.match('meta')) {
      display = 'flex'
    } else {
      const computedStyle = getComputedStyle(node)
      display = computedStyle.display
    }
    node.style.setProperty('display', display, 'important')
  })

  const style = document.createElement('style')
  style.appendChild(document.createTextNode('')) // webkit hack
  document.head.appendChild(style)
  style.sheet.insertRule(
    '@media print { :not(.visible-only-in-print) { display: none; } }'
  )
  style.sheet.insertRule(
    '@media print { .message_actions { opacity: 0 !important; } }'
  )

  function cleanup() {
    style.parentNode.removeChild(style)
    startingNode.style.display = ''
    nodes.forEach(node => {
      if (typeof node.className === 'string' && node.className.match('meta')) {
        node.style.removeProperty('display')
      }
    })
    window.removeEventListener('afterprint', cleanup)
  }
  window.addEventListener('afterprint', cleanup)
  window.print()
}

export const shouldStartExpanded = (
  isForward,
  isFirstMessage,
  parsedBody,
  index
) => {
  // If the following part is text then we don't want to collapse
  // the quoted part. This is a way to try to stop us collapsing
  // quoted text in the middle of message bodies, which was
  // originally intended functionality.
  // Now we are only collapsing quoted parts after the final text
  // part, which should just be the quoted replies.
  //
  const nextPart = parsedBody[index + 1]
  const nextPartText = nextPart && nextPart.type === 'text'

  // If this is an enduser forward and it's the first message in
  // a ticket then we need to expand the quoted part, because that
  // is the forwarded message. This is a bit of a hack but I can't
  // think of a better way right now.
  const firstMessageAndEnduserForward = isForward && isFirstMessage

  return nextPartText || firstMessageAndEnduserForward
}

const previousUserText = customer => {
  if (!customer) return ''
  const { name, email } = customer
  if (name && email) return ` from ${name} (${email})`
  if (name) return ` from ${name}`
  if (email) return ` from ${email}`
  return ''
}

const getIntegrationName = provider => {
  if (provider === 'atlassian_oauth2') return 'Jira Cloud'
  if (provider === 'github') return 'Github'
  if (provider === 'hubspot') return 'Hubspot'
  if (provider === 'jira_server') return 'Jira Server'
  if (provider === 'salesforce') return 'Salesforce'
  if (provider === 'shopify') return 'Shopify'
  if (provider === 'shopify_v2') return 'Shopify'
  if (provider === 'stripe_connect') return 'Stripe'
  if (provider === 'trello') return 'Trello'

  return 'Integration'
}

export const getAuthor = (action, currentUserId) => {
  const actor = action.actor
  const authorName = actor.label || actor.name
  let author = authorName || actor.email
  if (actor.type === 'Agent' && actor.id === currentUserId) {
    author = 'You'
  } else if (actor.type === 'Collaborator' && authorName) {
    author = `${authorName} (${actor.email})`
  } else if (actor.type === 'Rule') {
    author = <RuleLabel ruleId={actor.id}>{actor.name}</RuleLabel>
  } else if (actor.type === 'Agent') {
    author = <AgentLabel agentId={actor.id} />
  } else if (actor.type === 'OauthCredential') {
    author = getIntegrationName(actor.provider)
  }
  return author
}

export const getByline = (
  {
    action,
    currentUserId,
    integrationSettings,
    isNote,
    isFirstMessage,
    isForward,
  },
  { onMouseEnter, openRuleEditDrawer } = {}
) => {
  const author = getAuthor(action, currentUserId)

  let operation = null
  let icon = null

  const { change_type: type, change } = action

  switch (type) {
    case MESSAGE:
      if (isNote) {
        if (change.ruleId) {
          operation = (
            <span className="operation note">
              <span className="action-verb">
                added an{' '}
                <span
                  className="action-verb-topic"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={e => openRuleEditDrawer(e, change.ruleId)}
                >
                  automated note
                </span>
              </span>
            </span>
          )
        } else {
          operation = (
            <span className="operation note">
              <span className="action-verb">added</span> a note
            </span>
          )
        }
      } else if (!isNote && isFirstMessage) {
        operation = (
          <span className="operation message">
            <span className="action-verb">started</span> a conversation
          </span>
        )
      } else {
        try {
          const recipients = (change.to || [])
            .map(recipient => {
              if (isForward && recipient.name) {
                return `${recipient.name} (${recipient.email})`
              }
              return recipient.name || recipient.email
            })
            .join(', ')

          if (change.ruleId) {
            let operationContent = null

            if (isForward) {
              operationContent = (
                <span className="action-verb">
                  <span
                    className="action-verb-topic"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={e => openRuleEditDrawer(e, change.ruleId)}
                  >
                    forwarded this conversation by rule
                  </span>
                </span>
              )
            } else {
              operationContent = (
                <span className="action-verb">
                  sent an{' '}
                  <span
                    className="action-verb-topic"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={e => openRuleEditDrawer(e, change.ruleId)}
                  >
                    automated reply
                  </span>
                </span>
              )
            }

            operation = (
              <span className="operation message">
                {operationContent} to {recipients}
              </span>
            )
          } else {
            const actionText = isForward ? 'forwarded' : 'replied'
            operation = (
              <span className="operation message">
                <span className="action-verb">{actionText}</span> to{' '}
                {recipients}
              </span>
            )
          }
        } catch (e) {} // eslint-disable-line no-empty
      }
      break
    case STATE:
      {
        const { state } = change
        if (state === 'opened') {
          operation = (
            <span>
              <span className="action-verb">reopened</span> the conversation
            </span>
          )
        } else if (state === 'closed') {
          operation = (
            <span>
              <span className="action-verb">closed</span> the conversation
            </span>
          )
        } else {
          operation = (
            <span>
              <span className="action-verb">marked</span> as {change.state}
            </span>
          )
        }
      }
      break
    case STAR:
      {
        const actionText = change.starred ? 'added' : 'removed'
        operation = (
          <span>
            <span className="action-verb">{actionText}</span> star
          </span>
        )
      }
      break
    case LABEL:
      {
        const actionText = change.delta > 0 ? 'added' : 'removed'
        operation = (
          <span>
            <span className="action-verb">{actionText} tag</span>&nbsp;
            <TagLabel labelId={change.label_id} />
          </span>
        )
      }
      break
    case AGENT:
    case GROUP:
    case AGENT_AND_GROUP:
      if (!change.id) {
        operation = <span className="action-verb">unassigned</span>
      } else if (change.id === action.actor.id) {
        operation = (
          <span>
            <span className="action-verb">took</span> ownership
          </span>
        )
      } else if (type === 'Group') {
        operation = (
          <React.Fragment>
            <span className="action-verb">assigned</span> to{' '}
            <GroupLabel groupId={action.change.id} />
          </React.Fragment>
        )
      } else {
        operation = (
          <React.Fragment>
            <span className="action-verb">assigned</span> to{' '}
            <AgentLabel agentId={action.change.id} />
          </React.Fragment>
        )
      }
      break
    case MAILBOX:
      {
        const { mailbox, previous_mailbox: previousMailbox } = change
        operation = (
          <React.Fragment>
            <span className="action-verb">moved</span>
            {previousMailbox ? ' from ' : ' to '}
            <MailboxLabel mailboxId={previousMailbox || mailbox} />
          </React.Fragment>
        )
      }
      break
    case SNOOZE_STATE:
      if (change.until) {
        operation = (
          <span>
            <span className="action-verb">snoozed</span> until{' '}
            {snoozeLabel(change.until, false)}
          </span>
        )
      } else {
        operation = (
          <span>
            <span className="action-verb">snoozed</span>{' '}
            {snoozeLabel(SNOOZED_INDEFINITELY)}
          </span>
        )
      }
      break
    case UNSNOOZE_STATE:
      operation = <span className="action-verb">unsnoozed</span>
      break
    case SHOPIFY_EXTERNAL_CHANGE:
      // chat events are rendered through:
      // components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/IntegrationAction
      icon = <ProviderIcon provider="shopify_v2" />
      operation = (
        <span className="action-verb">
          {shopifyChangeText(change.change_type)}&nbsp;
          <Link target="_blank" to={change.link}>
            {change?.meta?.orderName || `#${change.external_id}`}
          </Link>
        </span>
      )
      break
    case TICKET_MERGER:
      operation = (
        <span>
          <span className="action-verb">merged</span> conversation
        </span>
      )
      break
    case TITLE:
      {
        const { previousTitle } = change
        if (previousTitle) {
          operation = (
            <span>
              <span className="action-verb">changed</span> title from{' '}
              {previousTitle}
            </span>
          )
        } else {
          operation = (
            <span>
              <span className="action-verb">changed</span> title
            </span>
          )
        }
      }
      break
    case DELETED:
      if (change.restored) {
        operation = (
          <span>
            <span className="action-verb">restored</span> from trash
          </span>
        )
      } else {
        operation = (
          <span>
            <span className="action-verb">moved</span> to trash
          </span>
        )
      }
      break
    case TICKET_INTEGRATION:
      icon = <ProviderIcon provider={change.provider} />
      operation = integrationAction(action)
      break
    case INTEGRATED_EXTERNAL:
      if (
        shouldShowIntegrationAction &&
        !shouldShowIntegrationAction(action, integrationSettings)
      ) {
        return null
      }
      icon = <ProviderIcon provider={change.provider} />
      operation = <IntegrationExternalAction action={action} />
      break
    case END_USER:
      operation = (
        <span>
          <span className="action-verb">changed</span> user{previousUserText(
            change.previousCustomer
          )}
        </span>
      )
      break
    case COMMENT_DELETION:
      operation = (
        <span>
          <span className="action-verb">deleted</span> note
        </span>
      )
      break
    default:
      operation = type
      break
  }

  return (
    <div className="byline" onMouseEnter={onMouseEnter}>
      {icon} <span className="author">{author}</span> {operation}
    </div>
  )
}

export const getAvatarComponent = ({ action }) => {
  const actor = action.actor
  const avatarUrl = actor.avatarUrl

  // if possible, use the avatar provided, otherwise, use the store
  if (actor.type === 'Agent' && !avatarUrl) {
    return <AgentAvatar agentId={actor.id} />
  }
  const actorInitials = actor.initials
  const styles = { backgroundImage: `url(${avatarUrl})` }
  return (
    <div className="avatar" style={styles}>
      {!avatarUrl && actorInitials}
    </div>
  )
}
