import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsInChat } from 'selectors/location'
import HotkeysGroup from './HotkeysGroup'

const inboxActions = {
  j: 'View previous conversation',
  k: 'View next conversation',
  '/': 'Search',
}

const chatActions = {
  j: 'View previous conversation',
  k: 'View next conversation',
}

export default function ConversationsList() {
  const isInChat = useSelector(selectIsInChat)
  return (
    <HotkeysGroup
      title="Conversations List"
      hotkeys={isInChat ? chatActions : inboxActions}
    />
  )
}
