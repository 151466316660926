import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const container = theme => css`
  && {
    width: ${rem(280)};
    color: ${theme.color.monochrome.black};
    padding: ${rem(16)};
  }
`

const heading = theme => css`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${rem(19)};
  line-height: ${theme.lineHeight.base};
`

const copy = theme => css`
  font-weight: ${theme.fontWeight.medium};
  margin: ${rem(12)} 0;
`

const redioButtons = theme => css`
  & input:checked + label {
    background-color: ${theme.color.primary.info};
  }
`

const qr = css`
  width: ${rem(183)};
  margin: ${rem(5)} -${rem(8)} 0;

  img {
    width: 100%;
  }
`

const storeImg = css`
  height: ${rem(32)};
  margin-top: -${rem(3)};
  display: block;

  img {
    height: 100%;
  }
`

const buttonLink = theme => css`
  opacity: 0.5;
  color: ${theme.color.monochrome.mediumDark};
  font-size: ${theme.fontSize.small};
  margin-top: ${rem(18)};
  font-weight: ${theme.fontWeight.normal};

  &:hover {
    opacity: 1;
  }
`

export const styles = {
  container,
  heading,
  copy,
  redioButtons,
  qr,
  storeImg,
  buttonLink,
}
