import moment from 'moment'
import Link from 'redux-first-router-link'

import KeyValues, { KeyValue } from 'components/KeyValues'

function formatDate(date) {
  if (!date) return ''
  const asDate = new Date(date)
  const displayYear = asDate.getFullYear() !== new Date().getFullYear()
  return moment(date).format(`D MMM${displayYear ? " 'YY" : ''}, H:mmA`)
}

function getFieldProps(
  field,
  {
    board: { name: boardName, url: boardUrl } = {},
    closed,
    createdAt,
    due,
    labels = [],
    list: { name: listName } = {},
    members = [],
    updatedAt,
  }
) {
  switch (field) {
    case 'showSidebarField.list':
      return {
        singleline: true,
        title: 'List',
        value: listName,
      }
    case 'showSidebarField.board':
      return {
        singleline: true,
        title: 'Board',
        value: (
          <Link target="_blank" to={boardUrl}>
            {boardName}
          </Link>
        ),
      }
    case 'showSidebarField.status':
      return {
        singleline: true,
        title: 'Status',
        value: closed ? 'Closed' : 'Open',
      }
    case 'showSidebarField.members':
      if (members.length > 0) {
        return {
          title: 'Members',
          value: members.map(({ fullName }) => fullName).join(', '),
        }
      }
      return null
    case 'showSidebarField.labels':
      if (labels.length > 0) {
        return {
          title: 'Labels',
          value: labels
            .map(({ color, name: labelName }) => labelName || color)
            .join(', '),
        }
      }
      return null
    case 'showSidebarField.created':
      return {
        singleline: true,
        title: 'Created',
        value: formatDate(createdAt),
      }
    case 'showSidebarField.lastUpdated':
      return {
        singleline: true,
        title: 'Last updated',
        value: formatDate(updatedAt),
      }
    case 'showSidebarField.dueDate':
      if (due) {
        return {
          singeline: true,
          title: 'Due',
          value: formatDate(due),
        }
      }
      return null
    default:
      return null
  }
}

export default function LinkedTrelloCardFields({ fields, card }) {
  return (
    <KeyValues>
      {fields.map(field => {
        const props = getFieldProps(field, card)
        if (!props) return null
        return <KeyValue key={field} {...props} />
      })}
    </KeyValues>
  )
}
