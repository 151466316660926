import React from 'react'
import cn from 'classnames'
import { anchor } from '@groovehq/internal-design-system/lib/styles/elements'
import logo from 'assets/groove_logo_v2.svg'
import SUI from 'shared/components/ui/SUI'
import { styles } from './styles'

export default function LoginLayout({ className, children }) {
  return (
    <SUI transparent={false}>
      <div css={styles.login} className={cn('grui flex-center', className)}>
        <div css={styles.container} className="grui w-100">
          <div className="grui py-14">
            <div css={styles.header}>
              <a
                href="https://www.groovehq.com/"
                target="_blank"
                rel="noopener noreferrer"
                css={[anchor.styles.preflight, styles.logo]}
              >
                <img src={logo} alt="GrooveHQ" />
              </a>
            </div>
            {children}
          </div>
        </div>
      </div>
    </SUI>
  )
}
