import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { convertHexToRGBA } from 'util/colors'
import aiWidgetHeaderBg from 'assets/ai-widget-header-bg@2x.png'

const card = theme => css`
  font-family: ${theme.font.quaternary};
  font-size: ${theme.fontSize.large};
  line-height: ${theme.lineHeight.leadingTight};

  .CollapsableCard-title {
    border-top-left-radius: ${rem(4)};
    border-top-right-radius: ${rem(4)};
  }

  &.open,
  &:hover {
    .CollapsableCard-title {
      background: ${theme.color.monochrome.white} url(${aiWidgetHeaderBg})
        no-repeat center/cover;
    }
  }

  .CollapsableCard-contentInternal {
    padding: 0;
  }

  &&& button {
    font-family: inherit;
  }
`

const icon = css`
  width: ${rem(11)};
`

const actionTitle = theme => css`
  font-weight: ${theme.fontWeight.medium};
`

const actionButton = theme => css`
  min-height: ${rem(32)};
  max-width: ${rem(268)};
  font-weight: ${theme.fontWeight.medium};
  font-size: inherit;
  border-radius: 9999px;
  &:hover:not(:disabled) {
    background-color: ${theme.color.primary.info};
    color: ${theme.color.monochrome.white};
    border-color: ${theme.color.primary.info};
  }
`

const lightDark = theme => css`
  color: rgba(${theme.color.monochrome.black_rgba}, 0.7);
`

const regenerationButton = theme => css`
  svg {
    color: rgba(${theme.color.monochrome.black_rgba}, 0.4);
  }

  &:hover,
  &:focus {
    svg {
      color: inherit;
    }
  }

  &,
  &:disabled {
    color: rgba(${theme.color.monochrome.black_rgba}, 0.6);
    border-color: rgba(${theme.color.monochrome.black_rgba}, 0.2);
  }

  &.loading {
    border-style: dashed;
  }

  &:disabled:not(.loading) {
    opacity: 0.2;
  }
`

const divider = theme => css`
  background-color: ${convertHexToRGBA(theme.color.info['900'], 0.2)};
`

const betaAnimationContainer = css`
  // Remove inline spacing
  svg {
    display: block;
  }
`

const loadingState = css`
  color: #f3f3f3;
`

export const styles = {
  card,
  icon,
  actionTitle,
  actionButton,
  lightDark,
  regenerationButton,
  divider,
  betaAnimationContainer,
  loadingState,
}
