import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSubscriptions } from 'ducks/integrations/stripe/hooks/subscriptions'
import { isEmpty } from 'util/arrays'
import { Loader } from 'shared/ui'

import doFetchSubscriptions from 'ducks/integrations/stripe/operations/doFetchSubscriptions'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import ListItem from './ListItem'

export default function List({ integrationId, customerId }) {
  const dispatch = useDispatch()
  const {
    subscriptions,
    isLoading,
    isFetchingMore,
    hasMore,
  } = useSubscriptions(integrationId, customerId)

  const onShowMoreClick = useCallback(
    () => {
      dispatch(
        doFetchSubscriptions({
          integrationId,
          customerId,
          lastItemId: subscriptions[subscriptions.length - 1].id,
        })
      )
    },
    [dispatch, subscriptions, integrationId, customerId]
  )

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Subscriptions" />
      <NavigatableIntegrationWidgetCard.List hideMargin>
        {isLoading && <Loader className="grui mb-5" />}

        {isEmpty(subscriptions) &&
          !isLoading && (
            <EmptyMessage className="grui p-7">
              This customer has no subscriptions
            </EmptyMessage>
          )}

        {!isEmpty(subscriptions) &&
          subscriptions.map(subscription => {
            return (
              <ListItem
                key={subscription.id}
                subscription={subscription}
                customerId={customerId}
              />
            )
          })}

        {hasMore && (
          <NavigatableIntegrationWidgetCard.ShowMore
            text="Show more subscriptions"
            loadingText="Fetching..."
            isLoading={isFetchingMore}
            onClick={onShowMoreClick}
          />
        )}
      </NavigatableIntegrationWidgetCard.List>
    </>
  )
}
