import React, { Component } from 'react'
import { css } from '@emotion/react'
import { SUI } from 'shared/ui'

export default class SUIModalWrapper extends Component {
  takeRef = el => {
    app.suiModalRoot = el
  }

  render() {
    const { children } = this.props

    return (
      <SUI
        css={css`
          position: absolute;
          top: 0;
          left: 0;
        `}
      >
        <div ref={this.takeRef}>{children}</div>
      </SUI>
    )
  }
}
