import React from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { divider } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_ONBOARDING_WORKFLOW } from 'ducks/drawers/types'
import inbox from 'assets/inbox_empty.png'

import { styles } from './styles'

const OnboardingWorkflowCard = ({ mini = false, className }) => {
  const { openDrawer: openOnboardingWorkflow } = useDrawer({
    type: DRAWER_TYPE_ONBOARDING_WORKFLOW,
  })

  return (
    <>
      <div
        css={[
          styles.positionBase,
          mini ? styles.positionMini : styles.position,
        ]}
        className={className}
      >
        <div
          css={[
            styles.containerBase,
            mini ? styles.containerMini : styles.container,
          ]}
        >
          <div className="grui flex">
            <div css={mini ? styles.contentMini : styles.content}>
              <h2 className="grui">👋 Welcome to Groove!</h2>
              <p className="grui">
                Connect an account to start managing your emails with your team
                in Groove.
              </p>
              <Button type="info" size="xBig" onClick={openOnboardingWorkflow}>
                <span aria-hidden="true">📨</span>
                Connect<span className="hidden-small"> an account</span>
              </Button>
            </div>
            {!mini && (
              <div className="grui flex flex-grow">
                <img src={inbox} aria-hidden="true" alt="" css={styles.image} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div role="separator" css={divider.styles.base} />
    </>
  )
}

export default OnboardingWorkflowCard
