// NOTE (jscheel): The key in this timezone list is based on the legacy ui dropdown
// we originally used for timezone selectors back in groove 1.0 days. Our current
// timezone dropdown continues to use this to match the existing data we have.

export const TIMEZONES = [
  {
    key: 'American Samoa',
    title: '(GMT-11:00) American Samoa',
    offset: -11,
    name: 'Pacific/Pago_Pago',
  },
  {
    key: 'International Date Line West',
    title: '(GMT-11:00) International Date Line West',
    offset: -11,
    name: 'Pacific/Midway',
  },
  {
    key: 'Midway Island',
    title: '(GMT-11:00) Midway Island',
    offset: -11,
    name: 'Pacific/Midway',
  },
  {
    key: 'Hawaii',
    title: '(GMT-10:00) Hawaii',
    offset: -10,
    name: 'Pacific/Honolulu',
  },
  {
    key: 'Alaska',
    title: '(GMT-09:00) Alaska',
    offset: -9,
    name: 'America/Juneau',
  },
  {
    key: 'Pacific Time (US & Canada)',
    title: '(GMT-08:00) Pacific Time (US & Canada)',
    offset: -8,
    name: 'America/Los_Angeles',
  },
  {
    key: 'Tijuana',
    title: '(GMT-08:00) Tijuana',
    offset: -8,
    name: 'America/Tijuana',
  },
  {
    key: 'Arizona',
    title: '(GMT-07:00) Arizona',
    offset: -7,
    name: 'America/Phoenix',
  },
  {
    key: 'Chihuahua',
    title: '(GMT-07:00) Chihuahua',
    offset: -7,
    name: 'America/Chihuahua',
  },
  {
    key: 'Mazatlan',
    title: '(GMT-07:00) Mazatlan',
    offset: -7,
    name: 'America/Mazatlan',
  },
  {
    key: 'Mountain Time (US & Canada)',
    title: '(GMT-07:00) Mountain Time (US & Canada)',
    offset: -7,
    name: 'America/Denver',
  },
  {
    key: 'Central America',
    title: '(GMT-06:00) Central America',
    offset: -6,
    name: 'America/Guatemala',
  },
  {
    key: 'Central Time (US & Canada)',
    title: '(GMT-06:00) Central Time (US & Canada)',
    offset: -6,
    name: 'America/Chicago',
  },
  {
    key: 'Guadalajara',
    title: '(GMT-06:00) Guadalajara',
    offset: -6,
    name: 'America/Mexico_City',
  },
  {
    key: 'Mexico City',
    title: '(GMT-06:00) Mexico City',
    offset: -6,
    name: 'America/Mexico_City',
  },
  {
    key: 'Monterrey',
    title: '(GMT-06:00) Monterrey',
    offset: -6,
    name: 'America/Monterrey',
  },
  {
    key: 'Saskatchewan',
    title: '(GMT-06:00) Saskatchewan',
    offset: -6,
    name: 'America/Regina',
  },
  {
    key: 'Bogota',
    title: '(GMT-05:00) Bogota',
    offset: -5,
    name: 'America/Bogota',
  },
  {
    key: 'Eastern Time (US & Canada)',
    title: '(GMT-05:00) Eastern Time (US & Canada)',
    offset: -5,
    name: 'America/New_York',
  },
  {
    key: 'Indiana (East)',
    title: '(GMT-05:00) Indiana (East)',
    offset: -5,
    name: 'America/Indiana/Indianapolis',
  },
  {
    key: 'Lima',
    title: '(GMT-05:00) Lima',
    offset: -5,
    name: 'America/Lima',
  },
  {
    key: 'Quito',
    title: '(GMT-05:00) Quito',
    offset: -5,
    name: 'America/Lima',
  },
  {
    key: 'Atlantic Time (Canada)',
    title: '(GMT-04:00) Atlantic Time (Canada)',
    offset: -4,
    name: 'America/Halifax',
  },
  {
    key: 'Caracas',
    title: '(GMT-04:00) Caracas',
    offset: -4,
    name: 'America/Caracas',
  },
  {
    key: 'Georgetown',
    title: '(GMT-04:00) Georgetown',
    offset: -4,
    name: 'America/Guyana',
  },
  {
    key: 'La Paz',
    title: '(GMT-04:00) La Paz',
    offset: -4,
    name: 'America/La_Paz',
  },
  {
    key: 'Santiago',
    title: '(GMT-04:00) Santiago',
    offset: -4,
    name: 'America/Santiago',
  },
  {
    key: 'Newfoundland',
    title: '(GMT-03:30) Newfoundland',
    offset: -4,
    name: 'America/St_Johns',
  },
  {
    key: 'Brasilia',
    title: '(GMT-03:00) Brasilia',
    offset: -3,
    name: 'America/Sao_Paulo',
  },
  {
    key: 'Buenos Aires',
    title: '(GMT-03:00) Buenos Aires',
    offset: -3,
    name: 'America/Argentina/Buenos_Aires',
  },
  {
    key: 'Greenland',
    title: '(GMT-03:00) Greenland',
    offset: -3,
    name: 'America/Godthab',
  },
  {
    key: 'Montevideo',
    title: '(GMT-03:00) Montevideo',
    offset: -3,
    name: 'America/Montevideo',
  },
  {
    key: 'Mid-Atlantic',
    title: '(GMT-02:00) Mid-Atlantic',
    offset: -2,
    name: 'Atlantic/South_Georgia',
  },
  {
    key: 'Azores',
    title: '(GMT-01:00) Azores',
    offset: -1,
    name: 'Atlantic/Azores',
  },
  {
    key: 'Cape Verde Is.',
    title: '(GMT-01:00) Cape Verde Is.',
    offset: -1,
    name: 'Atlantic/Cape_Verde',
  },
  {
    key: 'Edinburgh',
    title: '(GMT+00:00) Edinburgh',
    offset: 0,
    name: 'Europe/London',
  },
  {
    key: 'Lisbon',
    title: '(GMT+00:00) Lisbon',
    offset: 0,
    name: 'Europe/Lisbon',
  },
  {
    key: 'London',
    title: '(GMT+00:00) London',
    offset: 0,
    name: 'Europe/London',
  },
  {
    key: 'Monrovia',
    title: '(GMT+00:00) Monrovia',
    offset: 0,
    name: 'Africa/Monrovia',
  },
  {
    key: 'UTC',
    title: '(GMT+00:00) UTC',
    offset: 0,
    name: 'Etc/UTC',
  },
  {
    key: 'Amsterdam',
    title: '(GMT+01:00) Amsterdam',
    offset: 1,
    name: 'Europe/Amsterdam',
  },
  {
    key: 'Belgrade',
    title: '(GMT+01:00) Belgrade',
    offset: 1,
    name: 'Europe/Belgrade',
  },
  {
    key: 'Berlin',
    title: '(GMT+01:00) Berlin',
    offset: 1,
    name: 'Europe/Berlin',
  },
  {
    key: 'Bern',
    title: '(GMT+01:00) Bern',
    offset: 1,
    name: 'Europe/Berlin',
  },
  {
    key: 'Bratislava',
    title: '(GMT+01:00) Bratislava',
    offset: 1,
    name: 'Europe/Bratislava',
  },
  {
    key: 'Brussels',
    title: '(GMT+01:00) Brussels',
    offset: 1,
    name: 'Europe/Brussels',
  },
  {
    key: 'Budapest',
    title: '(GMT+01:00) Budapest',
    offset: 1,
    name: 'Europe/Budapest',
  },
  {
    key: 'Casablanca',
    title: '(GMT+01:00) Casablanca',
    offset: 1,
    name: 'Africa/Casablanca',
  },
  {
    key: 'Copenhagen',
    title: '(GMT+01:00) Copenhagen',
    offset: 1,
    name: 'Europe/Copenhagen',
  },
  {
    key: 'Dublin',
    title: '(GMT+01:00) Dublin',
    offset: 1,
    name: 'Europe/Dublin',
  },
  {
    key: 'Ljubljana',
    title: '(GMT+01:00) Ljubljana',
    offset: 1,
    name: 'Europe/Ljubljana',
  },
  {
    key: 'Madrid',
    title: '(GMT+01:00) Madrid',
    offset: 1,
    name: 'Europe/Madrid',
  },
  {
    key: 'Paris',
    title: '(GMT+01:00) Paris',
    offset: 1,
    name: 'Europe/Paris',
  },
  {
    key: 'Prague',
    title: '(GMT+01:00) Prague',
    offset: 1,
    name: 'Europe/Prague',
  },
  {
    key: 'Rome',
    title: '(GMT+01:00) Rome',
    offset: 1,
    name: 'Europe/Rome',
  },
  {
    key: 'Sarajevo',
    title: '(GMT+01:00) Sarajevo',
    offset: 1,
    name: 'Europe/Sarajevo',
  },
  {
    key: 'Skopje',
    title: '(GMT+01:00) Skopje',
    offset: 1,
    name: 'Europe/Skopje',
  },
  {
    key: 'Stockholm',
    title: '(GMT+01:00) Stockholm',
    offset: 1,
    name: 'Europe/Stockholm',
  },
  {
    key: 'Vienna',
    title: '(GMT+01:00) Vienna',
    offset: 1,
    name: 'Europe/Vienna',
  },
  {
    key: 'Warsaw',
    title: '(GMT+01:00) Warsaw',
    offset: 1,
    name: 'Europe/Warsaw',
  },
  {
    key: 'West Central Africa',
    title: '(GMT+01:00) West Central Africa',
    offset: 1,
    name: 'Africa/Algiers',
  },
  {
    key: 'Zagreb',
    title: '(GMT+01:00) Zagreb',
    offset: 1,
    name: 'Europe/Zagreb',
  },
  {
    key: 'Athens',
    title: '(GMT+02:00) Athens',
    offset: 2,
    name: 'Europe/Athens',
  },
  {
    key: 'Bucharest',
    title: '(GMT+02:00) Bucharest',
    offset: 2,
    name: 'Europe/Bucharest',
  },
  {
    key: 'Cairo',
    title: '(GMT+02:00) Cairo',
    offset: 2,
    name: 'Africa/Cairo',
  },
  {
    key: 'Harare',
    title: '(GMT+02:00) Harare',
    offset: 2,
    name: 'Africa/Harare',
  },
  {
    key: 'Helsinki',
    title: '(GMT+02:00) Helsinki',
    offset: 2,
    name: 'Europe/Helsinki',
  },
  {
    key: 'Jerusalem',
    title: '(GMT+02:00) Jerusalem',
    offset: 2,
    name: 'Asia/Jerusalem',
  },
  {
    key: 'Kaliningrad',
    title: '(GMT+02:00) Kaliningrad',
    offset: 2,
    name: 'Europe/Kaliningrad',
  },
  {
    key: 'Kyiv',
    title: '(GMT+02:00) Kyiv',
    offset: 2,
    name: 'Europe/Kiev',
  },
  {
    key: 'Pretoria',
    title: '(GMT+02:00) Pretoria',
    offset: 2,
    name: 'Africa/Johannesburg',
  },
  {
    key: 'Riga',
    title: '(GMT+02:00) Riga',
    offset: 2,
    name: 'Europe/Riga',
  },
  {
    key: 'Sofia',
    title: '(GMT+02:00) Sofia',
    offset: 2,
    name: 'Europe/Sofia',
  },
  {
    key: 'Tallinn',
    title: '(GMT+02:00) Tallinn',
    offset: 2,
    name: 'Europe/Tallinn',
  },
  {
    key: 'Vilnius',
    title: '(GMT+02:00) Vilnius',
    offset: 2,
    name: 'Europe/Vilnius',
  },
  {
    key: 'Baghdad',
    title: '(GMT+03:00) Baghdad',
    offset: 3,
    name: 'Asia/Baghdad',
  },
  {
    key: 'Istanbul',
    title: '(GMT+03:00) Istanbul',
    offset: 3,
    name: 'Europe/Istanbul',
  },
  {
    key: 'Kuwait',
    title: '(GMT+03:00) Kuwait',
    offset: 3,
    name: 'Asia/Kuwait',
  },
  {
    key: 'Minsk',
    title: '(GMT+03:00) Minsk',
    offset: 3,
    name: 'Europe/Minsk',
  },
  {
    key: 'Moscow',
    title: '(GMT+03:00) Moscow',
    offset: 3,
    name: 'Europe/Moscow',
  },
  {
    key: 'Nairobi',
    title: '(GMT+03:00) Nairobi',
    offset: 3,
    name: 'Africa/Nairobi',
  },
  {
    key: 'Riyadh',
    title: '(GMT+03:00) Riyadh',
    offset: 3,
    name: 'Asia/Riyadh',
  },
  {
    key: 'St. Petersburg',
    title: '(GMT+03:00) St. Petersburg',
    offset: 3,
    name: 'Europe/Moscow',
  },
  {
    key: 'Tehran',
    title: '(GMT+03:30) Tehran',
    offset: 3,
    name: 'Asia/Tehran',
  },
  {
    key: 'Abu Dhabi',
    title: '(GMT+04:00) Abu Dhabi',
    offset: 4,
    name: 'Asia/Muscat',
  },
  {
    key: 'Baku',
    title: '(GMT+04:00) Baku',
    offset: 4,
    name: 'Asia/Baku',
  },
  {
    key: 'Muscat',
    title: '(GMT+04:00) Muscat',
    offset: 4,
    name: 'Asia/Muscat',
  },
  {
    key: 'Samara',
    title: '(GMT+04:00) Samara',
    offset: 4,
    name: 'Europe/Samara',
  },
  {
    key: 'Tbilisi',
    title: '(GMT+04:00) Tbilisi',
    offset: 4,
    name: 'Asia/Tbilisi',
  },
  {
    key: 'Volgograd',
    title: '(GMT+04:00) Volgograd',
    offset: 4,
    name: 'Europe/Volgograd',
  },
  {
    key: 'Yerevan',
    title: '(GMT+04:00) Yerevan',
    offset: 4,
    name: 'Asia/Yerevan',
  },
  {
    key: 'Kabul',
    title: '(GMT+04:30) Kabul',
    offset: 4,
    name: 'Asia/Kabul',
  },
  {
    key: 'Ekaterinburg',
    title: '(GMT+05:00) Ekaterinburg',
    offset: 5,
    name: 'Asia/Yekaterinburg',
  },
  {
    key: 'Islamabad',
    title: '(GMT+05:00) Islamabad',
    offset: 5,
    name: 'Asia/Karachi',
  },
  {
    key: 'Karachi',
    title: '(GMT+05:00) Karachi',
    offset: 5,
    name: 'Asia/Karachi',
  },
  {
    key: 'Tashkent',
    title: '(GMT+05:00) Tashkent',
    offset: 5,
    name: 'Asia/Tashkent',
  },
  {
    key: 'Chennai',
    title: '(GMT+05:30) Chennai',
    offset: 5,
    name: 'Asia/Kolkata',
  },
  {
    key: 'Kolkata',
    title: '(GMT+05:30) Kolkata',
    offset: 5,
    name: 'Asia/Kolkata',
  },
  {
    key: 'Mumbai',
    title: '(GMT+05:30) Mumbai',
    offset: 5,
    name: 'Asia/Kolkata',
  },
  {
    key: 'New Delhi',
    title: '(GMT+05:30) New Delhi',
    offset: 5,
    name: 'Asia/Kolkata',
  },
  {
    key: 'Sri Jayawardenepura',
    title: '(GMT+05:30) Sri Jayawardenepura',
    offset: 5,
    name: 'Asia/Colombo',
  },
  {
    key: 'Kathmandu',
    title: '(GMT+05:45) Kathmandu',
    offset: 5,
    name: 'Asia/Kathmandu',
  },
  {
    key: 'Almaty',
    title: '(GMT+06:00) Almaty',
    offset: 6,
    name: 'Asia/Almaty',
  },
  {
    key: 'Astana',
    title: '(GMT+06:00) Astana',
    offset: 6,
    name: 'Asia/Dhaka',
  },
  {
    key: 'Dhaka',
    title: '(GMT+06:00) Dhaka',
    offset: 6,
    name: 'Asia/Dhaka',
  },
  {
    key: 'Urumqi',
    title: '(GMT+06:00) Urumqi',
    offset: 6,
    name: 'Asia/Urumqi',
  },
  {
    key: 'Rangoon',
    title: '(GMT+06:30) Rangoon',
    offset: 6,
    name: 'Asia/Rangoon',
  },
  {
    key: 'Bangkok',
    title: '(GMT+07:00) Bangkok',
    offset: 7,
    name: 'Asia/Bangkok',
  },
  {
    key: 'Hanoi',
    title: '(GMT+07:00) Hanoi',
    offset: 7,
    name: 'Asia/Bangkok',
  },
  {
    key: 'Jakarta',
    title: '(GMT+07:00) Jakarta',
    offset: 7,
    name: 'Asia/Jakarta',
  },
  {
    key: 'Krasnoyarsk',
    title: '(GMT+07:00) Krasnoyarsk',
    offset: 7,
    name: 'Asia/Krasnoyarsk',
  },
  {
    key: 'Novosibirsk',
    title: '(GMT+07:00) Novosibirsk',
    offset: 7,
    name: 'Asia/Novosibirsk',
  },
  {
    key: 'Beijing',
    title: '(GMT+08:00) Beijing',
    offset: 8,
    name: 'Asia/Shanghai',
  },
  {
    key: 'Chongqing',
    title: '(GMT+08:00) Chongqing',
    offset: 8,
    name: 'Asia/Chongqing',
  },
  {
    key: 'Hong Kong',
    title: '(GMT+08:00) Hong Kong',
    offset: 8,
    name: 'Asia/Hong_Kong',
  },
  {
    key: 'Irkutsk',
    title: '(GMT+08:00) Irkutsk',
    offset: 8,
    name: 'Asia/Irkutsk',
  },
  {
    key: 'Kuala Lumpur',
    title: '(GMT+08:00) Kuala Lumpur',
    offset: 8,
    name: 'Asia/Kuala_Lumpur',
  },
  {
    key: 'Perth',
    title: '(GMT+08:00) Perth',
    offset: 8,
    name: 'Australia/Perth',
  },
  {
    key: 'Singapore',
    title: '(GMT+08:00) Singapore',
    offset: 8,
    name: 'Asia/Singapore',
  },
  {
    key: 'Taipei',
    title: '(GMT+08:00) Taipei',
    offset: 8,
    name: 'Asia/Taipei',
  },
  {
    key: 'Ulaanbaatar',
    title: '(GMT+08:00) Ulaanbaatar',
    offset: 8,
    name: 'Asia/Ulaanbaatar',
  },
  {
    key: 'Osaka',
    title: '(GMT+09:00) Osaka',
    offset: 9,
    name: 'Asia/Tokyo',
  },
  {
    key: 'Sapporo',
    title: '(GMT+09:00) Sapporo',
    offset: 9,
    name: 'Asia/Tokyo',
  },
  {
    key: 'Seoul',
    title: '(GMT+09:00) Seoul',
    offset: 9,
    name: 'Asia/Seoul',
  },
  {
    key: 'Tokyo',
    title: '(GMT+09:00) Tokyo',
    offset: 9,
    name: 'Asia/Tokyo',
  },
  {
    key: 'Yakutsk',
    title: '(GMT+09:00) Yakutsk',
    offset: 9,
    name: 'Asia/Yakutsk',
  },
  {
    key: 'Adelaide',
    title: '(GMT+09:30) Adelaide',
    offset: 9,
    name: 'Australia/Adelaide',
  },
  {
    key: 'Darwin',
    title: '(GMT+09:30) Darwin',
    offset: 9,
    name: 'Australia/Darwin',
  },
  {
    key: 'Brisbane',
    title: '(GMT+10:00) Brisbane',
    offset: 10,
    name: 'Australia/Brisbane',
  },
  {
    key: 'Canberra',
    title: '(GMT+10:00) Canberra',
    offset: 10,
    name: 'Australia/Melbourne',
  },
  {
    key: 'Guam',
    title: '(GMT+10:00) Guam',
    offset: 10,
    name: 'Pacific/Guam',
  },
  {
    key: 'Hobart',
    title: '(GMT+10:00) Hobart',
    offset: 10,
    name: 'Australia/Hobart',
  },
  {
    key: 'Melbourne',
    title: '(GMT+10:00) Melbourne',
    offset: 10,
    name: 'Australia/Melbourne',
  },
  {
    key: 'Port Moresby',
    title: '(GMT+10:00) Port Moresby',
    offset: 10,
    name: 'Pacific/Port_Moresby',
  },
  {
    key: 'Sydney',
    title: '(GMT+10:00) Sydney',
    offset: 10,
    name: 'Australia/Sydney',
  },
  {
    key: 'Vladivostok',
    title: '(GMT+10:00) Vladivostok',
    offset: 10,
    name: 'Asia/Vladivostok',
  },
  {
    key: 'Magadan',
    title: '(GMT+11:00) Magadan',
    offset: 11,
    name: 'Asia/Magadan',
  },
  {
    key: 'New Caledonia',
    title: '(GMT+11:00) New Caledonia',
    offset: 11,
    name: 'Pacific/Noumea',
  },
  {
    key: 'Solomon Is.',
    title: '(GMT+11:00) Solomon Is.',
    offset: 11,
    name: 'Pacific/Guadalcanal',
  },
  {
    key: 'Srednekolymsk',
    title: '(GMT+11:00) Srednekolymsk',
    offset: 11,
    name: 'Asia/Srednekolymsk',
  },
  {
    key: 'Auckland',
    title: '(GMT+12:00) Auckland',
    offset: 12,
    name: 'Pacific/Auckland',
  },
  {
    key: 'Fiji',
    title: '(GMT+12:00) Fiji',
    offset: 12,
    name: 'Pacific/Fiji',
  },
  {
    key: 'Kamchatka',
    title: '(GMT+12:00) Kamchatka',
    offset: 12,
    name: 'Asia/Kamchatka',
  },
  {
    key: 'Marshall Is.',
    title: '(GMT+12:00) Marshall Is.',
    offset: 12,
    name: 'Pacific/Majuro',
  },
  {
    key: 'Wellington',
    title: '(GMT+12:00) Wellington',
    offset: 12,
    name: 'Pacific/Auckland',
  },
  {
    key: 'Chatham Is.',
    title: '(GMT+12:45) Chatham Is.',
    offset: 12,
    name: 'Pacific/Chatham',
  },
  {
    key: "Nuku'alofa",
    title: "(GMT+13:00) Nuku'alofa",
    offset: 13,
    name: 'Pacific/Tongatapu',
  },
  {
    key: 'Samoa',
    title: '(GMT+13:00) Samoa',
    offset: 13,
    name: 'Pacific/Apia',
  },
  {
    key: 'Tokelau Is.',
    title: '(GMT+13:00) Tokelau Is.',
    offset: 13,
    name: 'Pacific/Fakaofo',
  },
]

export const TIME_FORMATS = ['12hour', '24hour']
