import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@emotion/react'
import { doToggleLabelBatched } from 'actions/labelings/shared'
import { selectLabelById } from 'selectors/labels'
import { Close } from '@groovehq/internal-design-system/lib/assets/icons'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { hasLowContrast } from 'util/colors'
import { styles, Container } from './styles'

export default function Tag({ ticketId, labelId }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const label = useSelector(state => selectLabelById(state, labelId))
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const { name, color } = label
  const isTagColorSmilarToBackground = hasLowContrast(
    theme.color.monochrome.white,
    color
  )

  const onDeleteClick = useCallback(
    () => {
      dispatch(doToggleLabelBatched(ticketId, label))
    },
    [dispatch, ticketId, label]
  )

  return (
    <Container
      color={color}
      className="grui inline-flex items-center"
      css={isTagColorSmilarToBackground && styles.tagWithLightColor}
    >
      <div>{name}</div>
      {isAdminOrOwnerOrAgent && (
        <Close
          name="close"
          size="xsmall"
          onClick={onDeleteClick}
          css={[styles.closeIcon]}
        />
      )}
    </Container>
  )
}
