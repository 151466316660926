import React, { useCallback } from 'react'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function LinkedJiraServerIssuesRow({
  doNavigatableSetState,
  issue,
  issue: {
    loaded,
    fields: {
      project: { name: projectName } = {},
      status: { name: status } = {},
    } = {},
    title,
  } = {},
}) {
  const onClick = useCallback(
    () => {
      doNavigatableSetState(issue.id)
    },
    [doNavigatableSetState, issue]
  )
  if (issue && loaded)
    return (
      <NavigatableIntegrationWidgetCard.ListItem
        onClick={onClick}
        subTitle={`${projectName} · ${status}`}
        title={title}
      />
    )
  return null
}
