import React from 'react'
import { withTheme } from '@emotion/react'
import Modal from 'components/Modal'
import EmojiPicker from './EmojiPicker'
import './styles.less'

const EmojiPickerModal = ({
  bottom = 20,
  offset,
  onClose,
  open,
  onSelect,
  theme,
}) => {
  if (!open) return null

  return (
    <Modal
      styleContainer={{ maxHeight: `calc(100% - ${bottom}px - ${top}px)` }}
      offset={offset}
      onBackgroundClick={onClose}
    >
      <EmojiPicker onSelect={onSelect} theme={theme} />
    </Modal>
  )
}

class MountedModal extends React.Component {
  componentDidMount() {
    const { onModalOpen } = this.props
    if (onModalOpen) onModalOpen()
  }

  componentDidUpdate(prevProps) {
    const { onModalOpen, open, reset } = this.props
    if (!prevProps.open && open && onModalOpen) return onModalOpen()
    if (prevProps.open && !open && reset) return reset()
    return null
  }

  runAndClose = fn => (...args) => {
    fn(...args)
    this.props.onClose()
  }

  closeAndRun = fn => (...args) => {
    this.props.onClose()
    return fn(...args)
  }

  render() {
    const { onSelect } = this.props

    return (
      <EmojiPickerModal {...this.props} onSelect={this.runAndClose(onSelect)} />
    )
  }
}

export default withTheme(MountedModal)
