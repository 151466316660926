import { connect } from 'react-redux'

import doFetchRelatedObjects from 'ducks/integrations/salesforce/operations/doFetchRelatedObjects'
import {
  selectRelatedObjectsForObject,
  selectSpecificRelatedObjectsForObject,
} from 'ducks/integrations/salesforce/selectors'
import View from './view'

const selectFactory = (initialState, initialOwnProps) => {
  const { contactObject } = initialOwnProps
  return state => {
    const getRelatedObjects = type => {
      return selectSpecificRelatedObjectsForObject(
        state,
        contactObject.id,
        type
      )
    }
    return {
      related: selectRelatedObjectsForObject(state, contactObject.id),
      tasks: getRelatedObjects('Tasks'),
      opportunities: getRelatedObjects('Opportunities'),
      account: getRelatedObjects('Account'),
    }
  }
}

const perform = dispatch => ({
  doFetchRelatedObjects: contactObject => {
    return dispatch(doFetchRelatedObjects(contactObject))
  },
})

export default connect(selectFactory, perform)(View)
