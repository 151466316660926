import React from 'react'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { SUI } from 'shared/ui'

import WithIconState from './WithIconState'

const AssignmentBadge = ({ bindHotKey, ...props }) => {
  return (
    <SUI>
      {bindHotKey && (
        <ListenToKeyboard onA={props.onClick} preventDefault disableForInput />
      )}
      <WithIconState bindHotKey={bindHotKey} size="medium" {...props} />
    </SUI>
  )
}

export default AssignmentBadge
