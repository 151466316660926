/* eslint-disable react/no-unused-prop-types */
import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import CopyIcon from 'assets/icons/groove/v2/copy.svg'
import Check from 'assets/icons/groove/v2/check.svg'
import { capitalize } from 'util/strings'
import InputWithDropdown from './InputWithDropdown'
import { styles } from './styles'
import List from '../List'
import { FocusContext } from '../../Expanded/FocusContext'
import Droppable, { emptyDroppable } from '../../Expanded/Droppable'

const RecipientSearch = React.forwardRef((props, ref) => {
  const { className, ...rest } = props
  const {
    onRemove,
    recipients,
    allowMultipleRecipients,
    editing,
    type,
    query,
    currentRecipientSyncSource,
    dataTestId,
  } = rest
  const showSingleInput = !allowMultipleRecipients && editing
  const [copied, setCopied] = useState(false)
  const timer = useRef()
  const canShowCopyButton = type !== 'to' && recipients?.length > 0

  const handleOnRemove = useCallback(
    recipient => {
      onRemove(recipient, type, recipients)
    },
    [onRemove, type, recipients]
  )

  const { getFocusableRef, setFocus } = useContext(FocusContext)

  const focus = useCallback(
    () => {
      setFocus(type)
    },
    [setFocus, type]
  )

  const handleCopyRecipients = useCallback(
    () => {
      // Put the recipients in the clipboard
      navigator.clipboard.writeText(recipients.map(r => r.email).join(','))
      setCopied(true)
      timer.current = setTimeout(() => {
        setCopied(false)
        timer.current = null
      }, 2000)
    },
    [recipients]
  )

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
    }
  }, [])

  return (
    <div
      className={cn(
        'grui flex flex-auto',
        // Make sure padding right is added back when the copy button isn't showing
        !canShowCopyButton && 'pr-4',
        className
      )}
      css={styles.recipientSearchContainer}
    >
      <div
        css={styles.recipientSearch}
        className={className}
        tabIndex={editing ? -1 : props.tabIndex || 0}
        onClick={focus}
        onFocus={focus}
        ref={getFocusableRef(type)}
        dataTestId={`${dataTestId}-trigger`}
      >
        <div className={'row_content'}>
          <div className={'grui flex recipients'}>
            {showSingleInput && (
              <Droppable
                recipient={emptyDroppable}
                type={type}
                disabled={!allowMultipleRecipients && recipients.length === 1}
                singleInput
              >
                <InputWithDropdown
                  {...rest}
                  defaultValue={query}
                  ref={ref}
                  key={`input-dropdown-${type}`}
                  dataTestId={dataTestId}
                />
              </Droppable>
            )}
            {!showSingleInput && (
              <List
                className="recipients_list"
                onRemove={handleOnRemove}
                recipients={recipients}
                syncing={currentRecipientSyncSource === 'to' && type === 'to'}
                type={type}
                allowMultipleRecipients={allowMultipleRecipients}
                focus={focus}
              >
                <Droppable
                  recipient={emptyDroppable}
                  type={type}
                  disabled={!allowMultipleRecipients && recipients.length === 1}
                >
                  {editing ? (
                    <InputWithDropdown
                      {...rest}
                      defaultValue={query}
                      ref={ref}
                      key={`input-dropdown-${type}`}
                      css={styles.cancelGap}
                      dataTestId={dataTestId}
                    />
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </Droppable>
              </List>
            )}
          </div>
        </div>
      </div>
      {canShowCopyButton && (
        <Tooltip
          title={`${copied ? 'Copied' : 'Copy'} ${capitalize(type)}`}
          position="top"
          strategy="fixed"
        >
          <Button
            type="icon"
            size="small"
            onClick={copied ? undefined : handleCopyRecipients}
            tabIndex={-1}
            css={[text.styles.textMediumDark, styles.copyButton]}
          >
            {copied ? <Check /> : <CopyIcon css={styles.copyIcon} />}
          </Button>
        </Tooltip>
      )}
    </div>
  )
})

RecipientSearch.propTypes = {
  /* assumes contacts are to be added by name, if it doesnt pass email validation */
  allowCreateNewContactByName: PropTypes.bool,

  // Allow mutiple recipients (enabled by default)
  allowMultipleRecipients: PropTypes.bool,

  // override for our bloody SUI override.
  bgColor: PropTypes.string, // eslint-disable-line react/no-unused-prop-types

  /** Called when a recipient is selected. */
  onAdd: PropTypes.func,

  /** Called when a recipient is removed. */
  onRemove: PropTypes.func,

  /** Called when the input is blurred, CAVEAT: if this fn unmounts this
   * component, it will stop the dropdown menu items' onClick handler, so may
   * need to be debounced/cancelable.
   * */
  onBlur: PropTypes.func,

  /** Called when the input is focused. Can be used to cancel a pending
   * onBlur. */
  onFocus: PropTypes.func,

  // The initial list of recipients
  recipients: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types

  // Tab index
  tabIndex: PropTypes.string,

  /* Used by the connected component */
  ticketId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types

  // type of dropdown.
  type: PropTypes.oneOf(['to', 'cc', 'bcc']),

  // whether the search DD opens upward
  upward: PropTypes.bool,

  // Last search query
  query: PropTypes.string,

  /** Allows direct access to the search box input */
  searchBoxRef: PropTypes.func,
}

RecipientSearch.defaultProps = {
  bgColor: 'white',
  allowCreateNewContactByName: false,
  allowMultipleRecipients: true,
  onAdd: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onRemove: undefined,
  type: undefined,
  tabIndex: '-1',
  upward: false,
  searchBoxRef: undefined,
}

export default RecipientSearch
