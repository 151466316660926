import React, { useCallback, useMemo, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'

import NavItem from 'components/App/DesktopView/shared/Composer/Email/Header/NavItem'

import sparkles from 'assets/icons/emoji/sparkles.png'
import { isFunction } from 'util/functions'
import { emptyArr } from 'util/arrays'

import DropdownMenu from './DropdownMenu'
import WithAiMessageCallout from './WithAiMessageCallout'
import { styles } from './styles'

const MessageDropdown = ({
  position = 'bottom-end',
  featureCalloutHidden = true,
  onAiOptionSelected,
  list: listProp,
  ...props
}) => {
  const listFn = isFunction(listProp) && props.list
  const [list, setList] = useState(listFn ? emptyArr : listProp)
  const [menuVisible, setMenuVisible] = useState(false)

  const handleMenuVisibleChange = useCallback(
    visible => {
      if (visible && listFn) {
        setList(listFn())
      }
      setMenuVisible(visible)
    },
    [listFn]
  )

  const onSelect = useCallback(
    item => {
      if (item.comingSoon === true) return

      onAiOptionSelected(item)
      setMenuVisible(false)
    },
    [onAiOptionSelected]
  )

  const overlay = useMemo(
    () => {
      return (
        <div className="grui flex h-100" css={styles.overlay}>
          <DropdownMenu onSelect={onSelect} list={list} />
        </div>
      )
    },
    [onSelect, list]
  )

  return (
    <>
      <Dropdown
        overlay={overlay}
        css={[text.styles.textSm, styles.dropdownContainer]}
        visible={menuVisible}
        onVisibleChange={handleMenuVisibleChange}
        emptyHint=""
        portal="#overlays"
        strategy="fixed"
        position={position}
        autoHeight
        isNavByArrowsDisabled
      >
        <div>
          <Tooltip title="Use AI features" strategy="fixed" position="bottom">
            <NavItem active {...props}>
              <div className="grui flex-1-0-auto flex-center">
                <WithAiMessageCallout hidden={featureCalloutHidden}>
                  <Button
                    type="icon"
                    size="small"
                    {...props}
                    css={styles.wandIcon}
                  >
                    <img src={sparkles} alt="sparkles-icon" />
                  </Button>
                </WithAiMessageCallout>
              </div>
            </NavItem>
          </Tooltip>
        </div>
      </Dropdown>
    </>
  )
}

export default MessageDropdown
