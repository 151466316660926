import React from 'react'
import { styles } from './styles'

export default React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <input
      css={styles.searchBox}
      type="search"
      autoComplete="off"
      autoCapitalize="off"
      autoCorrect="off"
      spellCheck="off"
      className={className}
      ref={ref}
      tabIndex="-1"
      {...rest}
    />
  )
})
