import GlobalContext from './GlobalContext'
import WithLocalContext from './WithLocalContext'

export default function WithGlobalContext(props) {
  return (
    <GlobalContext.Consumer>
      {globalContext => (
        <WithLocalContext {...props} globalContext={globalContext} />
      )}
    </GlobalContext.Consumer>
  )
}

WithGlobalContext.displayName = 'WithGlobalContext(ListenToKeyboard)'
