import { createElement, useCallback, useState } from 'react'
import { compose } from 'redux'
import { useTheme } from '@emotion/react'
import { IconButton } from 'shared/ui'

import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import Modal from 'components/Modal'
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker/Dropdown/Content/EmojiPicker'

const EmojiPickerButton = ({
  onChoose,
  children: CustomButton,
  left,
  right,
  tooltipPortal,
}) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const onButtonClick = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onSelect = useCallback(
    emoji => {
      onChoose(emoji)
      setOpen(false)
    },
    [onChoose]
  )

  const onEmojiPickerAreaClick = useCallback(
    event => event.stopPropagation(),
    []
  )

  return (
    <div onClick={onEmojiPickerAreaClick}>
      {CustomButton ? (
        createElement(CustomButton.type, {
          ...CustomButton.props,
          onClick: onButtonClick,
        })
      ) : (
        <IconButton
          name="smiley"
          svg
          onClick={onButtonClick}
          size="small"
          tooltip="Add an emoji"
          iconSize={16}
          tooltipPortal={tooltipPortal}
        />
      )}

      {open && (
        <Modal
          offset={{ left, right }}
          onBackgroundClick={onClose}
          portal="#overlays"
        >
          <EmojiPicker onSelect={onSelect} theme={theme} />
        </Modal>
      )}
    </div>
  )
}

export default compose(withMenuPositioning)(EmojiPickerButton)
