import { connect } from 'react-redux'

import {
  selectIsOpen,
  selectImages,
  selectImageIndex,
} from 'selectors/lightbox'

import { doClose, doShowPrevious, doShowNext } from 'actions/lightbox'
import { selectAccountSubdomain } from 'selectors/app'

import ImageLightbox from './view'

const select = state => ({
  isOpen: selectIsOpen(state),
  images: selectImages(state),
  currentImage: selectImageIndex(state),
  subdomain: selectAccountSubdomain(state),
})

const perform = {
  close: doClose,
  showPrevious: doShowPrevious,
  showNext: doShowNext,
}

export default connect(select, perform)(ImageLightbox)
