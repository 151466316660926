import { useCallback, useRef, useState } from 'react'

export default function useFileInputBindings(existingUrl) {
  const fileInputRef = useRef(null)
  const openTriggerRef = useRef(null)
  const [url, setUrl] = useState(existingUrl)
  const [remove, setRemove] = useState(false)
  const { current: reader } = useRef(new FileReader())
  if (!reader.onloadend) {
    reader.onloadend = () => {
      setUrl(reader.result)
    }
  }
  const doRemove = useCallback(
    () => {
      fileInputRef.current.value = null
      setUrl(null)
      setRemove(true)
    },
    [fileInputRef, setUrl]
  )
  const doOpen = useCallback(() => {
    fileInputRef.current.click()
  }, [])
  const onFileChange = useCallback(
    ({ target }) => {
      reader.readAsDataURL(target.files[0])
      const { current: { ref: { current: trigger } = {} } = {} } =
        openTriggerRef || {}
      if (trigger) trigger.blur()
      setRemove(false)
    },
    [reader]
  )

  return {
    doRemove,
    doOpen,
    fileInputRef,
    remove,
    openTriggerRef,
    onFileChange,
    url,
  }
}
