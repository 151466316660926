import React from 'react'
import { css } from '@emotion/react'
import { Button } from 'util/ui'
import { getTimestampDisplayString } from 'util/date'
import { propFunc } from 'util/functions'
import themeVars from 'ui_theme/site/globals/site.variables'
import List from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/List'
import ContentCard from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/ContentCard'

const cssClass = css`
  margin-top: 8px;
`

export default React.memo(SubscriptionList)

function SubscriptionList({ contactId, subscriptions, onUnsubscribe }) {
  return (
    <div css={cssClass}>
      {subscriptions.map(({ listName, listId, subscribedAt }) => (
        <ContentCard title={listName} key={listId}>
          <List.Container>
            {subscribedAt && (
              <List.Item label="Subscribed">
                {getTimestampDisplayString(subscribedAt)}
              </List.Item>
            )}
          </List.Container>
          <Button
            size="medium"
            color={themeVars.berryColorName}
            onClick={propFunc(onUnsubscribe, contactId, listId)}
          >
            Remove from list
          </Button>
        </ContentCard>
      ))}
    </div>
  )
}
