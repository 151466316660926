import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'redux-first-router-link'
import { runOnNextTick } from 'util/functions'

import NavItem from './NavItem'

function duplicateClick(e) {
  /**
   * SN: Hack to keep composer focused when switching between Reply and Note.
   * This needs to be done via Redux(?) instead. Feel free to curse me.
   */
  const { clientX, clientY } = e
  runOnNextTick(() => {
    const target = document.elementFromPoint(clientX, clientY)
    const targetContainer = target.parentElement.parentElement
    targetContainer.click()
  })
}

const NavLink = ({ to, className, ...rest }) => {
  return (
    <Link className={className} to={to}>
      <NavItem
        css={css`
          font-weight: 500;
        `}
        onClick={duplicateClick}
        {...rest}
      />
    </Link>
  )
}

export default styled(NavLink)``
