import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const annualOffer = css`
  max-width: ${rem(617)};
  padding: 0;

  p {
    max-width: unset;
  }
`

const imgWrapper = theme => css`
  background-color: ${theme.color.info['50']};
  width: 100%;
  height: ${rem(225)};
  border-top-left-radius: ${rem(12)};
  border-top-right-radius: ${rem(12)};
  display: flex;

  img {
    margin: auto auto -10px;
    max-width: ${rem(242)};
  }
`

const savingTitle = theme => css`
  font-size: ${theme.fontSize.base};
  margin-top: ${rem(16)};
  display: block;
`

const saving = theme => css`
  font-size: ${theme.fontSize.x6large};
  line-height: ${theme.lineHeight.leadingXSnug};
  margin-top: ${rem(5)};
  padding: 0 ${rem(14)} ${rem(6)};

  &::before {
    background-color: ${theme.color.info['50']};
    transform: rotate(2deg);
  }
`

const note = theme => css`
  font-size: ${theme.fontSize.small};
`

export const styles = {
  annualOffer,
  imgWrapper,
  savingTitle,
  saving,
  note,
}
