import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

const DynamicPortal = ({ portalRef, children }) => {
  const [domReady, setDomReady] = useState(false)
  useEffect(
    () => {
      if (portalRef.current && !domReady) {
        setDomReady(true)
      }
    },
    [domReady, setDomReady, portalRef]
  )

  return portalRef.current
    ? ReactDOM.createPortal(children, portalRef.current)
    : null
}

export default DynamicPortal
