import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import KeyboardNavigator from 'components/KeyboardNavigator'
import KeyboardHandler from 'components/Lists/KeyboardHandler'

import { withResizeChange } from 'util/hoc'

import SelectingTeamState from './SelectingTeamState'
import NormalState from './NormalState'

import styles from './styles.css'

const WithWindowResizing = WrappedComponent => {
  return withResizeChange(({ width, height, ...rest }) => {
    return <WrappedComponent {...rest} height={height} />
  })
}

const SelectingTeamStateMeasure = WithWindowResizing(SelectingTeamState)

const NormalStateMeasure = WithWindowResizing(NormalState)

class AssignmentModal extends React.Component {
  static propTypes = {
    onAgentSelect: PropTypes.func.isRequired,
    onGroupSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired, // always controlled.
    open: PropTypes.bool.isRequired,
  }

  state = {
    isSelectingGroup: false,
  }

  onGroupSelect = groupId => {
    this.props.onGroupSelect(this.props.conversationIds, groupId)
    if (!this.props.unmountedOnClose) this.handleClose()
  }

  onAgentSelect = (groupId, agentId) => {
    this.props.onAgentSelect(this.props.conversationIds, groupId, agentId)
    if (!this.props.unmountedOnClose) this.handleClose()
  }

  handleClose = () => {
    const { onClose, onFilter } = this.props
    if (onFilter) onFilter(null)
    onClose()
  }

  runAndClose = fn => (...args) => {
    fn(...args)
    this.handleClose()
  }

  enableGroupSelection = () => {
    const { onFilter } = this.props
    this.setState({ isSelectingGroup: true })
    if (onFilter) onFilter('') // clear the search state
  }

  disableGroupSelection = () => this.setState({ isSelectingGroup: false })

  render() {
    if (!this.props.open) return null

    const { offset, hidden } = this.props
    const { isSelectingGroup } = this.state
    const overrideProps = {
      onAgentSelect: this.onAgentSelect,
      onGroupSelect: this.onGroupSelect,
      close: this.handleClose,
    }
    let content = null

    if (isSelectingGroup) {
      content = (
        <SelectingTeamStateMeasure
          onCurrentTabClick={this.disableGroupSelection}
          {...this.props}
          {...overrideProps}
        />
      )
    } else {
      content = (
        <NormalStateMeasure
          onGroupTabClick={this.enableGroupSelection}
          hidden={hidden}
          {...this.props}
          {...overrideProps}
        />
      )
    }
    return (
      <Modal
        offset={offset}
        onBackgroundClick={this.handleClose}
        contentClass={styles.assignmentModal}
        styleContainer={{
          visibility: hidden ? 'hidden' : 'visible',
        }}
      >
        <React.Fragment>
          <KeyboardNavigator.Focus />
          <KeyboardHandler onEscape={this.handleClose} />
          {content}
        </React.Fragment>
      </Modal>
    )
  }
}

export default AssignmentModal
