import { PureComponent } from 'react'
import * as Keys from 'constants/keys'
import { any, uniq } from 'util/arrays'

import LocalContext from './LocalContext'

const ON_KEY_PROP_REGEX = /^on(Cmd|Ctrl|Alt|Shift)?((.+)(Down|Up)|(.+))$/
const VALID_KEY_EVENT_ID_REGEX = /^[A-Za-z0-9+]+$/
const SNAKE_CASE_REGEX = /(.)([A-Z])/g

export default class ListenToKeyboard extends PureComponent {
  constructor(props) {
    super(props)
    this.handleEvent = this.handleEvent.bind(this)
    this.getOnKeyHandlers = this.getOnKeyHandlers.bind(this)
    this.getOnEventHandlers = this.getOnEventHandlers.bind(this)
    this.getRawHandlers = this.getRawHandlers.bind(this)
    this.calculateListeners = this.calculateListeners.bind(this)
    this.listenersForEvent = this.listenersForEvent.bind(this)
  }

  componentWillMount() {
    document.addEventListener('keydown', this.handleEvent, false)
    document.addEventListener('keyup', this.handleEvent, false)
    const { globalContext, hijack, hijackChildren } = this.props
    if (globalContext && hijack) {
      globalContext.setHijacker(this, hijack, hijackChildren)
    }
  }

  componentWillReceiveProps(newProps) {
    const propKeys = uniq(Object.keys(newProps).concat(Object.keys(this.props)))
    const havePropsChanged = any(k => newProps[k] !== this.props[k], propKeys)
    if (havePropsChanged) {
      const { globalContext } = newProps
      this.cachedListeners = null
      const { hijack, hijackChildren } = newProps
      if (globalContext) {
        if (hijack) {
          globalContext.setHijacker(this, hijack, hijackChildren)
        } else {
          globalContext.unSetHijacker(this, hijack)
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEvent, false)
    document.removeEventListener('keyup', this.handleEvent, false)
    const { globalContext } = this.props
    if (globalContext) globalContext.unSetHijacker(this)
  }

  getOnKeyHandlers = () => {
    const {
      onKeyUp,
      onKeyDown,
      debug,
      disableForInput,
      keys,
      preventDefault,
      stopPropagation,
      withCmd,
      withAlt,
      withShift,
      ...rest
    } = this.props
    return Object.keys(rest)
      .map(key => {
        const matches = key.match(ON_KEY_PROP_REGEX)
        if (matches) {
          let modifier = matches[1] && matches[1].toUpperCase()
          if (['CMD', 'META', 'WINDOWS'].indexOf(modifier) >= 0)
            modifier = 'CTRL'
          let keyName = (matches[3] || matches[5])
            .replace(SNAKE_CASE_REGEX, '$1_$2')
            .toUpperCase()
          if (['CMD', 'META', 'WINDOWS'].indexOf(keyName) >= 0) keyName = 'CTRL'
          if (keyName === 'ESC') keyName = 'ESCAPE'
          const event = (matches[4] || 'Down').toUpperCase()
          if (Keys[keyName]) {
            const id = [modifier, keyName, event].filter(x => !!x).join('+')
            return {
              id,
              listener: rest[key],
            }
          }
        }
        return null
      })
      .filter(x => !!x)
  }

  getOnEventHandlers = () => {
    const {
      onKeyUp,
      onKeyDown,
      keys = [],
      withCmd,
      withCtrl,
      withAlt,
      withShift,
    } = this.props
    const modifiers = []
    if (withCmd || withCtrl) modifiers.unshift('CTRL')
    if (withShift) modifiers.unshift('SHIFT')
    if (withAlt) modifiers.unshift('ALT')
    const listeners = []
    keys.forEach(keyCode => {
      const keyIndex = Object.values(Keys).findIndex(x => x === keyCode)
      const keyName = Object.keys(Keys)[keyIndex]
      const idParts = [...modifiers, keyName]
      if (onKeyUp)
        listeners.push({ id: `${idParts.join('+')}+UP`, listener: onKeyUp })
      if (onKeyDown)
        listeners.push({ id: `${idParts.join('+')}+DOWN`, listener: onKeyDown })
    })
    return listeners
  }

  getRawHandlers = () => {
    const {
      enabled,
      disableForInput,
      debug,
      onKeyUp,
      onKeyDown,
      keys,
      preventDefault,
      stopPropagation,
      withCmd,
      withCtrl,
      withAlt,
      withShift,
      ...rest
    } = this.props
    return Object.keys(rest)
      .map(key => {
        const matches = key.match(VALID_KEY_EVENT_ID_REGEX)
        const keyUpper = key.toUpperCase()
        const keyId = keyUpper.match(/(DOWN|UP)$/)
          ? keyUpper
          : `${keyUpper}+DOWN`
        if (matches && !key.match(ON_KEY_PROP_REGEX)) {
          return {
            id: keyId.replace(/(\+|^)(CMD|WINDOWS|META)(\+|$)/, '$1CTRL$3'),
            listener: rest[key],
          }
        }
        return null
      })
      .filter(x => !!x)
  }

  get listeners() {
    if (!this.cachedListeners) this.calculateListeners()
    return this.cachedListeners
  }

  calculateListeners = () => {
    this.cachedListeners = [
      ...this.getOnKeyHandlers(),
      ...this.getOnEventHandlers(),
      ...this.getRawHandlers(),
    ].reduce((result, item) => {
      const existingValue = result[item.id] || []
      return {
        ...result,
        [item.id]: [...existingValue, item.listener],
      }
    }, {})
  }

  listenersForEvent = event => {
    const keyCode = event.charCode || event.keyCode
    const keyIndex = Object.values(Keys).findIndex(x => x === keyCode)
    let keyName = Object.keys(Keys)[keyIndex]
    if (['LEFT_CMD', 'CTRL', 'CONTEXT_MENU'].indexOf(keyName) >= 0)
      keyName = 'CTRL'
    if (!keyName) return []
    const keyEventParts = [keyName]
    if (event.altKey && keyName !== 'ALT') keyEventParts.unshift('ALT')
    if (event.shiftKey && keyName !== 'SHIFT') keyEventParts.unshift('SHIFT')
    if ((event.metaKey || event.ctrlKey) && keyName !== 'CTRL')
      keyEventParts.unshift('CTRL')
    if (event.type === 'keydown') keyEventParts.push('DOWN')
    if (event.type === 'keyup') keyEventParts.push('UP')
    const keyEventId = keyEventParts.join('+')
    return this.listeners[keyEventId] || []
  }

  handleEvent = event => {
    const {
      globalContext: { hijackChildren, hijacker } = {},
      localContext,
    } = this.props
    // A ListenToKeyboard component has hijacked events and it wasn't one of our parents, or it has hijacked its children too
    const parentsHijacked =
      hijacker && (!localContext.includes(hijacker) || hijackChildren)
    // A ListenToKeyboard component has hijacked events and it wasn't us
    const selfHijacked = hijacker && hijacker !== this
    if (parentsHijacked && selfHijacked) return null
    const {
      preventDefault,
      stopPropagation,
      enabled,
      disableForInput,
      ...rest
    } = this.props
    const { target } = event
    const isTargetInput =
      target &&
      (target.contentEditable === 'true' ||
        target.nodeName === 'INPUT' ||
        target.nodeName === 'TEXTAREA')
    if (disableForInput && isTargetInput) return null
    if (
      enabled === false ||
      (typeof enabled === 'function' && enabled(event) === false)
    ) {
      return null
    }
    const listeners = this.listenersForEvent(event)
    if (listeners.length > 0) {
      if (preventDefault) event.preventDefault()
      if (stopPropagation) event.stopPropagation()
    }
    listeners.forEach(listener => {
      if (listener) listener(event, rest)
    })
    return null
  }

  render = () => {
    return (
      <LocalContext.Provider value={[...this.props.localContext, this]}>
        {this.props.children || null}
      </LocalContext.Provider>
    )
  }
}
