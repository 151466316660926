import React from 'react'
import { Tab } from 'util/ui'
import { useShopInfo } from 'ducks/integrations/shopify/hooks'

import TimelineEvents from './TimelineEvents'
import CustomerDetails from './CustomerDetails'

const DetailsContainer = React.memo(props => {
  const { integration } = props
  useShopInfo({ integrationId: integration.id })

  const panes = [
    {
      menuItem: 'Customer',
      render: () => (
        <Tab.Pane as="div">
          <CustomerDetails {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Timeline',
      render: () => (
        <Tab.Pane as="div">
          <TimelineEvents
            integrationId={props.integration.id}
            customerId={props.customerGid}
          />
        </Tab.Pane>
      ),
    },
  ]

  return <Tab panes={panes} />
})

DetailsContainer.displayName = 'DetailsContainer'

export default DetailsContainer
