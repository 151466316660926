import { connect } from 'react-redux'

import { selectSnackbarIsOpen, selectSnackbarProps } from 'selectors/app'
import { selectCurrentPath as currentPathSelector } from 'selectors/location'
import { selectCurrentUndoData, selectIsUndoActive } from 'selectors/undoSend'
import { doHideSnackbar } from 'actions/snackbar'
import { doUndoSend, doEndTimer } from 'actions/undoSend'

import SnackbarSwitcherView from './view'

const select = state => ({
  isMobile: false,
  isNotificationOpen: selectSnackbarIsOpen(state),
  notificationData: selectSnackbarProps(state),
  currentPath: currentPathSelector(state),
  isUndoOpen: selectIsUndoActive(state),
  undoData: selectCurrentUndoData(state),
})

const perform = dispatch => ({
  onNotificationHide: () => dispatch(doHideSnackbar()),
  onUndoAction: undoData => dispatch(doUndoSend(undoData)),
  onUndoHide: undoData => dispatch(doEndTimer(undoData)),
})

export default connect(select, perform)(SnackbarSwitcherView)
