import React from 'react'
import LoginLayoutV2 from 'components/LoginLayoutV2'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { anchor } from '@groovehq/internal-design-system/lib/styles/elements'
import blogImg from 'assets/customer-service.jpg'

import LoginForm from './Form'
import { styles } from './styles'

export default function LoginPage() {
  return (
    <div className="grui flex" css={styles.loginPage}>
      <LoginLayoutV2>
        <LoginForm />
      </LoginLayoutV2>
      <div className="grui flex-center" css={styles.blogContainer}>
        <div css={styles.blog}>
          <div css={styles.label}>Check out what’s new...</div>
          <a
            href="https://www.groovehq.com/blog/ai-customer-service"
            target="_blank"
            rel="noopener noreferrer"
            css={[styles.blogCard, anchor.styles.preflight]}
            className="grui"
          >
            <img
              src={blogImg}
              alt="customer service"
              width="315"
              height="180"
              className="grui block"
            />
            <div className="grui p-12">
              <h4 css={styles.blogCardHeading} className="grui">
                {`AI Customer Service: How To Start?`}
              </h4>
              <p className="grui m-0" css={styles.copy}>
                ChatGPT was released around 12 months ago, yet for many people,
                it is hard to imagine their daily work without it.
              </p>
            </div>
          </a>
          <div className="grui text-center">
            <Button
              as="a"
              href="https://www.groovehq.com/blog/ai-customer-service"
              target="_blank"
              rel="noopener noreferrer"
              type="tertiary"
              size="xBig"
              css={styles.btnBlog}
            >
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
