import cn from 'classnames'
import AnimateHeight from 'react-animate-height'
import styled from '@emotion/styled'
import { Icon } from 'util/ui'

import withOpenState from 'shared/ui/withOpenState'
import { Loader } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

export default withOpenState(CollapsableCard, {
  defaultInitiallyOpen: true,
  persist: true,
})
const transitionDuration = '250ms'
const Styles = styled('div')`
  border: 1px solid transparent;
  border-radius: ${themeVars.defaultBorderRadius};
  background: transparent;
  transition: background ease-in ${transitionDuration},
    border-color ease-in ${transitionDuration};
  margin-bottom: ${props => props.theme.spacing['5']};

  &.sui {
    height: auto;
  }

  &.open,
  &:hover {
    background: white;
    border-color: ${props =>
      `rgba(${props.theme.color.monochrome.black_rgba}, 0.1)`};
    .CollapsableCard-title {
      border-color: ${props =>
        `rgba(${props.theme.color.monochrome.black_rgba}, 0.1)`};
    }
  }

  &:hover {
    .CollapsableCard-icon {
      opacity: 1;
    }
  }

  &.open .CollapsableCard-title .icon {
    transform: rotate(90deg) translate(2px, -4px);
  }

  .CollapsableCard-title {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: ${props => props.theme.color.monochrome.black};
    display: flex;
    font-size: 11px;
    font-family: ${props => props.theme.font.quaternary};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: ${props => props.theme.spacing['10']};
    padding: ${props => props.theme.spacing['7']} ${themeVars.small};
    text-transform: uppercase;
    transition: background ${transitionDuration} ease-in,
      border-color ${transitionDuration} ease-in;
  }

  .CollapsableCard-image {
    height: 16px;
    margin-right: ${props => props.theme.spacing['5']};
  }
  .CollapsableCard-image,
  .CollapsableCard-icon {
    flex-grow: 0;
  }
  .CollapsableCard-text {
    flex-grow: 1;
    letter-spacing: ${themeVars['1px']};
    line-height: ${props => props.theme.spacing['10']};
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px 0;
    margin: -5px 0;
    white-space: nowrap;
    padding-right: ${props => props.theme.spacing['5']};
    max-width: 100%;
  }

  .CollapsableCard-icon {
    opacity: 0;
    transition: opacity ${transitionDuration} ease-in-out;
    margin-left: 4px;
    margin-right: 3px;
    i.icon {
      transition: transform ${transitionDuration} ease-in-out;
      transform: rotate(0deg) translate(3px, 0px);
    }
  }

  .CollapsableCard-content > div {
    display: block !important;
  }

  .CollapsableCard-contentInternal {
    padding: ${props => props.theme.spacing['7']};

    &.hideOverflow {
      overflow: hidden;
    }

    &.hidePadding {
      padding: 0;
    }
  }

  .CollapsableCard-Loader {
    margin: ${props => props.theme.spacing['10']} auto;
  }
`

function CollapsableCard({
  children,
  className,
  hideOverflow = true,
  hidePadding = false,
  image,
  isLoading = false,
  open,
  onToggleOpen,
  title,
}) {
  const height = open ? 'auto' : 0
  return (
    <Styles className={cn('sui CollapsableCard', className, { open })}>
      <div className="CollapsableCard-title" onClick={onToggleOpen}>
        {image && <img className="CollapsableCard-image" alt="" src={image} />}
        <div className="CollapsableCard-text">{title}</div>
        <div className="CollapsableCard-icon">
          <Icon name="chevron right" />
        </div>
      </div>
      <AnimateHeight
        animateOpacity
        className="CollapsableCard-content"
        height={height}
      >
        {isLoading && <Loader className="CollapsableCard-Loader" />}
        {!isLoading && (
          <div
            className={cn('CollapsableCard-contentInternal', {
              hideOverflow,
              hidePadding,
            })}
          >
            {children}
          </div>
        )}
      </AnimateHeight>
    </Styles>
  )
}
