import React, { useCallback } from 'react'

export default function LinkedResourceAction({ action, children, id }) {
  const onClick = useCallback(() => action(id), [action, id])
  return (
    <span className="action" onClick={onClick}>
      {children}
    </span>
  )
}
