import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { deepCopy } from 'util/objects'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import ProductsSearch from 'components/integrations/ShopifyV2WidgetCard/ProductsSearch'
import CustomItem from 'components/integrations/ShopifyV2WidgetCard/CustomItem'
import OrderSummary from 'components/integrations/ShopifyV2WidgetCard/OrderSummary'
import PaymentTerm from 'components/integrations/ShopifyV2WidgetCard/PaymentTerm'
import ProductListTable from 'components/integrations/ShopifyV2WidgetCard/ProductListTable'

import {
  doDraftOrderDelete,
  selectCurrencyCode,
} from 'ducks/integrations/shopify'
import { PAYMENT_TERMS_TYPES } from 'ducks/integrations/shopify/constants'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import {
  NEW_ORDER_DEFAULTS,
  generateDefaultValues,
  convertOrderItemsToLineItems,
  FORM_KEY_ORDER_ITEMS,
  FORM_KEY_HAS_TAXES,
  FORM_KEY_SHIPPING,
  FORM_KEY_DISCOUNT,
  FORM_KEY_NOTES,
  FORM_KEY_TAGS,
  FORM_KEY_IS_PAYMENT_DUE_LATER,
  FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID,
  FORM_KEY_PAYMENT_SCHEDULES_DUE_AT,
  FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT,
  FORM_KEY_PAYMENT_TERMS_TYPE,
} from './util'
import Footer from './Footer'

const ORDER_FORM_SCHEMA = yup.object().shape({
  [FORM_KEY_ORDER_ITEMS]: yup.array(yup.object()).min(1, 'Add at least 1 item'),
  [FORM_KEY_HAS_TAXES]: yup.boolean().required(),
  [FORM_KEY_SHIPPING]: yup.object(),
  [FORM_KEY_DISCOUNT]: yup
    .object()
    // no validations or restrictions here as it's all done in PriceDiscount/view.jsx
    .shape({
      value: yup.number(),
      valueType: yup.string(),
      description: yup.string(),
    })
    .nullable(),
  [FORM_KEY_NOTES]: yup.string().nullable(),
  [FORM_KEY_TAGS]: yup.string().nullable(),
  // payment terms rules
  // https://shopify.dev/api/admin-graphql/2022-01/mutations/draftOrderUpdate#field-draftorderinput-paymentterms
  [FORM_KEY_IS_PAYMENT_DUE_LATER]: yup.boolean().required(),
  [FORM_KEY_PAYMENT_TERMS_TYPE]: yup.string().nullable(),
  [FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID]: yup
    .string()
    .nullable()
    .when(FORM_KEY_IS_PAYMENT_DUE_LATER, {
      is: true,
      then: schema => schema.required('Please select a payment term'),
      otherwise: schema => schema.oneOf([null, undefined]),
    }),
  [FORM_KEY_PAYMENT_SCHEDULES_DUE_AT]: yup
    .date()
    .nullable()
    .when([FORM_KEY_IS_PAYMENT_DUE_LATER, FORM_KEY_PAYMENT_TERMS_TYPE], {
      is: (isDueLater, termsType) =>
        !!isDueLater && termsType === PAYMENT_TERMS_TYPES.FIXED,
      then: schema =>
        schema.required('Due date is required for fixed payment term'),
      otherwise: schema => schema.oneOf([null, undefined]),
    }),
  [FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT]: yup
    .date()
    .nullable()
    .when([FORM_KEY_IS_PAYMENT_DUE_LATER, FORM_KEY_PAYMENT_TERMS_TYPE], {
      is: (isDueLater, termsType) =>
        !!isDueLater && termsType === PAYMENT_TERMS_TYPES.NET,
      then: schema =>
        schema.required('Issue date is required for fixed payment term'),
      otherwise: schema => schema.oneOf([null, undefined]),
    }),
})

const CreateOrderDrawer = ({
  onClose,
  onExit,
  visible = true,
  drawerResourceId: customerGid,
  drawerIntegrationId: integrationId,
  orderIdToDuplicate,
}) => {
  const {
    delayedDraftOrderUpdate,
    integration,
    draftOrder,
    draftOrderCreate,
    draftOrderCreateFromOrder,
    delayedDraftOrderCalculate,
  } = useShopifyDraft({
    integrationId,
    customerId: customerGid,
  })
  const isDuplicateOrder = !!orderIdToDuplicate
  const currencyCode = useSelector(state =>
    selectCurrencyCode(state, integrationId)
  )
  const [calculatedDraft, setCalculatedDraft] = useState(null)
  const dispatch = useDispatch()
  const defaultValues = useMemo(() => {
    return generateDefaultValues()
  }, [])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ORDER_FORM_SCHEMA),
    defaultValues,
  })

  const { register, reset, watch, getValues, setValue, control } = methods

  const runCalculation = useCallback(
    async () => {
      const items = getValues(FORM_KEY_ORDER_ITEMS)
      const result = await delayedDraftOrderCalculate(
        integration.id,
        customerGid,
        {
          lineItems: convertOrderItemsToLineItems(items),
        }
      )
      setCalculatedDraft(result)
    },
    [
      integration,
      customerGid,
      delayedDraftOrderCalculate,
      getValues,
      setCalculatedDraft,
    ]
  )

  useEffect(
    () => {
      // When dealing with duplicates, we want to create the draft order as soon as possible
      if (isDuplicateOrder) {
        draftOrderCreateFromOrder(
          integrationId,
          customerGid,
          orderIdToDuplicate
        )
      }
    },
    [
      orderIdToDuplicate,
      isDuplicateOrder,
      integrationId,
      customerGid,
      draftOrderCreateFromOrder,
    ]
  )

  useEffect(
    () => {
      if (!draftOrder.id) return
      let shipping = getValues(FORM_KEY_SHIPPING)
      if (draftOrder.shippingLine) {
        const {
          shippingRateHandle,
          custom,
          originalPriceSet,
          title,
        } = draftOrder.shippingLine
        let type = shippingRateHandle
        if (custom) type = 'custom'
        shipping = {
          type,
          title,
          fee: originalPriceSet?.shopMoney.amount * 1,
        }
      } else {
        // there either was no shipping to start with or shopify cleared it because currently selected items are not shippable
        shipping = deepCopy(NEW_ORDER_DEFAULTS[FORM_KEY_SHIPPING])
      }
      const { tags } = draftOrder
      const resetPayload = {
        ...getValues(),
        [FORM_KEY_NOTES]: draftOrder.note2,
        [FORM_KEY_TAGS]: tags ? tags.join(', ') : '',
        [FORM_KEY_DISCOUNT]: draftOrder.appliedDiscount,
        [FORM_KEY_SHIPPING]: shipping,
        [FORM_KEY_ORDER_ITEMS]: draftOrder.lineItems,
        [FORM_KEY_HAS_TAXES]: !draftOrder.taxExempt,
        // We don't need to load payment terms in draft return because:
        // 1. we don't load existing draft orders on drawer open any more
        // 2. the payment terms & schedules api query response is very different from what we send on mutate. Making it a bit complicated to reconcile with the form (e.g. query does not return the term template id)
        // 3. shopify does not copy over payment terms on order duplication
      }
      reset(resetPayload)
      if (!calculatedDraft) runCalculation()
    },
    [draftOrder, getValues, reset, runCalculation, calculatedDraft]
  )

  // order fields
  const shipping = watch(FORM_KEY_SHIPPING)
  const setShipping = useCallback(
    async newShipping => {
      let shippingLine = {}
      const { type, fee, title } = newShipping
      if (type === 'free') {
        shippingLine = { price: 0, title: 'Free' }
      } else if (type === 'custom') {
        shippingLine = { price: fee, title: title || 'Custom' }
      } else {
        shippingLine = {
          shippingRateHandle: type,
        }
      }
      setValue(FORM_KEY_SHIPPING, newShipping)

      if (integration) {
        await delayedDraftOrderUpdate(
          integration.id,
          draftOrder.id,
          {
            shippingLine,
            useCustomerDefaultAddress: true,
          },
          // For an unknown reason shopify returns shippingLine as null
          // in the response to the draft update even when the shipping is
          // being applied. The solution is just to reload the draft before
          // returning the response
          { withReload: true }
        )
        runCalculation()
      }
    },
    [setValue, draftOrder, delayedDraftOrderUpdate, integration, runCalculation]
  )

  const discount = watch(FORM_KEY_DISCOUNT)
  const setDiscount = useCallback(
    appliedDiscount => {
      setValue(FORM_KEY_DISCOUNT, appliedDiscount)
      if (integration) {
        delayedDraftOrderUpdate(integration.id, draftOrder.id, {
          appliedDiscount,
        })
      }
    },
    [setValue, delayedDraftOrderUpdate, integration, draftOrder.id]
  )

  const hasTaxes = watch(FORM_KEY_HAS_TAXES)
  const setHasTaxes = useCallback(
    newValue => {
      const taxExempt = !newValue
      delayedDraftOrderUpdate(integration.id, draftOrder.id, { taxExempt })
      setValue(FORM_KEY_HAS_TAXES, newValue)
    },
    [setValue, integration, draftOrder.id, delayedDraftOrderUpdate]
  )

  const orderItems = watch(FORM_KEY_ORDER_ITEMS)
  const setOrderItems = useCallback(
    newValue => {
      setValue(FORM_KEY_ORDER_ITEMS, newValue)
    },
    [setValue]
  )

  const handleExit = useCallback(
    () => {
      if (draftOrder && draftOrder.id) {
        dispatch(doDraftOrderDelete(integration.id, draftOrder.id))
      }

      onExit()
    },
    [dispatch, integration, draftOrder, onExit]
  )

  const handleAddItem = useCallback(
    async variant => {
      const hasItemAlready =
        orderItems.filter(item => item.id === variant.id).length > 0
      if (hasItemAlready) return
      const newItem = {
        ...variant,
        variantId: variant.id,
        product: {
          name: variant.displayName,
          stock: variant.inventoryQuantity,
          sku: variant.sku,
        },
        price: {
          price: variant.price * 100, // all prices we get are with decimals
          currency: currencyCode,
        },
        quantity: variant.quantity || 1,
        total: variant.price * 100,
      }
      if (variant.custom) {
        newItem.customPrice = variant.price
      }
      const newItems = [...orderItems, newItem]
      setOrderItems(newItems)
      if (!draftOrder.id) {
        if (variant.custom) {
          await draftOrderCreate(integration.id, customerGid, {
            lineItems: convertOrderItemsToLineItems(newItems),
          })
          runCalculation()
        } else {
          await draftOrderCreate(integration.id, customerGid, {
            lineItems: convertOrderItemsToLineItems([
              { quantity: 1, id: variant.id },
            ]),
          })
          runCalculation()
        }
      } else {
        await delayedDraftOrderUpdate(integration.id, draftOrder.id, {
          lineItems: convertOrderItemsToLineItems(newItems),
        })
        runCalculation()
      }
    },
    [
      draftOrderCreate,
      delayedDraftOrderUpdate,
      draftOrder,
      orderItems,
      setOrderItems,
      integration,
      customerGid,
      runCalculation,
      currencyCode,
    ]
  )

  const handleRemoveItem = useCallback(
    id => {
      const newItems = orderItems.filter(item => item.id !== id)
      setOrderItems(newItems)
      delayedDraftOrderUpdate(integration.id, draftOrder.id, {
        lineItems: convertOrderItemsToLineItems(newItems),
      })
    },
    [
      delayedDraftOrderUpdate,
      draftOrder,
      orderItems,
      setOrderItems,
      integration,
    ]
  )

  const handleUpdateItem = useCallback(
    async (index, id, value) => {
      const items = getValues(FORM_KEY_ORDER_ITEMS)
      let newItem
      const item = items[index]
      if (id === 'quantity') {
        newItem = { ...item, quantity: value }
      }
      if (id === 'total') {
        newItem = { ...item, total: value }
      }
      if (id === 'bulk') {
        newItem = { ...item, ...value }
      }
      if (newItem) {
        const newItems = [...items]
        newItems.splice(index, 1, newItem)
        setOrderItems(newItems)
        await delayedDraftOrderUpdate(integration.id, draftOrder.id, {
          lineItems: convertOrderItemsToLineItems(newItems),
        })
        runCalculation()
      }
    },
    [
      getValues,
      setOrderItems,
      integration,
      draftOrder.id,
      delayedDraftOrderUpdate,
      runCalculation,
    ]
  )

  const handleUpdateDraft = useCallback(
    payload => {
      delayedDraftOrderUpdate(integration.id, draftOrder.id, payload)
    },
    [integration, draftOrder.id, delayedDraftOrderUpdate]
  )

  const handlePaymentTermsChange = useCallback(
    () => {
      const [
        paymentDueLater,
        dueAt,
        issuedAt,
        paymentTermsTemplateId,
      ] = getValues([
        FORM_KEY_IS_PAYMENT_DUE_LATER,
        FORM_KEY_PAYMENT_SCHEDULES_DUE_AT,
        FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT,
        FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID,
      ])

      let paymentTerms = {}

      if (paymentDueLater) {
        paymentTerms = {
          paymentTermsTemplateId,
          paymentSchedules: {
            dueAt,
            issuedAt,
          },
        }
      }

      handleUpdateDraft({ paymentTerms })
    },
    [getValues, handleUpdateDraft]
  )

  const orderTotal = useMemo(
    () => {
      let total = 0
      if (orderItems)
        orderItems.forEach(item => (total += parseInt(item.total, 10)))
      return total
    },
    [orderItems]
  )

  const hasShippableItems = useMemo(
    () => {
      return (orderItems || []).some(item => item.requiresShipping === true)
    },
    [orderItems]
  )

  const disablePaymentControls = orderItems.length === 0

  return (
    <FormProvider {...methods}>
      <Drawer
        title={`${isDuplicateOrder ? 'Duplicate' : 'Create'} order`}
        footer={
          <Footer
            onClose={onClose}
            integrationId={integrationId}
            customerId={customerGid}
            isDuplicate={isDuplicateOrder}
          />
        }
        onClose={handleExit}
        open={visible}
        css={text.styles.textDark}
        size="wide"
      >
        <div className="grui flex mb-8 mt-12">
          <ProductsSearch
            className="grui flex-1"
            onAddItem={handleAddItem}
            integration={integration}
            orderItems={orderItems}
          />
          <CustomItem
            currency={currencyCode}
            onAddItem={handleAddItem}
            integrationId={integrationId}
            customerId={customerGid}
          />
        </div>
        <ProductListTable
          orderItems={orderItems}
          handleRemoveItem={handleRemoveItem}
          handleUpdateItem={handleUpdateItem}
          integrationId={integrationId}
          customerId={customerGid}
        />
        <OrderSummary
          currency={currencyCode}
          rawTotal={orderTotal}
          onCloseCreateOrder={onClose}
          hasTaxes={hasTaxes}
          setHasTaxes={setHasTaxes}
          discount={discount}
          setDiscount={setDiscount}
          register={register}
          shipping={shipping}
          setShipping={setShipping}
          hasShippableItems={hasShippableItems}
          calculatedDraft={calculatedDraft}
          totalTax={draftOrder.totalTax}
          totalPrice={draftOrder.totalPrice}
          lineItemsSubtotalPrice={draftOrder.lineItemsSubtotalPrice}
          className="grui mt-10"
          control={control}
          updateDraft={handleUpdateDraft}
          disabled={disablePaymentControls}
          integrationId={integrationId}
          customerId={customerGid}
        />
        <PaymentTerm
          register={register}
          watch={watch}
          control={control}
          disabled={disablePaymentControls}
          onChange={handlePaymentTermsChange}
          integrationId={integrationId}
          customerId={customerGid}
        />
      </Drawer>
    </FormProvider>
  )
}

export default CreateOrderDrawer
