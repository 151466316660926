import { css } from '@emotion/react'

const domainField = theme => css`
  max-width: 100%;

  label {
    font-family: ${theme.font.primary};
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.leadingNormal};
    color: ${theme.color.monochrome.black};
  }

  &&&& input {
    border: none;
  }
`

export const styles = {
  domainField,
}
