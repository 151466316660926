import { Fragment, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getRechargeMerchantUrl } from 'ducks/integrations/recharge/utils'
import { selectSubscriptionsIsError } from 'ducks/integrations/recharge/selectors/subscriptions'
import { selectCustomerIsError } from 'ducks/integrations/recharge/selectors'
import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'
import { capture } from 'ducks/tracking/actions'
import { selectCustomFieldCategoryForKey } from 'ducks/crm/customFieldCategories/selectors'
import { selectCurrentContactId } from 'ducks/crm/contacts'
import {
  defaultCustomFieldsOrder,
  MISSING_VALUE,
} from 'ducks/integrations/recharge/contants'

import ReorderableAndHideableComponents from 'components/ReorderableAndHideableComponents'
import IntegrationCustomField from 'components/crm/ContactDetailsColumn/WidgetCards/cards/CustomFieldCategory/IntegrationCustomField'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import Subscriptions from './Subscriptions/List'

const NoCustomer = () => {
  return (
    <div className="grui p-7">
      <EmptyMessage>There is no customer information</EmptyMessage>
    </div>
  )
}

const Error = () => {
  return (
    <div className="grui p-7">
      <EmptyMessage>There was an error fetching Recharge data</EmptyMessage>
    </div>
  )
}

const Fields = ({ integrationId, fields }) => {
  const { id } = fields

  const integration = useSelector(state => {
    return selectIntegrationCredentialsForId(state, {
      id: integrationId,
    })
  })

  const keyPrefix = `recharge_${integration.storeDomain}`
  const customFieldCategory = useSelector(state =>
    selectCustomFieldCategoryForKey(state, {
      key: keyPrefix,
    })
  )
  const contactId = useSelector(state => selectCurrentContactId(state))
  const initialOrder = defaultCustomFieldsOrder(keyPrefix)

  const handleOnShowToggle = useCallback(shown => {
    if (shown) {
      capture('Recharge Customer Expanded')
    } else {
      capture('Recharge Customer Collapsed')
    }
  }, [])

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Recharge Customer Link Clicked')
    return true
  }, [])

  const customerUrl = getRechargeMerchantUrl(
    integration.fullStoreDomain,
    `merchant/customers/${id}`
  )

  const componentOverides = useMemo(
    () => ({
      [`${keyPrefix}_id`]: {
        title: 'ID',
        type: 'LINK',
        value: {
          link: customerUrl,
          text: id,
          onClick: onViewExternalLinkClicked,
        },
      },
    }),
    [keyPrefix, customerUrl, id, onViewExternalLinkClicked]
  )

  return (
    <Fragment>
      <div className="grui p-7">
        <ReorderableAndHideableComponents
          className="SortableCustomFieldsContainer"
          collection={`category:${customFieldCategory?.id}`}
          initialOrder={initialOrder}
          // eslint-disable-next-line react/jsx-no-bind
          itemComponent={props => (
            <IntegrationCustomField
              {...props}
              componentOverides={componentOverides}
              missingValueText={MISSING_VALUE}
            />
          )}
          itemProps={{
            categoryType: 'CONTACT',
            subjectId: contactId,
          }}
          itemNoun="field"
          namespace="contact_details_column"
          onToggle={handleOnShowToggle}
        />
      </div>

      <Subscriptions integrationId={integrationId} customerId={id} />
    </Fragment>
  )
}

export default function CustomerDetails({ integrationId, fields }) {
  const { id } = fields
  const isSubscriptionsError = useSelector(state =>
    selectSubscriptionsIsError(state, id)
  )
  const isCustomerError = useSelector(state =>
    selectCustomerIsError(state, integrationId, { customerId: id })
  )

  if (!id) {
    return <NoCustomer />
  }

  if (isCustomerError || isSubscriptionsError) {
    return <Error />
  }

  return <Fields fields={fields} integrationId={integrationId} />
}
