import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_RULES_UPDATE } from 'ducks/drawers/types'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { styles } from './styles'

const RuleLabel = ({ ruleId, children }) => {
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_RULES_UPDATE
  )

  const handleEdit = useCallback(
    () => {
      if (ruleId) openCreateEditDrawer(ruleId)
    },
    [openCreateEditDrawer, ruleId]
  )

  const label = children ? (
    <Tooltip title={children} strategy="fixed" position="top">
      <span className="grui inline-block">
        Rule &quot;
        <span
          className="grui inline-block align-top truncate"
          css={styles.ruleLabel}
        >
          {children}
        </span>{' '}
        &quot;
      </span>
    </Tooltip>
  ) : (
    'Rule'
  )

  if (!isManager) {
    return label
  }

  return (
    <button className="grui" css={button.styles.link} onClick={handleEdit}>
      {label}
    </button>
  )
}

export default RuleLabel
