import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { connect } from 'react-redux'

import { selectIsFetchingFlags, selectFlag } from 'ducks/flags/selectors'
import { doSetFlag } from 'ducks/flags/operations'

import styled from '@emotion/styled'
import { SUI, Icon } from 'shared/ui'
import storage from 'util/storage'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { isFunction } from 'util/functions'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'

const NoticeBarContainer = styled('div')`
  position: relative;
  width: 100%;
  padding: ${props => props.theme.spacing.base};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  color: ${props => props.theme.color.monochrome.white};
  ${props =>
    props.type === 'warning' &&
    `color: ${props.theme.color.monochrome.black};`};
  text-align: center;
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.semibold};
  background-color: ${props => props.theme.color.brand['900']};
  ${props =>
    props.type === 'warning' &&
    `background-color: ${props.theme.color.primary.warning};`};
  min-height: 48px;
`

const DismissIcon = styled(Icon)`
  font-size: ${props => props.theme.spacing['10']};
  color: rgba(${props => props.theme.color.monochrome.white_rgba}, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  transition: all 0.175s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: ${props => props.theme.spacing['3']};
  width: ${props => props.theme.spacing['12']};
  height: ${props => props.theme.spacing['12']};
  background-color: rgba(
    ${props => props.theme.color.monochrome.white_rgba},
    0.32
  );
  ${props =>
    props.type === 'warning' &&
    `background-color: rgba(${
      props.theme.color.monochrome.black_rgba
    }, 0.25);`};

  &:hover {
    background-color: rgba(
      ${props => props.theme.color.monochrome.black_rgba},
      0.25
    );
    color: ${props => props.theme.color.monochrome.white};
  }
`

const noticeFlagTextStyle = () => theme => css`
  margin-right: 8px;
  background-color: ${theme.color.secondary.positive};
  color: ${theme.color.primary.positive};
  border-radius: 2px;
  padding: 1px 6px;
  font-size: ${theme.fontSize.base};
  user-select: none;
`

class NoticeBar extends React.PureComponent {
  // eslint-disable-next-line react/sort-comp
  state = {
    isVisible: false,
  }

  constructor(props) {
    super(props)
    this.possiblyAutomaticallyShow(props)
  }

  componentDidMount() {
    this.mounted = true
    this.possiblyAutomaticallyShow(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.possiblyAutomaticallyShow(nextProps)
  }

  setIsVisible(isVisible) {
    if (this.mounted) {
      this.setState({ isVisible })
    } else {
      this.state.isVisible = isVisible // eslint-disable-line react/no-direct-mutation-state
    }
  }

  handleFlagAsSeen(flagName, isSessionFlag, flagHasSeen) {
    if (isSessionFlag) {
      storage.set(flagName, true)
      return
    }

    flagHasSeen(flagName)
  }

  hasSeenFlag(flagName, isSessionFlag, hasSeenFromAPI) {
    if (isSessionFlag) return !!storage.get(flagName)

    return hasSeenFromAPI
  }

  possiblyAutomaticallyShow(props) {
    const {
      dismissible,
      flagName,
      isFetchingFlags,
      hasSeenFromAPI,
      flagHasSeen,
      forceShow,
      isSessionFlag,
      autoFlagSeen,
    } = props

    // not using flags, show it
    if (isNullOrUndefined(flagName) || forceShow) {
      this.setIsVisible(true)
    }

    const hasSeen = this.hasSeenFlag(flagName, isSessionFlag, hasSeenFromAPI)

    if (!isFetchingFlags && hasSeen === false) {
      // mark as seen only if its not a dismissible (one-off) notice
      // otherwise will be handled on dismiss
      if (!dismissible && autoFlagSeen) {
        this.handleFlagAsSeen(flagName, isSessionFlag, flagHasSeen)
      }
      this.setIsVisible(true)
    }
  }

  handleDismiss = () => {
    const { flagName, flagHasSeen, isSessionFlag, onDismiss } = this.props
    if (isFunction(onDismiss)) {
      onDismiss()
    } else {
      this.handleFlagAsSeen(flagName, isSessionFlag, flagHasSeen)
    }
    this.setIsVisible(false)
  }

  render() {
    const {
      dismissible,
      hasSeenFromAPI,
      children,
      forceShow,
      noticeFlagText,
      isSessionFlag,
      flagName,
      type,
      onDismiss,
      ...rest
    } = this.props
    const { isVisible } = this.state

    const hasSeen = this.hasSeenFlag(flagName, isSessionFlag, hasSeenFromAPI)

    if ((hasSeen && !forceShow) || !isVisible) {
      return null
    }

    return (
      <SUI>
        <NoticeBarContainer type={type} {...rest}>
          <SpacedItems.Container
            horizontalAlign="center"
            verticalAlign="center"
            css={css`
              width: 100%;
              flex-grow: 1;
            `}
          >
            <SpacedItems.Item grow={1} shink={0}>
              {noticeFlagText && (
                <span css={noticeFlagTextStyle}>{noticeFlagText}</span>
              )}
              {children}
            </SpacedItems.Item>

            {dismissible && (
              <SpacedItems.Item grow={0} shrink={0}>
                <DismissIcon
                  type={type}
                  name="close"
                  onClick={this.handleDismiss}
                />
              </SpacedItems.Item>
            )}
          </SpacedItems.Container>
        </NoticeBarContainer>
      </SUI>
    )
  }
}

NoticeBar.propTypes = {
  isSessionFlag: PropTypes.bool,
  bgColor: PropTypes.string,
  autoFlagSeen: PropTypes.bool,
}

NoticeBar.defaultProps = {
  isSessionFlag: false,
  bgColor: undefined,
  autoFlagSeen: true,
}

const select = (initialState, initialOwnProps) => {
  return state => {
    return {
      hasSeenFromAPI: initialOwnProps.flagName
        ? selectFlag(state)(initialOwnProps.flagName)
        : undefined,
      isFetchingFlags: selectIsFetchingFlags(state),
    }
  }
}

const perform = dispatch => {
  return {
    flagHasSeen: flagName => dispatch(doSetFlag(flagName)),
  }
}

export default connect(select, perform)(NoticeBar)
