import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import { selectIntegrationCredentialsAreLoaded } from 'ducks/integrations/selectors'
import {
  doFetchJiraServerIssueSearch,
  doLinkJiraServerIssueToTicket,
} from 'ducks/integrations/jiraServer'
import {
  selectJiraSearchedIssuesAsOptions,
  selectJiraSearchIssuesAreLoading,
} from 'ducks/integrations/jiraShared'

import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'

import LinkExistingJiraCloudIssueModal from './view'

function mapStateToProps(state) {
  return {
    areCredentialsLoaded: selectIntegrationCredentialsAreLoaded(state),
    areIssuesLoading: selectJiraSearchIssuesAreLoading(state, {
      source: 'jiraServer',
    }),
    issues: selectJiraSearchedIssuesAsOptions(state, { source: 'jiraServer' }),
  }
}

const mapDispatchToProps = {
  doFetchJiraServerIssueSearch,
  doLinkJiraServerIssueToTicket,
}

export default compose(
  withOpenState,
  withProps({ initialValues: { repositoryOwner: null } }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkExistingJiraCloudIssueModal)
