import React, { useCallback } from 'react'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { shopifyMoneyString } from 'util/currency'
import { capture } from 'ducks/tracking/actions'
import { shopifyStoreAdminUrl } from 'ducks/integrations/shopify/utils'

const OrderLineItem = ({ lineItem, integration }) => {
  const storeDomain = integration.storeDomain
  const {
    product,
    quantity,
    discountedTotalSet,
    discountedUnitPriceSet,
    originalUnitPriceSet,
  } = lineItem

  let url
  if (product) {
    const productId = product.id.replace(/[^\d]*/, '')
    url = `${shopifyStoreAdminUrl(storeDomain)}/products/${productId}`
  }

  const hasDiscount =
    originalUnitPriceSet.shopMoney.amount !==
    discountedUnitPriceSet.shopMoney.amount

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Shopify Product Link Clicked')
    return true
  }, [])

  return (
    <tbody className="shopifyV2-order-table-lineItem">
      <tr>
        <th colSpan="2">
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onViewExternalLinkClicked}
            >
              {lineItem.name}
              <ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          )}
          {!url && lineItem.name}
        </th>
      </tr>
      <tr className="shopifyV2-currency">
        <th scope="row" className="shopifyV2-order-table-itemText">
          <span
            css={[
              mainStyles.moneyString,
              hasDiscount && mainStyles.lineThrough,
            ]}
          >
            {shopifyMoneyString(originalUnitPriceSet.shopMoney)}
          </span>
          {hasDiscount && (
            <span css={mainStyles.moneyString}>
              &nbsp;
              {shopifyMoneyString(discountedUnitPriceSet.shopMoney)}
            </span>
          )}
          &nbsp;&times;&nbsp; {quantity}
        </th>
        <td className="grui text-right" css={mainStyles.moneyString}>
          {shopifyMoneyString(discountedTotalSet.shopMoney)}
        </td>
      </tr>
    </tbody>
  )
}

export default OrderLineItem
