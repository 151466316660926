import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  doUpdateTrelloCard,
  doUnlinkTrelloCard,
} from 'ducks/integrations/trello'
import { useAction } from 'util/hooks'

export function useTrelloCardActions(card, doCloseCardView) {
  const { id, closed, errored, title, url } = card || {}
  const dispatch = useDispatch()
  const onUnlink = useAction(doUnlinkTrelloCard)
  const onArchive = useCallback(
    () => dispatch(doUpdateTrelloCard(id, { closed: true })),
    [dispatch, id]
  )
  const onSendToBoard = useCallback(
    () => dispatch(doUpdateTrelloCard(id, { closed: false })),
    [dispatch, id]
  )
  return useMemo(
    () => {
      if (!id) return []
      return [
        {
          action: () => {
            onUnlink(id, title, url)
            doCloseCardView()
          },
          text: 'Unlink',
        },
        !errored && {
          action: closed ? onSendToBoard : onArchive,
          text: closed ? 'Send to board' : 'Archive',
        },
        {
          blankTarget: true,
          text: ['Open in Trello'],
          url,
        },
      ]
    },
    [
      closed,
      doCloseCardView,
      errored,
      id,
      onArchive,
      onSendToBoard,
      onUnlink,
      title,
      url,
    ]
  )
}
