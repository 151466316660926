import { useCallback, memo } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomFieldValueForCustomFieldKeyAndCategoryType } from 'ducks/crm/contacts'
import {
  DURATION,
  IP_LOCATION,
  SINGLE_LINE,
  NUMBER,
  URL,
} from 'ducks/crm/customFields'
import { getValueByPath } from 'util/objects'

import CustomFieldKeyValue from './CustomFieldKeyValue'

export default memo(CustomFieldNested)

const SINGLE_LINES = [DURATION, IP_LOCATION, SINGLE_LINE, NUMBER, URL]

function CustomFieldNested({
  categoryType,
  customFieldKey,
  displayAs,
  icon,
  isVisible,
  isInEditMode,
  itemKey,
  onHideItem,
  onShowItem,
  title = customFieldKey,
  nestedKey,
}) {
  const customField = useSelector(state =>
    selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
      customFieldKey,
      categoryType,
    })
  )
  const onEye = useCallback(
    () => {
      if (isVisible) {
        onHideItem(itemKey)
      } else {
        onShowItem(itemKey)
      }
    },
    [onHideItem, itemKey, isVisible, onShowItem]
  )
  const { value } = customField || {}
  const nestedValue = nestedKey ? getValueByPath(nestedKey, value) : value
  const singlelineField = SINGLE_LINES.includes(displayAs)
  const singleline = !value || singlelineField
  return (
    <CustomFieldKeyValue
      fieldHidden={!isVisible}
      fieldKey={customFieldKey}
      isEditable={false}
      icon={icon}
      isInEditMode={isInEditMode}
      onEye={onEye}
      singleline={singleline}
      title={title || customFieldKey}
      type={displayAs}
      value={nestedValue}
    />
  )
}
