import React from 'react'
import styled from '@emotion/styled'
import Screen from 'components/Screen'
import { TextLoader } from 'shared/ui'
import styles from './styles.css'
import Header from './Header'

const ScrollableScreen = styled(Screen)`
  overflow: auto;
  height: 100%;
`

const RawEmailPage = ({
  headers,
  plain,
  html,
  retrieved,
  fetching,
  className,
}) => (
  <ScrollableScreen className={className}>
    <Header />
    <div className={styles.container}>
      {headers && (
        <div>
          <div className={styles.bodyType}>Headers:</div>
          <div className={styles.body}>
            {Object.keys(headers)
              .map(header => `${header}: ${headers[header]}`)
              .join(`\n`)}
          </div>
        </div>
      )}
      {fetching && <TextLoader fullScreen />}
      {!fetching &&
        retrieved && (
          <div>
            <div className={styles.bodyType}>Plain Text:</div>
            <div className={styles.body}>{plain}</div>

            <div className={styles.bodyType}>HTML:</div>
            <div className={styles.body}>{html}</div>
          </div>
        )}
      {!fetching && !retrieved && <div>No raw email found</div>}
    </div>
  </ScrollableScreen>
)

export default RawEmailPage
