import styled from '@emotion/styled'

import githubIcon from 'assets/integrations/github/icon-256.png'
import jiraCloudIcon from 'assets/integrations/jira-cloud/icon-256.png'
import jiraServerIcon from 'assets/integrations/jira-server/icon-256.png'
import trelloIcon from 'assets/integrations/trello/icon-96.png'
import hubspotIcon from 'assets/integrations/hubspot/icon-24.png'
import salesforceIcon from 'assets/integrations/salesforce/icon-24.png'
import shopifyIcon from 'assets/integrations/shopify.png'
import rechargeIcon from 'assets/integrations/recharge.png'
import Tooltip from 'shared/ui/Tooltip'
import themeVars from 'ui_theme/site/globals/site.variables'

const IMAGES = {
  atlassian_oauth2: jiraCloudIcon,
  github: githubIcon,
  hubspot: hubspotIcon,
  jira_cloud: jiraCloudIcon,
  jira_server: jiraServerIcon,
  trello: trelloIcon,
  salesforce: salesforceIcon,
  shopify: shopifyIcon,
  shopify_v2: shopifyIcon,
  recharge: rechargeIcon,
}

const TOOLTIPS = {
  atlassian_oauth2: 'Jira Cloud',
  github: 'GitHub',
  hubspot: 'HubSpot',
  jira_cloud: 'Jira Cloud',
  jira_server: 'Jira Server',
  salesforce: 'Salesforce',
  shopify: 'Shopify',
  shopify_v2: 'Shopify',
  stripe: 'Stripe',
  trello: 'Trello',
  recharge: 'Recharge',
}

export default styled(ProviderIcon)`
  height: ${props => props.theme.spacing['11']};
  margin-left: ${props => props.theme.spacing['3']};
  margin-right: ${props => props.theme.spacing['5']};
  vertical-align: bottom;
  border-radius: ${themeVars.defaultBorderRadius};
`

function ProviderIcon({ className, provider }) {
  const src = IMAGES[provider]
  if (src) {
    return (
      <Tooltip as="span" tooltip={TOOLTIPS[provider]}>
        <img className={className} alt={`${provider} logo`} src={src} />
      </Tooltip>
    )
  }
  return null
}
