import { useMemo } from 'react'
import cn from 'classnames'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { styles } from './styles'
import oldStyles from './styles.css'
import SearchItem from './SearchItem'

export default function SearchFor({
  onSubmit,
  searchQuery,
  currentSubmittedTicketSearchFilterMatches,
  className,
}) {
  const searchQueryWithSubmittedFilters = useMemo(
    () => [...currentSubmittedTicketSearchFilterMatches, searchQuery].join(' '),
    [currentSubmittedTicketSearchFilterMatches, searchQuery]
  )

  return (
    <KeyboardNavigator.Item
      active
      activeClassName={oldStyles.SearchSuggestions_activeSubmitSuggestion}
      onSelect={onSubmit}
      className={className}
    >
      <div
        className={cn(
          oldStyles.SearchSuggestions_suggestion,
          oldStyles.SearchSuggestions_submitSuggestion,
          oldStyles.SearchSuggestions_ready
        )}
      >
        <div
          className={cn(
            oldStyles.hint,
            oldStyles.SearchSuggestions_suggestionHint
          )}
        >
          Show all results for
        </div>
        <div
          className={cn(
            oldStyles.suggestion,
            oldStyles.SearchSuggestions_suggestionQuery,
            'grui truncate'
          )}
          css={styles.innerList}
        >
          <SearchItem query={searchQueryWithSubmittedFilters} />
        </div>
      </div>
    </KeyboardNavigator.Item>
  )
}
