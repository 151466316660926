import Link from 'redux-first-router-link'

export default function ActionSubject({
  action: {
    change: {
      action: {
        action,
        changes: {
          new_issue: { html_url: newHtmlUrl, title: newTitle } = {},
          title: { from: oldTitle } = {},
        } = {},
        issue: { html_url: htmlUrl, title },
      },
    },
  },
}) {
  switch (action) {
    case 'assigned':
    case 'closed':
    case 'created':
    case 'deleted':
    case 'demilestoned':
    case 'edited':
    case 'labeled':
    case 'locked':
    case 'milestoned':
    case 'pinned':
    case 'reopened':
    case 'unassigned':
    case 'unlabeled':
    case 'unlocked':
    case 'unpinned':
      return (
        <Link rel="noopener noreferrer" target="_blank" to={htmlUrl}>
          {oldTitle || title}
        </Link>
      )
    case 'transferred':
      return (
        <Link rel="noopener noreferrer" target="_blank" to={newHtmlUrl}>
          {newTitle}
        </Link>
      )
    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
