import { connect } from 'react-redux'
import { doToggleSearchModifier } from 'actions/search_modifier'
import { selectIsSearchModifierOpen } from 'selectors/search_modifier'
import SearchModifier from './view'

const select = state => ({
  isOpen: selectIsSearchModifierOpen(state),
})
const perform = dispatch => ({
  closeModal: () => dispatch(doToggleSearchModifier(false)),
})

export default connect(select, perform)(SearchModifier)
