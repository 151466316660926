import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import {
  ADDRESS,
  DATE,
  DROPDOWN,
  DURATION,
  IP_LOCATION,
  LINK,
  SOURCE_AND_MEDIUM,
  MULTI_LINE,
  NUMBER,
  SINGLE_LINE,
  TIMEZONE,
  URL,
  BOOLEAN,
} from 'ducks/crm/customFields'
import { Tooltip } from 'shared/ui'
import { any } from 'util/arrays'

import CustomFieldValueLink from './CustomFieldValueLink'

function formatDate(date) {
  const asDate = new Date(date)
  const displayYear = asDate.getFullYear() !== new Date().getFullYear()
  return moment(date).format(`D MMM${displayYear ? " 'YY" : ''}`)
}

export default function CustomFieldValue({
  fieldKey,
  isInEditMode,
  options,
  singleline,
  type = SINGLE_LINE,
  value,
}) {
  if (typeof type === 'function') {
    return type(value)
  } else if (typeof type !== 'string') {
    const CustomFieldType = type
    return <CustomFieldType {...value} />
  }
  let valueRendered
  if (value) {
    const {
      city,
      content: objectContent,
      country,
      domain,
      hash,
      link,
      medium,
      path,
      postalCode,
      query,
      state,
      street,
      street2,
      source,
      subdivisions,
      raw,
      text,
      onClick,
    } = value
    const content = typeof value === 'string' ? value : objectContent
    const contentWithoutAt =
      content && content.replace && content.replace(/^@/, '')
    switch (type) {
      case ADDRESS:
        if (any(x => x && x !== '', Object.values(value))) {
          valueRendered = (
            <div className="address">
              {street && <div className="singleline">{street}</div>}
              {street2 && <div className="singleline">{street2}</div>}
              {city && <div className="singleline">{city}</div>}
              {state && <div className="singleline">{state}</div>}
              {postalCode && <div className="singleline">{postalCode}</div>}
              {country && <div className="singleline">{country}</div>}
            </div>
          )
        }
        break
      case DATE:
        valueRendered = value.content && formatDate(value.content)
        break
      case DROPDOWN:
        valueRendered =
          (
            options?.find(
              ({ value: optionValue }) => content === optionValue
            ) || {}
          ).text || content
        break
      case DURATION:
        if (content) {
          valueRendered = moment
            .duration(parseFloat(content), 'seconds')
            .format(
              'y [years], M [months], d [days], h [hour], m [minutes], s [seconds]',
              { largest: 1 }
            )
        }
        break
      case IP_LOCATION:
        if (city || subdivisions || country) {
          valueRendered = [city, subdivisions, country]
            .filter(x => !!x)
            .join(', ')
        }
        break
      case LINK:
        if (link) {
          const linkWithProtocol = link.match(/^https?:\/\//)
            ? link
            : `https://${link}`
          valueRendered = (
            <CustomFieldValueLink href={linkWithProtocol} onClick={onClick}>
              {text || link}
            </CustomFieldValueLink>
          )
        } else if (text) valueRendered = text
        break
      case SINGLE_LINE:
      case TIMEZONE:
        if (content && content !== '') {
          switch (fieldKey) {
            case 'contact_facebook':
            case 'company_facebook':
              valueRendered = (
                <CustomFieldValueLink
                  href={`https://www.facebook.com/${content}`}
                >
                  {content}
                </CustomFieldValueLink>
              )
              break
            case 'contact_twitter':
            case 'company_twitter':
              valueRendered = (
                <CustomFieldValueLink
                  href={`https://www.twitter.com/${contentWithoutAt}`}
                >
                  @{contentWithoutAt}
                </CustomFieldValueLink>
              )
              break
            case 'contact_linkedin':
            case 'company_linkedin':
              valueRendered = (
                <CustomFieldValueLink
                  href={`https://www.linkedin.com/in/${content}/`}
                >
                  {content}
                </CustomFieldValueLink>
              )
              break
            default:
              valueRendered = content
              break
          }
        }
        break
      case NUMBER:
        if (content && content !== '') {
          valueRendered = Number(content).toLocaleString()
        }
        break
      case SOURCE_AND_MEDIUM:
        if (source || medium) {
          valueRendered = `${source}${source && medium ? '/' : ''}${medium}`
        }
        break
      case MULTI_LINE:
        if (content && content !== '') {
          const lines = content.split(/[\r\n]+/)
          valueRendered = [].concat(
            ...lines.map((line, i) => [
              line,
              // eslint-disable-next-line react/no-array-index-key
              i + 1 === lines.length ? null : <br key={i} />,
            ])
          )
        }
        break
      case URL:
        valueRendered = (
          <a href={raw} rel="noopener noreferrer" target="_blank">
            {`${domain}${path}${query ? `?${query}` : ''}${
              hash ? `#${hash}` : ''
            }`}
          </a>
        )
        break
      case BOOLEAN:
        valueRendered = content
        break
      default:
      // does nothing
    }
  }

  if (valueRendered)
    return (
      <Tooltip
        className={cn('value', { clickable: !isInEditMode, singleline })}
        tooltipClassName="value"
        position="left"
        portal
        tooltip={valueRendered}
      >
        {valueRendered}
      </Tooltip>
    )
  return null
}
