import React from 'react'
import cn from 'classnames'
import { stopEventAndRun } from 'util/keys'
import styles from './styles.less'

export default function Card(props) {
  const {
    className,
    image,
    title: TitleComponent,
    children,
    isLoading,
    isCardOpen,
    doChangeOpenState,
    onReload,
  } = props
  const titleString = typeof TitleComponent === 'string' ? TitleComponent : ''
  return (
    <div className={styles.inappcard}>
      <div
        className="title"
        onClick={isLoading ? null : () => doChangeOpenState(!isCardOpen)}
      >
        {image && <img className="title" alt={titleString} src={image} />}
        {TitleComponent && (
          <div className={'title-text'}>
            {typeof TitleComponent === 'string' ? (
              TitleComponent
            ) : (
              <TitleComponent {...props} />
            )}
          </div>
        )}
        {isLoading && <div>Loading</div>}
        {!isLoading && (
          <div className="icons">
            {onReload && (
              <span
                className="Icon Icon-refresh"
                onClick={stopEventAndRun(onReload)}
              />
            )}
            <i
              className={cn('chevron small icon', {
                down: isCardOpen,
                right: !isCardOpen,
              })}
            />
          </div>
        )}
      </div>
      {children &&
        isCardOpen && (
          <div className={cn('content', className)}>{children}</div>
        )}
    </div>
  )
}
