import { connect } from 'react-redux'
import {
  selectIntegrationCredentialsForId,
  selectCanDisplayForCurrentConversation,
} from 'ducks/integrations/selectors'
import withCardDataLoading from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/withCardDataLoading'

import View from './view'

const selectFactory = (initialState, initialOwnProps) => {
  const { id: cardId } = initialOwnProps
  const openStateKey = `recharge-${cardId}`

  return state => {
    return {
      key: openStateKey,
      openStateKey,
      integration: selectIntegrationCredentialsForId(state, {
        id: cardId,
      }),
      showCard: selectCanDisplayForCurrentConversation(state, cardId),
    }
  }
}

export default connect(selectFactory, null)(
  withCardDataLoading(View, { useInAppCardView: false, canReload: true })
)
