import React from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import { shopifyMoneyString } from 'util/currency'

import AddShipping from '../AddShipping'

const ShippingSection = React.memo(
  ({
    currency,
    shipping,
    handleShippingChange,
    calculatedDraft,
    valueClassName,
    disabled,
    hasShippableItems,
    integrationId,
    customerId,
  }) => {
    const { isCalculating, isSending } = useShopifyDraft({
      integrationId,
      customerId,
    })

    const noShippingAmount = [null, undefined].includes(shipping?.fee)

    return (
      <>
        <Tooltip
          title="Shipping can't be added because there are no shippable items."
          disabled={hasShippableItems}
        >
          <div>
            <AddShipping
              currency={currency}
              shipping={shipping}
              onShippingChange={handleShippingChange}
              calculatedDraft={calculatedDraft}
              disabled={
                disabled || !hasShippableItems || isCalculating || isSending
              }
            >
              <button css={button.styles.link} type="button">
                {noShippingAmount ? 'Add' : 'Edit'} shipping
              </button>
            </AddShipping>
          </div>
        </Tooltip>
        <span className={valueClassName}>
          {noShippingAmount && <>&mdash;</>}
          {!noShippingAmount && (
            <span css={mainStyles.moneyString}>
              {shopifyMoneyString({
                currencyCode: currency,
                amount: shipping.fee,
              })}
            </span>
          )}
        </span>
      </>
    )
  }
)

export default ShippingSection
