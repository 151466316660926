import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_EMAIL_FORWARD } from 'ducks/drawers/types'
import { selectMailboxByStates } from 'selectors/mailboxes/selectMailboxByStates'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { styles } from './styles'

const MailboxContinueForwardingSetupBar = () => {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const confirmedUnconfirmedMailboxes = useSelector(state =>
    selectMailboxByStates(state, ['unconfirmed', 'confirmed'])
  )
  const firstMailbox = confirmedUnconfirmedMailboxes[0] || {}
  const currentMailboxId = ticketListMailboxId || firstMailbox.id
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
    lazyLoad: true,
  })

  const onOpen = useCallback(
    () => {
      openAddDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', 'forwarding'),
        },
      })
    },
    [openAddDrawer, currentMailboxId, drawerId]
  )

  return (
    <div css={styles.container} className="grui px-12 py-7 mx-12 mt-5 mb-10">
      <div className="grui mr-14 flex-grow">
        <h5 css={[heading.styles.h5, styles.heading]} className="grui mb-3">
          Complete your {app.t('mailbox')} setup
        </h5>
        <p className="grui" css={styles.content}>
          {`You’re just one step away from receiving emails! Complete your ${app.t(
            'mailbox'
          )} forwarding setup, and start receiving messages now!`}
        </p>
      </div>
      <Button type="info" size="xBig" css={styles.btn} onClick={onOpen}>
        Complete setup
      </Button>
    </div>
  )
}

export default MailboxContinueForwardingSetupBar
