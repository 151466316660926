import { useCallback, useEffect } from 'react'
import { COMMAND_PRIORITY_EDITOR } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { strftime } from 'util/date'
import { RANGE_SEPARATOR } from 'util/search/constants'
import { exitCurrentEditorNode } from '../util'

export const DATE_QUERY_COMMAND = 'DATE_QUERY_COMMAND'

const DatePlugin = ({ onToggleDatePicker, currentDateNodeRef }) => {
  const [editor] = useLexicalComposerContext()

  const handleUpdateCurrentDateFilter = useCallback(
    ({ value } = {}) => {
      const dateNode = currentDateNodeRef.current
      const filterName = dateNode.getTextContent().split(':')[0]
      const format = '%Y-%m-%d'
      if (value?.startDate) {
        const startDateString = strftime(format, value.startDate)
        const endDateString = strftime(format, value.endDate || value.startDate)
        dateNode.setTextContent(
          `${filterName}:"${startDateString}${RANGE_SEPARATOR}${endDateString}"`
        )
      } else if (value) {
        const dateString = strftime(format, value.date)
        const dateTimeString = [dateString, value.time]
          .filter(Boolean)
          .join(' ')
        dateNode.setTextContent(`${filterName}:"${dateTimeString}"`)
      }
      onToggleDatePicker(false)
      exitCurrentEditorNode(dateNode)
    },
    [currentDateNodeRef, onToggleDatePicker]
  )

  useEffect(
    () => {
      return editor.registerCommand(
        DATE_QUERY_COMMAND,
        handleUpdateCurrentDateFilter,
        COMMAND_PRIORITY_EDITOR
      )
    },
    [editor, onToggleDatePicker, handleUpdateCurrentDateFilter]
  )

  return null
}

export default DatePlugin
