import React, { useCallback, useMemo, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { list } from '@groovehq/internal-design-system/lib/styles/elements'
import { useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { currencyCodeToSymbol } from 'util/currency'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'

import { styles } from './styles'
import Plus from './Plus'

// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  displayName: yup.string().required('Item name is required'),
  price: yup.number().required('Price is required'),
  quantity: yup.number().required('Quantity is required'),
})

const defaultValues = {
  displayName: '',
  price: null,
  quantity: null,
  taxable: false,
  requiresShipping: false,
}

const CustomItem = ({ currency, onAddItem, onVisibleChange }) => {
  const formId = 'add-custom-item-form'
  const currencySymbol = currencyCodeToSymbol(currency)

  const {
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues,
  })

  const onSubmit = useCallback(
    data => {
      const payload = { ...data, id: uuid(), custom: true }
      onAddItem(payload)
      onVisibleChange(false)
    },
    [onAddItem, onVisibleChange]
  )

  const handleCancel = useCallback(
    () => {
      reset(defaultValues)
      onVisibleChange(false)
    },
    [reset, onVisibleChange]
  )

  const footer = (
    <>
      <Button type="tertiary" size="small" onClick={handleCancel}>
        Cancel
      </Button>
      <Button size="small" disabled={!isValid} htmlType="submit" form={formId}>
        Save line item
      </Button>
    </>
  )

  const overlay = useMemo(
    () => {
      return (
        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
          className="grui px-7 py-6"
          css={styles.overlay}
        >
          <ul className="grui mb-10" css={list.styles.preflight}>
            <li>
              <Field label="Line item name" {...register('displayName')} />
            </li>
            <li>
              <Field
                {...register('price')}
                label="Price per item"
                tagPosition="left"
                tagContent={currencySymbol}
              />
            </li>
            <li>
              <Field {...register('quantity')} label="Quantity" />
            </li>
          </ul>
          <Checkbox
            id="item-taxable"
            className="grui mb-5"
            {...register('taxable')}
          >
            Item is taxable
          </Checkbox>
          <Checkbox id="item-shipping" {...register('requiresShipping')}>
            Item requires shipping
          </Checkbox>
        </form>
      )
    },
    [currencySymbol, register, handleSubmit, onSubmit]
  )

  return (
    <Dropdown
      overlay={overlay}
      footer={footer}
      footerWithBtns
      hasMinWidth
      autoHeight
      visible
      onVisibleChange={onVisibleChange}
    >
      <Button
        type="tertiary"
        size="small"
        className="grui ml-5"
        customIcon={<Plus />}
      >
        Add custom item
      </Button>
    </Dropdown>
  )
}

const CustomItemOuter = props => {
  const { integrationId, customerId } = props
  const { isCalculating, isSending } = useShopifyDraft({
    integrationId,
    customerId,
  })
  const [isFormVisible, setFormVisible] = useState(false)

  const onVisibleChange = useCallback(
    newVal => {
      setFormVisible(newVal)
    },
    [setFormVisible]
  )

  const setVisible = useCallback(
    () => {
      setFormVisible(true)
    },
    [setFormVisible]
  )

  if (isFormVisible) {
    return <CustomItem {...props} onVisibleChange={onVisibleChange} />
  }

  return (
    <Button
      type="tertiary"
      size="small"
      className="grui ml-5"
      customIcon={<Plus />}
      onClick={setVisible}
      disabled={isCalculating || isSending}
    >
      Add custom item
    </Button>
  )
}

export default CustomItemOuter
