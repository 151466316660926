import { css } from '@emotion/react'

export const listGap = 6

const list = theme => css`
  width: 100%;
  gap: ${listGap}px;
  flex-wrap: wrap;
  align-items: center;

  .recipient {
    display: flex;
    margin-right: 4px;
  }

  .addContact:not(:only-child) {
    margin-left: ${theme.spacing['4']};
    opacity: 0;
    transition: opacity 300ms ease;
  }

  :hover .addContact {
    opacity: 1;
  }
`

const addContact = theme => css`
  color: ${theme.color.monochrome.superDark};
  cursor: text;
  /* Prevent showing caret beside the add contact text */
  /* because users may think the field is focused and start typing, then trigger shortcuts */
  user-select: none;
  align-self: stretch;
  flex-grow: 1;
  background: red;
  width: 100px;
  height: 20px;
`

const iconStyle = theme => css`
  cursor: pointer;
  margin-left: ${theme.spacing['3']};
  color: ${theme.color.monochrome.superDark};
  &:hover {
    color: blue;
  }
`

const recipient = theme => css`
  box-shadow: 0 0 0 1px ${theme.color.monochrome.medium};
  border-radius: 2rem;
  padding-left: ${theme.spacing['2']};
  padding-right: ${theme.spacing['6']};
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  line-height: 26px;
  background-color: ${theme.color.monochrome.white};
`

const recipientAvatar = theme => css`
  width: 22px;
  height: 22px;
  font-size: ${theme.fontSize.tiny};
`

const truncated = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const styles = {
  list,
  addContact,
  iconStyle,
  recipient,
  recipientAvatar,
  truncated,
}
