import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_SELECT_EMAIL } from 'ducks/drawers/types'
import demoInboxImage from 'assets/demo-inbox.png'
import { styles } from './styles'

const DemoInboxCard = ({ mini = false }) => {
  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
    lazyLoad: true,
  })

  const onAddClick = useCallback(
    () => {
      openAddDrawer('new', {
        additionalProps: {
          firstStep: true,
          drawerSource: 'inbox',
        },
      })
    },
    [openAddDrawer]
  )

  return (
    <div
      css={[styles.positionBase, mini ? styles.positionMini : styles.position]}
    >
      <div
        css={[
          styles.containerBase,
          mini ? styles.containerMini : styles.container,
        ]}
      >
        <div className="grui flex">
          <div css={mini ? styles.contentMini : styles.content}>
            <h2>Ready to start crushing your support?</h2>
            <p>Setup time of 2 minutes or less</p>
            <Button type="info" size="xBig" onClick={onAddClick}>
              <span aria-hidden="true">📨</span>Add{' '}
              {app.t('mailbox_with_article')}
            </Button>
          </div>
          {!mini && (
            <div className="grui flex flex-grow">
              <img
                src={demoInboxImage}
                aria-hidden="true"
                alt=""
                width="419"
                height="260"
                css={styles.image}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DemoInboxCard
