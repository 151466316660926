import React from 'react'
import cn from 'classnames'

import { SUI } from 'shared/ui'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { propFunc, runOnNextTick } from 'util/functions'
import CollapsedMessage from './CollapsedMessage'
import ExpandedMessage from './ExpandedMessage'
import AIMessage from './AIMessage'

import withMessageScroller from './withMessageScroller'
import styles from './styles.less'

class Changesets extends React.PureComponent {
  static defaultCollapsedItems(props) {
    const { messages } = props
    const collection = messages
    const hash = window.location.hash
    const hashId = hash ? hash.replace(/^#changeset-/, '') : undefined
    const collectionSize = collection.length
    const messagesExcludeNotes = collection.filter(item => !item.isNote)
    const lastMessageIdExcludeNotes =
      messagesExcludeNotes[messagesExcludeNotes.length - 1]?.id

    return collection.reduce((result, item, index) => {
      const id = item.id
      const cleanId = id.replace('-collapsed', '')
      const isHash = hashId && hashId === id
      const isLast = index >= collectionSize - 1
      const expanded =
        isHash ||
        (isLast && !hashId) ||
        (id === lastMessageIdExcludeNotes && !hashId)
      return {
        ...result,
        [id]: !expanded,
        [cleanId]: !expanded,
      }
    }, {})
  }

  constructor(props) {
    super(props)
    this.state = {
      collapsedItems: Changesets.defaultCollapsedItems(props),
    }
  }

  componentDidMount() {
    const { location, messages } = this.props

    this.captureLoadTime()

    const changesetId = location.payload.changesetId
    if (changesetId) {
      this.highlightChangeset(changesetId, messages)
    } else {
      this.props.onLoad() // let scroller do its thing
    }
  }

  // eslint-disable-next-line react/sort-comp
  componentWillReceiveProps(nextProps) {
    const { location } = this.props
    const { location: nextLocation } = nextProps

    if (nextProps.ticket.id !== this.props.ticket.id) {
      this.setState({
        collapsedItems: Changesets.defaultCollapsedItems(nextProps),
      })
    } else {
      const { messages } = nextProps
      if (!messages) return
      this.setState({
        collapsedItems: messages.reduce((result, item) => {
          const id = item.id
          const cleanId = id.replace('-collapsed', '')
          const collapsedId = `${cleanId}-collapsed`
          const existingValue =
            this.state.collapsedItems[id] ||
            this.state.collapsedItems[cleanId] ||
            this.state.collapsedItems[collapsedId]
          const newValue = existingValue === undefined ? false : existingValue
          return {
            ...result,
            [id]: newValue,
            [collapsedId]: newValue,
            [cleanId]: newValue,
          }
        }, {}),
      })
    }

    const { hashId } = location.payload
    const {
      changesetId: nextChangesetId,
      hashId: nextHashId,
    } = nextLocation.payload
    if (nextChangesetId && (hashId !== nextHashId || !hashId)) {
      this.highlightChangeset(nextChangesetId, nextProps.messages)
    }
  }

  componentDidUpdate() {
    this.captureLoadTime()
  }

  setCollapsedAll = value => {
    this.setState({
      collapsedItems: Object.keys(this.state.collapsedItems).reduce(
        (result, id) => {
          return {
            ...result,
            [id]: value,
          }
        },
        {}
      ),
    })
  }

  captureLoadTime() {
    const { messages, ticket } = this.props
    if (messages) {
      app.metrics.stopTimer(`ticket-load-${ticket.id}`, {
        changesetCount: messages.length,
      })
    }
  }

  collapseAll = () => this.setCollapsedAll(true)

  expandAll = () => {
    const { changesets } = this.props
    const candidates = changesets.messages.filter(({ loaded }) => !loaded)

    candidates.map(this.expandChangeset)
    this.setCollapsedAll(false)
  }

  expandChangeset = message => {
    const { ticket, doFetchChangesetTicketActions } = this.props
    if (message.loaded) return null
    const action = message.action
    const change = action.change
    if (!change) return null

    return doFetchChangesetTicketActions(
      ticket.id,
      message.id,
      change.subchangeset_ids
    )
  }

  handleOnLoading = () => {
    const { scrollerApiRef } = this.props

    if (scrollerApiRef) {
      const { scrollToBottom } = scrollerApiRef
      scrollToBottom()
    }
  }

  toggleItemCollapsed = id => {
    const hasUserSelection = window.getSelection().toString() !== ''

    if (hasUserSelection) return
    const cleanId = id.replace('-collapsed', '')
    const collapsedId = `${cleanId}-collapsed`

    const existingValue =
      this.state.collapsedItems[id] ||
      this.state.collapsedItems[cleanId] ||
      this.state.collapsedItems[collapsedId]
    const newValue = !existingValue

    this.setState({
      collapsedItems: {
        ...this.state.collapsedItems,
        [id]: newValue,
        [collapsedId]: newValue,
        [cleanId]: newValue,
      },
    })
  }

  highlightChangeset(changesetId, messages) {
    const message = messages.find(m => m.id === changesetId)

    if (!message) {
      return
    }

    if (message.loaded) {
      if (this.state.collapsedItems[message.id]) {
        runOnNextTick(() => this.toggleItemCollapsed(message.id))
      }
    } else {
      this.expandChangeset(message)
      runOnNextTick(() => this.toggleItemCollapsed(message.id))
    }

    runOnNextTick(() => this.props.onScrollToChangeset(message.id))
  }

  render() {
    const {
      ticket,
      isEditingTicketTitle,
      doUpdateTitle,
      messages,
      onOpenImage,
      isPrintModeOn,
      is3ColumnView,
      scrollerApiRef,
    } = this.props
    if (!messages) return 'empty'
    return (
      <SUI>
        <div
          className={cn(
            styles.changesetsContainer,
            {
              [styles['changesetsContainer-disabled']]: isEditingTicketTitle,
            },
            {
              [styles['changesetsContainer-3-column-view']]: is3ColumnView,
            }
          )}
        >
          <ListenToKeyboard
            onSemicolon={this.expandAll}
            onSemicolonFirefox={this.expandAll}
            onShiftSemicolon={this.collapseAll}
            onShiftSemicolonFirefox={this.collapseAll}
            disableForInput
          />
          {isEditingTicketTitle && (
            <div
              className={styles.changesetsContainer_curtain}
              onClick={propFunc(doUpdateTitle, ticket.id)}
            />
          )}
          {messages.map((message, index) => {
            const id = message.id
            const userCollapsed = this.state.collapsedItems[id]
            let isExpanded = !userCollapsed
            if (isPrintModeOn) isExpanded = true
            // if message is not loaded, no way to expand
            if (!message.loaded) isExpanded = false

            if (isExpanded) {
              return (
                <ExpandedMessage
                  key={id}
                  id={id}
                  ticketId={ticket.id}
                  message={message}
                  expand={this.toggleItemCollapsed}
                  onOpenImage={onOpenImage}
                  isFirstMessage={index === 0}
                />
              )
            }
            return (
              <CollapsedMessage
                key={id}
                id={id}
                ticketId={ticket.id}
                message={message}
                expand={this.toggleItemCollapsed}
              />
            )
          })}
          <AIMessage
            onLoading={scrollerApiRef ? this.handleOnLoading : undefined}
            className="grui m-5"
          />
        </div>
      </SUI>
    )
  }
}

export default withMessageScroller(Changesets)
