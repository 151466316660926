import { connect } from 'react-redux'

import { doToggleRightSidebar } from 'actions/app'
import { selectIsRightSidebarCollapsed } from 'selectors/app'

import View from './view'

const select = state => {
  return {
    isRightSidebarCollapsed: selectIsRightSidebarCollapsed(state),
  }
}

const perform = {
  doToggleRightSidebar,
}

export default connect(select, perform)(View)
