import React from 'react'

import Scroller from 'components/Scroller'
import { Loader, Text } from 'shared/ui'

import { propFunc } from 'util/functions'
import { withResizeChange } from 'util/hoc'
import { stopEventAndRun } from 'util/keys'
import { isChecked } from 'util/label_selection'

import ListItem from './ListItem'

const LabelList = ({
  focusedIndex,
  isFetchingSearchResults,
  labels,
  onItemClick,
  onCheckboxChanged,
  searchTerm,
  mode,
  hasMoreLabels,
  isSearching,
}) => {
  const displayHasMoreLabels = !isSearching && hasMoreLabels

  if (isFetchingSearchResults) return <Loader className="grui my-20" />

  return (
    <div>
      {labels.map((label, index) => (
        <ListItem
          isChecked={isChecked(label)}
          isIndeterminate={label.indeterminate}
          isFocused={index === focusedIndex}
          key={label.id}
          label={label}
          onClick={propFunc(onItemClick, index)}
          onCheckboxClick={stopEventAndRun(propFunc(onCheckboxChanged, label))}
          showCount={Boolean(searchTerm)}
          mode={mode}
          searchTerm={searchTerm}
        />
      ))}

      {displayHasMoreLabels && (
        <div className="grui text-center py-4">
          <Text size="small" fontWeight="500">
            Search to see all tags
          </Text>
        </div>
      )}
    </div>
  )
}

const ScrollingList = props => {
  return (
    <Scroller style={{ maxHeight: '246px' }}>
      <LabelList {...props} />
    </Scroller>
  )
}

const ScrollingListWithResizingChange = withResizeChange(ScrollingList)

export default ScrollingListWithResizingChange
