import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSurvey } from 'ducks/tracking/actions'
import { getTrackingContext } from 'ducks/tracking/context'
import { selectNpsNextTimestamp } from 'ducks/currentUser/selectors/preferences/selectNpsNextTimestamp'
import { selectIsOnOnboardingPage } from 'subapps/onboarding/selectors'
import moment from 'moment'
import selectIsCurrentAppBootstrapped from 'selectors/app/selectIsCurrentAppBootstrapped'
import debug from 'util/debug'
import { selectIsTestAccount } from 'selectors/account'

import NpsForm from './NpsForm'

const NpsSurvey = () => {
  const trackingContext = getTrackingContext()
  const isCurrentAppBootstrapped = useSelector(selectIsCurrentAppBootstrapped)
  const npsNextTimestamp = useSelector(selectNpsNextTimestamp)
  const inOnboarding = useSelector(selectIsOnOnboardingPage)
  const isTestAccount = useSelector(selectIsTestAccount)
  const isShownCaptured = useRef(false)

  const [survey, setSurvey] = useState(null)

  useEffect(
    () => {
      const { initialized } = trackingContext

      if (
        !initialized ||
        !isCurrentAppBootstrapped ||
        inOnboarding ||
        isTestAccount
      ) {
        return
      }

      async function setVisibility() {
        const activeSurvey = await getSurvey('NPS')
        const isAfterPreviousNpsSurvey =
          npsNextTimestamp === null
            ? true
            : moment().isAfter(new Date(npsNextTimestamp * 1000))

        debug(
          `activeSurvey: ${activeSurvey?.id}, isShownCaptured: ${
            isShownCaptured.current
          }, npsNextTimestamp: ${npsNextTimestamp}`
        )

        if (
          activeSurvey &&
          isAfterPreviousNpsSurvey &&
          !isShownCaptured.current
        ) {
          setSurvey(activeSurvey)
        }
      }

      setVisibility()
    },
    [
      trackingContext,
      isCurrentAppBootstrapped,
      inOnboarding,
      npsNextTimestamp,
      isTestAccount,
    ]
  )

  if (!survey) {
    return null
  }

  return (
    <NpsForm
      survey={survey}
      setSurvey={setSurvey}
      isShownCaptured={isShownCaptured}
    />
  )
}

export default NpsSurvey
