import styled from '@emotion/styled'

const AnimatedLeftRight = styled('div')`
  display: flex;
  .index,
  .detail {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    transition: transform ease-in-out ${props => props.duration || 300}ms;
    min-width: 0;
  }
  .detail,
  .detail-enter {
    transform: translateX(calc(100% + 16px));
  }
  .index {
    margin-right: -100%;
  }
  .index-exit,
  .index-enter,
  .index-exit-done {
    transform: translateX(calc(-100% - 16px));
  }
  .index-exit-done,
  .detail {
    display: none;
  }
  .detail-enter,
  .detail-enter-done,
  .detail-exit {
    display: block;
  }
  .index-enter-active,
  .index-enter-done {
    transform: translateX(0);
  }
  .detail-enter-active,
  .detail-enter-done {
    transform: translateX(0);
  }
  .detail-exit-active,
  .detail-exit-done,
  .index-exit-active,
  .index-exit-done {
    height: 0;
  }
`

export default AnimatedLeftRight
