import React from 'react'
import AssignmentDropdown from 'components/App/DesktopView/shared/AssignmentDropdown/Bulk'
import AssignmentBadge from '../'

export default function BulkAssignmentBadge({ assignment, iconSize }) {
  return (
    <AssignmentDropdown
      trigger={<AssignmentBadge iconSize={iconSize} assignment={assignment} />}
      bindHotKey
    />
  )
}
