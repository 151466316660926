import cn from 'classnames'

import * as icons from 'assets/icons/groove/v2'
import {
  DATE,
  DURATION,
  IP_LOCATION,
  SINGLE_LINE,
  LINK,
  NUMBER,
  SUM,
  TYPE_ICONS_MAP,
  URL,
} from 'ducks/crm/customFields'
import { camelize } from 'util/strings'
import { Icon } from 'util/ui'

import withSidebarModalPositioning from '../../withSidebarModalPositioning'
import CustomFieldCopyButton from './CustomFieldCopyButton'
import CustomFieldEditModal from './CustomFieldEditModal'
import CustomFieldValue from './CustomFieldValue'
import useEditableCustomFieldKeyValue from './useEditableCustomFieldKeyValue'

const SINGLE_LINES = [
  DATE,
  DURATION,
  IP_LOCATION,
  SINGLE_LINE,
  LINK,
  NUMBER,
  SUM,
  URL,
]

export default withSidebarModalPositioning(CustomFieldKeyValuesValue)

function CustomFieldKeyValuesValue({
  fieldKey,
  icon: iconKey,
  index,
  isEditable: isEditableProp = true,
  isInEditMode,
  isArray = false,
  offset,
  options,
  menuRef,
  subjectId,
  title,
  type = SINGLE_LINE,
  value,
  values,
}) {
  const {
    isEditable,
    isOpen,
    doClose,
    doOpen,
    doSave,
  } = useEditableCustomFieldKeyValue({
    isEditableProp,
    subjectId,
    type,
  })
  const singlelineField = SINGLE_LINES.includes(type)
  const valueComponent = CustomFieldValue({
    isInEditMode,
    fieldKey,
    type,
    value,
    singleline: singlelineField,
  })
  const singleline = singlelineField || !value

  const missingValue =
    isEditable && !isInEditMode ? (
      <span className="addMissingValue">Add</span>
    ) : (
      '-'
    )
  const icon = icons[camelize(iconKey) || TYPE_ICONS_MAP[type] || 'edit']
  return (
    <>
      <div
        className={cn('groupedValue', {
          singleline,
          missingValue: !valueComponent,
        })}
        onClick={!isInEditMode ? doOpen : null}
      >
        <div
          className={cn('value', {
            editable: isEditable && !isInEditMode,
            missing: !valueComponent,
          })}
        >
          {valueComponent || missingValue}
        </div>
        {!isInEditMode && (
          <CustomFieldCopyButton
            fieldKey={fieldKey}
            type={type}
            value={value}
          />
        )}
        {isEditable &&
          !isInEditMode && (
            <div className="action">
              <Icon name="pencil" size="small" />
            </div>
          )}
      </div>
      {isEditable &&
        isOpen && (
          <CustomFieldEditModal
            fieldKey={fieldKey}
            icon={icon}
            index={index}
            isArray={isArray}
            title={title}
            type={type}
            value={value}
            menuRef={menuRef}
            offset={offset}
            options={options}
            onCancel={doClose}
            onSave={doSave}
            values={values}
          />
        )}
    </>
  )
}
