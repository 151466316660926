import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  background-color: ${theme.color.monochrome.white};
  box-shadow: 0 ${rem(2)} ${rem(4)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.1);
  border-radius: ${rem(6)};
  border: 1px solid ${theme.color.monochrome.light};
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const wrapper = css`
  flex-grow: 1;
  height: auto;
`

const icon = css`
  width: 17px;
  height: 21px;
  margin-right: ${rem(4)};
`

const iconWrapper = theme => css`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: ${theme.fontSize.medium};
  font-size: 13px;

  img {
    width: 20px;
    padding: 4px;
    background: rgba(${theme.color.monochrome.white_rgba}, 0.3);
    border-radius: 4px;
  }
`

const menuHeader = theme => css`
  font-size: 11px;
  font-weight: ${theme.fontWeight.medium};
  letter-spacing: 1px;
  color: ${theme.color.monochrome.mediumDark};
  padding: ${rem(15)} 0 ${rem(7)} ${rem(13)};
  & + [aria-expanded='false'] {
    display: none;
  }

  & + [aria-expanded='true'] {
    display: block;
  }
`

const comingSoon = theme => css`
  font-family: ${theme.font.primary};
  font-size: 11px;
  font-weight: ${theme.fontWeight.semibold};
  padding: ${rem(2)} ${rem(5)} ${rem(2)} ${rem(4)};
`

const templateItem = theme => css`
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: ${rem(6)} ${rem(8)} ${rem(6)} ${rem(8)};
  border-radius: 4px;
  margin: 0 ${rem(4)};

  &:hover {
    background-color: ${theme.color.info['500']};
    color: #ffffff;
  }

  &.disabled {
    color: ${theme.color.monochrome.mediumDark};
    background-color: #ffffff;
    cursor: default;
  }

  span {
    align-self: flex-end;
  }
`

export const styles = {
  menuHeader,
  templateItem,
  container,
  wrapper,
  icon,
  iconWrapper,
  comingSoon,
}
