import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import config from 'config'
import Menu from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import { divider } from '@groovehq/internal-design-system/lib/styles/elements'
import { Help as HelpIcon } from '@groovehq/internal-design-system/lib/assets/icons'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import useFrillWidget from 'util/hooks/useFrillWidget'
import {
  announcements as AnnouncementsIcon,
  feature as FeatureIcon,
  conversations as ConversationsIcon,
  help as KBIcon,
} from 'assets/icons/groove/v2'
import useOpenContact from 'util/hooks/useOpenContact'
import GrooveStatusMenuItem from '../UserItem/GrooveStatusMenuItem'
import ItemWithIcon from '../UserItem/ItemWithIcon'
import { styles } from './styles'
import IconItem from '../IconItem'

const FullMenu = React.memo(
  ({
    announcementCount,
    onClickAnnoucementsLauncher,
    onClickIdeasLauncher,
    showAnnoucementItem,
  }) => {
    // We need to pass user details to Frill widgets
    // so need to make sure the user data exist
    const currentUser = useSelector(selectCurrentUser)
    const openContact = useOpenContact()

    return (
      <Menu as="div" css={styles.menuStyle}>
        <Menu.Item
          as="a"
          href="https://help.groovehq.com/help"
          target="_blank"
          itemKey="knowledge"
          rel="noopener noreferrer"
          className="grui"
        >
          <ItemWithIcon icon={<KBIcon className="sm-icon" />}>
            Knowledge Base
          </ItemWithIcon>
        </Menu.Item>
        <Menu.Item itemKey="contact" className="grui" onClick={openContact}>
          <ItemWithIcon icon={<ConversationsIcon className="sm-icon" />}>
            Contact Support
          </ItemWithIcon>
        </Menu.Item>
        <div css={divider.styles.primary} role="separator" />
        {currentUser?.email &&
          showAnnoucementItem && (
            <Menu.Item
              itemKey="announcements"
              className="grui"
              onClick={onClickAnnoucementsLauncher}
            >
              <ItemWithIcon icon={<AnnouncementsIcon className="sm-icon" />}>
                Announcements
                {announcementCount > 0 && (
                  <span css={styles.badge} className="grui ml-auto">
                    {announcementCount}
                  </span>
                )}
              </ItemWithIcon>
            </Menu.Item>
          )}
        {currentUser?.email && (
          <Menu.Item
            itemKey="request-feature"
            className="grui"
            onClick={onClickIdeasLauncher}
          >
            <ItemWithIcon icon={<FeatureIcon className="sm-icon" />}>
              Request a feature
            </ItemWithIcon>
          </Menu.Item>
        )}
        <GrooveStatusMenuItem />
      </Menu>
    )
  }
)

const FRILL_WIDGETS = config.frillWidgets
const SHOW_FRILL_ANNOUNCEMENTS = config.showFrillAnnoucements

const Help = React.memo(() => {
  const [announcementCount, setAnnouncementCount] = useState()

  const { toggle: handleClickAnnoucementsLauncher } = useFrillWidget(
    SHOW_FRILL_ANNOUNCEMENTS ? FRILL_WIDGETS.announcements.key : null,
    { onBadgeCount: setAnnouncementCount }
  )

  const { toggle: handleClickIdeasLauncher } = useFrillWidget(
    FRILL_WIDGETS.ideas.key,
    { onBadgeCount: setAnnouncementCount }
  )

  return (
    <Dropdown
      overlay={
        <FullMenu
          setAnnouncementCount={setAnnouncementCount}
          announcementCount={announcementCount}
          onClickAnnoucementsLauncher={handleClickAnnoucementsLauncher}
          onClickIdeasLauncher={handleClickIdeasLauncher}
          showAnnoucementItem={SHOW_FRILL_ANNOUNCEMENTS}
        />
      }
      position="bottom-end"
      strategy="fixed"
      offset={8}
      autoHeight
      menuItemIconHidden
    >
      <div css={styles.avatarContainer} className="grui ml-7">
        <IconItem className="grui relative">
          <HelpIcon />
          {announcementCount > 0 && (
            <Dot
              className="grui absolute"
              css={styles.dot}
              color="color.brand.800"
            />
          )}
        </IconItem>
      </div>
    </Dropdown>
  )
})

export default Help
