import { useCallback } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownButton from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownButton'
import DropdownMenu from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenu'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'

import { isEmpty } from 'util/arrays'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_SELECT_EMAIL } from 'ducks/drawers/types'

import Mailbox from './Mailbox'
import SelectedMailbox from './SelectedMailbox'

const mailboxStyle = () => theme => css`
  margin-left: ${theme.spacing['3']};
`

const setupMailboxStyle = () => theme => css`
  &&& {
    margin-left: ${theme.spacing['3']};
    font-weight: ${theme.fontWeight.semibold};
    color: ${theme.color.brand['900']};
    &:hover {
      text-decoration: underline;
    }
  }
`

const AddMailboxButton = () => {
  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
    lazyLoad: true,
    closeIgnoresStack: true,
  })

  const openDrawer = useCallback(
    () => {
      openAddDrawer('new')
    },
    [openAddDrawer]
  )

  return (
    <button
      css={[button.styles.link, setupMailboxStyle]}
      className="grui ml-4"
      onClick={openDrawer}
    >
      Add {app.t('mailbox_with_article')}
    </button>
  )
}

const MailboxDropdown = ({ onClick, mailbox, mailboxes }) => {
  const mailboxesEmpty = isEmpty(mailboxes)

  if (mailboxesEmpty) {
    if (mailbox) {
      return <SelectedMailbox mailbox={mailbox} css={mailboxStyle} />
    }
    return <AddMailboxButton />
  } else if (mailboxes.length === 1 && mailbox) {
    return <SelectedMailbox mailbox={mailbox} css={mailboxStyle} />
  }

  return (
    <Dropdown
      hasMinWidth
      strategy="fixed"
      portal="#overlays"
      onSelect={onClick}
      selectedKey={mailbox?.id}
      overlay={
        <DropdownMenu>
          {mailboxes.map(m => (
            <DropdownMenu.Item key={m.id} itemKey={m.id} item={m}>
              <Mailbox mailbox={m} />
            </DropdownMenu.Item>
          ))}
        </DropdownMenu>
      }
    >
      <DropdownButton size="small" textBtn css={mailboxStyle}>
        {mailbox ? (
          <SelectedMailbox mailbox={mailbox} />
        ) : (
          `Select ${app.t('mailbox_with_article')}`
        )}
      </DropdownButton>
    </Dropdown>
  )
}

export default styled(MailboxDropdown)`
  &&& {
    align-items: center;
    color: ${props => props.theme.color.primary.brand};
    cursor: pointer;
    display: inline-flex;
    margin-left: ${props => props.theme.spacing['3']};

    .dropdown {
      &.icon {
        /* SUI override for a bigger caret thats closer to icon */
        font-size: ${props => props.theme.fontSize.small} !important;
        margin-left: ${props => props.theme.spacing['4']} !important;
      }
    }
  }
`
