import React, { useCallback, useEffect } from 'react'
import Link from 'redux-first-router-link'
import { useSelector } from 'react-redux'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import {
  UserGroup,
  Negative,
} from '@groovehq/internal-design-system/lib/assets/icons'
import { buildSearchPath, constructSearchQueryString } from 'util/search'
import {
  selectLeftNavAgentsForMailboxId,
  selectLeftNavMailboxById,
} from 'selectors/left_nav'
import { selectAgentCount } from 'selectors/agents/base'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import SearchRawCount from 'components/App/DesktopView/Layout/SearchRawCount'
import TagsMenuItem from 'subapps/ticketing/components/TagsMenuItem'
import AddUserDrawerLink from './AddUserDrawerLink'
import Item from '../Item'
import MoreFolderOptions from './MoreFolderOptions'
import FolderKindIcon from './FolderKindIcon'
import { hasAtMostOneAgent } from './utils'

const Mailbox = ({
  isCurrent,
  toggleTeamSection,
  toggleTagsSection,
  isTeamSectionOpen,
  isTagsSectionOpen,
  doOpenSearchPage,
  doStartSearchTimer,
  wantsCompactMode,
  hasEverToggledTeamSection,
  sortOrder,
  mailboxId,
  onClick,
}) => {
  const agents = useSelector(state =>
    selectLeftNavAgentsForMailboxId(state, mailboxId)
  )
  const activeAgentCount = useSelector(selectAgentCount)
  const mailbox = useSelector(state =>
    selectLeftNavMailboxById(state, mailboxId)
  )
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)

  const buildMailboxFolderPath = useCallback(
    params => {
      const isAllMailboxes = mailbox.id === null
      return buildSearchPath({ sortOrder })(
        isAllMailboxes ? params : { ...params, mailbox: mailbox.id }
      )
    },
    [mailbox.id, sortOrder]
  )

  const buildMailboxQueryString = useCallback(
    params => {
      const isAllMailboxes = mailbox.id === null
      return constructSearchQueryString(
        isAllMailboxes
          ? params
          : {
              ...params,
              mailbox: mailbox.id,
            }
      )
    },
    [mailbox.id]
  )

  useEffect(
    () => {
      if (!hasEverToggledTeamSection && hasAtMostOneAgent(agents)) {
        // agent has never expanded teams section before and agents for mailbox <= 1
        // expand section to encourage adding new team member
        toggleTeamSection()
      }
    },
    [agents, hasEverToggledTeamSection, toggleTeamSection]
  )

  const folders = mailbox.folders
  const firstFolder = folders && folders[0]
  const firstFolderPath =
    firstFolder && buildMailboxFolderPath({ folder: firstFolder.id })
  const mailboxQueryId = buildMailboxQueryString({ is: 'open' })
  // Demo inbox should be considered as confirmed
  const isConfirmed = ['active', 'confirmed', 'syncing', 'demo'].includes(
    mailbox.state
  )
  // Make sure only show the unconfirmed or disconnected warning for channels that have state
  const shouldShowWarning =
    mailbox.state && mailbox.channel_type && !isConfirmed

  const teammatesItem = (
    <Menu.UnmanagedItem
      icon={<UserGroup />}
      open={isTeamSectionOpen}
      isSubMenuItem
      hasArrow
      onClick={toggleTeamSection}
      value="team-section"
      subMenu={
        <>
          {agents &&
            agents.map(agent => {
              return (
                <Item
                  isSubMenuItem
                  agentId={agent.id}
                  key={agent.id}
                  value={agent.id}
                  name={agent.shortName}
                  doOpenSearchPage={doOpenSearchPage}
                  doStartSearchTimer={doStartSearchTimer}
                  displayCountWhenInactive
                  buildMailboxFolderPath={buildMailboxFolderPath}
                  buildMailboxQueryString={buildMailboxQueryString}
                />
              )
            })}
          {isManager && (
            <AddUserDrawerLink
              isSubMenuItem
              highlight={hasAtMostOneAgent(agents)}
            />
          )}
        </>
      }
    >
      {app.t('Agents')}
    </Menu.UnmanagedItem>
  )

  const mailboxSubmenu = (
    <>
      {mailbox.folders.map(folder => {
        return (
          <Item
            icon={<FolderKindIcon kind={folder.kind} />}
            folderId={folder.id}
            key={folder.id}
            value={folder.id}
            name={folder.name}
            doOpenSearchPage={doOpenSearchPage}
            doStartSearchTimer={doStartSearchTimer}
            displayCountWhenInactive={folder.displayCountWhenInactive}
            hideIfZeroConversations={folder.hideIfZeroConversations}
            buildMailboxFolderPath={buildMailboxFolderPath}
            buildMailboxQueryString={buildMailboxQueryString}
            moreOptions={
              isManager && (
                <Menu.Item.MoreOptions
                  overlay={
                    <MoreFolderOptions
                      id={folder.id}
                      isDefault={folder.default}
                    />
                  }
                  menuItemIconHidden
                />
              )
            }
            isSubMenuItem
          />
        )
      })}
      {activeAgentCount > 1 && teammatesItem}
      <TagsMenuItem
        isTagsSectionOpen={isTagsSectionOpen}
        toggleTagsSection={toggleTagsSection}
        doOpenSearchPage={doOpenSearchPage}
        doStartSearchTimer={doStartSearchTimer}
        buildMailboxFolderPath={buildMailboxFolderPath}
        buildMailboxQueryString={buildMailboxQueryString}
      />
    </>
  )

  if (wantsCompactMode) {
    return mailboxSubmenu
  }

  return (
    <Menu.UnmanagedItem
      as={Link}
      dotColor={mailbox.color || 'color.monochrome.dark'}
      count={!shouldShowWarning && <SearchRawCount queryId={mailboxQueryId} />}
      onClick={onClick}
      to={firstFolderPath}
      open={isCurrent}
      subMenu={isCurrent && mailboxSubmenu}
      value="mailbox-section"
      title={mailbox.name}
      // The negative margin is for offsetting the icon's whitespace,
      // so it can align well with other inboxes's count numbers
      extraIcon={shouldShowWarning && <Negative className="grui -mr-2" />}
      extraIconType="warning"
    >
      {mailbox.name}
    </Menu.UnmanagedItem>
  )
}

export default Mailbox
