import cn from 'classnames'
import React from 'react'
import styled from '@emotion/styled'
import Link from 'redux-first-router-link'

import { Icon } from 'util/ui'

import LinkedResourceAction from './LinkedResourceAction'

export default styled(LinkedResourceActions)`
  margin-top: ${props => props.theme.spacing['7']};

  .action {
    color: ${props => props.theme.color.primary.brand};
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i.icon {
      font-size: 0.8em;
      top: -1px;
      position: relative;
    }
  }
`

function LinkedResourceActions({ actions, className, id }) {
  if (!actions || actions.length === 0) return null
  return (
    <div className={cn('LinkedResourceActions', className)}>
      {actions
        .map((actionItem, index) => {
          if (!actionItem) return null
          const {
            action,
            blankTarget,
            icon,
            target = blankTarget ? '_blank' : '_self',
            text,
            url,
          } = actionItem
          if (url) {
            return (
              <Link
                // eslint-disable-next-line react/no-array-index-key
                key={`${text}-${index}`}
                className="action"
                to={url}
                target={target}
              >
                {icon && [<Icon key="icon" name={icon} />, ' ']}
                {text}
                {target === '_blank' && [
                  ' ',
                  <Icon key="icon" name="external" />,
                ]}
              </Link>
            )
          }
          return (
            <LinkedResourceAction
              // eslint-disable-next-line react/no-array-index-key
              key={`${text}-${index}`}
              action={action}
              id={id}
            >
              {icon && [<Icon key="icon" name={icon} />, ' ']}
              {text}
            </LinkedResourceAction>
          )
        })
        .reduce((result, item, i, original) => {
          if (i !== original.length - 1) return result.concat(item, ' · ')
          return result.concat(item)
        }, [])}
    </div>
  )
}
