import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAccountCanSeePinnedSearches } from 'selectors/app'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectCurrentTicketSearchMailboxId } from 'selectors/search'
import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'
import { doStartSearchTimer } from 'actions/metrics'

import {
  selectLeftNavMailboxes,
  selectPinnedSearchesListItems,
  selectLeftNavShowBookDemoCard,
  selectLeftNavShowTeammatesCard,
} from 'selectors/left_nav'

import storage from 'util/storage'
import { selectShowOnboardingWorkflow } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflow'

const DEFAULT_OPEN_SECTIONS = { Starred: true, Folders: true }

export const useLeftNav = () => {
  const dispatch = useDispatch()

  const [expandedSections, setExpandedSections] = useState(
    DEFAULT_OPEN_SECTIONS
  )

  const canSeePinnedSearches = useSelector(selectAccountCanSeePinnedSearches)
  const currentMailboxId = useSelector(selectCurrentTicketSearchMailboxId)
  const mailboxes = useSelector(selectLeftNavMailboxes)
  const mailboxId = currentMailboxId || (mailboxes && mailboxes[0]?.id)
  const prefersUnifiedInbox = useSelector(selectPrefersUnifiedInbox)
  const starredSearches = useSelector(selectPinnedSearchesListItems)
  const showBookDemoCard = useSelector(selectLeftNavShowBookDemoCard)
  const showTeammatesCard = useSelector(state =>
    selectLeftNavShowTeammatesCard(state, mailboxId)
  )
  const showOnboardingWorkflow = useSelector(selectShowOnboardingWorkflow)

  const openSearchPage = useCallback(
    (...args) => {
      dispatch(doOpenSearchPage(...args))
    },
    [dispatch]
  )

  const startSearchTimer = useCallback(
    (...args) => {
      dispatch(doStartSearchTimer(...args))
    },
    [dispatch]
  )

  const [starredOpen, setStarredOpen] = useState(!mailboxId)

  const onMailboxClick = useCallback(() => {
    setStarredOpen(false)
  }, [])

  const onStarredClick = useCallback(() => {
    setStarredOpen(true)
  }, [])

  const hasEverToggledSection = useCallback(
    sectionTitle => expandedSections[sectionTitle] !== undefined,
    [expandedSections]
  )

  const isSectionExpanded = useCallback(
    sectionTitle => expandedSections[sectionTitle] || false,
    [expandedSections]
  )

  const setSectionCollapsedState = useCallback(
    (sectionTitle, isExpanded) => {
      const newExpandedSections = {
        ...expandedSections,
        [sectionTitle]: isExpanded,
      }
      storage.set('inbox-leftnav-open-sections', newExpandedSections)
      setExpandedSections(newExpandedSections)
    },
    [expandedSections]
  )

  const toggleSection = useCallback(
    sectionTitle => {
      if (isSectionExpanded(sectionTitle)) {
        setSectionCollapsedState(sectionTitle, false)
      } else {
        setSectionCollapsedState(sectionTitle, true)
      }
    },
    [isSectionExpanded, setSectionCollapsedState]
  )

  const toggleTeamSection = useCallback(() => toggleSection('Team'), [
    toggleSection,
  ])
  const toggleTagsSection = useCallback(() => toggleSection('Tags'), [
    toggleSection,
  ])

  useEffect(() => {
    const values =
      storage.get('inbox-leftnav-open-sections') || DEFAULT_OPEN_SECTIONS
    let valuesAsObj = values

    if (Array.isArray(values)) {
      // old data structure which is an array of strings
      valuesAsObj = values.reduce((accumulator, section) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[section] = true
        return accumulator
      }, {})
    }

    setExpandedSections(valuesAsObj)
  }, [])

  return {
    canSeePinnedSearches,
    mailboxId,
    mailboxes,
    prefersUnifiedInbox,
    starredSearches,
    showBookDemoCard,
    showTeammatesCard,
    showOnboardingWorkflow,
    openSearchPage,
    startSearchTimer,
    toggleTeamSection,
    toggleTagsSection,
    isSectionExpanded,
    starredOpen,
    onStarredClick,
    onMailboxClick,
    hasEverToggledSection,
  }
}
