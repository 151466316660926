import cn from 'classnames'
import React from 'react'
import { BoxHeadline, Flex } from 'shared/ui'
import { summarizeCount } from 'util/counts'
import Name from './Name'
import styles from './styles.less'

export default function LabelText({ id, name, count, searchTerm, showCount }) {
  return (
    <Flex
      className={cn('grui overflow-hidden', { [styles.create]: id === -1 })}
    >
      <Name id={id} name={name} searchTerm={searchTerm} />
      <Flex className="grui flex-1-0-auto">
        <BoxHeadline>
          {id === -1 && '”'}
          {id !== -1 &&
            showCount && (
              <span className={styles.spaceLeft}>
                ({summarizeCount(count)})
              </span>
            )}
        </BoxHeadline>
      </Flex>
    </Flex>
  )
}
