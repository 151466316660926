import React, { useCallback, useEffect, useMemo } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAgentMention,
  selectIsMentioningAgent,
  selectTicketOrNoteDraftFilteredMentionableAgentsWithSuggested,
} from 'selectors/mentions'
import { doMentionAgent } from 'actions/tickets'
import { doUpdateSelectedMentionSelectorAgentId } from 'actions/app'
import editor from 'shared/editor/utils'
import SelectableMenu from './SelectableMenu'
import { styles } from './styles'

const MentionedAgentList = props => {
  const { scrollParentRef, editorInstance: _editorInstance } = props
  const dispatch = useDispatch()
  const filteredMentionableAgentsWithSuggested = useSelector(state =>
    selectTicketOrNoteDraftFilteredMentionableAgentsWithSuggested(state, true)
  )
  const selectAgent = useSelector(selectAgentMention)
  const isMentioning = useSelector(selectIsMentioningAgent)
  const [popperElement, setPopperElement] = React.useState(null)
  const [popperVirtualReference, setPopperVirtualReference] = React.useState({})
  const { styles: popperStyles, attributes } = usePopper(
    popperVirtualReference,
    popperElement,
    {
      placement: 'top-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [4, 6],
          },
        },
      ],
    }
  )
  const editorInstance = _editorInstance || editor.getEditor()

  useEffect(
    () => {
      if ((!isMentioning && !selectAgent?.rect) || selectAgent.word.length < 1)
        return
      setPopperVirtualReference({
        getBoundingClientRect: () => selectAgent.rect,
      })
    },
    [scrollParentRef, selectAgent, isMentioning]
  )

  const isOwnSelector = useMemo(
    () => {
      if (!editorInstance || !selectAgent) {
        return null
      }

      return editorInstance.targetElm.contains(selectAgent.node)
    },
    [editorInstance, selectAgent]
  )

  const handleSelect = useCallback(
    (_ticket, agent) => {
      dispatch(doMentionAgent(agent.id, editorInstance))
    },
    [dispatch, editorInstance]
  )

  const handleFocus = useCallback(
    (_ticket, agent) => {
      return dispatch(doUpdateSelectedMentionSelectorAgentId(agent.id))
    },
    [dispatch]
  )

  if (
    !isMentioning ||
    !isOwnSelector ||
    filteredMentionableAgentsWithSuggested?.agents?.length === 0
  )
    return null

  return (
    <div
      style={popperStyles.popper}
      css={styles.menuWrapper}
      ref={setPopperElement}
      {...attributes.popper}
    >
      <SelectableMenu
        items={filteredMentionableAgentsWithSuggested?.agents}
        suggestedAgentCount={
          filteredMentionableAgentsWithSuggested?.suggestedAgents?.length
        }
        onSelect={handleSelect}
        onFocus={handleFocus}
        showArrow={false}
      />
    </div>
  )
}

export default MentionedAgentList
