import React from 'react'
import cn from 'classnames'
import styles from './styles.css'

export default class IsolateScroll extends React.Component {
  firstY = null

  maybeSwallowScrollEvent = (delta, event) => {
    const container =
      this.node.querySelector(this.props.contentSelector) || this.node
    event.stopPropagation()

    if (!container.contains(event.target) && event.target !== container) {
      return event.preventDefault()
    }

    const scrollingUp = delta > 0
    const scrollingDown = delta < 0

    const fuzzyFactor = 15
    const scrollBottom = container.scrollTop + container.offsetHeight
    const isScrolledToTop = container.scrollTop <= fuzzyFactor
    const isScrolledToBottom =
      container.scrollHeight - scrollBottom <= fuzzyFactor

    if (scrollingDown && isScrolledToBottom) {
      container.scrollTop = container.scrollHeight // scroll to bottom
      event.preventDefault()
    } else if (scrollingUp && isScrolledToTop) {
      container.scrollTop = 0 // scroll to top
      event.preventDefault()
    }
  }

  onMouseScroll = e => {
    const delta = e.type === 'mousewheel' ? e.wheelDelta : e.detail * -40
    this.maybeSwallowScrollEvent(delta, e)
  }

  rememberFirstTouchpointY = e => {
    this.firstY = e.touches[0].clientY
  }

  onTouchScroll = e => {
    const currentY = e.touches[0].clientY
    const delta = currentY - this.firstY
    this.maybeSwallowScrollEvent(delta, e)
  }

  setupEvents() {
    this.node.addEventListener('mousewheel', this.onMouseScroll)
    this.node.addEventListener('DOMMouseScroll', this.onMouseScroll)
    this.node.addEventListener('touchstart', this.rememberFirstTouchpointY)
    this.node.addEventListener('touchmove', this.onTouchScroll)
  }

  teardownEvents() {
    this.node.removeEventListener('mousewheel', this.onMouseScroll)
    this.node.removeEventListener('DOMMouseScroll', this.onMouseScroll)
    this.node.removeEventListener('touchstart', this.rememberFirstTouchpointY)
    this.node.removeEventListener('touchmove', this.onTouchScroll)
  }

  componentDidMount() {
    if (!this.props.disabled) {
      this.setupEvents()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.disabled && this.props.disabled) {
      this.setupEvents()
    } else if (nextProps.disabled && !this.props.disabled) {
      this.teardownEvents()
    }
  }

  componentWillUnmount() {
    this.teardownEvents()
  }

  render() {
    const { className, disableFastClick, children } = this.props
    return (
      <div
        className={cn([
          className,
          styles.isolateScroll,
          {
            needsclick:
              disableFastClick === undefined ? true : disableFastClick,
          },
        ])}
        ref={node => {
          this.node = node
        }}
      >
        {children}
      </div>
    )
  }
}
