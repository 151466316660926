import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import {
  selectIsEditorPinned,
  selectIsEditorVisible,
} from 'ducks/editor/selectors'

import ReplyEmailForm from 'components/App/DesktopView/CommunicationForms/Reply/Email'
import ReplyNoteForm from 'components/App/DesktopView/CommunicationForms/Reply/Note'
import ReplyFacebookForm from 'components/App/DesktopView/CommunicationForms/Reply/Facebook'
import { useAi } from 'ducks/ai/hooks'
import { COMPOSER_TYPE } from 'ducks/ai/types'
import { styles as aIMessageStyles } from 'components/App/DesktopView/Layout/TicketInspector/Changesets/AIMessage/styles'

import Selector from './Selector'

import styles from './styles.less'

const Form = ({
  loaded,
  commenting,
  onEditorFormFocus,
  onEditorFormBlur,
  onEditorInit,
  ticketId,
  isTwitter,
  isFacebook,
  className,
}) => {
  const pinned = useSelector(selectIsEditorPinned)
  const showCommentEditor = loaded && commenting
  let editor = null

  if (!showCommentEditor)
    editor = (
      <ReplyEmailForm
        ticketId={ticketId}
        onEditorFormFocus={onEditorFormFocus}
        onEditorFormBlur={onEditorFormBlur}
        onEditorInit={onEditorInit}
      />
    )
  if (!showCommentEditor && isTwitter) editor = null
  if (!showCommentEditor && isFacebook)
    editor = (
      <ReplyFacebookForm
        ticketId={ticketId}
        onEditorFormFocus={onEditorFormFocus}
        onEditorFormBlur={onEditorFormBlur}
        onEditorInit={onEditorInit}
      />
    )

  if (showCommentEditor) {
    editor = (
      <ReplyNoteForm
        ticketId={ticketId}
        onEditorFormFocus={onEditorFormFocus}
        onEditorFormBlur={onEditorFormBlur}
        onEditorInit={onEditorInit}
      />
    )
  }

  return <div className={cn(className, pinned && 'pinned')}>{editor}</div>
}

const Footer = ({
  className,
  isReplyFormVisible: replying,
  isAddingNote: commenting,
  ticketId,
  isTwitter,
  ...rest
}) => {
  const editorVisible = useSelector(selectIsEditorVisible)
  const editorPinned = useSelector(selectIsEditorPinned)
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { requestState: composerRequestState } = useAi(COMPOSER_TYPE)
  const hasAIEditRequestState =
    composerRequestState.loading || composerRequestState.error

  const onEditorInit = useCallback(
    () => {
      setEditorLoaded(true)
    },
    [setEditorLoaded]
  )

  if (isTwitter) return null

  return (
    <React.Fragment>
      {!editorVisible ? (
        <Selector />
      ) : (
        <Form
          className={!editorLoaded && styles.HiddenForm}
          css={hasAIEditRequestState && aIMessageStyles.hiddenComposer}
          commenting={commenting}
          replying={replying}
          ticketId={ticketId}
          isTwitter={isTwitter}
          onEditorInit={onEditorInit}
          {...rest}
        />
      )}
      {!editorPinned && <div className={styles.BottomSpacer} />}
    </React.Fragment>
  )
}

export default Footer
