import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { string, oneOfType, node, shape, func, bool, number } from 'prop-types'
import { useForm, useController } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { currencyCodeToSymbol } from 'util/currency'
import { isLocalePercentageSymbolSuffixed } from 'util/locale'
import { omit } from 'util/objects'
import { DISCOUNT_VALUE_TYPES } from 'ducks/integrations/shopify/constants'
import { styles } from './styles'

const FORM_KEY_VALUE = 'value'
const FORM_KEY_VALUE_TYPE = 'valueType'
const FORM_KEY_DESCRIPTION = 'description'
const FORM_KEY_PRICE_CENTS = 'priceCents'

// using separate react hook forms here instead of using current rhf form state
// because this is used for both order and lineItem discounts
// let the caller apply the form data to the respective parent form field on submit
const FORM_SCHEMA = yup.object().shape({
  [FORM_KEY_VALUE_TYPE]: yup
    .string()
    .oneOf(
      [DISCOUNT_VALUE_TYPES.FIXED_AMOUNT, DISCOUNT_VALUE_TYPES.PERCENTAGE],
      'Please select a valid discount value type'
    )
    .required('Discount type is required'),
  [FORM_KEY_VALUE]: yup
    .number('Only numeric amounts allowed')
    // eslint-disable-next-line no-template-curly-in-string
    .min(0, 'Discount needs to be greater than ${min}')
    .when(
      [FORM_KEY_VALUE_TYPE, FORM_KEY_PRICE_CENTS],
      (valueType, priceCents, schema) => {
        switch (valueType) {
          case DISCOUNT_VALUE_TYPES.PERCENTAGE:
            return schema.max(
              100,
              // eslint-disable-next-line no-template-curly-in-string
              'Discount percentage must be less than ${max}'
            )
          case DISCOUNT_VALUE_TYPES.FIXED_AMOUNT:
            return !Number.isNaN(parseFloat(priceCents))
              ? schema.max(
                  priceCents / 100,
                  // eslint-disable-next-line no-template-curly-in-string
                  'Discount amount must be less than ${max}'
                )
              : schema.max(Number.MAX_SAFE_INTEGER)
          default:
            return schema.max(Number.MAX_SAFE_INTEGER)
        }
      }
    )
    .required('Discount amount is required'),
  [FORM_KEY_DESCRIPTION]: yup.string().nullable(),
  // only adding this for validation of valueType
  [FORM_KEY_PRICE_CENTS]: yup.number().required(),
})

const DEFAULT_VALUES = {
  [FORM_KEY_VALUE]: null,
  [FORM_KEY_VALUE_TYPE]: DISCOUNT_VALUE_TYPES.FIXED_AMOUNT,
  [FORM_KEY_DESCRIPTION]: null,
  [FORM_KEY_PRICE_CENTS]: null,
}

const showTagContent = (isPercent, currencySymbol) => {
  const content = isPercent ? '%' : currencySymbol
  return <span css={styles.tag}>{content}</span>
}

const PriceDiscount = ({
  children,
  discount,
  currency,
  onDiscountChange,
  priceCents,
  isOrderDiscount,
  disabled,
}) => {
  const formId = `form-price-${isOrderDiscount ? 'order' : 'item'}-discount`
  const [visible, setVisible] = useState(false)

  const currencySymbol = currencyCodeToSymbol(currency)

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  })

  const {
    field: { onChange: onValueTypeChange, value: discountValueType },
  } = useController({
    name: FORM_KEY_VALUE_TYPE,
    control,
  })

  const selectDiscountValueTypeFixedAmount = useCallback(
    () => {
      onValueTypeChange(DISCOUNT_VALUE_TYPES.FIXED_AMOUNT)
    },
    [onValueTypeChange]
  )

  const selectDiscountValueTypePercentage = useCallback(
    () => {
      onValueTypeChange(DISCOUNT_VALUE_TYPES.PERCENTAGE)
    },
    [onValueTypeChange]
  )

  const isPercentValueType =
    discountValueType === DISCOUNT_VALUE_TYPES.PERCENTAGE

  const handleClose = useCallback(
    () => {
      setVisible(false)
    },
    [setVisible]
  )

  // Remove discount
  const handleRemove = useCallback(
    () => {
      reset({ ...DEFAULT_VALUES })
      setVisible(false)
      onDiscountChange(null)
    },
    [setVisible, reset, onDiscountChange]
  )

  const discountValueTagPosition = useMemo(
    () => {
      if (!isPercentValueType) return 'left'

      return isLocalePercentageSymbolSuffixed() ? 'right' : 'left'
    },
    [isPercentValueType]
  )

  useEffect(
    () => {
      reset({
        [FORM_KEY_VALUE]: discount?.value,
        [FORM_KEY_VALUE_TYPE]: discount?.valueType,
        [FORM_KEY_DESCRIPTION]: discount?.description,
        [FORM_KEY_PRICE_CENTS]: priceCents,
      })
    },
    [discount, priceCents, reset]
  )

  // apply discount
  const onSubmit = useCallback(
    data => {
      setVisible(false)
      onDiscountChange({ ...omit([FORM_KEY_PRICE_CENTS], data) })
    },
    [onDiscountChange]
  )

  const footer = useMemo(
    () => {
      if (!visible) return null
      return (
        <>
          {discount?.value ? (
            <Button type="warning" size="small" onClick={handleRemove}>
              Remove
            </Button>
          ) : (
            <Button type="tertiary" size="small" onClick={handleClose}>
              Cancel
            </Button>
          )}
          <Button
            size="small"
            htmlType="submit"
            form={formId}
            disabled={!isValid || !isDirty}
          >
            Apply
          </Button>
        </>
      )
    },
    [
      discount?.value,
      handleClose,
      handleRemove,
      visible,
      isValid,
      isDirty,
      formId,
    ]
  )

  const overlay = useMemo(
    () => {
      return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <div className="grui px-7 pb-10 pt-6">
            <div className="grui mb-8">
              <div css={fieldStyles.labelBox}>
                {`Discount this ${isOrderDiscount ? 'order' : 'item'} by`}
              </div>
              <div className="grui flex">
                <div
                  css={[
                    styles.controls,
                    isPercentValueType && styles.percentage,
                  ]}
                  className="grui mr-5"
                >
                  <Button
                    type="tertiary"
                    customIcon={currencySymbol}
                    onClick={selectDiscountValueTypeFixedAmount}
                  />
                  <Button
                    type="tertiary"
                    customIcon="%"
                    onClick={selectDiscountValueTypePercentage}
                  />
                </div>
                <Field
                  className="grui flex-1"
                  tagPosition={discountValueTagPosition}
                  tagContent={showTagContent(
                    isPercentValueType,
                    currencySymbol
                  )}
                  {...register(FORM_KEY_VALUE)}
                />
              </div>
            </div>
            <Field
              label="Reason"
              placeholder="Loyalty discount"
              {...register(FORM_KEY_DESCRIPTION)}
            />
            <div
              className="grui text-left"
              css={[text.styles.text2Xs, text.styles.textMediumDark]}
            >
              Your customers can see this reason
            </div>
          </div>
        </form>
      )
    },
    [
      currencySymbol,
      isOrderDiscount,
      isPercentValueType,
      discountValueTagPosition,
      selectDiscountValueTypeFixedAmount,
      selectDiscountValueTypePercentage,
      register,
      formId,
      handleSubmit,
      onSubmit,
    ]
  )

  return (
    <Dropdown
      overlay={overlay}
      footer={footer}
      footerWithBtns
      hasMinWidth
      autoHeight
      visible={visible}
      onVisibleChange={setVisible}
      disabled={disabled}
    >
      {children}
    </Dropdown>
  )
}

PriceDiscount.propTypes = {
  children: oneOfType([string, node]),
  discount: shape({
    value: number,
    valueType: string,
    description: string,
  }),
  currency: string,
  onDiscountChange: func,
  priceCents: number,
  /**
   * Is it for order, false if a product/line item discount
   */
  isOrderDiscount: bool,
  disabled: bool,
}

PriceDiscount.defaultProps = {
  children: undefined,
  discount: undefined,
  currency: '',
  onDiscountChange() {},
  priceCents: undefined,
  isOrderDiscount: false,
  disabled: false,
}

export default PriceDiscount
