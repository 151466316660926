import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import doCreateLead from 'ducks/integrations/salesforce/operations/doCreateLead'
import withFormFields from 'shared/ui/Form/withFormFields'
import { required, join, email } from 'util/validation'
import { selectCurrentCustomer } from 'selectors/tickets/customer/selectCurrentCustomer'
import {
  selectCreateContactErrors,
  selectNewContactInitialValues,
} from 'ducks/integrations/salesforce/selectors'

import View from './view'

function mapStateToProps(state) {
  return {
    createContactError: selectCreateContactErrors(state),
    customer: selectCurrentCustomer(state),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    doCreateLead: () => {
      dispatch(doCreateLead(props.formFields))
        .then(() => {
          props.resetForm()
          props.onClose()
        })
        .catch(() => {})
    },
    onClose: () => {
      props.resetForm()
      props.onClose()
    },
  }
}

const Form = compose(
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(View)

const validators = {
  Email: join([email, required]),
}

function select(state) {
  return {
    initialValues: selectNewContactInitialValues(state),
  }
}

class LinkForm extends React.PureComponent {
  render() {
    return <Form {...this.props} validators={validators} />
  }
}

const ConnectedLinkForm = connect(select)(LinkForm)

export default ConnectedLinkForm
