import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  doFetchJiraCloudIssueSearch,
  doLinkJiraCloudIssueToTicket,
} from 'ducks/integrations/jiraCloud'

import {
  selectJiraSearchedIssuesAsOptions,
  selectJiraSearchIssuesAreLoading,
  selectJiraSitesAreLoading,
  selectJiraSitesAsOptions,
} from 'ducks/integrations/jiraShared'
import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'

import LinkExistingJiraCloudIssueModal from './view'

function mapStateToProps(state) {
  return {
    areIssuesLoading: selectJiraSearchIssuesAreLoading(state, {
      source: 'jiraCloud',
    }),
    areSitesLoading: selectJiraSitesAreLoading(state, { source: 'jiraCloud' }),
    sites: selectJiraSitesAsOptions(state, { source: 'jiraCloud' }),
    issues: selectJiraSearchedIssuesAsOptions(state, { source: 'jiraCloud' }),
  }
}

const mapDispatchToProps = {
  doFetchJiraCloudIssueSearch,
  doLinkJiraCloudIssueToTicket,
}

export default compose(
  withOpenState,
  withProps({ initialValues: { repositoryOwner: null } }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkExistingJiraCloudIssueModal)
