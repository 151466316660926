import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsHotkeysModalVisible,
  doHideHotkeysModal,
} from 'ducks/modals/hotkeys'
import React, { useCallback } from 'react'

import { Header, Icon } from 'shared/ui'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

import Actions from './Actions'
import Conversation from './Conversation'
import ConversationsList from './ConversationsList'
import Other from './Other'
import ReplyNoteForm from './ReplyNoteForm'
import VersionNumber from './VersionNumber'
import { styles } from './styles'

const HotkeysModal = () => {
  const visible = useSelector(selectIsHotkeysModalVisible)
  const dispatch = useDispatch()
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const close = useCallback(
    () => {
      dispatch(doHideHotkeysModal())
    },
    [dispatch]
  )

  if (!visible) return null

  return (
    <>
      <ListenToKeyboard onEscape={close} />
      <div css={styles.hotkeysModalContainer}>
        <div css={styles.contentContainer}>
          <Header css={styles.title} size="medium">
            Keyboard shortcuts
          </Header>

          <div className="grui flex" css={styles.columns}>
            <div>
              <ConversationsList />
              <Conversation />
              {false && <ReplyNoteForm />}
              <Other />
            </div>
            {isAdminOrOwnerOrAgent && (
              <div>
                <Actions />
              </div>
            )}
          </div>
        </div>

        <VersionNumber />

        <div css={styles.closeButton}>
          <Icon name="close" size="large" onClick={close} />
        </div>
      </div>
    </>
  )
}

export default HotkeysModal
