import React from 'react'

import SearchableList from './SearchableList'
import Actions from './Actions'

const EditPane = props => {
  return (
    <React.Fragment>
      <SearchableList
        key="list"
        items={props.labels}
        onSelect={props.toggleLabel}
        {...props}
      />
      {props.mode === 'bulk' && <Actions key="actions" {...props} />}
    </React.Fragment>
  )
}

export default EditPane
