import React from 'react'
import styled from '@emotion/styled'
import { debounce } from 'util/functions'
import { Flex, SearchBox, Divider, Label, Button } from 'shared/ui'
import UserList from './SearchingList'

const StyledContainer = styled('div')`
  background-color: white;
  padding-top: ${props => props.theme.spacing['10']};
`

export default function SearchingState({
  onCancel,
  searchTerm,
  users,
  onSearch,
  onSelect,
  openCreating,
  isSearchingLoading,
  isSearchingError,
}) {
  return (
    <StyledContainer>
      <Label as="div" size="large" className="grui ml-12">
        Change user
      </Label>
      <SearchBox
        className="grui ml-12 mt-4"
        onSearchChange={debounce(onSearch, 500)}
        placeholder="Search..."
        value={searchTerm}
      />
      <Divider />
      <UserList
        isSearchingError={isSearchingError}
        items={Object.values(users)}
        searchTerm={searchTerm}
        onSelect={onSelect}
        onClose={onCancel}
        onAddNew={openCreating}
        isFetchingSearchResults={isSearchingLoading}
      />
      <Divider className="grui mt-4" />
      <Flex className="grui p-10 justify-end">
        <Button primary size="small" onClick={openCreating}>
          Create user
        </Button>
        <Button className="grui ml-10" basic onClick={onCancel} size="small">
          Cancel
        </Button>
      </Flex>
    </StyledContainer>
  )
}
