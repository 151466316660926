import React from 'react'
import cn from 'classnames'
import { css } from '@emotion/react'
import { IconButton } from 'shared/ui'
import { propFunc } from 'util/functions'

const StarStyle = () => theme => css`
  &&:not(.disabled).star:hover svg path {
    fill: ${theme.color.primary.warning};
  }

  &&:not(.disabled).starred svg path,
  &&:not(.disabled).starred:hover svg path {
    fill: ${theme.color.primary.warning};
  }
`

const BulkStarIcon = ({
  areAllSelectedStarred,
  count,
  iconSize,
  onStarClick,
}) => {
  return (
    <IconButton
      tooltip={`${
        areAllSelectedStarred ? 'Unstar' : 'Star'
      } all selected items`}
      name={areAllSelectedStarred ? 'starSelected' : 'star'}
      onClick={propFunc(onStarClick, count, areAllSelectedStarred)}
      size="medium"
      iconSize={iconSize}
      active={areAllSelectedStarred}
      css={StarStyle}
      className={cn('star', { starred: areAllSelectedStarred })}
      svg
    />
  )
}
export default BulkStarIcon
