import WidgetCardModal from '../../../WidgetCardModal'
import CompanyForm from './CompanyForm'

export default function CompanyHeaderFieldsEditModal({
  doRecalcMenuPosition,
  domains,
  menuRef,
  name = '',
  offset,
  onCancel,
  onSave,
}) {
  return (
    <WidgetCardModal
      noCurtain={false}
      onBackgroundClick={onCancel}
      offset={offset}
      contentRef={menuRef}
    >
      <CompanyForm
        doRecalcMenuPosition={doRecalcMenuPosition}
        domains={domains}
        name={name}
        onCancel={onCancel}
        onSave={onSave}
      />
    </WidgetCardModal>
  )
}
