import React from 'react'
import { useSelector } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { selectCurrentSubmittedTicketSearchQueryObject } from 'selectors/search/searchFilters'
import { escapeRegExp } from 'util/strings'

// Amend the regex in the defaultFindChunks to only highlight words: https://github.com/bvaughn/highlight-words-core/blob/9285e8adc0a4a679035aafe363b7571da0fff6f0/src/utils.js#L80
const findWords = ({ searchWords, textToHighlight, caseSensitive }) => {
  return searchWords
    .filter(searchWord => searchWord) // Remove empty words
    .reduce((chunks, searchWord) => {
      const regex = new RegExp(
        `(^|\\s)(${escapeRegExp(searchWord)})(?=$|\\s)`,
        caseSensitive ? 'g' : 'gi'
      )

      let match
      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index
        const end = regex.lastIndex
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end })
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          // eslint-disable-next-line no-plusplus
          regex.lastIndex++
        }
      }

      return chunks
    }, [])
}

// Highlight searched keywords in text (not html)
const TextSearchMarker = ({ text }) => {
  const { keywords = [] } = useSelector(
    selectCurrentSubmittedTicketSearchQueryObject
  )
  const textToHighlight = text.replace(/&nbsp;/g, ' ')
  if (!keywords.length) return <span>{textToHighlight}</span>

  return (
    <Highlighter
      textToHighlight={textToHighlight}
      searchWords={keywords}
      autoEscape
      findChunks={findWords}
    />
  )
}

export default TextSearchMarker
