import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsInChat } from 'selectors/location'
import HotkeysGroup from './HotkeysGroup'

const inboxActions = {
  'shift+c': 'Close',
  'shift+o': 'Open',
  '+': 'Star',
  '-': 'Unstar',
  '!': 'Mark as spam',
  g: 'Assign to me',
  a: 'Assign',
  s: 'Snooze',
  t: 'Tag',
  'shift+enter': 'Apply tags (when tags dropdown is open)',
  '.': 'Open more actions',
  m: 'Merge',
  v: 'Move',
}

const chatActions = {
  'shift+c': 'Close',
  'shift+o': 'Open',
  '+': 'Star',
  '-': 'Unstar',
  '!': 'Mark as spam',
  g: 'Assign to me',
  a: 'Assign',
  s: 'Snooze',
}

export default function Actions() {
  const isInChat = useSelector(selectIsInChat)
  return (
    <HotkeysGroup
      title="Actions (current or selected)"
      hotkeys={isInChat ? chatActions : inboxActions}
    />
  )
}
