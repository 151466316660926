export const shopifyChangeText = changeType => {
  if (changeType === 'order_canceled') return 'canceled order'
  if (changeType === 'order_created') return 'created order'
  if (changeType === 'order_duplicated') return 'duplicated order'
  if (changeType === 'order_refunded') return 'refunded order'
  if (changeType === 'draft_order_invoice_sent') {
    return 'sent an invoice for draft order'
  }

  return ''
}
