import React from 'react'
import { actionToPath } from 'redux-first-router'
import querySerializer from 'query-string'
import { doOpenNewTicketPage } from 'actions/pages'

export default class MailtoHandler extends React.PureComponent {
  componentDidMount() {
    document.body.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClick)
  }

  handleClick = e => {
    if (
      e.target.nodeName !== 'A' ||
      e.target.protocol !== 'mailto:' ||
      !this.props.hasMailboxAccess
    )
      return

    e.preventDefault()

    const { routesMap } = this.props
    const url = new URL(e.target.href)
    const email = url.pathname
    const path = actionToPath(
      doOpenNewTicketPage({ to: email }),
      routesMap,
      querySerializer
    )

    window.open(path)
  }

  render() {
    return null
  }
}
