import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchAiConversationSuggestions } from 'ducks/ai/operations'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectIsInInbox } from 'selectors/location'
import {
  selectSuggestionsIsLoading,
  selectSuggestionsIsLoaded,
} from 'ducks/ai/selectors/suggestions'
import CollapsableCard from 'components/CollapsableCard'
import { threeStars as Stars } from 'assets/icons/groove/v2'
import { doFetchWalletsForFeature } from 'ducks/wallets/operations'
import { selectAiOverviewWalletsStatus } from 'ducks/wallets/selectors'
import { walletsFeaturesMap } from 'ducks/wallets/contants'
import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'
import { styles } from './styles'
import Content from './Content'

export default function AI({ doHideCard }) {
  const dispatch = useDispatch()
  const ticketId = useSelector(selectCurrentTicketId)
  const isInInbox = useSelector(selectIsInInbox)

  const isLoading = useSelector(state =>
    selectSuggestionsIsLoading(state, ticketId)
  )
  const isLoaded = useSelector(state =>
    selectSuggestionsIsLoaded(state, ticketId)
  )

  const {
    isLoading: walletsIsLoading,
    isLoaded: walletsIsLoaded,
  } = useSelector(selectAiOverviewWalletsStatus)

  const canShowWidgetContent = isInInbox && ticketId !== 'new'

  useEffect(
    () => {
      // Fetch existed suggestions
      if (canShowWidgetContent && !isLoading && !isLoaded) {
        dispatch(doFetchAiConversationSuggestions({ ticketId }))
      }
    },
    [dispatch, isLoading, isLoaded, ticketId, canShowWidgetContent]
  )

  useEffect(
    () => {
      if (canShowWidgetContent && !walletsIsLoading && !walletsIsLoaded) {
        dispatch(
          doFetchWalletsForFeature(walletsFeaturesMap.aiOverview, 'aiOverview')
        )
      }
    },
    [dispatch, walletsIsLoading, walletsIsLoaded, canShowWidgetContent]
  )

  if (!canShowWidgetContent) {
    return null
  }

  return (
    <CollapsableCard
      persistenceKey={'customWidget.ai'}
      title={
        <SortableVisibleControlsWithTitle
          title={
            <div className="grui flex items-center">
              <Stars className="grui mr-5" width="12" height="12" />AI Overview
            </div>
          }
          onHide={doHideCard}
        />
      }
      css={styles.card}
    >
      <Content
        ticketId={ticketId}
        isLoaded={isLoaded}
        isLoading={isLoading !== false || walletsIsLoading}
      />
    </CollapsableCard>
  )
}
