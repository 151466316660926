import React from 'react'

import Sidebar from 'shared/components/ui/Sidebar'

import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Form, Input, Button } from 'util/ui'
import styles from '../styles.less'

export default class CreateContact extends React.PureComponent {
  handleChange = (_, { name, value }) => {
    return this.props.onChange(name, value, null, { validate: true })
  }

  render() {
    const {
      onClose,
      doCreateContact,
      createContactError,
      open,
      formFields,
      formFieldsErrors,
      isFormValid,
    } = this.props
    const { email, firstname, lastname, company, website, phone } = formFields
    return (
      <Sidebar
        headerChildren="Create Lead"
        footerChildren={
          <React.Fragment>
            <Button
              disabled={!isFormValid}
              fluid
              onClick={doCreateContact}
              primary
            >
              Save changes
            </Button>
            <Button fluid onClick={onClose} basic>
              Cancel
            </Button>
          </React.Fragment>
        }
        narrow
        onClose={onClose}
        open={open}
      >
        <Form className={styles.content}>
          <React.Fragment>
            <div className={styles.row} flex="1">
              <Label as="div">Email Address</Label>
              <ValidatedField
                disabled={false}
                fluid
                name="email"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                errorMessage={
                  createContactError.message || formFieldsErrors.email
                }
                value={email || ''}
              />
            </div>

            <div className={styles.row} flex="1">
              <Label as="div">
                First Name <span className={styles.optional}>optional</span>
              </Label>
              <ValidatedField
                disabled={false}
                fluid
                name="firstname"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                value={firstname || ''}
              />
            </div>

            <div className={styles.row} flex="1">
              <Label as="div">
                Last Name <span className={styles.optional}>optional</span>
              </Label>
              <ValidatedField
                disabled={false}
                fluid
                name="lastname"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                value={lastname || ''}
              />
            </div>

            <div className={styles.row} flex="1">
              <Label as="div">
                Phone Number <span className={styles.optional}>optional</span>
              </Label>
              <ValidatedField
                disabled={false}
                fluid
                name="phone"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                value={phone || ''}
              />
            </div>

            <div className={styles.row} flex="1">
              <Label as="div">
                Company <span className={styles.optional}>optional</span>
              </Label>
              <ValidatedField
                disabled={false}
                fluid
                name="company"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                value={company || ''}
              />
            </div>

            <div className={styles.row} flex="1">
              <Label as="div">
                Website <span className={styles.optional}>optional</span>
              </Label>
              <ValidatedField
                disabled={false}
                fluid
                name="website"
                onChange={this.handleChange}
                validatedFieldComponent={Input}
                value={website || ''}
              />
            </div>
          </React.Fragment>
        </Form>
      </Sidebar>
    )
  }
}
