import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { DRAWER_TYPE_AGENT_ADD } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { HIDE_TEAMMATES_CARD } from 'ducks/flags/flagNames'
import { doSetFlag } from 'ducks/flags/operations'
import FeatureCard from './FeatureCard'

const TeammatesCard = () => {
  const dispatch = useDispatch()
  const { openDrawer } = useDrawer({
    type: DRAWER_TYPE_AGENT_ADD,
    lazyLoad: true,
  })
  const handleShowAddTeammatesDrawer = useCallback(
    () => {
      openDrawer('new', {
        additionalProps: { title: `Add ${app.t('Agent')}` },
      })
    },
    [openDrawer]
  )

  const handleCloseCard = useCallback(
    () => {
      dispatch(doSetFlag(HIDE_TEAMMATES_CARD))
    },
    [dispatch]
  )

  return (
    <FeatureCard
      icon="💪"
      title={`Bring on the ${app.t('group')}!`}
      subtitle={`Get your ${app.t(
        'group'
      )} up and running in no time by adding them to your account.`}
      buttonLabel={`Add ${app.t('agents')}`}
      onClickButton={handleShowAddTeammatesDrawer}
      onClose={handleCloseCard}
    />
  )
}

export default TeammatesCard
