import dot from 'dot-object'
import { useCallback, useRef } from 'react'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { Button } from 'shared/ui'
import { formData } from 'util/forms'

export default function CustomFieldEditForm({
  children,
  deletable = true,
  index,
  isArray = false,
  onCancel,
  onSave,
}) {
  const formRef = useRef()
  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      onSave(dot.object(formData(formRef.current)))
      onCancel(event)
    },
    [onCancel, onSave]
  )
  const onDelete = useCallback(
    event => {
      event.preventDefault()
      const data = formData(formRef.current)
      const emptyData = Object.keys(data).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] =
          !isArray || key.match(new RegExp(`[${index}]`)) ? null : data[key]
        return result
      }, {})
      const exploded = dot.object(emptyData)
      onSave(exploded)
      onCancel(event)
    },
    [formRef, index, isArray, onCancel, onSave]
  )
  return (
    <form className="ui form" onSubmit={onSubmit} ref={formRef}>
      <ListenToKeyboard onCmdEnter={onSubmit} />
      {children}
      <div className="buttons">
        <div className="left">
          {deletable && (
            <Button as="div" color="red" basic onClick={onDelete} size="small">
              Delete
            </Button>
          )}
        </div>
        <div className="right">
          <Button primary size="small">
            Save
          </Button>
          {onCancel && (
            <Button as="div" basic onClick={onCancel} size="small">
              Cancel
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}
