import React from 'react'
import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import { Label } from 'shared/ui'

import Section from '../Section'
import Properties from './Properties'

export default class View extends React.PureComponent {
  render() {
    const { label, properties, settings, specificFieldsPattern } = this.props
    if (specificFieldsPattern) {
      const pattern = new RegExp(specificFieldsPattern)
      const keys = extractSpecificFields(settings, pattern)
      if (!keys) return null
      if (keys.length === 0) return null
    }

    return (
      <Section>
        <Label size="small">{label}</Label>
        <Properties
          properties={properties}
          settings={settings}
          specificFieldsPattern={specificFieldsPattern}
        />
      </Section>
    )
  }
}
