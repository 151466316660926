import React from 'react'
import styled from '@emotion/styled'
import { Label } from 'shared/components/ui/Typography'

import Date from './Date'
import Time from './Time'

const Container = styled(Label)`
  display: flex;
  align-items: baseline;
  white-space: pre;
`

const At = styled('div')`
  white-space: pre;
  transform: translateY(-1px);
`

export default function DateTime({ date, timestamp, className }) {
  const dateWithFallback = date || timestamp
  return (
    <Container size="medium" className={className} color="ultraLight">
      <Date date={dateWithFallback} />
      <At> @ </At>
      <Time date={dateWithFallback} />
    </Container>
  )
}
