import { css, keyframes } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const typing = keyframes`
   0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
`

const container = css`
  margin-left: ${rem(10)};
  display: flex;
`

const avatarWrapper = theme => css`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  padding: 2px;
  border-radius: 50%;
  background-color: ${theme.color.monochrome.white};
  position: relative;
  &:not(:last-of-type) {
    margin-right: ${rem(-20)};
  }
  &:nth-of-type(1) {
    z-index: 2;
  }
  &:nth-of-type(2) {
    z-index: 1;
  }
`

const viewing = theme => css`
  border-color: ${theme.color.primary.positive};
`

const noting = theme => css`
  border-color: ${theme.color.primary.warning};
`

const replying = theme => css`
  border-color: ${theme.color.primary.negative};
`

const typingNote = theme => css`
  ${noting(theme)};
`

const typingReply = theme => css`
  ${replying(theme)};
`

const types = {
  viewing,
  noting,
  replying,
  typingNote,
  typingReply,
}

const tooltipBg = theme => css`
  background-color: ${theme.color.info['900']};
  z-index: 1002;
`

const agents = css`
  display: flex;
  margin: 0 ${rem(2)};
`

const typingIndicator = theme => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${rem(32)};
  height: ${rem(32)};
  border-radius: 50%;
  position: absolute;
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.1);
  &::before,
  &::after {
    content: '';
  }
  span,
  &::before,
  &::after {
    display: inline-block;
    background-color: ${theme.color.monochrome.white};
    width: 5px;
    height: 5px;
    border-radius: 50%;
    animation: ${typing} 1s infinite ease-in-out;
    margin: 0 1px;
  }
  &:before {
    animation-delay: 0.2s;
  }
  span {
    animation-delay: 0.4s;
  }
  &:after {
    animation-delay: 0.6s;
  }
`

export const styles = {
  container,
  avatarWrapper,
  types,
  tooltipBg,
  agents,
  typingIndicator,
}
