import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import KeyboardNavigator from 'components/KeyboardNavigator'
import { withFormFields, Divider, SUI, Button, Flex } from 'shared/ui'

import ValidatedField from 'shared/components/ui/ValidatedField'

import { Form } from 'util/ui'
import { minLength } from 'util/validation'

import Header from './Header'
import ColorPicker from '../../shared/ColorPickerIcon'

const defaultColor = '#8573BF'

const CreatePane = ({
  className,
  doCancelCreateLabel,
  formFields,
  formFieldsErrors,
  formSubmitting,
  submitForm,
  onChange,
}) => {
  const color = formFields.color || defaultColor

  const onFieldChange = useCallback(
    e => {
      onChange('name', e.persist() || e.target.value, minLength(1))
    },
    [onChange]
  )

  return (
    <div className={className}>
      <KeyboardNavigator.Focus />
      <SUI>
        <Header text={'Create a tag'} />

        <Flex className="grui mt-10 mx-12">
          <ColorPicker color={color} onSubmit={onChange} />
          <Form.Field>
            <ValidatedField
              autoFocus
              defaultValue={formFields.name}
              errorMessage={formFieldsErrors.name}
              onChange={onFieldChange}
              placeholder={'Tag name...'}
            />
          </Form.Field>
        </Flex>

        <Divider className="grui mt-12" />

        <Flex className="grui mx-12 my-10 justify-end">
          <Button basic size="small" onClick={doCancelCreateLabel}>
            Cancel
          </Button>
          <Button
            disabled={!formFields.name || formSubmitting}
            className="grui ml-4"
            size="small"
            primary
            onClick={submitForm}
          >
            Create a tag
          </Button>
        </Flex>
      </SUI>
    </div>
  )
}

const Styled = styled(withFormFields(CreatePane))`
  &&& {
    .separator {
      border-top: 1px solid var(--color-monochrome-medium);
      height: 1px;
    }

    .field {
      padding-left: 8px;
      width: 100%;

      .ui.input {
        width: 100%;
      }
    }
  }
`

export default Styled
