import styled from '@emotion/styled'

export default styled('div')`
  margin: 0 -${props => props.theme.spacing['7']};
  padding: ${props => props.theme.spacing['7']};
  border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};

  &:first-of-type {
    margin-top: -${props => props.theme.spacing['7']};
  }
  &:last-child {
    border-bottom: 0;
    margin-bottom: -${props => props.theme.spacing['7']};

    .NavigatableIntegrationWidgetCard-List {
      margin-bottom: -13px;
    }
  }
  &.flatBottom {
    padding-bottom: 0;
    border-bottom: 0;
    .NavigatableIntegrationWidgetCard-List {
      margin-bottom: 0;
    }
  }
`
