import React from 'react'
import KeyboardNavigator from 'components/KeyboardNavigator'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { ENTER, TAB } from 'constants/keys'
import Item from './Item'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }

const Menu = ({ children, className }) => {
  return (
    <ListenToKeyboard hijack={ListenToKeyboard.DROPDOWN}>
      <KeyboardNavigator
        keys={KEYBOARD_NAVIGATOR_KEYS}
        supressDuplicateActivate
      >
        <div role="menu" className={className}>
          {children}
        </div>
      </KeyboardNavigator>
    </ListenToKeyboard>
  )
}

Menu.Item = Item

export default Menu
