import { useCallback } from 'react'
import { Icon, Menu } from 'util/ui'

import SearchingState from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/Customer/ChangeUser/Menu/SearchingState'
import CreatingNewState from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/Customer/ChangeUser/Menu/CreatingNewState'

import WidgetCardModal from '../../../WidgetCardModal'
import { useChangeOrCreateContact } from './hooks'

export default function ChangeContactButton({
  doSetMenu,
  menuRef,
  offset,
  openMenu,
  subjectId,
}) {
  const handleOpenMore = useCallback(() => doSetMenu('more'), [doSetMenu])
  const handleOpenChange = useCallback(() => doSetMenu('change-contact'), [
    doSetMenu,
  ])
  const handleOpenCreate = useCallback(() => doSetMenu('create-contact'), [
    doSetMenu,
  ])
  const {
    isCreating,
    isSearchingLoading,
    isSearchingError,
    contacts,
    onClose,
    onCreate,
    onSearch,
    onSelect,
    searchTerm,
    initialValues,
  } = useChangeOrCreateContact({
    contactId: subjectId,
    onClose: useCallback(() => doSetMenu(null), [doSetMenu]),
  })
  return (
    <>
      <div className="action" onClick={handleOpenMore}>
        <Icon name="ellipsis vertical" size="small" />
      </div>
      {openMenu === 'more' && (
        <WidgetCardModal
          padded="4px 12px"
          contentRef={menuRef}
          offset={offset}
          onBackgroundClick={onClose}
          width={200}
        >
          <Menu text vertical>
            <Menu.Item name="Change user" onClick={handleOpenChange} />
          </Menu>
        </WidgetCardModal>
      )}
      {['change-contact', 'create-contact'].includes(openMenu) && (
        <WidgetCardModal
          contentRef={menuRef}
          noCurtain={false}
          offset={offset}
          onBackgroundClick={onClose}
          padded={false}
        >
          {openMenu === 'change-contact' && (
            <SearchingState
              isSearchingLoading={isSearchingLoading}
              isSearchingError={isSearchingError}
              onCancel={onClose}
              openCreating={handleOpenCreate}
              onSearch={onSearch}
              onSelect={onSelect}
              users={contacts}
              searchTerm={searchTerm}
            />
          )}
          {openMenu === 'create-contact' && (
            <CreatingNewState
              initialValues={initialValues}
              closeForm={handleOpenChange}
              saving={isCreating}
              onSave={onCreate}
            />
          )}
        </WidgetCardModal>
      )}
    </>
  )
}
