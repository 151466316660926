import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const title = css`
  margin-bottom: ${rem(14)};
`

const closeButton = css`
  position: fixed;
  top: ${rem(7)};
  right: ${rem(10)};
`

const columns = css`
  white-space: nowrap; // no items should span more than one line

  & > * {
    flex: 1 1 0;
  }

  & > * + * {
    margin-left: ${rem(50)};
  }
`

const contentContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & > * {
    flex-shrink: 0;
  }
`

const hotkeysModalContainer = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(50)};
  background-color: hsla(0, 0%, 100%, 0.96);
  overflow: auto;
  z-index: 1000000;
`

const hotkeysGroupContainer = css`
  & + & {
    margin-top: ${rem(14)};
  }
`

const key = css`
  margin-right: ${rem(10)};
  font-family: monospace;
`

export const styles = {
  title,
  closeButton,
  columns,
  contentContainer,
  hotkeysModalContainer,
  hotkeysGroupContainer,
  key,
}
