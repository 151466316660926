/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { string, oneOfType, node, number, shape, bool } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { shopifyMoneyString, currencyCodeToSymbol } from 'util/currency'

const AddShipping = ({
  children,
  currency,
  shipping,
  onShippingChange,
  calculatedDraft,
  disabled,
}) => {
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState(shipping.type)
  const [fee, setFee] = useState(shipping.fee)
  const [customTitle, setCustomTitle] = useState('')
  const [customFee, setCustomFee] = useState(0)
  const { availableShippingRates = [] } = calculatedDraft || {}

  const currencySymbol = currencyCodeToSymbol(currency)

  const shippingRatesByHandle = useMemo(
    () => {
      const object = {}
      availableShippingRates.map(rate => (object[rate.handle] = rate))
      return object
    },
    [availableShippingRates]
  )

  useEffect(
    () => {
      if (!type) {
        if (shipping.type !== type) setType(shipping.type)
        if (shipping.fee !== fee) {
          setFee(shipping.fee)
          setCustomFee(shipping.fee)
        }
        if (shipping.type === 'custom' && shipping.title !== customTitle) {
          setCustomTitle(shipping.title)
        }
      }
    },
    [
      shipping,
      setType,
      setFee,
      setCustomTitle,
      setCustomFee,
      type,
      fee,
      customTitle,
    ]
  )

  const handleVisibleChange = useCallback(
    isVisible => {
      setVisible(isVisible)
    },
    [setVisible]
  )

  const handleCustomFeeChange = useCallback(
    e => {
      const value = e.target.value
      setCustomFee(value)
      if (type === 'custom') {
        setFee(value)
      }
    },
    [setCustomFee, setFee, type]
  )

  const handleCustomTitleChange = useCallback(
    e => {
      const value = e.target.value
      setCustomTitle(value)
    },
    [setCustomTitle]
  )

  const handleCancel = useCallback(() => setVisible(false), [setVisible])

  const handleSave = useCallback(
    () => {
      const payload = { type, fee, title: customTitle }

      onShippingChange(payload)
      setVisible(false)
    },
    [onShippingChange, type, fee, customTitle]
  )

  const handleChangeShippingType = useCallback(
    e => {
      const newValue = e.target.value
      setType(newValue)
      const found = shippingRatesByHandle[newValue]
      if (found) {
        setFee(found.price.amount * 1)
      } else if (newValue === 'free') {
        setFee(0)
      } else if (newValue === 'custom') {
        setFee(customFee)
      }
      // TODO
    },
    [customFee, setType, setFee, shippingRatesByHandle]
  )

  const footer = (
    <>
      <Button type="tertiary" size="small" onClick={handleCancel}>
        Cancel
      </Button>
      <Button size="small" onClick={handleSave}>
        Apply
      </Button>
    </>
  )

  const overlay = useMemo(
    () => {
      return (
        <div className="grui px-7 pt-8 pb-10 text-left">
          {availableShippingRates.map(rate => {
            return (
              <div className="grui mb-5" key={rate.handle}>
                <Radio
                  id={`shipping-${rate.handle}`}
                  name="shipping"
                  value={rate.handle}
                  checked={type === rate.handle}
                  onChange={handleChangeShippingType}
                >
                  {rate.title}
                </Radio>
                <br />
                <span css={text.styles.textMediumDark} className="grui pl-12">
                  {shopifyMoneyString(rate.price)}
                </span>
              </div>
            )
          })}
          <Radio
            id="free-shipping"
            name="shipping"
            className="grui mb-5"
            value="free"
            onChange={handleChangeShippingType}
            checked={type === 'free'}
          >
            Free shipping
            <br />
          </Radio>
          <div>
            <Radio
              id="custom-shipping"
              name="shipping"
              className="grui mb-5"
              value="custom"
              onChange={handleChangeShippingType}
              checked={type === 'custom'}
            >
              Custom
            </Radio>
            {type === 'custom' && (
              <div className="grui pl-12">
                <Field
                  className="grui mb-4"
                  placeholder="Custom rate name"
                  value={customTitle}
                  onChange={handleCustomTitleChange}
                />
                <Field
                  tagPosition="left"
                  tagContent={currencySymbol}
                  value={customFee}
                  onChange={handleCustomFeeChange}
                />
              </div>
            )}
          </div>
        </div>
      )
    },
    [
      currencySymbol,
      handleChangeShippingType,
      type,
      customFee,
      handleCustomFeeChange,
      availableShippingRates,
      customTitle,
      handleCustomTitleChange,
    ]
  )

  return (
    <Dropdown
      overlay={overlay}
      footer={footer}
      footerWithBtns
      hasMinWidth
      autoHeight
      visible={visible}
      onVisibleChange={handleVisibleChange}
      disabled={disabled}
    >
      {children}
    </Dropdown>
  )
}

AddShipping.propTypes = {
  children: oneOfType([string, node]),
  currency: string,
  shipping: shape({
    type: string,
    value: number,
  }),
  disabled: bool,
}

AddShipping.defaultProps = {
  children: undefined,
  currency: '',
  shipping: {},
  onShippingChange() {},
  disabled: false,
}

export default AddShipping
