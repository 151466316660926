import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomFieldValueForCustomFieldKeyAndCategoryType } from 'ducks/crm/contacts'
import { selectCustomFieldsByKey } from 'ducks/crm/customFields/selectors'
import CustomFieldKeyValue from '../../CustomFieldKeyValue'

export default memo(IntegrationCustomField)

function IntegrationCustomField({
  categoryType,
  isVisible,
  onHideItem,
  onShowItem,
  isInEditMode,
  itemKey,
  subjectId,
  componentOverides = {},
  missingValueText,
}) {
  const customFieldsByKey = useSelector(selectCustomFieldsByKey)
  const customField = customFieldsByKey && customFieldsByKey[itemKey]

  const {
    key: customFieldKey,
    name: customFieldName,
    options,
    type: customFieldType,
  } =
    customField || {}

  const { value: customFieldValue } =
    useSelector(state =>
      selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
        customFieldKey,
        categoryType,
      })
    ) || {}

  const onEye = useCallback(
    () => {
      if (isVisible) {
        onHideItem(itemKey)
      } else {
        onShowItem(itemKey)
      }
    },
    [onHideItem, itemKey, isVisible, onShowItem]
  )

  const componentData = componentOverides[itemKey]
  if (componentData) {
    const Component = componentData.component || CustomFieldKeyValue
    return (
      <Component
        fieldKey={customFieldKey}
        fieldHidden={!isVisible}
        isEditable={false}
        isInEditMode={isInEditMode}
        options={options}
        onEye={onEye}
        subjectId={subjectId}
        title={componentData.title || customFieldName}
        type={componentData.type || customFieldType}
        value={componentData.value || customFieldValue}
        missingValueText={missingValueText}
      />
    )
  }

  return (
    <CustomFieldKeyValue
      fieldKey={customFieldKey}
      fieldHidden={!isVisible}
      icon={null}
      isEditable={false}
      isInEditMode={isInEditMode}
      options={options}
      onEye={onEye}
      subjectId={subjectId}
      title={customFieldName}
      type={customFieldType}
      value={customFieldValue}
      missingValueText={missingValueText}
    />
  )
}
