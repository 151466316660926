import { extension } from 'mime-types'
import { MAX_PREVIEWABLE_IMAGE_SIZE } from 'util/attachments'

import { styles, extentionStyles } from './styles'

const extentionStylesMap = {
  pdf: extentionStyles.pdf,
  doc: extentionStyles.doc,
  docx: extentionStyles.doc,
  xls: extentionStyles.xls,
  xlsx: extentionStyles.xls,
}

export default function FileIcon({
  fileType,
  fileName,
  url,
  onClick,
  disabled = false,
  isImage,
  fileSize,
}) {
  const fileExtension = extension(fileType)
  const isLargeImage = isImage && fileSize > MAX_PREVIEWABLE_IMAGE_SIZE

  // So it turns out all webkit engine browsers (Chrome, Opera, Edge)
  // all shit the bed when a very high resolution image is added to img tag.
  // Works fine on Firefox
  // disable image previews for attachments > limit
  // a better solution would be to get the resolution using the js Image class,
  // but that just means all images will be preloaded + extra processing
  // also using loading="lazy" attribute does not fix this
  if (/png|jpg|jpeg/.test(fileExtension) && !isLargeImage) {
    return <img src={url} alt={fileName} css={styles.image} onClick={onClick} />
  }

  const content = (
    <div css={isImage ? styles.imageWrapper : null} onClick={onClick}>
      <div css={styles.placeholder} />
      <div css={[styles.extension, extentionStylesMap[fileExtension]]}>
        {fileExtension}
      </div>
    </div>
  )

  if (/pdf/.test(fileExtension)) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        disabled={disabled}
      >
        {content}
      </a>
    )
  }

  return content
}
