import { useSelector } from 'react-redux'

import logo from 'assets/integrations/salesforce/icon-256.png'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'
import { selectIntegrationSettingsForId } from 'ducks/integrations/selectors'
import { selectObjectByEmail } from 'ducks/integrations/salesforce/selectors'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import SalesforceDetail from './SalesforceDetail'
import SalesforceIndex from './SalesforceIndex'

export default function SalesforceWidgetCard({ id, doHideCard }) {
  const email = useSelector(selectCurrentContactOrCustomerEmail)
  const object = useSelector(state => selectObjectByEmail(state, email))
  const settings = useSelector(state => {
    return selectIntegrationSettingsForId(state, id)
  })
  return (
    <NavigatableIntegrationWidgetCard
      customerId={email} // withCardDataLoading uses custom Id internally
      email={email}
      detailComponent={SalesforceDetail}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={SalesforceIndex}
      object={object}
      title="Salesforce"
      persistenceKey="salesfroce"
      settings={settings}
      unmountOnExit={false}
    />
  )
}
