import Menu from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenu'
import { styles } from './styles'
import ProductItem from './ProductItem'

const ProductSearchMenu = ({ results, orderItems, currencyCode }) => {
  return (
    <Menu css={styles.menu}>
      {results.map(({ variant, product }) => (
        <ProductItem
          key={variant.id}
          name={
            product.hasOnlyDefaultVariant ? product.title : variant.displayName
          }
          // not gonna use hasOnlyDefaultVariant check for this because variant could have no image but product does
          // use variant image with fallback to product image
          image={variant?.image?.url || product?.featuredImage?.url}
          sku={variant.sku}
          stock={variant.inventoryQuantity}
          currency={currencyCode}
          price={variant.price}
          orderItems={orderItems}
          variantId={variant.id}
        />
      ))}
    </Menu>
  )
}

export default ProductSearchMenu
