import React from 'react'

import themeVars from 'ui_theme/site/globals/site.variables'

import LinkExistingTrelloCardModalView from './view'

export default class LinkExistingTrelloCardModal extends React.PureComponent {
  componentWillUnmount = () => {
    clearTimeout(this.formResetTimeout)
  }

  doCloseModal = () => {
    this.formResetTimeout = setTimeout(
      this.props.resetForm,
      themeVars.defaultDuration // Allow sidebar close animation to complete
    )
    this.props.doCloseModal()
  }

  handleChange = (_, { name, value }) => {
    const {
      props: { doFetchTrelloBoardDetails, doFetchTrelloCardsForList, onChange },
    } = this
    if (name === 'board') doFetchTrelloBoardDetails(value)
    if (name === 'list') doFetchTrelloCardsForList(value)
    return onChange(name, value, null, { validate: true })
  }

  render = () => {
    return (
      <LinkExistingTrelloCardModalView
        {...this.props}
        doCloseModal={this.doCloseModal}
        onChange={this.handleChange}
      />
    )
  }
}
