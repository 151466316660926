import { css } from '@emotion/react'
import { button as buttonElement } from '@groovehq/internal-design-system/lib/styles/elements'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { themeValLookup } from '@groovehq/internal-design-system/lib/util/theme'

const itemSpacing = 4

const button = css`
  ${buttonElement.styles.preflight};
  font-weight: inherit;
  display: flex;
  align-items: center;

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    transform: rotate(90deg);
    transition: transform 0.2s;
    margin-top: 1px;
  }

  &.open svg {
    transform: rotate(-90deg);
  }
`

const dropdownContainer = theme => css`
  && {
    box-shadow: 0 ${rem(8)} ${rem(16)} 0
      rgba(${theme.color.monochrome.black_rgba}, 0.1);
    border-radius: ${rem(12)};
  }
`

const menuContainer = css`
  max-width: ${rem(340)};
  margin: 0 ${rem(-itemSpacing)};
`

const menu = css`
  padding: ${rem(12)};
  padding-top: ${rem(4)};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  [role='menuitem'] {
    margin: ${rem(8)} ${rem(itemSpacing)} 0;
  }
`

const multipleColsMenuContainer = css`
  max-width: ${rem(700)};

  [role='menu'] {
    flex-direction: row;
    [role='menuitem'] {
      width: calc(50% - ${rem(itemSpacing * 2)});
    }
  }
`

const menuItem = ({ theme, activeColor }) => css`
  display: flex;
  align-items: flex-start;
  transition: 0.2s background-color;
  padding: ${rem(8)};
  padding-bottom: ${rem(12)};
  border-radius: ${rem(4)};
  text-align: left;

  .menu-item-icon {
    background-color: ${themeValLookup(activeColor)({ theme }) || activeColor};
  }

  &:hover,
  &.active,
  &.open {
    background-color: ${themeValLookup(activeColor)({ theme }) || activeColor};

    .menu-item-icon {
      background-color: rgba(${theme.color.monochrome.white_rgba}, 0.8);
    }
  }
`

const menuItemIcon = theme => css`
  width: ${rem(48)};
  height: ${rem(48)};
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background-color;
  color: ${theme.color.info['900']};

  svg {
    height: 100%;
    width: 100%;
    &.sm-icon {
      margin: ${rem(8)};
    }
    &.md-icon {
      margin: ${rem(3)};
    }
  }
`

const recommendedIndicator = theme => css`
  background-color: ${theme.color.primary.info};
  color: ${theme.color.monochrome.white};
  font-size: ${theme.fontSize.small};
  padding: ${rem(3)} ${rem(5)} ${rem(3)} ${rem(7)};
  border-radius: ${rem(4)} 0 0 ${rem(4)};
  position: absolute;
  right: ${rem(-8)};
  top: ${rem(8)};
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    bottom: ${rem(-6)};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${rem(6)} ${rem(8)} 0 0;
    border-color: ${theme.color.info['200']} transparent transparent transparent;
  }

  strong {
    font-weight: ${theme.fontWeight.bold};
  }
`

const footer = theme => css`
  margin: 0 ${rem(itemSpacing)};
  border-top: 1px solid ${theme.color.monochrome.medium};

  strong {
    font-weight: ${theme.fontWeight.bold};
  }

  a:hover {
    text-decoration: underline;
  }
`

export const styles = {
  button,
  dropdownContainer,
  menuContainer,
  menu,
  multipleColsMenuContainer,
  menuItem,
  menuItemIcon,
  recommendedIndicator,
  footer,
}
