import React from 'react'
import { Label } from 'shared/ui'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Help as HelpIcon } from '@groovehq/internal-design-system/lib/assets/icons'
import { css } from '@emotion/react'

const labelStyle = theme => css`
  color: ${theme.color.grey[500]};
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
`

const helpIconStyle = theme => css`
  font-size: 14px;
  color: ${theme.color.monochrome.mediumDark};
`

const labelWrapperStyle = theme => css`
  display: flex;
  justify-content: space-between;

  padding: 12px;
  align-items: flex-end;
  color: ${theme.color.monochrome.mediumDark};
`

const containerStyle = theme => css`
  border-radius: 10px;
  margin: 12px 10px;
  background-color: ${theme.color.monochrome.light};
  overflow: hidden;
  border: 1px solid ${theme.color.monochrome.light};
`

const childrenWrapperStyle = theme => css`
  background-color: ${theme.color.monochrome.white};
`

export default function StripeCustomerDetail({ customerId, children }) {
  return (
    <div css={containerStyle}>
      <div css={labelWrapperStyle}>
        <Label css={labelStyle} size={10} as="div">
          {customerId?.toUpperCase()}
        </Label>
        <Tooltip title="Multiple customer records found" position="top">
          <HelpIcon css={helpIconStyle} />
        </Tooltip>
      </div>
      <div css={childrenWrapperStyle}>{children}</div>
    </div>
  )
}
