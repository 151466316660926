import React from 'react'
import styled from '@emotion/styled'

import { SUI, Button, Header, SubHeader } from 'shared/ui'
import { pluralize } from 'util/strings'
import { propFunc } from 'util/functions'

const DeletePane = ({
  className,
  onCancel,
  onDelete,
  label: { id, name, labelingsCount: count },
}) => {
  return (
    <div className={className}>
      <Header as="h1">Delete Tag</Header>
      <SubHeader as="h3" size="small">
        &quot;{name}&quot; has {count} {pluralize(count, 'conversation')}{' '}
        associated with it. Deleting this tag will remove it from all
        conversations.
      </SubHeader>
      <SubHeader as="h3" size="small">
        Are you sure you would like to continue?
      </SubHeader>
      <div className="buttons">
        <SUI>
          <Button color="brown" onClick={propFunc(onDelete, id)}>
            {"I'm sure, delete this tag"}
          </Button>
          <Button basic className="grui ml-10" onClick={onCancel}>
            Cancel
          </Button>
        </SUI>
      </div>
    </div>
  )
}

const Styled = styled(DeletePane)`
  &&& {
    font-size: 14px;
    width: 380px;

    .buttons {
      padding: 16px 0px;
    }
  }
`

export default Styled
