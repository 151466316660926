import { doNavigateToTicket } from 'actions/navigation'
import cn from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { doOpenRoomPageById } from 'ducks/chat/actions/rooms'
import { check, snooze, trash } from 'assets/icons/groove/v2'
import { CONVERSATION, ROOM } from 'ducks/integrations/recentConversations'
import { timeInHuman } from 'util/date'
import { titleize } from 'util/strings'
import { withTicketPreview } from 'ducks/ticketing/components'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

const ICON_MAP = {
  closed: check,
  deleted: trash,
  opened: undefined,
  snoozed: snooze,
  unread: 'div',
}

const Preview = withTicketPreview(
  ({
    delayedShowTicketPreviewModal,
    cancelDelayedShowTicketPreviewModal,
    className,
    children,
  }) => {
    return (
      <div
        onMouseMove={delayedShowTicketPreviewModal}
        onMouseLeave={cancelDelayedShowTicketPreviewModal}
        onClick={cancelDelayedShowTicketPreviewModal}
        className={className}
      >
        {children}
      </div>
    )
  }
)

export default function RecentConversation({
  conversation: {
    assignee,
    isCurrent,
    number,
    state,
    title,
    type,
    updatedAt,
    from,
  },
}) {
  const dispatch = useDispatch()
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const onClick = useCallback(
    () => {
      if (type === ROOM) dispatch(doOpenRoomPageById(number))
      if (type === CONVERSATION) dispatch(doNavigateToTicket(number))
    },
    [dispatch, number, type]
  )
  const Icon = ICON_MAP[state]
  return (
    <a className={cn('ticket', { current: isCurrent })} onClick={onClick}>
      <div className="assigneeAndUpdatedAt">
        {assignee ? (
          <span className="assignee">{`@${assignee}`}</span>
        ) : (
          <span>Unassigned</span>
        )}
        <span>
          {updatedAt && ` · ${timeInHuman(updatedAt)}`} via {titleize(from)}
        </span>
      </div>
      <div className="titleAndState">
        <Preview
          ticketId={number}
          className="title"
          eventOffsetRight={30}
          disabled={type === ROOM || !isAdminOrOwnerOrAgent}
        >
          <Tooltip
            position="top-start"
            title={
              <>
                Previews for live chat<br />conversations coming soon
              </>
            }
            disabled={type !== ROOM}
          >
            <span>{title || <em>No Subject</em>}</span>
          </Tooltip>
        </Preview>
        <div className="state">{Icon && <Icon className={state} />}</div>
      </div>
    </a>
  )
}
