import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_AUTHENTICATE } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import NoticeBar from 'components/NoticeBar'
import { selectWidgetFromUrl } from 'ducks/widgets/selectors/widgets'
import { buildId } from 'util/globalId'

import styles from './styles.less'

export default function MessengerDisconnected({ isManager }) {
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
    closeIgnoresStack: true,
  })
  const widget = useSelector(selectWidgetFromUrl)

  const { id: currentChannelId, name, kind } = widget

  const onOpen = useCallback(
    () => {
      openAddDrawer(buildId('Widget', currentChannelId), {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', kind),
          ...buildDrawerQueryParam(drawerId, 'drawerSource', kind),
          ...buildDrawerQueryParam(drawerId, 'drawerAction', 'reconnect'),
        },
      })
    },
    [openAddDrawer, currentChannelId, drawerId, kind]
  )

  let callToActionContent =
    'Please contact your administrator to reconnect your Facebook Page now.'
  if (isManager) {
    callToActionContent = (
      <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
        Please reconnect the channel now.
      </a>
    )
  }

  return (
    <NoticeBar type="warning">
      Oh no, looks like the Facebook Page has been disconnected from the channel{' '}
      {name}!&nbsp;
      {callToActionContent}
    </NoticeBar>
  )
}
