import KeyboardNavigator from 'components/KeyboardNavigator'
import { propFunc } from 'util/functions'

import { styles } from './styles'

const Item = ({ itemKey, children, onSelect, className }) => {
  return (
    <KeyboardNavigator.Item
      css={styles.item}
      onSelect={propFunc(onSelect, itemKey)}
      role="menuitem"
      className={className}
      active={false}
    >
      {children}
    </KeyboardNavigator.Item>
  )
}

export default Item
