export const UNREAD = 'unread'
export const OPENED = 'opened'
export const FOLLOW_UP = 'follow_up'
export const PENDING = 'pending'
export const CLOSED = 'closed'
export const SPAM = 'spam'
export const TRASH = 'trash'

export const STATE_INT_MAP = {
  [UNREAD]: 1000,
  [OPENED]: 2000,
  [FOLLOW_UP]: 3000,
  [PENDING]: 4000,
  [CLOSED]: 5000,
  [SPAM]: 6000,
  [TRASH]: 7000,
}

export const INT_STATE_MAP = {
  1000: UNREAD,
  2000: OPENED,
  3000: FOLLOW_UP,
  4000: PENDING,
  5000: CLOSED,
  6000: SPAM,
  7000: TRASH,
}
