import { connect } from 'react-redux'
import { selectAccount, oauthTokenSelector } from 'selectors/app'
import { selectIsBillingAccountActive } from 'ducks/billing/selectors/selectIsBillingAccountActive'
import LegacyLink from './view'

const select = state => ({
  isAccountExpired: !selectIsBillingAccountActive(state),
  subdomain: selectAccount(state).subdomain,
  token: oauthTokenSelector(state),
})

export default connect(select)(LegacyLink)
