import cn from 'classnames'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

import { useReorderingAndVisibility } from 'components/ReorderableAndHideableComponents/hooks'
import Tabs from 'components/Tabs'
import { doFetchCurrentUserAgentPreferences } from 'ducks/agentPreferences'
import { selectCurrentContactId } from 'ducks/crm/contacts/selectors'
import { doFetchCurrentContact } from 'ducks/crm/contacts/operations/fetching/doFetchCurrentContact'
import { selectCurrentCompanyId, doFetchCompany } from 'ducks/crm/companies'
import { selectCurrentMatrixUserId } from 'ducks/chat/selectors/users'
import { doBootstrapCustomFieldsAndCategories } from 'ducks/crm/customFields/operations/fetching'
import { selectCurrentCustomerId } from 'selectors/tickets/customer/selectCurrentCustomerId'
import themeVars from 'ui_theme/site/globals/site.variables'
import { useActionEffect, useIsScrolled } from 'util/hooks'

import {
  selectDefaultContactDetailsColumnWidgetCardOrderAndVisibility,
  selectDefaultPriorityContactDetailsColumnWidgetCardOrderAndVisibility,
} from './WidgetCards/selectors'
import ContactDetailsColumnHeader from './ContactDetailsColumnHeader'
import EditModeControlWindow from './EditModeControlWindow'
import { selectIsContactDetailsColumnLoading } from './selectors'

import WidgetCards from './WidgetCards'

const TWO_MINUTES = 2 * 60 * 1000
export default styled(ContactDetailsColumn)`
  position: relative;
  height: 100%;
  ${Tabs.Header} {
    border-color: ${props => props.theme.color.monochrome.medium};
  }

  .ContactDetailsColumnOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.2);
  }

  .EditModeControlWindow {
    position: relative;
    width: 300px;
    left: -308px;
    top: 8px;
    border-radius: ${themeVars.defaultBorderRadius};
    background: white;
    box-shadow: ${themeVars.floatingShadow};
    overflow: hidden;
    z-index: 100;

    .EditModeControlWindow-title {
      border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
      padding: 16px;
    }

    .EditModeControlWindow-widgets p {
      padding: 18px 16px;
      color: ${props => props.theme.color.monochrome.mediumDark};
      font-style: italic;
      border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
    }
    .EditModeControlWindow-widget {
      padding: 12px 16px;
      border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
      display: flex;
      cursor: pointer;

      .IconButton {
        padding: 4px;
      }

      &:hover {
        background: ${props => props.theme.color.secondary.brand};
      }

      .IconButton:not(.disabled):hover:before {
        background: none;
      }
    }

    .EditModeControlWindow-widgetName {
      line-height: 20px;
      flex-grow: 1;
    }

    .EditModeControlWindow-more {
      padding: 13px 16px;
      .button {
        margin-right: 8px;
      }
    }
  }
  .EditModeHeader,
  .ContactDetailsColumnHeader {
    z-index: 4;
    position: relative;
  }
  .ContactDetailsColumnBody {
    position: absolute;
    overflow: scroll;
    padding: 55px ${props => props.theme.spacing['5']} 320px;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: ${props => props.theme.color.monochrome.superLight};
  }

  && .CollapsableCard-text {
    overflow: hidden;
  }

  .EditModeHeader {
    background: white;
    border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
    padding: 11px 64px 10px 0;
    text-align: right;
  }

  .WidgetCard-wrapper:not(:empty) {
    padding-bottom: 8px;
  }

  .SortableVisibleControls {
    display: flex;
    opacity: 0;
    transition: opacity ${themeVars.defaultDuration} ease-in-out;
    flex-shrink: 0;
  }

  .CollapsableCard {
    margin-bottom: 0;
  }

  .SortableVisibleControlsWithTitle-hideButton {
    display: none;
  }

  .CollapsableCard:hover {
    .SortableVisibleControls {
      opacity: 1;
    }

    .DragHandle {
      display: inline-flex;
    }

    .DragHandle,
    .IconButton {
      path,
      &:hover path {
        fill: var(--color-monochrome-black) !important;
      }
    }
  }
  && .CollapsableCard-text {
    padding-right: 0;
  }

  .ContactInformationCategory {
    padding: ${themeVars.huge} ${themeVars.small} ${themeVars.nano};
    border: 1px solid ${props => props.theme.color.monochrome.medium};
    background-color: white;
    border-radius: ${themeVars.defaultBorderRadius};
  }

  &.EditMode {
    .CollapsableCard-icon {
      display: none;
    }
    .CollapsableCard-title {
      padding-right: 11px;
    }
    .SortableVisibleControls {
      display: flex;
      opacity: 1;
    }

    .CollapsableCard-contentInternal {
      opacity: 0.5;
      pointer-events: none;
    }
    .CollapsableCard {
      .SortableVisibleControlsWithTitle-hideButton {
        display: inline-flex;
      }
    }
  }
  &.EditMode {
    .CollapsableCard:hover {
      .DragHandle,
      .IconButton {
        path,
        &:hover path {
          fill: var(--color-monochrome-mediumDark) !important;
        }
      }
    }
  }

  && {
    .action.editAction {
      opacity: 1;
    }
    .dragHandle {
      cursor: grab;
    }
  }

  .editModeButton {
    margin-left: 10px;
    height: 0;
    margin-top: 4px;
    position: relative;
    z-index: 2;

    > div {
      margin-bottom: -6px;
      margin-left: 4px;
      color: ${props => props.theme.color.monochrome.mediumDark};
      cursor: pointer;
      font-size: ${props => props.theme.spacing['6']};
      font-weight: ${themeVars.graphikWeightMedium};
      text-align: left;
      float: right;
      padding: ${props => props.theme.spacing['3']};
      border-left: 1px solid ${props => props.theme.color.monochrome.medium};
    }

    &:hover > div {
      color: ${props => props.theme.color.primary.brand};
    }

    &.noMoreFields {
      height: auto;
      > div {
        border-left: none;
        float: none;
        text-align: right;
      }
    }
  }

  .SortableCustomFieldsContainer {
    margin-top: -1px;
    padding-top: 1px;
  }

  .CollapsableContent-fields {
    margin-top: 6px;
  }

  .CollapsableContent-animatedHeightInner {
    padding-top: 6px;
  }

  .isInEditMode {
    .editModeButton {
      > div {
        border: 0;
      }
    }

    .CollapsableContent-trigger {
      opacity: 0;
    }

    .CollapsableContent {
      /* margin-top: 23px; */
    }
  }
`

function doFetchCurrentUserContactSideBarPreferences() {
  return doFetchCurrentUserAgentPreferences('contact_details_column')
}

function ContactDetailsColumn({ className, children }) {
  const {
    isScrolled,
    onScroll,
    elementRef: scrolledElementRef,
  } = useIsScrolled({
    upThreshold: 20,
  })
  const contactId = useSelector(selectCurrentContactId)
  const customerId = useSelector(selectCurrentCustomerId)

  const handle = useSelector(selectCurrentMatrixUserId)
  const defaultPriorityCategoryOrder = useSelector(
    selectDefaultPriorityContactDetailsColumnWidgetCardOrderAndVisibility
  )
  const defaultCategoryOrder = useSelector(
    selectDefaultContactDetailsColumnWidgetCardOrderAndVisibility
  )
  const isLoading = useSelector(selectIsContactDetailsColumnLoading)
  const dispatch = useDispatch()
  useActionEffect(doFetchCurrentUserContactSideBarPreferences)
  useActionEffect(doBootstrapCustomFieldsAndCategories)
  useEffect(
    () => {
      if (!customerId && !handle) return undefined
      dispatch(doFetchCurrentContact())
      const interval = setInterval(() => {
        dispatch(doFetchCurrentContact())
      }, TWO_MINUTES)
      return () => clearInterval(interval)
    },
    [dispatch, customerId, contactId, handle]
  )
  const companyId = useSelector(selectCurrentCompanyId)
  useEffect(
    () => {
      if (companyId) dispatch(doFetchCompany(companyId))
    },
    [dispatch, companyId]
  )
  const {
    doExitEditMode,
    doHideItem,
    doReorderItems,
    doShowItem,
    doToggleEditMode,
    hiddenItems: hiddenCards,
    items: cardOrder,
    isInEditMode,
  } = useReorderingAndVisibility(
    'contact_details_column',
    'widget_cards',
    defaultCategoryOrder,
    defaultPriorityCategoryOrder
  )

  if (!contactId) return null

  return (
    <div
      className={cn('sui', 'ContactDetailsColumn', className, {
        EditMode: isInEditMode,
      })}
    >
      {isInEditMode && <div className="ContactDetailsColumnOverlay" />}
      {!isLoading && (
        <ContactDetailsColumnHeader
          doToggleEditMode={doToggleEditMode}
          doExitEditMode={doToggleEditMode}
          isInEditMode={isInEditMode}
          isScrolled={isScrolled}
        />
      )}
      {!isLoading &&
        isInEditMode && (
          <EditModeControlWindow
            hiddenCards={hiddenCards}
            doExitEditMode={doExitEditMode}
            doShowItem={doShowItem}
          />
        )}
      <div
        className="ContactDetailsColumnBody"
        onScroll={onScroll}
        ref={scrolledElementRef}
      >
        <WidgetCards
          cardOrder={cardOrder}
          doHideCard={doHideItem}
          doReorderItems={doReorderItems}
          isInEditMode={isInEditMode}
          isLoading={isLoading}
        />
      </div>
      {children}
    </div>
  )
}
