import { useDebouncedCallback } from 'use-debounce'

import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { doSearchCompanies } from 'ducks/crm/companies'

export function useCompanySearch({ companyId }) {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const submitSearch = useCallback(
    query => {
      return dispatch(doSearchCompanies(query)).then(result => {
        setLoading(false)
        setCompanies(
          result?.companies?.nodes?.filter(({ id }) => companyId !== id)
        )
      })
    },
    [companyId, setLoading, dispatch]
  )
  const [debouncedSubmitSearch, cancelSubmitSearch] = useDebouncedCallback(
    submitSearch,
    500
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => cancelSubmitSearch(), [])
  const onSearchChange = useCallback(
    event => {
      setLoading(true)
      const query = event?.target?.value
      setSearchQuery(query)
      debouncedSubmitSearch(query)
    },
    [setSearchQuery, debouncedSubmitSearch]
  )

  return {
    companies,
    hasResults: companies && companies.length > 0,
    loading,
    onSearchChange,
    searchQuery,
  }
}
