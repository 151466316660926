export default {
  autoPresence: {
    id: 'autoPresence',
    name: 'Auto-away',
    description:
      'Automatically go online, away or offline depending on your activity.',
    className: 'auto',
  },
  online: {
    id: 'online',
    name: 'Online',
    description: 'Shown as online & available in your widget chat.',
    className: 'online',
  },
  unavailable: {
    id: 'unavailable',
    name: 'Away',
    description: 'Shown as away but available in your widget chat.',
    className: 'away',
  },
  offline: {
    id: 'offline',
    name: 'Offline',
    description:
      'Unavailable in any active widget chats, or new conversations. New messages to existing conversations will be unassigned and reopened.',
    className: 'offline',
  },
}
