import React from 'react'
import { css, useTheme } from '@emotion/react'
import Lightbox from 'react-images'
import { Flex, Icon } from 'shared/ui'
import { fixDownloadUrl } from 'util/url'

const OpenInNewTab = ({ url, theme }) => {
  return (
    <a
      href={url}
      target="_blank"
      without
      rel="noopener noreferrer"
      css={css`
        align-self: center;
        color: white;
        opacity: 0.8;
        :hover {
          opacity: 1;
        }
        transition: opacity 0.175s;
      `}
    >
      <Flex className="grui items-baseline flex-1-0-auto">
        <Icon
          name="file-download"
          size={13}
          css={css`
            margin-right: 4px;
          `}
          theme={theme}
        />
        {'Download'}
      </Flex>
    </a>
  )
}

const ImageLightbox = ({
  images,
  isOpen,
  close,
  showPrevious,
  showNext,
  currentImage,
  subdomain,
}) => {
  const theme = useTheme()
  if (!images || images.length === 0) return null

  return (
    <Lightbox
      backdropClosesModal
      images={images.map(image => ({ ...image, src: image.url }))}
      isOpen={isOpen}
      onClickPrev={showPrevious}
      onClickNext={showNext}
      onClose={close}
      currentImage={currentImage}
      customControls={[
        <OpenInNewTab
          key={currentImage}
          url={fixDownloadUrl(images[currentImage].url, subdomain)}
          theme={theme}
        />,
      ]}
    />
  )
}

export default ImageLightbox
