import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { Button, BoxHeadline } from 'shared/ui'
import { propFunc } from 'util/functions'

const SearchingEmptyState = ({ className, searchTerm, onAdd }) => {
  const handleCreateTag = propFunc(onAdd, { name: searchTerm })
  return (
    <div className={cn('grui my-20', className)}>
      <ListenToKeyboard
        stopPropagation
        preventDefault
        onEnter={handleCreateTag}
      />
      <BoxHeadline>No tags found</BoxHeadline>

      <Button
        primary
        className="grui mt-4"
        onClick={handleCreateTag}
        size="small"
      >
        Create a tag
      </Button>
    </div>
  )
}

const Styled = styled(SearchingEmptyState)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Styled
