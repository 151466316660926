import React from 'react'
import { connect } from 'react-redux'

import mailchimpLogo from 'assets/integrations/mailchimp.svg'
import campaignmonitorLogo from 'assets/integrations/campaignmonitor.png'
import constantcontactLogo from 'assets/integrations/constantcontact.png'
import { selectCurrentContactId } from 'ducks/crm/contacts'

import {
  doFetchEmailMarketingIntegrationState,
  doSubscribeToEmailList,
  doUnsubscribeFromEmailList,
  selectIsEmailMarketingIntegrationLoading,
  selectIsEmailMarketingIntegrationFailed,
  selectEmailMarketingSubscriptions,
  selectEmailMarketingAvailableLists,
} from 'ducks/integrations/emailMarketing'
import CollapsableCard from 'components/CollapsableCard'
import SortableVisibleControlsWithTitle from 'components/crm/ContactDetailsColumn/SortableVisibleControlsWithTitle'
import { runOnNextTick } from 'util/functions'

const LOGOS = {
  MAILCHIMP: mailchimpLogo,
  CAMPAIGN_MONITOR: campaignmonitorLogo,
  CONSTANT_CONTACT: constantcontactLogo,
}

const EmailMarketingCard = Content => {
  const selectFactory = (initialState, initialOwnProps) => {
    const { id: integrationId } = initialOwnProps
    return state => {
      const contactId = selectCurrentContactId(state)
      return {
        contactId,
        // data: selectSidebarCardData(state)(`legacy_${cardId}`),
        subscriptions: selectEmailMarketingSubscriptions(state, {
          contactId,
          integrationId,
        }),
        availableLists: selectEmailMarketingAvailableLists(state, {
          contactId,
          integrationId,
        }),
        isFailed: selectIsEmailMarketingIntegrationFailed(state, {
          contactId,
          integrationId,
        }),
        isLoading: selectIsEmailMarketingIntegrationLoading(state, {
          contactId,
          integrationId,
        }),
      }
    }
  }

  const performFactory = (initialState, initialOwnProps) => {
    const { id: cardId } = initialOwnProps

    return dispatch => ({
      load: contactId => {
        const action = doFetchEmailMarketingIntegrationState(cardId, contactId)
        if (action) dispatch(action)
      },
      onSubscribe: (contactId, list) => {
        dispatch(doSubscribeToEmailList(cardId, contactId, list))
      },
      onUnsubscribe: (contactId, listId) => {
        dispatch(doUnsubscribeFromEmailList(cardId, contactId, listId))
      },
    })
  }

  class CustomerChangeHandler extends React.Component {
    componentDidMount() {
      this.runLoad()
    }

    componentDidUpdate(prevProps) {
      if (!this.props.contactId) return
      if (prevProps.contactId !== this.props.contactId) this.runLoad()
    }

    runLoad() {
      runOnNextTick(() => this.props.load(this.props.contactId))
    }

    render() {
      const { type, doHideItem } = this.props
      return (
        <CollapsableCard
          image={LOGOS[type]}
          hideOverflow={false}
          persistenceKey={type}
          title={
            <SortableVisibleControlsWithTitle
              title={type}
              onHide={doHideItem}
            />
          }
        >
          <Content {...this.props} />
        </CollapsableCard>
      )
    }
  }

  return connect(selectFactory, performFactory)(CustomerChangeHandler)
}

export default EmailMarketingCard
