import cn from 'classnames'
import styled from '@emotion/styled'
import { Flex } from 'shared/ui'
import Action from './Action'

const Actions = ({
  className,
  hideName = false,
  hideBcc = false,
  hideCc = false,
  hideSubject = false,
  hidePhone = false,
  onClick,
}) => {
  return (
    <Flex className={cn('actions grui ml-4 flex-center', className)}>
      {!hideName && (
        <Action name="name" className="grui ml-10" onClick={onClick} />
      )}
      {!hideSubject && (
        <Action name="subject" className="grui ml-10" onClick={onClick} />
      )}
      {!hidePhone && (
        <Action name="phone" className="grui ml-10" onClick={onClick} />
      )}
      {!hideCc && <Action name="cc" className="grui ml-10" onClick={onClick} />}
      {!hideBcc && (
        <Action name="bcc" className="grui ml-10" onClick={onClick} />
      )}
    </Flex>
  )
}

export default styled(Actions)`
  line-height: 24px;
`
