// Multi-file Attachment upload with (optional) icon
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.css'

const AttachmentUpload = ({
  className,
  forNote,
  hideIcon,
  onChange,
  triggerId,
  draftId,
  upload,
  onUploadComplete,
}) => {
  return (
    <label htmlFor={triggerId} className={styles.AttachmentUpload}>
      {!hideIcon && <span className={cn('Icon Icon-attachment', className)} />}
      <span className="u-visuallyhidden">Add Attachment</span>
      <input
        id={triggerId}
        type="file"
        multiple
        className={styles.input}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={event => {
          const target = event.target
          upload(event.target.files, forNote, draftId).then(() => {
            target.value = null
            target.files = null
            if (onUploadComplete) onUploadComplete(event, { draftId })
          })
          if (onChange) onChange(event)
        }}
      />
    </label>
  )
}

AttachmentUpload.propTypes = {
  /* Whether or not to show the attachment Icon (or just the hidden input) */
  hideIcon: PropTypes.bool,

  /* Passthru prop to the upload fn. */
  forNote: PropTypes.bool,

  /* Optional callback after files are selected */
  onChange: PropTypes.func,

  /* Optional ID of input element */
  triggerId: PropTypes.string.isRequired,

  /* Called when files are selected */
  upload: PropTypes.func.isRequired,
}

AttachmentUpload.defaultProps = {
  forNote: false,
  hideIcon: false,
  onChange: undefined,
  triggerId: 'attachmentUploadTrigger',
}

export default AttachmentUpload
