import { deepFreeze, deepCopy } from 'util/objects'

export const FORM_KEY_ORDER_ITEMS = 'orderItems'
export const FORM_KEY_HAS_TAXES = 'hasTaxes'
export const FORM_KEY_DISCOUNT = 'appliedDiscount'
export const FORM_KEY_SHIPPING = 'shipping'
export const FORM_KEY_NOTES = 'notes'
export const FORM_KEY_TAGS = 'tags'
export const FORM_KEY_IS_PAYMENT_DUE_LATER = 'isPaymentDueLater'
export const FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID = 'paymentTermsTemplateId'
export const FORM_KEY_PAYMENT_TERMS_TYPE = 'paymentTermsType'
export const FORM_KEY_PAYMENT_SCHEDULES_DUE_AT = 'dueAt'
export const FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT = 'issuedAt'

export const NEW_ORDER_DEFAULTS = deepFreeze({
  [FORM_KEY_ORDER_ITEMS]: [],
  [FORM_KEY_HAS_TAXES]: false,
  [FORM_KEY_SHIPPING]: {},
  [FORM_KEY_DISCOUNT]: null,
  [FORM_KEY_NOTES]: '',
  [FORM_KEY_TAGS]: '',
  [FORM_KEY_IS_PAYMENT_DUE_LATER]: false,
  [FORM_KEY_PAYMENT_TERMS_TYPE]: null,
  [FORM_KEY_PAYMENT_TERMS_TEMPLATE_ID]: null,
  [FORM_KEY_PAYMENT_SCHEDULES_DUE_AT]: null,
  [FORM_KEY_PAYMENT_SCHEDULES_ISSUED_AT]: null,
})

// Well shit, line items requires a variantId to be stored
// in the variantId field (duh...), but for some reasons our
// internal structure somtimes stores the variantId in the variantId
// and somtimes it stores it in the id
const hackVariantIdFromOrderItem = orderItem => {
  const { id, variantId } = orderItem

  if (id.includes('ProductVariant')) return id
  return variantId
}

const convertOrderItemToLineItem = orderItem => {
  let item
  if (orderItem.custom) {
    item = {
      title: orderItem.displayName,
      quantity: +orderItem.quantity,
      originalUnitPrice: +orderItem.customPrice,
      taxable: orderItem.taxable,
      requiresShipping: orderItem.requiresShipping,
    }
  } else {
    item = {
      quantity: +orderItem.quantity,
      variantId: hackVariantIdFromOrderItem(orderItem),
      requiresShipping: orderItem.requiresShipping,
    }
  }

  item.appliedDiscount = orderItem.appliedDiscount
  return item
}

export const convertOrderItemsToLineItems = orderItems => {
  return orderItems.map(convertOrderItemToLineItem)
}

export const generateDefaultValues = () => {
  return deepCopy(NEW_ORDER_DEFAULTS)
}
