import React from 'react'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { removeURLQueryString } from 'util/strings'

import { connect } from 'react-redux'
import { selectCurrentPathWithSearchEncoded } from 'selectors/location/selectCurrentPathWithSearchEncoded'
import { selectLatestTicketSearchEncodedQueryString as selectLatestQueryString } from 'selectors/search'
import SearchRawCount from 'components/App/DesktopView/Layout/SearchRawCount'

class StarredView extends React.PureComponent {
  render() {
    const { starredSearches, currentPath, latestSearchPath } = this.props
    return (
      <>
        {starredSearches.map(search => {
          const cleanHref = removeURLQueryString(search.href)
          return (
            <Menu.Item
              as={Link}
              to={search.href}
              count={<SearchRawCount queryId={search.queryId} />}
              key={search.queryId}
              value={search.queryId}
              active={
                cleanHref === currentPath || cleanHref === latestSearchPath
              }
              isSubMenuItem
            >
              {search.label}
            </Menu.Item>
          )
        })}
      </>
    )
  }
}

const select = state => {
  const currentPath = selectCurrentPathWithSearchEncoded(state)
  const latestSearchPath = `/search/${selectLatestQueryString(state)}`

  return {
    currentPath,
    latestSearchPath,
  }
}

const Starred = connect(select)(StarredView)

export default Starred
