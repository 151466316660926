import React from 'react'
import cn from 'classnames'
import { Divider, Button, Flex } from 'shared/ui'

const Actions = ({ className, hasPendingEdits, onApply, onCancel }) => {
  if (!hasPendingEdits) return null
  return (
    <React.Fragment>
      <Divider className="grui mt-4" />
      <Flex className={cn('grui p-4 justify-end flex-col', className)}>
        <Flex className="grui justify-end" key="actions">
          <Button basic size="small" onClick={onCancel} key="cancel">
            Cancel
          </Button>
          <Button
            primary
            size="small"
            key="apply"
            onClick={onApply}
            className="grui ml-4"
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    </React.Fragment>
  )
}

export default Actions
