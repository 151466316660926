import React from 'react'

import { IconButton } from 'shared/ui'
import LabelingDropdownV1 from 'components/App/DesktopView/Labels/LabelingDropdown'

const TagsButton = React.memo(
  ({ tagsOpen, onTagIconClick, onTagDropdownClose }) => {
    return (
      <div className="tags">
        {!tagsOpen && (
          <IconButton
            tooltip="Tag [t]"
            name="tag"
            onClick={onTagIconClick}
            size="medium"
            svg
            className="menu-button"
          />
        )}
        {tagsOpen && (
          <LabelingDropdownV1
            mode="single"
            open
            onClose={onTagDropdownClose}
            trigger={
              <IconButton
                name="tag"
                size="medium"
                className="menu-button"
                svg
              />
            }
          />
        )}
      </div>
    )
  }
)

export default TagsButton
