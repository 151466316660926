import { css } from '@emotion/react'

const content = css`
  & > * {
    margin-bottom: 21px;
  }
`

const fullWidth = css`
  max-width: 100%;
`

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: 100%;
  width: 100%;
`

export const styles = {
  content,
  fullWidth,
  dropdownContainer,
  dropdownBtn,
}
