import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_EMAIL_FORWARD } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import NoticeBar from 'components/NoticeBar'
import { selectMailboxByStates } from 'selectors/mailboxes/selectMailboxByStates'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'

import styles from './styles.less'

export default function ForwardingUnconfirmed({ isManager }) {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const confirmedUnconfirmedMailboxes = useSelector(state =>
    selectMailboxByStates(state, ['unconfirmed', 'confirmed'])
  )
  const firstMailbox = confirmedUnconfirmedMailboxes[0] || {}
  const currentMailboxId = ticketListMailboxId || firstMailbox.id
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
    lazyLoad: true,
  })

  const mailboxName = !ticketListMailboxId ? ` for ${firstMailbox.name}` : ''

  const onOpen = useCallback(
    () => {
      openAddDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', 'forwarding'),
        },
      })
    },
    [openAddDrawer, currentMailboxId, drawerId]
  )

  let callToActionContent = `Please contact your administrator to reconnect your ${app.t(
    'mailbox'
  )} now.`
  if (isManager) {
    callToActionContent = (
      <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
        Setup forwarding now{mailboxName}.
      </a>
    )
  }

  return (
    <NoticeBar
      type="warning"
      flagName={`seen-forwarding-unconfirmed-notice-${currentMailboxId || ''}`}
      dismissible
      isSessionFlag
    >
      You will only start receiving emails in this {app.t('mailbox')} once
      forwarding is completed!&nbsp;
      {callToActionContent}
    </NoticeBar>
  )
}
