import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { doFetchJiraServerIssueSearch } from 'ducks/integrations/jiraServer'
import {
  selectJiraSearchedIssuesAsOptions,
  selectJiraSearchIssuesAreLoading,
  selectJiraTypes,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'
import { debounce } from 'util/functions'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import ProjectLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/ProjectLabel'

export default connect(
  (state, { projectId, siteId }) => {
    return {
      areIssuesLoading: selectJiraSearchIssuesAreLoading(state, {
        source: 'jiraServer',
      }),
      issues: selectJiraSearchedIssuesAsOptions(state, {
        source: 'jiraServer',
      }),
      types: selectJiraTypes(state, {
        projectId,
        siteId,
        source: 'jiraServer',
      }),
    }
  },
  {
    fetchJiraServerIssueSearch: doFetchJiraServerIssueSearch,
  }
)(ParentIssueFormField)
function dontFilter(results) {
  return results
}

function ParentIssueFormField({
  areIssuesLoading,
  fetchJiraServerIssueSearch,
  formFields: { parent = null, type = null } = {},
  issues,
  onChange,
  projectId,
  resetFields,
  siteId,
  types,
}) {
  const [currentSearchQuery, setSearchQuery] = useState('')
  useEffect(
    () => {
      resetFields('parent')
      setSearchQuery('')
      fetchJiraServerIssueSearch(siteId, '', projectId, null, type)
    },
    [
      fetchJiraServerIssueSearch,
      projectId,
      resetFields,
      setSearchQuery,
      siteId,
      type,
    ]
  )
  const onSearchChange = useCallback(
    (event, { searchQuery }) => {
      setSearchQuery(searchQuery)
      fetchJiraServerIssueSearch(siteId, searchQuery, projectId, null, type)
    },
    [fetchJiraServerIssueSearch, projectId, setSearchQuery, siteId, type]
  )
  let noResultsMessage = 'Search for Jira Server Issues.'
  if (areIssuesLoading) noResultsMessage = 'Loading...'
  if (!areIssuesLoading && currentSearchQuery.length > 0)
    noResultsMessage = 'No results found.'
  const isTypeChoosen = !!type
  const isTypeSubtask =
    types &&
    !!types.find(({ id, subtask }) => {
      return id === type && subtask
    })
  if (!(isTypeChoosen && isTypeSubtask)) return null
  return (
    <FormRow>
      <Label as="div">Parent Issue</Label>
      <ValidatedField
        fluid
        loading={areIssuesLoading}
        name="parent"
        noResultsMessage={noResultsMessage}
        options={
          issues &&
          issues.map(option => ({
            ...option,
            children: (
              <React.Fragment key={option.key}>
                <div>
                  <strong>{option.text}</strong>
                </div>
                <ProjectLabel>{option.project}</ProjectLabel>
                {option.components.length > 0 && (
                  <div>{option.components.join(', ')}</div>
                )}
              </React.Fragment>
            ),
          }))
        }
        onChange={onChange}
        onSearchChange={debounce(onSearchChange, 500)}
        placeholder={noResultsMessage}
        search={dontFilter}
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={parent}
      />
    </FormRow>
  )
}
