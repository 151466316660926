import { connect } from 'react-redux'

import {
  selectIsOnLoginPage,
  selectIsOnForgotPasswordPage,
  selectIsOnForgotSubdomainPage,
  selectIsOnChangePasswordPage,
  selectIsOnOauthErrorPage,
} from 'selectors/app'

import {
  selectIsOnKbCreatePage,
  selectIsOnKbDashboardPage,
  selectIsOnKbThemePage,
  selectIsOnKbArticlesPage,
  selectIsOnKbArticleCreatePage,
  selectIsOnKbArticleEditPage,
  selectIsOnKbCategoriesPage,
  selectIsOnKbCategoryCreatePage,
  selectIsOnKbCategoryEditPage,
  selectIsOnKbSettingsPage,
  selectIsOnKbUpgradePage,
  selectIsOnKbUpgradeProPage,
} from 'subapps/kb/selectors/pages'
import { selectCurrentPath } from 'selectors/location'

// Note: Changed to selectors directly to prevent circular references
import { selectIsOnConversationsPage } from 'ducks/reports/conversations/selectors'
import { selectIsOnProductivityPage } from 'ducks/reports/productivity/selectors'
import { selectIsOnHappinessPage } from 'ducks/reports/happiness/selectors'
import { selectIsOnArticlesPage } from 'ducks/reports/kb/selectors'
import { selectIsOnAgentsOverviewPage } from 'ducks/reports/agents/selectors'
import { selectIsOnTeamsOverviewPage } from 'ducks/reports/teams/selectors'
import {
  selectIsOnAgentOverviewPage,
  selectIsOnTeamOverviewPage,
} from 'ducks/reports/detail/selectors'
import {
  selectIsOnSignupPage as selectIsOnOnboardingSignupPage,
  selectIsOnCustomerInformationPage as selectIsOnOnboardingCustomerInformationPage,
  selectIsOnChannelOverviewPage as selectIsOnOnboardingChannelOverviewPage,
  selectIsOnAddTeamMembersPage as selectIsOnOnboardingAddTeamMembersPage,
  selectIsOnAddChannelPage as selectIsOnOnboardingAddChannelPage,
  selectIsOnSetupEmailPage as selectIsOnOnboardingSetupEmailPage,
  selectIsOnCompletedPage as selectIsOnOnboardingCompletedPage,
  selectIsOnV2SignupPage as selectIsOnOnboardingV2SignupPage,
  selectIsOnV2CompanyPage as selectIsOnOnboardingV2CompanyPage,
  selectIsOnV2OwnerPage as selectIsOnOnboardingV2OwnerPage,
  selectIsOnV2PasswordPage as selectIsOnOnboardingV2PasswordPage,
  selectIsOnV2VerificationPage as selectIsOnOnboardingV2VerificationPage,
  selectIsOnV2CompletionPage as selectIsOnOnboardingV2CompletionPage,
  selectIsOnV3SignupPage as selectIsOnOnboardingV3SignupPage,
  selectIsOnV3EmailPage as selectIsOnOnboardingV3EmailPage,
  selectIsOnV3CompanyPage as selectIsOnOnboardingV3CompanyPage,
  selectIsOnV3VerificationPage as selectIsOnOnboardingV3VerificationPage,
  selectIsOnV4EmailPage as selectIsOnOnboardingV4EmailPage,
  selectIsOnV4UserPage as selectIsOnOnboardingV4UserPage,
  selectIsOnV4CompanyPage as selectIsOnOnboardingV4CompanyPage,
  selectIsOnV4VerificationPage as selectIsOnOnboardingV4VerificationPage,
  selectIsOnV5SignupPage as selectIsOnOnboardingV5SignupPage,
  selectIsOnV5EmailPage as selectIsOnOnboardingV5EmailPage,
  selectIsOnV5UserPage as selectIsOnOnboardingV5UserPage,
  selectIsOnV5CompanyPage as selectIsOnOnboardingV5CompanyPage,
  selectIsOnV5VerificationPage as selectIsOnOnboardingV5VerificationPage,
  selectIsOnV6SignupPage as selectIsOnOnboardingV6SignupPage,
  selectIsOnV6EmailPage as selectIsOnOnboardingV6EmailPage,
  selectIsOnV6CompanyPage as selectIsOnOnboardingV6CompanyPage,
  selectIsOnV6CompanyPage2 as selectIsOnOnboardingV6CompanyPage2,
  selectIsOnV6VerificationPage as selectIsOnOnboardingV6VerificationPage,
  selectIsOnShopifyEmailPage as selectIsOnOnboardingShopifyEmailPage,
  selectIsOnShopifyUserPage as selectIsOnOnboardingShopifyUserPage,
  selectIsOnShopifyCompanyPage as selectIsOnOnboardingShopifyCompanyPage,
  selectIsOnShopifyTrialPage as selectIsOnOnboardingShopifyTrialPage,
  selectOnboardingLayoutVariant,
} from 'subapps/onboarding/selectors'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'

import { trackPage } from 'util/tracking'
import { capture } from 'ducks/tracking/actions'

const select = state => {
  return {
    onLoginPage: selectIsOnLoginPage(state),
    onForgotPasswordPage: selectIsOnForgotPasswordPage(state),
    onForgotSubdomainPage: selectIsOnForgotSubdomainPage(state),
    onChangePasswordPage: selectIsOnChangePasswordPage(state),
    onOauthErrorPage: selectIsOnOauthErrorPage(state),
    onKbCreatePage: selectIsOnKbCreatePage(state),
    onKbDashboardPage: selectIsOnKbDashboardPage(state),
    onKbThemePage: selectIsOnKbThemePage(state),
    onKbArticlesPage: selectIsOnKbArticlesPage(state),
    onKbArticleCreatePage: selectIsOnKbArticleCreatePage(state),
    onKbArticleEditPage: selectIsOnKbArticleEditPage(state),
    onKbCategoriesPage: selectIsOnKbCategoriesPage(state),
    onKbCategoryCreatePage: selectIsOnKbCategoryCreatePage(state),
    onKbCategoryEditPage: selectIsOnKbCategoryEditPage(state),
    onKbSettingsPage: selectIsOnKbSettingsPage(state),
    onKbUpgradePage: selectIsOnKbUpgradePage(state),
    onKbUpgradeProPage: selectIsOnKbUpgradeProPage(state),
    path: selectCurrentPath(state),
    onReportsInboxConversationsPage: selectIsOnConversationsPage(state),
    onReportsInboxProductivityPage: selectIsOnProductivityPage(state),
    onReportsInboxAgentOverviewPage: selectIsOnAgentOverviewPage(state),
    onReportsInboxTeamOverviewPage: selectIsOnTeamOverviewPage(state),
    onReportsInboxAgentsOverviewPage: selectIsOnAgentsOverviewPage(state),
    onReportsInboxTeamsOverviewPage: selectIsOnTeamsOverviewPage(state),
    onReportsInboxHappinessPage: selectIsOnHappinessPage(state),
    onReportsKbArticlesPage: selectIsOnArticlesPage(state),
    onOnboardingSignupPage: selectIsOnOnboardingSignupPage(state),
    onOnboardingCustomerInformationPage: selectIsOnOnboardingCustomerInformationPage(
      state
    ),
    onOnboardingChannelOverviewPage: selectIsOnOnboardingChannelOverviewPage(
      state
    ),
    onOnboardingAddTeamMembersPage: selectIsOnOnboardingAddTeamMembersPage(
      state
    ),
    onOnboardingAddChannelPage: selectIsOnOnboardingAddChannelPage(state),
    onOnboardingSetupEmailPage: selectIsOnOnboardingSetupEmailPage(state),
    onOnboardingCompletedPage: selectIsOnOnboardingCompletedPage(state),
    onOnboardingV2SignupPage: selectIsOnOnboardingV2SignupPage(state),
    onOnboardingV2CompanyPage: selectIsOnOnboardingV2CompanyPage(state),
    onOnboardingV2OwnerPage: selectIsOnOnboardingV2OwnerPage(state),
    onOnboardingV2PasswordPage: selectIsOnOnboardingV2PasswordPage(state),
    onOnboardingV2VerificationPage: selectIsOnOnboardingV2VerificationPage(
      state
    ),
    onOnboardingV2CompletionPage: selectIsOnOnboardingV2CompletionPage(state),

    onOnboardingV3SignupPage: selectIsOnOnboardingV3SignupPage(state),
    onOnboardingV3EmailPage: selectIsOnOnboardingV3EmailPage(state),
    onOnboardingV3CompanyPage: selectIsOnOnboardingV3CompanyPage(state),
    onOnboardingV3VerificationPage: selectIsOnOnboardingV3VerificationPage(
      state
    ),

    onOnboardingV4EmailPage: selectIsOnOnboardingV4EmailPage(state),
    onOnboardingV4UserPage: selectIsOnOnboardingV4UserPage(state),
    onOnboardingV4CompanyPage: selectIsOnOnboardingV4CompanyPage(state),
    onOnboardingV4VerificationPage: selectIsOnOnboardingV4VerificationPage(
      state
    ),

    onOnboardingV5SignupPage: selectIsOnOnboardingV5SignupPage(state),
    onOnboardingV5EmailPage: selectIsOnOnboardingV5EmailPage(state),
    onOnboardingV5UserPage: selectIsOnOnboardingV5UserPage(state),
    onOnboardingV5CompanyPage: selectIsOnOnboardingV5CompanyPage(state),
    onOnboardingV5VerificationPage: selectIsOnOnboardingV5VerificationPage(
      state
    ),

    onOnboardingV6SignupPage: selectIsOnOnboardingV6SignupPage(state),
    onOnboardingV6EmailPage: selectIsOnOnboardingV6EmailPage(state),
    onOnboardingV6CompanyPage: selectIsOnOnboardingV6CompanyPage(state),
    onOnboardingV6CompanyPage2: selectIsOnOnboardingV6CompanyPage2(state),
    onOnboardingV6VerificationPage: selectIsOnOnboardingV6VerificationPage(
      state
    ),

    onOnboardingShopifyEmailPage: selectIsOnOnboardingShopifyEmailPage(state),
    onOnboardingShopifyUserPage: selectIsOnOnboardingShopifyUserPage(state),
    onOnboardingShopifyCompanyPage: selectIsOnOnboardingShopifyCompanyPage(
      state
    ),
    onOnboardingShopifyTrialPage: selectIsOnOnboardingShopifyTrialPage(state),

    userId: selectCurrentUser(state).id,

    variant: selectOnboardingLayoutVariant(state),
  }
}

const Tracking = props => {
  const {
    onLoginPage,
    onForgotPasswordPage,
    onForgotSubdomainPage,
    onChangePasswordPage,
    onOauthErrorPage,
    onKbCreatePage,
    onKbDashboardPage,
    onKbThemePage,
    onKbArticlesPage,
    onKbArticleCreatePage,
    onKbArticleEditPage,
    onKbCategoriesPage,
    onKbCategoryCreatePage,
    onKbCategoryEditPage,
    onKbSettingsPage,
    onKbUpgradePage,
    onKbUpgradeProPage,
    path,
    onReportsInboxConversationsPage,
    onReportsInboxProductivityPage,
    onReportsInboxAgentOverviewPage,
    onReportsInboxTeamOverviewPage,
    onReportsInboxAgentsOverviewPage,
    onReportsInboxTeamsOverviewPage,
    onReportsInboxHappinessPage,
    onReportsKbArticlesPage,
    onOnboardingSignupPage,
    onOnboardingCustomerInformationPage,
    onOnboardingChannelOverviewPage,
    onOnboardingAddTeamMembersPage,
    onOnboardingAddChannelPage,
    onOnboardingSetupEmailPage,
    onOnboardingCompletedPage,
    onOnboardingV2SignupPage,
    onOnboardingV2CompanyPage,
    onOnboardingV2OwnerPage,
    onOnboardingV2PasswordPage,
    onOnboardingV2VerificationPage,
    onOnboardingV2CompletionPage,
    onOnboardingV3SignupPage,
    onOnboardingV3EmailPage,
    onOnboardingV3CompanyPage,
    onOnboardingV3VerificationPage,
    onOnboardingV4EmailPage,
    onOnboardingV4UserPage,
    onOnboardingV4CompanyPage,
    onOnboardingV4VerificationPage,
    onOnboardingV5SignupPage,
    onOnboardingV5EmailPage,
    onOnboardingV5UserPage,
    onOnboardingV5CompanyPage,
    onOnboardingV5VerificationPage,
    onOnboardingV6SignupPage,
    onOnboardingV6EmailPage,
    onOnboardingV6CompanyPage,
    onOnboardingV6CompanyPage2,
    onOnboardingV6VerificationPage,
    onOnboardingShopifyEmailPage,
    onOnboardingShopifyUserPage,
    onOnboardingShopifyCompanyPage,
    onOnboardingShopifyTrialPage,
    userId,
    variant,
  } = props

  if (onLoginPage) return trackPage('login', 'login', userId) && null
  if (onForgotPasswordPage)
    return trackPage('forgot-password', 'login', userId) && null
  if (onForgotSubdomainPage)
    return trackPage('forgot-subdomain', 'login', userId) && null
  if (onChangePasswordPage)
    return trackPage('change-password', 'login', userId) && null
  if (onOauthErrorPage) return trackPage('oauth-error', 'login', userId) && null

  // Settings
  if (path.match('settings/business_hours'))
    return trackPage('business-hours', 'settings', userId) && null
  if (path.match('settings/billing'))
    return trackPage('billing', 'settings', userId) && null
  if (path.match('settings/integrations'))
    return trackPage('integrations-beta', 'settings', userId) && null

  // Inbox
  if (path === '/') return trackPage('home', 'inbox', userId) && null
  if (path.match(/^\/search/))
    return trackPage('search', 'inbox', userId) && null
  if (path.match(/^\/tickets/))
    return trackPage('ticket', 'inbox', userId) && null

  // Onboarding
  if (onOnboardingSignupPage)
    return trackPage('signup', 'onboarding', userId) && null
  if (onOnboardingCustomerInformationPage)
    return trackPage('your-business', 'onboarding', userId) && null
  if (onOnboardingChannelOverviewPage)
    return trackPage('channel-overview', 'onboarding', userId) && null
  if (onOnboardingAddTeamMembersPage)
    return trackPage('add-team', 'onboarding', userId) && null
  if (onOnboardingAddChannelPage)
    return trackPage('add-channel', 'onboarding', userId) && null
  if (onOnboardingSetupEmailPage)
    return (
      trackPage('add-channel-email-forwarding', 'onboarding', userId) && null
    )
  if (onOnboardingCompletedPage)
    return trackPage('completed', 'onboarding', userId) && null

  if (onOnboardingV2SignupPage)
    return trackPage('v2-signup', 'onboarding', userId) && null
  if (onOnboardingV2CompanyPage)
    return trackPage('v2-company', 'onboarding', userId) && null
  if (onOnboardingV2OwnerPage)
    return trackPage('v2-owner', 'onboarding', userId) && null
  if (onOnboardingV2PasswordPage)
    return trackPage('v2-password', 'onboarding', userId) && null
  if (onOnboardingV2VerificationPage)
    return trackPage('v2-verification', 'onboarding', userId) && null
  if (onOnboardingV2CompletionPage) {
    capture('user signed up')
    return trackPage('v2-completion', 'onboarding', userId) && null
  }

  if (onOnboardingV3SignupPage)
    return trackPage('v3-signup', 'onboarding', userId) && null
  if (onOnboardingV3EmailPage)
    return trackPage('v3-email', 'onboarding', userId) && null
  if (onOnboardingV3CompanyPage)
    return trackPage('v3-company', 'onboarding', userId) && null
  if (onOnboardingV3VerificationPage)
    return trackPage('v3-verification', 'onboarding', userId) && null

  // NOTE (jscheel): V3 onboarding flow does not have a completion page, it must
  // be tracked from the components instead

  if (onOnboardingV4EmailPage)
    return trackPage('v4-email', 'onboarding', userId) && null
  if (onOnboardingV4UserPage)
    return trackPage('v4-user', 'onboarding', userId) && null
  if (onOnboardingV4CompanyPage)
    return trackPage('v4-company', 'onboarding', userId) && null
  if (onOnboardingV4VerificationPage)
    return trackPage('v4-verification', 'onboarding', userId) && null

  // NOTE (jscheel): V4 onboarding flow does not have a completion page, it must
  // be tracked from the components instead

  if (onOnboardingV5SignupPage)
    return trackPage('v5-signup', 'onboarding', userId) && null
  if (onOnboardingV5EmailPage)
    return trackPage('v5-email', 'onboarding', userId) && null
  if (onOnboardingV5UserPage)
    return trackPage('v5-user', 'onboarding', userId) && null
  if (onOnboardingV5CompanyPage)
    return trackPage('v5-company', 'onboarding', userId) && null
  if (onOnboardingV5VerificationPage)
    return trackPage('v5-verification', 'onboarding', userId) && null

  // NOTE (jscheel): V5 onboarding flow does not have a completion page, it must
  // be tracked from the components instead

  if (onOnboardingV6SignupPage)
    return (
      trackPage(
        'v6-signup',
        'onboarding',
        userId,
        undefined,
        undefined,
        undefined,
        variant
      ) && null
    )
  if (onOnboardingV6EmailPage)
    return (
      trackPage(
        'v6-email',
        'onboarding',
        userId,
        undefined,
        undefined,
        undefined,
        variant
      ) && null
    )
  if (onOnboardingV6CompanyPage)
    return (
      trackPage(
        'v6-company',
        'onboarding',
        userId,
        undefined,
        undefined,
        undefined,
        variant
      ) && null
    )
  if (onOnboardingV6CompanyPage2)
    return (
      trackPage(
        'v6-company-2',
        'onboarding',
        userId,
        undefined,
        undefined,
        undefined,
        variant
      ) && null
    )
  if (onOnboardingV6VerificationPage)
    return (
      trackPage(
        'v6-verification',
        'onboarding',
        userId,
        undefined,
        undefined,
        undefined,
        variant
      ) && null
    )

  // NOTE: V6 onboarding flow does not have a completion page, it must
  // be tracked from the components instead

  if (onOnboardingShopifyEmailPage)
    return trackPage('shopify-email', 'onboarding', userId) && null
  if (onOnboardingShopifyUserPage)
    return trackPage('shopify-user', 'onboarding', userId) && null
  if (onOnboardingShopifyCompanyPage)
    return trackPage('shopify-company', 'onboarding', userId) && null
  if (onOnboardingShopifyTrialPage)
    return trackPage('shopify-trial', 'onboarding', userId) && null

  // NOTE (kevinrademan): Shopify onboarding flow does not have a completion page, it must
  // be tracked from the components instead

  // Reports
  if (onReportsInboxConversationsPage)
    return trackPage('inbox-conversations', 'reports', userId) && null
  if (onReportsInboxProductivityPage)
    return trackPage('inbox-productivity', 'reports', userId) && null
  if (onReportsInboxAgentOverviewPage)
    return trackPage(`inbox-agent`, 'reports', userId) && null
  if (onReportsInboxTeamOverviewPage)
    return trackPage(`inbox-team`, 'reports', userId) && null
  if (onReportsInboxAgentsOverviewPage)
    return trackPage('inbox-agents', 'reports', userId) && null
  if (onReportsInboxTeamsOverviewPage)
    return trackPage('inbox-teams', 'reports', userId) && null
  if (onReportsInboxHappinessPage)
    return trackPage('inbox-happiness', 'reports', userId) && null
  if (onReportsKbArticlesPage)
    return trackPage('kb-articles', 'reports', userId) && null

  // KB
  if (onKbCreatePage) return trackPage('create', 'kb', userId) && null
  if (onKbDashboardPage) return trackPage('dashboard', 'kb', userId) && null
  if (onKbThemePage) return trackPage('editor-theme', 'kb', userId) && null
  if (onKbArticlesPage)
    return trackPage('listings-article', 'kb', userId) && null
  if (onKbArticleCreatePage)
    return trackPage('editor-article', 'kb', userId) && null
  if (onKbArticleEditPage)
    return trackPage('editor-article', 'kb', userId) && null
  if (onKbCategoriesPage)
    return trackPage('listing-category', 'kb', userId) && null
  if (onKbCategoryCreatePage)
    return trackPage('editor-category', 'kb', userId) && null
  if (onKbCategoryEditPage)
    return trackPage('editor-category', 'kb', userId) && null
  if (onKbSettingsPage) return trackPage('settings', 'kb', userId) && null
  if (onKbUpgradePage) return trackPage('trial-end', 'kb', userId) && null
  if (onKbUpgradeProPage) return trackPage('upgrade', 'kb', userId) && null

  return null
}

export default connect(select)(Tracking)
