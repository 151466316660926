import React from 'react'
import cn from 'classnames'
import styles from './styles.css'

const Screen = props => (
  <div {...props} className={cn(styles.screen, props.className)}>
    {props.children}
  </div>
)

export default Screen
