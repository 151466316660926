import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'

export default styled('div')`
  margin-bottom: ${themeVars.relativeLarge};

  & .label {
    margin-bottom: ${themeVars.relativeSmall};
  }

  margin-right: ${({ split }) => (split ? themeVars.relativeLarge : 0)};
  width: ${({ split }) => (split ? '50%' : 'auto')};

  &:last-child {
    margin-right: 0;
  }
`
