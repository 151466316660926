export const DEFAULT_BREAKPOINTS = [500, 555, 775]

export const NEW_SIDEBAR_SIZES = {
  maxSize: 400,
  minSize: 280,
  size: 320,
  resize: 'dynamic',
}

export const DEFAULT_WIDTHS = {
  '(max-width: 1199px)': [
    { minSize: 220, size: 240, resize: 'dynamic' },
    { minSize: 226, size: 100, resize: 'stretch' },
    { overlay: true, fixedSize: 320, minSize: 320, resize: 'fixed' },
  ],
  '(max-width: 1365px) and (min-width: 1200px)': [
    { minSize: 220, size: 240, resize: 'dynamic' },
    { minSize: 350, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
  '(max-width: 1919px) and (min-width: 1366px)': [
    { minSize: 220, size: 300, resize: 'dynamic' },
    { minSize: 500, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
  '(min-width: 1920px)': [
    { minSize: 220, size: 440, resize: 'dynamic' },
    { minSize: 900, size: 100, resize: 'stretch' },
    NEW_SIDEBAR_SIZES,
  ],
}
export const STORAGE_KEY = '3-columns-widths-v3'
