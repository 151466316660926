import React from 'react'
import { IconButton } from 'shared/ui'

const MergeButton = React.memo(({ mergeOpen, onMergeIconClick }) => {
  return (
    <div className="merge">
      {!mergeOpen && (
        <IconButton
          name="codeBranch"
          size="medium"
          className="menu-button"
          onClick={onMergeIconClick}
          tooltip="Merge [m]"
          data-test-id="ticket-merge-drawer-opener"
          svg
        />
      )}
    </div>
  )
})

export default MergeButton
