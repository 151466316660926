import { Flex, Label } from 'shared/ui'

import LinkExistingJiraServerIssueModal from './LinkExistingJiraServerIssueModal'
import LinkNewJiraServerIssueModal from './LinkNewJiraServerIssueModal'

export default function LinkJiraServerIssue() {
  // useActionEffect(doFetchJiraServerSites)
  return (
    <React.Fragment>
      <Label size="small">Link Conversation To Issue</Label>
      <Flex>
        <LinkExistingJiraServerIssueModal />
        <LinkNewJiraServerIssueModal />
      </Flex>
    </React.Fragment>
  )
}
