import React, { useCallback, useEffect } from 'react'
import { func, bool } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'

import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE } from 'ducks/drawers/types'
import {
  selectShopIsLoading,
  selectShopIsLoaded,
  selectContactEmail,
  selectDraftOrderForId,
} from 'ducks/integrations/shopify/selectors'
import doFetchShopInfo from 'ducks/integrations/shopify/operations/doFetchShopInfo'
import doFetchDraftOrderById from 'ducks/integrations/shopify/operations/doFetchDraftOrderById'
import { omit } from 'util/objects'

import FromDropdown from './FromDropdown'
import { styles } from './SendInvoice.styles'

import {
  FORM_KEY_TO,
  FORM_KEY_SUBJECT,
  FORM_KEY_CUSTOM_MESSAGE,
  FORM_KEY_FROM,
  FORM_KEY_INCLUDE_BCC,
} from './constants'

const FORM_SCHEMA = yup.object().shape({
  [FORM_KEY_TO]: yup
    .string()
    .email()
    .required(),
  [FORM_KEY_SUBJECT]: yup.string().required('You need to provide a subject'),
  [FORM_KEY_CUSTOM_MESSAGE]: yup.string().nullable(),
  [FORM_KEY_FROM]: yup
    .string()
    .email()
    .required('From email is required'),
  [FORM_KEY_INCLUDE_BCC]: yup.boolean().required(),
})

const SendInvoice = ({
  open = true,
  onClose,
  onExit,
  drawerResourceId: draftOrderId,
  drawerIntegrationId,
  prefillForm,
}) => {
  const formId = 'form-send-invoice'

  const integration = useSelector(state => {
    return selectIntegrationCredentialsForId(state, {
      id: drawerIntegrationId,
    })
  })

  const isShopInfoLoading = useSelector(state => {
    return selectShopIsLoading(state, integration?.id)
  })

  const isShopInfoLoaded = useSelector(state => {
    return selectShopIsLoaded(state, integration?.id)
  })

  const shopContactEmail = useSelector(state => {
    return selectContactEmail(state, integration?.id)
  })

  const draftOrder = useSelector(state => {
    return selectDraftOrderForId(state, draftOrderId)
  })

  const customerEmail = draftOrder?.customer?.email

  const dispatch = useDispatch()

  const {
    register,
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      [FORM_KEY_TO]: null,
      [FORM_KEY_SUBJECT]: 'Invoice {{name}}',
      [FORM_KEY_CUSTOM_MESSAGE]: null,
      [FORM_KEY_FROM]: null,
      [FORM_KEY_INCLUDE_BCC]: false,
    },
  })

  const { drawerId, openDrawer } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE,
  })

  const onSubmit = useCallback(
    payload => {
      const data = { ...omit([FORM_KEY_INCLUDE_BCC], payload), bcc: null }

      if (payload[FORM_KEY_INCLUDE_BCC] === true) {
        data.bcc = [shopContactEmail]
      }

      openDrawer(draftOrderId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerIntegrationId',
            drawerIntegrationId
          ),
        },
        additionalProps: { data },
      })
    },
    [drawerId, draftOrderId, drawerIntegrationId, openDrawer, shopContactEmail]
  )

  useEffect(
    () => {
      if (!drawerIntegrationId) return
      if (isShopInfoLoaded) return

      dispatch(doFetchShopInfo(drawerIntegrationId))
    },
    [dispatch, drawerIntegrationId, isShopInfoLoaded]
  )

  useEffect(
    () => {
      if (!drawerIntegrationId) return
      if (draftOrder?.id) return

      dispatch(doFetchDraftOrderById(drawerIntegrationId, draftOrderId))
    },
    [dispatch, drawerIntegrationId, draftOrderId, draftOrder?.id]
  )

  useEffect(
    () => {
      reset(
        {
          ...getValues(),
          [FORM_KEY_TO]: customerEmail,
          [FORM_KEY_FROM]: shopContactEmail,
        },
        {
          keepIsValid: false,
          keepDefaultValues: true,
        }
      )
    },
    [customerEmail, shopContactEmail, getValues, reset]
  )

  useEffect(
    () => {
      if (prefillForm) {
        reset(prefillForm)
      }
    },
    [reset, prefillForm]
  )

  const footer = (
    <ModalBtns
      saveBtnText="Review invoice"
      tertiaryBtnText="Cancel"
      saveBtnDisabled={!isValid}
      saveBtnHtmlType="submit"
      saveBtnForm={formId}
      onClickTertiaryBtn={onClose}
    />
  )

  return (
    <Drawer
      title="Send invoice"
      open={open}
      footer={footer}
      onClose={onExit}
      isLoading={isShopInfoLoading}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="grui pt-10"
        css={styles.content}
      >
        <Field
          css={styles.fullWidth}
          className="grui mb-5"
          label="To"
          placeholder="Receiver email address"
          htmlType="email"
          {...register(FORM_KEY_TO)}
        />
        <Checkbox
          id="bcc"
          {...register(FORM_KEY_INCLUDE_BCC)}
        >{`Send bcc to "${shopContactEmail || ''}"`}</Checkbox>
        <div>
          <div css={fieldStyles.labelBox}>From</div>
          <div css={styles.dropdownContainer}>
            {/* we cannot use StaffMembers for list as it's restricted to only Shopify Plus or Advanced store. https://shopify.dev/api/admin-graphql/2022-07/objects/StaffMember */}
            {/* TODO: should we include Groove agents emails as well? */}
            <FromDropdown
              control={control}
              list={[shopContactEmail]}
              name={FORM_KEY_FROM}
            />
          </div>
        </div>
        <Field
          css={styles.fullWidth}
          label="Subject"
          {...register(FORM_KEY_SUBJECT)}
        />
        <Textarea
          css={styles.fullWidth}
          rows={3}
          className="grui mb-12"
          label="Custom message (optional)"
          {...register(FORM_KEY_CUSTOM_MESSAGE)}
        />
      </form>
    </Drawer>
  )
}

SendInvoice.propTypes = {
  onClose: func,
  open: bool.isRequired,
}

SendInvoice.defaultProps = {
  onClose() {},
  onExit() {},
}

export default SendInvoice
