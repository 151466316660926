import React from 'react'
import { VictoryPie, VictoryContainer } from 'victory'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { warning as Warning } from 'assets/icons/groove/v2'
import { pluralize } from 'util/strings'
import { styles } from './styles'

const colorScale = ['#D5D5D5', '#767676']
const tooltipOffset = [24, 8]

const UsageCounter = ({
  total = 0,
  used = 0,
  remaining,
  onUpgradeAction,
  isCurrentUserManager,
  limitReached,
  canShowUpgradeAction,
  isAccessRequestBtnDisabled,
  currentPlanName,
}) => {
  return (
    <div
      css={[
        styles.usageCounter,
        text.styles.fontMedium,
        limitReached && styles.limitReached,
      ]}
      className="grui p-5 flex-center"
    >
      {limitReached ? (
        <Warning css={styles.warningIcon} />
      ) : (
        used > 0 && (
          <VictoryPie
            colorScale={colorScale}
            data={[{ y: used }, { y: remaining > 0 ? remaining : 0 }]}
            style={{ labels: { display: 'none' } }}
            // Remove width 100% style: https://commerce.nearform.com/open-source/victory/docs/common-props#width
            containerComponent={<VictoryContainer responsive={false} />}
            padding={0}
            width={14}
            height={14}
          />
        )
      )}
      <span className="grui ml-5">
        {limitReached
          ? 'Free limit reached'
          : `${remaining} free ${pluralize(remaining, 'use')} left`}
      </span>

      {canShowUpgradeAction && (
        <>
          <button
            className="grui mx-5"
            css={button.styles.link}
            onClick={onUpgradeAction}
            disabled={isAccessRequestBtnDisabled}
          >
            {isCurrentUserManager ? 'Upgrade plan' : 'Request access'}
          </button>
          <Tooltip
            title={
              <div className="grui text-center">
                {isCurrentUserManager
                  ? `With the ${currentPlanName} plan, you can try out ${total} AI overviews. For unlimited access to AI overviews, consider upgrading your plan.`
                  : `With the ${currentPlanName} plan, you can try out ${total} AI overviews. For unlimited access to AI overviews, please ask your account owner to upgrade your plan.`}
              </div>
            }
            position="top-end"
            strategy="fixed"
            offset={tooltipOffset}
          >
            <span
              css={styles.tooltipIcon}
              className="grui flex-center flex-shrink-0"
            >
              ?
            </span>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default UsageCounter
