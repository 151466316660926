import { connect } from 'react-redux'
import { doUploadFiles } from 'actions/attachments'
import AttachmentUpload from './view'

const perform = dispatch => ({
  upload: (fileList, forNote, draftId) =>
    dispatch(doUploadFiles(fileList, forNote, draftId)),
})

export default connect(null, perform)(AttachmentUpload)
