import React, { PureComponent } from 'react'
import cn from 'classnames'

import { runOnNextTick } from 'util/functions'

import styles from './styles.css'

class Expander extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: this.props.open,
    }
  }

  componentDidMount() {
    // We have to render twice because we need to get full height
    // of a DOM element and then we need to apply it as a `max-height`
    // for animations to work on after initial render
    runOnNextTick(() => {
      // We defer so that if we are in a modal we give the element a chance to be mounted in the DOM
      if (this.content.current) {
        this.setState({ initialMaxHeight: this.content.current.scrollHeight }) // eslint-disable-line react/no-did-mount-set-state
      }
    })
  }

  onLabelClick = () => {
    const { onOpen, onClose } = this.props
    this.setState({ isOpen: !this.state.isOpen })
    if (!this.state.isOpen && onOpen) {
      onOpen()
    } else if (this.state.isOpen && onClose) {
      onClose()
    }
  }

  render() {
    const { label, labelClass, children, className } = this.props

    const { isOpen, initialMaxHeight } = this.state

    this.content = React.createRef()
    return (
      <div
        className={cn(className, {
          [styles.isOpen]: isOpen,
        })}
      >
        <div
          className={cn(labelClass, styles.label)}
          onClick={this.onLabelClick}
        >
          {label}
          <div className={cn('Icon Icon-expand-more', styles.expandIcon)} />
        </div>
        <div
          ref={this.content}
          className={styles.content}
          style={{ maxHeight: isOpen ? initialMaxHeight || '99em' : 0 }}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default Expander
