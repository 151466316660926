import { css } from '@emotion/react'

const base = theme => css`
  .recharge-show-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 12px;
    text-align: right;
    font-size: ${theme.fontSize.tiny};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.color.monochrome.mediumDark};
    cursor: pointer;
  }

  .recharge-footer {
    background: ${theme.color.monochrome.light};
    padding: 8px 12px;
    display: flex;
    align-items: center;
  }

  .recharge-line-item {
    display: flex;
    justify-content: space-between;
    line-height: 22px;
  }

  .recharge-line-item-top {
    color: ${theme.color.monochrome.dark};
  }

  .recharge-line-item-top-quantity {
    font-size: ${theme.fontSize.xsmall};
  }

  .recharge-line-item-subtotal {
    margin-top: ${theme.spacing[3]};
    padding-top: ${theme.spacing[7]};
    color: ${theme.color.monochrome.mediumDark};
    border-top: 1px solid ${theme.color.monochrome.medium};
  }

  .recharge-line-item-price {
    color: ${theme.color.monochrome.mediumDark};
  }

  .recharge-line-item-total {
    margin-top: ${theme.spacing[3]};
    padding-top: ${theme.spacing[3]};
    border-top: 1px solid ${theme.color.monochrome.medium};
  }

  .recharge-line-item-total-price {
    font-weight: ${theme.fontWeight.medium};
  }

  .NavigatableIntegrationWidgetCard-Detail-title {
    padding: ${theme.spacing[7]};
    padding-bottom: 0;
    margin-bottom: -7px;

    .NavigatableIntegrationWidgetCard-Detail-icon.chevron {
      margin-left: -2px;
    }
  }
`

export const styles = {
  base,
}
