import React, { useCallback, useMemo, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN,
  DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { useBilling } from 'ducks/billing/hooks'
import debug from 'util/debug'
import cash from 'assets/cash.png'
import { getFormattedPrice, percentageDecrease } from 'util/numbers'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  BILLING_CYCLE_ANNUAL,
  BILLING_CYCLE_MONTHLY,
} from 'ducks/billing/constants'
import { styles } from './styles'

const AnnualOffer = ({ onExit, onClose, open, drawerUpgrade }) => {
  const [disabled, setDisabled] = useState(false)
  const { openDrawer: openBillingExternalApprove } = useDrawer({
    type: DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  })

  const {
    drawerId: upgradePlanDrawerId,
    openDrawer: openUpgradePlan,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN,
  })

  const {
    updateSubscription,
    calculateEstimatedTotalBill,
    currentPricingIds,
  } = useBilling()

  const handleUpgradeToAnnual = useCallback(
    async () => {
      setDisabled(true)

      const data = {
        cycle: BILLING_CYCLE_ANNUAL,
        pricingIds: currentPricingIds,
      }

      try {
        const response = await updateSubscription(data, {
          successMessage: 'Your account has been upgraded to annual',
        })

        const {
          updateSubscription: {
            pendingSubscription: { approvalUrl } = {},
          } = {},
        } =
          response || {}

        if (approvalUrl) {
          openBillingExternalApprove()
        }

        onExit()
      } catch (error) {
        onClose()
        debug(error)
        Bugsnag.notify(error)
      }
      setDisabled(false)
    },
    [
      openBillingExternalApprove,
      updateSubscription,
      onClose,
      onExit,
      currentPricingIds,
    ]
  )

  const handleOpenUpgradePlanConfirmationDrawer = useCallback(
    () => {
      openUpgradePlan(currentPricingIds, {
        query: {
          ...buildDrawerQueryParam(
            upgradePlanDrawerId,
            'drawerProductType',
            'inbox'
          ),
          ...buildDrawerQueryParam(
            upgradePlanDrawerId,
            'drawerBillingCycle',
            BILLING_CYCLE_ANNUAL
          ),
        },
      })
    },
    [openUpgradePlan, upgradePlanDrawerId, currentPricingIds]
  )

  const totalMonthlyBillForAYear = useMemo(
    () => calculateEstimatedTotalBill(BILLING_CYCLE_MONTHLY) * 12,
    [calculateEstimatedTotalBill]
  )

  const totalAnnualBill = useMemo(
    () => calculateEstimatedTotalBill(BILLING_CYCLE_ANNUAL),
    [calculateEstimatedTotalBill]
  )

  const totalSavings = Math.max(0, totalMonthlyBillForAYear - totalAnnualBill)
  const savingsPercent = Math.max(
    0,
    Math.trunc(percentageDecrease(totalMonthlyBillForAYear, totalAnnualBill))
  )

  const saveRate = <strong>save up to {savingsPercent}%</strong>

  return (
    <Modal
      portal="#overlays"
      open={open}
      className="grui pb-14"
      css={styles.annualOffer}
      onClose={onExit}
    >
      <div css={styles.imgWrapper}>
        <img src={cash} alt="money" />
      </div>
      <div className="grui text-center px-17">
        <Modal.Title className="grui mt-16">
          Ready for some savings?
        </Modal.Title>
        <Modal.Description>
          {drawerUpgrade ? (
            <>
              By switching over to an annual plan you’ll <br />
              {saveRate} on your Groove account!
            </>
          ) : (
            <>Switch to an annual plan to {saveRate} on your Groove account!</>
          )}
        </Modal.Description>
        <div>
          <strong css={styles.savingTitle}>Your savings per year</strong>
          <strong className="grui tilt-highlight" css={styles.saving}>
            <span>{getFormattedPrice(totalSavings, 'USD')}</span>
          </strong>
        </div>
        {!drawerUpgrade && (
          <Modal.Description>
            When you move from a monthly to annual plan you’ll continue to get
            all the time-saving and productivity-enhancing benefits of Groove’s{' '}
            {app.t('Mailbox')} and Knowledge Base at a better rate.
          </Modal.Description>
        )}
        <Button
          type="info"
          size="xBig"
          className="grui mt-11 mb-5"
          onClick={
            drawerUpgrade
              ? handleOpenUpgradePlanConfirmationDrawer
              : handleUpgradeToAnnual
          }
          disabled={disabled}
          id="cta-annual-offer"
        >
          Upgrade me to annual
        </Button>
        <Modal.Description css={styles.note}>
          *By upgrading now, your credit card will be{' '}
          {drawerUpgrade ? 'pro-rata' : ''} billed immediately
        </Modal.Description>
      </div>
    </Modal>
  )
}

export default AnnualOffer
