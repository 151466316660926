import React from 'react'
import cn from 'classnames'
import Badge from 'components/Badge'
import styles from './styles.css'

const NormalState = ({ onClick, label, state, ...rest }) => {
  return (
    <Badge
      className={cn(styles.base, styles[`state-${state}`])}
      onClick={onClick}
      {...rest}
    >
      {label}
    </Badge>
  )
}

export default NormalState
