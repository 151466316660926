export const TRELLO_COLORS = {
  default: 'B6BBBF',
  green: '4BCE97',
  yellow: '7F5F01',
  orange: 'A54800',
  red: 'AE2E24',
  purple: '5E4DB2',
  blue: '0055CC',
  sky: '206A83',
  lime: '4C6B1F',
  pink: '943D73',
  black: '596773',
  green_dark: '1F845A',
  yellow_dark: '533f04',
  orange_dark: '702E00',
  red_dark: '5D1F1A',
  purple_dark: '352C63',
  blue_dark: '09326C',
  sky_dark: '164555',
  lime_dark: '94C748',
  pink_dark: '50253F',
  black_dark: '454F59',
  green_light: '164B35',
  yellow_light: 'E2B203',
  orange_light: 'FEA362',
  red_light: 'F87168',
  purple_light: '9F8FEF',
  blue_light: '579DFF',
  sky_light: '6CC3E0',
  lime_light: 'D3F1A7',
  pink_light: 'E774BB',
  black_light: '8C9BAB',
}
