import React from 'react'
import { dumbLinkify } from 'util/strings'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import BodySearchMarker from '../BodySearchMarker'

export default class TextBody extends React.Component {
  constructor() {
    super()
    this.images = []
    this.state = {
      bodyNode: null,
    }
  }
  componentWillUnmount() {
    this.images.forEach(image => {
      if (image.lightboxListener) {
        image.removeEventListener('click', image.lightboxListener)
        // eslint-disable-next-line no-param-reassign
        image.lightboxListener = null
      }
    })
    this.images = null
  }
  onBodyNode = node => {
    if (!node || !node.querySelectorAll) return
    const { onOpenImage } = this.props
    this.setState({
      bodyNode: !node || !node.querySelectorAll ? null : node,
    })
    const images = node.querySelectorAll('img')
    images.forEach(image => {
      if (!image.lightboxListener) {
        // eslint-disable-next-line no-param-reassign
        image.lightboxListener = () =>
          onOpenImage(this.transformImageArguments(image))
        image.addEventListener('click', image.lightboxListener)
        this.images.push(image)
      }
    })
  }

  transformImageArguments = image => [{ url: image.src }]

  render() {
    const { text } = this.props
    return (
      <>
        <SanitizedHTML
          className="body"
          html={dumbLinkify(text, { showFullUrl: true })}
          onBodyNode={this.onBodyNode}
        />
        <BodySearchMarker node={this.state.bodyNode} />
      </>
    )
  }
}
