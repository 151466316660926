import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdown = theme => css`
  padding: ${rem(8)};
  border: 1px solid ${theme.color.monochrome.light};
  box-shadow: 0px 2px 4px 0px rgba(${theme.color.monochrome.black_rgba}, 0.1) !important;

  svg {
    width: ${rem(16)};
    height: ${rem(16)};
    margin-right: ${rem(8)};
  }
`

const btn = css`
  display: block;

  img {
    display: block;
    border-radius: ${rem(6)};
  }
`

const heading = theme => css`
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${rem(4)};
  display: flex;
  align-items: center;
  padding: ${rem(6)} ${rem(8)};
  line-height: ${theme.lineHeight.leadingTight};
  font-family: ${theme.font.quaternary};
  letter-spacing: 1.1px;
`

const item = theme => css`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${rem(6)} ${rem(8)};
  line-height: ${theme.lineHeight.leadingTight};
  transition: all 0.2s ease;

  svg {
    color: ${theme.color.monochrome.mediumDark};
  }

  &.active {
    background-color: ${theme.color.primary.info};
    color: ${theme.color.monochrome.white};
    border-radius: ${rem(4)};

    svg {
      color: inherit;
    }
  }
`

export const styles = {
  dropdown,
  btn,
  heading,
  item,
}
