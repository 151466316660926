import ArrayOfStringsWithPrimary from 'shared/components/ui/Form/ArrayOfStringsWithPrimary'

const MAX_DOMAINS = 6

export default function CompanyHeaderFieldsDomains({
  domains,
  doRecalcMenuPosition,
}) {
  return (
    <ArrayOfStringsWithPrimary
      maxRecords={MAX_DOMAINS}
      noun={'domain'}
      onCountChange={doRecalcMenuPosition}
      primaryName="company_domain"
      records={domains}
      secondaryName="company_secondary_domains"
      title="Domains"
    />
  )
}
