import React, { PureComponent } from 'react'
import cn from 'classnames'

import ClassicView from './InnerListItem/ClassicView'
import ModernView from './InnerListItem/ModernView'

export default class ListItem extends PureComponent {
  constructor(props) {
    super(props)
    this.listItemRef = React.createRef()
  }

  componentDidMount() {
    // position selected conservation to middle of list on first load
    // only positions to middle/center if item is out of scroll view
    this.scrollToSelectedListItem('center')
  }

  componentDidUpdate = ({ active: prevActive }) => {
    const { active } = this.props
    if (prevActive !== active) {
      this.scrollToSelectedListItem()
    }
  }

  scrollToSelectedListItem(block = 'nearest') {
    const {
      props: { active, isLastViewed } = {},
      listItemRef: { current } = {},
    } = this

    // scroll item to view if active/selected (4-col view), or was last viewed (3-col view)
    if (current && (active || isLastViewed)) {
      if (isLastViewed) {
        // Left nav shifts by 1px if we use scrollIntoView()
        current.scrollIntoViewIfNeeded()
      } else {
        current.scrollIntoView({ behavior: 'auto', block })
      }
    }
  }

  render() {
    const {
      conversationId,
      active,
      selected,
      isUnread,
      is3ColumnView,
      isLastViewed,
      present,
    } = this.props
    if (!present) return null
    const View = is3ColumnView ? ClassicView : ModernView
    return (
      <div
        ref={this.listItemRef}
        id={`ConversationListItem-${conversationId}`}
        className={cn('conversation-list-item', {
          current: active,
          selected,
          unread: isUnread,
          classic: is3ColumnView,
          modern: !is3ColumnView,
          'last-viewed': isLastViewed,
        })}
      >
        <View {...this.props} active={active} />
      </div>
    )
  }
}
