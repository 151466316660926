import styled from '@emotion/styled'

export default styled('img')`
  .sui .ui.dropdown .menu > .item > & {
    display: inline;
    float: left;
    height: 20px;
    margin-top: 2px;
  }
`
