import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { doLabelSelectedTickets } from 'actions/labelings/bulk'
import LabelingDropdownV2 from 'components/App/DesktopView/Labels/LabelingDropdownV2'
import { IconButton } from 'shared/ui'

const perform = {
  onApply: doLabelSelectedTickets,
}

// eslint-disable-next-line react/prefer-stateless-function
class BulkTagIcon extends PureComponent {
  render() {
    const { iconSize = 17, ...rest } = this.props
    return (
      <LabelingDropdownV2
        key="tag"
        mode="bulk"
        {...rest}
        trigger={
          <IconButton
            key="tag-icon"
            name="tag"
            tooltip="Tag"
            iconSize={iconSize}
            size="medium"
            svg
          />
        }
      />
    )
  }
}

export default connect(null, perform)(BulkTagIcon)
