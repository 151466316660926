import styled from '@emotion/styled'

const CarouselWindow = styled('div')`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  overflow: hidden;
  position: relative;
  border: 1px solid ${props => props.theme.color.monochrome.medium};
`
CarouselWindow.displayName = 'CarouselWindow'
export default CarouselWindow
