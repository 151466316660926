import React from 'react'
import { css } from '@emotion/react'
import cn from 'classnames'

import PanelGroup from 'components/PaneGroup'
import RightSidebar from 'components/App/DesktopView/Layout/RightSidebar'
import MainCell from 'components/App/DesktopView/Layout/MainCell'
import withPanelWidthManager from 'components/App/DesktopView/Layout/withPanelWidthManager'

import TicketListPane from './TicketListPane'
import RightSidebarToggler from '../../RightSidebar/Toggler'
import useRightSidebar from '../../RightSidebar/useRightSidebar'

import styles from '../styles.css'

import { DEFAULT_WIDTHS, DEFAULT_BREAKPOINTS, STORAGE_KEY } from './defaults'

class ClassicView extends React.PureComponent {
  // Length has to match panes count
  componentWrappers = [
    null,
    React.forwardRef((props, ref) => {
      const { isRightSidebarCollapsed } = this.props
      const { onTransitionEnd, finalStyle } = useRightSidebar({
        isRightSidebarCollapsed,
        style: props.style,
      })
      return (
        <div
          {...props}
          ref={ref}
          onTransitionEnd={onTransitionEnd}
          style={finalStyle}
          className={cn(
            props.className,
            styles.rightSidebarWrapper,
            'hidden-in-print'
          )}
        />
      )
    }),
  ]

  render() {
    const {
      areTicketsSelected,
      hasSearchErrored,
      isBulkSelectionMode,
      isMerging,
      isNewConversation,
      isNewEmail,
      isQueryStringValid,
      isUpdateAvailable,
      queryObject,
      queryString,
      queryStringIsComplete,
      ticketId,
      panelWidths = [],
      panelUpdateCallback,
      ...rest
    } = this.props

    const shouldDisplayList = !isNewEmail && !isNewConversation && !ticketId

    if (shouldDisplayList) {
      return (
        <TicketListPane
          hasSearchErrored={hasSearchErrored}
          isQueryStringValid={isQueryStringValid}
          queryObject={queryObject}
          queryString={queryString}
        />
      )
    }

    return (
      <React.Fragment>
        <RightSidebarToggler />
        <PanelGroup
          css={css`
            min-height: 0;
            @media print {
              min-width: 0 !important;
            }
          `}
          spacing={0}
          panelWidths={panelWidths}
          onUpdate={panelUpdateCallback}
          ComponentWrappers={this.componentWrappers}
          breakpoints={DEFAULT_BREAKPOINTS}
          className="three-column-view"
        >
          <MainCell
            areTicketsSelected={areTicketsSelected}
            isBulkSelectionMode={isBulkSelectionMode}
            isClassicView
            isMerging={isMerging}
            isNewConversation={isNewConversation}
            isNewEmail={isNewEmail}
            isUpdateAvailable={isUpdateAvailable}
            ticketId={ticketId}
            {...rest}
          />
          <RightSidebar panelWidth={(panelWidths[2] || {}).size} />
        </PanelGroup>
      </React.Fragment>
    )
  }
}

export default withPanelWidthManager(DEFAULT_WIDTHS, STORAGE_KEY)(ClassicView)
