import { css } from '@emotion/react'

const container = css`
  margin-left: auto;
  margin-right: auto;
  position: relative;

  & > img {
    max-width: 100%;
    display: block;
  }
`

const content = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const styles = {
  container,
  content,
}
