import { css } from '@emotion/react'

const usageCounter = theme => css`
  border-bottom: 1px solid rgba(${theme.color.monochrome.black_rgba}, 0.1);
  background: rgba(255, 182, 39, 0.1);
  color: rgba(${theme.color.monochrome.black_rgba}, 0.8);
  font-size: ${theme.fontSize.base};

  a,
  button {
    font-weight: inherit;
    color: ${theme.color.primary.info};

    &:hover,
    &:focus {
      color: ${theme.color.info['600']};
    }

    &:disabled {
      color: ${theme.color.info['200']};
    }
  }
`

const limitReached = css`
  background-color: #ffeeee;
`

const tooltipIcon = theme => css`
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.1);
  width: ${theme.spacing[10]};
  height: ${theme.spacing[10]};
  border-radius: 50%;
  display: block;
  font-size: 9px;
  font-weight: ${theme.fontWeight.bold};
  color: rgba(${theme.color.monochrome.black_rgba}, 0.6);
`

const warningIcon = css`
  path {
    fill: #ff5454;
  }
`

export const styles = {
  usageCounter,
  limitReached,
  tooltipIcon,
  warningIcon,
}
