import React from 'react'
import styled from '@emotion/styled'
import { doFetchEmailMarketingIntegrations } from 'ducks/integrations/emailMarketing'
import ContactDetailsColumn from 'components/crm/ContactDetailsColumn'
import { useActionEffect } from 'util/hooks'

const StyledNewRightSidebar = styled('div')`
  flex-grow: 1;
  max-width: 100%;
  background: ${props => props.theme.color.monochrome.superLight};
  border-left: 1px solid ${props => props.theme.color.monochrome.medium};
  .sui {
    p {
      line-height: 1.62;
    }
    ul,
    ol {
      line-height: 1.62;
      padding-inline-start: 24px;
    }
  }
`

export default function RightSidebar({ panelWidth }) {
  useActionEffect(doFetchEmailMarketingIntegrations, [])

  return (
    <StyledNewRightSidebar className="NewRightSidebar grui relative">
      <ContactDetailsColumn width={panelWidth} />
    </StyledNewRightSidebar>
  )
}
