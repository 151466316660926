import { connect } from 'react-redux'
import editor from 'shared/editor/utils'

import View from './view'

const perform = (dispatch, ownProps) => {
  return {
    onSelectEmoji: emoji => {
      const editorInstance = ownProps.editor || (editor && editor.getEditor())

      if (emoji && editorInstance && editorInstance.fire) {
        return editorInstance.fire('emoji-insert', {
          emoji,
        })
      }
      return null
    },
  }
}

export default connect(null, perform)(View)
