import { css } from '@emotion/react'
import cn from 'classnames'

import themeVars from 'ui_theme/site/globals/site.variables'

const cssClassName = () => theme => css`
  margin-left: 10px;
  height: 0;
  margin-top: 4px;
  position: relative;
  z-index: 2;

  > div {
    margin-left: 4px;
    color: ${theme.color.monochrome.mediumDark};
    cursor: pointer;
    font-size: ${themeVars.tiny};
    font-weight: ${themeVars.graphikWeightMedium};
    text-align: left;
    float: right;
    padding: ${themeVars.nano};
    border-left: 1px solid ${theme.color.monochrome.medium};
  }

  &:hover > div {
    color: ${theme.color.primary.brand};
  }

  &.noMoreFields {
    height: auto;
    > div {
      border-left: none;
      float: none;
      text-align: right;
    }
  }
`
export default function ReorderableAndHideableComponentsActions({
  noMoreFields,
  isInEditMode,
  onDone,
  onEdit,
}) {
  return (
    <div
      css={cssClassName}
      className={cn('editModeButton', {
        noMoreFields,
        isInEditMode,
      })}
    >
      {!isInEditMode && <div onClick={onEdit}>Edit</div>}
      {isInEditMode && <div onClick={onDone}>Done</div>}
    </div>
  )
}
