/* eslint-disable global-require */
import React, { useEffect } from 'react'
import Layout from 'components/App/DesktopView/Layout'
import SubAppLoader, { preloadSubapp } from 'core/subapps'

const pages = require('components/App/DesktopView/Pages')

export default function DesktopPage({
  componentName,
  DucksPageComponent,
  ducksPageProps,
  props,
  subapp,
}) {
  useEffect(() => {
    preloadSubapp('chat')
  }, [])

  if (!DucksPageComponent && subapp)
    return (
      <SubAppLoader
        subapp={subapp}
        subprops={props}
        componentName={componentName}
      />
    )

  if (DucksPageComponent) {
    return <DucksPageComponent {...ducksPageProps} />
  }
  const Page = pages[componentName]
  if (Page) return <Page {...props} isDesktop />

  // Otherwise just assume its a ticket page known to Layout.
  return <Layout />
}
