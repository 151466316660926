import React from 'react'
import cn from 'classnames'
import { string, arrayOf } from 'prop-types'
import { Check } from '@groovehq/internal-design-system/lib/assets/icons'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

const Features = ({ className, list = [] }) => {
  return (
    <div
      css={[text.styles.textSm, text.styles.fontSemibold]}
      className={cn('grui flex items-center justify-center', className)}
    >
      {list.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="grui mx-3" key={index}>
          <Check
            width={24}
            height={24}
            className="grui align-middle mr-3 mb-2"
          />
          {item}
        </div>
      ))}
    </div>
  )
}

Features.propTypes = {
  className: string,
  list: arrayOf(string),
}

Features.defaultProps = {
  className: undefined,
  list: [],
}

export default Features
