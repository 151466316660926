import React from 'react'
import cn from 'classnames'
import { Divider, Button, Flex } from 'shared/ui'

const Actions = ({
  className,
  hasPendingEdits,
  onApply,
  onCancel,
  onManageTagsClick,
  onCreateTagClick,
}) => {
  return (
    <React.Fragment>
      <Divider className="grui mt-4" />
      <Flex className={cn('grui p-10 justify-end flex-col', className)}>
        {!hasPendingEdits && (
          <Flex>
            <Button basic onClick={onManageTagsClick} size="small">
              Manage tags
            </Button>
            <Button
              onClick={onCreateTagClick}
              primary
              className="grui ml-4"
              size="small"
            >
              Create a tag
            </Button>
          </Flex>
        )}
        {(hasPendingEdits && (
          <Flex className="grui justify-end" key="actions">
            <Button basic size="small" onClick={onCancel} key="cancel">
              Cancel
            </Button>
            <Button
              primary
              size="small"
              key="apply"
              onClick={onApply}
              className="grui ml-4"
            >
              Apply
            </Button>
          </Flex>
        )) ||
          undefined /* dont render "0" as false */}
      </Flex>
    </React.Fragment>
  )
}

export default Actions
