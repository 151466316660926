import { css } from '@emotion/react'

const primary = theme => css`
  &.active,
  &:hover,
  &:active {
    color: ${theme.color.brand['900']};
  }
`

const note = theme => css`
  &.active {
    color: ${theme.color.primary.warningText};
  }

  &:hover {
    color: ${theme.color.primary.warning};
  }

  &:active {
    color: ${theme.color.warning['700']};
  }
`

const types = {
  primary,
  note,
}

const base = props => css`
  height: 100%;
  color: ${props.theme.color.brand['900']};
  cursor: pointer;
  font-size: ${props.theme.fontSize.small};
  font-weight: ${props.theme.fontWeight.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  transition: color 0.2s;

  &:hover {
    text-decoration: underline;
    .Icon {
      text-decoration: none;
    }
  }

  &.active {
    color: ${props.theme.color.brand['900']};
  }

  ${types[props.type] && types[props.type](props.theme)};
`

export const styles = {
  base,
}
