import React, { useCallback } from 'react'

import { SUI } from 'shared/ui'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  DRAWER_TYPE_TAGS_OVERVIEW,
  DRAWER_TYPE_TAGS_UPDATE,
} from 'ducks/drawers/types'

import Header from './Header'
import SearchableList from './SearchableList'
import Actions from './Actions'

const EditPane = props => {
  const { onClose, onCheckboxChanged, onApply, hasPendingEdits } = props

  const { openDrawer: openTagsOverviewDrawer } = useDrawer({
    type: DRAWER_TYPE_TAGS_OVERVIEW,
  })

  const {
    drawerId: tagCreateDrawerId,
    openDrawer: openTagCreateDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_TAGS_UPDATE,
  })

  const onManageTagsClick = useCallback(
    () => {
      onClose()
      openTagsOverviewDrawer()
    },
    [openTagsOverviewDrawer, onClose]
  )

  const onCreateTagClick = useCallback(
    ({ name: initialTagName }) => {
      onClose()
      openTagCreateDrawer('new', {
        query: {
          ...buildDrawerQueryParam(
            tagCreateDrawerId,
            // this will select the tag in the tags assignment dropdown
            'drawerAddAsSelected',
            true
          ),
          ...buildDrawerQueryParam(
            tagCreateDrawerId,
            // this will open the tags assignment dropdown on save or close of create drawer
            'drawerOpenTagsAssignModalOnExit',
            true
          ),
        },
        additionalProps: {
          // if passed, prefill the name form field with value
          initialTagName,
        },
      })
    },
    [openTagCreateDrawer, onClose, tagCreateDrawerId]
  )

  const onClickTag = useCallback(
    (_, label) => {
      onCheckboxChanged({
        ...label,
        status: label.status ? null : 'checked',
      })
    },
    [onCheckboxChanged]
  )

  return (
    <React.Fragment>
      <SUI>
        <Header key="header" text="Add some tags" />
      </SUI>
      <SearchableList
        key="list"
        items={props.labels}
        onSelect={onClickTag}
        hidden={props.hidden}
        onCreateTagClick={onCreateTagClick}
        {...props}
        onClose={undefined}
        onShiftEnter={hasPendingEdits ? onApply : undefined}
      />
      <SUI>
        <Actions
          key="actions"
          onManageTagsClick={onManageTagsClick}
          onCreateTagClick={onCreateTagClick}
          {...props}
        />
      </SUI>
    </React.Fragment>
  )
}

export default EditPane
