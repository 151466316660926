import { connect } from 'react-redux'

import selectIntegrationSettingsForProvider from 'ducks/integrations/selectors/selectIntegrationSettingsForProvider'

import IntegrationActionContentJiraCloud from '../IntegrationActionContentJiraCloud/view'

export default connect(state => ({
  provider: 'jira_server',
  settings: selectIntegrationSettingsForProvider(state, {
    provider: 'jira_server',
  }),
}))(IntegrationActionContentJiraCloud)
