import React from 'react'

import { Loader } from 'shared/ui'

import { getLength } from 'util/arrays'
import { propFunc } from 'util/functions'

import ListItem from './ListItem'
import SearchingEmptyState from '../../shared/SearchingEmptyState'
import styles from './styles.css'

const List = ({
  onEdit,
  onAdd,
  onDelete,
  isFetchingSearchResults,
  labels,
  searchTerm,
}) => {
  if (isFetchingSearchResults) return <Loader className={styles.loader} />

  if (!isFetchingSearchResults && getLength(labels) <= 0) {
    return (
      <SearchingEmptyState isDesktop searchTerm={searchTerm} onAdd={onAdd} />
    )
  }

  return (
    <div className={styles.list}>
      {labels.map(label => (
        <ListItem
          key={label.id}
          label={label}
          onDelete={propFunc(onDelete, label)}
          onEdit={propFunc(onEdit, label)}
        />
      ))}
    </div>
  )
}

export default List
