import React from 'react'
import { useSelector } from 'react-redux'
import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import Scroller from 'components/Scroller'
import SearchErrorMessage from 'components/App/Pages/SearchPage/SearchErrorMessage'
import DemoInboxCard from 'components/TrialSetup/DemoInboxCard'
import OnboardingWorkflowCard from 'components/TrialSetup/OnboardingWorkflowCard'
import TicketList from 'subapps/ticketing/components/TicketList'
import LimitSearchHistoryCard from 'subapps/ticketing/components/LimitSearchHistoryCard'
import { Loader } from 'shared/ui'
import { selectShowDemoInboxCard } from 'selectors/trial_setup'
import { selectIsSettingUpTheCurrentMailBox } from 'selectors/mailboxes'
import { selectCurrentMailboxHasTickets } from 'selectors/mailboxes/selectCurrentMailboxHasTickets'
import { selectDoesCurrentMailboxHaveState } from 'selectors/mailboxes/selectDoesCurrentMailboxHaveState'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectShowContinueForwardingSetupPane } from 'selectors/layout'
import { selectSearchesRequestState } from 'selectors/search'
import { selectHasCurrentTicketSearchLoaded } from 'selectors/search/base'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectShowOnboardingWorkflowInConversationList } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflowInConversationList'
import { selectIsRefreshingSearches } from 'selectors/app'

import ActionBar from './ActionBar'
import { styles } from './styles'
import MailboxContinueForwardingSetupBar from '../../../MailboxContinueForwardingSetupBar'

// Wraps the ticket list container and adds mouseEnter/Leave events
export default function TicketListPane({
  hasSearchErrored,
  isQueryStringValid,
  queryString,
}) {
  const showDemoInboxCard = useSelector(selectShowDemoInboxCard)
  const showOnboardingWorkflow = useSelector(
    selectShowOnboardingWorkflowInConversationList
  )
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const currentMailboxHasTickets = useSelector(selectCurrentMailboxHasTickets)
  const isCurrentMailboxConfirmedOrUnconfirmed = useSelector(state =>
    selectDoesCurrentMailboxHaveState(state, ['unconfirmed'], null, [
      'FORWARDING',
    ])
  )
  const isSettingUpTheCurrentMailBox = useSelector(
    selectIsSettingUpTheCurrentMailBox
  )
  const showContinueForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )
  const { loaded, error } = useSelector(selectSearchesRequestState)
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const isSearchesLoaded = loaded && !error

  const showMailboxContinueForwardingSetupBar =
    isCurrentMailboxConfirmedOrUnconfirmed &&
    !isSettingUpTheCurrentMailBox &&
    !showContinueForwardingSetupPane &&
    isSearchesLoaded &&
    isManager

  const isRefreshingSearches = useSelector(selectIsRefreshingSearches)
  const isCurrentSearchLoaded = useSelector(selectHasCurrentTicketSearchLoaded)
  const isSearchError = queryString && !isQueryStringValid && !hasSearchErrored

  const showAllScreenLoader =
    !isCurrentSearchLoaded && !isSearchError && !isRefreshingSearches
  const showActionBar =
    (currentMailboxHasTickets === true ||
      !currentMailboxId ||
      isRefreshingSearches) &&
    !isSearchError

  if (showAllScreenLoader) {
    return (
      <div css={[styles.base]} className="grui justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div css={[styles.base]}>
      {showActionBar && <ActionBar />}

      {isManager && (
        <>
          <LimitSearchHistoryCard className="grui mx-10 my-5" />
          {showOnboardingWorkflow && (
            <OnboardingWorkflowCard className="grui mb-14 mt-14" />
          )}
        </>
      )}

      {queryString && hasSearchErrored && <SearchErrorMessage />}

      {isSearchError && (
        <Scroller className="desktopNormalScrollbarDisableRightBorder">
          <InvalidSearchMessage queryString={queryString} />
        </Scroller>
      )}
      {showMailboxContinueForwardingSetupBar && (
        <MailboxContinueForwardingSetupBar />
      )}

      {(!queryString || (!hasSearchErrored && isQueryStringValid)) && (
        <TicketList is3ColumnView isSidebarOpen />
      )}
      {!showOnboardingWorkflow && showDemoInboxCard && <DemoInboxCard />}
    </div>
  )
}
