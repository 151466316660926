import { css } from '@emotion/react'

const container = css`
  display: flex;
  flex-direction: column;
`

const content = css`
  & > *[role='separator'] {
    max-width: 280px;
  }

  ul > li:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const iframeWrapper = theme => css`
  border: 1px solid ${theme.color.divider};
  border-radius: 4px;
  overflow: hidden;
  min-height: 300px;
  background-color: ${theme.color.monochrome.white};

  iframe {
    border: 0;
  }
`

const icon = css`
  display: inline-block;
  margin-left: 5px;
`

export const styles = {
  container,
  content,
  icon,
  iframeWrapper,
}
