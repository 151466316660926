import { css } from '@emotion/react'

const productCell = css`
  /* Image is in a separate cell, but it should be shown together with product name under the header "product",
   * image cell is too big because of the length of "product" text, so need to move the name to the left a little bit.
   * We could use colspan in the header to prevent this issue, but it seems like react-table don't support this yet
  */
  margin-left: -10px;
`

const footer = css`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const footerBtnHalfWidth = css`
  width: calc(50% - 4px);
`

const refundOrderTable = css`
  tbody > tr {
    td {
      &.cell-product-name {
        padding-right: 16px;
        max-width: 365px;
      }

      &.cell-quantity {
        text-align: left;
        width: 140px;
        max-width: auto;
      }

      &.cell-image {
        width: 40px;
      }
    }
  }
`

export const styles = {
  footer,
  refundOrderTable,
  productCell,
  footerBtnHalfWidth,
}
