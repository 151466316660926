import { connect } from 'react-redux'
import { selectLocationState } from 'redux-first-router'
import OauthError from './view'

const select = state => {
  const payload = selectLocationState(state).payload
  return {
    service: payload.service,
    error: payload.error,
  }
}

export default connect(select)(OauthError)
