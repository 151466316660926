import React from 'react'
import cn from 'classnames'

const SidebarPlaceholderButton = ({ isRightSidebarCollapsed }) => (
  <div
    className={cn('sidebarplaceholder', {
      open: !isRightSidebarCollapsed,
    })}
  >
    <div key="divider-sidebar" className="divider" />
    <div className="spacer" />
  </div>
)

export default SidebarPlaceholderButton
