import React from 'react'
import { Icon, Tooltip } from 'shared/ui'
import ListenToKeyboard from 'components/ListenToKeyboard'

export default class StarIcon extends React.PureComponent {
  state = { hovered: false }

  onHover = () => {
    this.setState({ hovered: true })
  }

  onBlur = () => {
    this.setState({ hovered: false })
  }

  render() {
    const { disabled = false, isStarred, onStarClick, size } = this.props
    return (
      <div
        className="StarIcon"
        onMouseEnter={this.onHover}
        onMouseLeave={this.onBlur}
      >
        <ListenToKeyboard
          onHyphen={onStarClick}
          disableForInput
          preventDefault
          key="keyboard-listener"
        />
        <Tooltip
          position="bottom"
          tooltip={isStarred ? 'Unstar [-]' : 'Star [+]'}
        >
          {isStarred && (
            <Icon
              name="star"
              onClick={!disabled ? onStarClick : null}
              color="color.primary.warning"
              size={size}
            />
          )}
          {!isStarred && (
            <Icon
              name="star-outline"
              onClick={!disabled ? onStarClick : null}
              color={
                this.state.hovered
                  ? 'color.primary.warning'
                  : 'color.monochrome.mediumDark'
              }
              size={size}
            />
          )}
        </Tooltip>
      </div>
    )
  }
}
