import React from 'react'
import debug from 'util/debug'
import ListenToKeyboard from 'components/ListenToKeyboard'

// This component listens to events and dispatches whatever handlers you pass
// it. But it also has a concept of an index (i.e.focusedIndex) . This index
// should be managed by whatever parent component includes this component.
// KeyboardHandler will simply dispatch given handlers with updated indices on
// Up/Down events.
class KeyboardHandler extends React.Component {
  static defaultProps = {
    count: undefined,
    onEnter: undefined,
    onTab: undefined,
    onEsc: undefined,
    onDown: undefined,
    onUp: undefined,
    onLeft: undefined,
    onRight: undefined,
    onPageUp: undefined,
    onPageDown: undefined,
    onShiftEnter: undefined,
    pageSize: 10,
  }

  onEnter = evt => {
    debug('Lists/KBHandler: onEnter')
    if (this.props.onEnter) this.props.onEnter(this.props.focusedIndex, evt)
  }

  onShiftEnter = evt => {
    debug('Lists/KBHandler: onShiftEnter')
    if (this.props.onShiftEnter)
      this.props.onShiftEnter(this.props.focusedIndex, evt)
  }

  onTab = evt => {
    debug('Lists/KBHandler: onTab')
    if (this.props.onTab) this.props.onTab(this.props.focusedIndex, evt)
  }

  onEsc = evt => {
    if (this.props.onEsc) {
      this.props.onEsc(evt)
    }
  }

  onDown = evt => {
    if (!this.props.onDown) return
    this.props.onDown((this.props.focusedIndex + 1) % this.props.count, evt)
  }

  onUp = evt => {
    if (!this.props.onUp) return
    let newIndex = (this.props.focusedIndex - 1) % this.props.count
    if (newIndex === -1) newIndex = this.props.count - 1
    this.props.onUp(newIndex, evt)
  }

  onRight = evt => {
    if (this.props.onRight) this.props.onRight(this.props.focusedIndex, evt)
  }

  onLeft = evt => {
    if (this.props.onLeft) this.props.onLeft(this.props.focusedIndex, evt)
  }

  onPageDown = evt => {
    const { onPageDown, pageSize, focusedIndex, count } = this.props
    if (!onPageDown) return
    let newFocusedIndex = focusedIndex + pageSize
    if (newFocusedIndex > count) newFocusedIndex = count - 1

    this.props.onPageDown(newFocusedIndex, evt)
  }

  onPageUp = evt => {
    const { onPageUp, pageSize, focusedIndex } = this.props
    if (!onPageUp) return
    let newFocusedIndex = focusedIndex - pageSize
    if (newFocusedIndex < 0) newFocusedIndex = 0
    this.props.onPageDown(newFocusedIndex, evt)
  }

  reset = () => {
    if (this.focusedIndex === null) return
    this.setIndex(null)
  }

  render() {
    return (
      <React.Fragment>
        <ListenToKeyboard
          onEnter={this.onEnter}
          preventDefault
          stopPropagation
        />
        <ListenToKeyboard
          onDown={this.onDown}
          onEscape={this.onEsc}
          onLeft={this.onLeft}
          onPageDown={this.onPageDown}
          onPageUp={this.onPageUp}
          onRight={this.onRight}
          onShiftEnter={this.onShiftEnter}
          onTab={this.onTab}
          onUp={this.onUp}
        />
      </React.Fragment>
    )
  }
}

export default KeyboardHandler
