import React from 'react'
import cn from 'classnames'

import Modal from 'components/Modal'
import { Flex } from 'shared/ui'

import EditPane from './EditPane'
import CreatePane from './CreatePane'
import styles from './styles.css'

// We wrap the create and edit states inside the same modal to simplify the
// transition effect between them.
const EditModal = ({
  bottom = 20,
  isCreatingALabel,
  labelToEdit,
  offset,
  onClose,
  open,
  hidden,
  ...rest
}) => {
  if (!open) return null
  const heightReduction = offset.top + bottom
  return (
    <Modal
      styleContainer={{
        visibility: hidden ? 'hidden' : 'visible',
        maxHeight: `calc(100% - ${heightReduction}px)`,
        overflow: 'visible' /* hack for color picker */,
        display: 'flex',
      }}
      offset={offset}
      onBackgroundClick={onClose}
    >
      <Flex className={cn('grui flex-col', styles.tags)}>
        {!isCreatingALabel && (
          <EditPane
            top={offset.top}
            bottom={offset.bottom}
            onClose={onClose}
            hidden={hidden}
            {...rest}
          />
        )}
        {isCreatingALabel && (
          <CreatePane initialValues={labelToEdit} {...rest} />
        )}
      </Flex>
    </Modal>
  )
}

// Hackery so that we can fetch Top Labels on mount.

class MountedModal extends React.Component {
  componentDidMount() {
    const { onModalOpen } = this.props
    if (onModalOpen) onModalOpen()
  }

  runAndClose = fn => (...args) => {
    fn(...args)
    this.props.onClose()
  }

  closeAndRun = fn => (...args) => {
    this.props.onClose()
    return fn(...args)
  }

  render() {
    const { onApply, doBeginManageLabels, doToggleLabel, onClose } = this.props

    return (
      <EditModal
        {...this.props}
        // We no longer manage the menu open/close state inside redux, so we
        // wrap the actions that should close the modal menu inside a fn that
        // calls the onClose callback
        doBeginManageLabels={this.closeAndRun(doBeginManageLabels)}
        doToggleLabel={this.closeAndRun(doToggleLabel)}
        onApply={this.runAndClose(onApply)}
        onCancel={onClose}
      />
    )
  }
}

export default MountedModal
