import React, { useState, useCallback } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'

import SetupPaymentMethodCard from 'subapps/settings/components/BillingCards/SetupPaymentMethodCard'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import debug from 'util/debug'
import { useSetupSubscription } from 'ducks/billing/hooks'
import { discountMessageFor } from 'ducks/billing/util'
import { styles } from './styles'

const FORM_ID = 'CLAIM_DISCOUNT_FORM_ID'

const ClaimDiscount = ({ coupon }) => {
  const { saveSubscription } = useSetupSubscription({
    convertTrials: true,
  })

  const [isReady, setIsReady] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleOnReady = useCallback(
    () => {
      setIsReady(true)
    },
    [setIsReady]
  )

  const handleOnSubmitting = useCallback(() => {
    setIsSubmitting(true)
  }, [])

  const handleOnSubmitted = useCallback(
    async () => {
      try {
        await saveSubscription()
      } catch (error) {
        debug(error)
      }
      setIsSubmitting(false)
    },
    [saveSubscription]
  )
  const handleOnError = useCallback(() => {
    setIsSubmitting(false)
  }, [])
  return (
    <div css={styles.contentCc} className="grui pb-20 pt-14">
      <Modal.Title className="grui text-center px-8">
        {`🎁 Claim your ${discountMessageFor(coupon)}`}
      </Modal.Title>
      <Modal.Description css={styles.description}>
        Rest assured, our 30-day Money-Back Guarantee ensures that you are
        entitled to a full refund if you{"'"}re not fully satisfied.
      </Modal.Description>
      <SetupPaymentMethodCard
        className="grui mt-8 mb-8 w-100"
        formId={FORM_ID}
        onReady={handleOnReady}
        showFormButtons={false}
        onSubmitting={handleOnSubmitting}
        onSubmitted={handleOnSubmitted}
        onError={handleOnError}
        saveSubscription
        convertTrials
      />
      <Modal.Button
        type="info"
        disabled={!isReady || isSubmitting}
        htmlType="submit"
        form={FORM_ID}
      >
        {isSubmitting && (
          <span>
            Saving<AnimatedEllipsis />
          </span>
        )}
        {!isSubmitting && 'Save'}
      </Modal.Button>
    </div>
  )
}

export default ClaimDiscount
