import { useContext, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CollapsibleKeyValue, KeyValue } from 'components/KeyValues'
import { selectOrderForId } from 'ducks/integrations/recharge/selectors'
import {
  formatDateForCurrentYear,
  formatAmountWithCurrency,
  getRechargeMerchantUrl,
} from 'ducks/integrations/recharge/utils'
import { useRechargeCharge } from 'ducks/integrations/recharge/hooks/charges'
import { capitalize } from 'util/strings'
import { MISSING_VALUE } from 'ducks/integrations/recharge/contants'
import { capture } from 'ducks/tracking/actions'

import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import DetailsTable from './DetailsTable'

export default function Detail({ integration, className }) {
  const { navigatableState, navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const { orderId, customerId, subscriptionId } = navigatableState || {}
  const order = useSelector(state =>
    selectOrderForId(state, { subscriptionId, orderId })
  )

  const charge = useRechargeCharge(
    integration.id,
    subscriptionId,
    order?.charge?.id
  )

  const createdAt = useMemo(
    () =>
      formatDateForCurrentYear(
        order?.created_at,
        '%d %b @ %l:%M%p',
        '%d %b %Y @ %l:%M%p'
      ),
    [order]
  )
  const scheduledAt = useMemo(
    () =>
      formatDateForCurrentYear(
        order?.scheduled_at,
        '%d %b @ %l:%M%p',
        '%d %b %Y @ %l:%M%p'
      ),
    [order]
  )
  const totalRefunds = useMemo(
    () => formatAmountWithCurrency(order?.total_refunds, order?.currency),
    [order]
  )
  const shippingAddress = useMemo(
    () => {
      if (!order) {
        return []
      }

      const { address1, address2, zip, city } = order.shipping_address
      return [address1, address2, zip, city]
    },
    [order]
  )

  const onBack = useCallback(
    () =>
      navigatableSetState({
        customerId,
        subscriptionId,
        orderId,
        type: 'subscription',
      }),
    [navigatableSetState, subscriptionId, customerId, orderId]
  )

  const statusClassName = useMemo(
    () => {
      if (!order) {
        return null
      }

      switch (order.status) {
        case 'success':
          return 'recharge-status-positive'
        case 'error':
          return 'recharge-status-warning'
        case 'cancelled':
          return 'recharge-status-negative'
        case 'queued':
          return 'recharge-status-neutral'
        default:
          return ''
      }
    },
    [order]
  )

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Recharge Order Link Clicked')
    return true
  }, [])

  const orderUrl = getRechargeMerchantUrl(
    integration.fullStoreDomain,
    `merchant/orders/${order?.id}`
  )

  if (!order) {
    return null
  }

  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      unifiedTitleIconButton
      onBack={onBack}
      title="back"
    >
      <div className="grui p-7">
        <KeyValue
          title="Order ID"
          value={
            <a
              href={orderUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onViewExternalLinkClicked}
            >
              {order.id}
              <ExternalLink className="shopifyV2-externalLink-icon" />
            </a>
          }
        />
        <KeyValue title="Total refunds" value={totalRefunds} />
        <KeyValue title="Type" value={capitalize(order.type)} />
        <KeyValue
          title="Is prepaid"
          value={capitalize(order.is_prepaid.toString())}
        />
        <KeyValue
          title="Status"
          value={
            <span className={statusClassName}>{capitalize(order.status)}</span>
          }
        />
        <KeyValue title="Created at" value={createdAt} />
        <KeyValue title="Scheduled at" value={scheduledAt} />
        <KeyValue
          title="Tags"
          value={order.tags}
          missingValue={MISSING_VALUE}
        />
        <KeyValue
          title="Note"
          value={order.note}
          singleline={false}
          missingValue={MISSING_VALUE}
        />
        <CollapsibleKeyValue title="Shipping address" items={shippingAddress} />
      </div>

      <DetailsTable charge={charge} />
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
