import { connect } from 'react-redux'
import {
  doOpenNewConversationPage,
  doOpenLogConversationPage,
} from 'actions/pages'
import View from './view'

const performFactory = (initialState, initialOwnProps) => {
  const { isLogging } = initialOwnProps

  return dispatch => ({
    onSwitch: () =>
      dispatch(
        isLogging ? doOpenNewConversationPage() : doOpenLogConversationPage()
      ),
  })
}

export default connect(null, performFactory)(View)
