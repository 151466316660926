import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
  doFetchJiraCloudPriorities,
  selectCreateIssueTypeFieldsFieldMeta,
} from 'ducks/integrations/jiraCloud'
import {
  selectJiraArePrioritiesLoading,
  selectJiraPrioritiesAsOptions,
} from 'ducks/integrations/jiraShared'
import { PRIORITY } from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionImageIcon from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionImageIcon'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'

export default connect(
  (state, { formFields: { type: issueTypeId, project: projectId } }) => ({
    arePrioritiesLoading: selectJiraArePrioritiesLoading(state, {
      source: 'jiraCloud',
    }),
    priorities: selectJiraPrioritiesAsOptions(state, { source: 'jiraCloud' }),
    fieldMeta: selectCreateIssueTypeFieldsFieldMeta(state, {
      projectId,
      issueTypeId,
      fieldKey: PRIORITY,
    }),
  }),
  {
    doFetchJiraCloudPriorities,
  }
)(PrioritiesFormField)

function PrioritiesFormField({
  arePrioritiesLoading,
  doFetchJiraCloudPriorities: fetchPriorities,
  isProjectChoosen,
  formFields: { priority, type },
  onChange,
  resetFields,
  siteId,
  priorities,
  fieldMeta,
  projectId,
}) {
  useEffect(
    () => {
      if (siteId) {
        fetchPriorities(siteId)
      }
      resetFields('priority')
    },
    [fetchPriorities, type, siteId, projectId, resetFields]
  )

  // this field is not supported on JIRA for the project + issue type combo
  if (!fieldMeta) return null

  return (
    <FormRow split>
      <Label as="div">
        Priority&nbsp;
        {!fieldMeta.required && <OptionalLabel>(optional)</OptionalLabel>}
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || arePrioritiesLoading}
        fluid
        loading={arePrioritiesLoading}
        name="priority"
        options={priorities.map(option => {
          return {
            ...option,
            icon: <OptionImageIcon alt={option.text} src={option.iconUrl} />,
          }
        })}
        onChange={onChange}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={priority}
      />
    </FormRow>
  )
}
