import { css } from '@emotion/react'
import { indicator } from '@groovehq/internal-design-system/lib/styles/elements'

const innerList = theme => css`
  .search-filter {
    ${indicator.styles.accentNeutral(theme)};
    &::first-letter {
      text-transform: lowercase;
    }
  }
`

const recentSearch = css`
  border-radius: 4px;
  cursor: pointer;
  &.active {
    background-color: #f8f8f8;
  }
`

export const styles = {
  innerList,
  recentSearch,
}
