import React from 'react'
import HotkeysGroup from './HotkeysGroup'

export default function ReplyNoteForm() {
  return (
    <HotkeysGroup
      title="Reply/Note Form"
      hotkeys={{
        'ctrl+shift+c': 'Add CC',
        'ctrl+shift+b': 'Add BCC',
        'ctrl+shift+f': 'Open from dropdown',
        'ctrl+shift+t': 'Change to',
        'ctrl+k': 'Turn selected text into a link',
      }}
    />
  )
}
