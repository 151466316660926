import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { ArrowDown } from '@groovehq/internal-design-system/lib/assets/icons'
import MailboxesDropdown from 'subapps/settings/components/MailboxesDropdown'
import { getMailboxNameOrEmail } from 'util/mailboxes'
import { styles } from './styles'
import { getMailboxSearchFilterLabel } from './util'

const menuOffset = [0, 10]

const MailboxSearchFilter = ({
  selectedMailboxIds,
  focused,
  onSelectMailboxes,
}) => {
  const [visible, setVisible] = useState(false)
  const mailboxes = useSelector(selectKnownMailboxes)
  const [selectedIds, setSelectedIds] = useState([])

  const mailboxesForDropdown = useMemo(
    () => {
      return mailboxes.map(mailbox => {
        return {
          ...mailbox,
          name: getMailboxNameOrEmail(mailbox, mailboxes, {
            nameWithEmail: true,
          }),
        }
      })
    },
    [mailboxes]
  )

  const handleSelectValue = useCallback(
    list => {
      onSelectMailboxes(list, {
        shouldFocusSearchBox: visible,
      })
    },
    [onSelectMailboxes, visible]
  )

  const value = useMemo(
    () => {
      if (!selectedMailboxIds.length) {
        return mailboxes.map(m => m.id)
      }
      return selectedMailboxIds
    },
    [selectedMailboxIds, mailboxes]
  )

  useEffect(
    () => {
      if (!visible) {
        setSelectedIds(value)
      }
    },
    [visible, value]
  )

  return (
    <MailboxesDropdown
      onSelectedIdsChange={setSelectedIds}
      selectedIds={selectedIds}
      setValue={handleSelectValue}
      value={value}
      list={mailboxesForDropdown}
      menuOffset={menuOffset}
      onVisibleChange={setVisible}
      css={styles.mailboxFilterDropdown}
    >
      <button
        css={[
          button.styles.preflight,
          !focused && text.styles.textWhite,
          !focused && styles.mailboxFilterButtonLight,
          styles.mailboxFilterButton,
        ]}
      >
        <span className="grui px-5 flex items-center">
          <span className="grui truncate">
            {getMailboxSearchFilterLabel(value, mailboxes)}
          </span>
          <ArrowDown className="grui ml-5" />
        </span>
      </button>
    </MailboxesDropdown>
  )
}

export default MailboxSearchFilter
