import React from 'react'
import GlobalNav from '@groovehq/internal-design-system/lib/components/GlobalNav/GlobalNav'
import ProgressCircle from '@groovehq/internal-design-system/lib/components/ProgressCircle/ProgressCircle'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { divider } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  chatsNew as ChatV2,
  emailNew as InboxV2,
  social as Social,
} from 'assets/icons/groove/v2'
import { propFunc } from 'util/functions'

import { doOpenMainPage } from 'actions/pages'
import {
  CHAT_PAGE as CHAT_LANDING_PAGE,
  SOCIAL_PAGE as SOCIAL_LANDING_PAGE,
} from 'subapps/chat/pages'
import AppsMenu from 'components/AppsMenu'

import GlobalNavItem from './GlobalNavItem'
import { styles } from './styles'

const toChatLandingPage = { type: CHAT_LANDING_PAGE }
const toSocialLandingPage = { type: SOCIAL_LANDING_PAGE }

const appsMenuOffset = [50, -48]

export default class GlobalNavWrapper extends React.Component {
  state = { visible: false }

  handleVisibleChange = isVisible => {
    const { hasSeenInstallAppMenu, setSeenInstallAppMenu } = this.props
    this.setState({ visible: isVisible })
    if (isVisible && hasSeenInstallAppMenu === false) {
      setSeenInstallAppMenu()
    }
  }

  render() {
    const {
      active,
      noticeCounts,
      alerts,
      isLiveChatEnabled,
      isSocialChatEnabled,
      billingProvider,
      hasSeenInstallAppMenu,
      isCurrentUserSignupOlderThan2Weeks,
      showMobileAppsMenu,
    } = this.props

    const showNav = isLiveChatEnabled || isSocialChatEnabled

    const showPhoneIcon =
      showMobileAppsMenu && isCurrentUserSignupOlderThan2Weeks

    if (!showNav) return null

    return (
      <GlobalNav
        className="grui pt-4"
        metaNav={
          <>
            {showPhoneIcon && (
              <Dropdown
                overlay={
                  <AppsMenu
                    onVisibleChange={this.handleVisibleChange}
                    showHideButton
                  />
                }
                autoHeight
                position="top-start"
                strategy="fixed"
                offset={appsMenuOffset}
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
              >
                <div className="grui position-relative">
                  <GlobalNavItem
                    title="Get the Groove mobile app!"
                    icon={<div css={styles.phone}>📱</div>}
                  />
                  {hasSeenInstallAppMenu === false && (
                    <ProgressCircle css={styles.circle} />
                  )}
                </div>
              </Dropdown>
            )}
            <div css={divider.styles.primary} role="separator" />
          </>
        }
      >
        <GlobalNavItem
          to={doOpenMainPage()}
          title={app.t('Mailbox')}
          icon={<InboxV2 />}
          isActive={active === 'Inbox'}
          onClick={propFunc(this.handleSubappClick, 'Inbox')}
          noticeCount={noticeCounts.Inbox || null}
          alert={alerts.Inbox || null}
        />
        {isLiveChatEnabled &&
          billingProvider !== 'shopify' && (
            <GlobalNavItem
              to={toChatLandingPage}
              title="Live Chat"
              icon={<ChatV2 />}
              isActive={active === 'Live Chat'}
              onClick={propFunc(this.handleSubappClick, 'Live Chat')}
              noticeCount={noticeCounts['Live Chat'] || null}
              alert={alerts.widget || null}
            />
          )}
        {isSocialChatEnabled &&
          billingProvider !== 'shopify' && (
            <GlobalNavItem
              to={toSocialLandingPage}
              title="Social Chat"
              icon={<Social />}
              isActive={active === 'Social Chat'}
              onClick={propFunc(this.handleSubappClick, 'Social Chat')}
              noticeCount={noticeCounts['Social Chat'] || null}
              alert={alerts.social || null}
              css={styles.navItemWithBigIcon}
            />
          )}
      </GlobalNav>
    )
  }
}
