import React from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { DISCOUNT_VALUE_TYPES } from 'ducks/integrations/shopify/constants'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import { shopifyMoneyString } from 'util/currency'
import { localePercentageString } from 'util/strings'
import PriceDiscount from '../PriceDiscount'

const DiscountSection = React.memo(
  ({
    integrationId,
    customerId,
    currency,
    discount,
    handleDiscountChange,
    rawTotal,
    disabled,
    valueClassName,
  }) => {
    const { isCalculating, isSending } = useShopifyDraft({
      integrationId,
      customerId,
    })
    return (
      <>
        <PriceDiscount
          currency={currency}
          discount={discount}
          onDiscountChange={handleDiscountChange}
          disabled={disabled || isCalculating || isSending}
          priceCents={rawTotal}
          isOrderDiscount
        >
          <button css={button.styles.link} type="button">
            {discount ? 'Edit' : 'Add'} discount
          </button>
        </PriceDiscount>
        <span className={valueClassName}>
          {discount?.valueType === DISCOUNT_VALUE_TYPES.FIXED_AMOUNT &&
            discount?.value > 0 && (
              <span css={mainStyles.moneyString}>
                {shopifyMoneyString({
                  currencyCode: currency,
                  amount: -discount.value,
                })}
              </span>
            )}
          {discount?.valueType === DISCOUNT_VALUE_TYPES.PERCENTAGE &&
            discount?.value > 0 &&
            `${localePercentageString(-(discount.value / 100))}`}
          {!discount && <>&mdash;</>}
        </span>
      </>
    )
  }
)

export default DiscountSection
