import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  ALL_REPOSITORIES,
  doFetchGitHubDetailsForRepository,
  doCreateAndLinkGitHubIssueToTicket,
  selectGitHubIsLoading,
  selectGitHubAreRepositoryDetailsLoadingForRespository,
  selectGitHubRepositoryAssignableUsersAsOptionsForRespository,
  selectGitHubRepositoryLabelsAsOptionsForRespository,
  selectGitHubRepositoryMilestonesAsOptionsForRespository,
  selectGitHubRepositoriesAsOptionsForOwner,
  selectGitHubRepositoryOwnersAsOptions,
} from 'ducks/integrations/github'
import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'
import { required } from 'util/validation'

import LinkNewGitHubIssueModal from './view'

function mapStateToProps(state, props) {
  const {
    formFields: { repository, repositoryOwner },
  } = props
  return {
    areRepositoriesLoading: selectGitHubIsLoading(state, {
      loadingId: ALL_REPOSITORIES,
    }),
    areRepositoryDetailsLoading:
      repository &&
      selectGitHubAreRepositoryDetailsLoadingForRespository(state, {
        repositoryId: repository,
      }),

    assignableUsers: selectGitHubRepositoryAssignableUsersAsOptionsForRespository(
      state,
      {
        repositoryId: repository,
      }
    ),
    labels: selectGitHubRepositoryLabelsAsOptionsForRespository(state, {
      repositoryId: repository,
    }),
    milestones: selectGitHubRepositoryMilestonesAsOptionsForRespository(state, {
      repositoryId: repository,
    }),
    repositoryOwners: selectGitHubRepositoryOwnersAsOptions(state),
    repositories:
      repositoryOwner &&
      selectGitHubRepositoriesAsOptionsForOwner(state, {
        ownerId: repositoryOwner,
      }),
  }
}

const mapDispatchToProps = {
  doCreateAndLinkGitHubIssueToTicket,
  doFetchGitHubDetailsForRepository,
}

export default compose(
  withOpenState,
  withProps({
    initialValues: { repositoryOwner: null },
    validators: {
      title: required,
      repository: required,
    },
  }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkNewGitHubIssueModal)
