import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loading from 'core/subapps/Loading'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectShowContinueForwardingSetupPane } from 'selectors/layout'
import MailboxContinueForwardingSetup from 'components/App/DesktopView/Layout/MailboxContinueForwardingSetup'
import ClassicView from './ClassicView'
import ModernView from './ModernView'

export default function LayoutSwitcher(props) {
  const {
    isBootstrapped,
    prefersClassicView,
    isNewConversation,
    isNewEmail,
  } = props

  const mailboxId = useSelector(selectCurrentMailboxId)
  const showContinueForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )

  const isNewConvo = isNewConversation || isNewEmail

  useEffect(
    () => {
      if (prefersClassicView) {
        document.body.classList.toggle('classic-inbox-view', true)
        document.body.classList.toggle('modern-inbox-view', false)
      } else {
        document.body.classList.toggle('classic-inbox-view', false)
        document.body.classList.toggle('modern-inbox-view', true)
      }
    },
    [prefersClassicView]
  )

  if (!isBootstrapped) return <Loading />

  if (!isNewConvo) {
    if (showContinueForwardingSetupPane) {
      return <MailboxContinueForwardingSetup mailboxId={mailboxId} />
    }
  }

  if (prefersClassicView) {
    return <ClassicView {...props} />
  }

  return <ModernView {...props} />
}
