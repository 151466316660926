import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const ruleLabel = css`
  max-width: ${rem(200)};
`

export const styles = {
  ruleLabel,
}
