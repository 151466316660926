import React, { useCallback } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import ReplyIcon from '@groovehq/internal-design-system/lib/assets/icons/Reply'
import ReplyAllIcon from '@groovehq/internal-design-system/lib/assets/icons/ReplyAll'
import ChevronRight from '@groovehq/internal-design-system/lib/assets/icons/ChevronRight'
import { styles as dropdownButtonStyles } from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownButton.styles'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { css } from '@emotion/react'

const forwardStyle = css`
  transform: scaleX(-1);
`

const dropdownMenuItemStyle = css`
  justify-content: flex-start;
  .dropdown-menu-item-icon {
    margin-left: auto;
    padding-left: 8px;
  }
`

const dropdownMenuIconStyle = theme => css`
  margin-right: ${theme.spacing[5]};
  font-size: ${theme.fontSize.x4large};
  color: ${theme.color.monochrome.mediumDark};
`

const buttonStyle = css`
  margin-left: -${rem(4)};
  margin-right: ${rem(4)};
  padding-left: ${rem(4)};
  padding-right: ${rem(2)};
  width: auto;
  position: relative;
  height: ${rem(26)};
`

const chevronStyle = css`
  position: relative;
  margin-left: -2px;
  margin-right: 0;
  padding-right: 0;
  & > svg {
    width: ${rem(16)} !important;
    height: ${rem(16)} !important;
  }
`

const SwitcherButton = ({
  className,
  isForwarding,
  replyType,
  onReplyAllClick,
  onDirectReplyClick,
  onForwardClick,
}) => {
  const handleChange = useCallback(
    value => {
      switch (value) {
        case 'reply-all':
          onReplyAllClick()
          break
        case 'reply-direct':
          onDirectReplyClick()
          break
        case 'forward':
          onForwardClick()
          break
        default:
          throw new Error('Incompatible reply state')
      }
    },
    [onReplyAllClick, onDirectReplyClick, onForwardClick]
  )

  const value = isForwarding ? 'forward' : replyType

  return (
    <Dropdown
      overlay={
        <Dropdown.Menu>
          <Dropdown.Menu.Item
            key="reply-all"
            itemKey="reply-all"
            css={dropdownMenuItemStyle}
          >
            <ReplyAllIcon css={dropdownMenuIconStyle} /> Reply all
          </Dropdown.Menu.Item>
          <Dropdown.Menu.Item
            key="reply-direct"
            itemKey="reply-direct"
            css={dropdownMenuItemStyle}
          >
            <ReplyIcon css={dropdownMenuIconStyle} /> Direct reply
          </Dropdown.Menu.Item>
          <Dropdown.Menu.Item
            key="forward"
            itemKey="forward"
            css={dropdownMenuItemStyle}
          >
            <ReplyIcon css={[forwardStyle, dropdownMenuIconStyle]} /> Forward
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      }
      strategy="fixed"
      onSelect={handleChange}
    >
      <Button
        data-test-id="forward-menu"
        type="icon"
        size="small"
        css={buttonStyle}
        className={className}
      >
        {value === 'reply-all' && <ReplyAllIcon />}
        {value === 'reply-direct' && <ReplyIcon />}
        {value === 'forward' && <ReplyIcon css={forwardStyle} />}
        <div css={[dropdownButtonStyles.dropdownIcon, chevronStyle]}>
          <ChevronRight />
        </div>
      </Button>
    </Dropdown>
  )
}
export default SwitcherButton
