import { connect } from 'react-redux'
import { doDeleteLabel, doCancelDeleteLabel } from 'actions/labels'

import { selectInitialLabelProps } from 'selectors/modals_base'

import DeleteModal from './view'

const select = state => {
  return {
    // we simply repurpose the modalProps that we use for create/update
    // Ideally we'd have the labelId from the route/URL
    label: selectInitialLabelProps(state),
  }
}

const perform = {
  onDelete: doDeleteLabel,
  onCancel: doCancelDeleteLabel,
}

export default connect(select, perform)(DeleteModal)
