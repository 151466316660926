import React from 'react'

const defaultContext = {
  getFocusableRef: () => {},
  setFocus: () => {},
  advanceFocus: () => {},
  retreatFocus: () => {},
  onToRecipientUpdated: () => {},
  getFocusedRow: () => {},
}

export const FocusContext = React.createContext(defaultContext)
