import { connect } from 'react-redux'
import { selectAgent } from 'selectors/agents/base'

const AgentAvatar = connect((initialState, initialProps) => state => {
  const agent = selectAgent(state, initialProps.agentId)
  return {
    avatarUrl: agent.avatarUrl || null,
    actorInitials: agent.initials || null,
  }
})(({ avatarUrl, actorInitials }) => {
  const styles = { backgroundImage: `url(${avatarUrl})` }
  return (
    <div className="avatar" style={styles}>
      {!avatarUrl && actorInitials}
    </div>
  )
})

export default AgentAvatar
