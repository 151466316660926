import { connect } from 'react-redux'
import { doOpen as openLightbox } from 'actions/lightbox'
import {
  selectTicketDraftAttachments,
  selectUploadingAttachmentsForCurrentTicket,
} from 'selectors/attachments'

import { selectAttachmentsByDraftId } from 'ducks/drafts2/selectors'

import Attachments from './view'

const empty = () => {
  return {}
}

const selectFactory = (initialState, initialOwnProps) => {
  const { forDraft } = initialOwnProps

  // eslint-disable-next-line no-prototype-builtins
  if (initialOwnProps.hasOwnProperty('draftId')) {
    return (state, props) => {
      const draftId = props.draftId
      return {
        draftId,
        draftAttachments: selectAttachmentsByDraftId(
          state,
          draftId,
          props.ticketId
        ),
        // this will only work because we only have one form
        // it needs to be changed to work off prop
        ticketAttachments: selectUploadingAttachmentsForCurrentTicket(state),
      }
    }
  }

  if (forDraft) {
    return state => {
      return {
        draftAttachments: selectTicketDraftAttachments(state),
      }
    }
  }

  return empty
}

const perform = {
  onClickImage: openLightbox,
}

export default connect(selectFactory, perform)(Attachments)
