import React from 'react'
import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Link from 'redux-first-router-link'
import { bellRing as BellRing } from 'assets/icons/groove/v2'
import { SETTINGS_NOTIFICATIONS_PAGE } from 'subapps/settings/types'

import { styles } from './styles'

const EmptyNotification = ({ className }) => {
  return (
    <div css={[styles.menu, styles.emptyNotification]} className={className}>
      <div css={styles.bellRing}>
        <BellRing />
      </div>
      <div css={heading.styles.h3} className="grui my-3">
        Notifications!
      </div>
      <div css={text.styles.textNormal}>
        Notifications will keep you in the loop with important conversation
        actions, replies, @mentions and so much more..
      </div>
      <Link
        to={{ type: SETTINGS_NOTIFICATIONS_PAGE }}
        css={button.styles.link}
        className="grui underline mt-10"
      >
        Update your notification settings
      </Link>
    </div>
  )
}

export default EmptyNotification
