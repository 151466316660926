import React, { Fragment, useMemo } from 'react'
import { useCustomer } from 'ducks/integrations/stripe/hooks/customers'
import { Loader } from 'shared/ui'
import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import Subscriptions from './Subscriptions/List'
import Charges from './Charges/List'
import StripeCustomerDetail from './StripeCustomerDetail'

export default function StripeIndex({ integration }) {
  const customer = useCustomer(integration.id)

  const getAction = customerId => {
    return [
      {
        blankTarget: true,
        text: ['View in Stripe'],
        url: `https://dashboard.stripe.com/customers/${customerId}`,
      },
    ]
  }

  const displayCustomerRecordDetail = useMemo(
    () => {
      if (Array.isArray(customer?.records) && customer.records.length > 1)
        return true
      return false
    },
    [customer]
  )

  if (customer.isLoading) {
    return (
      <div className="grui p-7">
        <Loader />
      </div>
    )
  }

  if (!customer.records) {
    return (
      <div className="grui p-7">
        <EmptyMessage>There is no customer information</EmptyMessage>
      </div>
    )
  }

  const StripeDetailContainer = ({ children, customerId }) =>
    displayCustomerRecordDetail ? (
      <StripeCustomerDetail customerId={customerId}>
        {children}
      </StripeCustomerDetail>
    ) : (
      <>{children}</>
    )
  return (
    <div>
      {customer.records.map(record => (
        <Fragment key={record.id}>
          <StripeDetailContainer customerId={record.id}>
            <Subscriptions
              integrationId={integration.id}
              customerId={record.id}
            />
            <Charges integrationId={integration.id} customerId={record.id} />
            <NavigatableIntegrationWidgetCard.Footer>
              <LinkedResourceActions
                actions={getAction(record.id)}
                className="grui m-0 w-100 flex justify-end"
              />
            </NavigatableIntegrationWidgetCard.Footer>
          </StripeDetailContainer>
        </Fragment>
      ))}
    </div>
  )
}
