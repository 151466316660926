import { useCallback, useState } from 'react'

import { Dropdown } from 'util/ui'

export default function CustomFieldEditDropdown({
  fieldKey,
  options,
  title,
  value: valueProp,
}) {
  const [valueState, setValue] = useState(valueProp)
  const onChange = useCallback(
    (event, { value }) => {
      setValue(value)
    },
    [setValue]
  )
  return (
    <>
      <Dropdown
        fluid
        onChange={onChange}
        options={options}
        placeholder={`Select ${title}...`}
        selection
        value={valueState}
      />
      <input
        type="hidden"
        name={`${fieldKey}.content`}
        value={valueState || ''}
      />
    </>
  )
}
