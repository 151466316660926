import React, { useCallback, useState } from 'react'

import Sidebar from 'shared/components/ui/Sidebar'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Button, Label, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { debounce } from 'util/functions'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import ProjectLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/ProjectLabel'
import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'

import JiraCloudOptionAvatar from '../../../JiraCloudOptionAvatar'

function dontFilter(results) {
  return results
}

export default function LinkExistingGitHubIssueModal({
  areIssuesLoading,
  areSitesLoading,
  doFetchJiraCloudIssueSearch,
  doLinkJiraCloudIssueToTicket,
  issues,
  formFields: { issue = null, site: formSite = null } = {},
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
  resetForm,
  sites,
}) {
  const site = sites.length === 1 ? sites[0].value : formSite
  const [currentSearchQuery, setSearchQuery] = useState('')
  let noResultsMessage = 'Search for Jira Cloud Issues.'
  if (areIssuesLoading) noResultsMessage = 'Loading...'
  if (!areIssuesLoading && currentSearchQuery.length > 0)
    noResultsMessage = 'No results found.'
  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [onClose, resetForm]
  )

  const onChange = useCallback(
    (event, { name, value }) => {
      propOnChange(name, value)
    },
    [propOnChange]
  )
  const onSearchChange = useCallback(
    (event, { searchQuery }) => {
      setSearchQuery(searchQuery)
      doFetchJiraCloudIssueSearch(site, searchQuery)
    },
    [site, doFetchJiraCloudIssueSearch, setSearchQuery]
  )
  const onLink = useCallback(
    () => {
      doLinkJiraCloudIssueToTicket(site, issue)
      doResetAndClose()
    },
    [doLinkJiraCloudIssueToTicket, doResetAndClose, site, issue]
  )

  const isLoading = areSitesLoading
  return (
    <React.Fragment>
      <Button basic className="grui mt-4" onClick={onOpen}>
        Existing
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="link"
            portal
            position="top"
            tooltip={
              !issue && 'Please ensure all required fields are filled in'
            }
          >
            <Button disabled={!issue} fluid onClick={onLink} primary>
              Link card
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={doResetAndClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to Existing Jira Cloud Issue"
        onClose={doResetAndClose}
        open={open}
      >
        <ModalContent>
          {!isLoading && (
            <React.Fragment>
              {sites.length > 1 && (
                <FormRow>
                  <Label as="div">Jira Cloud Site</Label>
                  <ValidatedField
                    fluid
                    name="site"
                    options={sites.map(option => {
                      return {
                        ...option,
                        children: (
                          <React.Fragment key={option.value}>
                            <JiraCloudOptionAvatar url={option.avatarUrl} />
                            {option.text}
                          </React.Fragment>
                        ),
                      }
                    })}
                    onChange={onChange}
                    placeholder="- Please select -"
                    search
                    selection
                    selectOnNavigation={false}
                    validatedFieldComponent={Dropdown}
                    value={site}
                  />
                </FormRow>
              )}
              <FormRow>
                <Label as="div">Issue</Label>
                <ValidatedField
                  disabled={!site}
                  fluid
                  loading={areIssuesLoading}
                  name="issue"
                  noResultsMessage={noResultsMessage}
                  options={issues.map(option => ({
                    ...option,
                    children: (
                      <React.Fragment>
                        <div>
                          <strong>{option.text}</strong>
                        </div>
                        <ProjectLabel>{option.project}</ProjectLabel>
                        {option.components.length > 0 && (
                          <div>{option.components.join(', ')}</div>
                        )}
                      </React.Fragment>
                    ),
                  }))}
                  onChange={onChange}
                  onSearchChange={debounce(onSearchChange, 500)}
                  placeholder={noResultsMessage}
                  search={dontFilter}
                  selection
                  selectOnNavigation={false}
                  validatedFieldComponent={Dropdown}
                  value={issue}
                />
              </FormRow>
            </React.Fragment>
          )}
          {isLoading && <Loader />}
        </ModalContent>
      </Sidebar>
    </React.Fragment>
  )
}
