import React, { useState, useCallback, useMemo } from 'react'
import { IconButton } from 'shared/ui'
import { doOpenRawEmailPage } from 'actions/pages'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_RULES_UPDATE } from 'ducks/drawers/types'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import AiMessageDropdown from 'components/Ai/MessageDropdown'
import EmojiPickerButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/EmojiPickerButton'
import { css } from '@emotion/react'
import memoEmoji from 'assets/icons/emoji/memo.png'
import greenBookEmoji from 'assets/icons/emoji/green-book.png'
import { getByline } from './util'

const Header = ({
  action,
  currentUserId,
  isNote,
  isFirstMessage,
  isForward,
  hasAttachments,
  isFromMerge,
  hasRawEmail,
  readReceipt,
  onCopyLinkClick,
  onPrintClick,
  onDeleteClick,
  onEditClick,
  onReactionClick,
  onAiOptionSelected,
  showAIInstantReplyButton,
  showMeta,
}) => {
  const [disableTooltip, setDisableTooltip] = useState(true)

  const onMouseEnter = useCallback(e => {
    // only show tooltip if container text is overflowing
    setDisableTooltip(
      e.currentTarget.clientWidth >= e.currentTarget.scrollWidth
    )
  }, [])

  const onRawEmailClickStopPropagation = useCallback(e => {
    // prevent the href click from collapsing the changeset
    e.stopPropagation()
  }, [])

  const rawEmailTo = useMemo(() => doOpenRawEmailPage(action.change.id), [
    action.change.id,
  ])

  const canEditOrDelete = useMemo(
    () => isNote && action.actor.id === currentUserId,
    [isNote, currentUserId, action]
  )

  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_RULES_UPDATE
  )

  const openRuleEditDrawer = useCallback(
    (event, id) => {
      event.stopPropagation()
      openCreateEditDrawer(id)
    },
    [openCreateEditDrawer]
  )

  const aiOptions = [
    {
      name: 'AI GENERATE FROM MESSAGE',
      items: [
        {
          id: 'instantReply',
          name: 'Create an Instant Reply with AI',
          icon: memoEmoji,
        },
        {
          id: 'knowledgeBase',
          name: 'Create a Knowledge Base article',
          icon: greenBookEmoji,
          comingSoon: true,
        },
      ],
    },
  ]

  const datetime = action.datetime
  const byLine = getByline({
    action,
    currentUserId,
    isNote,
    isFirstMessage,
    isForward,
  })

  return (
    <div className="header">
      <Tooltip
        className="byline-container"
        disabled={disableTooltip}
        title={byLine}
        portal="#overlays"
      >
        {getByline(
          {
            action,
            currentUserId,
            isNote,
            isFirstMessage,
            isForward,
          },
          {
            onMouseEnter,
            openRuleEditDrawer,
          }
        )}
      </Tooltip>
      <div className="right-side">
        {showMeta && (
          <div className="meta">
            {readReceipt && <div className="read-receipt">SEEN</div>}
            <div className="icons">
              {hasAttachments && <div className="Icon Icon-attachment" />}
              {isFromMerge && <div className="Icon Icon-merge" />}
            </div>
            <div className="datetime">{datetime}</div>
          </div>
        )}
        {!showMeta && (
          <div className="actions hidden-in-print">
            <div
              css={css`
                position: relative;
                top: 2px;
              `}
            >
              {showAIInstantReplyButton && (
                <AiMessageDropdown
                  onAiOptionSelected={onAiOptionSelected}
                  list={aiOptions}
                />
              )}
            </div>
            {isNote && (
              <EmojiPickerButton
                direction="left"
                onChoose={onReactionClick}
                tooltipPortal
              />
            )}
            {canEditOrDelete && (
              <IconButton
                name="edit"
                svg
                onClick={onEditClick}
                size="small"
                tooltip="Edit note"
                iconSize={16}
                tooltipPortal
              />
            )}
            {canEditOrDelete && (
              <IconButton
                name="trash"
                svg
                onClick={onDeleteClick}
                size="small"
                tooltip={
                  action.change.editedAt ? 'Delete edited note' : 'Delete note'
                }
                iconSize={16}
                tooltipPortal
              />
            )}
            <IconButton
              name="print"
              svg
              onClick={onPrintClick}
              size="small"
              tooltip="Print"
              iconSize={16}
              // Prevent the tooltip from being hidden when the email has high z-index
              tooltipPortal
            />
            <IconButton
              name="link"
              svg
              onClick={onCopyLinkClick}
              size="small"
              tooltip={
                <span>
                  Copy Link<br />to {isNote ? 'Note' : 'Message'}
                </span>
              }
              iconSize={16}
              tooltipPortal
            />
            {hasRawEmail && (
              <IconButton
                name="externalLink"
                to={rawEmailTo}
                target="_blank"
                svg
                onClick={onRawEmailClickStopPropagation}
                size="small"
                tooltip={
                  <span>
                    View<br />Raw Email
                  </span>
                }
                iconSize={16}
                tooltipPortal
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
