import React from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from './styles'

const EditorButton = React.forwardRef((props, ref) => {
  const { className, children, editorButtonType, ...rest } = props
  return (
    <Button
      {...rest}
      className={className}
      css={[
        styles.selectorButton,
        editorButtonType === 'note' && styles.selectorNoteButton,
      ]}
      ref={ref}
    >
      {children}
    </Button>
  )
})

export default EditorButton
