import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Input } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

export default function SummaryFormField({
  formFields: { summary },
  onChange,
}) {
  return (
    <FormRow>
      <Label as="div">Summary</Label>
      <ValidatedField
        fluid
        name="summary"
        onChange={onChange}
        validatedFieldComponent={Input}
        value={summary || ''}
      />
    </FormRow>
  )
}
