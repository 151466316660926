import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const backdrop = theme => css`
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.5);
`

const modalPanel = css`
  padding: 0;
  max-width: unset;
`

const contentSave = css`
  max-width: ${rem(396)};
  margin: 0 ${rem(60)};

  @media screen and (min-width: 800px) {
    margin: 0 ${rem(162)};
  }
`

const contentCc = css`
  margin: 0 ${rem(60)};
`

const description = css`
  max-width: 500px;
  margin: ${rem(24)} auto;
`

const header = theme => css`
  border-bottom: 1px solid ${theme.color.info[50]};
`

const logo = css`
  height: ${rem(32)};

  img {
    height: 100%;
    width: auto;
  }
`

const button = css`
  border-radius: 9999px;
  width: 100%;
`

export const styles = {
  backdrop,
  modalPanel,
  contentSave,
  contentCc,
  header,
  logo,
  button,
  description,
}
