import React, { useCallback, useRef, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { motion, useAnimation } from 'framer-motion'
import Context from './Context'

export default function AnimateAutoHeight({ duration, children }) {
  const controls = useAnimation()
  const disabled = useRef(false)
  const prevDisabled = useRef(false)

  const onResize = useCallback(
    (_, height) => {
      window.requestAnimationFrame(() => {
        if (disabled.current) {
          controls.set({ height: 'auto' })
        } else {
          controls.start({ height })
        }
      })
    },
    [controls, disabled]
  )

  const contextValue = useMemo(
    () => {
      return {
        disableAnimations: () => {
          prevDisabled.current = disabled.current
          disabled.current = true
        },
        restoreAnimationState: () => {
          disabled.current = prevDisabled.current || false
          prevDisabled.current = null
        },
      }
    },
    [disabled, prevDisabled]
  )

  const { ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    onResize,
    // HACK (jscheel): https://github.com/maslianok/react-resize-detector/issues/45
    refreshMode: 'debounce',
    refreshRate: 0,
    // END HACK
  })

  return (
    <Context.Provider value={contextValue}>
      <motion.div
        initial={{ height: 'auto' }}
        animate={controls}
        transition={{ type: 'spring', duration: duration / 1000, bounce: 0 }}
        style={{ overflow: 'hidden' }}
      >
        <div ref={ref}>{children}</div>
      </motion.div>
    </Context.Provider>
  )
}
