import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  doLinkTrelloCardToTicket,
  doFetchTrelloBoardDetails,
  doFetchTrelloCardsForList,
  selectTrelloCardsAsOptionsForList,
  selectTrelloBoardsAsOptions,
  selectTrelloBoardIsLoading,
  selectTrelloListsAsOptionsForBoard,
  selectTrelloListIsLoading,
} from 'ducks/integrations/trello'
import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'

import LinkExistingTrelloCardModalForm from './LinkExistingTrelloCardModalForm'

function mapStateToProps(state, props) {
  const {
    formFields: { board, list },
  } = props
  return {
    cards: selectTrelloCardsAsOptionsForList(state, list),
    boards: selectTrelloBoardsAsOptions(state),
    isBoardLoading: selectTrelloBoardIsLoading(state, board),
    isListLoading: selectTrelloListIsLoading(state, list),
    lists: selectTrelloListsAsOptionsForBoard(state, board),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    doLinkTrelloCardToTicket: () => {
      dispatch(doLinkTrelloCardToTicket(props.formFields.card))
      props.resetForm()
      props.onClose()
    },
    doFetchTrelloBoardDetails: id => dispatch(doFetchTrelloBoardDetails(id)),
    doFetchTrelloCardsForList: id => dispatch(doFetchTrelloCardsForList(id)),
    onClose: () => {
      props.resetForm()
      props.onClose()
    },
  }
}

export default compose(
  withOpenState,
  withProps({ initialValues: { board: null } }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkExistingTrelloCardModalForm)
