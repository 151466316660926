import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const StateButton = React.memo(
  ({ openOrCloseConversation, isCloseable, isExpired = false }) => {
    let tooltip = isCloseable ? 'Close [shift+c]' : 'Reopen [shift+o]'
    const isDisabled = isCloseable && isExpired

    if (isDisabled) {
      tooltip = "Can't Reopen an expired conversation"
    }

    return (
      <div className="state">
        <IconButton
          className={cn('menu-button', { closeable: isCloseable })}
          name={isCloseable ? 'check' : 'redo'}
          svg
          onClick={openOrCloseConversation}
          size="medium"
          tooltip={tooltip}
          disabled={isDisabled}
        />
      </div>
    )
  }
)

export default StateButton
