import React from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { propFunc } from 'util/functions'
import { discountMessageFor } from 'ducks/billing/util'
import { styles } from './styles'

const SaveDiscount = ({
  coupon,
  setShowSaveDiscount,
  onRemoveDiscount,
  isSaving,
}) => {
  return (
    <div css={styles.contentSave} className="grui pb-20 pt-14">
      <Modal.Title className="grui text-center">
        {`Are you sure don't want ${discountMessageFor(coupon)}?`}
      </Modal.Title>
      <Modal.Description>
        {`Rest assured, our 30-day Money-Back Guarantee ensures that you are entitled to a full refund if you're not fully satisfied.`}
      </Modal.Description>
      <Button
        type="info"
        size="xBig"
        css={styles.button}
        className="grui mt-18"
        onClick={propFunc(setShowSaveDiscount, false)}
      >
        No, I want my discount 🎁
      </Button>
      <Button
        size="xBig"
        type="tertiary"
        css={styles.button}
        className="grui mt-5"
        onClick={onRemoveDiscount}
        disabled={isSaving}
      >
        Remove my discount
      </Button>
    </div>
  )
}

export default SaveDiscount
