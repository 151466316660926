import { css } from '@emotion/react'
import { useSelector } from 'react-redux'

import { search as SearchIcon } from 'assets/icons/groove/v2'
import { selectCurrentContactId } from 'ducks/crm/contacts'
import { Loader } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { useAutofocus } from 'util/hooks'

import ChangeCompanyListItem from './ChangeCompanyListItem'
import { useCompanySearch } from './hooks'

const ChangeCompanyClass = css`
  & .ChangeCompany-Search {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${themeVars.dust};
    border-radius: 0;
    width: 100%;
    font-size: 1em;
    padding: 12px;
    line-height: 1em;

    &:focus {
      outline: none;
    }
  }

  & .ChangeCompany-SearchIcon {
    position: absolute;
    top: 13px;
    right: 11px;
  }

  & .ChangeCompany-Contents {
    padding: 12px;
    height: 200px;
    overflow: auto;
    em {
      color: ${themeVars.light};
    }
  }

  & .ChangeCompany-Companies {
    padding: 0;
    margin: -12px;
  }

  & .ChangeCompany-Loader {
    margin: 12px auto;
  }

  & .ChangeCompany-Company {
    cursor: pointer;
    list-style: none;
    display: flex;
    padding: 4px 8px 8px;

    &:hover {
      background: #e8f5fa;
    }

    h5 {
      margin-bottom: 4px;
      margin-top: 5px;
    }

    div {
      font-size: 0.92em;
    }
  }

  & .ChangeCompany-Company-NameAndDomain {
  }

  & .ChangeCompany-Footer {
    color: ${themeVars.linkBlue};
    border-top: 1px solid ${themeVars.dust};
    padding: 12px;
    cursor: pointer;
  }
`

export default function ChangeCompany({ companyId, onCreate, onClose }) {
  const {
    companies,
    hasResults,
    loading,
    onSearchChange,
    searchQuery,
  } = useCompanySearch({ companyId })
  const autofocus = useAutofocus()
  const contactId = useSelector(selectCurrentContactId)

  return (
    <div css={ChangeCompanyClass}>
      <input
        className="ChangeCompany-Search"
        onChange={onSearchChange}
        placeholder="Search..."
        ref={autofocus}
        value={searchQuery}
      />
      <SearchIcon className="ChangeCompany-SearchIcon" />
      <div className="ChangeCompany-Contents">
        {!searchQuery &&
          !hasResults &&
          !loading && (
            <div>
              <em>Start typing to search</em>
            </div>
          )}{' '}
        {loading && <Loader className="ChangeCompany-Loader" />}
        {!hasResults &&
          !loading &&
          searchQuery && <em>No companies matched your search</em>}
        {hasResults &&
          !loading && (
            <ul className="ChangeCompany-Companies">
              {companies.map(({ domain, id, logoUrl, name }, index) => {
                return (
                  <ChangeCompanyListItem
                    contactId={contactId}
                    companyId={companyId}
                    domain={domain}
                    id={id}
                    index={index}
                    key={id}
                    logoUrl={logoUrl}
                    name={name}
                    onClose={onClose}
                  />
                )
              })}
            </ul>
          )}
      </div>
      <div className="ChangeCompany-Footer" onClick={onCreate}>
        + Create new company
      </div>
    </div>
  )
}
