import { connect } from 'react-redux'
import { selectIsRightSidebarCollapsed } from 'selectors/app'
import View from './view'

const select = state => {
  return {
    isRightSidebarCollapsed: selectIsRightSidebarCollapsed(state),
  }
}

export default connect(select)(View)
