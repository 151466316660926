import React from 'react'
import cn from 'classnames'

import KeyboardNavigator from 'components/KeyboardNavigator'
import { propFunc } from 'util/functions'
import { indicator } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

export default function AiOptions({
  item,
  onSelect,
  handleMouseLeaveItem,
  activeItemId,
}) {
  return (
    <>
      {
        <KeyboardNavigator.Item
          key={item.id}
          onSelect={propFunc(onSelect, item)}
          css={styles.templateItem}
          id={item.id}
          className={cn({ disabled: item.comingSoon })}
          onMouseLeave={handleMouseLeaveItem}
          active={activeItemId === item.id}
        >
          <div css={styles.iconWrapper}>
            <img css={styles.icon} src={item.icon} alt="" />
            <span>{item.name}</span>
            {item.comingSoon && (
              <span
                css={[indicator.styles.primaryPositive, styles.comingSoon]}
                className="grui ml-4 mr-4"
              >
                Coming soon
              </span>
            )}
          </div>
        </KeyboardNavigator.Item>
      }
    </>
  )
}
