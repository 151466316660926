import { TextDivider } from 'shared/ui'
import { Button, Form } from 'util/ui'
import Link from 'redux-first-router-link'
import config from 'config'
import { LOGIN_PAGE } from 'constants/pages'
import LoginLayout from '../LoginLayout'

// NOTE (jscheel): We create the signup url manually, because we want it to go
// to the app subdomain even if the user is on an account subdomain.
const signupTo = `${config.appUrl}signup`
const loginTo = { type: LOGIN_PAGE }

const LoginAgentMissing = ({ isAccountSubdomain }) => {
  return (
    <LoginLayout
      tagline={
        isAccountSubdomain
          ? "This email address isn't linked to this Groove account!"
          : "This email address isn't linked to any Groove account... yet!"
      }
    >
      <Form size="large">
        <Button as="a" href={signupTo} fluid primary size="big">
          Create a Groove account
        </Button>
        <TextDivider>or</TextDivider>
        <Button as={Link} to={loginTo} fluid basic size="big">
          Go back to login page
        </Button>
      </Form>
    </LoginLayout>
  )
}

export default LoginAgentMissing
