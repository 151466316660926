import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { TextArea } from 'util/ui'
import { selectCreateIssueTypeFieldsFieldMeta } from 'ducks/integrations/jiraCloud'
import { DESCRIPTION } from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'
import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

export default function DescriptionFormField({
  isProjectChoosen,
  formFields: { description, type, project },
  onChange,
  projectId,
  resetFields,
  siteId,
}) {
  useEffect(
    () => {
      resetFields('description')
    },
    [type, siteId, projectId, resetFields]
  )

  const fieldMeta = useSelector(state =>
    selectCreateIssueTypeFieldsFieldMeta(state, {
      projectId: project,
      issueTypeId: type,
      fieldKey: DESCRIPTION,
    })
  )

  // this field is not supported on JIRA for the project + issue type combo
  if (!fieldMeta) return null

  return (
    <FormRow>
      <Label as="div">
        Description&nbsp;
        {!fieldMeta.required && <OptionalLabel>(optional)</OptionalLabel>}
      </Label>
      <ValidatedField
        name="description"
        disabled={!isProjectChoosen}
        onChange={onChange}
        validatedFieldComponent={TextArea}
        value={description || ''}
      />
    </FormRow>
  )
}
