import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $getNodeByKey, $getRoot, $getSelection } from 'lexical'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import Bugsnag from '@bugsnag/js'
import { logError } from 'util/debug'
import {
  CLEAR_SEARCH_TAG,
  doUpdateSearchByKey,
  doUpdateSearchMailboxIds,
} from 'actions/search'
import {
  selectSearchEditorState,
  selectSearchMailboxIds,
} from 'selectors/search/searchFilters'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import { areArraysEqual, difference } from 'util/arrays'
import {
  getTextFromRoot,
  getCurrentPart,
  normalizeQueryStringAddRemoveMailboxFilters,
  getDateTimeValueFromString,
  getMailboxIdsFromEditorState,
} from '../util'
import FilterPlugin from './FilterPlugin'
import {
  $isDateFilter,
  $isDatesRangeFilter,
  $isMailboxFilter,
  FilterNode,
} from './FilterNode'
import DatePlugin from './DatePlugin'
import SearchWithSuggestions from '../SearchWithSuggestions'
import DatePickerPane from '../DatePickerPane'

const Composer = ({
  onChange,
  onToggleDatePicker,
  isSearchBoxFocused,
  committedSearchQuery,
  prevLabelSize,
  shouldShowDatePicker,
  onSearchBoxFocus,
  onSearchBoxBlur,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const currentDateNodeRef = useRef(null)
  const [dateTimeValue, setDateTimeValue] = useState(null)
  const currentEditorState = useSelector(selectSearchEditorState)
  const mailboxes = useSelector(selectKnownMailboxes)
  const searchMailboxIds = useSelector(selectSearchMailboxIds)
  const editorConfig = useMemo(
    () => {
      const config = {
        theme: {
          // Class name for the filter node
          filter: 'data-search-filter',
        },
        nodes: [FilterNode],
        onError: error => {
          logError(error)
          Bugsnag.notify(error)
        },
        // Prevent focusing the editor after set the initial state
        editable: false,
      }
      if (currentEditorState) {
        config.editorState = currentEditorState
      }
      return config
    },
    [currentEditorState]
  )

  // We're using MailboxSearchFilter (mailboxes dropdown) for mailboxes selection too,
  // so we only want to update search mailboxes on these constraints:
  // Only update search mailboxes when the mailbox filters exist in the query string from search box and they are changed from the previous query from search box
  // DON'T update search mailboxes when the current node is a mailbox filter
  const updateSearchMailboxes = useCallback(
    (query, currentNode) => {
      if (!query || $isMailboxFilter(currentNode)) return
      const mailboxIdsFromNewQueryString = getMailboxIdsFromEditorState(
        mailboxes
      )
      if (!mailboxIdsFromNewQueryString.length) {
        return
      }
      currentEditorState.read(() => {
        const diff = difference(mailboxIdsFromNewQueryString, searchMailboxIds)
        let areMailboxesChanged = false
        if (diff.length && !searchMailboxIds.includes(diff[0])) {
          areMailboxesChanged = true
        } else {
          const mailboxIdsFromCurrentQueryString = getMailboxIdsFromEditorState(
            mailboxes
          )
          areMailboxesChanged = !areArraysEqual(
            mailboxIdsFromNewQueryString,
            mailboxIdsFromCurrentQueryString
          )
        }
        if (areMailboxesChanged) {
          dispatch(doUpdateSearchMailboxIds(mailboxIdsFromNewQueryString))
        }
      })
    },
    [dispatch, mailboxes, currentEditorState, searchMailboxIds]
  )

  const handleChange = useCallback(
    (editorState, _editor, tags) => {
      editorState.read(() => {
        if (tags.has(CLEAR_SEARCH_TAG)) {
          onToggleDatePicker(false)
          // Pevent onChange from being called when clear the field
          // because we want to handle it differently to also remove the filter badges
          return
        }
        // Read the contents of the EditorState here.
        const root = $getRoot()
        const selection = $getSelection()
        if (!selection) return
        const currentNode = $getNodeByKey(selection.anchor.key)
        const queryString = getTextFromRoot(root)
        const queryStringWithoutMailboxes = normalizeQueryStringAddRemoveMailboxFilters(
          queryString
        )
        if ($isDateFilter(currentNode)) {
          onToggleDatePicker(true)
          currentDateNodeRef.current = currentNode
          setDateTimeValue(
            getDateTimeValueFromString(
              currentNode.getTextContent(),
              $isDatesRangeFilter(currentNode)
            )
          )
        } else {
          onToggleDatePicker(false)
        }
        onChange(
          queryStringWithoutMailboxes,
          selection ? getCurrentPart(currentNode, root) : {}
        )
        updateSearchMailboxes(queryString, currentNode)
        dispatch(doUpdateSearchByKey('editorState', editorState))
      })
    },
    [onChange, dispatch, onToggleDatePicker, updateSearchMailboxes]
  )

  useEffect(
    () => {
      if (!shouldShowDatePicker) {
        setDateTimeValue(null)
      }
    },
    [shouldShowDatePicker]
  )

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <SearchWithSuggestions
        isSearchBoxFocused={isSearchBoxFocused}
        committedSearchQuery={committedSearchQuery}
        prevLabelSize={prevLabelSize}
        onSearchBoxFocus={onSearchBoxFocus}
        onSearchBoxBlur={onSearchBoxBlur}
        onSubmit={onSubmit}
        shouldShowDatePicker={shouldShowDatePicker}
      >
        {dateTimeValue && (
          <DatePickerPane
            onSearchBoxBlur={onSearchBoxBlur}
            value={dateTimeValue}
            setValue={setDateTimeValue}
            isDatesRangeFilter={$isDatesRangeFilter(currentDateNodeRef.current)}
          />
        )}
      </SearchWithSuggestions>
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      {/* Support highlighting editable search filters */}
      <FilterPlugin />
      <DatePlugin
        onToggleDatePicker={onToggleDatePicker}
        currentDateNodeRef={currentDateNodeRef}
      />
    </LexicalComposer>
  )
}

export default Composer
