import React from 'react'
import styled from '@emotion/styled'
import { SUI, Dropdown } from 'shared/ui'
import { propFunc } from 'util/functions'

export const option = (doChangeMailbox, ticketIds) => mailbox => {
  return {
    text: mailbox.name, // for searchInMenu
    description: `<${mailbox.email}>`, // for searchInMenu
    key: mailbox.id,
    onClick: propFunc(doChangeMailbox, ticketIds, mailbox.id),
    value: mailbox.id,
  }
}

const ChangeInboxDropdown = ({
  className,
  doChangeMailbox,
  mailboxes,
  ticketIds,
  trigger,
  ...rest
}) => (
  <SUI>
    <Dropdown
      className={className}
      header={`Change ${app.t('Mailbox')}`}
      icon={null}
      noResultsText={`No ${app.t('mailboxes')} found`}
      options={mailboxes.map(option(doChangeMailbox, ticketIds))}
      placeholder="Filter by name, email..."
      searchInMenu // CAVEAT - if you pass this, options need text[/description] (not just content)
      selectOnBlur={false}
      trigger={trigger}
      {...rest}
    />
  </SUI>
)

export default styled(ChangeInboxDropdown)`
  > .menu {
    max-width: 320px;
  }
`
