import { css } from '@emotion/react'

const product = css`
  width: 40%;
  padding-left: 5px;
`

const stock = css`
  width: 25%;
  text-align: right;
`

const menuItem = css`
  user-select: none;
`

export const styles = { product, stock, menuItem }
