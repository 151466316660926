import React, { useCallback, useState } from 'react'

import Sidebar from 'shared/components/ui/Sidebar'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Button, Label, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { debounce } from 'util/functions'
import { Dropdown } from 'util/ui'

import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import ProjectLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/ProjectLabel'
import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'

function dontFilter(results) {
  return results
}
export default function LinkExistingJiraServerIssueModal({
  areCredentialsLoaded,
  areIssuesLoading,
  doFetchJiraServerIssueSearch,
  doLinkJiraServerIssueToTicket,
  issues,
  formFields: { issue = null } = {},
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
  resetForm,
}) {
  const [currentSearchQuery, setSearchQuery] = useState('')
  let noResultsMessage = 'Search for Jira Server Issues.'
  if (areIssuesLoading) noResultsMessage = 'Loading...'
  if (!areIssuesLoading && currentSearchQuery.length > 0)
    noResultsMessage = 'No results found.'
  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [onClose, resetForm]
  )

  const onChange = useCallback(
    (event, { name, value }) => {
      propOnChange(name, value)
    },
    [propOnChange]
  )
  const onSearchChange = useCallback(
    (event, { searchQuery }) => {
      setSearchQuery(searchQuery)
      doFetchJiraServerIssueSearch(null, searchQuery)
    },
    [doFetchJiraServerIssueSearch]
  )

  return (
    <React.Fragment>
      <Button basic className="grui mt-4" onClick={onOpen}>
        Existing
      </Button>
      <Sidebar
        footerChildren={
          <React.Fragment>
            <Tooltip
              key="link"
              portal
              position="top"
              tooltip={
                !issue && 'Please ensure all required fields are filled in'
              }
            >
              <Button
                disabled={!issue}
                fluid
                onClick={useCallback(
                  () => {
                    doLinkJiraServerIssueToTicket(issue)
                    doResetAndClose()
                  },
                  [doLinkJiraServerIssueToTicket, doResetAndClose, issue]
                )}
                primary
              >
                Link card
              </Button>
            </Tooltip>
            <Button
              basic
              fluid
              key="cancel"
              className="grui mt-4"
              onClick={doResetAndClose}
            >
              Cancel
            </Button>
          </React.Fragment>
        }
        headerChildren="Link to Existing Jira Server Issue"
        onClose={doResetAndClose}
        open={open}
      >
        <ModalContent>
          {areCredentialsLoaded && (
            <FormRow>
              <Label as="div">Issue</Label>
              <ValidatedField
                fluid
                loading={areIssuesLoading}
                name="issue"
                noResultsMessage={noResultsMessage}
                options={issues.map(option => ({
                  ...option,
                  children: (
                    <React.Fragment key={option.value}>
                      <div>
                        <strong>{option.text}</strong>
                      </div>
                      <ProjectLabel>{option.project}</ProjectLabel>
                      {option.components.length > 0 && (
                        <div>{option.components.join(', ')}</div>
                      )}
                    </React.Fragment>
                  ),
                }))}
                onChange={onChange}
                onSearchChange={debounce(onSearchChange, 500)}
                placeholder={noResultsMessage}
                search={dontFilter}
                selection
                selectOnNavigation={false}
                validatedFieldComponent={Dropdown}
                value={issue}
              />
            </FormRow>
          )}
          {!areCredentialsLoaded && <Loader />}
        </ModalContent>
      </Sidebar>
    </React.Fragment>
  )
}
