// unstyled mailbox
const Mailbox = ({ className, mailbox }) => {
  if (!mailbox) return null

  return (
    <div className={className}>
      {mailbox.name}&nbsp;
      <span data-test-id="from-input" className="email">{`<${mailbox.email ||
        'none'}>`}</span>
    </div>
  )
}

export default Mailbox
