// used for create, duplicate and refund orders

import { css } from '@emotion/react'

const row = css`
  & > *:nth-of-type(even) {
    margin-left: auto;
  }
  & > *:nth-of-type(odd) {
    width: 370px;
  }
`

const summary = theme => css`
  border-bottom: 1px solid ${theme.color.monochrome.medium};

  li:not(:last-of-type) {
    margin-bottom: 8px;
  }

  li button:disabled {
    opacity: 0.6;
    cursor: text;
  }
`

export const styles = { summary, row }
