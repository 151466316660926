import React, { useState, useEffect, useCallback, useRef } from 'react'
import config from 'config'
import Bugsnag from '@bugsnag/js'
import Menu from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenu'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { status as StatusIcon } from 'assets/icons/groove/v2'
import ItemWithIcon from '../ItemWithIcon'
import { styles } from './styles'

const REFETCH_STATUS_INTERVAL_MS = 5 * 60 * 1000

const GrooveStatusMenuItem = ({ iconHidden }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const refetchIntervalIdRef = useRef()

  const fetchGrooveStatus = useCallback(
    async () => {
      if (isLoading) return
      const statusUrl = `https://${
        config.statusPageIOPageId
      }.statuspage.io/api/v2/status.json`

      setIsLoading(true)
      try {
        const response = await fetch(statusUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (response.ok) {
          const jsonResponse = await response.json()
          setStatus(jsonResponse?.status)
        }
      } catch (e) {
        Bugsnag.notify(e, event => {
          // eslint-disable-next-line no-param-reassign
          event.groupingHash = 'fetchGrooveStatus failed'
        })
      }
      setIsLoading(false)
    },
    [isLoading]
  )

  useEffect(() => {
    if (!refetchIntervalIdRef?.current) {
      fetchGrooveStatus()
      refetchIntervalIdRef.current = setInterval(
        fetchGrooveStatus,
        REFETCH_STATUS_INTERVAL_MS
      )
    }

    return () => {
      clearInterval(refetchIntervalIdRef.current)
      refetchIntervalIdRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tooltip
      title={status?.description}
      disabled={!status?.description}
      position="top"
    >
      <Menu.Item
        as="a"
        itemKey="groove-status"
        className="grui"
        href="https://status.groovehq.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ItemWithIcon icon={!iconHidden && <StatusIcon className="sm-icon" />}>
          <div className="grui mr-4">System Status</div>
          {!!status?.indicator &&
            !isLoading && (
              <span
                css={styles.statusIndicatorBadge}
                className={status?.indicator}
              >
                &nbsp;
              </span>
            )}
          {isLoading && <AnimatedEllipsis />}
        </ItemWithIcon>
      </Menu.Item>
    </Tooltip>
  )
}

export default GrooveStatusMenuItem
