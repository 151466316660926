import Link from 'redux-first-router-link'

export default function ToText({
  action: {
    change: {
      action: {
        action,
        changes: {
          new_repository: { html_url: repoHtmlUrl, name: repoName } = {},
          title: oldTitle,
        } = {},
        issue: { html_url: htmlUrl, title },
      },
    },
  },
}) {
  switch (action) {
    case 'edited':
      if (oldTitle) {
        return [
          ' to ',
          <Link rel="noopener noreferrer" target="_blank" to={htmlUrl}>
            {title}
          </Link>,
        ]
      }
    // eslint-disable-next-line no-fallthrough
    case 'transferred':
      return [
        ' to ',
        <Link rel="noopener noreferrer" target="_blank" to={repoHtmlUrl}>
          {repoName}
        </Link>,
      ]
    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
