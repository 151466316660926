import { REFUND_RESTOCK_TYPES } from 'ducks/integrations/shopify/constants'

export const buildLineItems = order => {
  if (!order) return []
  return order.lineItems.edges.map(e => {
    return {
      ...e.node,
      orderCurrencyCode: order.currencyCode,
      // refund is not calculated yet, will be updated in updateLineItemsWithSuggestedRefund below
      suggestedRefund: {},
    }
  })
}

export const updateLineItemsWithSuggestedRefund = (
  lineItems,
  suggestedRefund
) => {
  if (!lineItems) return []
  if (!suggestedRefund) return lineItems

  return (lineItems || []).map(li => {
    // shopify only returns refundLineItem for items that have a requested quantity > 0
    const suggestedRefundLineItem =
      (suggestedRefund?.refundLineItems || []).find(
        rli => rli?.lineItem?.id === li.id
      ) || {}

    return {
      ...li,
      suggestedRefund: suggestedRefundLineItem,
    }
  })
}

export const canRestockRefundLineItems = suggestedRefund => {
  return !(suggestedRefund?.refundLineItems || []).every(
    rli => rli.restockType === REFUND_RESTOCK_TYPES.NO_RESTOCK
  )
}
