import moment from 'moment'
import Link from 'redux-first-router-link'

function formatDate(date) {
  if (!date) return ''
  const asMoment = moment(date)
  const displayYear = asMoment.year() !== new Date().getFullYear()
  return asMoment.format(`D MMM${displayYear ? " 'YY" : ''}, H:mmA`)
}

export default function ToText({
  action: {
    change: {
      action: {
        data: {
          card: { due, id: cardId, name, shortLink } = {},
          board: { name: boardName, shortLink: boardShortLink } = {},
          listAfter: { name: listAfterName } = {},
        },
        display: {
          entities: {
            board: {
              text: entityBoardName,
              shortLink: entityBoardShortLink,
            } = {},
          } = {},
          translationKey,
        } = {},
      },
    } = {},
  },
}) {
  switch (translationKey) {
    case 'action_sent_card_to_board':
      return (
        <React.Fragment>
          {' '}
          to{' '}
          <Link to={`https://trello.com/b/${boardShortLink}`} target="_blank">
            {boardName}
          </Link>
        </React.Fragment>
      )
    case 'action_move_card_from_list_to_list':
      return (
        <React.Fragment>
          {' '}
          to <em>{listAfterName}</em> on{' '}
          <Link to={`https://trello.com/b/${boardShortLink}`} target="_blank">
            {boardName}
          </Link>
        </React.Fragment>
      )
    case 'action_move_card_from_board':
      return (
        <React.Fragment>
          {' '}
          to{' '}
          <Link
            to={`https://trello.com/b/${entityBoardShortLink}`}
            target="_blank"
          >
            {entityBoardName}
          </Link>
        </React.Fragment>
      )
    case 'action_added_a_due_date':
    case 'action_changed_a_due_date':
      return ` to ${formatDate(due)}`
    case 'action_archived_list':
      return (
        <React.Fragment>
          {' '}
          on{' '}
          <Link to={`https://trello.com/b/${boardShortLink}`} target="_blank">
            {boardName}
          </Link>
        </React.Fragment>
      )
    case 'action_sent_list_to_board':
      return (
        <React.Fragment>
          {' '}
          to{' '}
          <Link to={`https://trello.com/b/${boardShortLink}`} target="_blank">
            {boardName}
          </Link>
        </React.Fragment>
      )
    case 'action_renamed_card':
      return (
        <React.Fragment>
          {' '}
          to{' '}
          <Link
            target="_blank"
            to={`https://trello.com/c/${shortLink || cardId}`}
          >
            {name}
          </Link>
        </React.Fragment>
      )
    default:
      return null
  }
}
