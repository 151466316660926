import { VictoryLabel } from 'victory'

const positions = {
  dx: {
    2: -20,
  },
  dy: {
    1: -20,
  },
}

const ChartLabel = props => {
  const { index, datum, style } = props
  const color = datum.completed ? 'white' : 'black'
  return (
    <>
      <VictoryLabel
        {...props}
        dx={positions.dx[index] && positions.dx[index]}
        dy={positions.dy[index] && positions.dy[index]}
        textAnchor="middle"
        style={{ ...style, fill: color }}
      />
      <VictoryLabel
        {...props}
        style={{ ...style, fill: color }}
        text={datum.note}
        dx={positions.dx[index] && positions.dx[index]}
        dy={positions.dy[index] ? positions.dy[index] + 24 : 24}
        textAnchor="middle"
      />
      {props.datum.secondNote && (
        <VictoryLabel
          {...props}
          style={{ ...style, fill: color }}
          text={datum.secondNote}
          dx={positions.dx[index] && positions.dx[index]}
          dy={positions.dy[index] ? positions.dy[index] + 48 : 48}
          textAnchor="middle"
        />
      )}
    </>
  )
}

export default ChartLabel
