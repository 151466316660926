import { connect } from 'react-redux'
import { selectLastSnoozedDate } from 'selectors/tickets'
import storage from 'util/storage'
import SnoozeDropdown from './DropdownSwitcher'

const select = state => ({
  lastSnoozedDate: selectLastSnoozedDate(state),
  showHiddenSnoozeOptions: !!storage.get('showHiddenSnoozeOptions'),
})

export default connect(select)(SnoozeDropdown)
