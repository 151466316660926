import cn from 'classnames'
import { labelObjFor } from 'util/recipientLabel'
import { styles } from './styles'

const Recipient = ({ className, recipient }) => {
  if (!recipient) return null
  const { name, email } = labelObjFor(recipient)

  return (
    <div css={styles.recipient} className={cn('grui flex', className)}>
      <div css={styles.truncated} className="name">
        {name}
      </div>
      {email && (
        <div css={styles.truncated} className="email">{`<${email}>`}</div>
      )}
    </div>
  )
}

export default Recipient
