import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { doUpdateCustomFieldValuesFromForm } from 'ducks/crm/contacts'

export default function useDoUpdateCustomFieldValuesFromForm(subjectId) {
  const dispatch = useDispatch()
  return useCallback(
    formData => {
      return dispatch(doUpdateCustomFieldValuesFromForm(subjectId, formData))
    },
    [dispatch, subjectId]
  )
}
