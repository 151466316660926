import { connect } from 'react-redux'
import {
  selectStripeCustomerPageUrl,
  selectStripeSubscriptionPageUrl,
} from 'selectors/sidebar_cards'
import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'
import withCardDataLoading from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/withCardDataLoading'

import View from './view'

const selectFactory = (initialState, initialOwnProps) => {
  const { id: cardId } = initialOwnProps
  return state => ({
    customerPageUrl: selectStripeCustomerPageUrl(state, cardId),
    subscriptionUrls: selectStripeSubscriptionPageUrl(state, cardId),
    integration: selectIntegrationCredentialsForId(state, {
      id: cardId,
    }),
  })
}

export default connect(selectFactory, null)(
  withCardDataLoading(View, { useInAppCardView: false })
)
