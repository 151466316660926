import React from 'react'
import cn from 'classnames'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { styles } from './styles'

const IconItem = ({
  children,
  isActive,
  className,
  title,
  tooltipPosition,
  onClick,
}) => {
  return (
    <Tooltip title={title} strategy="fixed" position={tooltipPosition}>
      <div
        css={styles.base}
        className={cn(className, {
          active: isActive,
        })}
        onClick={onClick}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export default IconItem
