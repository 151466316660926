import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const sortMenuWrapper = css`
  position: relative;
`

const progressCircle = css`
  position: absolute;
  width: ${rem(10)};
  height: ${rem(10)};
  left: 92%;
  top: 13%;
  z-index: 1000;
`

const sortMenu = theme => css`
  &&& {
    color: ${theme.color.monochrome.dark};
    font-weight: ${theme.fontWeight.medium};
    user-select: none;
    z-index: 11;
    padding: 11px 0;
    height: 32px;
    display: flex;
    align-items: center;

    & > .text::before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      transition-duration: 0.15s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      z-index: -1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: ${theme.color.iconButton.backgroundHover};
      border-radius: 4px;
      transform: scale(0);
      transition-property: transform, opacity;
    }

    & > .text:hover::before {
      opacity: 1;
      transform: scale(1);
      color: ${theme.color.iconButton.textHover};
    }

    svg.caret {
      margin-left: 0.5em;
      font-size: 0.7em;
      top: 0;
    }

    svg.arrow-up-down {
      margin-right: ${rem(5)};
    }

    svg.arrow-up-down > g {
      stroke-width: 1.3;
    }
  }
`

const sortOrderTrigger = theme => css`
  font-weight: ${theme.fontWeight.medium};
  color: inherit;
  svg {
    width: 1em;
    height: 1em;
  }
`

export const styles = {
  sortMenuWrapper,
  sortMenu,
  progressCircle,
  sortOrderTrigger,
}
