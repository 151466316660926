import React from 'react'
import { useWatch } from 'react-hook-form'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { styles as createOrderStyles } from 'components/integrations/ShopifyV2WidgetCard/CreateOrderDrawer/styles'
import useColumns from './useColumns'
import { FORM_KEY_LINE_ITEMS } from '../constants'

const OrderItemTable = ({ control, isLoading, isCalculating, updateData }) => {
  const lineItems = useWatch({
    control,
    name: FORM_KEY_LINE_ITEMS,
  })

  const columns = useColumns({ isCalculating })

  return (
    <Table
      columns={columns}
      data={isLoading ? [] : lineItems}
      loading={isLoading}
      updateData={updateData}
      css={[createOrderStyles.table, createOrderStyles.refundOrderTable]}
    />
  )
}

export default OrderItemTable
