import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ExternalLink from '@groovehq/internal-design-system/lib/assets/icons/ExternalLink'
import { selectEventsMetaForCustomerId } from 'ducks/integrations/shopify/selectors'
import { useShopifyTimeline } from 'ducks/integrations/shopify/hooks'
import { capture } from 'ducks/tracking/actions'
import { styles } from './styles'

const ShowMoreEventsLink = ({ integrationId, customerId }) => {
  const { externalLinkViewMoreEvents } = useShopifyTimeline({
    customerId,
    integrationId,
  })
  const { pageInfo: { hasNextPage = false } = {} } =
    useSelector(state => selectEventsMetaForCustomerId(state, customerId)) || {}

  const onViewExternalLinkClicked = useCallback(() => {
    capture('Shopify Timeline Link Clicked')
    return true
  }, [])

  if (!hasNextPage) return null
  return (
    <div css={styles.ordersFooter}>
      <a
        className="grui"
        href={externalLinkViewMoreEvents}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onViewExternalLinkClicked}
      >
        View timeline in Shopify<ExternalLink className="shopifyV2-externalLink-icon" />
      </a>
    </div>
  )
}

export default ShowMoreEventsLink
