import { css } from '@emotion/react'
import ContactAvatar from 'components/crm/ContactAvatar'
import AvatarEditField from 'components/crm/AvatarEditField'

import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'
import MissingAvatar from './MissingAvatar'

const companyLogo = css`
  .DisplayedAvatar.ui.avatar.image {
    border-radius: 2px;
  }
`
export default function CompanyAvatarEditField() {
  const logoUrl = useSelectCustomFieldValueForKeyAndType(
    'company_logo',
    'COMPANY',
    'url'
  )
  return (
    <AvatarEditField
      as={ContactAvatar}
      css={companyLogo}
      contact={false}
      Fallback={MissingAvatar}
      name="company_logo"
      size={80}
      url={logoUrl}
    />
  )
}
