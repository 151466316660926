import { connect } from 'react-redux'
import { compose } from 'redux'
import { withProps } from 'recompose'

import {
  doCreateAndLinkJiraCloudIssueToTicket,
  doFetchJiraCloudProjects,
  doFetchJiraCloudCreateIssueMetaTypeFields,
  selectJiraAreCreateIssueTypeFieldsLoading,
} from 'ducks/integrations/jiraCloud'
import {
  selectJiraAreProjectsLoading,
  selectJiraSiteIdForProject,
  selectJiraSitesAreLoading,
} from 'ducks/integrations/jiraShared'

import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'
import { required } from 'util/validation'

import LinkNewJiraCloudIssueModal from './view'

function mapStateToProps(state, { formFields: { project: projectId } }) {
  return {
    areProjectsLoading: selectJiraAreProjectsLoading(state, {
      source: 'jiraCloud',
    }),
    areSitesLoading: selectJiraSitesAreLoading(state, {
      source: 'jiraCloud',
    }),
    areCreateIssueTypeFieldsLoading: selectJiraAreCreateIssueTypeFieldsLoading(
      state
    ),
    projectId: projectId && projectId.split(':')[1],
    siteId: selectJiraSiteIdForProject(state, {
      projectId,
      source: 'jiraCloud',
    }),
  }
}
const mapDispatchToProps = {
  doFetchJiraCloudProjects,
  doFetchJiraCloudCreateIssueMetaTypeFields,
  doCreateAndLinkJiraCloudIssueToTicket,
}

export default compose(
  withOpenState,
  withProps({
    initialValues: { summary: '', project: null },
    validators: {
      description: required,
      project: required,
      summary: required,
      type: required,
    },
  }),
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkNewJiraCloudIssueModal)
