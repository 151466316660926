import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Flex, Icon } from 'shared/ui'

import Recipient from './Recipient'

const flexStyle = css`
  flex: 1 0 90%;
`

const Header = ({ className, label, facebookRecipient }) => {
  return (
    <Flex className={className}>
      <Flex css={flexStyle} className="grui overflow-hidden">
        <Icon
          name="facebook"
          size="small"
          color="color.externalBrand.facebook"
        />
        <div className="grui ml-4">
          <Recipient label={label} to={facebookRecipient} />
        </div>
      </Flex>
    </Flex>
  )
}

export default styled(Header)`
  flex-shrink: 0;
  margin-bottom: ${props => props.theme.spacing['5']};
  padding: ${props => props.theme.spacing['3']}
    ${props => props.theme.spacing['10']};
  white-space: nowrap;
`
