import styled from '@emotion/styled'

import LinkedGitHubIssues from './LinkedGitHubIssues'
import LinkGitHubIssue from './LinkGitHubIssue'

export default styled(GitHubIndex)`
  .LinkedGitHubIssues {
    margin-bottom: ${props => props.theme.spacing['10']};

    .LinkedGitHubIssuesRow {
      border-bottom: 1px solid ${props => props.theme.color.monochrome.medium};
      padding: ${props => props.theme.spacing['5']} 0;
      cursor: pointer;
      display: flex;
      align-items: center;

      .iconColumn {
        flex-grow: 0;
        flex-shrink: 0;

        .icon {
          font-size: ${props => props.theme.spacing['6']};
          color: ${props => props.theme.color.monochrome.mediumDark};
        }
      }
      .titleAndSubTitleColumn {
        flex-grow: 1;
        .title,
        .subTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .title {
          color: ${props => props.theme.color.primary.brand};
          font-size: ${props => props.theme.spacing.base};
          line-height: ${props => props.theme.spacing['11']};
        }

        .subTitle {
          font-size: ${props => props.theme.spacing['7']};
          line-height: ${props => props.theme.spacing['10']};
        }
      }
    }
  }
`

function GitHubIndex({ className, doNavigatableSetState }) {
  return (
    <div className={className}>
      <LinkedGitHubIssues doNavigatableSetState={doNavigatableSetState} />
      <LinkGitHubIssue />
    </div>
  )
}
