import {
  DURATION,
  SINGLE_LINE,
  IP_LOCATION,
  NUMBER,
  SOURCE_AND_MEDIUM,
  URL,
} from 'ducks/crm/customFields'
import CustomFieldNestedSet from '../CustomFieldNestedSet'

const FIELD_PROPS = {
  last_viewed: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: URL,
    icon: 'page',
    nestedKey: 'lastPageView.url',
    title: 'Last viewed',
  },
  entrance: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_first_session',
    displayAs: URL,
    icon: 'page',
    nestedKey: 'firstPageView.url',
    title: 'Entrance page',
  },
  referrer: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_latest_session',
    displayAs: SOURCE_AND_MEDIUM,
    nestedKey: 'firstPageView.referrer',
    title: 'Source/Medium',
  },
  address: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_ip_address',
    displayAs: SINGLE_LINE,
    icon: 'network',
    nestedKey: 'address',
    title: 'IP address',
  },
  location: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_ip_address',
    displayAs: IP_LOCATION,
    title: 'Location',
  },
  page_views: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_total_page_views',
    displayAs: NUMBER,
    icon: 'copy',
    title: 'Total pages views',
  },
  visits: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_total_page_visits',
    displayAs: NUMBER,
    icon: 'books',
    title: 'Total visits',
  },
  time_on_site: {
    categoryType: 'CONTACT',
    customFieldKey: 'contact_total_time_on_site',
    displayAs: DURATION,
    title: 'Total time on site',
  },
}

export default function VisitHistory({ doHideCard }) {
  return (
    <CustomFieldNestedSet
      collection="visit_history"
      doHideCard={doHideCard}
      fields={FIELD_PROPS}
      title="Visit history"
    />
  )
}
