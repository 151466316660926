import { connect } from 'react-redux'
import {
  selectLastForgotMessage,
  selectLastForgotIsSent,
} from 'selectors/account'
import { email } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'
import { selectPrevious } from 'selectors/location'
import ForgotForm from '../ForgotForm'
import { doForgotSubdomain } from './actions'

const initialValues = {
  emailInput: '',
}

const validators = {
  emailInput: email,
}

const select = state => ({
  initialValues,
  validators,
  forgotError: selectLastForgotMessage(state),
  isSent: selectLastForgotIsSent(state),
  submitText: 'Send reset link',
  previousPage: selectPrevious(state),
})

const perform = {
  onSave: doForgotSubdomain,
}

export default connect(select, perform)(withFormFields(ForgotForm))
