import styled from '@emotion/styled'
import themeVars from 'ui_theme/site/globals/site.variables'

const OverlayBackground = styled('div')`
  background: black;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 97;
  transition: ${themeVars.defaultDuration} opacity ease;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  opacity: ${({ visible }) => (visible ? '0.2' : '0')};
`

export default OverlayBackground
