import LinkedJiraServerIssues from './LinkedJiraServerIssues'
import LinkJiraServerIssue from './LinkJiraServerIssue'

export default function JiraServerIndex({ className, doNavigatableSetState }) {
  return (
    <div className={className}>
      <LinkedJiraServerIssues doNavigatableSetState={doNavigatableSetState} />
      <LinkJiraServerIssue />
    </div>
  )
}
