import React from 'react'
import Narrow from './Narrow'
import Wide from './Wide'

export default function TitleBar(props) {
  const { isWide } = props

  if (isWide) return <Wide {...props} />

  return <Narrow {...props} />
}
