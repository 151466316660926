import { useEffect } from 'react'

export const useVideoElement = videoId => {
  useEffect(
    () => {
      const existing = document.getElementById(videoId)
      if (existing) {
        return
      }
      const scriptTag = document.createElement('script')
      scriptTag.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`
      scriptTag.id = videoId
      scriptTag.async = 'async'
      document.body.appendChild(scriptTag)
    },
    [videoId]
  )

  useEffect(() => {
    const existing = document.getElementById('wistia')
    if (existing) {
      return
    }
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://fast.wistia.com/assets/external/E-v1.js'
    scriptTag.id = 'wistia'
    scriptTag.async = 'async'
    document.body.appendChild(scriptTag)
  }, [])
}
