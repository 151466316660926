import { css } from '@emotion/react'

const usageCounter = theme => css`
  border: 1px solid rgba(${theme.color.monochrome.black_rgba}, 0.1);
  background: #fdf8e6;
  color: rgba(${theme.color.monochrome.black_rgba}, 0.8);
  font-size: ${theme.fontSize.large};
  border-radius: ${theme.spacing[3]};
  a,
  button {
    font-weight: inherit;
    color: ${theme.color.primary.info};
    &:hover,
    &:focus {
      color: ${theme.color.info['600']};
    }

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      color: ${theme.color.info['200']};
    }
  }
`

const limitReached = css`
  background-color: #ffeeee;
`

const warningIcon = css`
  path {
    fill: #ffb627;
  }
`

const negativeIcon = css`
  path {
    fill: #ff5454;
  }
`

export const styles = {
  usageCounter,
  limitReached,
  warningIcon,
  negativeIcon,
}
