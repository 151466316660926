import React from 'react'
import Link from 'redux-first-router-link'
import { useSelector } from 'react-redux'
import {
  text,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { selectAppliedDiscount } from 'ducks/billing/selectors/selectAppliedDiscount'
import { discountMessageFor } from 'ducks/billing/util'
import { selectIsTrial } from 'ducks/billing/selectors/selectIsTrial'
import { styles } from './styles'

const DiscountLink = ({ className }) => {
  const isTrial = useSelector(selectIsTrial)
  const { coupon } = useSelector(selectAppliedDiscount) || {}

  if (!isTrial) return null
  if (!coupon) return null

  return (
    <Link
      className={className}
      css={[
        button.styles.link,
        text.styles.text2Xs,
        text.styles.fontMedium,
        styles.discountLink,
      ]}
      to={{
        type: SETTINGS_BILLING_PAGE,
      }}
    >
      🥳&nbsp;Save {discountMessageFor(coupon)}
    </Link>
  )
}

export default DiscountLink
