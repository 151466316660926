import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const base = theme => css`
  background-color: ${theme.color.info['900']};
  color: ${theme.color.monochrome.white};
  display: flex;
  align-items: center;
  min-height: ${rem(56)};
`

const logo = css`
  flex-shrink: 0;
  height: ${rem(40)};
  width: ${rem(48)};
  display: flex;
  align-items: center;
  padding: 0 ${rem(16.5)};

  img {
    width: 100%;
  }
`

const icon = css`
  padding: 0;
  &&& svg {
    width: ${rem(32)};
    height: ${rem(32)};
  }
`

const christmasLogo = css`
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
`

const navMenu = css`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const search = theme => css`
  max-width: ${rem(508)};
  margin-left: ${rem(150)};
  flex: 1;

  @media screen and (max-width: ${theme.breakpoints.mediumMonitorBreakpoint}) {
    margin-left: ${rem(40)};
  }

  .search-above-curtain {
    padding: 0;

    .search-backIcon {
      left: ${rem(6)};

      path {
        fill: rgba(${theme.color.monochrome.white_rgba}, 0.5);
      }
    }

    .search-clearIcon {
      opacity: 1;
    }
  }

  .search-suggestions {
    border: none;
    box-shadow: 0 8px 16px 0px rgba(${theme.color.monochrome.black_rgba}, 0.1);
  }
`

const present = theme => css`
  font-size: ${theme.fontSize.xlarge};
`

const discountLink = theme => css`
  background-color: ${theme.color.primary.warningAlt};
  color: ${theme.color.info['900']};
  padding: ${theme.spacing[3]} ${theme.spacing[10]};
  border-radius: 9999px;

  &:hover {
    background-color: #36a900;
    color: ${theme.color.monochrome.white};
  }
`

export const styles = {
  base,
  logo,
  christmasLogo,
  navMenu,
  search,
  present,
  icon,
  discountLink,
}
