import { useCallback } from 'react'

import { IconButton } from 'shared/ui'

export default function HiddenWidget({ children, doShowItem, uiKey }) {
  const onShow = useCallback(() => doShowItem(uiKey), [doShowItem, uiKey])
  return (
    <div className="EditModeControlWindow-widget" onClick={onShow}>
      <div className="EditModeControlWindow-widgetName">{children}</div>
      <IconButton name="eye" size="tiny" svg />
    </div>
  )
}
