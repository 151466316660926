import { css } from '@emotion/react'

const composeFormContainer = theme => css`
  height: 100%;
  background-color: ${theme.color.monochrome.superLight};
  overflow: auto;
`

export const styles = {
  composeFormContainer,
}
