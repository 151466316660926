import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import { css } from '@emotion/react'

import { SUI, Button, Flex } from 'shared/ui'

const stopEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const buttonToolbarStyle = css`
  margin-top: -50px;
`

class View extends Component {
  state = {
    colorPickerIsActive: false,
    newColor: '',
  }

  onRef = node => {
    this.colorInput = node
  }

  handleOpenPicker = evt => {
    stopEvent(evt)
    this.setState({
      colorPickerIsActive: true,
    })
  }

  handleClosePicker = () => {
    this.setState({ colorPickerIsActive: false, newColor: '' })
  }

  // Save the color in local state (dont update label color yet)
  handleColorChanged = (newColor = {}) => {
    this.setState({ newColor: newColor.hex })
  }

  // Call the parent components onChange to update the label color
  handleSave = () => {
    const { onSubmit } = this.props
    const { newColor } = this.state

    onSubmit('color', newColor)
    this.handleClosePicker()
  }

  render() {
    const { color: origColor, className } = this.props
    const { newColor, colorPickerIsActive } = this.state
    const color = newColor || origColor
    const borderStyle = /^#([f]{6}|[f]{3})$/i.test(color)
      ? '1px solid #dfe5ee'
      : 'none'

    return (
      <div ref={this.onRef} className={className}>
        {colorPickerIsActive && (
          <div className="popover">
            <SketchPicker
              color={color}
              onChangeComplete={this.handleColorChanged}
            />
            <Flex css={buttonToolbarStyle} className="grui p-4 justify-end">
              <SUI>
                <Button basic size="small" onClick={this.handleClosePicker}>
                  Cancel
                </Button>
                <Button
                  primary
                  className="grui ml-4"
                  onClick={this.handleSave}
                  size="small"
                >
                  Save
                </Button>
              </SUI>
            </Flex>
          </div>
        )}

        <span
          className="icon"
          onClick={this.handleOpenPicker}
          style={{ backgroundColor: color, border: borderStyle }}
        />
      </div>
    )
  }
}

export default View
