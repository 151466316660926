import React from 'react'
import PropTypes from 'prop-types'
import { runOnNextTick } from 'util/functions'
import { last } from 'util/arrays'
import debug from 'util/debug'
import ScrollerContext from 'components/Scroller/Context'

// Props Proxy HOC for message scrolling
function withMessageScroller(WrappedComponent) {
  class WithMessageScroller extends React.Component {
    static contextType = ScrollerContext

    static scrollToChangeset(changesetId) {
      const id = `changeset-${changesetId}`
      const e = document.getElementById(id)
      if (e) {
        const offsetTop = e.offsetTop
        // Only scroll if the reply is further down the
        // screen. If it's the first reply, no point in
        // scrolling
        if (offsetTop > 300) {
          this.scrollTo(0, e.offsetTop - 50)
        }
      }
    }

    constructor() {
      super()
      this.state = {
        scrolled: false,
      }
      this.isAutoScrolling = false
      this.statelessRefs = {}
      this.boundHandleScroll = this.handleScroll.bind(this)
      this.boundScrollToLastMessage = this.scrollToLastMessage.bind(this)
    }

    componentDidMount() {
      document.body.classList.add('autoScrolled')
      window.addEventListener('scroll', this.boundHandleScroll, false)
      this.scrollToLastMessage()
    }

    componentDidUpdate(prevProps) {
      const { ticket: newTicket, changesets: newChangesets } = this.props
      const { ticket: oldTicket, changesets: oldChangesets } = prevProps

      if (this.isDifferentTicket(oldTicket, newTicket)) {
        return runOnNextTick(this.scrollToLastMessage)
      }

      // NOTE (jscheel): If same ticket, only scroll if new message has come in.
      if (this.isLoaded()) {
        const newLastChangeset = last(newChangesets.messages)
        const oldLastChangeset = last(oldChangesets.messages)
        const newLastChangesetId = newLastChangeset && newLastChangeset.id
        const oldLastChangesetId = oldLastChangeset && oldLastChangeset.id
        const newLastChangesetCleanId =
          newLastChangesetId && newLastChangesetId.replace('optimistic-', '')
        const oldLastChangesetCleanId =
          oldLastChangesetId && oldLastChangesetId.replace('optimistic-', '')
        if (oldLastChangesetCleanId !== newLastChangesetCleanId) {
          return runOnNextTick(this.scrollToLastMessage)
        }
      }

      return false
    }

    componentWillUnmount() {
      document.body.classList.remove(
        'addingNote',
        'autoScrolled',
        'manualScrolled'
      )
      window.removeEventListener('scroll', this.boundHandleScroll, false)
    }

    onLoad = () => {
      runOnNextTick(this.scrollToLastMessage)
    }

    onScrollToChangeset = id => {
      runOnNextTick(() => this.scrollToChangeset(`changeset-${id}`))
    }

    isDifferentTicket = (oldTicket, newTicket) => {
      return newTicket && (!oldTicket || oldTicket.id !== newTicket.id)
    }

    handleScroll() {
      this.checkManualScroll()
    }

    scrollTo(x, y) {
      this.isAutoScrolling = true
      window.scrollTo(x, y)
      runOnNextTick(() => {
        this.isAutoScrolling = false
      })
    }

    checkManualScroll() {
      if (!this.isAutoScrolling) {
        this.manualScrolled = true
        this.autoScrolled = false
        document.body.classList.add('manualScrolled')
        document.body.classList.remove('autoScrolled')
      }
    }

    scrollToChangeset(id) {
      const el = document.getElementById(id)
      if (!el) return false
      const { scrollElementIntoView } = this.context.getScrollerAPI()
      if (!scrollElementIntoView) return false
      return scrollElementIntoView(el)
    }

    shouldScrollToLastMessage() {
      const { ticket, changesets, lastMessageId } = this.props
      return (
        lastMessageId &&
        !this.state.scrolled &&
        ticket &&
        changesets &&
        changesets.messages.length > 1
      )
    }

    scrollToLastMessage = () => {
      if (!this.shouldScrollToLastMessage()) return false
      const { lastMessageId } = this.props
      // didScroll tracks whether the Scroller was ready and scrolled correctly.
      // We dont update our state until the scrolling action actually happened.
      let didScroll = false
      const hash = window.location.hash

      if (hash) {
        didScroll = this.scrollToChangeset(hash.replace(/^#/, ''))
      } else if (lastMessageId) {
        didScroll = this.scrollToChangeset(`changeset-${lastMessageId}`)
      } else {
        debug(
          'Cant find message to scroll to. A `lastMessageId` prop is required.'
        )
      }
      if (didScroll) this.setState({ scrolled: true })
      return didScroll
    }

    resetScroll() {
      this.setState({ scrolled: false })
    }

    isLoaded() {
      return !!this.props.changesets
    }

    render() {
      const props = this.props

      return (
        <WrappedComponent
          {...props}
          parentRefs={this.statelessRefs}
          onLoad={this.onLoad}
          onScrollToChangeset={this.onScrollToChangeset}
        />
      )
    }
  }

  WithMessageScroller.propTypes = {
    lastMessageId: PropTypes.string,
    ticket: PropTypes.object, // eslint-disable-line
  }

  WithMessageScroller.defaultProps = {
    lastMessageId: undefined,
    ticket: undefined,
  }

  return WithMessageScroller
}

export default withMessageScroller
