import cn from 'classnames'
import React, { useCallback, useState, useContext } from 'react'
import AnimateHeight from 'react-animate-height'
import styled from '@emotion/styled'
import AnimateAutoHeightContext from 'components/AnimateAutoHeight/Context'

import themeVars from 'ui_theme/site/globals/site.variables'

export default styled(CollapsableContent)`
  margin-top: 8px;

  .CollapsableContent-trigger {
    color: ${props => props.theme.color.monochrome.mediumDark};
    cursor: pointer;
    font-size: ${props => props.theme.spacing['6']};
    font-weight: ${themeVars.graphikWeightMedium};
    text-align: left;
    min-height: 11px;
    &:hover {
      color: ${props => props.theme.color.primary.brand};
    }
  }
  .CollapsableContent-trigger-right {
    text-align: right;
  }

  .CollapsableContent-fields {
    height: 0;
    overflow: visible;
    margin-top: 6px;
    &.animateOpacity {
      transition: opacity ease 300ms;
      opacity: 0;
    }

    &.shown {
      height: auto;
      opacity: 1;
    }
  }
`
function CollapsableContent({
  animateHeight = true,
  animateOpacity = true,
  children,
  className,
  hideTrigger = '– Hide',
  initiallyShown = false,
  position = 'top right',
  showTrigger = '+ Show',
  shown: overrideShown = null,
  onToggle,
}) {
  const displayBottom = !!position.match(/bottom/)
  const displayLeft = !!position.match(/left/)
  const [shown, setShown] = useState(initiallyShown)
  const doToggle = useCallback(
    () => {
      if (onToggle) onToggle(!shown)
      setShown(!shown)
    },
    [shown, setShown, onToggle]
  )
  const {
    disableAnimations: disableAnimateAutoHeightAnimations,
    restoreAnimationState: restoreAnimateAutoHeightAnimationState,
  } = useContext(AnimateAutoHeightContext)
  const shouldShow = overrideShown !== null ? overrideShown : shown
  const trigger = (
    <div
      className={cn('CollapsableContent-trigger', {
        'CollapsableContent-trigger-right': !displayLeft,
      })}
      onClick={doToggle}
    >
      {(shouldShow ? hideTrigger : showTrigger) || null}
    </div>
  )
  return (
    <div className={cn('CollapsableContent', className)}>
      {!displayBottom && trigger}
      {animateHeight && (
        <AnimateHeight
          animateOpacity={animateOpacity}
          className={'CollapsableContent-fields'}
          height={!shouldShow && animateHeight ? 0 : 'auto'}
          onAnimationStart={disableAnimateAutoHeightAnimations}
          onAnimationEnd={restoreAnimateAutoHeightAnimationState}
        >
          <div className="CollapsableContent-animatedHeightInner">
            {children}
          </div>
        </AnimateHeight>
      )}
      {!animateHeight && (
        <div
          className={cn('CollapsableContent-fields', {
            show: shouldShow,
            animateOpacity,
          })}
        >
          {children}
        </div>
      )}
      {displayBottom && trigger}
    </div>
  )
}
