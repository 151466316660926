import React, { useCallback } from 'react'
import { bool, func, number, string } from 'prop-types'
import {
  text,
  list,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { shopifyMoneyString } from 'util/currency'

import { styles } from './styles'
import AdditionalInfo from './AdditionalInfo'
import DiscountSection from './DiscountSection'
import TaxesSection from './TaxesSection'
import ShippingSection from './ShippingSection'

const OrderSummary = ({
  className,
  disabled,
  currency,
  rawTotal,
  hasTaxes,
  setHasTaxes,
  discount,
  setDiscount,
  shipping,
  setShipping,
  totalTax,
  totalPrice,
  lineItemsSubtotalPrice,
  control,
  updateDraft,
  calculatedDraft,
  hasShippableItems,
  integrationId,
  customerId,
}) => {
  const handleShippingChange = useCallback(
    change => {
      setShipping(change)
    },
    [setShipping]
  )

  return (
    <>
      <div
        className={['grui flex justify-between', className]}
        css={styles.row}
      >
        <div>
          <div css={fieldStyles.labelBox}>Payment</div>
          <ul
            className="grui pb-12"
            css={[list.styles.preflight, styles.summary]}
          >
            <li className="grui flex">
              <span css={text.styles.textMediumDark}>Subtotal</span>
              <span className="grui ml-auto" css={mainStyles.moneyString}>
                {shopifyMoneyString({
                  currencyCode:
                    lineItemsSubtotalPrice?.shopMoney?.currencyCode || currency,
                  amount: lineItemsSubtotalPrice?.shopMoney?.amount,
                })}
              </span>
            </li>
            <li className="grui flex">
              <DiscountSection
                currency={currency}
                discount={discount}
                handleDiscountChange={setDiscount}
                rawTotal={rawTotal}
                disabled={disabled}
                valueClassName="grui ml-auto"
                integrationId={integrationId}
                customerId={customerId}
              />
            </li>
            <li className="grui flex">
              <ShippingSection
                calculatedDraft={calculatedDraft}
                currency={currency}
                shipping={shipping}
                handleShippingChange={handleShippingChange}
                valueClassName="grui ml-auto"
                disabled={disabled}
                hasShippableItems={hasShippableItems}
                integrationId={integrationId}
                customerId={customerId}
              />
            </li>
            <li className="grui flex">
              <TaxesSection
                currency={currency}
                taxes={totalTax}
                hasTaxes={hasTaxes}
                setHasTaxes={setHasTaxes}
                valueClassName="grui ml-auto"
                disabled={disabled}
                integrationId={integrationId}
                customerId={customerId}
              />
            </li>
            <li
              className="grui flex"
              css={[text.styles.textSuperDark, text.styles.fontMedium]}
            >
              <span>Total</span>
              <span className="grui ml-auto" css={mainStyles.moneyString}>
                {shopifyMoneyString({
                  currencyCode: currency,
                  amount: totalPrice || 0,
                })}
              </span>
            </li>
          </ul>
        </div>
        <AdditionalInfo
          control={control}
          updateDraft={updateDraft}
          disabled={disabled}
          integrationId={integrationId}
          customerId={customerId}
        />
      </div>
    </>
  )
}

OrderSummary.propTypes = {
  currency: string,
  rawTotal: number,
  onCloseCreateOrder: func,
  className: string,
  hasShippableItems: bool,
  integrationId: string.isRequired,
  customerId: string.isRequired,
}

OrderSummary.defaultProps = {
  currency: '',
  rawTotal: 0,
  onCloseCreateOrder() {},
  className: undefined,
  hasShippableItems: false,
}

export default OrderSummary
