import React from 'react'

import GlobalContext from './GlobalContext'

import { DROPDOWN, MODAL, MODAL_DROPDOWN } from './constants'

const PRIORITIES = {
  [DROPDOWN]: 3,
  [MODAL]: 5,
  [MODAL_DROPDOWN]: 7,
}
export default class ListenToKeyboardGlobalProvider extends React.PureComponent {
  constructor(props) {
    super(props)
    this.priority = null
    this.state = {
      hijacker: null,
      setHijacker: this.setHijacker,
      unSetHijacker: this.unSetHijacker,
    }
  }

  setHijacker = (hijacker, priority, hijackChildren) => {
    const prioritInt = PRIORITIES[priority] || priority
    if (this.priority > prioritInt) return
    this.priority = prioritInt
    this.setState({
      hijacker,
      hijackChildren,
      priority: prioritInt,
    })
  }

  unSetHijacker = hijacker => {
    if (this.state.hijacker === hijacker) {
      this.priority = null
      this.setState({
        hijacker: null,
        priority: null,
        hijackChildren: false,
      })
    }
  }

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    )
  }
}
