import React, { useCallback } from 'react'
import cn from 'classnames'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { asOverlay } from 'util/hoc'

import styles from './styles-desktop.css'

export default asOverlay(Modal)

function Modal({
  children,
  contentRef,
  position,
  offset = null,
  onBackgroundClick,
  className,
  allowOverflow,
  contentClass,
  noCurtain = false,
  style,
  styleContainer,
  modalProps,
}) {
  const effectiveOffset = offset || modalProps.offset
  const onClick = useCallback(
    e => {
      if (!onBackgroundClick) return null
      if (e.target !== e.currentTarget) return null
      return onBackgroundClick(e)
    },
    [onBackgroundClick]
  )

  return (
    <div className={cn('sui', styles.modal, className)} style={style}>
      <div
        className={cn(styles.contentContainer, {
          [styles['contentContainer-center']]:
            position === 'center' || !position,
          [styles['contentContainer-bottom']]: position === 'bottom',
          [styles['contentContainer-top']]: position === 'top',
        })}
        // Use mousedown instead click,
        // because click event will close the menu if we select text in the menu and end the selection outside
        // for example: select all input text and end the cursor outside the modal
        onMouseDown={onClick}
      >
        <ListenToKeyboard
          stopPropagation
          preventDefault
          onEscape={onBackgroundClick}
        />
        <div
          className={cn(contentClass, styles.content)}
          ref={contentRef}
          style={{
            ...styleContainer,
            ...(effectiveOffset && {
              position: 'absolute',
              top: effectiveOffset.top,
              right: effectiveOffset.right,
              bottom: effectiveOffset.bottom,
              left: effectiveOffset.left,
            }),
            ...(allowOverflow && { overflow: 'visible' }),
          }}
        >
          {children}
        </div>
      </div>
      {!noCurtain && (
        <div
          className={styles.backgroundCuratain}
          onMouseDown={onBackgroundClick}
        />
      )}
    </div>
  )
}
