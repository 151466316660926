import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
  doFetchJiraCloudComponents,
  selectCreateIssueTypeFieldsFieldMeta,
} from 'ducks/integrations/jiraCloud'
import { COMPONENTS } from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import {
  selectJiraAreComponentsLoading,
  selectJiraComponentsAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { emptyArr } from 'util/arrays'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'

export default connect(
  (state, { formFields: { type: issueTypeId, project: projectId } }) => ({
    areComponentsLoading: selectJiraAreComponentsLoading(state, {
      source: 'jiraCloud',
    }),
    components: selectJiraComponentsAsOptions(state, { source: 'jiraCloud' }),
    fieldMeta: selectCreateIssueTypeFieldsFieldMeta(state, {
      projectId,
      issueTypeId,
      fieldKey: COMPONENTS,
    }),
  }),
  { doFetchJiraCloudComponents }
)(ComponentFormField)

function ComponentFormField({
  areComponentsLoading,
  components,
  doFetchJiraCloudComponents: fetchComponents,
  formFields: { component = [], type },
  isProjectChoosen,
  onChange,
  projectId,
  resetFields,
  siteId,
  fieldMeta,
}) {
  useEffect(
    () => {
      if (siteId && projectId) {
        fetchComponents(siteId, projectId)
      }
    },
    [fetchComponents, projectId, siteId]
  )

  useEffect(() => resetFields('component'), [
    type,
    siteId,
    projectId,
    resetFields,
  ]) // If project changes reset components

  // this field is not supported on JIRA for the project + issue type combo
  if (!fieldMeta) return null

  return (
    <FormRow split>
      <Label as="div">
        Components&nbsp;
        {!fieldMeta.required && <OptionalLabel>(optional)</OptionalLabel>}
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || areComponentsLoading}
        fluid
        loading={areComponentsLoading}
        multiple
        name="component"
        onChange={onChange}
        options={components}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={component || emptyArr}
      />
    </FormRow>
  )
}
