import React from 'react'
import { useSelector } from 'react-redux'

import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import {
  selectContactCompaniesForEmail,
  selectShouldShowCompanySection,
} from 'ducks/integrations/hubspot/selectors'

import { Label } from 'shared/ui'

import Properties from './Properties'
import Section from '../Section'

const companyPrioritizedFields = ['Name']

export default function Company({ id, email, settings, ownersById }) {
  const shouldShow = useSelector(state =>
    selectShouldShowCompanySection(state, id)
  )
  const companyObject = useSelector(state => {
    return selectContactCompaniesForEmail(state, email)
  })
  const specificFields = extractSpecificFields(
    settings,
    /showSidebarField.Company\./
  )
  if (!shouldShow || !companyObject || companyObject.state !== 'fetched')
    return null

  return (
    <Section>
      <Label size="small">Company</Label>
      <Properties
        properties={companyObject.properties}
        prioritizedFields={companyPrioritizedFields}
        specificFields={specificFields}
        ownersById={ownersById}
      />
    </Section>
  )
}
