import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import debug from 'util/debug'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAddingNote } from 'selectors/page'

import {
  selectCurrentRecipientSyncSource,
  selectDraftById,
  selectDraftIdByTicketId,
  selectIsSyncingRecipients,
} from 'ducks/drafts2/selectors'
import {
  doChangeDraft,
  doStartRecipientSyncSearch,
  doFinishRecipientSyncSearch,
  doSyncDraftWithSearchRecipients,
  doSearchRecipients,
} from 'ducks/drafts2/operations'

import { emptyArr } from 'util/arrays'
import { selectChangesetsForTicketId } from 'selectors/currentChangesets'
import { buildDraftDefaults } from 'ducks/drafts2/util'
import { selectRawTicketById } from 'selectors/tickets/byId/selectRawTicketById'
import { FocusContext } from '../Expanded/FocusContext'

export const useRecipientsEditor = ({
  type,
  ticketId,
  draftType = 'reply',
  allowMultipleRecipients = false,
}) => {
  const searchBoxRef = useRef(null)
  const [query, setQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchedRecipients, setSearchedRecipients] = useState([])
  const draftId = useSelector(state =>
    selectDraftIdByTicketId(state, ticketId, draftType)
  )
  const isSyncingRecipients = useSelector(selectIsSyncingRecipients)
  const currentRecipientSyncSource = useSelector(
    selectCurrentRecipientSyncSource
  )
  const ticketChangesets = useSelector(state =>
    selectChangesetsForTicketId(state, ticketId)
  )
  const ticket = useSelector(state => selectRawTicketById(state, ticketId))
  const ticketsState = useSelector(state => state.tickets)

  const defaultDraft = useMemo(
    () => buildDraftDefaults(ticketsState, ticket, ticketChangesets),
    [ticketsState, ticket, ticketChangesets]
  )

  const stateDraft = useSelector(state => selectDraftById(state, draftId))

  const draft = stateDraft || defaultDraft

  const dispatch = useDispatch()
  const forNote = useSelector(selectIsAddingNote)
  const allDraftRecipients = useMemo(
    () => {
      return [
        ...(draft.to || emptyArr),
        ...(draft.cc || emptyArr),
        ...(draft.bcc || emptyArr),
      ]
    },
    [draft]
  )
  const recipients = useMemo(() => draft[type] || emptyArr, [draft, type])

  const { onToRecipientUpdated, setFocus, getFocusedRow } = useContext(
    FocusContext
  )

  const editing = getFocusedRow() === type

  const startEditing = useCallback(
    () => {
      if (allowMultipleRecipients || recipients.length === 0) {
        setFocus(type)
      }
    },
    [allowMultipleRecipients, recipients, setFocus, type]
  )

  const stopEditing = useCallback(
    () => {
      setFocus(null)
    },
    [setFocus]
  )

  const onEscape = useCallback(
    () => {
      setQuery('')
      stopEditing()
    },
    [stopEditing]
  )

  const searchAndSyncRecipients = useCallback(
    async newQuery => {
      let response = null
      response = await dispatch(doSearchRecipients(newQuery))
      const found = response?.contacts && response.contacts[0]
      if (found) {
        dispatch(doSyncDraftWithSearchRecipients(draftType, ticketId, [found]))
      }

      return [response, found]
    },
    [dispatch, draftType, ticketId]
  )

  const searchRecipientsNow = useCallback(
    async newQuery => {
      try {
        setQuery(newQuery)
        const response = await dispatch(doSearchRecipients(newQuery))
        const contactsExcludingSelected = (response?.contacts || []).filter(
          c => !allDraftRecipients.some(rec => c.email === rec.email)
        )
        setSearchedRecipients(contactsExcludingSelected)
      } catch (error) {
        debug('Error occured while executing doBasicSearchContacts', { error })
      } finally {
        setIsSearching(false)
      }
    },
    [dispatch, setIsSearching, allDraftRecipients]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [searchRecipients] = useDebouncedCallback(searchRecipientsNow, 200)

  const onBulkAdd = useCallback(
    (inputRecipients, { replace = false } = {}) => {
      const existingRecipients = replace ? [] : recipients
      const recipientsToAdd = inputRecipients.filter(
        recipient =>
          !existingRecipients.some(
            existingRecipient => recipient.email === existingRecipient.email
          )
      )

      if (recipientsToAdd.length > 0) {
        const newRecipients = allowMultipleRecipients
          ? [].concat(...existingRecipients, ...recipientsToAdd)
          : [recipientsToAdd[0]]

        dispatch(
          doChangeDraft(draftId, draftType, ticketId, null, {
            [type]: newRecipients,
          })
        )

        // NOTE (jscheel): This syncs recipients that may need their names
        // updated. For the "to" field, if the user types an email address
        // and tabs out of the field before the search has a chance to run,
        // it may introduce a weird delay where the name field could show and
        // appear blank, then populate once the search has run. This is a
        // better alternative than not having the enduser data populated when it
        // actually should be though.
        if (type === 'to') {
          const recipient = newRecipients?.[0]
          if (!recipient.id && recipient.email && query !== recipient.email) {
            dispatch(doStartRecipientSyncSearch(type))
            searchAndSyncRecipients(recipient.email)
              .then(([, found]) => {
                dispatch(doFinishRecipientSyncSearch())
                onToRecipientUpdated(found)
              })
              .catch(() => {
                dispatch(doFinishRecipientSyncSearch())
              })
          } else if (
            !recipient.id &&
            recipient.email &&
            query === recipient.email
          ) {
            onToRecipientUpdated(false)
          } else if (recipient.id) {
            onToRecipientUpdated(true)
          }
        } else {
          ;(newRecipients || []).forEach(recipient => {
            if (!recipient.id && recipient.email && query !== recipient.email) {
              searchAndSyncRecipients(recipient.email)
            }
          })
        }
      }
      setQuery('')
      if (!allowMultipleRecipients) {
        stopEditing()
      }
    },
    [
      ticketId,
      dispatch,
      type,
      recipients,
      draftId,
      draftType,
      allowMultipleRecipients,
      stopEditing,
      query,
      searchAndSyncRecipients,
      onToRecipientUpdated,
    ]
  )

  const onChangeList = useCallback(
    newRecipients => {
      onBulkAdd(newRecipients, { replace: true })
    },
    [onBulkAdd]
  )

  const onAdd = useCallback(
    recipient => {
      onBulkAdd([recipient])
    },
    [onBulkAdd]
  )

  const onRemove = useCallback(
    recipient => {
      const exists = recipients.find(existingRecipient => {
        return recipient.email === existingRecipient.email
      })

      if (exists) {
        const index = recipients.indexOf(recipient)
        const newRecipients = [].concat(recipients)
        newRecipients.splice(index, 1)

        dispatch(
          doChangeDraft(draftId, draftType, ticketId, null, {
            [type]: newRecipients,
          })
        )
      }
      setQuery('')
      if (!allowMultipleRecipients) {
        setFocus(type)
      }
    },
    [
      dispatch,
      ticketId,
      type,
      recipients,
      draftId,
      draftType,
      allowMultipleRecipients,
      setFocus,
    ]
  )

  const onFocus = useCallback(
    e => {
      startEditing(e)
    },
    [startEditing]
  )

  const onBlur = useCallback(
    () => {
      if (query === '') stopEditing()
    },
    [stopEditing, query]
  )

  useEffect(
    () => {
      if (query === '') setSearchedRecipients([])
    },
    [query]
  )

  return {
    forNote,
    filtered: searchedRecipients,
    isSearching,
    isSyncingRecipients,
    currentRecipientSyncSource,
    recipients,
    onEscape,
    onAdd,
    onBulkAdd,
    onRemove,
    onChangeList,
    searchRecipientsNow,
    searchRecipients,
    allowMultipleRecipients,
    onFocus,
    onBlur,
    query,
    editing,
    searchBoxRef,
  }
}
