import React from 'react'

import { runOnNextTick } from 'util/functions'
import { Snackbar } from 'shared/ui'

export default class SnackbarSwitcher extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.isNotificationOpen && nextProps.isUndoOpen) {
      // This is for closing the notif when we need to show an undo

      runOnNextTick(this.props.onNotificationHide)
    }
  }

  onUndoAction = () => {
    const { onUndoAction, undoData } = this.props
    return onUndoAction(undoData)
  }

  onUndoHide = () => {
    const { onUndoHide, undoData } = this.props
    return onUndoHide(undoData)
  }

  composeNotificationProps = props => {
    const {
      isNotificationOpen,
      onNotificationHide,
      notificationData: { message, duration, link, linkText, id },
      currentPath,
      isMobile,
    } = props
    return {
      currentPath,
      id: `${id}`,
      isMobile,
      link,
      linkText,
      message,
      autoHideDuration: duration,
      onRequestClose: onNotificationHide,
      open: isNotificationOpen,
    }
  }

  statusDependentOptions = status => {
    switch (status) {
      case 'undoing':
        return { message: 'Undoing send...', autoHideDuration: 0 }
      case 'succeeded':
        return { message: 'Sending cancelled' }
      case 'failed':
        return { message: 'Message could not be cancelled and has been sent' }
      default:
        return { message: 'Unknown state' }
    }
  }
  composeUndoProps = props => {
    const {
      isUndoOpen,
      isMobile,
      undoData: { ticketId, changesetId, status },
    } = props
    if (status === 'sending') {
      return {
        id: `${ticketId}+${changesetId}`,
        message: 'Your email has been sent',
        action: 'Undo',
        onAction: this.onUndoAction,
        onRequestClose: this.onUndoHide,
        autoHideDuration: 0,
        open: isUndoOpen,
        isMobile,
      }
    }
    return {
      ...this.statusDependentOptions(status),
      noTransition: true,
      id: `${ticketId}+${changesetId}`,
      onRequestClose: this.onUndoHide,
      open: isUndoOpen,
      isMobile,
    }
  }

  render() {
    const { isUndoOpen } = this.props
    const currentProps = isUndoOpen
      ? this.composeUndoProps(this.props)
      : this.composeNotificationProps(this.props)
    return <Snackbar {...currentProps} />
  }
}
