import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { Exclamation } from '@groovehq/internal-design-system/lib/assets/icons'
import {
  heading,
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { DRAWER_TYPE_CHANNELS_EMAIL_FORWARD } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { styles } from './styles'

const MailboxContinueForwardingSetup = ({ mailboxId }) => {
  const { id, name } =
    useSelector(state => selectMailbox(state, mailboxId)) || {}
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
    lazyLoad: true,
  })

  const openSetupForwardingDrawer = useCallback(
    () => {
      openAddDrawer(mailboxId, {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', 'forwarding'),
        },
      })
    },
    [openAddDrawer, mailboxId, drawerId]
  )

  if (!id) return null

  return (
    <div className="grui p-12 pt-23 text-center w-100 flex flex-col items-center">
      <Exclamation className="grui mt-3 mb-16" css={styles.icon} />
      <h3 css={heading.styles.h3}>Your {app.t('mailbox')} requires setup!</h3>
      <p
        className="grui"
        css={[
          paragraph.styles.preflight,
          text.styles.textBlack,
          styles.subtitle,
        ]}
      >
        To start receiving mail in your{' '}
        <span
          className="grui tilt-highlight p-4deg"
          css={[text.styles.fontMedium, styles.mailboxNameHighlight]}
        >
          <span>{name}</span>
        </span>{' '}
        {app.t('mailbox')}, you need to set up email forwarding to Groove.
      </p>
      {isManager && (
        <Button
          className="grui mt-12"
          css={styles.button}
          size="big"
          type="info"
          onClick={openSetupForwardingDrawer}
        >
          Setup Email Forwarding
        </Button>
      )}
      {!isManager && (
        <p
          className="grui mt-12"
          css={[
            paragraph.styles.preflight,
            text.styles.textBlack,
            styles.callToAction,
          ]}
        >
          Please contact your administrator to connect your {app.t('mailbox')}{' '}
        </p>
      )}
    </div>
  )
}

export default MailboxContinueForwardingSetup
