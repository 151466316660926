import {
  UserGroup,
  Inbox2,
  User,
  CheckSquare,
  FileText,
  Folder,
  Trash,
  MailWarning,
  CalendarClock,
  MailOpen,
} from '@groovehq/internal-design-system/lib/assets/icons'

export default function FolderKindIcon({ kind, ...restProps }) {
  if (kind === 'unassigned') {
    return <Inbox2 {...restProps} />
  } else if (kind === 'mine') {
    return <User {...restProps} />
  } else if (kind === 'closed') {
    return <CheckSquare {...restProps} />
  } else if (kind === 'drafts') {
    return <FileText {...restProps} />
  } else if (kind === 'assigned') {
    return <UserGroup {...restProps} />
  } else if (kind === 'trash') {
    return <Trash {...restProps} />
  } else if (kind === 'spam') {
    return <MailWarning {...restProps} />
  } else if (kind === 'snoozed') {
    return <CalendarClock {...restProps} />
  } else if (kind === 'open') {
    return <MailOpen {...restProps} />
  }

  return <Folder {...restProps} />
}
