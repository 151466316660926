import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsImpersonating } from 'selectors/app/selectIsImpersonating'

const ImpersonationBar = () => {
  const isImpersonating = useSelector(selectIsImpersonating)

  if (!isImpersonating) return null

  return <div className="impersonation-bar">Impersonating</div>
}

export default ImpersonationBar
