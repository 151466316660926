import React from 'react'
import styles from './styles.css'

const UpdateBanner = () => {
  return (
    <div className={styles.update}>
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          window.location.reload()
        }}
      >
        <b>Update available.</b>&nbsp;
        <span>Click here to refresh</span>
      </a>
    </div>
  )
}

export default UpdateBanner
