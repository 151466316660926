import { css } from '@emotion/react'

const ExpiryLabel = ({ remainingMins, isActive }) => {
  const warningStyle = () => theme => css`
    color: ${isActive
      ? theme.color.monochrome.white
      : theme.color.brand['800']};
    font-weight: ${theme.fontWeight.medium};
  `

  const negativeStyle = () => theme => css`
    color: ${isActive
      ? theme.color.monochrome.white
      : theme.color.primary.negative};
    font-weight: ${theme.fontWeight.medium};
  `

  if (remainingMins === undefined) {
    return null
  }

  if (remainingMins > 24 * 60) {
    return (
      <span css={warningStyle}>
        &lt; {Math.ceil(remainingMins / 60 / 24)} days
      </span>
    )
  }

  if (remainingMins > 1 * 60) {
    return (
      <span css={warningStyle}>&lt; {Math.ceil(remainingMins / 60)} hrs</span>
    )
  }

  if (remainingMins > 1) {
    return <span css={negativeStyle}>&lt; {Math.ceil(remainingMins)} mins</span>
  }

  return <span css={negativeStyle}>Expired</span>
}

export default ExpiryLabel
