import { css } from '@emotion/react'

const base = css`
  & > * {
    width: 100%;
  }
`

const search = css`
  width: 100%;
  max-width: 100%;
`

const menu = theme => css`
  li {
    padding: 10px 24px;
    &:nth-of-type(even):not(:hover) {
      background-color: ${theme.color.table.evenRowBg};
    }
    & > *:last-child {
      display: none;
    }
  }
`
export const styles = { search, base, menu }
