import React from 'react'
import cn from 'classnames'

import Scroller from 'components/Scroller'
import { withResizeChange } from 'util/hoc'
import { propFunc, runOnNextTick } from 'util/functions'
import { getLength, uniq } from 'util/arrays'
import { mod } from 'util/math'
import { ENTER, UP, DOWN, ESCAPE, TAB } from 'constants/keys'

import { SearchBox, Divider, Loader } from 'shared/ui'

import Header from './Header'
import NotFound from './NotFound'
import AgentItem from './AgentItem'
import GroupItem from './GroupItem'

import styles from './styles.css'

const ANY_GROUP = null

class NormalState extends React.Component {
  state = {
    currentIndex: null,
  }

  componentDidMount = () => {
    if (
      this.suggestedAgents &&
      this.suggestedAgents.length > 0 &&
      this.assignee &&
      this.assignee.id
    ) {
      const currentIndex = this.suggestedAgents
        .map(a => a.id)
        .indexOf(this.assignee.id)
      if (currentIndex >= 0) this.setState({ currentIndex })
    }
  }

  onKeyPressTab = () => this.onTabOrEnter()

  onKeyPressEnter = () => this.onTabOrEnter()

  onKeyPressEscape = () => {
    this.props.close()
  }

  onKeyPressDown = () => {
    const { currentIndex } = this.state
    const count = getLength(this.agents)
    const nextIndex = mod(currentIndex + 1, count)
    this.setState({ currentIndex: nextIndex })
  }

  onKeyPressUp = () => {
    const { currentIndex } = this.state
    const count = getLength(this.agents)
    const nextIndex = mod(currentIndex - 1, count)
    this.setState({ currentIndex: nextIndex })
  }

  onTabOrEnter = () => {
    // Assuming this.agents contains the current array of agents (and/or
    // groups!) (that matches what we are rendering), its a simple matter of
    // invoking the handler for the agent or group at the current index.
    const agentOrGroup = this.focusedItem
    // Hack - gets around `destroyVnode` bug in react-lite: `Cannot
    // read property 'vtype' of undefined`. Looks like a keydown handler issue
    runOnNextTick(() => {
      if (!agentOrGroup) return this.handleAgentSelect(null) // Anyone
      if (agentOrGroup.type === 'Agent') {
        return this.handleAgentSelect(agentOrGroup.id)
      }
      // Else assign to Group+Anyone
      return this.props.onGroupSelect(agentOrGroup.id)
    })
  }

  onInput = value => {
    const { onFilter } = this.props
    if (onFilter) onFilter(value)
  }

  getAnyoneOffset() {
    return this.props.hideAnyone ? 0 : 1
  }

  getYouOffset() {
    return this.shouldHideSuggestedAgents ? 1 : 0
  }

  getSuggestedOffset() {
    return getLength(this.suggestedAgents)
  }

  handleAgentSelect = agentId => {
    const {
      selectedGroup,
      onAgentSelect,
      isFilteringPossibleAssignees,
    } = this.props
    const groupId =
      !isFilteringPossibleAssignees && selectedGroup && selectedGroup.id
        ? selectedGroup.id
        : ANY_GROUP
    return onAgentSelect(groupId, agentId)
  }

  handleGroupSelect = groupId => {
    this.props.onGroupSelect(groupId)
  }

  trackActiveElement = node => {
    const { focusedElement } = this.state
    if (node && focusedElement !== node) this.setState({ focusedElement: node })
  }

  keyDownHandler = e => {
    if (e.keyCode === ESCAPE && this.onKeyPressEscape) this.onKeyPressEscape(e)
    if (e.keyCode === ENTER && this.onKeyPressEnter) this.onKeyPressEnter(e)
    if (e.keyCode === UP && this.onKeyPressUp) this.onKeyPressUp(e)
    if (e.keyCode === DOWN && this.onKeyPressDown) this.onKeyPressDown(e)
    if (e.keyCode === TAB && this.onKeyPressTab) this.onKeyPressTab(e)
  }

  // On this tab we have 2 main states
  //  1. Showing Suggested Agents + [Unassigned] + Agents in 'current' group i.e.
  //    a. the group that the ticket is currently assigned to, OR
  //    b. the group that you currently selected. (takes precedence)
  //  2. Showing search results (agents and/or groups) (from the search box)
  //    - this does not show Suggested Agents or Anyone
  //    - Note: Groups are included in these results!
  get agents() {
    const { isFilteringPossibleAssignees } = this.props

    return isFilteringPossibleAssignees
      ? this.searchResults
      : this.defaultStateAgents
  }

  get focusedItem() {
    const { currentIndex } = this.state
    return this.agents[currentIndex]
  }

  get agentsInCurrentGroupWithoutYou() {
    const { filteredAgents: agentsInCurrentGroup, currentAgent } = this.props
    return agentsInCurrentGroup.filter(
      u => (u || {}).id !== (currentAgent || {})?.id
    )
  }

  get defaultStateAgents() {
    // filteredAgents does the heavy lifting for 1a and 1b above
    const { hideAnyone, currentAgent } = this.props

    let agents = this.suggestedAgentsWithoutYou
    agents = agents.concat(this.agentsInCurrentGroupWithoutYou)
    if (!hideAnyone || this.shouldHideSuggestedAgents) {
      agents = [...agents, null]
    }
    return [currentAgent, ...agents]
  }

  // Per 2. above
  get searchResults() {
    const {
      filteredAgentsAllGroups: agents,
      filteredGroupsFromAgentFilter: groups,
    } = this.props

    return [...agents, ...groups]
  }

  get shouldHideSuggestedAgents() {
    return this.props.totalAgentCount < 7
  }

  // Takes the first two suggested agents. Assumes the API doesnt return 'You'
  // in that list
  get suggestedAgentsWithoutYou() {
    if (this.shouldHideSuggestedAgents) return []

    const { currentAgent, suggestedAgents } = this.props
    return uniq(suggestedAgents)
      .filter(a => (a || {})?.id !== (currentAgent || {})?.id)
      .slice(0, 2)
  }

  get suggestedAgents() {
    if (this.shouldHideSuggestedAgents) return []

    const { currentAgent } = this.props
    return [currentAgent, ...this.suggestedAgentsWithoutYou].filter(e => e)
  }

  get assignee() {
    const { selectedAgent, ticket } = this.props
    return selectedAgent || (ticket && ticket.assignee)
  }

  render() {
    const {
      ticket,
      filteredAgents,
      selectedGroup,
      isFilteringPossibleAssignees,
      onGroupTabClick,
      hideAnyone,
      isLoading,
      currentAgent,
      windowHeight,
      hidden,
      canShowPresence,
      close,
    } = this.props

    const { currentIndex, focusedElement } = this.state

    const assignedGroup = ticket && ticket.assigned_group_id

    const youOffset = this.getYouOffset()
    const suggestedOffset = this.getSuggestedOffset()

    const headerHeight = 120
    const top = 8
    const bottom = 20
    const maxItemsHeight = 338
    const maxHeight = Math.min(
      windowHeight - headerHeight - top - bottom,
      maxItemsHeight
    )

    const agentsInCurrentGroupWithoutYou = this.agentsInCurrentGroupWithoutYou

    return (
      <div
        className={cn(styles.assignmentModal, {
          [styles.isSearching]: isFilteringPossibleAssignees,
        })}
      >
        <Header
          selectedGroupName={selectedGroup && selectedGroup.name}
          onGroupTabClick={onGroupTabClick}
        />
        {!hidden && (
          <SearchBox
            className="grui ml-12 mt-10"
            onKeyDown={this.keyDownHandler}
            onKeyUp={this.keyUpHandler}
            onSearchChange={this.onInput}
            placeholder="Search..."
          />
        )}
        <Divider />

        {isFilteringPossibleAssignees &&
          (!this.searchResults ||
            (this.searchResults && this.searchResults.length <= 0)) && (
            <NotFound onCloseAssignmentDropdown={close} />
          )}

        {isLoading && <Loader className="grui my-20" />}
        {!isLoading && (
          <Scroller
            style={{ maxHeight }}
            className={styles.scroller}
            focusedElement={focusedElement}
          >
            {!isFilteringPossibleAssignees && (
              <div>
                <div className={styles.suggestedAgents}>
                  {this.suggestedAgents.map((agent, index) => (
                    <AgentItem
                      agent={agent}
                      agentId={agent.agentId}
                      canShowPresence={canShowPresence}
                      isActive={currentIndex === index}
                      isCurrentChosen={
                        this.assignee && agent.id === this.assignee.id
                      }
                      key={`suggestedAgents-${agent.id}`}
                      onClick={propFunc(this.handleAgentSelect, agent.id)}
                      trackActiveElement={this.trackActiveElement}
                    />
                  ))}
                </div>
                {!this.shouldHideSuggestedAgents && (
                  <div className={styles.separator} />
                )}
                {this.shouldHideSuggestedAgents && (
                  <AgentItem
                    agent={currentAgent}
                    agentId={currentAgent.id}
                    canShowPresence={canShowPresence}
                    isActive={currentIndex === suggestedOffset}
                    isCurrentChosen={
                      this.assignee && currentAgent.id === this.assignee.id
                    }
                    key={`agentsInCurrentGroupWithoutYou-${currentAgent.id}`}
                    onClick={propFunc(this.handleAgentSelect, currentAgent.id)}
                    trackActiveElement={this.trackActiveElement}
                  />
                )}
                {this.agentsInCurrentGroupWithoutYou.map((agent, index) => {
                  const isActive =
                    currentIndex === index + youOffset + suggestedOffset
                  return (
                    <AgentItem
                      agent={agent}
                      agentId={agent.agentId}
                      canShowPresence={canShowPresence}
                      isActive={isActive}
                      isCurrentChosen={
                        this.assignee && agent.id === this.assignee.id
                      }
                      key={`agentsInCurrentGroupWithoutYou-${agent.id}`}
                      onClick={propFunc(this.handleAgentSelect, agent.id)}
                      trackActiveElement={this.trackActiveElement}
                    />
                  )
                })}
                {!hideAnyone && (
                  <AgentItem
                    isUnassigned
                    agents={agentsInCurrentGroupWithoutYou}
                    isActive={
                      currentIndex ===
                      suggestedOffset +
                        youOffset +
                        (agentsInCurrentGroupWithoutYou
                          ? agentsInCurrentGroupWithoutYou.length
                          : 0)
                    }
                    agent={{
                      label: selectedGroup
                        ? `Anyone in ${selectedGroup.name}`
                        : 'Unassigned',
                    }}
                    canShowPresence={canShowPresence}
                    hint={
                      selectedGroup &&
                      `${getLength(filteredAgents)} in ${app.t('group')}`
                    }
                    isCurrentChosen={!this.assignee}
                    onClick={propFunc(this.handleAgentSelect, null)}
                    trackActiveElement={this.trackActiveElement}
                  />
                )}
              </div>
            )}

            {isFilteringPossibleAssignees && (
              <div className={styles.agentList}>
                {this.searchResults.map((agentOrGroup, index) => {
                  if (agentOrGroup.type === 'Agent') {
                    const agent = agentOrGroup
                    return (
                      <AgentItem
                        agent={agent}
                        agentId={agent.agentId}
                        canShowPresence={canShowPresence}
                        isActive={currentIndex === index}
                        isCurrentChosen={
                          this.assignee && agent.id === this.assignee.id
                        }
                        key={`searchResultsAgentOrGroup-${agentOrGroup.id}`}
                        onClick={propFunc(this.handleAgentSelect, agent.id)}
                        trackActiveElement={this.trackActiveElement}
                      />
                    )
                  }
                  const group = agentOrGroup
                  return (
                    <GroupItem
                      agentCount={getLength(group.agents)}
                      isActive={currentIndex === index}
                      isCurrentChosen={
                        assignedGroup && group.id === assignedGroup.id
                      }
                      isCurrent={assignedGroup && assignedGroup === group.id}
                      key={`searchResultsAgentOrGroup-${agentOrGroup.id}`}
                      name={group.name}
                      groupId={group.id}
                      onClick={propFunc(this.handleGroupSelect, group.id)}
                    />
                  )
                })}
              </div>
            )}
          </Scroller>
        )}
      </div>
    )
  }
}

const NormalStateWithResizingChange = withResizeChange(
  ({ height, ...rest }) => {
    return <NormalState {...rest} windowHeight={height} />
  }
)

export default NormalStateWithResizingChange
