import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { memoize } from 'util/memoization'

import ListenToKeyboard from 'components/ListenToKeyboard'
import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import withOpenState from 'shared/ui/withOpenState'
import withDrilledDownGroup from './withDrilledDownGroup'
import Menu from './Menu'

const calculateOffset = memoize((left, right, top, bottom) => ({
  left,
  right,
  top,
  bottom,
}))

export default compose(
  withDrilledDownGroup,
  withOpenState,
  withMenuPositioning
)(AssignmentDropdown)

AssignmentDropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
}

function AssignmentDropdown({
  bottom,
  left,
  offset: passedOffset = { left: 0, right: 0, top: 0, bottom: 0 },
  onOpen,
  open,
  right,
  top,
  trigger,
  bindHotKey,
  ...rest
}) {
  const offset = calculateOffset(
    passedOffset.left ? left + passedOffset.left : left,
    passedOffset.right ? left + passedOffset.right : right,
    passedOffset.top ? left + passedOffset.top : top,
    passedOffset.bottom ? left + passedOffset.bottom : bottom
  )
  return (
    <div onClick={!open ? onOpen : undefined}>
      {bindHotKey && (
        <ListenToKeyboard
          onA={!open ? onOpen : undefined}
          preventDefault
          disableForInput
        />
      )}
      {trigger}
      {open && (
        <ListenToKeyboard hijack={ListenToKeyboard.DROPDOWN}>
          <Menu open={open} {...rest} offset={offset} />
        </ListenToKeyboard>
      )}
    </div>
  )
}
