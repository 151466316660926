import { css } from '@emotion/react'

const searchBox = theme => css`
  width: 100%;
  border-radius: 8px;
  background-color: rgba(${theme.color.monochrome.white_rgba}, 0.25);
  line-height: 1.6;

  p {
    margin: 0;
  }

  .data-search-filter {
    background-color: rgba(${theme.color.monochrome.black_rgba}, 0.3);
    border-radius: 4px;
    padding: 0 6px;
    font-weight: 500;
    display: inline-block;
  }

  &.focused {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${theme.color.monochrome.white};
    color: ${theme.color.monochrome.black};

    .data-search-filter {
      background-color: ${theme.color.secondary.brand};
    }

    .builder-icon {
      color: ${theme.color.monochrome.dark};
    }
  }
`

const editableContentInputWrapper = css`
  min-width: 0;
`

const editableContentInput = css`
  width: 100%;
  // Don't use nowrap here, it will prevent insertting trailing whitespace after a filter node
  white-space: pre !important;

  // Prevent showing the scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

const placeholder = theme => css`
  color: ${theme.color.monochrome.mediumDark};
  position: absolute;
  left: 8px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  display: inline-block;
  pointer-events: none;
`

const filterBadges = theme => css`
  border-bottom: 1px solid ${theme.color.monochrome.medium};
  gap: ${theme.spacing[5]};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.large};
`

const filterBadge = theme => css`
  background-color: ${theme.color.secondary.brand};
  display: inline-flex;
`

const mailboxFilterButton = theme => css`
  border-right: 1px solid ${theme.color.divider};
  max-width: 200px;
  transition: color 0.2s;

  svg {
    width: 9px;
    height: 9px;
    color: ${theme.color.monochrome.mediumDark};
  }
`

const mailboxFilterButtonLight = theme => css`
  && {
    border-color: ${theme.color.monochrome.mediumDark};
  }
`

const searchQueryBuilderButton = theme => css`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  color: ${theme.color.monochrome.white};
  width: 24px;
  height: 24px;

  &&&::before {
    background-color: rgba(${theme.color.monochrome.black_rgba}, 0.15);
  }

  &:hover {
    color: currentColor;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

const datePicker = css`
  border-left-color: transparent;
  border-right-color: transparent;

  .DayPicker-Months {
    .DayPicker-Month {
      border-left-color: transparent;

      &:nth-of-type(odd) {
        border-right-color: transparent;
      }
    }
  }
`
const mailboxFilterDropdown = css`
  width: 320px;
`

export const styles = {
  searchBox,
  editableContentInputWrapper,
  editableContentInput,
  placeholder,
  filterBadges,
  filterBadge,
  mailboxFilterButton,
  mailboxFilterButtonLight,
  searchQueryBuilderButton,
  datePicker,
  mailboxFilterDropdown,
}
