import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { doFetchJiraServerComponents } from 'ducks/integrations/jiraServer'
import {
  selectJiraAreComponentsLoading,
  selectJiraComponentsAsOptions,
} from 'ducks/integrations/jiraShared'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { Dropdown } from 'util/ui'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import OptionalLabel from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/OptionalLabel'

export default connect(
  state => ({
    areComponentsLoading: selectJiraAreComponentsLoading(state, {
      source: 'jiraServer',
    }),
    components: selectJiraComponentsAsOptions(state, { source: 'jiraServer' }),
  }),
  { doFetchJiraServerComponents }
)(ComponentFormField)

function ComponentFormField({
  areComponentsLoading,
  components,
  doFetchJiraServerComponents: fetchComponents,
  formFields: { component = [] },
  isProjectChoosen,
  onChange,
  projectId,
  resetFields,
}) {
  useEffect(
    () => {
      if (projectId) {
        fetchComponents(projectId)
      }
    },
    [fetchComponents, projectId]
  )
  useEffect(() => resetFields('component'), [resetFields, projectId]) // If project changes reset components
  return (
    <FormRow split>
      <Label as="div">
        Components <OptionalLabel>(optional)</OptionalLabel>
      </Label>
      <ValidatedField
        disabled={!isProjectChoosen || areComponentsLoading}
        fluid
        loading={areComponentsLoading}
        multiple
        name="component"
        onChange={onChange}
        options={components}
        placeholder="- Please select -"
        search
        selection
        selectOnNavigation={false}
        validatedFieldComponent={Dropdown}
        value={component || []}
      />
    </FormRow>
  )
}
