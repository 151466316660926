import { memo } from 'react'

import {
  text,
  divider,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as baseStyles } from '../styles'
import { styles } from './styles'
import SummaryContent from './SummaryContent'

const Summary = ({ suggestion, isLoading }) => {
  return (
    <div css={isLoading && baseStyles.loadingState}>
      <div className="grui flex items-center">
        <span css={text.styles.fontMedium} className="grui mr-2">
          Summary
        </span>
      </div>
      {isLoading ? (
        <div css={[styles.html, styles.loadingSummary]}>
          <ul>
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <div
            css={[divider.styles.base, baseStyles.divider]}
            role="separator"
          />
        </div>
      ) : (
        <SummaryContent suggestion={suggestion} />
      )}
    </div>
  )
}

export default memo(Summary)
