import PropTypes from 'prop-types'
import { css } from '@emotion/react'

import { Flex } from 'shared/ui'
import Label from '../Label'
import Row from '../IndentedRow'
import Input from './Input'

const flexStyle = css`
  flex: 1 1 100%;
`

const SimpleRow = ({
  className,
  onChange,
  value,
  type,
  label,
  tabIndex = '-1',
  inputDataTestId,
}) => {
  return (
    <Row css={flexStyle} className={className}>
      <Label>{label}:</Label>
      <Flex className="grui ml-3 flex-1">
        <Input
          dataTestId={inputDataTestId}
          value={value}
          onChange={onChange}
          tabIndex={tabIndex}
          type={type}
        />
      </Flex>
    </Row>
  )
}

SimpleRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default SimpleRow
