import { contrast } from 'util/colors'

import { TRELLO_COLORS } from './constants'

export default function renderLabelLabel({ color, text }) {
  return {
    style: {
      color: `${contrast(TRELLO_COLORS[color])}`,
      background: `#${TRELLO_COLORS[color]}`,
    },
    content: text,
  }
}
