import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import { convertHexToRGBA } from 'util/colors'

const btnBase = theme => css`
  border-radius: ${rem(200)};
  width: 100%;
  font-weight: ${theme.fontWeight.semibold};

  svg {
    margin-right: ${rem(16)};
    width: ${rem(32)};
    height: ${rem(32)};

    @media only screen and (max-width: ${theme.breakpoints.tabletBreakpoint}) {
      margin-right: ${rem(8)};
      width: ${rem(24)};
      height: ${rem(24)};
    }
  }

  & svg + span {
    font-size: ${theme.fontSize.x2large};
    margin-left: 0;

    @media only screen and (max-width: ${theme.breakpoints.tabletBreakpoint}) {
      font-size: ${theme.fontSize.large};
    }
  }
`

const oAuthBtnWithOutline = theme => css`
  ${btnBase(theme)};
  svg {
    rect {
      fill: ${convertHexToRGBA(theme.color.primary.info, 0.1)};
    }
  }
`

export const styles = {
  oAuthBtnWithOutline,
  btnBase,
}
