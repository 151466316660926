import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'
import { styles } from './NavItem.styles'

const Item = styled.div`
  ${styles.base};
`

const NavItem = React.forwardRef(
  (
    { children, className, active, type = 'primary', ...rest },
    forwardedRef
  ) => {
    return (
      <Item
        className={cn(className, { active })}
        type={type}
        ref={forwardedRef}
        {...rest}
      >
        {children}
      </Item>
    )
  }
)

export default NavItem
