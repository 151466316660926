import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { doLabelSelectedTickets } from 'actions/labelings/bulk'
import { selectIsTagsAssignOpen } from 'selectors/modals_base'
import { doShowTagsAssignModal, doHideTagsAssignModal } from 'actions/modals'
import LabelingDropdown from 'components/App/DesktopView/Labels/LabelingDropdown'
import { IconButton } from 'shared/ui'

const select = state => ({
  isTagsAssignModalOpen: selectIsTagsAssignOpen(state),
})

const perform = {
  onApply: doLabelSelectedTickets,
  onShowTagsAssignModal: doShowTagsAssignModal,
  onHideTagsAssignModal: doHideTagsAssignModal,
}

// eslint-disable-next-line react/prefer-stateless-function
class BulkTagIcon extends PureComponent {
  render() {
    const {
      iconSize = 17,
      isTagsAssignModalOpen,
      onHideTagsAssignModal,
      onShowTagsAssignModal,
      ...rest
    } = this.props

    return (
      <>
        {!isTagsAssignModalOpen && (
          <IconButton
            key="tag-icon"
            name="tag"
            tooltip="Tag"
            iconSize={iconSize}
            size="medium"
            svg
            onClick={onShowTagsAssignModal}
          />
        )}
        {isTagsAssignModalOpen && (
          <LabelingDropdown
            key="tag"
            {...rest}
            open
            onClose={onHideTagsAssignModal}
            trigger={
              <IconButton
                key="tag-icon"
                name="tag"
                tooltip="Tag"
                iconSize={iconSize}
                size="medium"
                svg
              />
            }
          />
        )}
      </>
    )
  }
}

export default connect(select, perform)(BulkTagIcon)
