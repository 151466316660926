import React from 'react'
import cn from 'classnames'
import { useDraggable } from '@dnd-kit/core'
import { styles } from './styles'

const Draggable = ({ recipient, type, children, className }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: [recipient.id || recipient.email, type].join('-'),
    data: { type, recipient },
  })
  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      css={styles.draggable}
      className={cn(isDragging && 'grui opacity-50', className)}
    >
      {children}
    </div>
  )
}

export default Draggable
