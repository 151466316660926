import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import themeVars from 'ui_theme/site/globals/site.variables'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { SUI, Header, Text, IconButton, Divider } from 'shared/ui'

import BulkStateBadge from 'components/StateBadge/Bulk'
import BulkAssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge/Bulk'
import ChangeInboxDropdown from 'components/App/DesktopView/shared/ChangeInboxDropdown'
import BulkStarIcon from 'components/Bulk/StarIcon'
import BulkTagIconV1 from 'components/App/DesktopView/Labels/LabelingDropdown/Bulk'
import BulkTagIconV2 from 'components/App/DesktopView/Labels/LabelingDropdownV2/Bulk'

import { getLength } from 'util/arrays'
import { pluralize } from 'util/strings'

import BulkSnoozeIcon from './SnoozeIcon'

const Link = styled('a')`
  font-size: ${props => props.theme.spacing['10']};
  cursor: pointer;
`

const Count = styled('span')`
  font-weight: ${themeVars.pageFontWeightSemiBold};
  font-size: ${props => props.theme.spacing['10']};
`

const OuterContainer = styled(SpacedItems.Container)`
  margin-top: 100px;
`

// (pnagy) This is hacky af but we have a bug in SpacedItems when they're nested.
// If I nest a horizontal container into a vertical container the vertical Items' props will apply as well.
const NestedItems = styled(SpacedItems.Item)`
  margin-top: 0px !important;
`

const DangerButtonStyle = () => theme => css`
  &&:hover svg path {
    fill: ${theme.color.primary.negative} !important;
  }
`

const dividerStyle = () => theme => css`
  display: inline-block;
  padding-top: ${themeVars['4px']};
  height: ${theme.spacing['11']};
`

class DocumentTitle extends React.Component {
  static defaultProps = {
    title: undefined,
  }

  componentWillMount() {
    this.oldTitle = document.title
  }

  componentWillUnmount() {
    // Only set the title back to the old one if it was not changed by something
    // else
    if (document.title === this.props.title) {
      document.title = this.oldTitle
    }
  }

  oldTitle = null

  render() {
    if (this.props.title) document.title = this.props.title
    return null
  }
}

const generateTitle = count =>
  `${count || 0} ${pluralize(count, 'Conversation')} Selected`

const DetailPane = ({
  onCancel,
  onDeleteClick,
  onMarkAsSpamClick,
  onMarkAsUnreadClick,
  onSelectAllShown,
  shownTicketCount,
  ticketIds,
  isExperimentalLabelUiEnabled,
}) => {
  const count = getLength(ticketIds)
  const titleString = generateTitle(count)
  const BulkTagIcon = isExperimentalLabelUiEnabled
    ? BulkTagIconV2
    : BulkTagIconV1

  return (
    <SUI>
      <OuterContainer
        direction="vertical"
        horizontalAlign="center"
        verticalAlign="center"
        gap="large"
      >
        <DocumentTitle title={`${titleString} - Groove`} />

        <SpacedItems.Item>
          <Header
            as="h1"
            textAlign="center"
            css={css`
              &&& {
                font-family: ${themeVars.walsheim} !important;
              }
            `}
          >
            What would you like to do?
          </Header>
        </SpacedItems.Item>

        <SpacedItems.Item>
          <Count>{count || 0}</Count>
          <Text size="large">
            &nbsp;{pluralize(count, 'conversation')} selected.&nbsp;
          </Text>
          <Link onClick={onSelectAllShown}>
            Select all shown ({shownTicketCount})
          </Link>
          <Divider css={dividerStyle} className="grui ml-4" vertical />
          <Link onClick={onCancel} className="grui ml-4">
            Unselect all
          </Link>
        </SpacedItems.Item>

        <SpacedItems.Item>
          <SpacedItems.Container
            direction="horizontal"
            verticalAlign="center"
            horizontalAlign="center"
            gap="mini"
          >
            <NestedItems>
              <BulkStateBadge variant="withIcon" />
            </NestedItems>
            <NestedItems>
              <BulkStarIcon />
            </NestedItems>
            <NestedItems>
              <BulkTagIcon key={`bulk-tag-${isExperimentalLabelUiEnabled}`} />
            </NestedItems>
            <NestedItems>
              <BulkSnoozeIcon
                conversationIds={ticketIds}
                snoozeDropdownNeedOverflow
              />
            </NestedItems>
            <NestedItems>
              <IconButton
                name="delete"
                size="medium"
                onClick={onDeleteClick}
                tooltip="Move to Trash"
                css={DangerButtonStyle}
                svg
              />
            </NestedItems>
            <NestedItems>
              <IconButton
                name="spam"
                size="medium"
                onClick={onMarkAsSpamClick}
                tooltip="Mark as Spam"
                css={DangerButtonStyle}
                svg
              />
            </NestedItems>
            <NestedItems>
              <IconButton
                name="envelopeAlt"
                size="medium"
                onClick={onMarkAsUnreadClick}
                tooltip="Mark as Unread"
                svg
              />
            </NestedItems>
            <NestedItems>
              <ChangeInboxDropdown
                ticketIds={ticketIds}
                trigger={
                  <IconButton
                    name="moveToInbox"
                    svg
                    size="medium"
                    iconSize={19}
                    tooltip={`Change ${app.t('Mailbox')}`}
                  />
                }
              />
            </NestedItems>
            <NestedItems>
              <BulkAssignmentBadge iconSize={18} />
            </NestedItems>
          </SpacedItems.Container>
        </SpacedItems.Item>
      </OuterContainer>
    </SUI>
  )
}

export default DetailPane
