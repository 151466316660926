import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SHOPIFY_EMAIL_PAGE } from 'subapps/onboarding/shopify/pages'
import { FORGOT_SUBDOMAIN_PAGE } from 'constants/pages'
import config from 'config'
import { Button, Form } from 'util/ui'
import LoginLayout from 'components/LoginLayout'
import Link from 'redux-first-router-link'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextDivider } from 'shared/ui'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import { selectQueryParams } from 'selectors/location'
import { styles } from './styles'

const grooveDomain = `.${config.appDomain}`
const signupTo = { type: SHOPIFY_EMAIL_PAGE }

const FORM_SCHEMA = yup.object().shape({
  subdomain: yup.string().required(),
})

export default function LoginSelector() {
  const query = useSelector(selectQueryParams)
  const {
    handleSubmit,
    formState: { isValid },
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      subdomain: '',
    },
  })

  const returnTo = useMemo(
    () => {
      const { integrationId, shop } = query
      if (integrationId === 'SHOPIFY_V2' && !!shop) {
        return encodeURIComponent(`/settings/redirect/shopify?shop=${shop}`)
      }
      return null
    },
    [query]
  )

  const buildLoginUrl = useCallback(
    subdomain => {
      const base = `https://${
        config.isDevelopment ? 'mobile' : subdomain
      }${grooveDomain}`
      let path = '/login'
      if (returnTo) {
        path += `?returnTo=${returnTo}`
      }
      return `${base}${path}`
    },
    [returnTo]
  )

  const onSubmit = useCallback(
    ({ subdomain }) => {
      window.location = buildLoginUrl(subdomain)
    },
    [buildLoginUrl]
  )

  return (
    <LoginLayout>
      <Form size="large" data-test-id="login" onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Field
            css={styles.domainField}
            label="Connect your existing account"
            name="subdomain"
            placeholder="groove-url"
            tagPosition="right"
            tagContent={grooveDomain}
            {...register('subdomain')}
          />
        </Form.Field>
        <Form.Button
          id="login-email"
          disabled={!isValid}
          primary
          size="big"
          fluid
          name="loginButton"
          type="submit"
        >
          Log in
        </Form.Button>
        <Form.Field className="links">
          <Link to={{ type: FORGOT_SUBDOMAIN_PAGE }}>Forgot subdomain</Link>
        </Form.Field>
        <TextDivider>Or</TextDivider>
        <Button as={Link} to={signupTo} fluid basic size="large">
          Sign up for a new account
        </Button>
      </Form>
    </LoginLayout>
  )
}
