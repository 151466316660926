import React from 'react'
import { css } from '@emotion/react'
import Link from 'redux-first-router-link'
import { Label } from 'shared/components/ui/Typography'

const linkStyle = ({ option }) => theme => css`
  padding: ${theme.spacing['5']} ${theme.spacing['3']};
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: ${option.current
    ? theme.color.primary.brand
    : theme.color.monochrome.mediumDark} !important;
  box-shadow: inset 0 -3px ${option.current ? 'currentColor' : 'transparent'};

  &:hover {
    color: ${theme.color.primary.brand} !important;
  }
`

const Option = ({ option }) => {
  if (option.queryString.length < 1) return null

  return (
    <Link to={option.url} css={linkStyle({ option })}>
      <Label size="small" color="inherit">
        {option.label}
      </Label>
    </Link>
  )
}

const Options = ({ options }) => {
  return Object.values(options).map(option => (
    <Option key={option.queryString} option={option} />
  ))
}

export default Options
