import React, { useMemo } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import AgentChip from './AgentChip'
import { styles } from './styles'

const tooltip = {
  viewing: 'viewing',
  noting: 'leaving a note',
  replying: 'replying',
  typingNote: 'typing a note',
  typingReply: 'typing a reply...',
}

const getAgentsNameString = agents => {
  const names = agents.map(agent => `@${agent.first_name}`)
  const agentCount = 2
  const hintArray = [names.slice(0, agentCount).join(', ')]
  if (names.length > agentCount + 1) {
    hintArray.push(`${names.length - agentCount} others`)
  } else if (names.length > agentCount) {
    hintArray.push(names[agentCount])
  } else if (names.length > 1) {
    hintArray[0] = names[0]
    hintArray.push(names[agentCount - 1])
  }
  let hint = hintArray.join(' and ')
  if (names.length > 1) {
    hint += ' are'
  } else {
    hint += ' is'
  }
  // @graham, @sarah and 3 others are leaving a note
  // @graham is leaving a note
  // @graham and @nick are leaving a note
  // @graham, @sarah and @nick are leaving a note
  return hint
}

const AgentsChip = ({ users = [], type }) => {
  const namesString = useMemo(
    () => {
      return getAgentsNameString(users)
    },
    [users]
  )

  return (
    <Tooltip
      title={`${namesString} ${tooltip[type]}`}
      maxWidth={330}
      css={styles.tooltipBg}
      position="top"
    >
      <div css={styles.agents}>
        {users.map((user, index) => {
          if (index > 2) return null
          return <AgentChip user={user} type={type} key={user?.id} />
        })}
      </div>
    </Tooltip>
  )
}

export default AgentsChip
