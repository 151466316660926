import React from 'react'
import ReactDOM from 'react-dom'

import NoCustomerEmailBanner from 'components/Banners/NoCustomerEmail'
import Printable from 'components/Printable'
import Scroller from 'components/Scroller'
import ImageLightbox from 'components/ImageLightbox'
import Header from 'subapps/ticketing/components/TicketHeader'
import cn from 'classnames'

import { isOpen } from 'util/ticketState'
import { times } from 'util/arrays'
import { runOnNextTick, debounce } from 'util/functions'
import TicketNotFound from 'subapps/ticketing/components/TicketInspector/TicketNotFound'
import TicketDeleted from 'subapps/ticketing/components/TicketInspector/TicketNotFound/TicketDeleted'
import NoAccess from 'subapps/ticketing/components/TicketInspector/NoAccess'

import TicketHero from './TicketHero'
import Changesets from './Changesets'
import Footer from './Footer'
import styles from './styles.css'
import KeyboardHandler from './KeyboardHandler'

class NormalState extends React.Component {
  componentDidMount() {
    const { ticket, doMarkAsRead, isCurrentUserViewer } = this.props
    if (ticket && !isCurrentUserViewer) doMarkAsRead(ticket.id)
    this.fetchTicket()
  }

  componentWillReceiveProps(nextProps) {
    const { ticket, doMarkAsRead, isCurrentUserViewer } = this.props

    if (isCurrentUserViewer) return

    if (!ticket && nextProps.ticket) {
      doMarkAsRead(ticket.id)
    } else if (
      ticket &&
      nextProps.ticket &&
      ticket.id !== nextProps.ticket.id
    ) {
      doMarkAsRead(nextProps.ticket.id)
    }
  }

  componentDidUpdate() {
    this.fetchTicket()
  }

  onResize = debounce((height, diff) => {
    if (this.wrappedScroller) {
      const { getScrollDimensions, scrollToY } = this.scrollerAPI
      const scrolls = getScrollDimensions()

      const newScroll = scrolls.scrollTop + diff

      scrollToY(newScroll)
      // this.wrappedScroller.style.marginBottom = `${height}px`
    } else {
      // The reply editor is in an absolutely positioned div. This means its
      // critical for the marginBottom to be set on the wrapped scroller to
      // prevent its contents from displaying under the reply editor. We have
      // abit of a race condition where wrappedScroller wont be available on the
      // initial load. When that happens we want to just wait a shot period and
      // try again
      runOnNextTick(() => {
        this.onResize(height, diff)
      })
    }
  }, 50)

  setScrollerAPIReference = wrappedScroller => {
    if (wrappedScroller) this.scrollerAPI = wrappedScroller.getScrollerAPI()
  }

  setWrapperReference = component => {
    // eslint-disable-next-line react/no-find-dom-node
    this.wrappedScroller = ReactDOM.findDOMNode(component)
  }

  fetchTicket = () => {
    const { ticket, changesetsLoaded } = this.props
    if (ticket && ticket.full) {
      if (!changesetsLoaded) runOnNextTick(this.fetchTicketActions)
      if (
        !ticket.linkedExternalResources ||
        !ticket.linkedExternalResources.records
      ) {
        if (ticket.linkedExternalResources.loading) return
        runOnNextTick(this.fetchTicketLinkedResources)
      }
    }
  }

  fetchTicketActions = () => {
    const { ticket, doFetchTicketActions } = this.props
    doFetchTicketActions(ticket.id)
  }

  fetchTicketLinkedResources = () => {
    const { ticket, doFetchTicketLinkedResources } = this.props
    doFetchTicketLinkedResources(ticket.id)
  }

  takePrintableRef = node => (this.printableRef = node)

  render() {
    const {
      isCustomerEmailBlank,
      ticket,
      changesetsLoaded,
      isEditorPinned,
      isEditorVisible,
    } = this.props

    const footerContent = (
      <Footer
        isCustomerEmailBlank={isCustomerEmailBlank}
        isOpen={isOpen(ticket)}
        loaded={ticket?.full}
        ticketId={ticket?.id}
        onResize={this.onResize}
      />
    )

    return (
      <div className={styles.TicketInspector}>
        <KeyboardHandler ticketId={ticket?.id} />
        {isCustomerEmailBlank && <NoCustomerEmailBanner />}
        <Header conversationId={ticket?.id} />
        <div
          className={cn(styles.scrollerWrapper)}
          ref={this.setWrapperReference}
        >
          <Scroller
            className={cn('desktopNormalScrollbarDisableRightBorder')}
            ref={this.setScrollerAPIReference}
          >
            <Printable
              passRef={this.takePrintableRef}
              id="printableWholeTicket"
            >
              {ticket && (
                <TicketHero ticket={ticket} className={styles.ticketHero} />
              )}
              {changesetsLoaded && (
                <Changesets scrollerApiRef={this.scrollerAPI} ticket={ticket} />
              )}
              {!changesetsLoaded && (
                <TicketChangesetPlaceholder ticket={ticket} />
              )}
            </Printable>
            {(!isEditorPinned || !isEditorVisible) && footerContent}
          </Scroller>
          {isEditorPinned && isEditorVisible && footerContent}
        </div>
        <ImageLightbox />
      </div>
    )
  }
}

const EmptyState = () => <div className={styles.TicketInspector} />

// Partial ticket loading screen - allows early entry into Label Editing while
// full ticket is still being loaded.

const CollapsedChangesetPlaceholder = () => {
  return (
    <div className="placeholder-collapsed-changeset">
      <div className="placeholder-byline" />
      <div className="placeholder-date" />
      <div className="placeholder-content" />
    </div>
  )
}

const ExpandedChangesetPlaceholder = () => {
  return (
    <div className="placeholder-collapsed-changeset">
      <div className="placeholder-byline" />
      <div className="placeholder-date" />
      <div className="placeholder-cc" />
      <div className="placeholder-content" />
      <div className="placeholder-content" />
      <div className="placeholder-content" />
    </div>
  )
}

const TicketChangesetPlaceholder = ({ ticket }) => {
  const collapsedCount = ticket ? ticket.message_count - 1 : 0
  const rows = []
  if (collapsedCount > 0) {
    times(collapsedCount, i =>
      rows.push(<CollapsedChangesetPlaceholder key={`tcp-${i}`} />)
    )
  }
  rows.push(<ExpandedChangesetPlaceholder key={`tcp-last`} />)

  return <div className={styles.TicketInspector}>{rows}</div>
}

// eslint-disable-next-line react/prefer-stateless-function
class TicketInspector extends React.Component {
  render() {
    const props = this.props
    const { ticket } = props

    if (!ticket) return <EmptyState {...props} />
    if (ticket.loadFailed) {
      const { errors } = ticket
      // No access
      const noAccessError = errors?.find(e => e.status === 403)
      if (noAccessError)
        return <NoAccess mailboxId={noAccessError.meta?.mailbox_id} />
      const deletedError = errors?.find(e => e.status === 410)
      if (deletedError)
        return (
          <TicketDeleted
            actorId={deletedError.meta?.actor_id}
            actorType={deletedError.meta?.actor_type}
          />
        )
      // 404
      return <TicketNotFound />
    }
    return <NormalState {...props} />
  }
}

export default TicketInspector
