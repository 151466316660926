import React from 'react'
import ScrollerContext from 'components/Scroller/Context'
import KeyboardNavContext from './Context'
import Combined from './KeyboardAndScrollerContext'

// This is a reusable piece that could be used by any component that requires both contexts.
const CombinedProvider = props => {
  return (
    <KeyboardNavContext.Consumer>
      {keyboardNavigator => (
        <ScrollerContext.Consumer>
          {args => (
            <Combined.Provider
              value={{ keyboardNavigator, getScrollerAPI: args.getScrollerAPI }}
            >
              {props.children}
            </Combined.Provider>
          )}
        </ScrollerContext.Consumer>
      )}
    </KeyboardNavContext.Consumer>
  )
}

export default CombinedProvider
