import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const base = theme => css`
  background-color: ${theme.color.monochrome.white};
  margin: ${rem(11)};
  margin-top: 0;
  border-radius: ${rem(6)};
  color: ${theme.color.monochrome.black};
  position: absolute;
  bottom: 0;
  z-index: 1;

  p {
    line-height: ${theme.lineHeight.leadingRelaxed};
  }
`

const icon = theme => css`
  background-color: ${theme.color.monochrome.white};
  width: ${rem(48)};
  height: ${rem(48)};
  border-radius: 50%;
  border: 1px solid ${theme.color.monochrome.light};
  font-size: ${rem(27)};
  margin: ${rem(-30)} auto ${rem(8)};

  img,
  svg {
    vertical-align: middle;
    width: ${rem(44)};
    height: ${rem(44)};
    border-radius: 50%;
  }
`

const button = theme => css`
  font-size: inherit;
  width: 100%;
  height: ${rem(40)};
  font-weight: ${theme.fontWeight.medium};
`

const link = theme => css`
  color: ${theme.color.monochrome.mediumDark};
  font-weight: ${theme.fontWeight.normal};

  &:hover {
    color: ${theme.color.monochrome.dark};
  }
`

export const styles = {
  icon,
  base,
  button,
  link,
}
