import React from 'react'
import { useSelector } from 'react-redux'
import { selectShouldHideNewAIMessageCallout } from 'ducks/flags/selectors'
import AiMessageCallout from './AiMessageCallout'

const WithAiMessageCallout = ({ children, hidden }) => {
  const shouldHide = useSelector(selectShouldHideNewAIMessageCallout)
  // Prevent loading the Frill announcement script if the callout should be hidden
  if (hidden || shouldHide !== false) return children
  return <AiMessageCallout>{children}</AiMessageCallout>
}

export default WithAiMessageCallout
