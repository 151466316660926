import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const emptyFolderContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const innerContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const imgWrapper = css`
  width: ${rem(300)};
  margin-bottom: ${rem(30)};

  img {
    width: 100%;
  }
`

const content = theme => css`
  & > p {
    font-size: ${theme.fontSize.x3large};
  }
`

const announcementContainer = css`
  display: none;
  @media (min-height: 700px) {
    display: block;
  }
`

const accouncementImgWrapper = css`
  background-color: #cfebff;
  padding: ${rem(10)};
`

const webinarAnnouncementBtn = theme => css`
  background-color: ${theme.color.brand['800']};
`

export const styles = {
  emptyFolderContainer,
  innerContainer,
  imgWrapper,
  content,
  announcementContainer,
  accouncementImgWrapper,
  webinarAnnouncementBtn,
}
