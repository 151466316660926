import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Scroller from 'components/Scroller'
import { Loader, BoxHeadline, IntegrationError } from 'shared/ui'
import withListController from 'components/App/DesktopView/shared/withListController'

import { getLength } from 'util/arrays'
import { propFunc } from 'util/functions'
import { withResizeChange } from 'util/hoc'

import SearchingListItem from './SearchingListItem'
import SearchingEmptyState from './SearchingEmptyState'

const UserChangeBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
`

const StyledError = styled(IntegrationError)`
  ${UserChangeBox};
  margin: 0 ${props => props.theme.spacing['12']};
`

const StyledInitialMessage = styled(BoxHeadline)`
  ${UserChangeBox};
  text-align: center;
  padding-left: ${props => props.theme.spacing['12']};
  padding-right: ${props => props.theme.spacing['12']};
`

const UserList = ({
  isSearchingError,
  isFetchingSearchResults,
  searchTerm,
  focusedIndex = 0,
  items,
  onSelect,
  onAddNew,
}) => {
  if (isSearchingError) return <StyledError />

  if (!searchTerm && getLength(items) <= 0) {
    return (
      <StyledInitialMessage>
        Change the assigned user to this {app.t('ticket')} by searching above
      </StyledInitialMessage>
    )
  }

  if (isFetchingSearchResults) return <Loader css={UserChangeBox} />

  if (!isFetchingSearchResults && getLength(items) <= 0) {
    return (
      <SearchingEmptyState
        isDesktop
        searchTerm={searchTerm}
        onCreateNewUser={onAddNew}
        css={UserChangeBox}
      />
    )
  }

  return (
    <div className="grui mt-4">
      {items.map((user, index) => (
        <SearchingListItem
          isFocused={index === focusedIndex}
          key={user.id}
          name={user.name}
          email={user.email}
          secondaryEmails={user.secondaryEmails}
          onClick={propFunc(onSelect, user)}
        />
      ))}
    </div>
  )
}

const ScrollingList = props => {
  return (
    <Scroller style={{ height: '200px' }}>
      <UserList {...props} />
    </Scroller>
  )
}

const ScrollingListWithResizingChange = withListController(
  withResizeChange(ScrollingList)
)

export default ScrollingListWithResizingChange
