import React from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import { shopifyMoneyString } from 'util/currency'
import SetTax from '../SetTax'

const TaxesSection = React.memo(
  ({
    currency,
    taxes,
    hasTaxes,
    setHasTaxes,
    valueClassName,
    disabled,
    integrationId,
    customerId,
  }) => {
    const { isCalculating, isSending } = useShopifyDraft({
      integrationId,
      customerId,
    })
    return (
      <>
        <SetTax
          hasTaxes={hasTaxes}
          onHasTaxesChange={setHasTaxes}
          disabled={disabled || isCalculating || isSending}
        >
          <button css={button.styles.link} type="button">
            Taxes
          </button>
        </SetTax>
        <span className={valueClassName}>
          <span css={mainStyles.moneyString}>
            {shopifyMoneyString({ currencyCode: currency, amount: taxes })}
          </span>
        </span>
      </>
    )
  }
)

export default TaxesSection
