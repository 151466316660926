import React from 'react'
import appleLogo from 'assets/appstore.png'
import googleLogo from 'assets/googleplay.png'
import LoginLayoutV2 from 'components/LoginLayoutV2'
import { styles } from './styles'

export default function MobilePage() {
  return (
    <LoginLayoutV2>
      <p css={styles.copy}>
        Use Groove on the go through the native mobile apps. Available for both
        Android and iOS, they offer the best experience with all the main
        features of the web app.
        <br />
        <br />
        To download the app for your platform, visit the relevant store:
      </p>
      <div css={styles.btns}>
        <a
          href="https://apps.apple.com/app/groove-help-desk/id1509476768"
          rel="noopener noreferrer"
          target="_blank"
          css={styles.link}
        >
          <img src={appleLogo} alt="Download on the App Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.groovehq.app"
          rel="noopener noreferrer"
          target="_blank"
          css={styles.link}
        >
          <img src={googleLogo} alt="Get it on Google Play" />
        </a>
      </div>
    </LoginLayoutV2>
  )
}
