import React from 'react'

import KeyValues, { KeyValue } from 'components/KeyValues'
import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { getValueByPath, isObject } from 'util/objects'
import { dumbLinkify, humanize } from 'util/strings'
import { isDefined } from 'util/nullOrUndefinedChecks'

export default class Properties extends React.PureComponent {
  renderProperty(property) {
    const { value } = property
    if (property.type === 'text' && value.match('http')) {
      return <SanitizedHTML html={dumbLinkify(value)} />
    } else if (isObject(value)) {
      return Object.entries(value).map(([key, val]) => (
        <KeyValue key={key} title={humanize(key, true)} value={val} />
      ))
    } else if (isDefined(value) && typeof value === 'string') {
      return <SanitizedHTML html={value} />
    }
    return value
  }

  render() {
    const {
      properties,
      specificFields,
      specificFieldsPattern,
      ownersById,
      settings,
    } = this.props
    let keys
    if (specificFieldsPattern) {
      const pattern = new RegExp(specificFieldsPattern)
      keys = extractSpecificFields(settings, pattern)
      if (!keys) return null
    } else if (specificFields) {
      keys = specificFields
    } else {
      keys = Object.keys(properties)
    }
    const sorted = keys
      .filter(key => properties[key])
      .map(key => properties[key])
      .sort((a, b) => {
        return a.displayOrder > b.displayOrder ? 1 : -1
      })

    const ownerId = getValueByPath('hubspot_owner_id.value', properties)
    const owner = ownerId && ownersById && ownersById[ownerId]

    return (
      <KeyValues>
        {sorted.map(property => (
          <KeyValue
            key={property.key}
            title={property.label}
            value={this.renderProperty(property, owner)}
            singleline={
              property?.type !== 'textarea' && !isObject(property?.value)
            }
          />
        ))}
      </KeyValues>
    )
  }
}
