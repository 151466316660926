import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Loader from 'core/subapps/Loading'
import { doLogout } from 'actions/auth'

export default function LogoutPage() {
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(doLogout())
    },
    [dispatch]
  )
  return <Loader disableFadeIn text="" />
}
