import { connect } from 'react-redux'
import { doShowSnackbar } from 'actions/snackbar'
import { doBulkStarSelectedTickets } from 'actions/tickets'
import { selectAreAllSelectedStarred } from 'selectors/ticket_list'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { getLength } from 'util/arrays'
import { batchActions } from 'util/redux'
import BulkStarIcon from './view'

const select = state => ({
  areAllSelectedStarred: selectAreAllSelectedStarred(state),
  count: getLength(selectSelectedTicketIds(state)),
})

const perform = dispatch => {
  return {
    onStarClick: (count, isUnstarring) =>
      dispatch(
        batchActions(
          doBulkStarSelectedTickets(),
          doShowSnackbar(`${count} ${isUnstarring ? 'unstarred' : 'starred'}`)
        )
      ),
  }
}

export default connect(select, perform)(BulkStarIcon)
