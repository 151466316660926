import { createRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEditorInstance, useEditorMentions } from 'ducks/editor/hooks'
import {
  doMessageTemplateAddPendingAttachment,
  doMessageTemplateRemovePendingAttachment,
} from 'ducks/messageTemplates/operations'
import { v4 as uuidV4 } from 'uuid'
import { selectCurrentAgentsById } from 'selectors/agents/base'
import {
  selectCurrentAttachmentsById,
  selectPendingAttachmentsById,
  selectPendingMessageTemplateAttachmentIdsById,
} from 'ducks/messageTemplates/selectors'

import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import NoteEditor from 'components/App/DesktopView/shared/Composer/Note/Editor'
import MentionSelector from 'components/MentionSelector'
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'
import AttachmentButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AttachmentButton'
import Attachments from '@groovehq/internal-design-system/lib/components/Attachment/Attachments'

const RuleNote = ({
  id,
  value,
  onChange,
  onAttachmentsChange,
  pendingUploads = [],
}) => {
  const dispatch = useDispatch()

  const editorScrollElement = createRef()
  const { editorWrapperRef: composerRef, editorInstance } = useEditorInstance()
  const { onKeyDown, onMention } = useEditorMentions({ editorInstance })

  const agentsById = useSelector(selectCurrentAgentsById)
  const templateAttachmentIds = useSelector(state =>
    selectPendingMessageTemplateAttachmentIdsById(
      state,
      'ruleActionMessageTemplate',
      id
    )
  )
  const currentAttachmentsById = useSelector(selectCurrentAttachmentsById)
  const pendingAttachmentsById = useSelector(selectPendingAttachmentsById)

  const attachments = useMemo(
    () => {
      if (!templateAttachmentIds || !Array.isArray(templateAttachmentIds))
        return []

      const allAttachments = templateAttachmentIds
        .map(attachmentId => {
          const attachment =
            pendingAttachmentsById[attachmentId] ||
            currentAttachmentsById[attachmentId]
          if (!attachment) return null
          return {
            id: attachment.id,
            name: attachment.file_name || attachment.attachment_file_name,
            size: attachment.file_size || attachment.attachment_file_size,
            added: attachment.created_at,
            url: attachment.url,
            uploader: {
              firstName:
                agentsById[attachment.creator_id || attachment.creator]?.name,
            },
          }
        })
        .filter(a => !!a)

      return allAttachments
    },
    [
      templateAttachmentIds,
      agentsById,
      currentAttachmentsById,
      pendingAttachmentsById,
    ]
  )

  const onUploadfile = useCallback(
    event => {
      const files = event.target.files

      Array.from(files).forEach(file => {
        const attachmentId = uuidV4()

        dispatch(
          doMessageTemplateAddPendingAttachment({
            id,
            attachmentId,
            entityType: 'ruleActionMessageTemplate',
            editorFile: file,
          })
        )

        onAttachmentsChange({
          pendingUploads: [
            {
              attachmentId,
              editorFile: file,
            },
          ],
        })
      })
    },
    [dispatch, id, onAttachmentsChange]
  )

  const onAttachmentRemove = useCallback(
    attachmentId => {
      dispatch(
        doMessageTemplateRemovePendingAttachment({
          id,
          entityType: 'ruleActionMessageTemplate',
          attachmentId,
        })
      )

      onAttachmentsChange({
        attachments: templateAttachmentIds.filter(aId => aId !== attachmentId),
        pendingUploads: pendingUploads.filter(
          a => a.attachmentId !== attachmentId
        ),
      })
    },
    [dispatch, id, onAttachmentsChange, templateAttachmentIds, pendingUploads]
  )

  return (
    <div className={styles.form} ref={composerRef}>
      <div className="inner note rule-note" ref={editorScrollElement}>
        <div className="form-header-wrapper">
          <div className="form-header" />
        </div>
        <div className="form-editor-wrapper grui flex-2-1-auto flex-col">
          <MentionSelector
            legacy={false}
            scrollParentRef={editorScrollElement}
            editorInstance={editorInstance}
          />
          <NoteEditor
            content={value}
            contentKey={`rule-note-editor-${id}`}
            className="editor"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onMention={onMention}
          />
          <div className="attachments">
            <Attachments
              attachments={attachments}
              onRemove={onAttachmentRemove}
            />
          </div>
        </div>
        <div className="form-footer-wrapper">
          <div className="form-footer">
            <div className="form-footer-content">
              <div className="grui flex flex-grow">
                <div className="left-side">
                  <div className="actions">
                    <div className="attachments">
                      <AttachmentButton
                        triggerId="replyAttachmentUploadTrigger"
                        attachments={attachments}
                      >
                        <label htmlFor="replyAttachmentUploadTrigger">
                          <input
                            id="replyAttachmentUploadTrigger"
                            type="file"
                            multiple
                            className={styles.input}
                            onChange={onUploadfile}
                          />
                        </label>
                      </AttachmentButton>
                    </div>
                    <EmojiPicker editor={editorInstance} direction="right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RuleNote
