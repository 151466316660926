import { useCallback } from 'react'

export default function ReorderableAndHideableComponent({
  collection,
  onHideItem: doHideItem,
  onShowItem: doShowItem,
  index,
  itemComponent: ItemComponent,
  isInEditMode,
  itemKey,
  itemProps,
  isVisible,
}) {
  const onHideItem = useCallback(() => doHideItem(itemKey), [
    doHideItem,
    itemKey,
  ])
  const onShowItem = useCallback(() => doShowItem(itemKey), [
    doShowItem,
    itemKey,
  ])
  // We have to wrap in div as ItemComponent might not render an HTML element and react-sortable-hoc needs one
  return (
    <div>
      <ItemComponent
        collection={collection}
        onHideItem={onHideItem}
        onShowItem={onShowItem}
        index={index}
        isVisible={isVisible}
        isInEditMode={isInEditMode}
        itemKey={itemKey}
        {...itemProps}
      />
    </div>
  )
}
