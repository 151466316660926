import { Header, SubHeader, Text } from 'shared/ui'
import styles from './styles.css'

// NOTE (jscheel): Verb is included in inverse form.
const HUMANIZED_TARGET_TYPES = {
  reply: { normal: 'reply', inverse: 'add a private comment' },
  note: { normal: 'private comment', inverse: 'add a reply' },
  newEmail: { normal: 'new email', inverse: 'log a conversation' },
  newConversation: { normal: 'new conversation', inverse: 'add a new email' },
}

const DropzoneIndicator = ({
  isDraggingMultipleFiles,
  isHoldingAlt,
  targetType,
}) => {
  const humanizedType = HUMANIZED_TARGET_TYPES[targetType]
  return (
    <div className={styles.Curtain}>
      <div className={styles.Indicator}>
        <Header as="h1">Drop anywhere to upload</Header>
        <SubHeader as="h3" size="small">
          {isDraggingMultipleFiles ? 'Attachments' : 'Attachment'} will be added
          to your {humanizedType.normal}.
        </SubHeader>
      </div>
      <Text>
        {isHoldingAlt ? 'Release' : 'Hold'}{' '}
        <span className={styles.ButtonHint}>Alt / Option</span> to{' '}
        {humanizedType.inverse} instead.
      </Text>
    </div>
  )
}

export default DropzoneIndicator
