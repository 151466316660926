import { connect } from 'react-redux'

import { doHandleUploadComplete, doUploadFiles } from 'actions/attachments'

import {
  selectDraftIdByTicketId,
  selectDraftPropByDraftId,
  makeDraftPropOrDefaultSelector,
  selectAttachmentsByDraftId,
} from 'ducks/drafts2/selectors'
import { doRealtimeAgentStartTicketTypingNotification } from 'actions/realtime'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
  doEnsureDefaults,
  doSwitchToDirectReply,
  doSwitchToReplyAll,
} from 'ducks/drafts2/operations'
import { doSwitchToForward } from 'ducks/drafts2/operations/doSwitchToForward'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { selectIsForwarding } from 'selectors/page'

import { getAssignmentLabel } from 'components/App/DesktopView/CommunicationForms/shared/util'
import { emptyArr } from 'util/arrays'

import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'

import {
  selectAccountPrefersReplyOpen,
  selectPrefersAiEnabled,
} from 'selectors/app/selectAccountPreferences'
import { doOpenTicketPage } from 'actions/pages'
import { doToggleCannedReplyDropdown } from 'ducks/cannedReplies/operations'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'

import View from './view'

const select = () => {
  return (state, props) => {
    const { ticketId } = props
    const ticket = selectRawTicket(state, ticketId)
    const draftId = selectDraftIdByTicketId(state, ticketId, 'reply')

    const selectDraftPropByName = makeDraftPropOrDefaultSelector(
      state,
      draftId,
      ticketId
    )

    const assigneeId = selectDraftPropByName('assigneeId')
    const assigneeGroupId = selectDraftPropByName('assigneeGroupId')
    const serverVersion = selectDraftPropByName('serverVersion')
    const version = selectDraftPropByName('version')

    const isForwardingFromDraft = selectDraftPropByName('isForwarding')
    const isForwardingFromQuery = selectIsForwarding(state)

    const showKbSearch = selectShowKbSearchOnInboxEditor(state)

    return {
      draftId,
      defaultsSet: selectDraftPropByDraftId(state, draftId, 'defaultsSet'),
      defaultsAvailable: !!ticket.draftDefaults,
      syncable: !!draftId,
      isOpen: ticket.isOpen,
      isSynced: serverVersion === version,
      assignmentLabel: getAssignmentLabel(state, assigneeId, assigneeGroupId),
      assigneeGroupId,
      mailboxId: selectDraftPropByName('mailboxId'),
      to: selectDraftPropByName('to') || emptyArr,
      cc: selectDraftPropByName('cc') || emptyArr,
      bcc: selectDraftPropByName('bcc') || emptyArr,
      subject: selectDraftPropByName('title') || ticket.title || '',
      isForwardingFromDraft,
      isForwardingFromQuery,
      forwarding: isForwardingFromDraft || isForwardingFromQuery,
      replyType: selectDraftPropByName('replyType') || 'reply-all',
      isSendable: selectDraftPropByName('isSendable'),
      notSendableReason: selectDraftPropByName('notSendableReason'),
      showKbSearch,
      prefersOpen: selectAccountPrefersReplyOpen(state),
      attachments: selectAttachmentsByDraftId(state, draftId),
      isNewTicket: selectIsComposingNewTicket(state),
      prefersAiEnabled: selectPrefersAiEnabled(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
      if (ticketId !== 'new') {
        dispatch(doOpenTicketPage(ticketId))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId, oldBody } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        'reply',
        draftId,
        ticketId,
        messageId,
        fields,
        oldBody
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'reply', draftId, ticketId, messageId, fields)
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(dispatch, 'reply', draftId, ticketId, messageId, fields)
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
    uploadFiles: (...args) => {
      dispatch(doUploadFiles(...args))
    },
    realtimeAgentStartTicketTypingNotification: (...args) => {
      dispatch(doRealtimeAgentStartTicketTypingNotification(...args))
    },
    switchToReplyAll: (ticketId, draftId) => {
      dispatch(doSwitchToReplyAll(ticketId, 'reply', draftId))
    },
    switchToDirectReply: (ticketId, draftId) => {
      dispatch(doSwitchToDirectReply(ticketId, 'reply', draftId))
    },
    switchToForward: (ticketId, draftId) => {
      dispatch(doSwitchToForward(ticketId, 'reply', draftId))
    },
    ensureDefaults: (draftId, forwarding) => {
      dispatch(doEnsureDefaults(draftId, 'reply', forwarding))
    },
    openTicketPageWithReplying: ticketId => {
      dispatch(doOpenTicketPage(ticketId, { replying: true }))
    },
    openCannedReplyDropdown: (source, cursorBookmark) => {
      dispatch(doToggleCannedReplyDropdown(true, { source, cursorBookmark }))
    },
    handleUploadComplete: (...args) =>
      dispatch(doHandleUploadComplete(...args)),
  }
}

export default connect(select, perform)(View)
