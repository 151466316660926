import { css } from '@emotion/react'

const modalPanel = theme => css`
  max-width: 380px;
  padding: ${theme.spacing[14]};
  font-family: ${theme.font.tertiary};

  button,
  a {
    font-family: inherit;
    font-size: ${theme.fontSize.large};
  }

  .drawer-close-btn {
    background-color: ${theme.color.monochrome.white};
    border-radius: 50%;
    padding: ${theme.spacing[2]};
    box-shadow: 0 1px 3px 0 rgba(${theme.color.monochrome.black_rgba}, 0.15);
    right: -${theme.spacing[12]};
    top: -${theme.spacing[12]};
    bottom: unset;
    left: unset;

    svg {
      color: ${theme.color.monochrome.black};
      height: ${theme.spacing[11]};
      width: ${theme.spacing[11]};
      display: block;
      opacity: 0.5;
    }
  }
`

const title = theme => css`
  font-family: inherit;
  font-weight: ${theme.fontWeight.semibold};
  font-size: 18px;
`

const description = theme => css`
  &&& {
    margin-top: ${theme.spacing[5]};
    font-size: ${theme.fontSize.xlarge};
  }
`

const backdrop = theme => css`
  background-color: rgba(${theme.color.monochrome.black_rgba}, 0.1);
`

export const styles = {
  modalPanel,
  title,
  description,
  backdrop,
}
