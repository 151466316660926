import moment from 'moment'
import Link from 'redux-first-router-link'

import getIssueUrl from './getIssueUrl'

function formatDate(date) {
  if (!date) return ''
  const asMoment = moment(date)
  const displayYear = asMoment.year() !== new Date().getFullYear()
  return asMoment.format(`D MMM${displayYear ? " 'YY" : ''}`)
}

export default function ToText({
  action: {
    change: {
      action: {
        webhookEvent,
        changelog: { items: [{ field, fromString, toString }] = [{}] } = {},
        issue: { fields: { summary } = {}, key, self } = {},
      },
    },
  },
}) {
  switch (webhookEvent) {
    case 'jira:issue_updated':
      switch (field) {
        case 'assignee':
          return [
            toString ? ' to ' : ' from ',
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={getIssueUrl(key, self)}
            >
              [{key}] {summary}
            </Link>,
          ]
        case 'duedate':
          return ` to ${formatDate(toString)}`
        case 'issuetype':
        case 'status':
        case 'resolution':
          return toString && ` to "${toString}"`
        case 'priority':
        case 'project':
        case 'Key':
          return ` from "${fromString}" to "${toString}"`
        case 'labels':
          return ` to ${toString
            .split(' ')
            .map(x => `"${x}"`)
            .join(', ')}`
        case 'summary':
          return [
            ' to ',
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={getIssueUrl(key, self)}
            >
              {toString}
            </Link>,
          ]
        default:
          return null
      }

    // eslint-disable-next-line no-fallthrough
    default:
      return null
  }
}
