import { css } from '@emotion/react'

const copy = theme => css`
  font-size: ${theme.fontSize.large};
  margin-bottom: 0;
  text-align: center;
  color: ${theme.color.info[900]};
`

const btns = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
`

const link = css`
  display: block;
  max-width: 166px;

  img {
    width: 100%;
    display: block;
  }
`

export const styles = {
  copy,
  btns,
  link,
}
