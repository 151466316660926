import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $nodesOfType,
} from 'lexical'
import { createRegexForFilter } from 'util/search/filter'
import getSearchQueryPartAtPosition from 'util/search/getSearchQueryPartAtPosition'
import storage from 'util/storage'
import { escapeRegExp } from 'util/strings'
import { _processQueryStringPart, _splitQueryString } from 'util/search'
import {
  RANGE_SEPARATOR,
  RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY,
} from 'util/search/constants'
import { parseUserTime } from 'util/date'
import {
  $isFilterNode,
  $isMailboxFilter,
  FilterNode,
} from './PlainTextEditor/FilterNode'

export const removeWhitspacesAfterColon = (query = '') =>
  query?.trim().replace(/:\s+/g, ':')

export const mergeRegister = (...func) => {
  return () => {
    func.forEach(f => f())
  }
}

export const recentSearchQueries = {
  get value() {
    return JSON.parse(
      storage.get(RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY) || '[]'
    )
  },
  set value(query) {
    if (!query) return
    const queries = this.value
    const index = queries.indexOf(query)
    if (index > -1) {
      queries.splice(index, 1)
    }
    // Put the latest query at the beginning of the list
    queries.unshift(query)
    if (queries.length > 100) {
      queries.pop()
    }
    storage.set(
      RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY,
      JSON.stringify(queries)
    )
  },
}

const emptyStringForQuotesOnlyFilter = filterString =>
  filterString.replace(/:("|')\s?("|')?$/, ':')

// Replace empty quotes with empty string
// remove the whitespace after colon for each filter
// remove mailbox filters from the query string, because we will use searchMailboxIds
export const normalizeQueryStringAddRemoveMailboxFilters = (
  queryString = ''
) => {
  const splitQuery = _splitQueryString(removeWhitspacesAfterColon(queryString))
  if (!splitQuery) return queryString
  return splitQuery
    .filter(part => {
      const filter = _processQueryStringPart(part)
      const filterName = `${filter.key}:`
      return filterName !== 'inbox:'
    })
    .map(part => emptyStringForQuotesOnlyFilter(part))
    .join(' ')
}

// The value from getSearchQueryPartAtPosition will remove wrapped quotes
// so can compare correctly with the stored queries
const normalizeQueryForSearchComparison = query => {
  if (!query) return ''
  const splitQuery = _splitQueryString(query)
  return splitQuery
    .map(part => {
      const { operator, value } = getSearchQueryPartAtPosition(part, 0)
      if (operator !== 'keywords') {
        return [operator, value || ''].join(':')
      }
      return value
    })
    .join(' ')
}

export const getMatchedRecentSearchQueries = (searchQuery = '') => {
  const deprecatedFilters = [
    'created_after:',
    'created_before:',
    'updated_after:',
    'updated_before:',
  ]
  const maxLength = 3
  const storedSearches = recentSearchQueries.value.filter(
    query => !deprecatedFilters.some(filter => query.includes(filter))
  )
  const matchedSize = 0
  if (searchQuery) {
    const regex = new RegExp(
      escapeRegExp(normalizeQueryForSearchComparison(searchQuery)),
      'i'
    )
    return storedSearches
      .filter(query => {
        if (matchedSize < maxLength) {
          return regex.test(normalizeQueryForSearchComparison(query))
        }
        return false
      })
      .splice(0, maxLength)
  }
  return storedSearches.splice(0, maxLength)
}

// eslint-disable-next-line no-underscore-dangle
export const getTextFromRoot = root => root.__cachedText

export const getCurrentPart = (node, root) => {
  if (root.isEmpty()) {
    return undefined
  }
  const text = node.getTextContent()
  // eslint-disable-next-line no-underscore-dangle
  if (!$isFilterNode(node)) {
    const selection = $getSelection()
    return getSearchQueryPartAtPosition(text, selection.anchor.offset)
  }
  return getSearchQueryPartAtPosition(
    removeWhitspacesAfterColon(emptyStringForQuotesOnlyFilter(text)),
    0
  )
}

export const clearEditorAndAddBaseNode = () => {
  const root = $getRoot()
  const p = $createParagraphNode()
  root.clear().append(p)
  p.select()
}

// Reset the editor state when the search query is submitted to only show keywords
// This is to prevent filters from being persisted in the editor, since we show them in filter badges
export const updateEditorContentAfterSubmit = (editor, keywords) => {
  editor.update(
    () => {
      const root = $getRoot()
      if (keywords.length === 0) {
        clearEditorAndAddBaseNode()
        return
      }
      const p = $createParagraphNode()
      p.append($createTextNode(keywords.join(' ')))
      root.clear()
      root.append(p)
    },
    {
      onUpdate: () => {
        // Editor update will add selection, blur the editor like normal submit
        editor.blur()
      },
      skipTransforms: true,
    }
  )
}

// Replace the current selection with the new filter node, the split symbol is space
export const textNodeTransformAfterSelectFilter = (
  node,
  selection,
  newFilterNode
) => {
  let targetNode
  const text = node.getTextContent()
  const currentOffset = selection.anchor.offset
  const whitespaceArray = []

  for (let i = 0; i < text.length; i += 1) {
    if (text[i] === ' ') {
      whitespaceArray.push(i)
    }
  }

  const closestToOffset =
    whitespaceArray.find(index => index >= currentOffset) ||
    whitespaceArray[whitespaceArray.length - 1] ||
    0

  const splitTextIndexArray = [
    whitespaceArray[whitespaceArray.indexOf(closestToOffset) - 1],
    closestToOffset,
  ].filter(index => index !== undefined)

  const splitNodes = node.splitText(...splitTextIndexArray)
  targetNode =
    splitNodes[
      currentOffset < splitTextIndexArray[splitTextIndexArray.length - 1]
        ? splitTextIndexArray.length - 1
        : splitNodes.length - 1
    ]
  targetNode = targetNode || splitNodes[0]
  targetNode.replace(newFilterNode)
}

// Replace the value for matched filters with valueId in the filter node
export const replaceFilterValues = (queryString, filterNodes, filterName) => {
  // filterName: from:
  const regex = createRegexForFilter(filterName)
  let i = -1
  return queryString.replace(regex, match => {
    i += 1
    if (filterNodes[i].getValueId()) {
      return `${filterName}${filterNodes[i].getValueId()}`
    }
    return match
  })
}

export const exitCurrentEditorNode = editorNode => {
  const nextSibling = editorNode.getNextSibling()
  if (!nextSibling || !nextSibling.getTextContent().startsWith(' ')) {
    editorNode.insertAfter($createTextNode(' '))
  }
  editorNode.selectNext()
}

const getDate = value => {
  if (isNaN(new Date(Date.parse(value)))) {
    return undefined
  }
  const dateTimeArray = value.split(' ')
  return new Date(dateTimeArray[0])
}

const getTime = value => {
  if (isNaN(new Date(Date.parse(value)))) {
    return undefined
  }
  const dateTimeArray = value.split(' ')
  return parseUserTime(dateTimeArray[1])
}

export const getDateTimeValueFromString = (dateQuery, isDateRange) => {
  const { value } = getSearchQueryPartAtPosition(dateQuery, 0)
  if (isDateRange && value) {
    const [startDateString, endDateString] = value.split(RANGE_SEPARATOR).sort()
    return {
      startDate: getDate(startDateString),
      endDate: getDate(endDateString),
    }
  }
  return {
    date: getDate(value),
    time: getTime(value),
  }
}

const allMailboxesOption = { id: 'all', name: `All ${app.t('mailboxes')}` }

export const getMailboxSearchFilterLabel = (selectedMailboxIds, mailboxes) => {
  if (selectedMailboxIds.length === 1) {
    return (
      mailboxes.find(mailbox => mailbox.id === selectedMailboxIds[0])?.name ||
      allMailboxesOption.name
    )
  }
  if (
    !selectedMailboxIds.length ||
    selectedMailboxIds.length === mailboxes.length
  ) {
    return allMailboxesOption.name
  }
  return `Selected ${app.t('mailboxes')}`
}

// Get mailbox ids from the editor state
// Call the function inside editorState.read to get the current editor state
// If the filter node has valueId, return the valueId
// Otherwise, find the mailbox id by matching the mailbox name or email
export const getMailboxIdsFromEditorState = (mailboxes = []) => {
  const filterNodes = $nodesOfType(FilterNode)
  if (filterNodes.length === 0) {
    return []
  }
  const mailboxIds = filterNodes
    .filter(node => $isMailboxFilter(node))
    .map(node => {
      if (node.getValueId()) {
        return node.getValueId()
      }
      const filter = _processQueryStringPart(node.getTextContent())
      const value = filter.values[0]
      if (!value) {
        return undefined
      }
      return mailboxes.find(m =>
        [m.id, m.name?.toLowerCase(), m.email?.toLowerCase()].includes(
          value?.toLowerCase()
        )
      )?.id
    })
  return Array.from(new Set(mailboxIds)).filter(Boolean)
}
