export default function ActionText({
  action: {
    change: {
      action: {
        display: {
          entities: {
            label: { color: labelColor, text: labelName } = {},
            member: { username } = {},
          } = {},
          translationKey,
        } = {},
      },
    },
  },
}) {
  const labelText = labelName && labelName !== '' ? labelName : labelColor
  switch (translationKey) {
    case 'action_changed_description_of_card':
      return 'changed the description of '
    case 'action_renamed_card':
      return 'changed the title of '
    case 'action_archived_card':
      return 'archived '
    case 'action_sent_card_to_board':
      return 'sent '
    case 'action_move_card_from_board':
    case 'action_move_card_from_list_to_list':
      return 'moved '
    case 'action_member_joined_card':
      return 'added themselves to '
    case 'action_member_left_card':
      return 'removed themselves from '
    case 'action_added_member_to_card':
      return `added @${username} to `
    case 'action_removed_member_from_card':
      return `removed @${username} from `
    case 'action_add_label_to_card':
      return `added the label "${labelText}" to `
    case 'action_remove_label_from_card':
      return `removed the label "${labelText}" from `
    case 'action_added_a_due_date':
    case 'action_changed_a_due_date':
      return 'changed the due date for '
    case 'action_removed_a_due_date':
      return 'removed the due date for '
    case 'action_comment_on_card':
      return 'added a comment to '
    case 'action_archived_list':
      return 'archived the list '
    case 'action_sent_list_to_board':
      return 'sent the list '
    case 'action_closed_board':
      return 'closed the board '
    case 'action_reopened_board':
      return 're-opened the board '
    case 'action_delete_card':
      return 'deleted '
    default:
      return null
  }
}
