import { useState, useEffect, useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import GA from 'util/googleAnalytics'
import { styles } from './styles'

const OAuthButtonV2 = ({
  onClick,
  customIcon,
  disabled = false,
  saving = false,
  text = '',
  savingText = '',
  tracking = false,
  dark,
  ...rest
}) => {
  const [clicked, setClicked] = useState(false)
  useEffect(
    () => {
      if (saving === false && clicked) {
        setClicked(false)
      }
    },
    [saving, clicked]
  )

  const handleClick = useCallback(
    () => {
      setClicked(true)
      if (tracking) {
        GA.track(tracking.category, tracking.action, tracking.label)
      }
      onClick()
    },
    [tracking, onClick]
  )

  return (
    <Button
      type={dark ? 'info' : 'infoOutline'}
      size="xBig"
      css={dark ? styles.btnBase : styles.oAuthBtnWithOutline}
      onClick={handleClick}
      disabled={disabled || saving}
      customIcon={customIcon}
      {...rest}
    >
      {clicked && saving && savingText ? savingText : text}
    </Button>
  )
}

export default OAuthButtonV2
