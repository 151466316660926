/* eslint-disable */

import React from 'react'
import cn from 'classnames'
import ReactDOM from 'react-dom'
import config from 'config'
import ResizeObserver from 'resize-observer-polyfill'
import debug from 'util/debug'
import { throttle } from 'util/functions'

class PanelGroup extends React.Component {
  // Load initial panel configuration from props
  constructor() {
    super(...arguments)
    this.state = this.loadPanels(this.props)
    this.observer = new ResizeObserver(this.onResizeObserved)
  }

  // reload panel configuration if props update
  componentWillReceiveProps(nextProps) {
    var nextPanels = nextProps.panelWidths

    // Only update from props if we're supplying the props in the first place
    if (nextPanels.length) {
      // if the panel array is a different size we know to update
      if (this.state.panels.length !== nextPanels.length) {
        this.setState(this.loadPanels(nextProps))
      } else {
        // otherwise we need to iterate to spot any difference
        for (var i = 0; i < nextPanels.length; i++) {
          if (
            this.state.panels[i].size !== nextPanels[i].size ||
            this.state.panels[i].minSize !== nextPanels[i].minSize ||
            this.state.panels[i].resize !== nextPanels[i].resize
          ) {
            this.setState(this.loadPanels(nextProps))
            break
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.removeObserver()
  }

  onResizeObserved = throttle(entries => {
    const { breakpoints = [] } = this.props
    const { hasResized = false } = this.state
    if (breakpoints.length === 0) return
    entries.forEach(({ contentRect: { width }, target }) => {
      breakpoints.forEach(breakpoint => {
        const breakpointActive = width >= breakpoint
        const breakpointName = `panel-breakpoint-${breakpoint}`
        if (breakpointActive && !target.className.includes(breakpointName)) {
          target.className += ` ${breakpointName}`
        } else if (
          !breakpointActive &&
          target.className.includes(breakpointName)
        ) {
          target.className = target.className.replace(breakpointName, '')
        }
      })
    })
    if (!hasResized) {
      this.setState({ hasResized: true })
    }
  }, 100)

  removeObserver() {
    this.observer.disconnect()
    this.observer = null
  }

  // load provided props into state
  loadPanels = props => {
    var panels = []

    if (props.children) {
      // Default values if none were provided
      var defaultSize = 256
      var defaultMinSize = 48
      var defaultMaxSize = 0
      var defaultOffset = 0
      var defaultResize = 'stretch'

      var stretchIncluded = false
      var children = React.Children.toArray(props.children)

      for (var i = 0; i < children.length; i++) {
        if (i < props.panelWidths.length && props.panelWidths[i]) {
          var widthObj = {
            offset:
              props.offsets && props.offsets[i] !== undefined
                ? props.offsets[i]
                : defaultOffset,
            overlay: props.panelWidths[i].overlay || false,
            size:
              props.panelWidths[i].size !== undefined
                ? props.panelWidths[i].size
                : defaultSize,
            maxSize:
              props.panelWidths[i].maxSize !== undefined
                ? props.panelWidths[i].maxSize
                : defaultMaxSize,
            minSize:
              props.panelWidths[i].minSize !== undefined
                ? props.panelWidths[i].minSize
                : defaultMinSize,
            resize: props.panelWidths[i].resize
              ? props.panelWidths[i].resize
              : props.panelWidths[i].size
                ? 'dynamic'
                : defaultResize,
            snap:
              props.panelWidths[i].snap !== undefined
                ? props.panelWidths[i].snap
                : [],
          }
          panels.push(widthObj)
        } else {
          // default values if no props are given
          panels.push({
            offset: defaultOffset,
            overlay: false,
            size: defaultSize,
            resize: defaultResize,
            minSize: defaultMinSize,
            snap: [],
          })
        }

        // if none of the panels included was stretchy, make the last one stretchy
        if (panels[i].resize === 'stretch') stretchIncluded = true
        if (!stretchIncluded && i === children.length - 1)
          panels[i].resize = 'stretch'
      }
    }

    return {
      panels: panels,
    }
  }

  // Pass internal state out if there's a callback for it
  // Useful for saving panel configuration
  onUpdate = (panels, isResizingWindow) => {
    if (this.props.onUpdate && !isResizingWindow) {
      this.props.onUpdate(panels.slice())
    }
  }

  // For styling, track which direction to apply sizing to
  getSizeDirection = caps => {
    if (caps) return this.props.direction === 'column' ? 'Height' : 'Width'
    else return this.props.direction === 'column' ? 'height' : 'width'
  }

  // Render component
  render() {
    const { hasResized = false } = this.state
    var style = {
      container: {
        width: '100%',
        height: '100%',
        ['min' + this.getSizeDirection(true)]: this.getPanelGroupMinSize(
          this.props.spacing
        ),
        display: 'flex',
        flexDirection: this.props.direction,
        flexGrow: 1,
      },
      panel: {
        flexGrow: 0,
        display: 'flex',
      },
    }

    // lets build up a new children array with added resize borders
    var initialChildren = React.Children.toArray(this.props.children)
    var newChildren = []
    var stretchIncluded = false

    for (var i = 0; i < initialChildren.length; i++) {
      if (!this.state.panels[i]) break

      // setting up the style for this panel.  Should probably be handled
      // in the child component, but this was easier for now
      var panelStyle = {
        [this.getSizeDirection()]: this.state.panels[i].size,
        [this.props.direction === 'row' ? 'height' : 'width']: '100%',
        ['min' + this.getSizeDirection(true)]:
          this.state.panels[i].resize === 'stretch'
            ? 0
            : this.state.panels[i].size,

        flexGrow: this.state.panels[i].resize === 'stretch' ? 1 : 0,
        flexShrink: this.state.panels[i].resize === 'stretch' ? 1 : 0,
        display: 'flex',
        position: 'relative',
      }

      // patch in the background color if it was supplied as a prop
      Object.assign(panelStyle, { backgroundColor: this.props.panelColor })

      // give position info to children
      var metadata = {
        isFirst: i === 0 ? true : false,
        isLast: i === initialChildren.length - 1 ? true : false,
        resize: this.state.panels[i].resize,

        // window resize handler if this panel is stretchy
        onWindowResize:
          this.state.panels[i].resize === 'stretch' ? this.setPanelSize : null,
      }

      // if none of the panels included was stretchy, make the last one stretchy
      if (this.state.panels[i].resize === 'stretch') stretchIncluded = true
      if (!stretchIncluded && metadata.isLast) metadata.resize = 'stretch'

      // push children with added metadata
      newChildren.push(
        <Panel
          ComponentWrapper={
            this.props.ComponentWrappers && this.props.ComponentWrappers[i]
          }
          style={panelStyle}
          key={'panel' + i}
          panelID={i}
          observer={this.observer}
          {...metadata}
        >
          {initialChildren[i]}
        </Panel>
      )

      // add a handle between panels
      if (i < initialChildren.length - 1) {
        newChildren.push(
          <Divider
            borderColor={this.props.borderColor}
            key={'divider' + i}
            panelID={i}
            handleResize={this.handleResize}
            dividerWidth={this.props.spacing}
            direction={this.props.direction}
            showHandles={this.props.showHandles}
          />
        )
      }
    }

    return (
      <div
        className={cn('panelGroup', this.props.className, {
          'panel-breakpoint-unknown': !hasResized,
        })}
        style={style.container}
      >
        {newChildren}
      </div>
    )
  }

  // Entry point for resizing panels.
  // We clone the panel array and perform operations on it so we can
  // setState after the recursive operations are finished
  handleResize = (i, delta, isResizingWindow) => {
    var tempPanels = this.state.panels.slice()
    var returnDelta = this.resizePanel(
      i,
      this.props.direction === 'row' ? delta.x : delta.y,
      tempPanels
    )
    this.setState({ panels: tempPanels })
    this.onUpdate(tempPanels, isResizingWindow)
    return returnDelta
  }

  // Recursive panel resizing so we can push other panels out of the way
  // if we've exceeded the target panel's extents
  resizePanel = (panelIndex, delta, panels, callDepth = 0) => {
    if (config.isTest) return
    // 1) first let's calculate and make sure all the sizes add up to be correct.
    let masterSize = 0
    for (let iti = 0; iti < panels.length; iti += 1) {
      masterSize += panels[iti].size
    }
    let boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect()
    let boundingSize =
      (this.props.direction == 'column'
        ? boundingRect.height
        : boundingRect.width) -
      this.props.spacing * (this.props.children.length - 1)

    if (masterSize != boundingSize) {
      // 2) Rectify the situation by adding all the unacounted for space to the first panel
      panels[panelIndex].size += boundingSize - masterSize
    }

    // If either left or right panel exceeds its max size just return.
    // We can't handle this in the same way we are to limit min width
    // as it would easily result in infinite loops and stack overflows.
    // Ideally the resizePanel method would be refactored to calculate
    // panels widths in a more direct way rather than setting them then going
    // round in circles correcting panels that are too wide or too narrow.
    // With this method it is fairly easy to concieve of size configurations
    // that can result in panels exceeding their maximum size. But not
    // with how we currently have it configured. Ergo I don't want to refactor
    // this significant piece of logic for a theoretical future usecase.
    const leftExceedsMaxSize =
      panels[panelIndex].maxSize &&
      panels[panelIndex].size + delta > leftMaxSide
    const rightExceedsMaxSize =
      panels[panelIndex + 1].maxSize &&
      panels[panelIndex + 1].size - delta > panels[panelIndex + 1].maxSize
    if (leftExceedsMaxSize || rightExceedsMaxSize)
      return panels[panelIndex].size

    var minsize
    var maxsize

    // track the progressive delta so we can report back how much this panel
    // actually moved after all the adjustments have been made
    var resultDelta = delta

    // make the changes and deal with the consequences later
    panels[panelIndex].size += delta
    panels[panelIndex + 1].size -= delta

    // Kevin R (2020-07-08)
    // Currently when we go into print preview mode this recursive algorithm looses
    // its shit and goes into an infinite loop. This call depth check prevents this
    // from happening and appears to not breaking anything. Under normal circumstances
    // (IE not printing) the highest callDepth that I observed was 1. Having a max of
    // 20 should be plenty
    if (callDepth > 20) {
      return resultDelta
    }

    // Min and max for LEFT panel
    minsize = this.getPanelMinSize(panelIndex, panels)
    maxsize = this.getPanelMaxSize(panelIndex, panels)

    // if we made the left panel too small
    if (panels[panelIndex].size < minsize) {
      let delta = minsize - panels[panelIndex].size

      if (panelIndex === 0)
        resultDelta = this.resizePanel(
          panelIndex,
          delta,
          panels,
          (callDepth += 1)
        )
      else
        resultDelta = this.resizePanel(
          panelIndex - 1,
          -delta,
          panels,
          (callDepth += 1)
        )
    }

    // if we made the left panel too big
    if (maxsize !== 0 && panels[panelIndex].size > maxsize) {
      let delta = panels[panelIndex].size - maxsize

      if (panelIndex === 0)
        resultDelta = this.resizePanel(
          panelIndex,
          -delta,
          panels,
          (callDepth += 1)
        )
      else
        resultDelta = this.resizePanel(
          panelIndex - 1,
          delta,
          panels,
          (callDepth += 1)
        )
    }

    // Min and max for RIGHT panel
    minsize = this.getPanelMinSize(panelIndex + 1, panels)
    maxsize = this.getPanelMaxSize(panelIndex + 1, panels)

    // if we made the right panel too small
    if (panels[panelIndex + 1].size < minsize) {
      let delta = minsize - panels[panelIndex + 1].size

      if (panelIndex + 1 === panels.length - 1)
        resultDelta = this.resizePanel(
          panelIndex,
          -delta,
          panels,
          (callDepth += 1)
        )
      else
        resultDelta = this.resizePanel(
          panelIndex + 1,
          delta,
          panels,
          (callDepth += 1)
        )
    }

    // if we made the right panel too big
    if (maxsize !== 0 && panels[panelIndex + 1].size > maxsize) {
      let delta = panels[panelIndex + 1].size - maxsize

      if (panelIndex + 1 === panels.length - 1)
        resultDelta = this.resizePanel(
          panelIndex,
          delta,
          panels,
          (callDepth += 1)
        )
      else
        resultDelta = this.resizePanel(
          panelIndex + 1,
          -delta,
          panels,
          (callDepth += 1)
        )
    }

    // Iterate through left panel's snap positions
    for (let i = 0; i < panels[panelIndex].snap.length; i++) {
      if (Math.abs(panels[panelIndex].snap[i] - panels[panelIndex].size) < 20) {
        let delta = panels[panelIndex].snap[i] - panels[panelIndex].size

        if (
          delta !== 0 &&
          panels[panelIndex].size + delta >=
            this.getPanelMinSize(panelIndex, panels) &&
          panels[panelIndex + 1].size - delta >=
            this.getPanelMinSize(panelIndex + 1, panels)
        )
          resultDelta = this.resizePanel(
            panelIndex,
            delta,
            panels,
            (callDepth += 1)
          )
      }
    }

    // Iterate through right panel's snap positions
    for (let i = 0; i < panels[panelIndex + 1].snap.length; i++) {
      if (
        Math.abs(panels[panelIndex + 1].snap[i] - panels[panelIndex + 1].size) <
        20
      ) {
        let delta = panels[panelIndex + 1].snap[i] - panels[panelIndex + 1].size

        if (
          delta !== 0 &&
          panels[panelIndex].size + delta >=
            this.getPanelMinSize(panelIndex, panels) &&
          panels[panelIndex + 1].size - delta >=
            this.getPanelMinSize(panelIndex + 1, panels)
        )
          resultDelta = this.resizePanel(
            panelIndex,
            -delta,
            panels,
            (callDepth += 1)
          )
      }
    }

    // return how much this panel actually resized
    return resultDelta
  }

  // Utility function for getting min pixel size of panel
  getPanelMinSize = (panelIndex, panels) => {
    if (panels[panelIndex].resize === 'fixed') {
      if (!panels[panelIndex].fixedSize) {
        panels[panelIndex].fixedSize = panels[panelIndex].size
      }
      return panels[panelIndex].fixedSize
    }
    return panels[panelIndex].minSize
  }

  // Utility function for getting max pixel size of panel
  getPanelMaxSize = (panelIndex, panels) => {
    if (panels[panelIndex].resize === 'fixed') {
      if (!panels[panelIndex].fixedSize) {
        panels[panelIndex].fixedSize = panels[panelIndex].size
      }
      return panels[panelIndex].fixedSize
    }
    return 0
  }

  // Utility function for getting min pixel size of the entire panel group
  getPanelGroupMinSize = spacing => {
    var size = 0
    for (var i = 0; i < this.state.panels.length; i++) {
      if (!this.state.panels[i].overlay) {
        size += this.getPanelMinSize(i, this.state.panels)
      }
    }
    return size + (this.state.panels.length - 1) * spacing
  }

  // Hard-set a panel's size
  // Used to recalculate a stretchy panel when the window is resized
  setPanelSize = (panelIndex, size, callback) => {
    size = this.props.direction === 'column' ? size.y : size.x
    // Make sure the stretchy panel's size shouldn't include collpased columns' widths
    // so the dynamic panel's width will be correct
    const collapsedPanelsArray = this.props.collapsedPanels
      ? this.props.collapsedPanels?.split(',')
      : []
    if (collapsedPanelsArray?.length) {
      collapsedPanelsArray.forEach(collapsedPanelIndex => {
        size -= this.state.panels[collapsedPanelIndex]?.size
      })
    }

    if (size > 0 && size !== this.state.panels[panelIndex].size) {
      var tempPanels = this.state.panels
      //make sure we can actually resize this panel this small
      if (size < tempPanels[panelIndex].minSize) {
        let diff = tempPanels[panelIndex].minSize - size
        tempPanels[panelIndex].size = tempPanels[panelIndex].minSize

        // 1) Find all of the dynamic panels that we can resize and
        // decrease them until the difference is gone
        for (let i = 0; i < tempPanels.length; i = i + 1) {
          if (i != panelIndex && tempPanels[i].resize === 'dynamic') {
            let available = tempPanels[i].size - tempPanels[i].minSize
            let cut = Math.min(diff, available)
            tempPanels[i].size = tempPanels[i].size - cut
            // if the difference is gone then we are done!
            diff = diff - cut
            if (diff == 0) {
              break
            }
          }
        }
      } else {
        tempPanels[panelIndex].size = size
      }
      this.setState({ panels: tempPanels })

      if (panelIndex > 0) {
        this.handleResize(panelIndex - 1, { x: 0, y: 0 }, true)
      } else if (this.state.panels.length > 2) {
        this.handleResize(panelIndex + 1, { x: 0, y: 0 }, true)
      }

      if (callback) {
        callback()
      }
    }
  }
}

PanelGroup.defaultProps = {
  offsets: [],
  spacing: 1,
  ComponentWrappers: null,
  direction: 'row',
  panelWidths: [],
  // Collapsed panels string with "," as the separator
  collapsedPanels: undefined,
}

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.panelRef = React.createRef()
  }

  // Find the resizeObject if it has one
  componentDidMount() {
    const { resize } = this.props
    if (resize === 'stretch') {
      this.calculateStretchWidth()
    }
    this.startResizeObserver()
  }

  componentWillUnmount() {
    if (this.resizeObserverTimeout) {
      clearTimeout(this.resizeObserverTimeout)
    }
  }

  startResizeObserver = (attempt = 0) => {
    const { observer } = this.props
    if (observer && this.panelRef.current) {
      observer.observe(this.panelRef.current)
    } else if (attempt < 10) {
      this.resizeObserverTimeout = setTimeout(() => {
        this.startResizeObserver(attempt + 1)
      }, 1000)
    } else {
      debug('PANEGROUP:PANEL:CRITICAL', { component: this, props: this.props })
    }
  }

  // Recalculate the stretchy panel if it's container has been resized
  calculateStretchWidth = () => {
    if (this.props.onWindowResize !== null) {
      var rect = ReactDOM.findDOMNode(this).getBoundingClientRect()
      this.props.onWindowResize(
        this.props.panelID,
        { x: rect.width, y: rect.height }

        // recalcalculate again if the width is below minimum
        // Kinda hacky, but for large resizes like fullscreen/Restore
        // it can't solve it in one pass.
        // function() {this.onNextFrame(this.calculateStretchWidth)}.bind(this)
      )
    }
  }

  // Render component
  render() {
    const { children, overlay, style } = this.props
    const ComponentWrapper = this.props.ComponentWrapper || 'div'

    const wrapperProps = {
      className: cn('panelWrapper', {
        overlay,
      }),
      style: style,
      ref: this.panelRef,
    }
    if (ComponentWrapper !== 'div') {
      wrapperProps.overlay = !!overlay ? overlay : undefined
    }

    return <ComponentWrapper {...wrapperProps}>{children}</ComponentWrapper>
  }
}

class Divider extends React.PureComponent {
  state = {
    dragging: false,
    initPos: { x: null, y: null },
  }

  // Add/remove event listeners based on drag state
  componentDidUpdate(props, state) {
    if (this.state.dragging && !state.dragging) {
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    }
  }

  // Start drag state and set initial position
  onMouseDown = e => {
    // only left mouse button
    if (e.button !== 0) return

    this.setState({
      dragging: true,
      initPos: {
        x: e.pageX,
        y: e.pageY,
      },
    })

    e.stopPropagation()
    e.preventDefault()
  }

  // End drag state
  onMouseUp = e => {
    this.setState({ dragging: false })
    e.stopPropagation()
    e.preventDefault()
  }

  // Call resize handler if we're dragging
  onMouseMove = e => {
    if (!this.state.dragging) return

    let initDelta = {
      x: e.pageX - this.state.initPos.x,
      y: e.pageY - this.state.initPos.y,
    }

    let flowMask = {
      x: this.props.direction === 'row' ? 1 : 0,
      y: this.props.direction === 'column' ? 1 : 0,
    }

    let flowDelta = initDelta.x * flowMask.x + initDelta.y * flowMask.y

    // Resize the panels
    var resultDelta = this.handleResize(this.props.panelID, initDelta)

    // if the divider moved, reset the initPos
    if (resultDelta + flowDelta !== 0) {
      // Did we move the expected amount? (snapping will result in a larger delta)
      let expectedDelta = resultDelta === flowDelta

      this.setState({
        initPos: {
          // if we moved more than expected, add the difference to the Position
          x: e.pageX + (expectedDelta ? 0 : resultDelta * flowMask.x),
          y: e.pageY + (expectedDelta ? 0 : resultDelta * flowMask.y),
        },
      })
    }

    e.stopPropagation()
    e.preventDefault()
  }

  // Handle resizing
  handleResize = (i, delta) => {
    return this.props.handleResize(i, delta)
  }

  // Utility functions for handle size provided how much bleed
  // we want outside of the actual divider div
  getHandleWidth = () => {
    return this.props.dividerWidth + this.props.handleBleed * 2
  }
  getHandleOffset = () => {
    return this.props.dividerWidth / 2 - this.getHandleWidth() / 2
  }

  // Render component
  render() {
    var style = {
      divider: {
        width:
          this.props.direction === 'row' ? this.props.dividerWidth : 'auto',
        minWidth:
          this.props.direction === 'row' ? this.props.dividerWidth : 'auto',
        maxWidth:
          this.props.direction === 'row' ? this.props.dividerWidth : 'auto',
        height:
          this.props.direction === 'column' ? this.props.dividerWidth : 'auto',
        minHeight:
          this.props.direction === 'column' ? this.props.dividerWidth : 'auto',
        maxHeight:
          this.props.direction === 'column' ? this.props.dividerWidth : 'auto',
        flexGrow: 0,
        position: 'relative',
      },
      handle: {
        position: 'absolute',
        width: this.props.direction === 'row' ? this.getHandleWidth() : '100%',
        height:
          this.props.direction === 'column' ? this.getHandleWidth() : '100%',
        left: this.props.direction === 'row' ? this.getHandleOffset() : 0,
        top: this.props.direction === 'column' ? this.getHandleOffset() : 0,
        backgroundColor: this.props.showHandles
          ? 'rgba(0,128,255,0.25)'
          : 'auto',
        cursor: this.props.direction === 'row' ? 'col-resize' : 'row-resize',
        zIndex: 100,
      },
    }
    Object.assign(style.divider, { backgroundColor: this.props.borderColor })

    // Add custom class if dragging
    var className = 'divider'
    if (this.state.dragging) {
      className += ' dragging'
    }

    return (
      <div
        className={className}
        style={style.divider}
        onMouseDown={this.onMouseDown}
      >
        <div style={style.handle} />
      </div>
    )
  }
}

Divider.defaultProps = {
  dividerWidth: 1,
  handleBleed: 4,
}

export default PanelGroup
