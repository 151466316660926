// import styled from '@emotion/styled'

import { doFetchJiraCloudSites } from 'ducks/integrations/jiraCloud'
// import themeVars from 'ui_theme/site/globals/site.variables'
import { useActionEffect } from 'util/hooks'

import LinkedJiraCloudIssues from './LinkedJiraCloudIssues'
import LinkJiraCloudIssue from './LinkJiraCloudIssue'

export default function JiraCloudIndex({ className, doNavigatableSetState }) {
  useActionEffect(doFetchJiraCloudSites)
  return (
    <div className={className}>
      <LinkedJiraCloudIssues doNavigatableSetState={doNavigatableSetState} />
      <LinkJiraCloudIssue />
    </div>
  )
}
