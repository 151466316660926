import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { pluralize } from 'util/strings'

import { BoxHeadline, Flex, Text } from 'shared/ui'

import Actions from './Actions'
import ColorIcon from './ColorIcon'

const flexStyle = css`
  flex: 8;
`

const ListItem = ({ className, label, onEdit, onDelete }) => {
  const { labelingsCount: count = 0 } = label

  return (
    <div className={className} key={label.id}>
      <ColorIcon color={label.color} />
      <Flex css={flexStyle} className="grui pl-10 overflow-hidden">
        <Flex className="grui overflow-hidden flex-col">
          <BoxHeadline className="name" truncate>
            {label.name}
          </BoxHeadline>
          <Text size="small">
            Used in {count} {pluralize(count, 'conversation')}
          </Text>
        </Flex>
      </Flex>
      <Actions className="grui pt-4" onEdit={onEdit} onDelete={onDelete} />
    </div>
  )
}

const Styled = styled(ListItem)`
  display: flex;
  color: #353f44;
  font-size: 13px;
  padding: 12px;
  border-bottom: 1px solid var(--color-monochrome-medium);
  line-height: 1;

  .name {
    line-height: 16px !important; /* BoxHeadline is too tall */
  }

  .actions {
    display: none;
  }

  &:hover {
    .actions {
      display: flex;
    }
  }
`

export default Styled
