import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import NewLabel from '@groovehq/internal-design-system/lib/components/NewLabel/NewLabel'
import { divider } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCanSendDebugInfo } from 'selectors/app/selectAccountPreferences'
import {
  selectCurrentUser,
  selectCurrentUserCanAccessAdmin,
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsAdminOrOwnerOrAgent,
} from 'ducks/currentUser/selectors'
import { selectSettingsLandingPageAction } from 'selectors/menus'
import { selectShouldHideNewSettings } from 'ducks/flags/selectors'
import * as storage from 'util/storage'
import LegacyLink from 'components/LegacyLink'
import Avatar from 'components/Avatar'
import FeatureGate from 'components/FeatureGate'
import { LOGOUT_PAGE } from 'constants/pages'
import {
  SETTINGS_PROFILE_PAGE,
  SETTINGS_BILLING_PAGE,
} from 'subapps/settings/types'
import {
  profile as ProfileIcon,
  billing as BillingIcon,
  inviteTeammate as InviteTeammateIcon,
  logout as LogoutIcon,
  settings as SettingsIcon,
  santa as SantaIcon,
  teammates as TeammatesIcon,
} from 'assets/icons/groove/v2'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_AGENT_ADD,
  DRAWER_TYPE_SEND_DEBUG_INFO,
} from 'ducks/drawers/types'
import ItemWithIcon from './ItemWithIcon'
import { styles } from './styles'

const TO_SETTINGS_PROFILE_PAGE = {
  type: SETTINGS_PROFILE_PAGE,
}

const TO_SETTINGS_BILLING_PAGE = {
  type: SETTINGS_BILLING_PAGE,
}

const FullMenu = React.memo(({ showSantaItem, toggleSantaMode, santaMode }) => {
  const dispatch = useDispatch()
  const isDebug = useSelector(selectCanSendDebugInfo)
  const isSuperAdmin = useSelector(selectCurrentUserCanAccessAdmin)
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const isAdminOwnerAgent = useSelector(selectCurrentUserIsAdminOrOwnerOrAgent)
  const settingsLandingPageAction = useSelector(selectSettingsLandingPageAction)
  const shouldHideNewSettings = useSelector(selectShouldHideNewSettings)
  const hasBottomItems = isSuperAdmin || isDebug

  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_AGENT_ADD,
    lazyLoad: true,
  })

  const { openDrawer: openSendDebugInfoModal } = useDrawer({
    type: DRAWER_TYPE_SEND_DEBUG_INFO,
  })

  const handleAddTeammates = useCallback(
    () => {
      openAddDrawer('new', {
        additionalProps: { title: `Add ${app.t('Agent')}` },
      })
    },
    [openAddDrawer]
  )

  const handleSendDebugInfo = useCallback(
    () => {
      openSendDebugInfoModal()
    },
    [openSendDebugInfoModal]
  )

  const handleLogout = useCallback(
    () => {
      dispatch({ type: LOGOUT_PAGE })
    },
    [dispatch]
  )

  return (
    <Menu as="div" css={styles.menuStyle}>
      <Link className="grui" to={TO_SETTINGS_PROFILE_PAGE}>
        <Menu.Item itemKey="my-profile" className="grui">
          <ItemWithIcon icon={<ProfileIcon />}>View profile</ItemWithIcon>
        </Menu.Item>
      </Link>
      {isAdminOwnerAgent && (
        <Link className="grui" to={settingsLandingPageAction} shouldDispatch>
          <Menu.Item itemKey="settings" className="grui">
            <ItemWithIcon icon={<SettingsIcon />}>Settings</ItemWithIcon>
            {!shouldHideNewSettings && (
              <NewLabel
                className="grui ml-5 mt-1 align-middle"
                isInfiniteAnimation
                size="small"
              />
            )}
          </Menu.Item>
        </Link>
      )}
      {isManager && (
        <Link className="grui" to={TO_SETTINGS_BILLING_PAGE}>
          <Menu.Item itemKey="billing" className="grui">
            <ItemWithIcon icon={<BillingIcon />}>Billing</ItemWithIcon>
          </Menu.Item>
        </Link>
      )}
      {showSantaItem && (
        <Menu.Item itemKey="santa" className="grui" onClick={toggleSantaMode}>
          <ItemWithIcon icon={<SantaIcon className="sm-icon" />}>
            {santaMode ? 'Hide Santa hat' : 'Show Santa hat'}
          </ItemWithIcon>
        </Menu.Item>
      )}
      {isManager && (
        <Menu.Item
          itemKey="invite-teammates"
          className="grui"
          onClick={handleAddTeammates}
          css={styles.highlightMenuItem}
        >
          <ItemWithIcon icon={<InviteTeammateIcon className="sm-icon" />}>
            {`Invite ${app.t('team')} members`}
          </ItemWithIcon>
        </Menu.Item>
      )}
      <FeatureGate flag="affiliates-link">
        <Menu.Item
          as="a"
          itemKey="affiliate-referral"
          className="grui"
          href="https://affiliates.groovehq.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ItemWithIcon icon={<TeammatesIcon className="sm-icon" />}>
            Refer a friend
          </ItemWithIcon>
        </Menu.Item>
      </FeatureGate>
      <div css={divider.styles.primary} role="separator" />
      <Menu.Item
        itemKey="logout"
        as="button"
        type="button"
        onClick={handleLogout}
      >
        <ItemWithIcon icon={<LogoutIcon className="sm-icon" />}>
          Log out
        </ItemWithIcon>
      </Menu.Item>
      {hasBottomItems && <div css={divider.styles.primary} role="separator" />}
      {isSuperAdmin && (
        <>
          <LegacyLink
            path="/admin/accounts"
            className="grui"
            async={false}
            skipModal
          >
            <Menu.Item itemKey="go-to-admin" className="grui">
              Go to admin
            </Menu.Item>
          </LegacyLink>
          <LegacyLink path="/sidekiq" className="grui" async={false} skipModal>
            <Menu.Item itemKey="go-to-sidekiq" className="grui">
              Sidekiq Dashboard
            </Menu.Item>
          </LegacyLink>
        </>
      )}
      {isDebug && (
        <Menu.Item
          className="grui"
          itemKey="sendDebugInfo"
          as="button"
          type="button"
          onClick={handleSendDebugInfo}
        >
          Send Debug Info
        </Menu.Item>
      )}
    </Menu>
  )
})

const SettingsMenu = React.memo(({ santa = false }) => {
  const currentUser = useSelector(selectCurrentUser)

  const [hideSanta, setHideSanta] = useState(!!storage.get('hide-santa'))

  const toggleSanta = useCallback(
    () => {
      setHideSanta(v => {
        const newValue = !v
        storage.set('hide-santa', newValue)
        return newValue
      })
    },
    [setHideSanta]
  )

  return (
    <Dropdown
      overlay={
        <FullMenu
          toggleSantaMode={toggleSanta}
          santaMode={!hideSanta}
          showSantaItem={santa}
        />
      }
      position="bottom-end"
      strategy="fixed"
      offset={8}
      autoHeight
      menuItemIconHidden
    >
      <div css={styles.avatarContainer} className="grui ml-7 flex items-center">
        <Avatar
          size={32}
          actor={currentUser}
          css={santa && !hideSanta && styles.santa}
        />
      </div>
    </Dropdown>
  )
})

export default SettingsMenu
