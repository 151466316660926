import { useCallback, useMemo, useState } from 'react'

export function useChangeCompanyMenus() {
  const [openMenu, setOpenMenu] = useState(null)
  const onClose = useCallback(() => setOpenMenu(null), [setOpenMenu])
  const onOpenMore = useCallback(() => setOpenMenu('more'), [setOpenMenu])
  const onOpenChange = useCallback(() => setOpenMenu('change-company'), [
    setOpenMenu,
  ])
  const onOpenCreate = useCallback(() => setOpenMenu('create-company'), [
    setOpenMenu,
  ])

  return useMemo(
    () => ({
      onClose,
      onOpenMore,
      onOpenChange,
      onOpenCreate,
      openMenu,
    }),
    [onClose, onOpenCreate, onOpenMore, onOpenChange, openMenu]
  )
}
