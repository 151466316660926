import React from 'react'
import { IconButton } from 'shared/ui'

const DeleteButton = React.memo(({ deleteConversation }) => {
  return (
    <div className="delete">
      <IconButton
        name="delete"
        size="medium"
        className="menu-button"
        onClick={deleteConversation}
        tooltip={'Move to Trash'}
        svg
      />
    </div>
  )
})

export default DeleteButton
