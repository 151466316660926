// Props Proxy HOC that initializes the modal.drilledDownGroup, which all of our
// assignment modals use to drive their filtered assignee lists. It also ensures
// the asssigned group and associated agents are visible when you open the menu.
//
// We expect the caller will pass the currently assigned group (assignment.groupId)
// as the `drilledDownGroup`.
//
// Its not great, but all our selectors rely on this state being initialized.
//
import React from 'react'
import { connect } from 'react-redux'
import { doHideModal, doDrillDownOnTeam } from 'actions/modals'
import { getDisplayName } from 'util/hoc'
import { propFunc } from 'util/functions'

export default function withDrilledDownGroup(WrappedDropdown) {
  class WithDrilledDownGroup extends React.Component {
    state = { open: false }

    componentDidMount() {
      const { open, drilledDownGroupId, initializeGroup } = this.props
      if (open) initializeGroup(drilledDownGroupId)
    }

    handleOpen = (onOpen, drilledDownGroupId, initializeGroup) => {
      if (drilledDownGroupId) initializeGroup(drilledDownGroupId)
      if (onOpen) onOpen()
    }

    handleClose = (onClose, clearGroup) => {
      clearGroup()
      if (onClose) onClose()
    }

    render() {
      const {
        clearGroup,
        drilledDownGroupId,
        initializeGroup,
        onClose,
        onOpen,
        ...rest
      } = this.props

      return (
        <WrappedDropdown
          {...rest}
          // order is important
          onOpen={propFunc(
            this.handleOpen,
            onOpen,
            drilledDownGroupId,
            initializeGroup
          )}
          onClose={propFunc(this.handleClose, onClose, clearGroup)}
        />
      )
    }
  }

  WithDrilledDownGroup.displayName = `WithDrilledDownGroup(${getDisplayName(
    WrappedDropdown
  )})`

  const perform = {
    initializeGroup: doDrillDownOnTeam,
    clearGroup: doHideModal,
  }

  return connect(null, perform)(WithDrilledDownGroup)
}
