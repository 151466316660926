import { css } from '@emotion/react'

const base = css`
  width: 270px;
`

const search = css`
  width: 100%;
`

export const styles = { base, search }
