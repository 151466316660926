import Link from 'redux-first-router-link'

export default function ActionSubject({
  action: {
    change: {
      action: {
        data: {
          card: { id: cardId, name, shortLink } = {},
          old: { name: oldName } = {},
        } = {},
        display: {
          entities: {
            board: { text: boardName, shortLink: boardShortLink } = {},
            list: { text: listName } = {},
          } = {},
          translationKey,
        } = {},
      },
    },
  },
}) {
  switch (translationKey) {
    case 'action_archived_list':
    case 'action_sent_list_to_board':
      return <em>{listName}</em>
    case 'action_closed_board':
    case 'action_reopened_board':
      return (
        <Link target="_blank" to={`https://trello.com/b/${boardShortLink}`}>
          {boardName}
        </Link>
      )
    case 'action_renamed_card':
      return (
        <Link
          target="_blank"
          to={`https://trello.com/c/${shortLink || cardId}`}
        >
          {oldName}
        </Link>
      )
    default:
      return (
        <Link
          target="_blank"
          to={`https://trello.com/c/${shortLink || cardId}`}
        >
          {name}
        </Link>
      )
  }
}
