import styled from '@emotion/styled'
import { Flex } from 'shared/ui'
import Row from './Row'

const Indent = styled(({ className, children }) => {
  return <Flex className={className}>{children}</Flex>
})`
  flex-grow: 1;
`

const IndentedRow = ({ className, children }) => {
  return (
    <Row className={className}>
      <Indent>{children}</Indent>
    </Row>
  )
}

export default IndentedRow
