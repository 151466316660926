export default function NavigatableIntegrationShowMore({
  text,
  onClick,
  isLoading = false,
  loadingText,
}) {
  return (
    <div className="NavigatableIntegrationWidgetCard-ShowMore">
      {isLoading && loadingText}

      {!isLoading && (
        <span onClick={onClick} rel="noopener noreferrer">
          {text}
        </span>
      )}
    </div>
  )
}
