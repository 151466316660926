import React from 'react'
import { bool, string } from 'prop-types'
import { useFormState } from 'react-hook-form'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { shopifyMoneyString, currencyCodeToSymbol } from 'util/currency'
import {
  FORM_KEY_REFUND_AMOUNT,
  FORM_KEY_SEND_NOTIFICATION,
} from '../../constants'

const RefundAmount = ({
  className,
  control,
  register,
  orderCurrencyCode,
  isCalculating,
  maximumRefundableSet,
  formattedGateway,
  accountNumber,
}) => {
  const { errors } = useFormState({
    control,
    name: FORM_KEY_REFUND_AMOUNT,
  })

  const currencyCode =
    maximumRefundableSet?.shopMoney?.currencyCode || orderCurrencyCode
  const currencySymbol = currencyCodeToSymbol(currencyCode)

  return (
    <div className={className}>
      <div className="grui mb-11">
        <Field
          label="Refund amount"
          placeholder="0.00"
          tagPosition="left"
          tagContent={currencySymbol}
          className="grui mb-3"
          disabled={isCalculating}
          /* changing refund amount should not trigger recalculation */
          {...register(FORM_KEY_REFUND_AMOUNT, {
            valueAsNumber: true,
          })}
          validateStatus={
            errors?.[FORM_KEY_REFUND_AMOUNT]?.message ? 'error' : undefined
          }
          help={errors?.[FORM_KEY_REFUND_AMOUNT]?.message}
        />
        <p css={[paragraph.styles.preflight, text.styles.textMediumDark]}>
          {formattedGateway && (
            <>
              <span css={text.styles.fontMedium}>
                {formattedGateway}&nbsp;
                {!!accountNumber && `(${accountNumber})`}
              </span>
              <br />
            </>
          )}
          <span css={mainStyles.moneyString}>
            {shopifyMoneyString({
              currencyCode,
              amount: maximumRefundableSet?.shopMoney?.amount,
            })}
          </span>{' '}
          available for refund
        </p>
      </div>
      <Checkbox id="sendNotification" {...register(FORM_KEY_SEND_NOTIFICATION)}>
        Send a notification to the customer
      </Checkbox>
    </div>
  )
}

RefundAmount.propTypes = {
  className: string,
  orderCurrencyCode: string,
  isCalculating: bool,
}

RefundAmount.defaultProps = {
  className: undefined,
  orderCurrencyCode: '',
  isCalculating: false,
}

export default RefundAmount
