import React, { useCallback } from 'react'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { dataLayer } from 'util/tracking'
import { capture } from 'ducks/tracking/actions'
import { styles } from './styles'

const Activation = ({
  img,
  imgWidth,
  imgHeight,
  name,
  title,
  subtitle,
  note,
  btnLabel,
  drawerType,
  to,
  type,
  footer: Footer,
  skippable,
  onSkip,
  completed,
}) => {
  const { openDrawer } = useDrawer({
    type: drawerType,
  })

  const createNewEntity = useCallback(
    () => {
      if (drawerType) {
        openDrawer('new')
      }
      dataLayer({ event: 'onboarding_create', item: type })
      capture(`onboarding create ${type}`)
    },
    [openDrawer, type, drawerType]
  )
  const handleSkip = useCallback(
    () => {
      onSkip(type)
      dataLayer({ event: 'onboarding_skip', item: type })
      capture(`onboarding skip ${type}`)
    },
    [onSkip, type]
  )

  return (
    <div
      className={cn(
        'grui flex flex-col items-center text-center flex-1 px-10 pt-16 pb-10',
        type
      )}
      css={styles.activationContainer}
    >
      {img && (
        <div
          css={styles.imgWrapper}
          className="grui onboarding-activation-img-wrapper"
        >
          <img src={img} alt={name} width={imgWidth} height={imgHeight} />
        </div>
      )}
      <div className="grui mb-15">
        <h2 className="grui" css={heading.styles.h2}>
          {title}
        </h2>
        <div className="grui mt-5">{subtitle}</div>
      </div>
      {note && (
        <div css={[text.styles.textXs, text.styles.fontSemibold]}>{note}</div>
      )}
      {Footer ? (
        <Footer onClickCreateBtn={createNewEntity} completed={completed} />
      ) : (
        <Button
          type="info"
          css={styles.actionBtn}
          className="grui px-14 mt-5"
          onClick={createNewEntity}
          as={to && Link}
          to={to && to}
        >
          {btnLabel}
        </Button>
      )}
      {skippable && (
        <button
          className="grui mt-10"
          css={button.styles.link}
          onClick={handleSkip}
        >
          Or skip this step
        </button>
      )}
    </div>
  )
}

export default Activation
