import React, { useMemo } from 'react'

import { extractSpecificFields } from 'ducks/integrations/shared/settings'
import { Label } from 'shared/ui'

import LinkedResourceActions from '../LinkedResourceActions'
import Properties from './Properties'
import Section from '../Section'

const contactPrioritizedFields = ['firstname', 'lastname', 'email']

export default function Contact({ contactObject, settings, ownersById }) {
  const specificFields = extractSpecificFields(
    settings,
    /showSidebarField.Contact\./
  )
  const { vid, 'portal-id': portalId } = contactObject?.data || {}
  const profileUrl =
    vid && portalId
      ? `https://app.hubspot.com/contacts/${portalId}/contact/${vid}`
      : null

  const actions = useMemo(
    () => [
      {
        blankTarget: true,
        text: ['View contact in HubSpot'],
        url: profileUrl,
      },
    ],
    [profileUrl]
  )
  if (!contactObject) return null
  if (!contactObject.data) return null
  return (
    <Section>
      {contactObject &&
        contactObject.state === 'fetched' && (
          <React.Fragment>
            <Label size="small">Contact</Label>
            <Properties
              properties={contactObject.properties}
              prioritizedFields={contactPrioritizedFields}
              specificFields={specificFields}
              ownersById={ownersById}
            />
            <LinkedResourceActions actions={actions} />
          </React.Fragment>
        )}
    </Section>
  )
}
