import React, { useEffect } from 'react'
import { Flex, Label } from 'shared/ui'

import LinkExistingGitHubIssueModal from './LinkExistingGitHubIssueModal'
import LinkNewGitHubIssueModal from './LinkNewGitHubIssueModal'

export default function LinkGitHubIssue({ doFetchGitHubInstalls }) {
  useEffect(() => {
    doFetchGitHubInstalls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="LinkGitHubIssue">
      <Label size="small">Link Conversation To Issue</Label>
      <Flex>
        <LinkExistingGitHubIssueModal />
        <LinkNewGitHubIssueModal />
      </Flex>
    </div>
  )
}
