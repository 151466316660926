const ACTION_TRANSLATIONS = {
  assigned: 'assigneesChanged',
  closed: 'issueClosed',
  edited: 'issueEdited',
  deleted: 'issueDeleted',
  demilestoned: 'issueDemilestoned',
  labeled: 'labelsChanged',
  locked: 'issueLocked',
  milestoned: 'issueMilestoned',
  pinned: 'issuePinned',
  reopened: 'issueReopened',
  transferred: 'issueTransferred',
  unassigned: 'assigneesChanged',
  unlabeled: 'labelsChanged',
  unlocked: 'issueUnlocked',
  unpinned: 'issueUnpinned',
}

export default function shouldShowAction(
  {
    change: {
      action: { action: actionKey, event },
    },
  },
  settings
) {
  const setting = `showAction.${ACTION_TRANSLATIONS[actionKey]}`
  const showCommentAction =
    event === 'issue_comment' &&
    actionKey === 'created' &&
    settings &&
    settings['showAction.issueCommentAdded']
  const showIssueAction =
    Object.keys(ACTION_TRANSLATIONS).includes(actionKey) &&
    settings &&
    settings[setting]
  return showIssueAction || showCommentAction
}
