import React from 'react'
import { BoxHeadline, Flex } from 'shared/ui'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'

export default function Name({ id, name, searchTerm }) {
  return (
    <Flex className="grui overflow-hidden">
      <BoxHeadline truncate>
        {id === -1 && '+ Create tag “'}
        <Highlighter
          highlightClassName={styles.bold}
          searchWords={[searchTerm || '']}
          autoEscape
          textToHighlight={name}
        />
      </BoxHeadline>
    </Flex>
  )
}
