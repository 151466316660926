import React from 'react'
import cn from 'classnames'
import { Pin } from '@groovehq/internal-design-system/lib/assets/icons'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'

import { isEmpty } from 'util/arrays'

import Mailbox from './Mailbox'
import Starred from './Starred'

import { styles } from './styles'
import BookDemoCard from './BookDemoCard'
import TeammatesCard from './TeammatesCard'
import { useLeftNav } from './useLeftNav'
import { HeaderWithComposeButton } from './HeaderWithComposeButton'
import OnboardingWorkflow from '../OnboardingWorkflow'

const Leftnav = () => {
  const {
    canSeePinnedSearches,
    mailboxId,
    mailboxes,
    prefersUnifiedInbox,
    starredSearches,
    showBookDemoCard,
    showTeammatesCard,
    showOnboardingWorkflow,
    openSearchPage,
    startSearchTimer,
    toggleTeamSection,
    toggleTagsSection,
    isSectionExpanded,
    starredOpen,
    onStarredClick,
    onMailboxClick,
    hasEverToggledSection,
  } = useLeftNav()

  if (!mailboxes) return null

  const shouldShowStarredSearches =
    canSeePinnedSearches && starredSearches && !isEmpty(starredSearches)
  const starredSearchesActive = shouldShowStarredSearches && starredOpen
  const wantsCompactMode = prefersUnifiedInbox && !shouldShowStarredSearches

  const showFooter =
    !showOnboardingWorkflow && (showBookDemoCard || showTeammatesCard)
  const footer = showFooter ? (
    <>
      {showBookDemoCard && <BookDemoCard />}
      {!showBookDemoCard && showTeammatesCard && <TeammatesCard />}
    </>
  ) : null

  return (
    <Menu
      header={<HeaderWithComposeButton />}
      tight
      animate={false}
      footer={footer}
      className={cn('grui relative', footer && 'with-footer')}
      css={styles.menu}
      dataTestId="inbox-left-nav"
    >
      <>
        {shouldShowStarredSearches && (
          <Menu.Item
            icon={<Pin className="grui mr-3" />}
            css={styles.pinnedItem}
            className="grui pl-4"
            onClick={onStarredClick}
            value="menu-starred"
            subMenu={
              starredOpen && <Starred starredSearches={starredSearches} />
            }
          >
            Pinned
          </Menu.Item>
        )}
        {mailboxes.map(mailbox => {
          let isCurrent = false
          if (!starredSearchesActive) {
            isCurrent = prefersUnifiedInbox || mailbox.id === mailboxId
          }
          return (
            <Mailbox
              key={mailbox.id || mailbox.name} // mailbox.id can be null (for 'All inboxes')
              onClick={onMailboxClick}
              mailboxId={mailbox.id}
              isCurrent={isCurrent}
              wantsCompactMode={wantsCompactMode}
              isTeamSectionOpen={isSectionExpanded('Team')}
              isTagsSectionOpen={isSectionExpanded('Tags')}
              toggleTeamSection={toggleTeamSection}
              toggleTagsSection={toggleTagsSection}
              doOpenSearchPage={openSearchPage}
              doStartSearchTimer={startSearchTimer}
              hasEverToggledTeamSection={hasEverToggledSection('Team')}
            />
          )
        })}
        <OnboardingWorkflow.ProgressIndicator className="grui mt-6" />
      </>
    </Menu>
  )
}

export default Leftnav
