import { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Form } from 'util/ui'
import { useDispatch, useSelector } from 'react-redux'
import { selectAvailableAccounts, selectAuthProvider } from 'selectors/app'
import { redirect } from 'redux-first-router'
import { LOGIN_PAGE } from 'constants/pages'
import OAuthButton from 'components/OAuthButton'
import { TextDivider } from 'shared/ui'
import debug from 'util/debug'
import {
  doGoogleSignup,
  doOfficeSignup,
} from 'subapps/onboarding/v6/signupPage/actions'
import useIsMounted from 'util/hooks/useIsMounted'
import LoginLayout from '../LoginLayout'
import LoginMultipleAccountsButton from './Button'
import { styles } from './styles'

const NarrowForm = styled(Form)`
  overflow-wrap: break-word;
  max-height: 60%;
  overflow: auto;
`

const googleTracking = {
  category: 'oAuth-onboarding',
  action: 'oAuth-account-creation',
  label: 'oAuth-creation-Gmail',
}

const LoginMultipleAccounts = () => {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const accounts = useSelector(selectAvailableAccounts)
  const provider = useSelector(selectAuthProvider)
  const isMounted = useIsMounted()

  const [selectedSubdomain, setSelectedSubdomain] = useState(null)

  useEffect(
    () => {
      if (!isMounted()) return
      if (accounts.length === 0) {
        dispatch(redirect({ type: LOGIN_PAGE }))
      }
    },
    [accounts, dispatch, isMounted]
  )

  const handleSignupGoogle = useCallback(
    async () => {
      setSaving(true)
      try {
        await dispatch(doGoogleSignup({ ignore_existing: true }))
      } catch {
        debug('Signup via google failed')
      } finally {
        if (isMounted()) setSaving(true)
      }
    },
    [dispatch, isMounted]
  )

  const handleSignupOffice = useCallback(
    async () => {
      setSaving(true)
      try {
        await dispatch(doOfficeSignup({ ignore_existing: true }))
      } catch {
        debug('Signup via office failed')
      } finally {
        if (isMounted()) setSaving(true)
      }
    },
    [dispatch, isMounted]
  )

  return (
    <LoginLayout
      showSignupLink
      tagline="Welcome back, which account would you like to log into?"
    >
      <NarrowForm size="large">
        {accounts.map(({ subdomain, token, company }) => {
          return (
            <LoginMultipleAccountsButton
              key={subdomain}
              subdomain={subdomain}
              company={company}
              token={token}
              selectedSubdomain={selectedSubdomain}
              setSelectedSubdomain={setSelectedSubdomain}
            />
          )
        })}
        <TextDivider css={styles.divider}>Or</TextDivider>
        {provider === 'google_oauth2' && (
          <OAuthButton
            id="signup-google"
            key="google"
            onClick={handleSignupGoogle}
            iconName="googleLogo"
            text="Create new account"
            savingText="Signing up…"
            saving={saving}
            tracking={googleTracking}
            dark
          />
        )}
        {provider === 'microsoft_office365' && (
          <OAuthButton
            id="login-office-365"
            key="office"
            onClick={handleSignupOffice}
            iconName="officeLogo"
            text="Create new account"
            savingText="Signing in…"
            saving={saving}
            className="lean-round"
            dark
          />
        )}
      </NarrowForm>
    </LoginLayout>
  )
}

export default LoginMultipleAccounts
