import React from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { selectIsChangesetFetched } from 'selectors/currentChangesets'
import { doFetchChangesetTicketActions } from 'actions/tickets/doFetchChangesetTicketActions'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import metrics from 'util/metrics'
import { strftime, isThisYear } from 'util/date'

import { getAvatarComponent, getAuthor } from '../ExpandedMessage/util'
import BodySearchMarker from '../BodySearchMarker'

export class CollapsedMessageView extends React.Component {
  constructor() {
    super()
    this.state = {
      bodyNode: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { message, isFetched } = this.props
    const { message: nextMessage, isFetched: nextIsFetched } = nextProps

    const actionsWereCount = message.subsequentActions
      ? message.subsequentActions.length
      : 0
    const actionsAreCount = nextMessage.subsequentActions
      ? nextMessage.subsequentActions.length
      : 0

    const should =
      message.id !== nextMessage.id ||
      message.action.change.editedAt !== nextMessage.action.change.editedAt ||
      isFetched !== nextIsFetched ||
      actionsAreCount !== actionsWereCount

    const shouldUpdateDueToStateChange =
      this.state.bodyNode !== nextState.bodyNode

    return should || shouldUpdateDueToStateChange
  }

  onBodyNode = node => {
    this.setState({
      bodyNode: !node || !node.querySelectorAll ? null : node,
    })
  }

  getEditedDateTime() {
    const { message } = this.props
    const change = message.action.change
    const thisYear = isThisYear(change.editedAt)

    if (thisYear) {
      return `Last edited ${strftime('%d %b, %l:%M %p', change.editedAt)}`
    }

    return `Last edited ${strftime('%d %b %Y, %l:%M %p', change.editedAt)}`
  }

  expandChangeset = () => {
    const {
      message,
      ticketId,
      doFetchChangesetTicketActions: fetch,
      isFetched,
    } = this.props
    const change = message.action.change

    if (!message.loaded && !isFetched) {
      metrics.startTimer(`changeset-load-${message.id}`, {
        metric: 'changeset-load',
        logname: 'main',
        changesetId: message.id,
        hot: false,
        ticketId,
      })

      fetch(ticketId, message.id, change.subchangeset_ids)
    } else {
      metrics.startTimer(`changeset-load-${message.id}`, {
        metric: 'changeset-load',
        logname: 'main',
        changesetId: message.id,
        hot: true,
        ticketId,
      })
    }

    this.props.expand(message.id)
  }

  render() {
    const { message, currentUserId, isFetched } = this.props
    const action = message.action
    const change = action.change
    if (change.snippet) change.body = change.snippet
    const snippet = message.snippet
    const isBodyEmpty = change.isBodyEmpty

    const isNote = message.isNote
    const isOutsideCommunication = message.isOutsideCommunication

    const datetime = action.datetime

    return (
      <div
        id={`changeset-${message.id}`}
        className={cn('changeset collapsed', {
          forward: isOutsideCommunication,
        })}
      >
        <div className="action-container">
          <div
            className={cn('action', {
              message: !isNote,
              note: isNote,
              forward: isOutsideCommunication,
            })}
            onClick={this.expandChangeset}
          >
            {getAvatarComponent({ action })}
            <div className="contents">
              <div className="header">
                {getAuthor(action, currentUserId)}
                <div className="right-side">
                  <div className="icons">
                    {message.hasAttachments && (
                      <div className="Icon Icon-attachment" />
                    )}
                  </div>
                  {change.editedAt && (
                    <Tooltip
                      title={this.getEditedDateTime()}
                      position="top"
                      strategy="fixed"
                    >
                      <span className="edited-datetime">Edited</span>
                    </Tooltip>
                  )}
                  <div className="datetime">{datetime}</div>
                </div>
              </div>
              <div className="snippet">
                {isFetched && (
                  <div className="placeholder">
                    <div className="placeholder-content" />
                    <div className="placeholder-content" />
                    <div className="placeholder-content" />
                  </div>
                )}
                {!isFetched &&
                  !isBodyEmpty &&
                  snippet && (
                    <>
                      <SanitizedHTML
                        as="span"
                        html={snippet.replace(/&nbsp;/g, ' ')}
                        onBodyNode={this.onBodyNode}
                      />
                      <BodySearchMarker node={this.state.bodyNode} />
                    </>
                  )}
                {!isFetched &&
                  isBodyEmpty && (
                    <div className="empty-body">No content in this message</div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const selectFactory = (initialState, initialProps) => {
  const changeset = initialProps.message
  return state => {
    return {
      currentUserId: selectCurrentUser(state).id,
      isFetched: selectIsChangesetFetched(state, changeset.id),
    }
  }
}

const perform = {
  doFetchChangesetTicketActions,
}
const CollapsedMessage = connect(selectFactory, perform)(CollapsedMessageView)

export default CollapsedMessage
