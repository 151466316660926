import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const list = css`
  display: flex;
  justify-content: flex-start;
  margin-top: ${rem(6)};
`

const reaction = theme => css`
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: ${rem(4)};
  padding: 0 ${rem(6)};
  font-size: ${theme.fontSize.small};
  background-color: ${theme.color.monochrome.white};
  border: 1px solid ${theme.color.monochrome.transparent};
  border-radius: ${rem(12)};
  cursor: pointer;
  transition: background-color 175ms ease-in-out, border 175ms ease-in-out;

  &:hover {
    border: 1px solid ${theme.color.primary.brand};
  }
`

const ownReaction = theme => css`
  background-color: ${theme.color.secondary.brand};
`

const button = theme => css`
  margin: 0;
  font-size: ${theme.fontSize.x2large};
  color: ${theme.color.monochrome.mediumDark};
`

export const styles = {
  list,
  reaction,
  ownReaction,
  button,
}
