import React, { Fragment } from 'react'
import Link from 'redux-first-router-link'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { FORGOT_PASSWORD_PAGE, FORGOT_SUBDOMAIN_PAGE } from 'constants/pages'
import { Form } from 'util/ui'
import { propFunc } from 'util/functions'
import { slugify } from 'util/url'
import ValidatedField from 'shared/components/ui/ValidatedField'
import FormError from 'shared/components/ui/Form/Error'
import ParsedInput from 'shared/components/ui/ParsedInput'
import { TextDivider } from 'shared/ui'
import OAuthButtonV2 from 'components/OAuthButtonV2'
import { signupTo } from 'components/LoginLayout'
import {
  googleLogoAlt as GoogleLogo,
  microsoftLogo as MicrosoftLogo,
} from 'assets/icons/groove/v2'
import { styles } from './styles'

const subdomainLabelOptions = { basic: true, content: '.groovehq.com' }

class LoginForm extends React.PureComponent {
  manualSubdomain = false

  handleChange = (name, _, { value, checked }) => {
    const { onChange, onValidate } = this.props
    onChange(name, checked === undefined ? value : checked)
    onValidate(name, checked === undefined ? value : checked)
  }

  handleSubdomainChange = (_, { value }) => {
    const { onChange, onValidate } = this.props
    this.manualSubdomain = true
    onChange('subdomainInput', value)
    onValidate('subdomainInput', value)
  }

  render() {
    const {
      formFields,
      formFieldsErrors,
      submitForm,
      canSave,
      saving,
      loginError,
      isAccountSubdomain,
      doGoogleLogin,
      doOfficeLogin,
    } = this.props
    return (
      <Form size="large" data-test-id="login">
        <h1 className="grui text-center my-0" css={styles.title}>
          Welcome back 👋
        </h1>
        <OAuthButtonV2
          id="login-google"
          key="google"
          onClick={doGoogleLogin}
          customIcon={<GoogleLogo />}
          text="Sign in with Google"
          savingText="Signing in…"
          saving={saving}
          className="mt-14"
          css={styles.googleLoginBtn}
        />
        <OAuthButtonV2
          id="login-office-365"
          key="office"
          onClick={doOfficeLogin}
          customIcon={<MicrosoftLogo />}
          text="Sign in with Microsoft"
          savingText="Signing in…"
          saving={saving}
          className="mt-10 mb-14"
        />
        <div className="grui px-11 mb-14" css={styles.textDividerWrapper}>
          <TextDivider>OR</TextDivider>
        </div>
        {!isAccountSubdomain && (
          <Form.Field>
            <ValidatedField
              placeholder="groove-url"
              label={subdomainLabelOptions}
              labelPosition="right"
              value={formFields.subdomainInput}
              errorMessage={formFieldsErrors.subdomainInput}
              onChange={this.handleSubdomainChange}
              validatedFieldComponent={ParsedInput}
              parse={slugify}
              autoComplete="organization"
              name="subdomain"
            />
          </Form.Field>
        )}
        <Form.Field>
          <ValidatedField
            placeholder="Email Address"
            type="email"
            value={formFields.emailInput}
            errorMessage={formFieldsErrors.emailInput}
            onChange={propFunc(this.handleChange, 'emailInput')}
            autoComplete="email"
            name="email"
          />
        </Form.Field>
        <Form.Field>
          <ValidatedField
            placeholder="Password"
            type="password"
            value={formFields.passwordInput}
            errorMessage={formFieldsErrors.passwordInput}
            onChange={propFunc(this.handleChange, 'passwordInput')}
            autoComplete="current-password"
            name="password"
          />
        </Form.Field>
        {loginError && <FormError error={loginError} />}
        <Button
          id="login-email"
          name="loginButton"
          type="info"
          size="xBig"
          css={styles.btnLogin}
          onClick={submitForm}
          disabled={!canSave}
          htmlType="submit"
        >
          {!saving ? 'Sign in' : 'Signing in...'}
        </Button>

        <Form.Field className="links">
          <Link to={{ type: FORGOT_PASSWORD_PAGE }}>Forgot password</Link>
          {!isAccountSubdomain && (
            <Fragment>
              {' or '}
              <Link to={{ type: FORGOT_SUBDOMAIN_PAGE }}>subdomain</Link>
            </Fragment>
          )}
        </Form.Field>
        <div css={styles.line} className="grui mx-11 mt-14" />
        <div className="grui -ml-12 -mr-12">
          <Form.Field className="links">
            Looking for a better way to support your customers?&nbsp;
            <a href={signupTo}>Try Groove Free</a>
          </Form.Field>
        </div>
      </Form>
    )
  }
}

export default LoginForm
