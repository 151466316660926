import React, { useCallback, useMemo } from 'react'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_FOLDERS_UPDATE,
  DRAWER_TYPE_FOLDERS_DELETE,
  DRAWER_TYPE_FOLDERS_OVERVIEW,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { buildId } from 'util/globalId'

const MoreFolderOptions = ({ id, isDefault }) => {
  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_UPDATE
  )
  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_DELETE
  )
  const { openDrawer: openOverviewDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_OVERVIEW
  )
  const folderId = useMemo(() => buildId('Folder', id), [id])

  const handleEdit = useCallback(
    e => {
      e.stopPropagation()
      openCreateEditDrawer(folderId)
    },
    [openCreateEditDrawer, folderId]
  )

  const handleDelete = useCallback(
    e => {
      e.stopPropagation()
      openDeleteDrawer(folderId, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, folderId]
  )

  const handleManageAll = useCallback(
    e => {
      e.stopPropagation()
      openOverviewDrawer(folderId)
    },
    [openOverviewDrawer, folderId]
  )

  return (
    <Dropdown.Menu>
      <Dropdown.Menu.Item onClick={handleEdit} itemKey="edit-folder">
        Edit {app.t('folder')}
      </Dropdown.Menu.Item>
      <Tooltip
        title={`This is a default ${app.t('folder')} and cannot be deleted`}
        position="right"
        disabled={!isDefault}
      >
        <div>
          <Dropdown.Menu.Item
            onClick={handleDelete}
            itemKey="remove-folder"
            disabled={isDefault}
            isWarning
          >
            Remove {app.t('folder')}
          </Dropdown.Menu.Item>
        </div>
      </Tooltip>
      {divider}
      <Dropdown.Menu.Item onClick={handleManageAll} itemKey="edit-folders">
        Manage {app.t('folders')}
      </Dropdown.Menu.Item>
    </Dropdown.Menu>
  )
}

export default MoreFolderOptions
