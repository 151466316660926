import React from 'react'
import { useSelector } from 'react-redux'

import CollapsableContent from 'components/CollapsableContent'

import {
  selectTrelloHiddenFields,
  selectCurrentTicketLinkedCardsById,
  selectTrelloVisibleFields,
} from 'ducks/integrations/trello'

import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import LinkedTrelloCardFields from './LinkedTrelloCardFields'
import { useTrelloCardActions } from './hooks'

export default function LinkedTrelloCard({
  className,
  doNavigatableClearState,
  navigatableState: cardId,
}) {
  const card = useSelector(selectCurrentTicketLinkedCardsById)[cardId]
  const hiddenFields = useSelector(selectTrelloHiddenFields)
  const visibleFields = useSelector(selectTrelloVisibleFields)
  const actions = useTrelloCardActions(card, doNavigatableClearState)
  const { id, title } = card || {}

  if (!card) return null
  return (
    <NavigatableIntegrationWidgetCard.Detail
      className={className}
      title={title}
      onBack={doNavigatableClearState}
    >
      {visibleFields &&
        visibleFields.length > 0 && (
          <LinkedTrelloCardFields fields={visibleFields} card={card} />
        )}
      <LinkedResourceActions id={id} actions={actions} />

      {hiddenFields &&
        hiddenFields.length > 0 && (
          <CollapsableContent
            animateHeight={false}
            hideTrigger="- Hide additional fields"
            showTrigger="+ Show additional fields"
          >
            <LinkedTrelloCardFields fields={hiddenFields} card={card} />
          </CollapsableContent>
        )}
    </NavigatableIntegrationWidgetCard.Detail>
  )
}
