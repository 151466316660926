import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const burgerMenu = css`
  opacity: 0.8;
  padding: ${rem(4)};
  display: none;

  @media screen and (max-width: 1100px) {
    display: block;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &[aria-expanded='true'] {
    span:first-of-type {
      transform: rotate(45deg);
    }

    span:nth-last-of-type(even) {
      display: none;
    }

    span:last-of-type {
      transform: rotate(-45deg);
      margin-top: -2px;
    }
  }
`

const hamburgerBar = theme => css`
  background-color: ${theme.color.monochrome.white};
  border-radius: 2px;
  display: block;
  height: 2px;
  transition: all 0.2s ease;
  width: ${theme.spacing['10']};

  &:nth-of-type(even) {
    margin: 3px 0;
  }
`

const accordionNav = theme => css`
  margin-bottom: 0;
  box-shadow: unset;

  a {
    color: ${theme.color.monochrome.black};
    padding-left: ${rem(24)};
    padding-right: ${rem(200)};
  }

  .active,
  .active a {
    color: ${theme.color.primary.brand};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.monochrome.medium};
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .centralizeMenu {
    max-width: ${rem(700)};

    [role='menu'] {
      flex-direction: row;
      [role='menuitem'] {
        width: calc(50% - ${rem(8)});
      }
    }
  }
`

const accordionPanelLink = css`
  & > * {
    padding: 0 !important;
  }

  [data-key] > * {
    width: 100%;
  }

  a {
    display: flex;
    height: ${rem(49)};
    width: 100%;
    align-items: center;

    &:hover > span:first-of-type {
      text-decoration: underline;
      text-decoration-thickness: 1.5px;
      text-underline-offset: 2px;
    }
  }

  svg {
    display: none;
  }
`

export const styles = {
  burgerMenu,
  hamburgerBar,
  accordionNav,
  accordionPanelLink,
}
