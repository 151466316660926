import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Sidebar from 'shared/components/ui/Sidebar'
import { Button, Flex, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Form } from 'util/ui'
import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import { selectRequiredCreateIssueTypeFieldNames } from 'ducks/integrations/jiraCloud'
import {
  PROJECT,
  ISSUE_TYPE,
  PARENT,
  SUMMARY,
  DESCRIPTION,
  ASSIGNEE,
  LABELS,
  COMPONENTS,
} from 'ducks/integrations/jiraCloud/createIssueTypeFieldKeys'
import { pick } from 'util/objects'
import {
  AssigneeFormField,
  ComponentsFormField,
  DescriptionFormField,
  LabelsFormField,
  ParentIssueFormField,
  PrioritiesFormField,
  ProjectFormField,
  SummaryFormField,
  TypesFormField,
} from './fields'

export default function LinkNewJiraCloudIssueModal({
  areProjectsLoading,
  areSitesLoading,
  areCreateIssueTypeFieldsLoading,
  doCreateAndLinkJiraCloudIssueToTicket,
  doFetchJiraCloudProjects,
  doFetchJiraCloudCreateIssueMetaTypeFields,
  formFields,
  formFields: {
    project = null,
    summary,
    type,
    parent,
    description,
    assignee,
    label,
    component,
  },
  onChange: propOnChange,
  onClose,
  onOpen,
  open,
  projectId,
  resetFields,
  resetForm,
  siteId,
}) {
  const isLoading =
    areProjectsLoading || areSitesLoading || areCreateIssueTypeFieldsLoading
  const isProjectChoosen = !!project

  const jiraRequiredIssueTypeFieldNames = useSelector(state =>
    selectRequiredCreateIssueTypeFieldNames(state, project, type)
  )

  const jiraFieldToFormFieldValueMapping = useMemo(
    () => {
      return {
        [PROJECT]: project,
        [ISSUE_TYPE]: type,
        [SUMMARY]: summary,
        [PARENT]: parent,
        [DESCRIPTION]: description,
        [ASSIGNEE]: assignee,
        [LABELS]: label,
        [COMPONENTS]: component,
      }
    },
    [project, summary, type, parent, description, assignee, label, component]
  )

  // get list of required fields from jira api
  // match them with the filled form values and calculate validity accordingly
  const isFormValid = useMemo(
    () => {
      if (isLoading || !project || !type) return false

      const requiredFormFieldsValues = Object.values(
        pick(jiraRequiredIssueTypeFieldNames, jiraFieldToFormFieldValueMapping)
      )

      return requiredFormFieldsValues.every(ff => !!ff && ff.trim() !== '')
    },
    [
      isLoading,
      project,
      type,
      jiraRequiredIssueTypeFieldNames,
      jiraFieldToFormFieldValueMapping,
    ]
  )

  const doResetAndClose = useCallback(
    () => {
      setTimeout(resetForm, themeVars.defaultDurationInt)
      onClose()
    },
    [resetForm, onClose]
  )
  const doSubmitResetAndClose = useCallback(
    () => {
      doCreateAndLinkJiraCloudIssueToTicket(siteId, projectId, formFields)
      doResetAndClose()
    },
    [
      doCreateAndLinkJiraCloudIssueToTicket,
      doResetAndClose,
      siteId,
      projectId,
      formFields,
    ]
  )
  const onChange = useCallback(
    (_, { name, value }) => {
      propOnChange(name, value, null, { validate: true })
    },
    [propOnChange]
  )
  useEffect(
    () => {
      doFetchJiraCloudProjects()
    },
    [doFetchJiraCloudProjects]
  )

  useEffect(
    () => {
      if (siteId && projectId) {
        doFetchJiraCloudCreateIssueMetaTypeFields(siteId, projectId)
      }
    },
    [siteId, projectId, doFetchJiraCloudCreateIssueMetaTypeFields, project]
  )

  return (
    <React.Fragment>
      <Button basic onClick={onOpen} className="grui ml-4 mt-4">
        New
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="create"
            portal
            position="top"
            tooltip={
              !isFormValid && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!isFormValid || isLoading}
              fluid
              key="link"
              onClick={doSubmitResetAndClose}
              primary
            >
              Create and link issue
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={doResetAndClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to New Jira Cloud Issue"
        onClose={doResetAndClose}
        open={open}
        wide
      >
        <ModalContent>
          {!isLoading && (
            <Form>
              {!areProjectsLoading && (
                <React.Fragment>
                  <Flex>
                    <ProjectFormField
                      formFields={formFields}
                      onChange={onChange}
                    />
                    <TypesFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      projectId={projectId}
                      resetFields={resetFields}
                      siteId={siteId}
                    />
                  </Flex>
                  <ParentIssueFormField
                    formFields={formFields}
                    onChange={onChange}
                    projectId={projectId}
                    resetFields={resetFields}
                    siteId={siteId}
                    type={type}
                  />
                  <SummaryFormField
                    formFields={formFields}
                    onChange={onChange}
                  />

                  <DescriptionFormField
                    formFields={formFields}
                    isProjectChoosen={isProjectChoosen}
                    onChange={onChange}
                    projectId={projectId}
                    resetFields={resetFields}
                    siteId={siteId}
                  />
                  <AssigneeFormField
                    formFields={formFields}
                    isProjectChoosen={isProjectChoosen}
                    onChange={onChange}
                    projectId={projectId}
                    resetFields={resetFields}
                    siteId={siteId}
                  />
                  <LabelsFormField
                    formFields={formFields}
                    isProjectChoosen={isProjectChoosen}
                    onChange={onChange}
                    siteId={siteId}
                    projectId={projectId}
                    resetFields={resetFields}
                  />

                  <Flex>
                    <ComponentsFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      projectId={projectId}
                      resetFields={resetFields}
                      siteId={siteId}
                    />
                    <PrioritiesFormField
                      formFields={formFields}
                      isProjectChoosen={isProjectChoosen}
                      onChange={onChange}
                      resetFields={resetFields}
                      siteId={siteId}
                      projectId={projectId}
                    />
                  </Flex>
                </React.Fragment>
              )}
            </Form>
          )}
        </ModalContent>
        {isLoading && <Loader />}
      </Sidebar>
    </React.Fragment>
  )
}
