import { memo, useCallback, useMemo, useState } from 'react'
import { useRechargeSubscriptions } from 'ducks/integrations/recharge/hooks/subscriptions'
import { Loader } from 'shared/ui'

import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import ListItem from './ListItem'

const List = ({ integrationId, customerId }) => {
  const [showMore, setShowMore] = useState(false)
  const { subscriptions, isLoading } = useRechargeSubscriptions(
    integrationId,
    customerId
  )

  const subscriptionsToShow = useMemo(
    () => {
      if (!subscriptions) {
        return []
      }

      if (showMore) {
        return subscriptions
      }

      return subscriptions.slice(0, 3)
    },
    [subscriptions, showMore]
  )

  const toggleShowMore = useCallback(
    event => {
      event.preventDefault()

      setShowMore(!showMore)
    },
    [showMore]
  )

  if (!subscriptions) {
    return null
  }

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Subscriptions" />

      {isLoading && <Loader className="grui my-7" />}

      {!isLoading &&
        !subscriptions.length && (
          <div className="grui px-4 py-7">
            <EmptyMessage>No subscriptions to show</EmptyMessage>
          </div>
        )}

      {!isLoading && (
        <NavigatableIntegrationWidgetCard.List hideMargin>
          {subscriptionsToShow.map(subscription => (
            <ListItem
              key={subscription.id}
              customerId={customerId}
              subscriptionId={subscription.id}
            />
          ))}
        </NavigatableIntegrationWidgetCard.List>
      )}

      {subscriptions.length > 3 &&
        !showMore && (
          <a
            className="grui recharge-show-more"
            onClick={toggleShowMore}
            rel="noopener noreferrer"
          >
            + Show more subscriptions
          </a>
        )}
    </>
  )
}

export default memo(List)
