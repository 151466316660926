import React, { useCallback } from 'react'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_SELECT_EMAIL } from 'ducks/drawers/types'
import NoticeBar from 'components/NoticeBar'

import styles from './styles.less'

export default function DemoMailboxBar({ isManager }) {
  const { openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
    lazyLoad: true,
    closeIgnoresStack: true,
  })

  const onOpen = useCallback(
    () => {
      openAddDrawer('new')
    },
    [openAddDrawer]
  )

  let callToActionContent = `Please contact your administrator to add ${app.t(
    'mailbox_with_article'
  )}.`
  if (isManager) {
    callToActionContent = (
      <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
        Add your {app.t('mailbox')}
      </a>
    )
  }

  return (
    <NoticeBar type="warning" noticeFlagText="HEADS UP!">
      You’re currently in demo mode. To start using Groove for real you’ll want
      to add your first {app.t('mailbox')}. {callToActionContent}
    </NoticeBar>
  )
}
