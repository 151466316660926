import LocalContext from './LocalContext'
import ListenToKeyboard from './ListenToKeyboard'

export default function WithLocalContext(props) {
  return (
    <LocalContext.Consumer>
      {localContext => (
        <ListenToKeyboard {...props} localContext={localContext} />
      )}
    </LocalContext.Consumer>
  )
}

WithLocalContext.displayName = 'WithLocalContext(ListenToKeyboard)'
