import { memo, useCallback, useMemo, useState } from 'react'
import DatePicker from 'shared/components/ui/DatePicker'
import { strftime } from 'util/date'
import { useAutofocus } from 'util/hooks'
import { Input } from 'util/ui'

export default memo(({ fieldKey, value }) => {
  const autofocusRef = useAutofocus()
  const valueAsDate = new Date(value)
  const validValueAsDate = isNaN(valueAsDate.getTime())
    ? new Date()
    : valueAsDate
  const [stateValue, setValue] = useState(
    strftime('%Y-%m-%d', validValueAsDate)
  )
  const onChange = useCallback(
    ({ target: { value: newValue } }) => {
      setValue(newValue)
    },
    [setValue]
  )
  const onClick = useCallback(
    newValue => {
      setValue(strftime('%Y-%m-%d', newValue))
    },
    [setValue]
  )
  const stateValueAsDate = useMemo(() => new Date(stateValue), [stateValue])
  return (
    <>
      {' '}
      <div className="CustomFieldEditValue-datePicker">
        <DatePicker
          initialMonth={stateValueAsDate}
          onDayClick={onClick}
          selectedDate={stateValueAsDate}
        />
      </div>
      <Input
        fluid
        icon="calendar"
        placeholder="YYYY-MM-DD"
        onChange={onChange}
        name={`${fieldKey}.content`}
        ref={autofocusRef}
        value={stateValue}
      />
    </>
  )
})
