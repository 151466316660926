import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'shared/ui'
import { toDate } from 'util/date'
import { propFunc } from 'util/functions'
import { snoozeOptions } from 'util/snooze'
import { omit } from 'util/objects'
import { useTheme } from '@emotion/react'

const SnoozeDropdown = ({
  onOptionClick,
  onCustomSnoozeOptionClick,
  header,
  bindHotKey,
  lastSnoozedDate,
  showHiddenSnoozeOptions,
  expiresAt,
  ...rest
}) => {
  const theme = useTheme()

  const snoozeDropdownOptions = useMemo(
    () => {
      const options = snoozeOptions(lastSnoozedDate, showHiddenSnoozeOptions)
        .filter(x => x.showOption())
        .map(opt => {
          const timestamp = opt.timestamp()
          const expiryOpt = {}

          if (
            expiresAt &&
            (timestamp === 'SNOOZED_INDEFINITELY' || timestamp > expiresAt)
          ) {
            expiryOpt.tooltip =
              'Heads up! The Snooze reply time is later than the remaining reply time that is allowed by Messenger. Click to snooze anyway...'
            expiryOpt.textColor = theme.color.primary.negative
          }

          return {
            ...omit(['indefinite'], opt),
            ...expiryOpt,
            key: opt.value,
            format: undefined,
            timestamp: undefined,
            condition: undefined,
            description: opt.format(timestamp),
            onClick: opt.disabled
              ? undefined
              : propFunc(
                  onOptionClick,
                  opt.indefinite ? timestamp : toDate(timestamp)
                ),
          }
        })

      // Custom Snooze
      options.push({
        key: 'custom-snooze',
        value: 'custom-snooze',
        text: 'Pick Date & Time',
        svgIcon: 'calendarAlt',
        onClick: onCustomSnoozeOptionClick,
      })

      return options
    },
    [
      theme,
      onOptionClick,
      onCustomSnoozeOptionClick,
      lastSnoozedDate,
      showHiddenSnoozeOptions,
      expiresAt,
    ]
  )

  return (
    <Dropdown
      {...rest}
      header={header}
      options={snoozeDropdownOptions}
      closeOnChange={false}
    />
  )
}

SnoozeDropdown.propTypes = {
  /* Whether the dropdown menu is disabled */
  disabled: PropTypes.bool,

  header: PropTypes.string,

  // Standard SUI Callbacks
  onClose: PropTypes.func,
  onBlur: PropTypes.func,

  /* onClick handler for an option (should trigger the snooze) */
  onOptionClick: PropTypes.func.isRequired,
  onCustomSnoozeOptionClick: PropTypes.func.isRequired,

  showHiddenSnoozeOptions: PropTypes.bool,
}

SnoozeDropdown.defaultProps = {
  disabled: false,
  header: 'Snooze',
  onBlur: undefined,
  onClose: undefined,
  showHiddenSnoozeOptions: false,
}

export default SnoozeDropdown
