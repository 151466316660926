import { useCallback } from 'react'

export default function CustomFieldValueLink({
  href,
  children,
  onClick: onExternalClick,
}) {
  const onClick = useCallback(
    event => {
      event.stopPropagation()
      if (onExternalClick) onExternalClick()
    },
    [onExternalClick]
  )
  return (
    <a href={href} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      {children}
    </a>
  )
}
