import React, { useCallback, useEffect, useState } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { useDispatch, useSelector } from 'react-redux'
import { doUploadHistory } from 'actions/attachments'
import useOpenContact from 'util/hooks/useOpenContact'
import { selectCanSendDebugInfo } from 'selectors/app/selectAccountPreferences'

const headers = {
  uploading: (
    <>
      Sending debug data<AnimatedEllipsis />
    </>
  ),
  uploaded: 'Debug data uploaded',
  failed: 'Unable to upload, please try again',
  default: 'Upload Groove data',
}

const SendDebugInfo = ({ open, onExit }) => {
  const dispatch = useDispatch()
  const openContact = useOpenContact()
  const canSendDebugInfo = useSelector(selectCanSendDebugInfo)
  const [state, setState] = useState({})
  const { stage, data } = state

  useEffect(
    () => {
      if (!canSendDebugInfo) {
        onExit()
      }
    },
    [canSendDebugInfo, onExit]
  )

  const startUpload = useCallback(
    () => {
      setState({ stage: 'uploading', data: null })
      dispatch(doUploadHistory())
        .then(response => {
          // eslint-disable-next-line no-console
          console.log(response)
          setState({ stage: 'uploaded', data: response })
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error('Debug upload failed', { error: e })
          setState({ stage: 'failed', data: null })
        })
    },
    [dispatch]
  )

  return (
    <Modal
      portal="#overlays"
      open={open}
      className="grui pb-14"
      onClose={onExit}
    >
      <Modal.Title>Send Groove Debug Info</Modal.Title>
      <Modal.Description>
        Sending Groove debugging info allows our support team to troubleshoot
        any issues experienced using the Groove App.
      </Modal.Description>
      {stage === 'uploaded' &&
        data && (
          <>
            <Modal.Description className="grui mt-12">
              <Modal.Instructions title="Debug data uploaded!">
                Please copy the link below and click on{' '}
                <strong>
                  <i>Send to Groove Support </i>
                </strong>
                to share with our support team.
              </Modal.Instructions>
            </Modal.Description>
            <Modal.CopyField
              className="grui mt-12"
              name="copy"
              copyText="Copy"
              defaultValue={`${data.url}/${data.key}`}
            />
            <Modal.Button
              className="grui mt-12"
              type="info"
              onClick={openContact}
            >
              Send to Groove Support
            </Modal.Button>
            <Modal.Button
              className="grui mt-5"
              type="tertiary"
              onClick={onExit}
            >
              Close
            </Modal.Button>
          </>
        )}
      {stage !== 'uploaded' && (
        <Modal.Button
          className="grui mt-12"
          type="info"
          onClick={startUpload}
          disabled={stage === 'uploading'}
        >
          {headers[stage] || headers.default}
        </Modal.Button>
      )}
    </Modal>
  )
}

export default SendDebugInfo
