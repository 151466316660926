import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const login = theme => css`
  position: relative;
  font-family: ${theme.font.tertiary};
  padding: 0 ${theme.spacing['14']};
  height: 100vh;

  * {
    letter-spacing: -0.02em;
  }

  .ui.large.form {
    width: 100%;
    max-width: 440px;
    padding: 0 ${theme.spacing['10']};

    .field {
      margin-bottom: ${theme.spacing['10']};

      input,
      .basic.label {
        padding: ${theme.spacing['7']} ${theme.spacing['10']};
        font-weight: ${theme.fontWeight.medium};
        border-radius: ${rem(8)};
        border: 1px solid rgba(${theme.color.info.black_rgba}, 0.2);
        line-height: ${theme.lineHeight.leadingTight};
        font-family: inherit;
      }

      .labeled > input {
        border-right: 1px solid rgba(${theme.color.info.black_rgba}, 0.2) !important;
      }

      input {
        &:focus {
          border-color: ${theme.color.primary.info} !important;
        }

        &::placeholder {
          color: rgba(${theme.color.info.black_rgba}, 0.4);
          font-weight: ${theme.fontWeight.medium};
        }
      }

      .basic.label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: unset;
        color: rgba(${theme.color.info.black_rgba}, 0.4);
        background-color: #eff1f5;
      }

      &.links {
        text-align: center;
        font-weight: ${theme.fontWeight.normal};
        color: rgba(${theme.color.info.black_rgba}, 0.6);
        margin-top: ${theme.spacing['14']};
        margin-bottom: 0;
        font-size: ${theme.fontSize.large};

        a {
          color: ${theme.color.primary.info};
          font-weight: ${theme.fontWeight.medium};
          &:hover {
            color: ${theme.color.info['700']};
          }
        }
      }

      .tip {
        font-style: normal !important;
      }
    }

    .loginError {
      font-size: ${theme.fontSize.x2large} !important;
      color: ${theme.color.negative['500']} !important;
      font-style: normal !important;
    }
  }
`

const container = css`
  max-width: ${rem(440)};
  max-height: 100%;
`

const header = theme => css`
  display: flex;
  justify-content: center;
  margin: 0 ${rem(20)} ${rem(48)};
  padding-bottom: ${rem(48)};
  border-bottom: 1px solid rgba(${theme.color.info.black_rgba}, 0.1);

  @media only screen and (max-width: ${theme.breakpoints.tabletBreakpoint}) {
    margin: 0 0 ${theme.spacing['14']};
    padding-bottom: ${theme.spacing['14']};
  }
`

const logo = theme => css`
  height: ${rem(40)};

  @media only screen and (max-width: ${theme.breakpoints.tabletBreakpoint}) {
    height: ${rem(30)};
  }

  img {
    height: 100%;
    width: auto;
  }
`

export const styles = {
  login,
  container,
  header,
  logo,
}
