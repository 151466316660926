import cn from 'classnames'

export default function NavigatableIntegrationWidgetCard({
  children,
  className,
  hideMargin = false,
}) {
  return (
    <div
      className={cn('NavigatableIntegrationWidgetCard-List', className, {
        hideMargin,
      })}
    >
      {children}
    </div>
  )
}
