// NOTE (jscheel): This is not a normal component file. Instead, this returns
// an HOC function, which is then combined with connect in index.jsx, resulting
// in a double-wrapped component. You can read more here:
// https://reactjs.org/docs/higher-order-components.html#convention-maximizing-composability

import React from 'react'
import ReactDropzone from 'react-dropzone'
import { getDataTransferItems } from 'util/browser'
import PropTypes from 'prop-types'
import { ALT } from 'constants/keys'
import ListenToKeyboard from 'components/ListenToKeyboard'
import cn from 'classnames'
import DropzoneIndicator from './Indicator'
import styles from './styles.css'

const inputProps = {
  style: {
    opacity: 0,
    display: 'none',
  },
}

export default function withDropzone(WrappedComponent, options = {}) {
  return class Dropzone extends React.PureComponent {
    static propTypes = {
      onDropCustom: PropTypes.func,
      onDropFiles: PropTypes.func.isRequired,
      isOnNewForm: PropTypes.bool,
      isCreatingPrivateNote: PropTypes.bool,
    }

    static defaultProps = {
      onDropCustom: () => {},
      isCreatingPrivateNote: false,
      isOnNewForm: false,
    }

    static initialState = {
      isDragging: false,
      prefersInverse: false,
      numFiles: 0,
    }

    state = { ...this.constructor.initialState }

    componentDidMount() {
      document.addEventListener('dragover', this.onDocumentDragOver, false)
      document.addEventListener('dragenter', this.onDocumentDragEnter, false)
      document.addEventListener('dragleave', this.onDocumentDragLeave, false)
      document.addEventListener('drop', this.onDocumentDrop, false)
    }

    componentWillUnmount() {
      document.removeEventListener('dragover', this.onDocumentDragOver)
      document.removeEventListener('dragenter', this.onDocumentDragEnter)
      document.removeEventListener('dragleave', this.onDocumentDragLeave)
      document.removeEventListener('drop', this.onDocumentDrop)
      this.dragIndicatorTimeout = null
    }

    onDocumentDragOver = () => this.startDocumentDragIndicator()

    onDocumentDragEnter = () => this.startDocumentDragIndicator()

    onDocumentDragLeave = () => this.stopDocumentDragIndicator()

    onDocumentDrop = () => this.stopDocumentDragIndicator()

    startDocumentDragIndicator = () => {
      if (this.dragIndicatorTimeout) {
        clearTimeout(this.dragIndicatorTimeout)
        this.dragIndicatorTimeout = null
      }
      if (!this.state.isDragging) {
        this.setState({ isDragging: true })
      }
    }

    stopDocumentDragIndicator = () => {
      this.dragIndicatorTimeout = setTimeout(() => {
        if (this.state.isDragging) {
          this.setState({ isDragging: false })
        }
      }, 50)
    }

    onDragEnter = e => {
      if (this.state.isDragging) {
        return
      }
      const newState = {
        isDragging: true,
        prefersInverse: e.isAlt,
      }

      const { types } = e.dataTransfer

      const numFiles = getDataTransferItems(e).length
      if (numFiles) {
        newState.numFiles = numFiles
      }
      if (types.length === 1 && types[0] === 'Files') {
        this.setState(newState)
      }
    }

    onDragLeave = () => {
      this.setState({ ...this.constructor.initialState })
    }

    onAltDown = () => {
      this.setState({ prefersInverse: true })
    }

    onAltUp = () => {
      this.setState({ prefersInverse: false })
    }

    onDrop = (accepted, rejected) => {
      const { onDropCustom, onDropFiles } = this.props

      if (onDropCustom) {
        onDropCustom(
          accepted,
          rejected,
          this.getTargetType(),
          this.props.onDropFiles
        )
      } else {
        onDropFiles(accepted, rejected, this.getTargetType())
      }
      this.setState({ ...this.constructor.initialState })
    }

    getTargetType() {
      const { isOnNewForm, isCreatingPrivateNote } = this.props
      const { prefersInverse } = this.state
      if (isOnNewForm && isCreatingPrivateNote) {
        return prefersInverse ? 'newEmail' : 'newConversation'
      }
      if (isOnNewForm && !isCreatingPrivateNote) {
        return prefersInverse ? 'newConversation' : 'newEmail'
      }
      if (!isOnNewForm && isCreatingPrivateNote) {
        return prefersInverse ? 'reply' : 'note'
      }
      return prefersInverse ? 'note' : 'reply'
    }

    render() {
      const {
        isOnTicketOrDraftPage,
        onDropFiles,
        isOnNewForm,
        onDropCustom,
        isCreatingPrivateNote,
        innerRef,
        ...rest
      } = this.props

      const { isDragging, numFiles, prefersInverse } = this.state
      const disabled =
        !isOnTicketOrDraftPage && !isOnNewForm && !isCreatingPrivateNote
      const IndicatorComponent = options.indicatorComponent || DropzoneIndicator
      const className = options.className || styles.Dropzone
      return (
        <ReactDropzone
          disabled={disabled}
          disableClick
          className={cn(className, {
            'dropzone-drag-active': isDragging,
          })}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          inputProps={inputProps}
        >
          <ListenToKeyboard
            onKeyDown={this.onAltDown}
            onKeyUp={this.onAltUp}
            keys={[ALT]}
          />
          <WrappedComponent {...rest} ref={innerRef} />
          {isDragging &&
            !options.skipIndicator && (
              <IndicatorComponent
                isDraggingMultipleFiles={numFiles > 1}
                isHoldingAlt={prefersInverse}
                targetType={this.getTargetType()}
              />
            )}
        </ReactDropzone>
      )
    }
  }
}
