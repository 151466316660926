import React, { useCallback } from 'react'
import cn from 'classnames'
import { useWatch } from 'react-hook-form'
import { bool, string } from 'prop-types'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import {
  text,
  list,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { styles as orderSummaryStyles } from 'components/integrations/ShopifyV2WidgetCard/OrderSummary/styles'
import { shopifyMoneyString } from 'util/currency'
import AdditinalInfo from './AdditinalInfo'
import RefundAmount from './RefundAmount'
import { styles } from './RefundSummary.styles'
import { canRestockRefundLineItems } from '../functions'
import { FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT } from '../constants'

const RefundSummary = ({
  control,
  register,
  className,
  orderCurrencyCode,
  isCalculating,
  calculateSuggestedRefund,
}) => {
  const suggestedRefund = useWatch({
    control,
    name: FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT,
  })

  const {
    subtotalSet,
    totalCartDiscountAmountSet,
    totalTaxSet,
    amountSet,
    shipping,
    suggestedTransactions,
  } =
    suggestedRefund || {}

  const canRefundShipping =
    !!shipping?.maximumRefundableSet?.shopMoney?.amount &&
    shipping.maximumRefundableSet.shopMoney.amount !== '0.0'

  const canRestock = canRestockRefundLineItems(suggestedRefund)

  const item = useCallback(
    (type, amount, currencyCode, options = {}) => {
      const { isGrandTotal = false } = options

      const convertedValue = shopifyMoneyString({
        currencyCode: currencyCode || orderCurrencyCode,
        amount,
      })

      return (
        <li
          className="grui flex"
          css={
            isGrandTotal
              ? [text.styles.textSuperDark, text.styles.fontMedium]
              : text.styles.textMediumDark
          }
        >
          <span>{type}</span>
          <span className="grui ml-auto" css={mainStyles.moneyString}>
            {isCalculating ? 'Calculating...' : convertedValue}
          </span>
        </li>
      )
    },
    [isCalculating, orderCurrencyCode]
  )
  return (
    <div>
      <div
        className={`grui flex justify-between ${className}`}
        css={orderSummaryStyles.row}
      >
        <div className="grui text-right mr-12">
          <ul
            className="grui pb-12 mb-12"
            css={[
              list.styles.preflight,
              orderSummaryStyles.summary,
              styles.summary,
              isCalculating ? styles.isCalculating : '',
            ]}
          >
            {item(
              'Items subtotal',
              subtotalSet?.shopMoney?.amount,
              subtotalSet?.shopMoney?.currencyCode
            )}
            {item(
              'Discounts',
              -totalCartDiscountAmountSet?.shopMoney?.amount,
              totalCartDiscountAmountSet?.shopMoney?.currencyCode
            )}
            {item(
              'Tax',
              totalTaxSet?.shopMoney?.amount,
              totalTaxSet?.shopMoney?.currencyCode
            )}
            {canRefundShipping
              ? item(
                  'Shipping',
                  shipping.amountSet.shopMoney.amount,
                  shipping.amountSet.shopMoney.currencyCode
                )
              : null}
            {item(
              'Refund total',
              amountSet?.shopMoney?.amount,
              amountSet?.shopMoney?.currencyCode,
              { isGrandTotal: true }
            )}
          </ul>
        </div>
        <div className={cn({ 'grui invisible': !canRestock })}>
          <p
            css={[paragraph.styles.preflight, text.styles.textMediumDark]}
            className="grui mb-5"
          >
            Refunded items will be removed from the order.
          </p>
          <Checkbox
            id="restock"
            {...register('restock', {
              onChange: calculateSuggestedRefund,
            })}
          >
            Restock item
          </Checkbox>
        </div>
      </div>
      <div className="grui flex justify-between" css={orderSummaryStyles.row}>
        <AdditinalInfo
          orderCurrencyCode={orderCurrencyCode}
          control={control}
          register={register}
          shippingMaximumRefundableSet={shipping?.maximumRefundableSet}
          isCalculating={isCalculating}
          canRefundShipping={canRefundShipping}
          calculateSuggestedRefund={calculateSuggestedRefund}
        />
        <RefundAmount
          className="grui mr-12"
          control={control}
          orderCurrencyCode={orderCurrencyCode}
          register={register}
          isCalculating={isCalculating}
          formattedGateway={suggestedTransactions?.[0]?.formattedGateway}
          accountNumber={suggestedTransactions?.[0]?.accountNumber}
          maximumRefundableSet={
            suggestedTransactions?.[0]?.maximumRefundableSet
          }
        />
      </div>
    </div>
  )
}

RefundSummary.propTypes = {
  orderCurrencyCode: string,
  className: string,
  isCalculating: bool,
}

RefundSummary.defaultProps = {
  orderCurrencyCode: '',
  summary: {},
  isCalculating: false,
  className: undefined,
}

export default RefundSummary
