import React from 'react'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex, SVGIcon } from 'shared/ui'
import Switch from 'shared/ui/Switch'
import CloseIcon from 'components/App/DesktopView/shared/Composer/CloseIcon'
import { doOpenMainPage } from 'actions/pages'

const Container = styled(Flex)`
  padding: 18px 28px;
  width: 100%;
  border-bottom: solid 1px ${props => props.theme.color.monochrome.medium};
`

const titleStyle = theme => css`
  color: ${theme.color.monochrome.superDark};
  font-family: ${theme.pageFont};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.x4large};
  line-height: ${theme.lineHeight.leading3};
  width: 100%;
  flex-grow: 1;
`

const Switcher = styled(Flex)`
  flex-grow: 0;
  flex-shrink: 0;
`

const Label = styled('div')`
  font-size: ${props => props.theme.fontSize.tiny};
  font-weight: ${props => props.theme.fontWeight.medium};
  text-transform: uppercase;
  color: ${props =>
    props.isLogging
      ? props.theme.color.primary.warningText
      : props.theme.color.monochrome.dark};
  margin-left: 4px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  line-height: 1.5;
`

export default function NewConvoHeader({ isLogging = true, onSwitch }) {
  const {
    color: { primary, monochrome },
  } = useTheme()
  return (
    <Container className="grui items-center">
      <div css={titleStyle}>
        {isLogging ? 'Log a Conversation' : 'Send New Conversation'}
      </div>
      <Switcher className="grui items-center">
        <Switch
          on={isLogging}
          color={isLogging ? primary.positive : monochrome.mediumDark}
          onClick={onSwitch}
        />
        <Label isLogging={isLogging}>
          <SVGIcon
            name="updatedPhoneLog"
            size={16}
            css={css`
              margin-left: 4px;
              margin-right: 2px;
              margin-top: 2px;
              margin-bottom: 2px;
            `}
          />
          Log a conversation
        </Label>
      </Switcher>
      <CloseIcon
        to={doOpenMainPage()}
        css={css`
          position: static;
          flex-shrink: 0;
          margin-left: 16px;
        `}
      />
    </Container>
  )
}
