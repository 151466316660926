import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { sortByKey } from 'util/arrays'
import { selectIsEditingTicketTitle } from 'selectors/app'
import { selectLabelsWithAppliedUpdates } from 'selectors/labelings'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { styles } from './styles'
import Tag from './Tag'

const LabelList = ({ className }) => {
  const isEditingTicketTitle = useSelector(selectIsEditingTicketTitle)
  const ticketId = useSelector(selectCurrentTicketId)
  const labels = useSelector(selectLabelsWithAppliedUpdates)

  const sortedLabels = useMemo(() => sortByKey(labels.filter(e => e), 'name'), [
    labels,
  ])

  if (!labels || labels.length === 0) return null

  return (
    <div
      css={[styles.labels, isEditingTicketTitle && styles.labelsHidden]}
      className={className}
    >
      {sortedLabels.map(label => (
        <Tag key={label.id} ticketId={ticketId} labelId={label.id} />
      ))}
    </div>
  )
}

export default LabelList
