import { doFetchJiraCloudSites } from 'ducks/integrations/jiraCloud'

import { Flex, Label } from 'shared/ui'
import { useActionEffect } from 'util/hooks'

import LinkExistingJiraCloudIssueModal from './LinkExistingJiraCloudIssueModal'
import LinkNewJiraCloudIssueModal from './LinkNewJiraCloudIssueModal'

export default function LinkJiraCloudIssue() {
  useActionEffect(doFetchJiraCloudSites)
  return (
    <React.Fragment>
      <Label size="small">Link Conversation To Issue</Label>
      <Flex>
        <LinkExistingJiraCloudIssueModal />
        <LinkNewJiraCloudIssueModal />
      </Flex>
    </React.Fragment>
  )
}
