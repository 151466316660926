import styled from '@emotion/styled'

export default styled('img')`
  height: 16px;
  width: 16px;
  display: inline;
  vertical-align: text-bottom;
  margin-right: 3px;
  border-radius: 2px;
`
