import { useCallback, useMemo } from 'react'

import Button from '@groovehq/internal-design-system/lib/components/Button/Button'

const NpsButton = ({ value, selectedScore, onSelect, className }) => {
  const type = useMemo(
    () => {
      if (selectedScore === null || selectedScore === value) {
        return 'primary'
      }

      return 'tertiary'
    },
    [value, selectedScore]
  )

  const onClick = useCallback(
    () => {
      onSelect(value)
    },
    [onSelect, value]
  )

  return (
    <Button className={className} type={type} onClick={onClick}>
      {value}
    </Button>
  )
}

export default NpsButton
