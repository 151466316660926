import { css } from '@emotion/react'

const tag = css`
  min-width: 11px;
`

const controls = theme => css`
  flex-shrink: 0;
  button {
    font-size: ${theme.fontSize.x3large};
    line-height: 1;
    width: 40px;
    height: 40px;
    vertical-align: bottom;
    border-right-color: transparent;

    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: ${theme.color.primary.brand};

      &:hover + button {
        border-left-color: transparent;
      }
    }

    & + button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right-color: ${theme.color.buttonTertiary.border};
      &:hover {
        border-right-color: ${theme.color.buttonTertiary.borderHover};
      }
    }
  }
`

const percentage = theme => css`
  & > button:first-of-type {
    color: ${theme.color.buttonTertiary.text};
  }
  & > button:nth-of-type(2) {
    color: ${theme.color.primary.brand};
  }
`

export const styles = { tag, controls, percentage }
