import React from 'react'
import { string, arrayOf } from 'prop-types'
import cn from 'classnames'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { stars as Stars } from 'assets/icons/groove'
import { styles } from './styles'

const Testimonials = ({ className, list = [] }) => {
  return (
    <div
      css={text.styles.textNormal}
      className={cn('grui flex justify-center text-center', className)}
    >
      <div css={styles.list} className="testimonial-list">
        {list.map((testimonial, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Stars className="grui block ml-auto mr-auto" />
            <div className="grui mt-5 testimonial">{testimonial}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

Testimonials.propTypes = {
  className: string,
  list: arrayOf(string),
}

Testimonials.defaultProps = {
  className: undefined,
  list: [],
}

export default Testimonials
