import { connect } from 'react-redux'
import { required } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'

import { selectLatestLoginError } from 'selectors/app'

import { doVerifyOtpOnServer } from 'actions/auth'

import OtpFormView from './view'

const validators = {
  code: required,
}

const initialValues = { code: '' }

const select = state => ({
  initialValues,
  validators,
  loginError: selectLatestLoginError(state),
})

const perform = dispatch => {
  return {
    onSave: ({ code }) => dispatch(doVerifyOtpOnServer(code)),
  }
}

export default connect(select, perform)(withFormFields(OtpFormView))
