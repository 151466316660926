import { useMemo } from 'react'

import {
  CUSTOMER_MARKETING_OPT_IN_LEVEL,
  PRODUCT_SUBSCRIBER_STATUS,
} from 'ducks/integrations/shopify/constants'

import { shopifyMoneyString } from 'util/currency'

const getAddress = (addresses = []) =>
  addresses.find(address => address.formatted)

const getArrayValuesContent = (values = []) =>
  values.length > 0 ? values.join(', ') : ''

export const useShopifyCustomerFields = (keyPrefix, customer) => {
  const totalSpentString = shopifyMoneyString(customer.amountSpent || {})

  const componentOverrides = useMemo(
    () => ({
      [`${keyPrefix}_lifetime_duration`]: {
        type: 'SINGLE_LINE',
        value: { content: customer?.lifetimeDuration },
      },
      [`${keyPrefix}_total_spent`]: {
        type: 'SINGLE_LINE',
        value: { content: totalSpentString },
      },
      [`${keyPrefix}_marketing_opt_in_level`]: {
        type: 'SINGLE_LINE',
        value: {
          content:
            CUSTOMER_MARKETING_OPT_IN_LEVEL[
              customer?.emailMarketingConsent?.marketingOptInLevel
            ],
        },
      },
      [`${keyPrefix}_product_subscriber_status`]: {
        type: 'SINGLE_LINE',
        value: {
          content: PRODUCT_SUBSCRIBER_STATUS[customer?.productSubscriberStatus],
        },
      },
      [`${keyPrefix}_tax_exemptions`]: {
        value: { content: getArrayValuesContent(customer?.taxExemptions) },
      },
      [`${keyPrefix}_addresses`]: {
        value: getAddress(customer?.addresses),
      },
      [`${keyPrefix}_state`]: {
        value: { content: customer?.state },
      },
      [`${keyPrefix}_note`]: {
        value: { content: customer?.note },
      },
      [`${keyPrefix}_locale`]: {
        value: { content: customer?.locale },
      },
      [`${keyPrefix}_tags`]: {
        value: { content: getArrayValuesContent(customer?.tags) },
      },
      [`${keyPrefix}_orders_count`]: {
        value: { content: customer?.numberOfOrders },
      },
      [`${keyPrefix}_tax_exempt`]: {
        value: customer?.taxExempt,
      },
      [`${keyPrefix}_last_order_id`]: {
        value: { content: customer?.lastOrder?.id },
      },
      [`${keyPrefix}_currency`]: {
        value: { content: customer?.amountSpent?.currencyCode },
      },
    }),
    [keyPrefix, customer, totalSpentString]
  )

  return { componentOverrides }
}
