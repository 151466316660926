import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLocalTime } from 'ducks/crm/contacts'
import CustomFieldKeyValue from '../../CustomFieldKeyValue'

export default function LocalTime({
  customFieldKey,
  fieldHidden,
  isInEditMode,
  options,
  onEye,
  subjectId,
}) {
  const content = useSelector(selectLocalTime)
  const value = useMemo(() => ({ content }), [content])
  return (
    <CustomFieldKeyValue
      isEditable={false}
      fieldKey={customFieldKey}
      fieldHidden={fieldHidden}
      icon="location"
      isInEditMode={isInEditMode}
      options={options}
      onEye={onEye}
      subjectId={subjectId}
      title="Local time"
      type={'SINGLE_LINE'}
      value={value}
    />
  )
}
