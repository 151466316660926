/* eslint-disable no-use-before-define */
import React from 'react'
import Item from './Item'

const Tags = props => {
  const {
    buildMailboxFolderPath,
    buildMailboxQueryString,
    doOpenSearchPage,
    doStartSearchTimer,
    isSubMenuItem,
    labels,
  } = props

  if (!labels) return null
  return labels.map(label => {
    return (
      <Item
        labelId={label.id}
        name={label.name}
        key={`label-${label.id}`}
        doOpenSearchPage={doOpenSearchPage}
        doStartSearchTimer={doStartSearchTimer}
        className="label"
        displayCountWhenInactive
        ticketCount={label.ticketCount}
        buildMailboxFolderPath={buildMailboxFolderPath}
        buildMailboxQueryString={buildMailboxQueryString}
        isSubMenuItem={isSubMenuItem}
      />
    )
  })
}

export default Tags
