import React from 'react'

const Recipients = ({ recipients, children, onClickRecipients }) => {
  return (
    <div className="grui flex items-center pr-12">
      {children}
      <span
        className="grui ml-3 truncate flex-grow cursor-text"
        onClick={onClickRecipients}
        tabIndex={0}
      >
        {recipients.length
          ? recipients
              .map(recipient => recipient.name || recipient.email)
              .join(', ')
          : 'Add contact'}
      </span>
    </div>
  )
}

export default Recipients
