import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {
  doCreateAndLinkTrelloCardToTicket,
  doFetchTrelloBoardDetails,
  doFetchTrelloBoards,
  selectTrelloBoardsAsOptions,
  selectTrelloBoardIsLoading,
  selectTrelloLabelsAsOptionsForBoard,
  selectTrelloListsAsOptionsForBoard,
  selectTrelloMembersAsOptionsForBoard,
} from 'ducks/integrations/trello'
import withFormFields from 'shared/ui/Form/withFormFields'
import withOpenState from 'shared/ui/withOpenState'
import { required } from 'util/validation'

import LinkNewTrelloCardModalForm from './LinkNewTrelloCardModalForm'

function mapStateToProps(state, props) {
  const {
    formFields: { board },
  } = props
  return {
    labels: selectTrelloLabelsAsOptionsForBoard(state, board),
    boards: selectTrelloBoardsAsOptions(state),
    isBoardLoading: selectTrelloBoardIsLoading(state, board),
    lists: selectTrelloListsAsOptionsForBoard(state, board),
    members: selectTrelloMembersAsOptionsForBoard(state, board),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    doFetchTrelloBoardDetails: id => dispatch(doFetchTrelloBoardDetails(id)),
    doFetchTrelloBoards: () => dispatch(doFetchTrelloBoards()),
    doCreateAndLinkTrelloCardToTicket: () => {
      dispatch(doCreateAndLinkTrelloCardToTicket(props.formFields))
      props.resetForm()
      props.onClose()
    },
    onClose: () => {
      props.resetForm()
      props.onClose()
    },
  }
}

const validators = {
  board: required,
  list: required,
  name: required,
}

const initialValues = { name: '' }

const Form = compose(
  withOpenState,
  withFormFields,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkNewTrelloCardModalForm)

export default function LinkNewTrelloCardModal(props) {
  return (
    <Form {...props} initialValues={initialValues} validators={validators} />
  )
}
