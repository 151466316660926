import { connect } from 'react-redux'

import { doRefreshSearches } from 'actions/app'
import { selectIsRefreshingSearches } from 'selectors/app'
import View from './view'

const select = state => {
  return {
    isRefreshingSearches: selectIsRefreshingSearches(state),
  }
}

const perform = dispatch => ({
  doRefreshSearches: () => dispatch(doRefreshSearches(true)),
})

export default connect(select, perform)(View)
