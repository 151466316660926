import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'
import { SelectableItem } from 'shared/ui'
import LabelText from './LabelText'
import LabelCheckbox from './LabelCheckbox'

const ListItem = ({
  className,
  isChecked,
  isFocused,
  isIndeterminate,
  label: { id, color, name, labelingsCount: count },
  onCheckboxClick = null /* When checkbox input changed */,
  onClick = null /* when row/label or checkbox input clicked */,
  showCount,
  mode,
  searchTerm,
}) => {
  return (
    <SelectableItem
      className={cn('grui py-3 pl-12 pr-10', className)}
      content={
        mode === 'single' || id === -1 ? (
          <LabelText
            id={id}
            name={name}
            count={count}
            showCount={showCount}
            searchTerm={searchTerm}
          />
        ) : (
          <LabelCheckbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            id={id}
            label={
              <LabelText
                id={id}
                name={name}
                count={count}
                showCount={showCount}
                searchTerm={searchTerm}
              />
            }
            inputColor={color}
            name={name}
            onCheckboxClick={onCheckboxClick}
            searchTerm={searchTerm}
          />
        )
      }
      onContainerClick={onClick}
      selected={isFocused}
    />
  )
}

export default styled(ListItem)`
  display: block;
`
