import React, { useState, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import {
  DndContext,
  DragOverlay,
  collisionDetection,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import { arrayMove } from '@dnd-kit/sortable'

import RemovableRecipient from '../RecipientsEditor/List/RemovableRecipient'
import { useRecipientsEditor } from '../RecipientsEditor/useRecipientsEditor'

const hasIdOrEmailInSource = (source, target) =>
  (source.id && source.id === target.id) || source.email === target.email

const modifiers = [restrictToWindowEdges]

const DraggableRowsWrapper = ({
  recipientsTo,
  recipientsCc,
  recipientsBcc,
  ticketId,
  isForwarding,
  children,
}) => {
  const [activeRecipient, setActiveRecipient] = useState(null)
  const {
    onRemove: onRemoveFromTO,
    onChangeList: onChangeTO,
  } = useRecipientsEditor({
    type: 'to',
    ticketId,
    allowMultipleRecipients: isForwarding,
    draftType: 'reply',
  })
  const {
    onRemove: onRemoveFromCC,
    onChangeList: onChangeCC,
  } = useRecipientsEditor({
    type: 'cc',
    ticketId,
    allowMultipleRecipients: true,
    draftType: 'reply',
  })
  const {
    onRemove: onRemoveFromBCC,
    onChangeList: onChangeBCC,
  } = useRecipientsEditor({
    type: 'bcc',
    ticketId,
    allowMultipleRecipients: true,
    draftType: 'reply',
  })

  const rows = useMemo(
    () => {
      return {
        to: {
          onChangeList: onChangeTO,
          onRemove: onRemoveFromTO,
          recipients: recipientsTo,
        },
        cc: {
          onChangeList: onChangeCC,
          onRemove: onRemoveFromCC,
          recipients: recipientsCc,
        },
        bcc: {
          onChangeList: onChangeBCC,
          onRemove: onRemoveFromBCC,
          recipients: recipientsBcc,
        },
      }
    },
    [
      onRemoveFromTO,
      onRemoveFromCC,
      onRemoveFromBCC,
      onChangeBCC,
      onChangeCC,
      onChangeTO,
      recipientsTo,
      recipientsCc,
      recipientsBcc,
    ]
  )

  const handleSwitchPosition = useCallback(
    (items, overRecipient) => {
      const oldIndex = items.findIndex(item =>
        hasIdOrEmailInSource(item, activeRecipient)
      )
      let newIndex = items.findIndex(item =>
        hasIdOrEmailInSource(item, overRecipient)
      )
      if (newIndex === -1) {
        newIndex = items.length
      } else if (oldIndex < newIndex) {
        newIndex -= 1
      }
      return arrayMove(items, oldIndex, newIndex)
    },
    [activeRecipient]
  )

  const handleInsertRecipient = useCallback(
    (items, overRecipient) => {
      let overIndex = items.findIndex(item =>
        hasIdOrEmailInSource(item, overRecipient)
      )
      const existed = items.some(item =>
        hasIdOrEmailInSource(item, activeRecipient)
      )
      let result = [...items]
      if (existed) {
        result = items.filter(
          item =>
            item.id
              ? item.id !== activeRecipient.id
              : item.email !== activeRecipient.email
        )
      }
      if (overIndex === -1) {
        overIndex = items.length
      }
      result.splice(overIndex, 0, activeRecipient)
      return result
    },
    [activeRecipient]
  )

  const handleDragStart = useCallback(e => {
    setActiveRecipient(e.active.data.current.recipient)
  }, [])

  const handleDragEnd = useCallback(
    e => {
      setActiveRecipient(null)
      const { active, over } = e
      if (!active || !over || active.id === over.id) return
      const activeType = active.data.current.type
      const overType = over.data.current.type
      if (activeType === overType) {
        // Same type: change position
        rows[overType].onChangeList(
          handleSwitchPosition(
            rows[overType].recipients,
            over.data.current.recipient
          )
        )
      } else {
        // Different types: remove from active and insert to over
        rows[activeType].onRemove(
          rows[activeType].recipients.find(r =>
            hasIdOrEmailInSource(r, active.data.current.recipient)
          )
        )
        rows[overType].onChangeList(
          handleInsertRecipient(
            rows[overType].recipients,
            over.data.current.recipient
          )
        )
      }
    },
    [rows, handleSwitchPosition, handleInsertRecipient]
  )

  const handleDragCancel = useCallback(() => {
    setActiveRecipient(null)
  }, [])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  )

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      collisionDetection={collisionDetection}
      sensors={sensors}
      modifiers={modifiers}
    >
      {createPortal(
        <DragOverlay>
          {activeRecipient ? (
            <RemovableRecipient
              recipient={activeRecipient}
              interactionDisabled
            />
          ) : null}
        </DragOverlay>,
        document.getElementById('overlays') || document.body
      )}

      {children}
    </DndContext>
  )
}

export default DraggableRowsWrapper
