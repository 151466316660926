import React from 'react'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import { bellRing as BellRing } from 'assets/icons/groove/v2'

import { styles } from './styles'

const EmptyCategoryNotification = ({ className }) => {
  return (
    <div css={[styles.emptyCategoryNotification]} className={className}>
      <div css={styles.bellRing}>
        <BellRing />
      </div>
      <div css={heading.styles.h3} className="grui my-3">
        No new notifications!
      </div>
    </div>
  )
}

export default EmptyCategoryNotification
