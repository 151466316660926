import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'
import { Loader } from 'util/ui'

export default styled(({ className }) => (
  <Loader active className={className} inline="centered" />
))`
  margin-top: ${themeVars.relativeLarge};
`
