import React from 'react'
import { IconButton } from 'shared/ui'

const SpamButton = React.memo(({ spamConversation }) => {
  return (
    <div className="spam">
      <IconButton
        name="spam"
        size="medium"
        className="menu-button"
        onClick={spamConversation}
        tooltip={'Mark as Spam [!]'}
        svg
      />
    </div>
  )
})

export default SpamButton
