import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HIDE_BOOK_DEMO_CARD } from 'ducks/flags/flagNames'
import { doSetFlag } from 'ducks/flags/operations'
import tony from 'assets/tony-small@2x.png'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import useCalendlyWidget from 'util/hooks/useCalendlyWidget'
import FeatureCard from './FeatureCard'

const CALENDLY_URL = 'https://calendly.com/tonyatgroove/30min'

const BookDemoCard = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const handleCloseCard = useCallback(
    () => {
      dispatch(doSetFlag(HIDE_BOOK_DEMO_CARD))
    },
    [dispatch]
  )

  useCalendlyWidget({
    onScheduleSuccess: handleCloseCard,
  })

  const onScheduleClick = useCallback(
    () => {
      if (!window.Calendly) {
        window.open(URL, CALENDLY_URL)
        return
      }
      window.Calendly.initPopupWidget({
        url: CALENDLY_URL,
        hide_gdpr_banner: true,
        prefill: {
          email: currentUser.email,
          name: `${currentUser.first_name} ${currentUser.last_name}`,
        },
      })
    },
    [currentUser]
  )

  if (!currentUser.id) return null

  return (
    <FeatureCard
      icon={<img src={tony} alt="Tony" />}
      title="Want the grand tour?"
      subtitle="Let Tony, a member of our Customer Success, team give you a 1-1 demo to get you up and running."
      buttonLabel="Schedule a time"
      onClickButton={onScheduleClick}
      onClose={handleCloseCard}
    />
  )
}

export default BookDemoCard
