import SidebarDotContent from 'components/App/DesktopView/Layout/Sidebar/SidebarDotContent'

export default function CollectionButton({ color, name }) {
  return (
    <div key="collection-button" className="collection">
      <SidebarDotContent color={color} />
      <span className="name">{name}</span>
    </div>
  )
}
