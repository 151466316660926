import React from 'react'
import UpdateAppBanner from 'components/Banners/UpdateApp'
import Switcher from './Switcher'
import styles from '../styles.less'

const MainCell = ({ isUpdateAvailable, ...rest }) => {
  return (
    <div className={styles.mainCell}>
      {isUpdateAvailable && <UpdateAppBanner />}
      <Switcher {...rest} />
    </div>
  )
}

export default MainCell
