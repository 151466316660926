import cn from 'classnames'
import { sortableHandle } from 'react-sortable-hoc'
import styled from '@emotion/styled'

import { IconButton } from 'shared/ui'

const DragHandle = sortableHandle(() => (
  <IconButton className="DragHandle" name="draggableDots" size="tiny" svg />
))

export default styled(SortableVisibleControlsWithTitle)`
  display: flex;
  height: 16px;

  .SortableVisibleControlsTitle {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .IconButton {
    padding: 4px;
    margin-top: -4px;
  }
  .DragHandle {
    cursor: grab;
  }

  .EditModeHelper & {
    .IconButton {
      cursor: grabbing;
    }
    .SortableVisibleControls {
      display: flex;
    }
    .DragHandle:hover path,
    .IconButton path {
      fill: var(--color-monochrome-black) !important;
    }
    .CollapsableCard:hover .SortableVisibleControlsWithTitle-hideButton {
      display: inline-flex;
    }
  }

  .EditModeHelper.isInEditMode & {
    .DragHandle:hover path,
    .IconButton path {
      fill: var(--color-monochrome-mediumDark) !important;
    }
  }
`

function SortableVisibleControlsWithTitle({
  children,
  className,
  onHide,
  hideable = !!onHide,
  reorderable = true,
  refreshable = false,
  onRefresh,
  title,
}) {
  return (
    <div className={cn('SortableVisibleControlsWithTitle', className)}>
      <div className="SortableVisibleControlsTitle">{title}</div>
      <div className="SortableVisibleControls">
        {children}
        {refreshable && (
          <IconButton onClick={onRefresh} name="redo" size="tiny" svg />
        )}
        {reorderable && <DragHandle />}
        {hideable && (
          <IconButton
            className="SortableVisibleControlsWithTitle-hideButton"
            onClick={onHide}
            name="eyeSlash"
            size="tiny"
            svg
          />
        )}
      </div>
    </div>
  )
}
