// Shared Layout for all pages that show a ticket detail pane
import ListInspector from '../ListInspector'
import TicketInspector from '../TicketInspector'

const Page = props => {
  if (props.ticketId) return <TicketInspector {...props} />

  return <ListInspector {...props} />
}

export default Page
