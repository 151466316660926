import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { bell as Bell } from 'assets/icons/groove/v2'
import { getMatrixEnvironment } from 'ducks/chat/utils/client/getMatrixEnvironment'
import { useNotifications } from 'ducks/notifications/v2/hooks'
import { NOTIFICATION_EVENT_TYPES } from 'ducks/notifications/v2/constants'
import Menu from './Menu'
import { styles } from './styles'
import { NotificationRoomContext } from './contexts'
import EmptyNotification from './EmptyNotification'

const heightConstraints = {
  max: 616,
  min: 0,
}

const menuOffset = [-100, 8]

const Notifications = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  // const needsPushPermission = useSelector(selectNeedsPushPermission)
  const {
    allUnreadCount,
    isReady,
    room,
    hasNotifications,
    isLoading,
    markAllEventsRead,
  } = useNotifications(NOTIFICATION_EVENT_TYPES)

  const showEmptyNotification = !hasNotifications && isReady

  useEffect(
    () => {
      if (!isReady) {
        getMatrixEnvironment()
      }
    },
    [isReady]
  )

  const roomContext = useMemo(
    () => ({
      room,
      isReady,
    }),
    [room, isReady]
  )

  const handleOnClickNotification = useCallback(() => {
    setIsMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <>
          {showEmptyNotification && <EmptyNotification />}
          {!showEmptyNotification && (
            <Menu onClickNotification={handleOnClickNotification} />
          )}
        </>
      )
    },
    [showEmptyNotification, handleOnClickNotification]
  )

  const onVisibleChange = useCallback(
    isVisible => {
      if (!isVisible && allUnreadCount > 0) {
        markAllEventsRead()
      }

      setIsMenuVisible(isVisible)
    },
    [markAllEventsRead, allUnreadCount]
  )

  if (!isReady || isLoading) return null

  return (
    <NotificationRoomContext.Provider value={roomContext}>
      <Dropdown
        overlay={menu}
        visible={isMenuVisible}
        defaultVisible={false}
        strategy="fixed"
        portal="#overlays"
        className="grui p-0"
        position="bottom"
        heightConstraints={heightConstraints}
        isNavByArrowsDisabled
        offset={menuOffset}
        onVisibleChange={onVisibleChange}
      >
        <button
          type="button"
          className="grui px-4 py-2 mr-4 flex-center"
          css={[
            button.styles.preflight,
            styles.notificationBtn,
            allUnreadCount === 0 && styles.resetBack,
          ]}
        >
          {allUnreadCount > 0 &&
            !isLoading && (
              <div css={styles.hasUnseenNotifications}>
                {allUnreadCount > 99 ? '99+' : `${allUnreadCount}`}
              </div>
            )}
          <Bell />
        </button>
      </Dropdown>
    </NotificationRoomContext.Provider>
  )
}

export default Notifications
