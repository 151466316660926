import React from 'react'
import { css } from '@emotion/react'
import { Flex, SUI } from 'shared/ui'
import { Image } from 'util/ui'
import { Heading, Paragraph } from 'shared/components/ui/Elements'
import themeVars from 'ui_theme/site/globals/site.variables'
import noMailboxAccessImgSrc from 'assets/inbox_you_haven_t_been_given_access.png'

const containerStyle = () => theme => css`
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.monochrome.superLight};
  text-align: center;
`

const adminStyle = () => theme => css`
  font-size: ${themeVars.writtenFontSize};
  color: ${theme.color.monochrome.black};
`

const illustrationStyle = () => theme => css`
  margin-bottom: ${theme.spacing['12']};
`

const headingStyle = () => theme => css`
  margin-bottom: ${theme.spacing['5']};
`

const paragraghStyle = () => theme => css`
  max-width: 620px;
  margin-bottom: ${theme.spacing['10']};
`

const Container = props => (
  <Flex
    className="grui items-center justify-center flex-col"
    css={containerStyle}
    {...props}
  />
)

const AdminsContainer = props => (
  <Flex className="grui items-center flex-col" {...props} />
)

const Admin = props => (
  <Flex className="grui justify-center" css={adminStyle} {...props} />
)

const Illustration = props => (
  <Image src={noMailboxAccessImgSrc} css={illustrationStyle} {...props} />
)

const Email = ({ email }) => (
  <SUI>
    <a
      href={`mailto:${email}`}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        font-size: ${themeVars.writtenFontSize};
      `}
    >
      {email}
    </a>
  </SUI>
)

export default function NoMailboxAccess({ admins }) {
  return (
    <Container>
      <Illustration />
      <Heading level={5} css={headingStyle}>
        Oh no! You haven&#39;t been given access to any {app.t('mailboxes')}{' '}
        yet.
      </Heading>
      <Paragraph size="regular" css={paragraghStyle}>
        Once you get given access to your {app.t('mailboxes')}, you can start
        sending and receiving mail over here. Please chat to one of your account
        admins to give you access:
      </Paragraph>
      <AdminsContainer>
        {admins.map(({ name, email, id }) => (
          <Admin key={`agent-${id}`}>
            <span>{name}</span>
            <span>&nbsp;&ndash;&nbsp;</span>
            <Email email={email} />
          </Admin>
        ))}
      </AdminsContainer>
    </Container>
  )
}
