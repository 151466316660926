import React from 'react'

import { SUI, SearchBox, Divider } from 'shared/ui'
import withListController from 'components/App/DesktopView/shared/withListController'
import LabelList from './LabelList'
import styles from './styles.css'

const SearchableList = ({
  focusedIndex,
  onSearch,
  searchTerm,
  hidden,
  ...rest
}) => {
  return (
    <React.Fragment>
      {!hidden && (
        <SearchBox
          className="grui ml-12 mt-10"
          onSearchChange={onSearch}
          placeholder="Search..."
          value={searchTerm}
        />
      )}
      <SUI className={styles.suiList}>
        <Divider />
        <LabelList
          focusedIndex={focusedIndex}
          searchTerm={searchTerm}
          {...rest}
        />
      </SUI>
    </React.Fragment>
  )
}

const SearchableListWithListController = withListController(SearchableList)

export default SearchableListWithListController
