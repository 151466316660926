import React from 'react'

import logo from 'assets/integrations/stripe.png'
import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import StripeDetail from './StripeDetail'
import StripeIndex from './StripeIndex'

export default function StripeView({ doHideCard, isLoading, integration }) {
  return (
    <NavigatableIntegrationWidgetCard
      detailComponent={StripeDetail}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={StripeIndex}
      isLoading={isLoading}
      title="Stripe"
      persistenceKey="stripe"
      integration={integration}
      hidePadding
    />
  )
}
