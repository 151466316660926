import cn from 'classnames'
import { css } from '@emotion/react'

import { building as BuildingIcon } from 'assets/icons/groove/v2'
import themeVars from 'ui_theme/site/globals/site.variables'

const defaultClassName = () => theme => css`
  height: 32px;
  width: 32px;
  margin: 4px 8px 0 0;
  border-radius: ${themeVars.defaultBorderRadius};
  background: ${theme.color.monochrome.light};
  padding: 10px;

  .AvatarEditField & {
    > svg {
      transform: scale(2);
    }
    padding: 33px;
    height: 80px;
    width: 80px;
    float: left;
    margin-top: 0;
    margin-right: 3.25px;
  }
`
export default function MissingAvatar({ className }) {
  return (
    <div css={defaultClassName} className={cn(className, 'MissingAvatar')}>
      <BuildingIcon />
    </div>
  )
}
