import { connect } from 'react-redux'
import { selectDraftById } from 'ducks/drafts2/selectors'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'

import Button from './view'

const select = (state, initialProps) => {
  const { draftId } = initialProps

  return {
    draft: selectDraftById(state, draftId),
  }
}

const perform = (dispatch, initialProps) => {
  const { ticketId } = initialProps

  return {
    resetAutomaticActionStatus: draft => {
      const automaticActions = (draft?.automaticActions || []).filter(
        a => a.type !== 'status'
      )

      if (!automaticActions.length) {
        return
      }

      dispatch(
        doUpsertReplyDraft(draft.id, 'reply', ticketId, null, {
          automaticActions,
        })
      )
    },
  }
}

export default connect(select, perform)(Button)
