import { useCallback, useState, useRef, useEffect } from 'react'
import { dumbLinkify } from 'util/strings'

import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { divider } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as baseStyles } from '../styles'
import { styles } from './styles'

const SummaryContent = ({ suggestion, isLoading }) => {
  const [isShowMore, setShowMore] = useState(undefined)
  const contentRef = useRef(null)

  useEffect(
    () => {
      if (isLoading) {
        setShowMore(undefined)
        return
      }
      const contentNode = contentRef.current
      setShowMore(contentNode?.scrollHeight <= contentNode?.clientHeight)
    },
    [isLoading]
  )

  const onShowMoreClick = useCallback(() => setShowMore(true), [])

  return (
    <>
      <SanitizedHTML
        css={[styles.html, isShowMore !== true && styles.text]}
        className="body"
        html={dumbLinkify(suggestion.value, { showFullUrl: true })}
        onBodyNode={contentRef}
      />

      {isShowMore === false && (
        <div className="grui flex items-center">
          <div
            css={[divider.styles.base, baseStyles.divider]}
            role="separator"
          />
          <Button
            css={styles.showMore}
            type="tertiary"
            size="small"
            className="grui mx-5 pt-2 pb-1 px-5 flex-shrink-0"
            onClick={onShowMoreClick}
          >
            VIEW MORE
          </Button>
          <div
            css={[divider.styles.base, baseStyles.divider]}
            role="separator"
          />
        </div>
      )}
    </>
  )
}

export default SummaryContent
