import React from 'react'
import cn from 'classnames'
import styles from './styles.css'

const NormalView = ({ className, variantState }) => {
  if (!styles[`StateIndicator-${variantState}`]) {
    return null
  }

  return (
    <div
      className={cn(
        styles.StateIndicator,
        styles[`StateIndicator-${variantState}`],
        className
      )}
    />
  )
}

// HOC that decorates the
function withVariant(WrappedComponent) {
  function WithVariant({
    notingIds,
    hasDraft,
    hideDraftIndicator,
    replyingIds,
    ticketId,
    typingIds,
    viewingIds,
    ...rest
  }) {
    const isAnotherAgentViewing = viewingIds.length > 0
    const isAnotherAgentReplying = replyingIds.length > 0
    const isAnotherAgentNoting = notingIds.length > 0
    const isAnotherAgentTyping = typingIds.length > 0

    let variantState

    if (
      isAnotherAgentReplying ||
      isAnotherAgentNoting ||
      isAnotherAgentTyping
    ) {
      variantState = 'replyCollision'
    } else if (isAnotherAgentViewing) {
      variantState = 'viewCollision'
    } else if (hasDraft && !hideDraftIndicator) {
      variantState = 'hasDraft'
    }

    return <WrappedComponent variantState={variantState} {...rest} />
  }

  return WithVariant
}

const View = withVariant(NormalView)

export default View
