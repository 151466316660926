import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { Icon } from 'util/ui'

export default function NavigatableIntegrationDetail({
  children,
  className,
  onBack,
  title,
  unifiedTitleIconButton,
}) {
  return (
    <div className={className}>
      <div className="NavigatableIntegrationWidgetCard-Detail-title">
        {unifiedTitleIconButton && (
          <button
            css={button.styles.preflight}
            className="NavigatableIntegrationWidgetCard-Detail-titleIconButton"
            onClick={onBack}
          >
            <Icon className="NavigatableIntegrationWidgetCard-Detail-icon chevron left" />
            {title}
          </button>
        )}
        {!unifiedTitleIconButton && (
          <>
            <Icon
              className="NavigatableIntegrationWidgetCard-Detail-icon chevron left"
              onClick={onBack}
            />
            {title}
          </>
        )}
      </div>
      {children}
    </div>
  )
}
