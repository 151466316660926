import styled from '@emotion/styled'

import Card from './Card'

const StyledWrapper = styled('div')`
  & .card-content {
    padding: 0;
  }
  & > div {
    border: 0 !important;
  }
  border: 0;
  .sui & .ui.accordion:last-child {
    margin-bottom: 0;
  }
`

function SalesforceIndex({ shouldShowCard, ...rest }) {
  return (
    <StyledWrapper>
      <Card {...rest} />
    </StyledWrapper>
  )
}

export default SalesforceIndex
