import { useSelector } from 'react-redux'
import { selectAvatarUrl } from 'ducks/crm/contacts'
import ContactAvatar from 'components/crm/ContactAvatar'
import AvatarEditField from 'components/crm/AvatarEditField'

import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'

export default function ContactAvatarEditField() {
  const email = useSelectCustomFieldValueForKeyAndType(
    'contact_email',
    'CONTACT'
  )

  const avatarUrl = useSelector(state =>
    selectAvatarUrl(state, { categoryType: 'CONTACT' })
  )

  return (
    <AvatarEditField
      as={ContactAvatar}
      email={email}
      fallback={
        <ContactAvatar
          className="DisplayedAvatar"
          email={email}
          size={80}
          src={avatarUrl}
        />
      }
      name="contact_avatar"
      size={80}
      url={avatarUrl}
    />
  )
}
