import { useSelector } from 'react-redux'
import logo from 'assets/integrations/jira-server/icon-256.png'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import LinkedJiraServerIssuesIndex from './LinkedJiraServerIssuesIndex'
import LinkedJiraServerIssue from './LinkedJiraServerIssue'

export default function JiraServerWidgetCard({ doHideCard }) {
  const conversationId = useSelector(selectCurrentTicketId)
  if (!conversationId) return null
  return (
    <NavigatableIntegrationWidgetCard
      detailComponent={LinkedJiraServerIssue}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={LinkedJiraServerIssuesIndex}
      title="Jira Server"
      persistenceKey="jiraServer"
    />
  )
}
