import cn from 'classnames'
import { capitalize } from 'util/strings'
import './styles.less'

const Rating = ({ grade, feedback, actorLabel }) => {
  if (!grade) return null
  return (
    <div className="rating">
      <div className={cn('rating-marker', grade)} />
      <div className="content">
        <div className="grade">
          {capitalize(actorLabel)} left a rating of{' '}
          <span className={cn('grade', grade)}>{capitalize(grade)}</span>
        </div>
        <div className={cn('feedback', { empty: !feedback })}>
          {feedback || 'No comment was left by the customer...'}
        </div>
      </div>
    </div>
  )
}

export default Rating
