import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const button = css`
  &&&& {
    border-radius: 32px;
    display: block;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const buttonIcon = css`
  margin: 0;
  align-self: center;
`

const text = css`
  display: inline-block;
  margin-right: 8px;
`

const divider = css`
  margin: ${rem(26)} 0;
`

export const styles = {
  button,
  buttonIcon,
  text,
  divider,
}
