import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useDroppable, useDndContext } from '@dnd-kit/core'
import { styles } from './styles'

export const emptyDroppable = { id: 'empty' }

function Droppable({
  recipient,
  type,
  children,
  disabled,
  className,
  singleInput,
}) {
  const { collisons } = useDndContext()
  const [indicatorPosition, setIndicatorPosition] = useState('left')
  const { setNodeRef, isOver } = useDroppable({
    id: [recipient.id || recipient.email, type].join('-'),
    data: { type, recipient },
    disabled,
  })

  useEffect(
    () => {
      if (isOver && collisons?.length) {
        const position = collisons?.length > 1 ? 'right' : 'left'
        setIndicatorPosition(position)
      }
    },
    [isOver, collisons?.length]
  )

  return (
    <div
      ref={setNodeRef}
      css={styles.droppable}
      className={cn(
        'grui flex items-center',
        recipient.id === emptyDroppable.id && 'flex-grow',
        className
      )}
    >
      {isOver &&
        indicatorPosition === 'left' && (
          <div
            css={styles.indicator}
            className={cn(singleInput && 'indicator-no-gap')}
          />
        )}
      {children}
      {isOver && indicatorPosition !== 'left' && <div css={styles.indicator} />}
    </div>
  )
}

export default Droppable
