import { DISCOUNT_VALUE_TYPES } from 'ducks/integrations/shopify/constants'

export const getDiscountedPrice = (price, appliedDiscount = {}) => {
  const { value, valueType } = appliedDiscount || {}
  if (Number(value) > 0) {
    // Percentage
    if (valueType === DISCOUNT_VALUE_TYPES.PERCENTAGE) {
      return (100 - value) / 100 * price
    }
    // total amount discount
    return price - value * 100
  }
  // No discount
  return null
}

export const convertFromCents = number => {
  let num = Number.parseFloat(number)
  if (Number.isNaN(num)) {
    num = 0
  }
  return (num / 100).toFixed(2)
}

export const isNotEmpty = number => {
  if (Number.parseFloat(number) !== 0 && !number) {
    return false
  }
  return true
}

export const createFromString = ({ name, email }) => `"${name}" <${email}>`

export const getItemTotal = (price, quantity, appliedDiscount) => {
  let discountedPrice = getDiscountedPrice(price, appliedDiscount)
  if (discountedPrice === null) {
    discountedPrice = price
  }
  const total = discountedPrice * parseInt(quantity, 10)
  return total > 0 ? total : 0
}

export const isDraftOrderId = id => {
  if (!id) return false

  return id.startsWith('gid://shopify/DraftOrder/')
}
