import { connect } from 'react-redux'

import {
  selectCustomFieldValueContentForCustomFieldKeyAndCategoryType,
  selectCurrentContactName,
  selectCurrentContact,
} from 'ducks/crm/contacts'
import { selectCustomerIdByIntegrationIdAndEmail } from 'ducks/integrations/shopify/selectors'

import ShopifyIndexView from './view'

const select = (state, { integration }) => {
  const { storeDomain } = integration
  const categoryType = 'CONTACT'
  const keyPrefix = `shopify_${storeDomain}.myshopify.com_`
  const contact = selectCurrentContact(state)
  const customer = selectCustomerIdByIntegrationIdAndEmail(
    state,
    integration.id,
    contact.email
  )

  const handle = selectCustomFieldValueContentForCustomFieldKeyAndCategoryType(
    state,
    {
      customFieldKey: `${keyPrefix}customer_id`,
      categoryType,
    }
  )

  const customerGid = handle ? `gid://shopify/Customer/${handle}` : null
  return {
    contact,
    customer,
    handle,
    name: selectCurrentContactName(state),
    integration,
    customerGid,
  }
}

export default connect(select, {})(ShopifyIndexView)
