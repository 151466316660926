import styled from '@emotion/styled'

import { Label } from 'shared/ui'

export { default as KeyValue } from './KeyValue'
export { default as CollapsibleKeyValue } from './CollapsibleKeyValue'

const KeyValues = styled('div')`
  margin-top: ${props => props.theme.spacing['7']};
`

export default KeyValues
export const KeyValuesLabel = styled(Label)`
  margin-bottom: 5px;
`
