import React from 'react'
import cn from 'classnames'
import { motion } from 'framer-motion'
import {
  text,
  paragraph,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from './styles'

const FeatureCard = ({
  className,
  onClose,
  title,
  subtitle,
  buttonLabel,
  onClickButton,
  icon,
}) => {
  return (
    <motion.div
      css={styles.base}
      className={cn('grui text-center p-5', className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 2 }}
    >
      {icon && <div css={styles.icon}>{icon}</div>}
      <div
        css={[text.styles.textSm, text.styles.fontMedium]}
        className="grui mb-3"
      >
        {title}
      </div>
      <p css={paragraph.styles.preflight}>{subtitle}</p>
      <Button
        type="info"
        css={styles.button}
        className="grui mb-3 mt-6"
        onClick={onClickButton}
      >
        {buttonLabel}
      </Button>
      {onClose && (
        <button
          type="button"
          css={[
            button.styles.tertiaryLink,
            text.styles.textMediumDark,
            styles.link,
          ]}
          onClick={onClose}
        >
          Don’t show again
        </button>
      )}
    </motion.div>
  )
}

export default FeatureCard
