import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectGlobalTitle,
  selectGlobalCount,
} from 'ducks/title/selectors/global'
import { setTitle } from 'ducks/title/utils'

export default function Title() {
  const title = useSelector(selectGlobalTitle)
  const count = useSelector(selectGlobalCount)
  useEffect(
    () => {
      setTitle(title, count)
    },
    [title, count]
  )
  return null
}
