import React from 'react'

import KeyValues, { KeyValue } from 'components/KeyValues'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { getValueByPath } from 'util/objects'
import { dumbLinkify } from 'util/strings'

export default class Properties extends React.PureComponent {
  renderProperty(property, owner) {
    const value =
      property.key.match('hubspot_owner_id') && owner
        ? owner.label
        : property.value
    if (property.type === 'text' && value.match('http')) {
      return <SanitizedHTML html={dumbLinkify(value)} />
    }
    return value
  }

  render() {
    const { properties, specificFields, ownersById } = this.props
    let keys
    if (specificFields) {
      keys = specificFields
    } else {
      keys = Object.keys(properties)
    }
    const sorted = keys
      .filter(key => properties[key])
      .map(key => properties[key])
      .sort((a, b) => {
        return a.displayOrder > b.displayOrder ? 1 : -1
      })

    const ownerId = getValueByPath('hubspot_owner_id.value', properties)
    const owner = ownerId && ownersById && ownersById[ownerId]

    return (
      <KeyValues>
        {sorted.map(property => (
          <KeyValue
            key={property.key}
            title={property.label}
            value={this.renderProperty(property, owner)}
          />
        ))}
      </KeyValues>
    )
  }
}
