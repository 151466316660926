import { css } from '@emotion/react'
import { useSelector } from 'react-redux'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import { selectCurrentContactName } from 'ducks/crm/contacts/selectors'

import { isDefined } from 'util/nullOrUndefinedChecks'
import useSelectCustomFieldValueForKeyAndType from '../useSelectCustomFieldValueForKeyAndType'

const styles = css`
  min-width: 0;
  flex-grow: 1;

  .name,
  .email {
    font-size: 11px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .name {
    font-size: 14px;
    font-weight: var(--fontWeight-medium);
    line-height: 21px;
  }
  .missing {
    color: var(--color-monochrome-mediumDark);
    display: block;
  }
  .unknown_sender {
    color: var(--color-primary-negative);
  }
`

export default function ContactNameAndEmail() {
  const fullName = useSelector(selectCurrentContactName)
  const email = useSelectCustomFieldValueForKeyAndType(
    'contact_email',
    'CONTACT'
  )

  const isUnknownSender =
    isDefined(email) && email === 'unknown_sender@domain.invalid'

  return (
    <div css={styles} className="ContactNameAndEmail">
      <>
        {isUnknownSender && (
          <>
            <Tooltip
              title="Conversation received with no sender information"
              strategy="fixed"
              position="auto"
            >
              <div>
                <div className="name unknown_sender">
                  ⚠️&nbsp;
                  <em>{fullName || email || 'Unknown Sender'}</em>
                </div>
                <em className="email missing">No email address provided</em>
              </div>
            </Tooltip>
          </>
        )}
        {!isUnknownSender && (
          <>
            <div className="name">{fullName || email || 'Unknown contact'}</div>
            {email && fullName && <div className="email">{email}</div>}
            {!email &&
              (!email && (
                <em className="email missing">No email address provided</em>
              ))}
          </>
        )}
      </>
    </div>
  )
}
