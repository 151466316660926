import { connect } from 'react-redux'

import {
  doSearchLabels,
  doFetchTopLabelsForCurrentMailbox,
  doStartEditLabel,
  doCancelCreateLabel,
  doCreateLabelAndAddAsSelected,
  doBeginManageLabels,
} from 'actions/labels'
import {
  doLabelCurrentTicket,
  doSmartToggleLabel,
} from 'actions/labelings/shared'
import { doSelectLabel } from 'actions/labelings/desktop'

import {
  selectIsLoadingTopLabels,
  selectIsFetchingLabelSearchResults,
} from 'selectors/app'
import {
  selectIsSearchingLabels,
  selectLabelSearchTerm,
  selectLabelsChanged,
  selectLabelsForBulkEditingWithCreateOption,
} from 'selectors/labelings'
import { selectInitialLabelProps } from 'selectors/modals_base'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectLabelingDropdownDisplayMoreLabels } from 'selectors/labels'

import { selectCurrentUserIsAdmin } from 'ducks/currentUser/selectors'

import LabelsModal from './view'

const select = state => {
  return {
    hasPendingEdits: selectLabelsChanged(state),
    isAdmin: selectCurrentUserIsAdmin(state),
    isFetchingSearchResults: selectIsFetchingLabelSearchResults(state),
    isLoadingTop: selectIsLoadingTopLabels(state),
    isSearching: selectIsSearchingLabels(state),
    labelToEdit: selectInitialLabelProps(state),
    labels: selectLabelsForBulkEditingWithCreateOption(state),
    searchTerm: selectLabelSearchTerm(state),
    hasMoreLabels: selectLabelingDropdownDisplayMoreLabels(state),
    ticketId: selectCurrentTicketId(state),
  }
}

const perform = {
  doBeginManageLabels,
  doCancelCreateLabel,
  doStartEditLabel,
  toggleLabel: doSmartToggleLabel,
  onApply: doLabelCurrentTicket,
  onCheckboxChanged: doSelectLabel,
  onModalOpen: doFetchTopLabelsForCurrentMailbox,
  onSearch: doSearchLabels,
  onSave: doCreateLabelAndAddAsSelected,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onApply: ownProps.onApply || dispatchProps.onApply,
  }
}

export default connect(select, perform, mergeProps)(LabelsModal)
