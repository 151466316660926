import { useMemo } from 'react'
import { formatAmountWithCurrency } from 'ducks/integrations/recharge/utils'
import { isEmpty } from 'util/objects'
import { Loader } from 'shared/ui'

import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'

export default function DetailsTable({ charge }) {
  const shippingPrice = useMemo(
    () => {
      if (!charge.shipping_lines) {
        return null
      }

      const prices = charge.shipping_lines.map(line => parseFloat(line.price))
      return prices.reduce((a, b) => a + b, 0).toFixed(2)
    },
    [charge]
  )

  if (isEmpty(charge)) {
    return null
  }

  if (charge.isLoading) {
    return <Loader className="grui" />
  }

  return (
    <>
      <NavigatableIntegrationWidgetCard.HeaderLabel text="Order details" />
      <div className="grui p-7">
        {charge.line_items.map(item => (
          <div key={item.purchase_item_id}>
            <span>{item.title}</span>
            <div className="recharge-line-item recharge-line-item-top">
              <span className="recharge-line-item-top-quantity">
                {formatAmountWithCurrency(item.unit_price, charge.currency)} x{' '}
                {item.quantity}
              </span>
              <span>
                {formatAmountWithCurrency(item.total_price, charge.currency)}
              </span>
            </div>
          </div>
        ))}

        <div className="recharge-line-item recharge-line-item-subtotal">
          <span>Subtotal</span>
          <span>
            {formatAmountWithCurrency(charge.subtotal_price, charge.currency)}
          </span>
        </div>
        <div className="recharge-line-item recharge-line-item-price">
          <span>Taxes</span>
          <span>
            {formatAmountWithCurrency(charge.total_tax, charge.currency)}
          </span>
        </div>
        <div className="recharge-line-item recharge-line-item-price">
          <span>Shipping</span>
          <span>
            {formatAmountWithCurrency(shippingPrice, charge.currency)}
          </span>
        </div>
        <div className="recharge-line-item recharge-line-item-total">
          <span className="recharge-line-item-total-price">Total</span>
          <span className="recharge-line-item-total-price">
            {formatAmountWithCurrency(charge.total_price, charge.currency)}
          </span>
        </div>
      </div>
    </>
  )
}
