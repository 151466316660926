import React from 'react'

import KeyboardHandler from 'components/Lists/KeyboardHandler'
import KeyboardNavigator from 'components/KeyboardNavigator'

import { getLength } from 'util/arrays'
import { getDisplayName } from 'util/hoc'

// Props Proxy HOC for list KB navigation
// Sauce https://medium.com/@franleplant/react-higher-order-components-in-depth-cf9032ee6c3e
function withListController(WrappedList) {
  class WithListController extends React.Component {
    state = {
      focusedIndex: null,
      mouseX: null,
      mouseY: null,
    }

    componentDidMount() {
      this.focusFirstItem()
    }

    componentWillReceiveProps(newProps) {
      const oldProps = this.props

      if (oldProps.isSearching && !newProps.isSearching) {
        this.focusFirstItem()
      }
    }

    componentDidUpdate(oldProps) {
      const newProps = this.props
      if (
        oldProps.items &&
        newProps.items &&
        oldProps.items[0] !== newProps.items[0]
      ) {
        this.focusFirstItem()
      }
    }

    checkMouseMoved = evt => {
      const x = evt.clientX
      const y = evt.clientY
      const mouseMoved = x !== this.state.mouseX && y !== this.state.mouseY
      if (mouseMoved) {
        this.setState({ mouseX: x, mouseY: y })
        return true
      }

      return false
    }

    focusFirstItem = () => {
      const { items } = this.props
      if (items) this.handleFocus(0)
    }

    handleFocus = (focusedIndex, evt = null, focusedViaMouse = false) => {
      if (focusedViaMouse) {
        // Dont focus this item if the mouse didnt move (i.e. a scroll event
        // caused the onMouseOver to fire)
        if (!this.checkMouseMoved(evt)) return
      }
      const { items, onFocus } = this.props
      if (onFocus) {
        const result = items[focusedIndex]
        onFocus(focusedIndex, result)
      }

      this.setState({ focusedIndex, focusedViaMouse })
    }

    // Focuse via mouse
    handleMouseFocus = (...args) => this.handleFocus(...args, true)

    handleSelect = (index, evt) => {
      if (isNaN(index)) return false
      const { items, ticketId, onSelect, onClose } = this.props
      if (!onSelect) return false
      if (evt) {
        // handle click events. (KB handler already strips this)
        evt.preventDefault()
        evt.stopPropagation()
      }
      const result = onSelect(ticketId, items[index])
      if (onClose) onClose()
      return result
    }

    render() {
      const props = this.props
      const { onClose, items, onShiftEnter } = props
      const { focusedIndex } = this.state

      return (
        <React.Fragment>
          <KeyboardNavigator.Focus />
          <KeyboardHandler
            focusedIndex={focusedIndex}
            count={getLength(items)}
            onEnter={this.handleSelect}
            onTab={this.handleSelect}
            onUp={this.handleFocus}
            onDown={this.handleFocus}
            onEscape={onClose}
            onShiftEnter={onShiftEnter}
          />
          <WrappedList
            focusedIndex={focusedIndex}
            onItemClick={this.handleSelect}
            onItemMouseFocus={this.handleMouseFocus}
            {...props}
          />
        </React.Fragment>
      )
    }
  }

  WithListController.displayName = `WithListController(${getDisplayName(
    WrappedList
  )})`

  return WithListController
}

export default withListController
