import { css } from '@emotion/react'
import { convertHexToRGBA } from 'util/colors'
import { styles as baseStyles } from '../styles'

const neutral = theme => css`
  color: ${theme.color.monochrome.darkGray};
  background: ${convertHexToRGBA(theme.color.monochrome.darkGray, 0.1)};
  border-radius: ${theme.spacing[3]};
  font-weight: ${theme.fontWeight.medium};
  line-height: normal;
  padding-top: 7px;
  padding-bottom: 7px;
`

const positive = theme => css`
  ${neutral(theme)};
  color: ${theme.color.primary.positive};
  background: ${convertHexToRGBA(theme.color.primary.positive, 0.1)};
`

const negative = theme => css`
  ${neutral(theme)};
  color: ${theme.color.negativeExtra.medium};
  background: ${convertHexToRGBA(theme.color.negativeExtra.medium, 0.1)};
`

const loadingSentiment = theme => css`
  ${neutral(theme)};
  ${baseStyles.loadingState};
  background-color: ${theme.color.monochrome.white};
  border: 1px solid currentColor;
  height: ${theme.spacing[14]};
`

export const styles = {
  neutral,
  positive,
  negative,
  loadingSentiment,
}
