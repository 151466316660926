import { connect } from 'react-redux'
import { doSelectModalTab, doHideModal } from 'actions/modals'
import { selectCurrentModalTab } from 'selectors/modals'
import TabbedModal from './view'

const select = state => ({
  currentTab: selectCurrentModalTab(state),
})

const perform = (dispatch, ownProps) => ({
  selectTab: tabName => dispatch(doSelectModalTab(tabName)),
  close: () => {
    dispatch(doHideModal())
    if (ownProps.onBackgroundClick) {
      ownProps.onBackgroundClick()
    }
  },
})

export * from './view'
export default connect(select, perform)(TabbedModal)
