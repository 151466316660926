import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import FeatureCallout from '@groovehq/internal-design-system/lib/components/FeatureCallout/FeatureCallout'
import { styles as featureCalloutStyles } from '@groovehq/internal-design-system/lib/components/FeatureCallout/FeatureCallout.styles'
import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import useFrillNotification from 'util/hooks/useFrillNotification'
import { HIDE_AI_TEXT_MODIFICATIONS_CALLOUT } from 'ducks/flags/flagNames'
import { doSetFlag } from 'ducks/flags/operations'

const AiMessageCallout = ({ children }) => {
  const dispatch = useDispatch()
  const { onNotify, loaded } = useFrillNotification()
  const [show, setShow] = useState(true)
  const handleClose = useCallback(
    () => {
      dispatch(doSetFlag(HIDE_AI_TEXT_MODIFICATIONS_CALLOUT))
      setShow(false)
    },
    [dispatch]
  )
  const handleLearnMore = useCallback(
    () => {
      onNotify('ai-text-modifications')
      handleClose()
    },
    [onNotify, handleClose]
  )
  const overlay = (
    <div>
      <h5
        className="grui my-4"
        css={[heading.styles.h5, text.styles.textWhite]}
      >
        AI: Text Modifications
      </h5>
      Enhance your replies with these quick and easy AI features!
      <div className="grui mt-10" css={featureCalloutStyles.footer}>
        <button
          css={button.styles.preflight}
          type="button"
          className="grui"
          onClick={handleLearnMore}
        >
          Learn more
        </button>
        <button
          css={button.styles.preflight}
          type="button"
          onClick={handleClose}
          className="grui"
        >
          Dismiss
        </button>
      </div>
    </div>
  )

  return (
    <FeatureCallout
      overlay={overlay}
      open={show && loaded}
      position="top"
      offset={2}
      strategy="fixed"
      portal="#overlays"
    >
      {children}
    </FeatureCallout>
  )
}

export default AiMessageCallout
