import { Label } from 'shared/ui'
import { Input } from 'util/ui'
import { useAutofocus } from 'util/hooks'

import CustomFieldEditForm from '../../../CustomFieldKeyValue/CustomFieldEditForm'
import WidgetCardModal from '../../../WidgetCardModal'
import ContactAvatarEditField from './ContactAvatarEditField'
import ContactHeaderFieldsEmails from './ContactHeaderFieldsEmails'

export default function ContactHeaderFieldsEditModal({
  firstName = '',
  lastName = '',
  doRecalcMenuPosition,
  menuRef,
  offset,
  onCancel,
  onSave,
}) {
  const autofocusRef = useAutofocus()
  return (
    <WidgetCardModal
      noCurtain={false}
      onBackgroundClick={onCancel}
      offset={offset}
      contentRef={menuRef}
    >
      <CustomFieldEditForm
        deletable={false}
        onCancel={onCancel}
        onSave={onSave}
      >
        <Label as="div">Avatar</Label>
        <ContactAvatarEditField />
        <Label as="div">First name</Label>
        <Input
          defaultValue={firstName}
          fluid
          name={`contact_first_name.content`}
          ref={autofocusRef}
        />
        <Label as="div">Last name</Label>
        <Input
          defaultValue={lastName}
          fluid
          name={`contact_last_name.content`}
        />
        <ContactHeaderFieldsEmails
          doRecalcMenuPosition={doRecalcMenuPosition}
        />
      </CustomFieldEditForm>
    </WidgetCardModal>
  )
}
