import React from 'react'
import styled from '@emotion/styled'

import { strftime, isThisYear } from 'util/date'

import { Label } from 'shared/components/ui/Typography'

function getDateString(date) {
  const format = isThisYear(date) ? '%b %d' : '%b %d, %Y'
  return strftime(format, date)
}

const Container = styled(Label)`
  white-space: nowrap;
`

export default function Date({ date, className }) {
  return (
    <Container size="medium" color="ultraLight" className={className}>
      {getDateString(date)}
    </Container>
  )
}
