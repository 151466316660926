export const CANCEL = 3
export const HELP = 6
export const BACKSPACE = 8
export const TAB = 9
export const CLEAR = 12
export const ENTER = 13
export const RETURN = 13
export const SHIFT = 16
export const CTRL = 17
export const ALT = 18
export const BREAK = 19
export const CAPS = 20
export const FINAL = 24
export const ESCAPE = 27
export const CONVERT = 28
export const NON_CONVERT = 29
export const ACCEPT = 30
export const MODE_CHANGE = 31
export const SPACE = 32
export const PAGE_UP = 33
export const PAGE_DOWN = 34
export const END = 35
export const HOME = 36
export const LEFT = 37
export const UP = 38
export const RIGHT = 39
export const DOWN = 40
export const SELECT = 41
export const PRINT = 42
export const EXECUTE = 43
export const PRINT_SCREEN = 44
export const INSERT = 45
export const DELETE = 46
export const NUM0 = 48
export const NUM1 = 49
export const NUM2 = 50
export const NUM3 = 51
export const NUM4 = 52
export const NUM5 = 53
export const NUM6 = 54
export const NUM7 = 55
export const NUM8 = 56
export const NUM9 = 57
export const A = 65
export const B = 66
export const C = 67
export const D = 68
export const E = 69
export const F = 70
export const G = 71
export const H = 72
export const I = 73
export const J = 74
export const K = 75
export const L = 76
export const M = 77
export const N = 78
export const O = 79
export const P = 80
export const Q = 81
export const R = 82
export const S = 83
export const T = 84
export const U = 85
export const V = 86
export const W = 87
export const X = 88
export const Y = 89
export const Z = 90
export const LEFT_CMD = 91
export const RIGHT_CMD = 92
export const CONTEXT_MENU = 93
export const SLEEP = 95
export const MULTIPLY = 106
export const ADD = 107
export const SEPARATOR = 108
export const SUBTRACT = 109
export const DECIMAL = 110
export const DIVIDE = 111
export const F1 = 112
export const F2 = 113
export const F3 = 114
export const F4 = 115
export const F5 = 116
export const F6 = 117
export const F7 = 118
export const F8 = 119
export const F9 = 120
export const F10 = 121
export const F11 = 122
export const F12 = 123
export const F13 = 124
export const F14 = 125
export const F15 = 126
export const NUM_LOCK = 144
export const SCROLL_LOCK = 145
export const SEMICOLON = 186
export const SEMICOLON_FIREFOX = 59
export const EQUALS = 187
export const COMMA = 188
export const DASH = 189
export const PERIOD = 190
export const FORWARD_SLASH = 191
export const BACKTICK = 192
export const OPEN_BRACKET = 219
export const BACK_SLASH = 220
export const CLOSE_BRACKET = 221
export const QUOTE = 222
