import { useCallback } from 'react'
import { Icon } from 'util/ui'

import useDoUpdateCustomFieldValuesFromForm from '../../../useDoUpdateCustomFieldValuesFromForm'
import CompanyHeaderFieldsEditModal from './CompanyHeaderFieldsEditModal'

export default function CompanyHeaderFieldsEditButton({
  doRecalcMenuPosition,
  doSetMenu,
  domains,
  menuRef,
  name,
  offset,
  openMenu,
  subjectId,
  type,
}) {
  const doOpen = useCallback(() => doSetMenu('edit'), [doSetMenu])
  const doClose = useCallback(() => doSetMenu(null), [doSetMenu])
  const onSave = useDoUpdateCustomFieldValuesFromForm(subjectId)
  return (
    <>
      <div className="action" onClick={doOpen}>
        <Icon name="pencil" size="small" />
      </div>
      {openMenu === 'edit' && (
        <CompanyHeaderFieldsEditModal
          domains={domains}
          doRecalcMenuPosition={doRecalcMenuPosition}
          menuRef={menuRef}
          name={name}
          offset={offset}
          onCancel={doClose}
          onSave={onSave}
          type={type}
        />
      )}
    </>
  )
}
