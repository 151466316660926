module.exports = {
  appUrl: 'https://www.groovehqstaging.com/',
  widgetUrl: 'https://widget-cluster.groovehqstaging.com',
  appDomain: 'groovehqstaging.com',
  kbProxyDomain: 'hosting-staging.groovehq.help',
  api_url: 'https://api.groovehqstaging.com',
  graphql_url: 'https://graphql.groovehqstaging.com/graphql',
  app_graphql_url: 'https://api.groovehqstaging.com/v2/graphql',
  report_url: 'https://graphql.groovehqstaging.com/reports',
  embeddable_cards_url:
    'https://graphql.groovehqstaging.com/embeddable_cards/proxy',
  realtime_url: 'realtime.groovehqstaging.com',
  s3_url: 's3.amazonaws.com',
  vapidPublicKey:
    'BIInyR5THROG5jHrl_tqEHwoerR163BN59-ptsu7mIke1em5POAci3goL8zeCXkOeWxYrgpE5amNRvPsUYn7H-Y=',
  stripePublicKey: 'pk_test_W2m1TzZq858q0esR0oFeMl0V',
  filestackKey: 'AbnxTnzehQ4KY9hgqQ767z',
  filestackRegion: 'us-east-1',
  filestackBucket: 'groove-filestack-staging',
  showReports: true,
  showSettings: true,
  api_concurrency: 20,
  gtm_segment: 'Application',
  gtm_version: 'staging',
  gtm_containerid: 'GTM-MQ94GV3',
  gtm_auth: 'mYVyrA1hQLLYvvjqsdPodQ',
  gtm_preview: 'env-8',
  google_recaptcha_site_key: '6LdhCn4UAAAAAH0OXvFAtu8mqe3tSwNsiVc3AO_n',
  integrationsApiUrl: 'https://integrations.groovehqstaging.com',
  integrationsApiConcurrency: 4,
  githubAppName: 'groovehq-staging',
  chatServerUrl: 'https://chat-server-cluster.staging.groovehq.com',
  facebookMessengerUrl: 'https://business.facebook.com/latest/inbox/all',
  chatIdentityServerUrl: 'https://vector.im',
  chatFileSizeLimits: {
    // in bytes
    matrix: 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.facebook': 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.instagram': 25 * 1024 * 1024, // 25 MB
  },
  emojiWidth: 280,
  emojiHeight: 399,
  uploaderUrl: 'https://uploader.groovehqstaging.com',
  trialDays: 7,
  trialShowNoticeBannerDaysLeft: 3,
  disableSW: process.env.OFFLINE_RUNTIME === 'off',
  frillWidgets: {
    ideas: {
      key: 'f3249687-32c0-4697-a247-7029260a0535',
    },
    announcements: {
      key: 'ec26475a-3dd8-4012-9106-d1295b6b8719',
    },
    roadmap: {
      key: '4d0a5ca0-2f67-453c-985a-d54e0682d5b4',
    },
  },
  showFrillAnnoucements: true,
  shopify: {
    graphQLApiVersion: '2024-01',
    restApiVersion: '2024-01',
    appStoreGrooveAppUrl: 'https://apps.shopify.com/support-software',
  },
  rechargeClientId:
    '91c50263090e1daab54eca2ed11667ccfc477259404deb5f8eb01505792bd1f0',
  posthog: {
    enabled: false,
    apiKey: 'phc_rhzVCgRaH2TjK7vM7s4oebWrjdN9K1Y7mULKBQGzfT7',
    apiHost: 'https://observe-cluster.production.groovehq.com',
  },
  statusPageIOPageId: '73nj5jhb3tk4',
  hotjar: {
    hjid: '3237486',
    hjsv: '7',
  },
}
