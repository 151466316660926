import React, { useEffect, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import {
  CLOSED,
  INT_STATE_MAP,
  OPENED,
  SPAM,
  UNREAD,
} from 'constants/ticket_state_types'
import { snoozeLabel } from 'util/snooze'
import { stripHTML } from 'util/strings'
import useIsMounted from 'util/hooks/useIsMounted'
import { isEventAuthorAutomation } from 'ducks/notifications/v2/utils'

const stateChangedActionText = stateInt => {
  switch (INT_STATE_MAP[stateInt]) {
    case UNREAD:
      return 'marked conversation as unread'
    case OPENED:
      return 're-opened conversation'
    case CLOSED:
      return 'closed conversation'
    case SPAM:
      return 'marked conversation as spam'
    default:
      return ''
  }
}

const NotificationSubject = ({ event }) => {
  const isMounted = useIsMounted()
  const { type, author, recipient, meta } = event.getContent()
  const subjectRef = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const { id: recipientId, name: recipientName } = recipient || {}
  const { id: authorId, name: authorName } = author || {}
  const {
    is_note: isNote = false,
    is_forwarded: isForwarded = false,
    rating_grade: ratingGrade,
    state_int: stateInt,
    stop_at: snoozedUntilDate,
    other_recipients: otherRecipients,
    reaction,
  } =
    meta || {}
  const isReply = !isNote && !isForwarded

  const currentUser = useSelector(selectCurrentUser)
  const isCurrentUserAuthor = authorId === currentUser.gid
  const isCurrentUserRecipient = recipientId === currentUser.gid
  const isSelfAction = authorId === recipientId
  const hasOtherRecipients = (otherRecipients?.length || 0) > 0
  const isAutomatedAuthor = isEventAuthorAutomation(event)

  useEffect(() => {
    const subjectEl = subjectRef.current

    if (!subjectEl) return

    if (
      subjectEl.scrollHeight > subjectEl.clientHeight ||
      subjectEl.scrollWidth > subjectEl.clientWidth
    ) {
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }, [])

  const displayAuthorName = isAutomatedAuthor ? `"${authorName}"` : authorName

  const subject = useMemo(
    () => {
      let operation = null
      switch (type) {
        case 'new_ticket_to_agents':
          operation = (
            <span>
              <strong>New conversation</strong> from {displayAuthorName}
            </span>
          )
          break
        case 'new_twitter_mention':
          operation = (
            <span>
              <strong>New twitter mention</strong> from {displayAuthorName}
            </span>
          )
          break
        case 'ticket_assigned_to_agent':
        case 'ticket_reassigned':
        case 'ticket_assigned_to_group':
          if (isSelfAction) {
            operation = (
              <span>
                {displayAuthorName} <strong>assigned a conversation</strong> to
                themself
              </span>
            )
          } else if (isCurrentUserRecipient) {
            operation = (
              <span>
                {displayAuthorName} <strong>assigned a conversation</strong> to
                you
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName} <strong>assigned a conversation</strong> to{' '}
                {recipientName}
              </span>
            )
          }
          break
        case 'ticket_unassigned':
          if (isCurrentUserAuthor) {
            operation = (
              <span>
                You <strong>unassigned a conversation</strong>
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName} <strong>unassigned a conversation</strong>
              </span>
            )
          }
          break
        case 'new_comment_from_enduser':
        case 'new_comment_from_agent':
          if (isNote) {
            if (isCurrentUserRecipient) {
              operation = (
                <span>
                  {displayAuthorName} <strong>added note</strong> to your
                  conversation
                </span>
              )
            } else {
              operation = (
                <span>
                  {displayAuthorName} <strong>added note</strong> to
                  conversation
                </span>
              )
            }
          } else if (isReply) {
            if (isCurrentUserRecipient) {
              operation = (
                <span>
                  {displayAuthorName}{' '}
                  <strong>replied to your conversation</strong>
                </span>
              )
            } else {
              operation = (
                <span>
                  {displayAuthorName} <strong>replied to a conversation</strong>
                </span>
              )
            }
          } else if (isForwarded) {
            operation = (
              <span>
                {displayAuthorName} <strong>forwarded a conversation</strong>
              </span>
            )
          }
          break
        case 'rating_notification_for_assigned_agent':
        case 'rating_notification_for_unassigned_agents':
          if (isCurrentUserRecipient) {
            operation = (
              <span>
                {displayAuthorName} said your{' '}
                <strong>response was {ratingGrade}</strong>
              </span>
            )
          } else if (recipientId) {
            operation = (
              <span>
                {displayAuthorName} said {recipientName}’s{' '}
                <strong>response was {ratingGrade}</strong>
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName}{' '}
                <strong>said response was {ratingGrade}</strong>
              </span>
            )
          }
          break
        case 'mention': {
          const mentionedOthers = hasOtherRecipients ? ' and others' : ''

          if (isCurrentUserRecipient) {
            operation = (
              <span>
                {displayAuthorName}{' '}
                <strong>mentioned you{mentionedOthers}</strong> in a
                conversation
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName}{' '}
                <strong>
                  mentioned {recipientName}
                  {mentionedOthers}
                </strong>{' '}
                in a conversation
              </span>
            )
          }
          break
        }
        case 'ticket_state_changed':
          operation = (
            <span>
              {displayAuthorName}{' '}
              <strong>{stateChangedActionText(stateInt)}</strong>
            </span>
          )
          break
        case 'ticket_snoozed':
          if (isCurrentUserAuthor) {
            operation = (
              <span>
                You <strong>snoozed</strong> conversation until{' '}
                {snoozeLabel(new Date(snoozedUntilDate), !snoozedUntilDate)}
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName} <strong>snoozed</strong> conversation until{' '}
                {snoozeLabel(snoozedUntilDate, !snoozedUntilDate)}
              </span>
            )
          }
          break
        case 'ticket_unsnoozed':
          if (isCurrentUserAuthor) {
            operation = (
              <span>
                You <strong>unsnoozed</strong> conversation
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName} <strong>unsnoozed</strong> conversation
              </span>
            )
          }
          break
        case 'new_comment_reaction_from_agent':
          if (isCurrentUserRecipient) {
            operation = (
              <span>
                {displayAuthorName} <strong>reacted {reaction}</strong> to your
                {isNote ? ' note' : ' conversation'}
              </span>
            )
          } else {
            operation = (
              <span>
                {displayAuthorName} <strong>reacted {reaction}</strong> to
                {isNote ? ' note' : ' conversation'}
              </span>
            )
          }
          break
        case 'rule_send_notification_to_agent':
          operation = (
            <span>
              {displayAuthorName} <strong>sent a notification</strong>
            </span>
          )
          break
        default:
          operation = type
          break
      }

      return operation
    },
    [
      type,
      displayAuthorName,
      recipientName,
      isCurrentUserRecipient,
      ratingGrade,
      isForwarded,
      isNote,
      recipientId,
      stateInt,
      isCurrentUserAuthor,
      isReply,
      snoozedUntilDate,
      hasOtherRecipients,
      isSelfAction,
      reaction,
    ]
  )

  const subjectPlainText = useMemo(
    () => {
      if (!subject || !isMounted() || !isOverflowing) return null

      try {
        return stripHTML(renderToString(subject))
      } catch (_) {
        // on dev when HMR reloads, it sometimes throws a `renderToString is not a function` error
        return null
      }
    },
    [subject, isMounted, isOverflowing]
  )

  return (
    <div
      className="grui truncate notification-subject"
      title={subjectPlainText}
      ref={subjectRef}
    >
      {subject}
    </div>
  )
}

export default NotificationSubject
