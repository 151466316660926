import React from 'react'
import { connect } from 'react-redux'
import { selectLocationState } from 'redux-first-router'
import CombinedProvider from '../CombinedProvider'

import Item from './view'

const select = state => ({
  routesMap: selectLocationState(state).routesMap,
})
const perform = {
  doNavigateToAction: x => x,
}

const Wrapper = React.forwardRef((props, ref) => (
  <CombinedProvider>
    <Item {...props} forwardedRef={ref} />
  </CombinedProvider>
))

export default connect(select, perform, null, { forwardRef: true })(Wrapper)
