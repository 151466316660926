import React from 'react'

import Scroller from 'components/Scroller'
import { Loader, Text } from 'shared/ui'

import { getLength } from 'util/arrays'
import { propFunc } from 'util/functions'
import { withResizeChange } from 'util/hoc'
import { stopEventAndRun } from 'util/keys'
import { isChecked } from 'util/label_selection'

import ListItem from './ListItem'
import SearchingEmptyState from '../../shared/SearchingEmptyState'

const LabelList = ({
  focusedIndex,
  isFetchingSearchResults,
  isLoadingTop,
  labels,
  onItemClick,
  onCheckboxChanged,
  searchTerm,
  onCreateTagClick,
  hasMoreLabels,
  isSearching,
}) => {
  const displayHasMoreLabels = !isSearching && hasMoreLabels
  if (isLoadingTop || isFetchingSearchResults)
    return <Loader className="grui my-20" />

  if (!isFetchingSearchResults && getLength(labels) <= 0) {
    return (
      <SearchingEmptyState
        isDesktop
        searchTerm={searchTerm}
        onAdd={onCreateTagClick}
      />
    )
  }

  return (
    <div className="grui mt-4">
      {labels.map((label, index) => (
        <ListItem
          isChecked={isChecked(label)}
          isFocused={index === focusedIndex}
          key={label.id}
          label={label}
          onClick={propFunc(onItemClick, index)}
          onCheckboxClick={stopEventAndRun(propFunc(onCheckboxChanged, label))}
          showCount={Boolean(searchTerm)}
        />
      ))}

      {displayHasMoreLabels && (
        <div className="grui text-center">
          <Text as="p" size="small" fontWeight="500">
            Search to see all tags
          </Text>
        </div>
      )}
    </div>
  )
}

const ScrollingList = props => {
  return (
    <Scroller style={{ maxHeight: '246px' }}>
      <LabelList {...props} />
    </Scroller>
  )
}

const ScrollingListWithResizingChange = withResizeChange(ScrollingList)

export default ScrollingListWithResizingChange
