import React from 'react'
import PropTypes from 'prop-types'
import { emptyObj, omit } from 'util/objects'
import navigateToLegacy, { redirectToLegacyLink } from 'util/navigateToLegacy'

const LegacyLink = ({
  as,
  children,
  className,
  dispatch: _,
  navigate,
  onClick,
  params,
  path,
  subdomain,
  target,
  token,
  async = true,
  removeProps = [],
  ...rest
}) => {
  const tag = as || 'a'
  const elementProps = { ...rest, className }
  if (async) {
    elementProps.onClick = e => {
      e.preventDefault()
      try {
        navigateToLegacy(token, subdomain, path, target, params)
        if (onClick) onClick()
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    }
  } else if (tag !== 'a') {
    throw new Error('Synchronous legacy redirects only supported with a tags')
  } else {
    elementProps.href = redirectToLegacyLink(token, subdomain, path)
    elementProps.target = target
  }
  const sanitizedProps =
    removeProps.length === 0 ? elementProps : omit(removeProps, elementProps)
  return React.createElement(tag, sanitizedProps, children)
}

LegacyLink.propTypes = {
  as: PropTypes.string,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  target: PropTypes.string,
  token: PropTypes.string.isRequired,
}

LegacyLink.defaultProps = {
  as: 'a',
  onClick: undefined,
  params: emptyObj,
  target: undefined,
}

export default LegacyLink
