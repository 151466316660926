import React, { useMemo } from 'react'

import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import NumberField from '@groovehq/internal-design-system/lib/components/NumberField/NumberField'
import Product from '@groovehq/internal-design-system/lib/components/Shopify/Product/Product'
import { shopifyMoneyString } from 'util/currency'
import { styles as mainStyles } from 'components/integrations/ShopifyV2WidgetCard/styles'
import { styles } from 'components/integrations/ShopifyV2WidgetCard/CreateOrderDrawer/styles'

const createProductImage = ({ value }) => {
  return <Product.Image image={value?.url} />
}

const createCustomProductCell = ({ row: { original } }) => {
  const {
    image,
    name,
    discountedUnitPriceSet,
    originalUnitPriceSet,
    suggestedRefund,
  } = original
  const hasDiscount =
    originalUnitPriceSet.shopMoney.amount !==
    discountedUnitPriceSet.shopMoney.amount

  return (
    <Product name={name} image={image ? image.src : null}>
      <span
        css={[mainStyles.moneyString, hasDiscount && mainStyles.lineThrough]}
      >
        {shopifyMoneyString(originalUnitPriceSet.shopMoney)}
      </span>
      {hasDiscount && (
        <span css={mainStyles.moneyString}>
          &nbsp;
          {shopifyMoneyString(discountedUnitPriceSet.shopMoney)}
        </span>
      )}
      <br />
      {suggestedRefund?.quantity > 0 && (
        <span css={[text.styles.textWarning]}>Item can’t be restocked</span>
      )}
    </Product>
  )
}

const createProductCell = column => {
  const {
    value,
    row: { original },
  } = column
  if (!value) return createCustomProductCell(column)
  const { displayName, productURL, sku } = value
  const {
    image,
    product,
    originalUnitPriceSet,
    discountedUnitPriceSet,
  } = original

  const name = product.hasOnlyDefaultVariant ? original.name : displayName

  const hasDiscount =
    originalUnitPriceSet.shopMoney.amount !==
    discountedUnitPriceSet.shopMoney.amount

  const info = (
    <>
      <span css={text.styles.uppercase}>
        {sku}
        &thinsp;&thinsp;&middot;&thinsp;&thinsp;
      </span>
      <span
        css={[mainStyles.moneyString, hasDiscount && mainStyles.lineThrough]}
      >
        {shopifyMoneyString(originalUnitPriceSet.shopMoney)}
      </span>
      {hasDiscount && (
        <span css={mainStyles.moneyString}>
          &nbsp;
          {shopifyMoneyString(discountedUnitPriceSet.shopMoney)}
        </span>
      )}
    </>
  )
  return (
    <Product
      name={name}
      image={image ? image.url : null}
      productURl={productURL}
    >
      {info}
    </Product>
  )
}

const createTotalCell = ({ value, row: { original } }) => {
  const { orderCurrencyCode, suggestedRefund } = original
  const { subtotalSet } = suggestedRefund

  const currencyCode = value?.shopMoney?.currencyCode || orderCurrencyCode
  const amount = subtotalSet?.shopMoney?.amount || 0

  return (
    <span css={[text.styles.fontMedium, mainStyles.moneyString]}>
      {shopifyMoneyString({ currencyCode, amount })}
    </span>
  )
}

const useColumns = ({ isCalculating }) => {
  const columns = useMemo(
    () => {
      const QuantityCell = ({
        value,
        updateData,
        row: { index, original },
        column: { id },
      }) => {
        // Handle change quantity and change item total accordingly
        const handleChange = ({ target }) => {
          updateData(index, id, target.value)
        }

        return (
          <NumberField
            css={styles.numberField}
            value={value || 0}
            onChange={handleChange}
            min={0}
            hasMaxHint
            max={original.currentQuantity}
            disabled={isCalculating}
          />
        )
      }

      return [
        {
          Header: 'Product',
          accessor: 'image',
          Cell: createProductImage,
          disableSortBy: true,
          className: 'cell-image',
          headerClassName: 'cell-image',
        },
        {
          accessor: 'variant',
          Cell: createProductCell,
          disableSortBy: true,
          className: 'cell-product-name',
          headerClassName: 'cell-product-name',
        },
        {
          Header: 'Quantity',
          accessor: 'suggestedRefund.quantity',
          Cell: QuantityCell,
          disableSortBy: true,
          className: 'cell-quantity',
          headerClassName: 'cell-quantity',
        },
        {
          Header: 'Subtotal',
          Cell: createTotalCell,
          accessor: 'suggestedRefund.subtotalSet',
          disableSortBy: true,
          className: 'cell-total',
          headerClassName: 'cell-total',
        },
      ]
    },
    [isCalculating]
  )

  return columns
}

export default useColumns
