import React from 'react'
import cn from 'classnames'
import {
  heading,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Features from './Features'
import Testimonials from './Testimonials'
import { styles } from './styles'

const StartContent = ({ title, subtitle, children, className }) => {
  return (
    <div className={cn('grui text-center', className)}>
      <h2 className="grui" css={heading.styles.h2}>
        {title}
      </h2>
      <p className="grui" css={[paragraph.styles.preflight, styles.subtitle]}>
        {subtitle}
      </p>
      {children}
    </div>
  )
}

StartContent.Features = Features
StartContent.Testimonials = Testimonials

export default StartContent
