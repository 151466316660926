import { useMemo } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { Avatar } from 'shared/ui'
import { css } from '@emotion/react'
import {
  indicator,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import NewLabel from '@groovehq/internal-design-system/lib/components/NewLabel/NewLabel'
import { selectFeatureCalloutExpired } from 'selectors/app/selectFeatureCalloutExpired'
import styles from './styles.css'

export default function Suggestion({ suggestion }) {
  const suggestObj =
    typeof suggestion === 'string' ? { suggestion } : suggestion
  const {
    avatar_url: avatarUrl,
    hint,
    suggestion: suggestionText,
    email,
  } = suggestObj
  const featureCalloutExpired = useSelector(state =>
    selectFeatureCalloutExpired(state, '2023-03-24T00:00:00')
  )

  const newLabel = useMemo(
    () => {
      if (featureCalloutExpired || suggestionText !== 'inbox:') {
        return null
      }

      return <NewLabel className={styles.newLabel} />
    },
    [suggestionText, featureCalloutExpired]
  )

  if (suggestion) {
    return (
      <div className={styles.SearchSuggestions_suggestion}>
        {avatarUrl && (
          <Avatar
            size={20}
            actor={suggestObj}
            css={css`
              flex-shrink: 0;
            `}
          />
        )}
        <div
          className={cn('grui truncate', styles.label)}
          css={indicator.styles.accentNeutral}
        >
          {suggestionText}
        </div>
        {hint && <div className={styles.hint}>{hint}</div>}
        {email && (
          <div
            className={cn('grui flex', styles.email)}
            css={text.styles.textMediumDark}
          >
            (<span className="grui truncate">{email}</span>)
          </div>
        )}
        {newLabel}
        <div className="Icon Icon-keyboard-return" />
      </div>
    )
  }
  return null
}
