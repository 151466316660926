import React from 'react'
import LoginLayout from 'components/LoginLayout'
import ChangePasswordForm from './Form'

export default function ChangePasswordPage() {
  return (
    <LoginLayout tagline="Please enter and confirm your new password in order to complete the password reset">
      <ChangePasswordForm />
    </LoginLayout>
  )
}
