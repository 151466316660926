import { connect } from 'react-redux'
import {
  selectAgentIdsForReplyingCollisionByConversationId,
  selectAgentIdsForViewingCollisionByConversationId,
  selectAgentIdsForNotingCollisionByConversationId,
  selectAgentIdsForTypingCollisionByConversationId,
} from 'ducks/collisions/selectors'
import { selectCurrentTicketReplyDraftHasBody } from 'ducks/drafts2/selectors'
import View from './view'

const select = () => {
  return (state, props) => {
    const { conversationId } = props

    return {
      hasDraft: selectCurrentTicketReplyDraftHasBody(state),
      viewingIds: selectAgentIdsForViewingCollisionByConversationId(
        state,
        conversationId
      ),
      replyingIds: selectAgentIdsForReplyingCollisionByConversationId(
        state,
        conversationId
      ),
      notingIds: selectAgentIdsForNotingCollisionByConversationId(
        state,
        conversationId
      ),
      typingIds: selectAgentIdsForTypingCollisionByConversationId(
        state,
        conversationId
      ),
    }
  }
}

export default connect(select)(View)
