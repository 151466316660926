import React from 'react'

const Context = React.createContext({
  activeIndex: 0,
  lastIndex: 0,
  registerItem: () => {},
  registeredItems: [],
  setActiveItem: () => {},
  totalSize: 0,
  unregisterItem: () => {},
})

export default Context
