import React from 'react'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Link from 'redux-first-router-link'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from './styles'

const ActivationCard = ({
  title,
  className,
  children,
  buttonLabel,
  buttonActionTo,
  icon: Icon,
  note,
}) => {
  return (
    <div css={styles.card} className={className}>
      <div css={styles.icon} className="activation-card-icon">
        <Icon />
      </div>
      <h1 css={heading.styles.h3} className="grui mb-5">
        {title}
      </h1>
      <div css={text.styles.textNormal}>{children}</div>
      <div
        css={[text.styles.fontSemibold, text.styles.textSm]}
        className="grui mt-10 mb-7"
      >
        {note}
      </div>
      <Button
        type="info"
        size="xBig"
        css={styles.button}
        as={Link}
        to={buttonActionTo}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default ActivationCard
