import { connect } from 'react-redux'
import selectIntegrationSettingsByProvider from 'ducks/integrations/selectors/selectIntegrationSettingsByProvider'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectIsMailboxIndicatorNeeded } from 'selectors/currentChangesets/selectIsMailboxIndicatorNeeded'
import {
  selectCurrentTicketMailboxName,
  selectCurrentTicketMailboxColor,
} from 'selectors/tickets'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { doShowSnackbar } from 'actions/snackbar'
import { doReact } from 'ducks/comments/operations'
import { doOpenDrawer } from 'ducks/drawers/actions'
import { DRAWER_COMMENT_DELETE } from 'ducks/drawers/types'

import ExpandedMessageView from './view'

const select = state => {
  const mailboxIndicatorNeeded = selectIsMailboxIndicatorNeeded(state)
  return {
    currentUserId: selectCurrentUser(state).id,
    ticketId: selectCurrentTicketId(state),
    mailboxIndicatorNeeded,
    mailboxName:
      mailboxIndicatorNeeded && selectCurrentTicketMailboxName(state),
    mailboxColor:
      mailboxIndicatorNeeded && selectCurrentTicketMailboxColor(state),
    integrationSettings: selectIntegrationSettingsByProvider(state),
  }
}

const perform = dispatch => ({
  doShowSnackbar,
  onDelete: (ticketId, commentId) => {
    const id = `${commentId}-${ticketId}`
    return dispatch(doOpenDrawer(id, DRAWER_COMMENT_DELETE, id))
  },
  onReaction: (ticketId, commentId, reaction) =>
    dispatch(doReact({ ticketId, commentId, reaction })),
})

export default connect(select, perform)(ExpandedMessageView)
