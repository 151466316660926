import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'

const ColorElement = styled('span')`
  width: ${props => props.theme.spacing.base};
  height: ${props => props.theme.spacing.base};
  border-radius: ${themeVars.defaultBorderRadius};
  margin-right: ${props => props.theme.spacing['5']};
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  top: 2px;
`

export default function LabelOptionIcon({ color }) {
  const bgColor = color || 'b6bbbf'

  return <ColorElement style={{ background: `#${bgColor}` }} />
}
