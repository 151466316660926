import { connect } from 'react-redux'
import { required } from 'util/validation'
import withFormFields from 'shared/ui/Form/withFormFields'
import { selectLatestLoginError } from 'selectors/app'
import { selectIsAccountSubdomain, selectSubdomain } from 'selectors/location'

import { doLogin, doGoogleLogin, doOfficeLogin } from '../actions'

import LoginFormView from './view'

// This is a little dirty, but the intial values object is not allowed
// to be re-declared otherwise it'll cause problems in the withFormFields
// module. Its doing a basic object === object check which will fail if
// the object is re-declared
let initialValues = null
function getInitialValues(state) {
  if (!initialValues) {
    initialValues = {
      emailInput: '',
      passwordInput: '',
      subdomainInput: selectIsAccountSubdomain(state)
        ? selectSubdomain(state)
        : '',
    }
  }
  return initialValues
}

const validators = {
  emailInput: required,
  passwordInput: required,
  subdomainInput: required,
}

const select = state => ({
  initialValues: getInitialValues(state),
  validators,
  isAccountSubdomain: selectIsAccountSubdomain(state),
  loginError: selectLatestLoginError(state),
})

const perform = {
  onSave: doLogin,
  doGoogleLogin,
  doOfficeLogin,
}

export default connect(select, perform)(withFormFields(LoginFormView))
