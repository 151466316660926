import React from 'react'

import FormRow from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/FormRow'
import Loader from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/Loader'
import ModalContent from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/LinkResource/ModalContent'
import Sidebar from 'shared/components/ui/Sidebar'
import ValidatedField from 'shared/components/ui/ValidatedField'
import { Button, Label, Tooltip } from 'shared/ui'
import { Dropdown } from 'util/ui'

export default function LinkExistingTrelloCardModalView({
  boards,
  cards,
  doLinkTrelloCardToTicket,
  formFields: {
    card: cardId = null,
    board: boardId = null,
    list: listId = null,
  } = {},
  isBoardLoading,
  isListLoading,
  lists,
  onChange,
  onClose,
  onOpen,
  open,
}) {
  const isLoading = !boards || isBoardLoading || isListLoading
  const isBoardChoosen = boardId && !isBoardLoading
  const isListChoosen = listId && !isListLoading
  return (
    <React.Fragment>
      <Button basic className="grui mt-4" onClick={onOpen}>
        Existing
      </Button>
      <Sidebar
        footerChildren={[
          <Tooltip
            key="link"
            portal
            position="top"
            tooltip={
              !cardId && 'Please ensure all required fields are filled in'
            }
          >
            <Button
              disabled={!cardId}
              fluid
              onClick={doLinkTrelloCardToTicket}
              primary
            >
              Link card
            </Button>
          </Tooltip>,
          <Button
            basic
            fluid
            key="cancel"
            className="grui mt-4"
            onClick={onClose}
          >
            Cancel
          </Button>,
        ]}
        headerChildren="Link to Existing Trello Card"
        onClose={onClose}
        open={open}
      >
        <ModalContent>
          {boards && [
            <FormRow key="row-1">
              <Label as="div">Board</Label>
              <ValidatedField
                fluid
                name="board"
                options={boards}
                onChange={onChange}
                placeholder="- Please select -"
                search
                selection
                selectOnNavigation={false}
                validatedFieldComponent={Dropdown}
                value={boardId}
              />
            </FormRow>,
            <FormRow key="row-2">
              <Label as="div">List</Label>
              <ValidatedField
                disabled={!isBoardChoosen}
                fluid
                name="list"
                options={lists}
                onChange={onChange}
                placeholder="- Please select -"
                search
                selection
                selectOnNavigation={false}
                validatedFieldComponent={Dropdown}
                value={listId}
              />
            </FormRow>,
            <FormRow key="row-3">
              <Label as="div">Card</Label>
              <ValidatedField
                disabled={!isListChoosen}
                fluid
                name="card"
                options={cards}
                onChange={onChange}
                placeholder="- Please select -"
                search
                selection
                selectOnNavigation={false}
                validatedFieldComponent={Dropdown}
                value={cardId}
              />
            </FormRow>,
          ]}
          {isLoading && <Loader />}
        </ModalContent>
      </Sidebar>
    </React.Fragment>
  )
}
