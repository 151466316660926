import { connect } from 'react-redux'
import { doNavigate } from 'actions/app'
import {
  selectModifiedSearchQueriesForState,
  selectModifiedSearchQueriesForDrafts,
} from 'selectors/search_modifier'
import { selectCurrentTicketSearchQueryTitle } from 'selectors/search'

import Options from './view'

const select = state => {
  const title = selectCurrentTicketSearchQueryTitle(state)
  const isDrafts = title && title.match('Drafts')
  const options = isDrafts
    ? selectModifiedSearchQueriesForDrafts(state)
    : selectModifiedSearchQueriesForState(state)
  const optionsTitle = isDrafts ? 'Filter by type' : 'Filter by State'

  return {
    options,
    optionsTitle,
  }
}

const perform = dispatch => ({
  doSelectOption: option => dispatch(doNavigate(option.url)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  doSelectOption: option => {
    dispatchProps.doSelectOption(option)
    ownProps.onSelect(option)
  },
})
export default connect(select, perform, mergeProps)(Options)
