import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import LabelList from 'components/LabelList'
import EditableSubject from './EditableSubject'
import styles from './styles.css'

const PrintableMessageCount = styled('div')`
  margin-top: ${props => props.theme.spacing['5']};
`

const TicketHero = ({ ticket, className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <EditableSubject ticketId={ticket.id} />
      {ticket.message_count > 1 && (
        <PrintableMessageCount className="visible-only-in-print">
          {ticket.message_count} messages
        </PrintableMessageCount>
      )}
      <LabelList className={styles.labels} />
    </div>
  )
}

export default TicketHero
