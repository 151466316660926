import { useEffect } from 'react'
import cn from 'classnames'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { runOnNextTick } from 'util/functions'
import { styles } from '../styles'

const PlainTextEditor = ({ className, placeholder }) => {
  const [editor] = useLexicalComposerContext()

  // Prevent focusing the editor after set the initial state
  useEffect(
    () => {
      runOnNextTick(() => {
        if (!editor.isEditable()) {
          editor.setEditable(true)
        }
      })
    },
    [editor]
  )

  return (
    <div
      className={cn('grui px-5 flex-1 relative', className)}
      css={styles.editableContentInputWrapper}
    >
      <PlainTextPlugin
        contentEditable={
          <ContentEditable
            spellCheck={false}
            css={styles.editableContentInput}
            className="grui py-4 overflow-x-auto"
          />
        }
        placeholder={
          <div className="grui truncate" css={styles.placeholder}>
            {placeholder}
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
    </div>
  )
}

export default PlainTextEditor
