import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const videoContainer = css`
  max-width: ${rem(500)};
  margin: ${rem(77)} auto ${rem(48)};
`

const subtitle = theme => css`
  color: ${theme.color.monochrome.black};
  font-size: ${theme.fontSize.x5large};
  line-height: ${theme.lineHeight.leadingSnug};
  margin: ${rem(12)} auto ${rem(26)};
`

export const styles = {
  videoContainer,
  subtitle,
}
