import React, { useCallback } from 'react'
import { css } from '@emotion/react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Attachment from '@groovehq/internal-design-system/lib/assets/icons/Attachment'

const buttonStyle = css`
  overflow: visible;
`

const countStyle = theme => css`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  font-size: ${theme.fontSize.tiny};
  top: -2px;
  right: -6px;
  text-align: center;
  display: block;
  width: 15px;
  z-index: -2;
  button:hover & {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
`

const AttachmentButton = React.memo(
  ({
    onClick,
    triggerId = 'attachmentUploadTrigger',
    attachments = [],
    children,
    ...rest
  }) => {
    const hasAttachments = attachments.length > 0

    const openAttachmentDialog = useCallback(
      () => {
        if (onClick) onClick()
        document.getElementById(triggerId).click()
      },
      [onClick, triggerId]
    )

    return (
      <React.Fragment>
        <Tooltip title="Add an attachment" position="top">
          <Button
            type="icon"
            size="small"
            onClick={openAttachmentDialog}
            css={buttonStyle}
            {...rest}
          >
            <Attachment />
            {hasAttachments && (
              <span css={countStyle}>{attachments.length}</span>
            )}
          </Button>
        </Tooltip>

        {children}
      </React.Fragment>
    )
  }
)

export default AttachmentButton
