import { css } from '@emotion/react'

const attachment = theme => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${theme.spacing[7]} 0;
  padding: ${theme.spacing[7]} 0;

  &:not(:first-of-type) {
    border-top: 1px solid ${theme.color.monochrome.light};
  }

  &:hover {
    .attachment-icon-buttons {
      display: flex;
    }
  }
`

const content = theme => css`
  max-width: calc(100% - 48px - ${theme.spacing[5]});
  display: flex;
  align-items: center;
`

const textContent = theme => css`
  max-width: calc(100% - 27px - ${theme.spacing[5]});
  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacing[5]};
`

const dateText = theme => css`
  margin-top: 2px;
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.color.monochrome.dark};
`

const fileName = theme => css`
  font-size: ${theme.fontSize.base};
  color: ${theme.color.monochrome.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const imageFileName = css`
  cursor: pointer;
`

const fileSize = theme => css`
  margin-left: ${theme.spacing[3]};
  font-size: ${theme.fontSize.tiny};
  color: ${theme.color.monochrome.black};
  white-space: nowrap;
`

const showMore = theme => css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 12px;
  text-align: right;
  font-size: ${theme.fontSize.tiny};
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.monochrome.mediumDark};
  cursor: pointer;

  &:hover {
    color: ${theme.color.primary.brand};
  }
`

export const styles = {
  attachment,
  content,
  textContent,
  dateText,
  fileName,
  imageFileName,
  fileSize,
  showMore,
}
