import React from 'react'
import TimelineEventComment from './TimelineEventComment'
import TimelineEventMessage from './TimelineEventMessage'

const TimelineEvent = React.memo(props => {
  const {
    timelineEvent: { __typename },
  } = props
  const isComment = __typename === 'CommentEvent'
  if (isComment) return <TimelineEventComment {...props} />

  return <TimelineEventMessage {...props} />
})

export default TimelineEvent
