import React from 'react'
import Link from 'redux-first-router-link'
import cn from 'classnames'
import config from 'config'
import SUI from 'shared/components/ui/SUI'
import Screen from 'components/Screen'
import { LOGIN_PAGE } from 'constants/pages'
import styles from './styles.less'

// NOTE (jscheel): We create the signup url manually, because we want it to go
// to the app subdomain even if the user is on an account subdomain.
export const signupTo = `${config.appUrl}signup`

export default function LoginLayout({
  greeting,
  tagline,
  showLoginLink = false,
  showSignupLink = false,
  children,
  className,
}) {
  return (
    <SUI transparent={false}>
      <Screen className={cn(styles.Login, className)}>
        <div className={styles.logo} />
        {greeting && <div className={styles.greeting}>{greeting}</div>}
        {tagline && <div className={styles.tagline}>{tagline}</div>}
        {children}
        {showLoginLink && (
          <div className={styles.loginLink}>
            Already have an account?{' '}
            <Link to={{ type: LOGIN_PAGE }}>Log in</Link>
          </div>
        )}
        {showSignupLink && (
          <div className={styles.loginLink}>
            Don&#39;t have an account? <a href={signupTo}>Sign up</a>
          </div>
        )}
      </Screen>
    </SUI>
  )
}
