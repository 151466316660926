import { css } from '@emotion/react'

const EmailMarketingWidgetHelp = css`
  && {
    font-style: italic;
    margin-top: 8px;
  }
`

export default function Help() {
  return (
    <p css={EmailMarketingWidgetHelp}>
      Seeing recently deleted lists, or have lists missing?{' '}
      <a
        href="https://help.groovehq.com/help/setting-up-the-mailchimp-integration"
        target="_blank"
        rel="noopener noreferrer"
      >
        Read this article
      </a>
    </p>
  )
}
